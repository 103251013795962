// constant
import { KNOWLEDGE_ITEMS } from "redux/constants";
// local variable
export const name = "associatedKPs";
// initial state
export const initialState = {
  loading: false,
  error: false,
  errorMessage: "",
  KPs: [],
  KPsBundle: []
};
// reducer selectors
export const selectors = {
  getAssociatedKPsBundle: (state) => state[name].KPsBundle,
  getAssociatedKPs: (state) => state[name].KPs,
};

const setLoading = (state) => {
  return {
    ...state,
    loading: true,
    error: false,
    errorMessage: ""
  }
};

const setError = (state, payload) => {
  return {
    ...state,
    loading: false,
    error: true,
    errorMessage: payload.error
  }
};

const setKPs = (state, payload) => {
  return {
    ...state,
    KPs: payload.pages,
    KPsBundle: payload.pages,
    loading: false
  }
};

const reorderKPsBundle = (state, payload) => {
  return {
    ...state,
    KPsBundle: payload.pages,
    loading: false
  }
};

const setKPsBundle = (state, payload) => {
  const { KPsBundle } = state;
  const newKP = payload.kpdata;
  return {
    ...state,
    KPsBundle: [...KPsBundle, newKP],
    loading: false
  }
};

const clearKPsBundle = (state) => {
  return {
    ...state,
    KPsBundle: [],
  }
};


export function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case KNOWLEDGE_ITEMS.CLEAR_ASSOCIATED_KNOWLEDGE_PAGES:
      return clearKPsBundle(state);
    case KNOWLEDGE_ITEMS.GET_ASSOCIATED_KNOWLEDGE_PAGES_PENDING:
    case KNOWLEDGE_ITEMS.SAVE_ASSOCIATED_KP_PENDING:
    case KNOWLEDGE_ITEMS.REORDER_ASSOCIATED_KPS_PENDING:
      return setLoading(state);
    case KNOWLEDGE_ITEMS.GET_ASSOCIATED_KNOWLEDGE_PAGES_FAILURE:
    case KNOWLEDGE_ITEMS.SAVE_ASSOCIATED_KP_FAILURE:
    case KNOWLEDGE_ITEMS.REORDER_ASSOCIATED_KPS_FAILURE:
      return setError(state, payload);

    case KNOWLEDGE_ITEMS.GET_ASSOCIATED_KNOWLEDGE_PAGES_SUCCESS:
      return setKPs(state, payload);

    case KNOWLEDGE_ITEMS.SAVE_ASSOCIATED_KP_SUCCESS:
      return setKPsBundle(state, payload);

    case KNOWLEDGE_ITEMS.REORDER_ASSOCIATED_KPS_SUCCESS:
      return reorderKPsBundle(state, payload);

    default:
      return state;
  }
}