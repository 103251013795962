import React from 'react';
import PropTypes from 'prop-types';
import { InfoPopup } from 'components/shared/InfoPopup/InfoPopup';
import Image from "components/shared/Image";
import dotsImage from 'assets/images/dots-image.svg';
import briefcaseImage from 'assets/images/briefcase.svg';
import billingImage from 'assets/images/dollar.svg';
import CONFIG from 'config';
import LABELS from 'labels';
import GLOBAL_CONSTANTS from 'globalConstants'
import { Link } from "react-router-dom";
import { getNumberInTwoDigits } from "utils/helpers/helpers";
import './CaseConsumptionActions.scss';

export const CaseConsumptionActions = ({
  assets,
  projectId,
  billingInfo,
  showBilling,
  showAssets,
  showEdit,
  showAssetsv1,
  knowledgeAssets,
  caseVignettes,
  caseGuid,
  showBudget,
  caseContactListingCallback
}) => {
  const {
    ALT_TEXTS: {
      MORE_OPTIONS,
      KNOWLEDGE_ASSETS,
      NO_KNOWLEDGE_ASSETS,
    },
    CP_CONSUMPTION: {
      // VIEW_LEGACY_KN,
      // LEGACY_KN,
      CASE_VIGNETTES,
      CASE_MATERIALS,
      BILLING,
      TB_BILLING,
      TB_BILLING_USD,
      BUDGET,
      EDIT_PAGE,
    }
  } = LABELS;
  const {
    UI_URL: {
      CP_METADATA,
    }
  } = CONFIG;
  const { CP_STEP1 } = GLOBAL_CONSTANTS;
  const { tbBillingLocal, tbBillingUsd, budget } = billingInfo;
  const totalAssetCount = knowledgeAssets || caseVignettes ? knowledgeAssets?.length + caseVignettes?.length : 0;

  const {
    IDENTIFIER: {
      CP_PRICING_IDENTIFIER,
      // CP_KN_LEGACY_IDENTIFIER,
      CP_EDIT_IDENTIFIER,
      CP_KNOWLEDGE_ASSETS_ACTIVE_IDENTIFIER,
      CP_KNOWLEDGE_ASSETS_INACTIVE_IDENTIFIER,
    },
  } = LABELS;

  return (
    <div className="caseConsumptionActions">
      {showBilling && !!billingInfo && <InfoPopup
        tooltipPosition="bottom"
        tooltipId="case_billing"
        popupVerticalPosition="bottom"
        popupHorizontalPosition="center"
        withTooltip={true}
        delayHide={0}
        title={BILLING}
        fireAnalyticsEvent={() => caseContactListingCallback({ identifier: CP_PRICING_IDENTIFIER })}
        trigger={
          <>
            <Image src={billingImage} className="caseConsumptionActions__dollar" />
          </>
        }
      >
        <div className="caseConsumptionActions__billingPopup">
          <div className="caseConsumptionActions__billingPopup__heading">{BILLING}</div>
          <ul>
            {!!tbBillingLocal && (
              <li>
                <div>{TB_BILLING}</div>
                <div className="caseConsumptionActions__billingPopup__value">{tbBillingLocal}</div>
              </li>
            )}
            {!!tbBillingUsd && (
              <li>
                <div>{TB_BILLING_USD}</div>
                <div className="caseConsumptionActions__billingPopup__value">{tbBillingUsd}</div>
              </li>
            )}
            {!!showBudget && <li>
              <div>{BUDGET}</div>
              <div className="caseConsumptionActions__billingPopup__value">{budget}</div>
            </li>}
          </ul>
        </div>
      </InfoPopup>}
      {showAssets && <InfoPopup
        tooltipPosition="bottom"
        tooltipId="case_briefcase"
        title={!assets || !assets.length ? NO_KNOWLEDGE_ASSETS : KNOWLEDGE_ASSETS}
        popupVerticalPosition="bottom"
        popupHorizontalPosition="left"
        withTooltip={true}
        delayHide={0}
        disabled={!assets || !assets.length}
        fireAnalyticsEvent={() => caseContactListingCallback(
          { identifier: !totalAssetCount ? CP_KNOWLEDGE_ASSETS_INACTIVE_IDENTIFIER : CP_KNOWLEDGE_ASSETS_ACTIVE_IDENTIFIER }
        )}
        trigger={
          <>
            <Image className={`${!assets || !assets.length ? 'disabledIcon' : ''}`} src={briefcaseImage} />
            {assets?.length ? <span className="caseConsumptionActions__assetscount">{getNumberInTwoDigits(assets.length)}</span> : ''}
          </>
        }
      >
        <div className="caseConsumptionActions__briefcasePopup">
          <div className="caseConsumptionActions__briefcasePopup__heading">{KNOWLEDGE_ASSETS}<span> - {getNumberInTwoDigits(assets?.length)}</span></div>
          <ul>
            <li>
              <div>{CASE_VIGNETTES}</div>
              <div>{getNumberInTwoDigits(assets?.length)}</div>
            </li>
          </ul>
        </div>
      </InfoPopup>}

      {showAssetsv1 && <InfoPopup
        tooltipPosition="bottom"
        tooltipId="case_briefcase"
        title={!totalAssetCount ? NO_KNOWLEDGE_ASSETS : KNOWLEDGE_ASSETS}
        popupVerticalPosition="bottom"
        popupHorizontalPosition="left"
        withTooltip={true}
        delayHide={0}
        disabled={!totalAssetCount}
        fireAnalyticsEvent={() => caseContactListingCallback(
          { identifier: !totalAssetCount ? CP_KNOWLEDGE_ASSETS_INACTIVE_IDENTIFIER : CP_KNOWLEDGE_ASSETS_ACTIVE_IDENTIFIER }
        )}
        trigger={
          <>
            <Image className={`${!totalAssetCount ? 'disabledIcon' : ''}`} src={briefcaseImage} />
            {totalAssetCount ? <span className="caseConsumptionActions__assetscount">{getNumberInTwoDigits(totalAssetCount)}</span> : ''}
          </>
        }
      >
        <div className="caseConsumptionActions__briefcasePopup">
          <div className="caseConsumptionActions__briefcasePopup__heading">{KNOWLEDGE_ASSETS}<span> - {getNumberInTwoDigits(totalAssetCount)}</span></div>
          <ul>
            {
              !!caseVignettes?.length &&
              <li>
                <div>{CASE_VIGNETTES}</div>
                <div className="caseConsumptionActions__briefcasePopup__childcount">{getNumberInTwoDigits(caseVignettes?.length)}</div>
              </li>
            }
            {
              !!knowledgeAssets?.length &&
              <li>
                <div>{CASE_MATERIALS}</div>
                <div>{getNumberInTwoDigits(knowledgeAssets?.length)}</div>
              </li>
            }
          </ul>
        </div>
      </InfoPopup>}

      {showEdit && <InfoPopup
        tooltipPosition="bottom"
        tooltipId="case_more_options"
        title={MORE_OPTIONS}
        popupVerticalPosition="bottom"
        popupHorizontalPosition="left"
        withTooltip={true}
        delayHide={0}
        trigger={
          <Image className="caseConsumptionActions__dots" src={dotsImage} />
        }
      >
        <div className="caseConsumptionActions__popup">
          <ul>
            <li className="caseConsumptionActions__action">
              <Link
                onClick={() => caseContactListingCallback({ identifier: CP_EDIT_IDENTIFIER })}
                to={{ pathname: CP_METADATA(CP_STEP1, caseGuid) }}
              >
                {EDIT_PAGE}
              </Link>
            </li>
          </ul>
        </div>
      </InfoPopup>
      }
    </div>
  )
};

CaseConsumptionActions.propTypes = {
  projectId: PropTypes.string.isRequired,
  assets: PropTypes.array,
  billingInfo: PropTypes.object,
  showBilling: PropTypes.bool,
  showAssets: PropTypes.bool,
  showAssetsv1: PropTypes.bool,
  showEdit: PropTypes.bool,
  caseGuid: PropTypes.string,
  showBudget: PropTypes.bool,
};

CaseConsumptionActions.defaultProps = {
  assets: [],
  billingInfo: {},
  showBilling: false,
  showAssets: true,
  showAssetsv1: true,
  showEdit: false,
  caseGuid: '',
  showBudget: false,
};