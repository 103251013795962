import { DOCVIZ } from "redux/constants";

export const getDocvizMetadataPending = (id) => ({
  type: DOCVIZ.GET_DOCVIZ_METADATA_PENDING,
  payload: {
    id,
    data: ''
  }
});

export const getDocvizMetadataMultiPending = (ids, isShowMoreClicked) => ({
  type: DOCVIZ.GET_DOCVIZ_METADATA_MULTI_PENDING,
  payload: {
    ids,
    data: '',
    isShowMoreClicked
  }
});

export const getDocvizMetadataSuccess = (id, data) => ({
  type: DOCVIZ.GET_DOCVIZ_METADATA_SUCCESS,
  payload: {
    id,
    data
  }
});

export const getDocvizMetadataMultiSuccess = (ids, data, isShowMoreClicked) => ({
  type: DOCVIZ.GET_DOCVIZ_METADATA_MULTI_SUCCESS,
  payload: {
    ids,
    data,
    isShowMoreClicked
  }
});

export const getDocvizMetadataFailure = (id, error) => ({
  type: DOCVIZ.GET_DOCVIZ_METADATA_FAILURE,
  payload: {
    id,
    data: error.toString()
  }
});

export const getDocvizMetadataMultiFailure = (ids, error) => ({
  type: DOCVIZ.GET_DOCVIZ_METADATA_MULTI_FAILURE,
  payload: {
    ids,
    data: error.toString()
  }
});

export const resetDocvizMetadata = () => ({
  type: DOCVIZ.RESET_DOCVIZ_METADATA,
});