import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { Checkbox } from 'semantic-ui-react';
import "./ToggleSwitch.scss";

const ToggleSwitch = ({ onToggle, optionalProps }) => {
	const [toggleSwitch, setToggleSwitch] = useState(false);

	useEffect(() => {
		if (onToggle)
			onToggle(toggleSwitch);
	}, [toggleSwitch])

	const handleChange = () => {
		setToggleSwitch(!toggleSwitch);
	}

	return (
		<Checkbox {...optionalProps} slider className="toggleSwitch" onChange={handleChange} />
	)
}

ToggleSwitch.propTypes = {
	onToggle: PropTypes.func,
	optionalProps: PropTypes.object,
};

ToggleSwitch.defaultProps = {
	onToggle: () => { },
	optionalProps: { toggle: true }
};

export default ToggleSwitch;