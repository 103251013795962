import { put, call, takeLatest, delay } from 'redux-saga/effects';
import { DOCVIZ } from "redux/constants";
import CONFIG from "config";
import { doAxiosRequest } from "config-axios";
import authService from 'redux/auth';
import {
  getDocvizMetadataPending,
  getDocvizMetadataSuccess,
  getDocvizMetadataFailure,
  getDocvizMetadataMultiPending,
  getDocvizMetadataMultiSuccess,
  getDocvizMetadataMultiFailure
} from "./docviz.actions";
import { moduleFailure } from "redux/global.action.js";
import { downloadFileFromBlobResoponse } from 'utils/helpers/helpers';

export function* getDocvizMetadata(action) {
  const { payload: { id, delayCall, isInPreviewMode } } = action;
  try {
    yield put(getDocvizMetadataPending(id));
    const uri = CONFIG.API_URL.DOCVIZ_METADATA(id, isInPreviewMode);

    if (delayCall) {
      yield delay(2000);
    }
    const response = yield call(doAxiosRequest, {
      method: 'get',
      endpoint: uri,
      headers: {
        'x-api-key': CONFIG.XA
      }
    });

    yield put(getDocvizMetadataSuccess(id, response.data));
  } catch (error) {
    yield put(getDocvizMetadataFailure(id, error));
  }
}


export function* getDocvizMetadataMulti(action) {
  const { payload: { ids, delayCall, isInPreviewMode, isShowMoreClicked } } = action;
  try {
    yield put(getDocvizMetadataMultiPending(ids, isShowMoreClicked));
    const uri = CONFIG.API_URL.DOCVIZ_METADATA_MULTI_GUIDS(ids.join('&ids='), isInPreviewMode);

    if (delayCall) {
      yield delay(2000);
    }
    const response = yield call(doAxiosRequest, {
      method: 'get',
      endpoint: uri,
      headers: {
        'x-api-key': process.env.REACT_APP_SC_X_API_KEY
      }
    });

    yield put(getDocvizMetadataMultiSuccess(ids, response.data, isShowMoreClicked));
  } catch (error) {
    yield put(getDocvizMetadataMultiFailure(ids, error));
  }
}


export function* downloadSelectedSlides(action) {
  const { API_URL, XA, DOWNLOAD_APPLICATION_NAME: { KNOWLEDGE_PAGE_APP_NAME } } = CONFIG;
  try {
    yield put({ type: DOCVIZ.DOWNLOAD_CLIPPED_SLIDES_STARTED, payload: { downloadStatus: 'started' } });
    const { payload: { pages, documentId, appName = KNOWLEDGE_PAGE_APP_NAME } } = action;
    const axiosConfig = {
      method: 'post',
      endpoint: API_URL.CLIP_FILE(appName),
      headers: {
        'x-api-key': XA
      }
    };
    const metadata = yield call(doAxiosRequest, { ...axiosConfig, params: { pages, documentId } })
    yield put({ type: DOCVIZ.GENERATING_CLIPPED_SLIDES, payload: { downloadStatus: 'generating', clippingId: metadata.clippingId } });
    yield* pollClippingData(metadata, action.payload);
  } catch (error) {
    yield put({ type: DOCVIZ.CLIPPED_FILE_STATUS_ERROR, downloadStatus: 'completed', payload: { percentage: "100%" } });
  }
}

export function* pollClippingData(metadata, payload) {
  const { clippingId: id } = metadata;
  const { doc: { fileName }, appName } = payload;
  let complete = false;
  try {
    while (!complete) {
      yield delay(4000);
      const axiosConfig = {
        method: 'get',
        endpoint: CONFIG.API_URL.GET_CLIPPING_STATUS(appName, id),
        headers: {
          'x-api-key': CONFIG.XA
        }
      };
      const response = yield call(doAxiosRequest, axiosConfig);
      const { clippedFile, percentageCompleted } = response;
      if (response?.status === "COMPLETED") {
        complete = true;
        const accessToken = authService.getAccessToken();
        yield put({ type: DOCVIZ.DOWNLOAD_CLIPPED_SLIDES_COMPLETE, downloadStatus: 'completed', payload: { clippingId: id, downloadLink: clippedFile, percentage: percentageCompleted } });
        const clippedResponse = yield call(doAxiosRequest, {
          headers: {
            'authorization': `Bearer ${accessToken}`,
            'x-api-key': process.env.REACT_APP_CLIPPING_API_KEY
          },
          method: 'get',
          endpoint: clippedFile,
          responsetype: 'blob'
        });
        downloadFileFromBlobResoponse(clippedResponse, fileName);
      } else {
        yield put({ type: DOCVIZ.DOWNLOAD_CLIPPED_SLIDES_INPROGRESS, downloadStatus: 'inprogress', payload: { clippingId: id, downloadLink: clippedFile } });
      }
    }
  } catch (e) {
    yield put({ type: DOCVIZ.CLIPPED_FILE_STATUS_ERROR, downloadStatus: 'completed', payload: { percentage: "100%" } });
  }
}

export default function* docvizSaga() {
  try {
    yield takeLatest(DOCVIZ.GET_DOCVIZ_METADATA, getDocvizMetadata);
    yield takeLatest(DOCVIZ.GET_DOCVIZ_METADATA_MULTI, getDocvizMetadataMulti);
    yield takeLatest(DOCVIZ.DOWNLOAD_SELECTED_SLIDES, downloadSelectedSlides);
  } catch (error) {
    yield put(moduleFailure(error, 'docvizSaga'));
  }
}