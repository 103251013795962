// Editor Components
import { ContentState, EditorState } from "draft-js";
// Convertor
import htmlToDraft from "html-to-draftjs";

export const getEditorState = (initialState) => {
    if (initialState) {
        return EditorState.createWithContent(
            ContentState.createFromBlockArray(
                htmlToDraft(initialState).contentBlocks
            )
        )
    } else {
        return EditorState.createEmpty()
    }
}

export const getDescriptionCharLength = (initialState) => {
    try {
        let editorState = getEditorState(initialState);
        return editorState.getCurrentContent().getPlainText("").length;
    } catch (error) {
        console.log('error ', error)
    }
}