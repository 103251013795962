import React from "react";
import PropTypes from "prop-types";

//components
import { Pill } from "components/shared/Pill";
import Workflow from "components/shared/Workflow";
import { BreadCrumb } from "components/BreadCrumb";
import { ContributeKnwldgBtn } from "components/shared/ContributeKnwldgBtn";

import "./PageHeader.scss";

export const PageHeader = ({ flags, data }) => {
  const {
    pillFlag,
    workflowFlag,
    additionalInfoFlag,
    breadCrumbFlag,
    bannerFlag,
    contributeKnwldgFlag,
    homeIconFlag,
    showSanitizationBanner
  } = flags;
  const { contentStage, activeStep, stepper, productName, pageTitle, sanitizationTitle } = data;
  return (
    <div className={`page-header ${bannerFlag ? "banner " : ""}`}>
      <div className="layout__fix">
        <div className="layout__fix--inner1">
          {(additionalInfoFlag || contributeKnwldgFlag || pillFlag) &&
            <div className="page-header__right">
              {additionalInfoFlag && additionalInfoFlag()}
              {contributeKnwldgFlag && <ContributeKnwldgBtn />}
            </div>
          }
          {(breadCrumbFlag || pillFlag) &&
            <div className="page-header__left">
              {breadCrumbFlag && <BreadCrumb productName={productName} pageTitle={pageTitle} homeIconFlag={homeIconFlag} />}
              {pillFlag && (
                <Pill defaultText={contentStage} active />
              )}
            </div>
          }
        </div>

        {workflowFlag && (
          <div className="page-header__bottom">
            <Workflow
              productName={productName}
              activeStep={activeStep}
              stepper={stepper}
              sanitizationTitle={sanitizationTitle}
              showSanitizationBanner={showSanitizationBanner}
            />
          </div>
        )}
      </div>
    </div>
  );
};

PageHeader.defaultProps = {
  additionalInfoFlag: () => null
}

PropTypes.propTypes = {
  flags: PropTypes.object,
  data: PropTypes.object,
};