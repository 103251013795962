// constant
import { CASE_CODE } from "redux/constants";

// local variable
export const name = "caseCode";

// initial state
export const initialState = {
    errorStatus: "",
    setData: {},
    loading: false,
};

export const selectors = {
    getCaseData: state => state[name].setData,
    getErrorStatus: state => state[name].errorStatus,
    getLoading: state => state[name].loading,
};

export function reducer(state = initialState, action) {
    switch (action.type) {
        case CASE_CODE.CASE_CODE_PENDING:
            return {
                ...state,
                data: action.payload,
                loading: true,
            };
        case CASE_CODE.CASE_CODE_FAILURE:
            return {
                ...state,
                errorStatus: action.payload,
                loading: false,
            };
        case CASE_CODE.CASE_CODE_WITH_NO_CONTENT:
            return {
                ...state,
                errorStatus: action.payload,
                loading: false,
            };
        case CASE_CODE.CASE_CODE_SUCCESS:
            return {
                ...state,
                setData: action.payload,
                errorStatus: "",
                loading: false,
            };
        case CASE_CODE.CASE_CODE_ERROR_RESET:
            return {
                ...state,
                errorStatus: "",
                loading: false,
            };
        default:
            return state;
    }
}