const CONFIG = {
  style: { top: "10px", position: "absolute", right: "5px" },
  buttonText: { add: "Add", delete: "Delete" },
  defaultValues: { name: "", value: "" },
  defaultValidation: { name: true, existingName: true, value: true, urlCustom: true, urlCustomString: true },
  errorMsg: {
    default: "DefaultState Not Greater Than Limit",
    name: "Please enter URL name",
    existingName: "Unable to add the entry, as same URL Name already exists.",
    url: ["Please enter URL", "Please enter the valid URL"]
  },
  inputType: "text",
  placeHolder: { name: "Enter URL Name", url: "Enter URL" },
  validate: (str, regexstr) => regexstr.test(str),
  validateStringsPresence: (str, urlSanitizationArr) => urlSanitizationArr.some(link => str.toLowerCase().includes(link.toLowerCase()))
};
// export default Config
export default CONFIG;
