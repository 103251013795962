///////////// Importing Dependencies ///////////
import React from 'react';
import PropTypes from "prop-types";

//////////// Importing Components //////////
import Modal from "components/shared/Modal";
import Icon from "components/shared/Icon";
import { Button } from "components/shared/Button";
import { TextAreaCounter } from "components/TextAreaCounter/TextAreaCounter";
import ToggleSwitch from "components/shared/ToggleSwitch/ToggleSwitch";

//////////// Importing Images /////////
import warningIcon from "assets/images/warning-icon.svg";
import warningIconRedTheme from "assets/images/warning-icon-red-theme.svg";
import CopyIcon from "assets/images/copy.svg";

//////////// Importing Constant Files /////////
import GLOBAL_CONFIG from "config";
import LABELS from "labels";

//////////// Importing scss Files /////////
import "./WarningModal.scss"

const RenderIcon = ({ iconName }) => {
    switch (iconName) {
        case 'copy':
            return <img className="copyicon" src={CopyIcon} alt="warning icon" />;
        case 'check':
            return <Icon name="check circle" className="check-icon" />;
        case 'warning':
            return <img src={warningIconRedTheme} alt="warning icon" />;
        default:
            return <img src={warningIcon} alt="warning icon" />;
    }
}

export const WarningModal = ({
    heading,
    button1: {
        text: button1text,
        clickHandler: button1Click,
        colorClass: button1Class,
        customClass: customBtnClass
    },
    button2: {
        text: button2text,
        clickHandler: button2Click,
        colorClass: button2Class
    },
    button3: {
        text: button3text,
        clickHandler: button3Click,
        colorClass: button3Class
    },
    isOpen,
    iconName,
    onRequestClose,
    headingAlign,
    customWidth,
    overlayClassName,
    ctasAlign,
    commentTextField,
    setCommentCallback,
    previewBarCallback,
    showPreviewBar,
    KPWarningModalBodySection,
    kbeWarningCustomCls
}) => {
    const { MANDATORY_COMMENT: { INPUT_CHARLIMIT_MAX } } = GLOBAL_CONFIG;
    const { WORK_SPACE: { WARNING_MODAL_INPUT_PLACEHOLDER }, KP: { REVIEWER_CONTENT } } = LABELS;
    const customStyles = {
        content: {
            width: customWidth ? customWidth : 490
        },
    };

    return (
        <Modal isOpen={isOpen} className={`kbeWarning ${showPreviewBar ? "kbeWarning__cmp" : ""} ${kbeWarningCustomCls} `} onRequestClose={onRequestClose} style={customStyles} overlayClassName={`ReactModal__Overlay ${overlayClassName ? overlayClassName : ""}`} >
            {showPreviewBar && <div className="kbeWarning__toggleswitch">
                <ToggleSwitch onToggle={previewBarCallback} />
                <span>{REVIEWER_CONTENT}</span>
            </div>}
            <div className={`kbeWarning__title  ${headingAlign && headingAlign === "left" ? "kbeWarning__title--left " : ""}`}>
                <RenderIcon iconName={iconName} />
                <h5 className="kbeWarning__heading ">{heading}</h5>
            </div>
            {
                commentTextField && <div className="kbeWarning__comment">
                    <TextAreaCounter
                        maxLength={INPUT_CHARLIMIT_MAX}
                        placeHolder={WARNING_MODAL_INPUT_PLACEHOLDER}
                        handleChangeCallback={(val) => setCommentCallback('comment', val)}
                    />
                </div>
            }
            {/** By default the vlaue of KPWarningModalBodySection is null, if we wants to pass any custom JSX then we can pass inside this variable */}
            {KPWarningModalBodySection}

            <div className={`kbeWarning__ctas ${ctasAlign && ctasAlign === "corner" ? "kbeWarning__ctas--corner " : ""}`}>
                {button1text && <Button className={`kbeWarning__cta ${button1Class ? button1Class : " black"} ${customBtnClass} `} onClick={button1Click}>{button1text}</Button>}
                {button2text && <Button className={`kbeWarning__cta ${button2Class ? button2Class : " green"} `} onClick={button2Click}>{button2text}</Button>}
                {button3text && <Button className={`kbeWarning__cta ${button3Class ? button3Class : " green"} `} onClick={button3Click}>{button3text}</Button>}
            </div>
        </Modal >
    )
};

WarningModal.propTypes = {
    heading: PropTypes.string.isRequired,
    isOpen: PropTypes.bool,
    onRequestClose: PropTypes.func,
    setCommentCallback: PropTypes.func,
    commentTextField: PropTypes.bool,
    button1: PropTypes.object,
    button2: PropTypes.object
};

WarningModal.defaultProps = {
    isOpen: false,
    commentTextField: false,
    setCommentCallback: () => { },
    iconName: 'warning',
    button1: {
        text: false,
        clickHandler: () => { },
        colorClass: "black",
        customClass: ''
    },
    button2: {
        text: false,
        clickHandler: () => { },
        colorClass: "green"
    },
    button3: {
        text: false,
        clickHandler: () => { },
        colorClass: "green"
    },
    KPWarningModalBodySection: null,
    kbeWarningCustomCls: ''
};