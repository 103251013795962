// constant
import { POLYHIERACHY } from "redux/constants";

// initial state
export const initialState = {
    loading: false,
    error: false,
    polyhierarchyData: []
};

// local variable
export const name = "polyhierarchy";

// reducer selectors
export const selectors = {
    getLoading: state => state[name].loading,
    getError: state => state[name].error,
    getpolyhierarchyData: state => state[name].polyhierarchyData || [],
};

// reducer
export function reducer(state = initialState, action) {
    switch (action.type) {
        case POLYHIERACHY.POLYHIERARCHY_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case POLYHIERACHY.POLYHIERARCHY_PENDING:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case POLYHIERACHY.POLYHIERARCHY_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                polyhierarchyData: action.payload
            };
        default:
            return state;
    }
}
