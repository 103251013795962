import React from "react";

import CONFIG from "config";
import LABELS from "labels";

import Image from "components/shared/Image";
import DownloadIcon from 'assets/images/download.svg';

import { showDownloadIcon } from "utils/helpers/helpers";

//shared component
import { RestrictedDownloadIcon } from "components/shared/RestrictedDownloadIcon";

import "./ConditionalDownloadIcon.scss";

export const ConditionalDownloadIcon = ({
  title,
  item,
  isPreviewDownloadEntitlement,
  isPreviewDownloadREntitlement
}) => {
  const {
    KPCONSUMPTIONGROUP: {
      KP_RESTRICTEDTEXT
    }
  } = LABELS;
  const {
    CONSUMPTION_MAIL: {
      EMAIL,
      SUBJECT,
      LABELTEXT,
      BODY
    },
    UI_URL: {
      KP_CONSUMPTION,
    }
  } = CONFIG;
  const {
    id,
    isRestricted,
    attachment
  } = item;
  const downloadIconVisible = showDownloadIcon(isPreviewDownloadEntitlement, isPreviewDownloadREntitlement, isRestricted);
  const previewUrl = window.location.host + KP_CONSUMPTION(id);
  const mailto = `mailto:${EMAIL}?subject=${SUBJECT}&body=${BODY} ${attachment?.fileName} - ${previewUrl}`;

  return (
    isPreviewDownloadEntitlement && downloadIconVisible ?
      <Image src={DownloadIcon} alt={title} />
      :
      <RestrictedDownloadIcon
        restrictedText={KP_RESTRICTEDTEXT}
        mailToText={LABELTEXT}
        toolTipPosition="left"
        mailTo={mailto}
      />
  )
};
