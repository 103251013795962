// Constant
import { KNOWLEDGEOBJECT_VERSIONING } from "redux/constants";

export const deleteKnowledgeAssetPending = (knowledgeId) => ({
    type: KNOWLEDGEOBJECT_VERSIONING.DELETE_KNOWLEDGE_ASSET_PENDING,
    payload: {
        knowledgeId,
        res: ''
    }
});

export const deleteKnowledgeAssetSuccess = (knowledgeId, res) => ({
    type: KNOWLEDGEOBJECT_VERSIONING.DELETE_KNOWLEDGE_ASSET_SUCCESS,
    payload: {
        knowledgeId,
        res: res.value
    }
});

export const deleteKnowledgeAssetFailure = (knowledgeId, error) => ({
    type: KNOWLEDGEOBJECT_VERSIONING.DELETE_KNOWLEDGE_ASSET_FAILURE,
    payload: {
        knowledgeId,
        res: error.toString()
    }
});
export const unpublishKnowledgeAssetPending = (knowledgeId, hideloader) => ({
    type: KNOWLEDGEOBJECT_VERSIONING.UNPUBLISH_KNOWLEDGE_ASSET_PENDING,
    payload: {
        knowledgeId: knowledgeId,
        hideloader: hideloader,
        res: ''
    }
});

export const unpublishKnowledgeAssetSuccess = (knowledgeId, hideloader, res) => ({
    type: KNOWLEDGEOBJECT_VERSIONING.UNPUBLISH_KNOWLEDGE_ASSET_SUCCESS,
    payload: {
        knowledgeId: knowledgeId,
        hideloader: hideloader,
        res: res.value
    }
});

export const unpublishKnowledgeAssetFailure = (error) => ({
    type: KNOWLEDGEOBJECT_VERSIONING.UNPUBLISH_KNOWLEDGE_ASSET_FAILURE,
    payload: {
        res: error.toString()
    }
});

export const archiveKnowledgeAssetPending = (knowledgeId, hideloader) => ({
    type: KNOWLEDGEOBJECT_VERSIONING.ARCHIVE_KNOWLEDGE_ASSET_PENDING,
    payload: {
        knowledgeId: knowledgeId,
        hideloader: hideloader,
        res: ''
    }
});

export const archiveKnowledgeAssetSuccess = (knowledgeId, hideloader, res) => ({
    type: KNOWLEDGEOBJECT_VERSIONING.ARCHIVE_KNOWLEDGE_ASSET_SUCCESS,
    payload: {
        knowledgeId: knowledgeId,
        hideloader: hideloader,
        res: res.value
    }
});

export const archiveKnowledgeAssetFailure = (error) => ({
    type: KNOWLEDGEOBJECT_VERSIONING.ARCHIVE_KNOWLEDGE_ASSET_FAILURE,
    payload: {
        res: error.toString()
    }
});

export const cloneKnowledgeAsset = (id, status, isContribution) => ({
    type: KNOWLEDGEOBJECT_VERSIONING.CLONE_KNOWLEDGE_ASSET,
    payload: {
        id,
        status,
        isContribution
    }
});

export const cloneKnowledgeAssetPending = (id) => ({
    type: KNOWLEDGEOBJECT_VERSIONING.CLONE_KNOWLEDGE_ASSET_PENDING,
    payload: {
        id
    }
});

export const cloneKnowledgeAssetSuccess = (data) => ({
    type: KNOWLEDGEOBJECT_VERSIONING.CLONE_KNOWLEDGE_ASSET_SUCCESS,
    payload: {
        ...data
    }
});

export const cloneKnowledgeAssetFailure = (data) => ({
    type: KNOWLEDGEOBJECT_VERSIONING.CLONE_KNOWLEDGE_ASSET_FAILURE,
    payload: {
        ...data
    }
});
