// Config
import LABELS from "labels";
import GLOBAL_CONFIG from "config";
import CONSTANTS from "globalConstants";
import infoIIcon from "assets/images/infoIIcon/info.svg";

const { /*STEP2: { LINKING_VALIDATION_OWNER },*/KP: { NOTIFICATION_MESSAGE, STEP1: { UNIQUE_TITLE_ERROR_MSG } }, CREATION: { TITLE_MIN_WITH_WORDS_LENGTH_MESSAGE } } = LABELS;
const { NEXT, BACK, PUBLISH, SAVEASDRAFT, PREVIEW_PAGE, SEND_REVIEW, CLONE_CONTENT } = LABELS.BUTTONS;
const { KP_LIMITS: { KPAGE_MIN_TITLE_CHARLIMIT, KPAGE_TITLE_CHARLIMIT, MULTIPLE_TOPIC_PAGES_MAXLIMIT, KPAGE_MIN_DESCRIPTION_CHARLIMIT, SUBJECT_TAGS_MAXLIMIT }, AUTO_TAG_TYPE_CONFIG_OBJ } = GLOBAL_CONFIG;

const CONFIG = {
  style: { top: "16px", position: "absolute", right: "20px" },
  footerWorkflow: [
    {
      align: "left",
      icon: "arrow left",
      iconAlign: "pre",
      id: "btn-back",
      classname: "white",
      text: BACK,
      outFilled: true,
      link: "",
      target: false,
      visible: true,
      isClick: true,
      children: [],
    },
    {
      align: "right",
      children: [
        {
          order: 2,
          text: SAVEASDRAFT,
          id: "btn-saveasdraft",
          outFilled: true,
          target: true,
          classname: "white knowledgepage__draftbtn",
          visible: false,
          disabled: true,
          isClick: true,
        },
        {
          type: "button",
          order: 3,
          id: "btn-next",
          icon: "arrow right",
          iconAlign: "post",
          text: NEXT,
          classname: "green",
          disabled: true,
          visible: true,
          isClick: true,
        },
        {
          order: 4,
          id: "btn-previewpage",
          text: PREVIEW_PAGE,
          classname: "white",
          disabled: false,
          visible: false,
          isClick: true,
        },
        {
          type: "button",
          order: 5,
          id: "btn-publish",
          text: PUBLISH,
          classname: "green",
          disabled: false,
          visible: false,
          isClick: true,
        },
        {
          type: "submit",
          order: 6,
          id: "btn-review",
          text: SEND_REVIEW,
          classname: "green",
          disabled: false,
          visible: false,
          isClick: true,
        },
        {
          order: 1,
          text: CLONE_CONTENT,
          id: "btn-clone",
          icon: "clone outline",
          iconAlign: "pre",
          outFilled: true,
          target: true,
          classname: "white knowledgepage__clonebtn",
          visible: true,
          disabled: true,
          isClick: true,
        },
      ],
    },
  ],
  validationDictionary: {
    step3: {
      officeLocation: {
        onEmpty: {
          message: NOTIFICATION_MESSAGE
        },
        type: "array"
      },
      regions: {
        onEmpty: {
          message: NOTIFICATION_MESSAGE
        },
        type: "object"
      },
      internalOfficeLocation: {
        onEmpty: {
          message: NOTIFICATION_MESSAGE
        },
        type: "object"
      }
    },
    step2: {
      mdpName: {
        onEmpty: {
          message: NOTIFICATION_MESSAGE
        },
        isKeyDependent: {
          value: "isRelatedToCase",
          type: "array"
        },
      },
      // originalRequestor: {
      //   onEmpty: {
      //     message: NOTIFICATION_MESSAGE
      //   },
      //   type: "array"
      // },
      authors: {
        onEmpty: {
          message: NOTIFICATION_MESSAGE
        },
        type: "array",
      }
    },
    step1: {
      knowledgeType: {
        onEmpty: {
          message: NOTIFICATION_MESSAGE
        },
        type: "object"
      },
      title: {
        onEmpty: {
          message: NOTIFICATION_MESSAGE
        },
        minLengthCheck: {
          value: KPAGE_MIN_TITLE_CHARLIMIT,
        },
        numberOfWordsCheck: {
          value: 4,
          message: TITLE_MIN_WITH_WORDS_LENGTH_MESSAGE
        },
        maxLengthCheck: {
          value: KPAGE_TITLE_CHARLIMIT,
        },
        uniqueTitleCheck: {
          message: UNIQUE_TITLE_ERROR_MSG,
        },
        type: "string"
      },
      description: {
        onEmpty: {
          message: NOTIFICATION_MESSAGE
        },
        minLengthCheck: {
          value: KPAGE_MIN_DESCRIPTION_CHARLIMIT,
        },
        type: "richHTML"
      },
      file: {
        onEmpty: {
          message: NOTIFICATION_MESSAGE
        },
        type: "file",
      },
      isMdpApprovalRequired: {
        onEmpty: {
          message: NOTIFICATION_MESSAGE
        },
        type: "string"
      },
      caseNumbers: {
        onEmpty: {
          message: NOTIFICATION_MESSAGE
        },
        isKeyDependent: {
          value: "isRelatedToCase",
          type: "string"
        },
      },
      materialUrl: {
        onEmpty: {
          message: NOTIFICATION_MESSAGE
        },
        type: "object",
      },
      kpOwner: {
        type: "array",
        onEmpty: {
          message: NOTIFICATION_MESSAGE
        },
      },
      practiseAreaTags: {
        onEmpty: {
          message: NOTIFICATION_MESSAGE
        },
        maxLengthCheckOnTotal: {
          value: MULTIPLE_TOPIC_PAGES_MAXLIMIT,
        },
        type: "multipleArray"
      },
      paTagsOwner: {
        onEmpty: {
          message: NOTIFICATION_MESSAGE
        },
        type: "boolean"
      },
      searchDiscoveryTags: {
        onEmpty: {
          message: NOTIFICATION_MESSAGE
        },
        maxLengthCheckOnTotal: {
          value: SUBJECT_TAGS_MAXLIMIT,
        },
        type: "array"
      },
    },
  }
};

export const subjectSuggestion = [
  {
    id: "8c2039d2-81a2-40ad-8a49-da7af2fc2065",
    name: "CFO Excellence"
  },
  {
    id: "4cfc9bd3-34f0-4bfe-83a5-95e344cce3f1",
    name: "Change and Program Management"
  },
  {
    id: "2ebba7cd-a3af-4ac2-9097-5acf1adfb766",
    name: "P-Core"
  },
  {
    id: "8c431764-836d-422e-afd2-afbb27939ac0",
    name: "Digital Change"
  },
  {
    id: "8eb734f6-5be9-49af-9bbe-488abbdf1bef",
    name: "Change Management"
  },
  {
    id: "172a846d-463f-413e-adf0-48faa1e692aa",
    name: "Change Journey"
  },
  {
    id: "c3a97a85-8d10-49d2-922c-d7aff96f6cfc",
    name: "BeSmart"
  },
  {
    id: "fd3386d7-ffb2-4bf9-8e1d-e2ba477b4a63",
    name: "Leader Journey"
  },
  {
    id: "9bb03b2b-4558-4cef-a127-32350c98368a",
    name: "People Journey"
  },
  {
    id: "55035b32-1e81-4554-ac50-66085469111c",
    name: "Program Journey"
  },
  {
    id: "7b6e6fe0-7aa9-46b5-8aaa-cac1097d4151",
    name: "Automotive and Mobility"
  },
  {
    id: "8681d82c-e2b6-43db-b169-c94a4ebdeddf",
    name: "Agile"
  },
  {
    id: "fcf6d597-2886-4bdc-aaf8-4d9d22b1892a",
    name: "Accounting and Finance"
  },
  {
    id: "65de9129-c786-43ba-8b08-c0d42837dc06",
    name: "Water"
  },
];

export const bstSuggestion = [
  {
    id: "2c7748ca-ad0a-4c4d-b4c2-4dbe3ac8b0ed",
    name: "Financial Institutions",
    type: "IPA",
    isExisting: false
  },
  {
    id: "4cfc9bd3-34f0-4bfe-83a5-95e344cce3f1",
    name: "Change and Program Management",
    type: "FPA",
    isExisting: true
  },
  {
    id: "2ebba7cd-a3af-4ac2-9097-5acf1adfb766",
    name: "P-Core",
    type: "IPA",
    isExisting: true
  },
  {
    id: "8c431764-836d-422e-afd2-afbb27939ac0",
    name: "Digital Change",
    type: "BCG_INTERNAL",
    isExisting: false
  }
];

export const getTaggedTaxonomyConfig = () => {
  let taggedTaxonomyConfig = [];
  try {
    const { AUTO_TAG_TYPE_CONFIG: { KP_GEO } } = CONSTANTS;
    const { tagName, tagToolTipMsg } = AUTO_TAG_TYPE_CONFIG_OBJ('KP', KP_GEO);
    taggedTaxonomyConfig = [{ infoIconKeyName: 'autoTagType', infoIconValueName: tagName, infoIcon: infoIIcon, infoIconToolTipMessage: tagToolTipMsg }]
  } catch (error) {
    console.log('Error in getTaggedTaxonomyConfig function ', error);
  }
  return taggedTaxonomyConfig;
}

// export default
export default CONFIG;
