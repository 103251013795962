import {
  ERROR,
  INFO,
  SUCCESS,
  WARNING,
  DISMISS_NOTIFICATIONS
} from 'redux/constants';

export const notifyError = (title, message) => ({
  type: ERROR,
  payload: { title, message }
});

export const notifyInfo = (title, message) => ({
  type: INFO,
  payload: { title, message }
});

export const notifySuccess = (title, message) => ({
  type: SUCCESS,
  payload: { title, message }
});

export const notifyWarning = (title, message) => ({
  type: WARNING,
  payload: { title, message }
});

export const dismissNotifications = () => ({
  type: DISMISS_NOTIFICATIONS
});
