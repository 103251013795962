// Analytics
import ANALYTICS from "utils/analytics/analytics";
import {
  SCREEN_NAMES,
  TRIGGERS,
  DTMRULE,
  PAGES,
  PRIMARY_CATEGORY
} from "utils/analytics/analytics_constants";
import LABELS from "labels";


export const onPageLoadAnalytics = (kbId,pageName, category,) => {
    ANALYTICS.page.initialize({pageName, category, trackPageLoad: true, pageId: {collection: { ID : kbId }}, isIdPresent: kbId ? true : false});
}

export const nextBtnAnalytics = (localMetaData, KBData) => {
    const topicPages = localMetaData?.topicPages;
    let topic_Id = "";
    let topicName = "";
    if (Array.isArray(topicPages) && topicPages.length > 0) {
    topic_Id = topicPages[0]?.topicId ?? "";
    topicName = topicPages[0]?.topicName ?? "";
    }

    let newAdobeData = {
    collection: {
        trigger: TRIGGERS.NEXT,
        ID: KBData.kbId,
        screenName: SCREEN_NAMES.ADDMETADATA,
    },
    suggestedTopicID: topic_Id,
    suggestedTopicTitle: topicName,
    };    
    ANALYTICS.kc.sendEventData(newAdobeData);
}

export const publishBtnAnalytics = (localMetaData) => {
        const topicPages = localMetaData?.topicPages;
        let topicId = "";
        let topicName = "";
        if (Array.isArray(topicPages) && topicPages.length > 0) {
        topicId = topicPages[0]?.topicId ?? "";
        topicName = topicPages[0]?.topicName ?? "";
        }

        let newAdobeData = {
        collection: {
            trigger: TRIGGERS.PUBLISH, //EVAR 109
            screenName: SCREEN_NAMES.SELECT_TAGS, //EVAR 112
        },
        suggestedTopicID: topicId, //EAVR18
        suggestedTopicTitle: topicName, //EVAR16
        };
        ANALYTICS.kc.sendEventData(newAdobeData);
}

export const backBtnAnalytics = (KBData, screen) => {
    const topicPages = KBData?.topicPages;
    let topicId = "", topicName = "";
    
    if (Array.isArray(topicPages) && topicPages.length > 0) {
        topicId = topicPages[0]?.topicId ?? "";
        topicName = topicPages[0]?.topicName ?? "";
    }

    let newAdobeData = {
        collection: {
          trigger: TRIGGERS.BACK, //evar 109
          screenName: screen, //evar 112
        },
        suggestedTopicID: topicId, //EVAR18
        suggestedTopicTitle: topicName, //EVAR16
      };
    ANALYTICS.kc.sendEventData(newAdobeData);  
}

export const btnPreviewBundleAnalytics = (localMetaData, KBData ,primaryCategory) => {
    const topicPages = KBData?.topicPages;
    let topicId = "", topicName = "";
    
    if (Array.isArray(topicPages) && topicPages.length > 0) {
        topicId = topicPages[0]?.topicId ?? "";
        topicName = topicPages[0]?.topicName ?? "";
    }
    let newAdobeData = {
        page: {
          category: {
            primaryCategory: primaryCategory, //eVar50
          }         
        },
        collection: {
          trigger: TRIGGERS.PREVIEW_BUNDLE,//evar 109          
          screenName: SCREEN_NAMES.SELECT_TAGS,//evar 112
        },
        suggestedTopicID: topicId,//EVAR18
        suggestedTopicTitle: topicName,//EVAR16
    };
    ANALYTICS.kc.sendEventData(newAdobeData);
}

export const kbCreatorAnalytics = (data) => {
    const { valueText, displayName, kbId, topicId } = data;
    ANALYTICS.kc.sendEventData({
      search: {
        term: valueText, //evar25
        typeHeadOptionText: displayName,
      },
      collection: {
        trigger: TRIGGERS.TYPEAHEAD_CREATOR, //evar109
        ID: kbId, //evar111
        screenName: SCREEN_NAMES.ADDMETADATA, //evar112
      },
      suggestedTopicID: topicId, //evar18
    }, DTMRULE.KB);
}

export const additionalContributorsAnalytics = (data) => { // event215
    const { valueText, displayName, kbId, topicId } = data;
        ANALYTICS.kc.sendEventData({
          search: {
            term: valueText, //evar25
            typeHeadOptionText: displayName,
          },
          collection: {
            trigger: TRIGGERS.TYPEAHEAD_CONTRIBUTORS, //evar109
            ID: kbId, //evar111
            screenName: SCREEN_NAMES.ADDMETADATA, //evar112
          },
          suggestedTopicID: topicId, //evar18          
        }, DTMRULE.KB);      
}

export const subjectTaxonomyModalActionsAnalytics = (triggerName, screenName, topicId, kbid, subId, typeAheadObj,pageName,category) => {

    let analyticsObj = {
      page: {
        pageInfo: {
          pageName
        },
        category: {
          primaryCategory: category,
        }
      },
      collection: {
        trigger: triggerName, //EVAR 109
        screenName: screenName, //EVAR 112
        ID: kbid, //EVAR 111
        subTaxonomyID: subId
      },
      suggestedTopicID: topicId
    };

    if(typeAheadObj) {
      const {
        searchTerm, selectedOptionValue, selectedOptionRank
      } = typeAheadObj;
      analyticsObj["search"] = {
        term: searchTerm,
        typeHeadOptionText: selectedOptionValue,
        typeHeadOptionRank: selectedOptionRank
      }
    }
    ANALYTICS.kc.sendEventData(analyticsObj, DTMRULE.KB);
  }

  export const knowledgeItemActionAnalytics = (localMetaData, trigger) => {
    const topicPages = localMetaData?.topicPages;
    let topicId = "";
    if (Array.isArray(topicPages) && topicPages.length > 0) {
      topicId = topicPages[0]?.topicId ?? "";
    }
    let aa_trigger = "";
    if (trigger === "delete") aa_trigger = TRIGGERS.DELETE;
    else if (trigger === "reorder") aa_trigger = TRIGGERS.REARRANGE;

    let newAdobeData = {
      collection: {
        trigger: aa_trigger,
        ID: localMetaData.kbId,
        screenName: SCREEN_NAMES.ADDITEMS_KP,
      },
      suggestedTopicID: topicId,
    };
    ANALYTICS.kc.sendEventData(newAdobeData);
  }