// Constant
import { CASE_HISTORY } from "redux/constants";

export const getCaseHistorySuccess = (payload) => ({
    type: CASE_HISTORY.GET_CASE_HISTORY_SUCCESS,
    payload
});
export const getCaseHistory = (CaseId, Count) => ({
    type: CASE_HISTORY.GET_CASE_HISTORY,
    payload: { CaseId, Count }

});

export const getCaseHistoryPending = () => ({
    type: CASE_HISTORY.GET_CASE_HISTORY_PENDING,
});

export const getCaseHistoryFailure = (error) => ({
    type: CASE_HISTORY.GET_CASE_HISTORY_FAILURE,
    payload: { error: error?.toString() }
});
