import React from 'react';
import PropTypes from 'prop-types';

// shared components
import { TabComponent } from 'components/shared/TabComponent';
import { AdditionalLinks } from 'components/AdditionalLinks';
import KPList from 'components/KPList/KPList';

// constants
import LABELS from 'labels';
import CONFIG from "config";

// styles
import './ConsumptionAssociations.scss';

const { DOWNLOAD_APPLICATION_NAME: { KNOWLEDGE_PAGE_APP_NAME } } = CONFIG;

export class ConsumptionAssociations extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
    };
  }


  setActiveTab = (activeIndex) => {
    this.setState({ activeTab: activeIndex });
  }

  tabClicked = (e, index) => {
    const { onTabClicked } = this.props;
    const {
      KPCONSUMPTIONGROUP: {
        LINKS,
        KNOWLEDGE_PAGES,
      }
    } = LABELS;
    if (!!onTabClicked) {
      const indexMap = [
        KNOWLEDGE_PAGES,
        LINKS,
      ];
      onTabClicked(indexMap[index]);
    }
  }

  render() {
    const {
      links,
      KPIds,
      isPreviewDownloadEntitlement,
      isPreviewDownloadREntitlement,
      kpCardClickCallback,
      kpDownloadCallback,
      onLinkClicked,
      handleDocvizPreview,
      totalKPs,
      showThumbsUpDown,
      thumbsUpDownAnalyticsCallback,
      downloadApplicationName,
      showKpGridView,
      handleViewAnalytics,
      getKPFile
    } = this.props;
    const {
      KPCONSUMPTIONGROUP: {
        LINKS,
        NO_LINKS_ASSOCIATED,
        KNOWLEDGE_PAGES,
        NO_PAGES_ASSOCIATED,
      }
    } = LABELS;
    const linksCount = links?.length ?? 0;
    const defaultActiveIndex = KPIds?.length || !linksCount ? 0 : 1;
    return (
      <div className="associationsWrapper margin_bottom--30">
        <TabComponent
          className="tabs__container associationsWrapper__tab-component consumptionTabContainer"
          defaultActiveIndex={defaultActiveIndex}
          getActiveIndex={this.setActiveTab}
          menu={{ secondary: true, pointing: true }}
          onTabChangeCallback={this.tabClicked}
          arrayOfPaneElements={[
            {
              className: 'item-style',
              heading: KNOWLEDGE_PAGES,
              count: totalKPs,
              hiddenClassName: totalKPs ? "" : "displaynone",
              component: <KPList
                isConsumptionView={true}
                emptyListHeading={NO_PAGES_ASSOCIATED}
                isPreviewDownloadEntitlement={isPreviewDownloadEntitlement}
                isPreviewDownloadREntitlement={isPreviewDownloadREntitlement}
                KPIds={KPIds}
                knowLedgeItemsInitialLimit={CONFIG.KNOWLEDGE_BUNDLE_LIMITS.KNOWLEDGE_ITEMS_INITIAL_DISPLAY}
                knowledgeItemHeight={CONFIG.KNOWLEDGE_BUNDLE_LIMITS.DRAGGABLE_KNOWLEDGE_ITEM_HEIGHT}
                kpCardClickCallback={kpCardClickCallback}
                kpDownloadCallback={kpDownloadCallback}
                handleDocvizPreview={handleDocvizPreview}
                showThumbsUpDown={showThumbsUpDown}
                thumbsUpDownAnalyticsCallback={thumbsUpDownAnalyticsCallback}
                downloadApplicationName={downloadApplicationName}
                showKpGridView={showKpGridView}
                handleViewAnalytics={handleViewAnalytics}
                getKPFile={getKPFile}
              />
            },
            {
              className: 'item-style',
              heading: LINKS,
              count: linksCount,
              hiddenClassName: linksCount ? "" : "displaynone",
              component: <AdditionalLinks onLinkClick={onLinkClicked} emptyListHeading={NO_LINKS_ASSOCIATED} links={links} />,
            }
          ]}
        />
      </div>
    );
  }
}

ConsumptionAssociations.prototypes = {
  links: PropTypes.arrayOf(PropTypes.object),
  KPIds: PropTypes.array,
  isPreviewDownloadEntitlement: PropTypes.bool,
  isPreviewDownloadREntitlement: PropTypes.bool,
  kpCardClickCallback: PropTypes.func,
  kpDownloadCallback: PropTypes.func,
  onTabClicked: PropTypes.func,
  onLinkClicked: PropTypes.func,
  handleDocvizPreview: PropTypes.func.isRequired,
  totalKPs: PropTypes.number,
  downloadApplicationName: PropTypes.string.isRequired,
  showKpGridView: PropTypes.bool,
  handleViewAnalytics: PropTypes.func
}

ConsumptionAssociations.defaultProps = {
  links: [],
  KPIds: [],
  isPreviewDownloadEntitlement: false,
  isPreviewDownloadREntitlement: false,
  kpCardClickCallback: () => { },
  kpDownloadCallback: () => { },
  onTabClicked: () => { },
  onLinkClicked: () => { },
  totalKPs: 0,
  downloadApplicationName: KNOWLEDGE_PAGE_APP_NAME,
  showKpGridView: false,
  handleViewAnalytics: () => { }
}
