import React from 'react';
import PropTypes from 'prop-types';
import LABELS from "labels";
import Image from "components/shared/Image";
import { getConcatNames } from 'utils/helpers/helpers';
import userIcon from "assets/images/user.svg";
import './AdminHistoryModalList.scss';

const AdminHistoryModalList = ({
  index,
  columns,
  data,
  people = [],
}) => {
  const {
    ADMIN_HISTORY_SECTION: {
      KEYS: {
        PERSON,
        TITLE,
      }
    }
  } = LABELS;
  return (
    <section key={index} className="adminhistorymodallist">
      {
        Object.keys(columns).map(field => {
          const { fixed, key } = columns[field];
          if (key === TITLE) {
            const { icon, text } = data[field] || {};
            return (
              <div key={field} className={`adminhistorymodal__column adminhistorymodallist__value adminhistorymodal__column--title ${fixed ? 'adminhistorymodal__column--fixed' : ''}`}>
                <Image className="adminhistorymodallist__fileicon" src={icon} alt="icon" />
                <div className="adminhistorymodallist__filetitle" title={text}>{text}</div>
              </div>
            );
          }
          if (key === PERSON) {
            const { picture, preferredLastName, preferredFirstName, businessTitle } = people[index] || {};
            return (
              <div key={field} className={`adminhistorymodal__column adminhistorymodallist__value adminhistorymodal__column--person ${fixed ? 'adminhistorymodal__column--fixed' : ''}`}>
                {people[index] &&
                  <>
                    <Image
                      className="adminhistorymodallist__personimage"
                      src={`${!!picture ? picture : userIcon}`}
                      alt="user"
                    />
                    <div className="adminhistorymodallist__person">
                      <span className="adminhistorymodallist__personname">{getConcatNames(preferredFirstName, preferredLastName)}</span>
                      <span className="adminhistorymodallist__personrole">{businessTitle}</span>
                    </div>
                  </>
                }
              </div>
            );
          }
          else {
            return <div key={field} className={`adminhistorymodal__column adminhistorymodallist__value${fixed ? ' adminhistorymodal__column--fixed' : ''}`}>{data[field]}</div>;
          }
        })
      }
    </section>
  );
};

AdminHistoryModalList.propTypes = {
  index: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
  columns: PropTypes.object.isRequired,
  people: PropTypes.arrayOf(PropTypes.object),
};

export default React.memo(AdminHistoryModalList);