import Hashes from "jshashes";
import CONFIG from "config";
import * as utils from '@kdshared/okta-utils';
import { removeFromLocalStorage } from 'utils/localStorage/localStorage';
import { isIE } from 'utils/mobile/mobile';
import Cookies from 'js-cookie';
import authService from 'redux/auth';

const {
  OKTA: {
    AUTH_ATTEMPTED,
    STK
  }
} = CONFIG;

export function getUserEmail() {
  try {
    const userEmail = utils.getUserClaims()?.email;
    if (!userEmail) {
      throw new Error('users email does not exist');
    }
    return userEmail;
  } catch (err) {
    let missingMsg = '';
    let stopChecking = false;
    try {
      const whatsErroring = Cookies.get('okta-user-claims');
      if (whatsErroring) {
        missingMsg = `got okta-user-claims from the cookie, is ${whatsErroring.length} long|`;
      } else {
        missingMsg = 'cannot get okta-user-claims from the cookie';
        stopChecking = true;
      }
    } catch (ex) {
      missingMsg = `error trying to get okta-user-claims from the cookie. ${ex.toString}|`;
      stopChecking = true;
    }

    if (!stopChecking) {
      try {
        const whatsErroring = JSON.parse(Cookies.get('okta-user-claims'));
        if (whatsErroring) {
          missingMsg += `idToken JSON parsed, has ${Object.keys(whatsErroring).length} keys|`;
        } else {
          missingMsg += 'idToken JSON did not parse|';
          stopChecking = true;
        }
      } catch (ex) {
        missingMsg += `error trying to idToken JSON parse. ${ex.toString}|`;
        stopChecking = true;
      }
    }

    if (!stopChecking) {
      try {
        const whatsErroring = JSON.parse(Cookies.get('okta-user-claims')).email;
        if (whatsErroring) {
          missingMsg += `email JSON parsed, has ${Object.keys(whatsErroring).length} keys|`;
        } else {
          missingMsg += 'email JSON did not parse|';
          stopChecking = true;
        }
      } catch (ex) {
        missingMsg += `error trying to claims JSON parse. ${ex.toString}|`;
        stopChecking = true;
      }
    }

    // eslint-disable-next-line no-console
    console.error('OKTA getUserEmail', missingMsg, err);

    if (!isAuthAttemptSet()) setAuthAttemptTime();

    // DD trying to fetch user email before login resulting in calling /implicit/callback twice
    if (window.location.href.indexOf('/implicit') === -1) {
      checkDurationAndReload(isIE() ? 25 : 15); // IE is too slow for 10s :(
    }
    return null;
  }
};
export const getStaffEmailID = () => {
  return new Hashes.SHA256().hex(getUserEmail()?.toLowerCase());
};
// retrieves claims of logged-in user to fetch HR ID
export async function getUserId() {
  const accessToken = await authService.getAccessToken();
  // const get me toke from local storgae
  const userId = !!accessToken ? JSON.parse(atob(accessToken.split('.')[1])).hrid : "0";
  return userId;
};

/**
 * ===============================================================================
 * OPERATIONS RELATED TO OKTA TOKENS
 * ===============================================================================
 */

export function clearAuthCache() {
  console.error('OKTA auth 001 - clearing cache');
  utils.clearOktaCookies(CONFIG.ENVIRONMENT_MAIN);
  removeFromLocalStorage(STK);
};
export function setAuthAttemptTime() {
  Cookies.set(AUTH_ATTEMPTED, new Date());
};
export function isAuthAttemptSet() {
  return Cookies.get(AUTH_ATTEMPTED) !== undefined;
};
export function getSecondsSinceLastAttempt() {
  const lastRetryTime = new Date(Cookies.get(AUTH_ATTEMPTED));
  const now = new Date();
  const durationInMs = now - lastRetryTime;

  return durationInMs / 1000;
};
export function checkDurationAndReload(durationInSeconds) {
  if (getSecondsSinceLastAttempt() > durationInSeconds) {
    clearAuthCache();
    setAuthAttemptTime();
    // eslint-disable-next-line no-console
    console.error('OKTA auth 002 - reloading page');
    if (window.location.href.indexOf('/error') > -1) {
      window.location.href = '/';
    } else {
      window.location.reload();
    }
  }
};
export function getOktaExpirations() {
  try {
    const accessToken = Cookies.get(CONFIG.OKTA.ACCESS_TOKEN_STORAGE);
    const idToken = Cookies.get(CONFIG.OKTA.ID_TOKEN_STORAGE);
    const oktaAccessExpiration = new Date(JSON.parse(accessToken).expiresAt * 1000).toString();
    const oktaIdExpiration = new Date(JSON.parse(idToken).expiresAt * 1000).toString();
    return [oktaAccessExpiration, oktaIdExpiration];
  } catch {
    return [null, null];
  }
};
export async function isUserAuthenticated() {
  try {
    const accessToken = await authService.getAccessToken();
    console.log('OKTA is user authenticated', !!accessToken);
    return !!accessToken;
  } catch {
    return false;
  }
}











