import React from 'react';
import PropTypes from 'prop-types';

//Components
import Image from "components/shared/Image";

// SCSS
import "./ListIconButton.scss";

const ListIconButton = ({ handleOnClick, isActive, btnName, activeBtnName, activeIcon, nonActiveIcon }) => {

    return (
        <div onClick={(e) => {
            e.stopPropagation();
            handleOnClick(activeBtnName)
        }}
            className={`listBtnView ${isActive ? 'listBtnView__active' : 'listBtnView__nonActive'}`}>
            <Image
                src={isActive ? activeIcon : nonActiveIcon}
                alt={'listIcon'}
            />
            <div className="viewIconTitle">{btnName}</div>
        </div>
    );
};


ListIconButton.propTypes = {
    handleOnClick: PropTypes.func,
    isActive: PropTypes.bool,
    btnName: PropTypes.string,
    activeBtnName: PropTypes.string,
    activeIcon: PropTypes.any,
    nonActiveIcon: PropTypes.any
};

ListIconButton.defaultProps = {
    handleOnClick: () => { },
    isActive: false,
    btnName: "",
    activeBtnName: "",
    activeIcon: "",
    nonActiveIcon: ""
};

export default ListIconButton;