import { put, call, takeLatest } from 'redux-saga/effects';
// Constant
import { CASE_METADATA } from "redux/constants";
// Config
import CONFIG from "config";
// Label
import LABELS from "labels";
// Axios Util
import { doAxiosRequest } from "config-axios";
import { moduleFailure } from "redux/global.action.js";
import { showPageLoader, hidePageLoader } from "redux/actions/global/global.actions";
import { notifySuccess } from "redux/actions/notification/notification_actions";
import { getAssociatedKPs } from "redux/actions/associatedKPs/associatedKPs.saga";
import { getCookie } from "utils/cookies/cookies";
import {
    getCaseTimeAndBillingPending,
    getCaseTimeAndBillingSuccess,
    getCaseTimeAndBillingFailure,
    getCaseSamPending,
    getCaseSamSuccess,
    getCaseSamFailure,
    getCaseMetadataPending,
    getCaseMetadataSuccess,
    getCaseMetadataFailure,
    getCaseCmpPending,
    getCaseCmpSuccess,
    getCaseCmpFailure,
    saveCaseMetadataPending,
    saveCaseMetadataSuccess,
    saveCaseMetadataFailure,
    getCaseFPADetailsPending,
    getCaseFPADetailsSuccess,
    getCaseFPADetailsFailure,
    searchCasesPending,
    searchCasesSuccess,
    searchCasesFailure,
    getCaseTypesPending,
    getCaseTypesSuccess,
    getCaseTypesFailure,
    getCaseOpenAndCloseDatePending,
    getCaseOpenAndCloseDateFailure,
    getCaseOpenAndCloseDateSuccess,
    getCaseConsumptionDataSuccess,
    getCaseConsumptionDataPending,
    getCaseConsumptionDataFailure,
} from "./caseMetadata.actions";
// helpers
import {
    getContentToDisplay,
    removeCurlyBrackets
} from "utils/helpers/helpers"

export function* getCaseTimeAndBillingData(action) {
    const { payload: { cid } } = action;
    try {
        yield put(getCaseTimeAndBillingPending());
        const {
            API_URL: {
                GET_CASE_T_AND_B
            },
            XA,
            STATUS_CODES: {
                SUCCESS
            },
            OKTA: {
                OKTA_PSID,
            },
        } = CONFIG;
        const res = yield call(doAxiosRequest, {
            method: 'get',
            endpoint: GET_CASE_T_AND_B(cid),
            headers: {
                'x-api-key': XA,
                'psId': getCookie(OKTA_PSID),
            }
        });
        if (res?.error?.code === SUCCESS) {
            yield put(getCaseTimeAndBillingSuccess(res));
        } else {
            const { CP_CONTRIBUTION: { T_AND_B_ERROR } } = LABELS;
            yield put(getCaseTimeAndBillingFailure(T_AND_B_ERROR));
        }
    } catch (error) {
        yield put(getCaseTimeAndBillingFailure(error));
    }
}

export function* getCaseSam(action) {
    const { payload: { cid } } = action;
    try {
        yield put(getCaseSamPending());
        const {
            API_URL: {
                GET_CASE_SAM
            },
            XA,
            STATUS_CODES: {
                SUCCESS
            },
            OKTA: {
                OKTA_PSID,
            },
        } = CONFIG;
        const res = yield call(doAxiosRequest, {
            method: 'get',
            endpoint: GET_CASE_SAM(cid),
            headers: {
                'x-api-key': XA,
                'psId': getCookie(OKTA_PSID),
            }
        });
        if (res?.error?.code === SUCCESS) {
            yield put(getCaseSamSuccess(res));
        } else {
            const { CP_CONTRIBUTION: { CASE_SAM_ERROR } } = LABELS;
            yield put(getCaseSamFailure(CASE_SAM_ERROR));
        }
    } catch (error) {
        yield put(getCaseSamFailure(error));
    }
}

export function* getCaseMetadata(action) {
    const { payload: { cid, isConsumption, hideLoading, isFcg } } = action;
    try {
        if (isConsumption) {
            yield put(getCaseConsumptionDataPending());
        }
        else if (!hideLoading) {
            yield put(getCaseMetadataPending());
        }
        const {
            API_URL: {
                GET_CASE_METADATA
            },
            XA,
            STATUS_CODES: {
                SUCCESS
            }
        } = CONFIG;
        const res = yield call(doAxiosRequest, {
            method: 'get',
            endpoint: GET_CASE_METADATA(cid),
            headers: {
                'x-api-key': XA,
            }
        });
        if (res?.error?.code === SUCCESS) {
            const { knowledgeAssets: materials = [], caseVignettesKnowledgeAssets: vignettes = [] } = res;
            const materialsToShow = getContentToDisplay(materials, []);
            const vignettesToShow = getContentToDisplay(vignettes, []);
            const KPIds = [...materialsToShow, ...vignettesToShow];
            if (!isFcg && KPIds?.length) {
                yield call(getAssociatedKPs, { payload: { KPIds } });
            }
            if (isConsumption) {
                yield put(getCaseConsumptionDataSuccess(res));
            }
            else {
                yield put(getCaseMetadataSuccess(res));
            }
        } else {
            const { CP_CONTRIBUTION: { T_AND_B_ERROR } } = LABELS;
            yield put(getCaseMetadataFailure(T_AND_B_ERROR));
            if (isConsumption) {
                yield put(getCaseConsumptionDataFailure(T_AND_B_ERROR));
            }
            else {
                yield put(getCaseMetadataFailure(T_AND_B_ERROR));
            }
        }
    } catch (error) {
        if (isConsumption) {
            yield put(getCaseConsumptionDataFailure(error));
        }
        else {
            yield put(getCaseMetadataFailure(error));
        }
    }
}

export function* getCaseCMPData(action) {
    const { payload: { cid } } = action;
    try {
        yield put(getCaseCmpPending());
        const {
            API_URL: {
                GET_CASE_CMP
            },
            XA,
            STATUS_CODES: {
                SUCCESS
            }
        } = CONFIG;
        const res = yield call(doAxiosRequest, {
            method: 'get',
            endpoint: GET_CASE_CMP(cid),
            headers: {
                'x-api-key': XA,
            }
        });
        if (res?.error?.code === SUCCESS || (res && !res.error)) {
            yield put(getCaseCmpSuccess(res));
        } else {
            const { CP_CONTRIBUTION: { T_AND_B_ERROR } } = LABELS;
            yield put(getCaseCmpFailure(T_AND_B_ERROR));
        }
    } catch (error) {
        yield put(getCaseCmpFailure(error));
    }
}

export function* caseAPIAndTBAPI(action) {
    const { payload: { cid } } = action;
    try {
        const {
            API_URL: {
                GET_CASE_METADATA
            },
            XA,
            STATUS_CODES: {
                SUCCESS
            }
        } = CONFIG;
        const res = yield call(doAxiosRequest, {
            method: 'get',
            endpoint: GET_CASE_METADATA(cid),
            headers: {
                'x-api-key': XA,
            }
        });
        if (res?.error?.code === SUCCESS) {
            yield put(getCaseMetadataSuccess(res));
            const guidList = res?.relatedCasesAndRelationshipType?.length ? res?.relatedCasesAndRelationshipType?.map(item => item.caseGuid) : [];
            if (guidList.length)
                yield getCaseOpenAndCloseDate({ payload: { cid: guidList } });
        } else {
            const { CP_CONTRIBUTION: { T_AND_B_ERROR } } = LABELS;
            yield put(getCaseMetadataFailure(T_AND_B_ERROR));
        }
    } catch (error) {
        yield put(getCaseMetadataFailure(error));
    }
}

export function* saveCaseMetadata(action) {
    const { payload } = action;
    const {
        CP_CONTRIBUTION: {
            SAVED_SUCCESS_TITLE,
            SAVED_SUCCESS_MSG,
            SAVING_TEXT,
        }
    } = LABELS;
    try {
        const cid = removeCurlyBrackets(payload?.id)
        yield put(saveCaseMetadataPending());
        yield put(showPageLoader(SAVING_TEXT));
        const {
            API_URL: {
                SAVE_CASE_METADATA
            },
            XA,
            STATUS_CODES: {
                SUCCESS
            }
        } = CONFIG;
        const res = yield call(doAxiosRequest, {
            method: 'post',
            endpoint: SAVE_CASE_METADATA(),
            params: payload,
            headers: {
                'x-api-key': XA,
            }
        });
        if (res?.error?.code === SUCCESS) {
            yield put(saveCaseMetadataSuccess(res));

            yield put(hidePageLoader());
            yield put(notifySuccess(SAVED_SUCCESS_TITLE, SAVED_SUCCESS_MSG));
            yield call(caseAPIAndTBAPI, { payload: { cid, hideLoading: true } });
        } else {
            const { CP_CONTRIBUTION: { T_AND_B_ERROR } } = LABELS;
            yield put(saveCaseMetadataFailure(T_AND_B_ERROR));
            yield put(hidePageLoader());
        }
    } catch (error) {
        yield put(saveCaseMetadataFailure(error));
        yield put(hidePageLoader());
    }
}

export function* getCaseFPADetails(action) {
    const { payload: { cid } } = action;
    try {
        yield put(getCaseFPADetailsPending());
        const {
            API_URL: {
                GET_CASE_FPA_DETAILS
            },
            XA,
            STATUS_CODES: {
                SUCCESS
            }
        } = CONFIG;
        const res = yield call(doAxiosRequest, {
            method: 'get',
            endpoint: GET_CASE_FPA_DETAILS(cid),
            headers: {
                'x-api-key': XA,
            }
        });
        if (res?.error?.code === SUCCESS) {
            yield put(getCaseFPADetailsSuccess(res));
        } else {
            const { CP_CONTRIBUTION: { T_AND_B_ERROR } } = LABELS;
            yield put(getCaseFPADetailsFailure(T_AND_B_ERROR));
        }
    }
    catch (error) {
        yield put(getCaseFPADetailsFailure(error));
    }
}

export function* searchCases(action) {
    const {
        payload: {
            caseNumber,
            doExactMatch,
            pageNumber,
            pageSize
        }
    } = action;
    try {
        yield put(searchCasesPending());
        const {
            API_URL: {
                SEARCH_CASES
            },
            XA,
            STATUS_CODES: {
                SUCCESS
            }
        } = CONFIG;
        const res = yield call(doAxiosRequest, {
            method: 'get',
            endpoint: SEARCH_CASES(
                caseNumber,
                doExactMatch,
                pageNumber,
                pageSize
            ),
            headers: {
                'x-api-key': XA,
            }
        });
        if (res?.error?.code === SUCCESS) {
            yield put(searchCasesSuccess({ data: res?.data, count: res?.totalCount }));
        } else {
            const { CP_CONTRIBUTION: { T_AND_B_ERROR } } = LABELS;
            yield put(searchCasesFailure(T_AND_B_ERROR));
        }
    }
    catch (error) {
        yield put(searchCasesFailure(error));
    }
}

export function* getCaseTypes() {
    try {
        yield put(getCaseTypesPending());
        const {
            API_URL: {
                GET_CASE_TYPES
            },
            XA,
            CASE_TYPE_GUID,
        } = CONFIG;
        const res = yield call(doAxiosRequest, {
            method: 'get',
            endpoint: GET_CASE_TYPES(CASE_TYPE_GUID),
            headers: {
                'x-api-key': XA,
            }
        });
        if (res?.value) {
            yield put(getCaseTypesSuccess(res));
        } else {
            const { CP_CONTRIBUTION: { T_AND_B_ERROR } } = LABELS;
            yield put(getCaseTypesFailure(T_AND_B_ERROR));
        }
    } catch (error) {
        yield put(getCaseTypesFailure(error));
    }
}

export function* getCaseOpenAndCloseDate(action) {
    const { payload: { cid } } = action;
    const cidsToString = cid.join(',')
    try {
        yield put(getCaseOpenAndCloseDatePending());
        const {
            API_URL: {
                GET_CASE_TB_DATES
            },
            XA,
            STATUS_CODES: {
                SUCCESS
            },
        } = CONFIG;
        const res = yield call(doAxiosRequest, {
            method: 'get',
            endpoint: GET_CASE_TB_DATES(cidsToString),
            headers: {
                'x-api-key': XA,
            }
        });
        if (res?.error?.code === SUCCESS) {
            yield put(getCaseOpenAndCloseDateSuccess(res));
        } else {
            const { CP_CONTRIBUTION: { T_AND_B_ERROR } } = LABELS;
            yield put(getCaseOpenAndCloseDateFailure(T_AND_B_ERROR));
        }
    } catch (error) {
        yield put(getCaseOpenAndCloseDateFailure(error));
    }
}

export default function* CaseMetadataSaga() {
    try {
        yield takeLatest(CASE_METADATA.GET_CASE_T_AND_B, getCaseTimeAndBillingData);
        yield takeLatest(CASE_METADATA.GET_CASE_SAM, getCaseSam);
        yield takeLatest(CASE_METADATA.GET_CASE_TB_DATES, getCaseOpenAndCloseDate);
        yield takeLatest(CASE_METADATA.GET_CASE_METADATA, getCaseMetadata);
        yield takeLatest(CASE_METADATA.GET_CASE_CMP, getCaseCMPData);
        yield takeLatest(CASE_METADATA.SAVE_CASE_METADATA, saveCaseMetadata);
        yield takeLatest(CASE_METADATA.GET_CASE_FPA_DETAILS, getCaseFPADetails);
        yield takeLatest(CASE_METADATA.SEARCH_CASES, searchCases);
        yield takeLatest(CASE_METADATA.GET_CASE_TYPES, getCaseTypes);
    } catch (error) {
        yield put(moduleFailure(error, 'CaseMetadataSaga'));
    }
}