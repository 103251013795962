/*
 * You can use this component like this <AddItems />
 * also you need inject required props
 */

// React
import React from "react";
import { AddKnowledgeItems } from "containers/KnowledgeBundles/AddKnowledgeItems";
import SupportingLinks from "components/SupportingLinks/AddSupportingLinks";
import { TabComponent } from "components/shared/TabComponent";

// React-Bootstrap
import Container from "react-bootstrap/Container";
import { onPageLoadAnalytics } from "../kb_analytics";
// Config
import CONFIG from "config";
//labels
import LABELS from "labels";
// scss
import "./KBStep2.scss";
import {
  PAGES,
  PRIMARY_CATEGORY
} from "utils/analytics/analytics_constants";

//const KBStep2 = ({ isPublishModalVisible, kbId, setStep2TabIndex }) => {
class KBStep2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      activeTabIndex: 0,
      isRequiredDataPresent: false,
      step2TabActiveIndex: 0

    }
  }
  componentDidMount = () => {
    const { kbId, getKPFile ,isKnowledgeBundle} = this.props;
    this.updateFooterConfig();
    onPageLoadAnalytics(kbId, isKnowledgeBundle ? PAGES.KB_CREATION : PAGES.TC_CREATION, isKnowledgeBundle  ? PRIMARY_CATEGORY.KB: PRIMARY_CATEGORY.TC);
  }
  componentDidUpdate = () => {
    this.updateFooterConfig();
  }

  componentWillUnmount() {
    if (this.overlaySubscriber) {
      this.overlaySubscriber.unsubscribe();
    }
  }

  updateFooterConfig = () => {
    // handles NEXT button disabled property
    let areStep2FieldsPresent = this.areStep2MinFieldsFilled();
    const { setStep2RequiredDataFlag } = this.props;
    const { isRequiredDataPresent } = this.state;

    if (areStep2FieldsPresent !== isRequiredDataPresent) {
      this.setState({
        isRequiredDataPresent: areStep2FieldsPresent
      }, () => {
        // send to parent so that footer is updated
        setStep2RequiredDataFlag(areStep2FieldsPresent);
      });
    }
  }

  areStep2MinFieldsFilled = () => {
    const { apidata } = this.props;
    const {
      KNOWLEDGE_BUNDLE_LIMITS: {
        KNOWLEDGE_ITEMS_MINLIMIT,
        SUPPORTING_LINKS_MAXLIMIT
      }
    } = CONFIG;
    let flag = false;
    const supportingLinksCount = apidata?.relatedLinks?.length ?? 0;
    const koCount = apidata?.knowledgeObjects?.length ?? 0;
    const totalCount = supportingLinksCount + koCount;
    if (totalCount >= KNOWLEDGE_ITEMS_MINLIMIT && supportingLinksCount <= SUPPORTING_LINKS_MAXLIMIT) {
      flag = true;
    }
    return flag;
  }

  getActiveTab = (activeIndex) => {
    const { setStep2TabIndex } = this.props;
    const { activeTabIndex } = this.state;
    if (activeTabIndex !== activeIndex) {
      this.setState({
        activeTabIndex: activeIndex
      });

      setStep2TabIndex(activeIndex); // send to parent
    }
  };


  render() {
    const { knowledgeItemsState,
      getItemsFromApi,
      getLinksFromApi,
      handleOnSelection,
      handleOnSearch,
      handleOnRecentContributions,
      sendAnalyticsForHelpModal,
      handleDocvizPreview,
      apidata,
      getKPFile
    } = this.props;

    const { KB_FORM_PLACEHOLDERS: { KB_ASSETS_VALIDATION } } = LABELS;
    return (
      <Container className="layout__page additems KBStep2">
        <TabComponent
          defaultActiveIndex={0}
          getActiveIndex={this.getActiveTab}
          menu={{ secondary: true, pointing: true }}
          arrayOfPaneElements={[
            {
              className: "item-style knowledge__pages",
              heading: LABELS.TABS_ADD_ITEMS.KNOWLEDGE_PAGES,
              count: knowledgeItemsState.length ?? 0,
              component: (
                <AddKnowledgeItems
                  defaultState={knowledgeItemsState ?? []}
                  defaultStateKoidList={apidata?.knowledgeObjects ?? []}
                  limit={
                    CONFIG.KNOWLEDGE_BUNDLE_LIMITS.KNOWLEDGE_ITEMS_MAXLIMIT
                  }
                  getItems={getItemsFromApi}
                  handleOnSelection={handleOnSelection}
                  handleOnSearch={handleOnSearch}
                  handleOnRecentContributions={handleOnRecentContributions}
                  sendAnalyticsForHelpModal={sendAnalyticsForHelpModal}
                  handleDocvizPreview={handleDocvizPreview}
                  minimumItemsMessage={KB_ASSETS_VALIDATION}
                  getKPFile={getKPFile}
                />
              ),
            },
            {
              className: "item-style supporting__urls",
              heading: LABELS.TABS_ADD_ITEMS.SUPPORTING_URLS,
              count: apidata?.relatedLinks?.length ?? 0,
              component: (
                <SupportingLinks
                  defaultState={apidata?.relatedLinks ?? []}
                  limit={
                    CONFIG.KNOWLEDGE_BUNDLE_LIMITS.SUPPORTING_LINKS_MAXLIMIT
                  }
                  getLinks={getLinksFromApi}
                  urlValidation={CONFIG.REGEX.SUPP_LINKS_URL}
                  urlSanitization={CONFIG.BLACK_LISTED_LINKS}
                  urlWhiteList={CONFIG?.KB_WHITE_LISTED_LINKS}
                  minimumItemsMessage={KB_ASSETS_VALIDATION}
                />
              ),
            },
          ]}
        />

      </Container>
    );
  }
}

export { KBStep2 };