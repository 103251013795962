import React from 'react';
import './TaxonomyListing.scss';
import { Image } from 'semantic-ui-react';
import kpc_Collapse from 'assets/images/CollapseTags.svg';
import kpc_Expand from 'assets/images/ExpandTags.svg';
import { ImageWithToolip } from "components/shared/ImageWithToolip";
import PolyHIcon from "assets/images/polyHierarchi/polyHierarchiIcon.svg";
import LABELS from 'labels';
import { ShowMore } from 'components/shared/ShowMore_Less/ShowMore';
import PropTypes from 'prop-types';
import _orderBy from 'lodash/orderBy';
import { v4 as uuidv4 } from 'uuid';
import { Tooltip } from "components/shared/Tooltip";
import { getPolyHierarchiData, isCurrentAPolyGuid } from "utils/helpers/helpers";




export class TaxonomyListing extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tagsExpanded: props.isDefaultTagsExpanded
    }
  }

  taxonomyRowRef = {};
  // method to keep the owner custodian at top and remaining after that.
  sortSectorsByOwner = (sectors) => {
    return _orderBy(sectors, ['isOwner'], ['desc']);
  }

  expandTags = (flag) => {
    this.setState({
      tagsExpanded: flag
    });
  }

  renderList = (sector) => {
    if (Array.isArray(sector) && !!sector.length) {
      let isCustodian = false;
      sector.forEach((item) => {
        if (!!item?.isOwner) {
          isCustodian = true;
        }
      });
      return (
        <div className={`taxonomylisting__row ${isCustodian ? "taxonomylisting__sectorCustodian" : ""}`}>
          {sector.map((item, index) => {
            const isLeafNode = index === (sector.length - 1)
            return this.renderItem(item, isLeafNode)
          })}
        </div>
      );
    } else {
      return this.renderItem(sector, true);
    }
  }
  fnToCheckIDIsPolyHierarchi = (id) => {
    const isPolyGuid = isCurrentAPolyGuid(id);
    if (isPolyGuid) {
      return true;
    }
    return false;
  }

  renderItem = (item, isLeafNode) => {
    const { KPCONSUMPTIONGROUP: { CUSTODIAN } } = LABELS;
    const { TAXONOMY: { TOOLTIPS: { CUSTODIAN_POLY_MSG_2 } } } = LABELS;
    const { tagsExpanded } = this.state;
    const isPolyHierarchi = this.fnToCheckIDIsPolyHierarchi(item.id);
    return (
      <>
        <div className={`taxonomylisting__sector 
          ${!isLeafNode ? tagsExpanded ? " tagvisible " : " taghidden" : ""}
        `}>
          <span className={`
            ${isLeafNode ? 'taxonomylisting__sectorLabel' : 'taxonomylisting__label'}                       
            ${item?.isPARecommended ? ' taxonomylisting__sectorLabel-star' : ''}
          `}>
            {isPolyHierarchi && <ImageWithToolip id={item.id} imgIcon={PolyHIcon} imgClassName="taxonomylisting__polyImg" toolTipMsg={CUSTODIAN_POLY_MSG_2} />}
            {item?.name}
          </span>
        </div>
        {item?.isOwner && (
          <span className="taxonomylisting__custodianLabel">
            {CUSTODIAN}
          </span>
        )}
      </>
    )
  };

  render() {
    const { taxonomyData, showHeading, showMoreRecords, individualShowMore, associatedTopicPagesCount } = this.props;
    const { KPCONSUMPTIONGROUP: { EXPAND_TAGS, COLLAPSE_TAGS } } = LABELS;
    const { tagsExpanded } = this.state;
    const iconForTags = tagsExpanded ? kpc_Collapse : kpc_Expand;
    return (
      <div className="taxonomylisting">
        {!associatedTopicPagesCount && <div className="taxonomylisting__collapseSection" onClick={() => this.expandTags(!tagsExpanded)}>
          <span className="taxonomylisting__collapse" >
            {!tagsExpanded ? EXPAND_TAGS : COLLAPSE_TAGS}
          </span>
          <Image
            src={iconForTags}
            className="taxonomylisting__collapseImage"
          ></Image>
        </div>}
        <ShowMore height={478} applyShowMore={showMoreRecords}>
          {Object.entries(taxonomyData).length > 0 &&
            <div className="taxonomylisting__sectorslist">
              {Object.keys(taxonomyData).map((taxonomy) => {
                return (
                  <ShowMore applyShowMore={individualShowMore} height={210} key={uuidv4()}>
                    {showHeading && taxonomyData[taxonomy].length > 0 &&
                      <span className="taxonomylisting__sectorCategory">
                        {taxonomy}
                      </span>
                    }
                    {taxonomyData[taxonomy].length > 0 && this.sortSectorsByOwner(taxonomyData[taxonomy]).map((sector) => {
                      return this.renderList(sector);
                    })}
                  </ShowMore>
                );
              })}
            </div>
          }
        </ShowMore>
      </div>
    );
  }
}
TaxonomyListing.propTypes = {
  showHeading: PropTypes.bool,
  showMoreRecords: PropTypes.bool,
  taxonomyData: PropTypes.object,
  individualShowMore: PropTypes.bool,
  isDefaultTagsExpanded: PropTypes.bool,
};

TaxonomyListing.defaultProps = {
  showHeading: true,
  showMoreRecords: false,
  taxonomyData: {},
  individualShowMore: false,
  isDefaultTagsExpanded: true
};