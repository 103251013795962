import { doAxiosRequest } from "config-axios";
import { MY_NAVIGATOR } from "redux/constants";
import CONFIG from "config";
import { SEARCH } from 'utils/queryUtils/queryUtils';
import { getUserId } from "utils/auth/auth";
import { actions as peopleResultsActions } from 'redux/actions/peopleresults/peopleresults_actions';
import { mergeKnowledgeWithLockInfo } from 'utils/helpers/helpers';

export const actions = {
  // switch search type between kp and kb
  // this triggers corresponding search
  changeSearchType: searchCriteria => async (dispatch) => {
    //when changing search type, reset status filter
    searchCriteria.status = CONFIG.KNOWLEDGE_STATUS.DRAFT;
    dispatch({
      type: MY_NAVIGATOR.CHANGE_SEARCH_TYPE,
      payload: searchCriteria
    });

    actions.searchKnowledge(searchCriteria)(dispatch);
  },

  // switch search by status filter
  // this triggers corresponding search
  changeStatusFilter: searchCriteria => async (dispatch) => {
    dispatch({
      type: MY_NAVIGATOR.CHANGE_STATUS_FILTER,
      payload: searchCriteria
    });

    actions.searchKnowledge(searchCriteria)(dispatch);
  },

  // switch search page
  // this triggers search with new paging info
  changePage: searchCriteria => async (dispatch) => {
    dispatch({
      type: MY_NAVIGATOR.CHANGE_PAGE,
      payload: searchCriteria
    });

    actions.searchKnowledge(searchCriteria)(dispatch);
  },

  //search knowledge pages or bundles based on paging and filter criteria
  searchKnowledge: searchCriteria => async (dispatch) => {
    //temporary check for KP vs KB search
    //this will be replaced with knowledge search when api is ready
    const {
      KP_SEARCH_PENDING,
      KP_SEARCH_SUCCESS,
      KP_SEARCH_FAILURE,
      KB_SEARCH_PENDING,
      KB_SEARCH_SUCCESS,
      KB_SEARCH_FAILURE
    } = MY_NAVIGATOR;
    const {
      KNOWLEDGE_TYPE_KEYS: {
        BUNDLES,
        COLLECTIONS
      }
    } = CONFIG;
    const {
      getPeopleDataUsingIDs
    } = peopleResultsActions;

    const isKb = (searchCriteria.searchType === BUNDLES || searchCriteria.searchType === COLLECTIONS);
    const pendingAction = isKb ? KB_SEARCH_PENDING : KP_SEARCH_PENDING;
    const successAction = isKb ? KB_SEARCH_SUCCESS : KP_SEARCH_SUCCESS;
    const failureAction = isKb ? KB_SEARCH_FAILURE : KP_SEARCH_FAILURE;

    // filter by logged in user (hr id for kp, email for kb)
    // this will be unified when the global knowledge search is implemented
    const hrId = await getUserId();
    searchCriteria.originalCreator = hrId;

    try {
      dispatch({
        type: pendingAction,
        payload: {
          criteria: searchCriteria
        }
      });

      const axiosConfig = {
        method: 'get',
        endpoint: `${SEARCH(searchCriteria)}`,
        headers: {
          'x-api-key': CONFIG.XA
        }
      };
      const response = await doAxiosRequest(axiosConfig);
      let knowledgeItems = response?.data;

      // for all items that are locked (lockedBy has value)
      // convert hr ids (kp) or email (kb) to name by making people api call
      // enhance knowledge list with lockedByName field
      const ids = [...new Set(knowledgeItems.map(item => item.lockedBy?.toString()))].filter(Boolean);
      if (ids?.length > 0) {
        const people = await dispatch(getPeopleDataUsingIDs(ids, false));
        knowledgeItems = mergeKnowledgeWithLockInfo(knowledgeItems, people);
      }

      dispatch({
        type: successAction,
        payload: {
          data: knowledgeItems,
          statistics: response?.statistics
        }
      });
    } catch (error) {
      dispatch({
        type: failureAction,
        payload: { error: error.toString() }
      });
      throw error;
    }
  }
};