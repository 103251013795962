// React
import React from 'react';
// Icon
import Icon from "components/shared/Icon";
// Scss
import './HelpIcon.scss';

// Help Icon return the help hover functionality 
export const HelpIcon = () => {
  return (<Icon name="info circle" className="help-icon" />)
};