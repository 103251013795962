import React from 'react';
import { connect } from 'react-redux';
import { KP, DOCVIZ } from "redux/constants";
import { actions as KPActions } from 'redux/actions/knowledgepage/knowledgepage_actions';
import { bindActionCreators } from 'redux';
import { selectors as kpSelectors } from 'redux/reducers/knowledgepage/knowledgepage_reducer';
import { selectors as caseSelectors } from 'redux/reducers/casecode/case_reducer';
import { isEmpty } from 'lodash';
import { ConsumptionContacts } from 'components/ConsumptionContacts/ConsumptionContacts';
import { ConsumptionHeader } from 'components/ConsumptionHeader/ConsumptionHeader'
import { ConsumptionDescription } from 'components/ConsumptionDescription/ConsumptionDescription';
import { Layout } from 'containers/Layout';
import './KpConsumption.scss';
import FooterWorkflow from 'components/shared/FooterWorkflow';
import LOCAL_CONFIG from './Consumption.config';
import { selectors as globalSelectors } from "redux/reducers/global/global_reducer";
import { TopBar } from 'components/shared/TopBar/TopBar';
import { actions as peopleResultsActions } from 'redux/actions/peopleresults/peopleresults_actions';
import { WarningModal } from "components/WarningModal/WarningModal";
import { CloneModal } from "components/CloneModal";
import WorkflowWarningSteps from 'components/shared/WorkflowWarningSteps';


// Selectors
import { selectors as entitlementSelectors } from "redux/reducers/authorization/authorization_reducer";
import { selectors as peopleResultsSelectors } from 'redux/reducers/peopleresults/peopleresults_reducer';
import { selectors as docvizSelectors } from "redux/reducers/docviz/docviz_reducer";
import { selectors as isPaRecommendedSelectors } from "redux/reducers/parecommened/parecommened_reducer";
import { selectors as taxonomySelector } from "redux/reducers/taxonomy/taxonomy_reducer";
import { ConsumptionTaxonomySectionV1 } from 'components/ConsumptionTaxonomySection/ConsumptionTaxonomySectionV1';
import { isPaRecommended, updateRecommendPA } from "redux/actions/parecommened/parecommended.actions";
import { CardViewSubject, SUBSCRIBERS } from '@kdshared/docviz';
import { resetDocvizMetadata } from "redux/actions/docviz/docviz.actions";
import CONFIG from 'config';
import LABELS from 'labels';
import { getPolyhierarchyApiData } from "redux/actions/polyhierarchy/polyhierarchy.actions";
import { postFeedbackRecommendedTags, getTaggingStatus } from "redux/actions/recommendedtagging/recommendedtagging.actions";
import { selectors as recommendedTaggingSelectors } from "redux/reducers/recommendedtagging/recommendedtagging_reducer";
import { uploadStart } from "redux/actions/upload/upload.actions";
import { getUserId } from "utils/auth/auth";
import GLOBAL_CONFIG from "config";

// Analytics
import ANALYTICS from "utils/analytics/analytics";
import {
  PAGES,
  PRIMARY_CATEGORY,
  TRIGGERS,
  DTMRULE,
  SCREEN_NAMES,
  MATERIAL_STATUS,
  COPY_COMPONENT
} from "utils/analytics/analytics_constants";
import { ConsumptionAdditionalContent } from 'components/ConsumptionAdditionalContent/ConsumptionAdditionalContent';
import {
  getCountOfFlagsAsTrue,
  deleteUsabillaKey,
  getmyCurrentLocation,
  stripHtml,
  noWordsTrimmingInString,
  isValidDocvizFile,
  convertStrToBool,
  convertStrToNum,
  getDocvizSupportedFileState,
  copyObject,
  getQueryParamIsRpa,
  getConcatNames,
  isFileTypePPT,
  isSectorTopicOwnerSelected,
  getLengthOfMultiArrayWithPoly,
  getIsRpaReview,
  isOwnerMdp,
  conditionsApprovalMdp
} from "utils/helpers/helpers";
import { getDescriptionCharLength } from "components/shared/RichEditor/RichEditor.helper.js"
import CONSTANTS from "globalConstants";
import { Helmet } from "react-helmet";
import { getCasesMetaDetails } from 'redux/actions/casecode/case.action';

import WarningIcon from "assets/images/warning/btn-warning.svg";
import SuccessIcon from "assets/images/success/btn-success.svg";
const {
  ENTITLEMENTS: {
    KNOWLEDGE_CONTRIBUTIONS,
    CREATE_EDIT_DRAFT,
    PREVIEW_DOWNLOAD,
    PREVIEW_DOWNLOAD_R,
    EDIT_PUBLISHED_CONTENT,
    PUBLISH_CONTENT,
    CASE_PAGES,
    VIEW_CLIENT_INFO
  },
  CONSUMPTION_RATING
} = CONSTANTS;
const {
  IDENTIFIER: {
    CARD_AUTHOR,
    CARD_EMAIL
  },
  WORK_SPACE: {
    WARNING_MODAL_CANCEL,
    WARNING_MODAL_CONFIRM,
  },
  KP: {
    PUBLISH_QUESTION,
    REVIEW_QUESTION,
    LOCKED_PUBLISH,
  },
} = LABELS

const { DOWNLOAD_APPLICATION_NAME: { KNOWLEDGE_PAGE_APP_NAME }, IS_KPC_NEW_LAYOUT } = CONFIG;
// const { SITECORE_XM_ENABLED } = GLOBAL_CONFIG;

class KpConsumptionContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    const { footerWorkflow } = LOCAL_CONFIG;
    this.state = {
      btnsConfig: footerWorkflow,
      defaultTabIndex: { Authors: 0, AdditionalContacts: 1 },
      initiatePolling: true,
      isDocvizLoadError: false,
      slideIndexNo: 0,
      defaultDocvizOverlayState: false,
      isWModalVisible: false,
      comment: '',
      mainKPState: this.getMainKPstate(false, {}),
      kpfileUploadStatus: false,
      kpfileState: {},
      isPublishModalVisible: false,
      showCloneModal: false,
      userId: ""
    };
    this.cardViewSubscriber = null;
  }

  isReview = () => {
    const { isPublishEntitlement } = this.props;
    return isPublishEntitlement === undefined ? false : !isPublishEntitlement;
  }
  creatingWarningStepsData = (stepsList = []) => {
    let updatedStepsList = [];
    if (stepsList.length > 0) {
      stepsList.forEach((item, index) => {
        const { title, isSuccess, link } = item;
        const stepsDefaultObj = {
          buttonText: title,
          buttonIcon: isSuccess ? SuccessIcon : WarningIcon,
          isSuccess,
          link,
          iconAltText: isSuccess ? 'success icon' : 'warning icon',
          showDivider: index < stepsList.length - 1,
        }
        updatedStepsList.push(stepsDefaultObj)
      })
    }
    return updatedStepsList;
  }
  cancelWarningModal = () => {
    const { resetWarningModal } = this.props;
    resetWarningModal(() => {
      this.openModalW();
    });
  }

  goToKpStepsPages = (link) => {
    const { history, resetWarningModal } = this.props;
    if (link) {
      resetWarningModal();
      history.push({
        pathname: link,
        search: getQueryParamIsRpa(),
        state: { mode: history.location?.state?.mode },
      });
    }
  }

  handleBtnsConfig = async () => {
    const { isPublishEntitlement, kpMetaData, userDetails,
      peopleResultsActions: {
        getPeopleDataUsingIDs
      } } = this.props;

    const hrid = kpMetaData?.owner;
    const peopledata = await getPeopleDataUsingIDs([hrid]);
    if (peopledata && peopledata.length > 0) {
      const { preferredFirstName, preferredLastName } = peopledata[0];


      const { footerWorkflow } = LOCAL_CONFIG;
      const {
        KNOWLEDGE_STATUS: {
          DRAFT,
          REVIEW
        }
      } = CONFIG;

      const isStatusReview = kpMetaData?.status === REVIEW;
      const isStatusDraft = kpMetaData?.status === DRAFT;

      const btnsConfig = copyObject(footerWorkflow);
      if (typeof isPublishEntitlement !== "undefined" && !isPublishEntitlement) {
        btnsConfig[1].children[0].visible = false;
        btnsConfig[1].children[1].visible = true;
        if (!isStatusDraft && !isStatusReview) {
          btnsConfig[1].children[1].disabled = true;
        }
      }
      else {
        btnsConfig[1].children[0].visible = true;
        if (!isStatusDraft && !isStatusReview) {
          btnsConfig[1].children[0].disabled = true;
        }
      }

      if (isStatusReview) {
        btnsConfig[0].visible = false;
      }
      if (isStatusDraft) {
        const loggedInHRID = userDetails?.id;
        const lockedHRID = kpMetaData?.owner;
        if (lockedHRID && lockedHRID?.toString() !== loggedInHRID) {
          btnsConfig[1].children[0].disabled = true;
          btnsConfig[1].children[1].disabled = true;
          btnsConfig[0].visible = false;
          btnsConfig[1].children[0].tooltip = (
            <span>{`${LOCKED_PUBLISH} <span style="text-decoration: underline">${getConcatNames(preferredFirstName, preferredLastName)}</span>`}</span>
          );
          btnsConfig[1].children[1].tooltip = (
            <span>{`${LOCKED_PUBLISH} <span style="text-decoration: underline">${getConcatNames(preferredFirstName, preferredLastName)}</span>`}</span>
          );
        }
        else {
          btnsConfig[1].children[0].disabled = false;
          btnsConfig[1].children[1].disabled = false;
        }

      }
      this.setState({ btnsConfig });
      const isReviewPublishEnabled = process.env.REACT_APP_IS_PUBLISH_REVIEW_ENABLED.trim() === 'false';
      if (isReviewPublishEnabled) {
        btnsConfig[1].children[0].visible = false;
        btnsConfig[1].children[1].visible = false;
      }
    }
  }
  associatedBundlesClickCallback = (kbId, index) => {
    const {
      publishedData: { paRecommended },
      match: {
        params: { kpId },
      },
    } = this.props;
    const {
      PA_RECOMMENDED,
      NOT_PA_RECOMMENDED
    } = MATERIAL_STATUS;

    const newAdobeData = {
      page: {
        category: {
          primaryCategory: PRIMARY_CATEGORY.KP_CONSUMPTION,
        },
        pageInfo: {
          pageName: PAGES.KP_CONSUMPTION,
          pageURL: getmyCurrentLocation()
        }
      },
      resultType: SCREEN_NAMES.KP,
      materialPAStatus: paRecommended ? PA_RECOMMENDED : NOT_PA_RECOMMENDED,
      searchResultRanking: index + 1,
      collection: {
        trigger: TRIGGERS.KP_CONSUMPTION.KP_ASSOCIATED_BUNDLE,
        ID: kbId
      },
      KCpage: {
        id: kpId
      }
    };

    ANALYTICS.kc.sendEventData(newAdobeData, DTMRULE.VIEW_MATERIAL_PAGE);
  }

  triggerPreviewAnalytics = () => {
    const {
      publishedData: { paRecommended },
      match: {
        params: { kpId },
      },
    } = this.props;
    const {
      PA_RECOMMENDED,
      NOT_PA_RECOMMENDED
    } = MATERIAL_STATUS;

    const newAdobeData = {
      collection: {
        trigger: TRIGGERS.KP_CONSUMPTION.KP_PREVIEW
      },
      KCpage: {
        id: kpId
      },
      materialPAStatus: paRecommended ? PA_RECOMMENDED : NOT_PA_RECOMMENDED,
      page: {
        category: {
          primaryCategory: PRIMARY_CATEGORY.KP_CONSUMPTION,
        },
        pageInfo: {
          pageName: PAGES.KP_CONSUMPTION,
          pageURL: getmyCurrentLocation()
        },
      },
      resultType: SCREEN_NAMES.KP
    };
    ANALYTICS.kc.sendEventData(newAdobeData, DTMRULE.PREVIEW_INTERACTIONS);
  }

  triggerDownloadAnalytics = () => {
    const {
      publishedData: { paRecommended },
      match: {
        params: { kpId },
      },
    } = this.props;
    const {
      PA_RECOMMENDED,
      NOT_PA_RECOMMENDED
    } = MATERIAL_STATUS;

    const newAdobeData = {
      collection: {
        trigger: TRIGGERS.KP_CONSUMPTION.KP_DOWNLOAD
      },
      KCpage: {
        id: kpId
      },
      materialPAStatus: paRecommended ? PA_RECOMMENDED : NOT_PA_RECOMMENDED,
      page: {
        category: {
          primaryCategory: PRIMARY_CATEGORY.KP_CONSUMPTION,
        },
        pageInfo: {
          pageName: PAGES.KP_CONSUMPTION,
          pageURL: getmyCurrentLocation()
        },
      },
      resultType: SCREEN_NAMES.KP
    };
    ANALYTICS.kc.sendEventData(newAdobeData, DTMRULE.DOWNLOAD_INTERACTIONS);
  };



  bindDocvizSubscribers = () => {
    this.cardViewSubscriber = CardViewSubject.subscribe(action => {
      switch (action.type) {
        case SUBSCRIBERS.PREVIEW_CLICK:
          this.triggerPreviewAnalytics();
          break;
        case SUBSCRIBERS.DOWNLOAD_CLICK:
          this.triggerDownloadAnalytics();
          break;
        case SUBSCRIBERS.DOCVIZ_LOAD_ERROR:
          this.setState({ isDocvizLoadError: true });
          break;
        default:
          break;
      }
    });
  }

  componentDidMount() {
    this.getConsumptionData();
    deleteUsabillaKey(CONSUMPTION_RATING);
    this.bindDocvizSubscribers();
    const { getPolyhierarchyApiData, resetDocvizData } = this.props;
    resetDocvizData();
    getPolyhierarchyApiData();
    this.getUserId();
  }
  getConsumptionData = () => {
    const {
      props: {
        match: {
          params: { kpId },
        },
        history
      },
    } = this;


    const isInPreviewMode = history.location.pathname.includes('preview');

    const {
      KPActions: {
        getMetaDataKP,
        getPublishedKPData
      },
      getFileStatus,
      getAssociatedBundles,
      getIsPaRecommended
    } = this.props;

    isInPreviewMode ? getMetaDataKP(kpId) : getPublishedKPData(kpId); //'da028c68-3b9d-4af5-9f27-1543677b54f6';
    getFileStatus(kpId);
    getAssociatedBundles(kpId);
    ANALYTICS.page.initialize({ pageName: PAGES.KP_CONSUMPTION, category: PRIMARY_CATEGORY.KP_CONSUMPTION, trackPageLoad: true, pageId: { KCpage: { id: kpId } } });

    if (kpId) {
      getIsPaRecommended(kpId);
    }
  }


  prepareAnalyticsData = (analyticsData) => {
    const {
      match: {
        params: { kpId },
      },
      kpMetaData,
      history,
      publishedData,
    } = this.props;
    const {
      PA_RECOMMENDED,
      NOT_PA_RECOMMENDED
    } = MATERIAL_STATUS;
    const isInPreviewMode = history.location.pathname.includes('preview');
    const consumptionMetaData = isInPreviewMode ? kpMetaData : publishedData;
    const { paRecommended } = consumptionMetaData;
    const {
      trigger,
      materialURL,
      section,
      hrId,
      resultType
    } = analyticsData;
    const newAdobeData = {
      KCpage: {
        id: kpId,
      },
      collection: {
        trigger: trigger,
      },
      page: {
        category: {
          primaryCategory: PRIMARY_CATEGORY.KP_CONSUMPTION,
        },
        pageInfo: {
          pageName: PAGES.KP_CONSUMPTION,
          pageURL: getmyCurrentLocation(),
        },
      },
      resultType: !!resultType ? resultType : SCREEN_NAMES.KP,
      materialPAStatus: paRecommended ? PA_RECOMMENDED : NOT_PA_RECOMMENDED
    };
    if (hrId) newAdobeData.authorStaffID = hrId;
    if (materialURL) newAdobeData.materialURL = materialURL;
    if (section) newAdobeData.section = section;

    return newAdobeData;
  }

  onLinkClickCallback = (link, trigger) => {
    const analyticsData = {
      trigger: trigger,
      materialURL: link,
      section: SCREEN_NAMES.KP,
    };
    const newAdobeData = this.prepareAnalyticsData(analyticsData)
    ANALYTICS.kc.sendEventData(newAdobeData, DTMRULE.VISIT_URL_INTERACTIONS);
  }

  thumbsUpDownAnalyticsCallback = (selectedOptions = "N/A") => {
    const analyticsData = {};
    const { RESULT_EXPLICIT_FEEDBACK } = DTMRULE;
    const { publishedData: { relatedToGTM } } = this.props;
    let newAdobeData = this.prepareAnalyticsData(analyticsData);
    newAdobeData = { ...newAdobeData, selectedOptionReason: selectedOptions, gtm: relatedToGTM };
    ANALYTICS.kc.sendEventData(newAdobeData, RESULT_EXPLICIT_FEEDBACK);
  }

  consumptionContactsAnalyticsCallback = ({ tabIdentifier, cardIdentifier, hrId }) => {
    const analyticsData = {
      trigger: tabIdentifier,
      hrId,
    };
    const { VIEW_AUTHOR_PROFILE, AUTHOR_EMAIL_INTERACTION } = DTMRULE;
    let dtmRule;
    if (cardIdentifier === CARD_AUTHOR) {
      dtmRule = VIEW_AUTHOR_PROFILE;
    } else if (cardIdentifier === CARD_EMAIL) {
      dtmRule = AUTHOR_EMAIL_INTERACTION;
    }
    const newAdobeData = this.prepareAnalyticsData(analyticsData)
    ANALYTICS.kc.sendEventData(newAdobeData, dtmRule);
  }

  contactsTabClickCallback = (tabIdentifier) => {
    const {
      match: {
        params: { kpId },
      }
    } = this.props;
    const { TAB_CLICK } = DTMRULE;
    const { KP_CONSUMPTION } = PRIMARY_CATEGORY;
    const { KP_CONSUMPTION: PAGENAME } = PAGES;
    const newAdobeData = {
      KCpage: {
        id: kpId,
      },
      searchTabName: tabIdentifier,
      page: {
        category: {
          primaryCategory: KP_CONSUMPTION,
        },
        pageInfo: {
          pageName: PAGENAME,
          pageURL: getmyCurrentLocation(),
        },
      }
    };

    ANALYTICS.kc.sendEventData(newAdobeData, TAB_CLICK);
  }

  componentWillUnmount() {
    if (this.cardViewSubscriber) {
      this.cardViewSubscriber.unsubscribe();
    }
    const {
      KPActions: { clearMetaDataKP },
    } = this.props;
    clearMetaDataKP();
    deleteUsabillaKey(CONSUMPTION_RATING);
  }
  footerButtonsClick = (e) => {
    if (e.target.id === "btn-publish") {
      this.openModalW();
    }
    else if (e.target.id === "btn-review") {
      this.openModalW();
    }
    else if (e.target.id === "btn-back") {
      this.handleBack();
    }
  }
  handleBack = () => {
    const {
      match: {
        params: { kpId },
      },
      history,
    } = this.props;

    history.push({
      pathname: CONFIG.UI_URL.METADATA_KP('step3', kpId), //`/kp/metadata/step3/${kpId}`,
    });
  };

  handleFileDownload = () => {
    const {
      getKPFile,
      match: {
        params: { kpId },
      },
    } = this.props;
    getKPFile(kpId);
  };

  sendAnalyticsOnTabClick = (tab) => {
    const {
      match: {
        params: { kpId },
      },
    } = this.props;
    const newAdobeData = {
      KCpage: {
        id: kpId,
      },
      page: {
        category: {
          primaryCategory: PRIMARY_CATEGORY.KP_CONSUMPTION,
        },
        pageInfo: {
          pageName: PAGES.KP_CONSUMPTION,
          pageURL: getmyCurrentLocation(),
        },
      },
      collection: {
        trigger: "KP_" + tab,
        screenName: PAGES.KP_CONSUMPTION
      },

    };
    ANALYTICS.kc.sendEventData(newAdobeData, DTMRULE.KNOWLEDGE_PAGE_ACTION);
  };

  getAttachment = (kpMetaData, publishedData) => {
    const { history: { location: { pathname } } } = this.props;
    const isInPreviewMode = pathname.includes('preview');
    const consumptionMetaData = isInPreviewMode ? kpMetaData : publishedData;
    const { attachment } = consumptionMetaData;
    return attachment;
  }

  componentDidUpdate(prevProps) {
    const {
      getPaRecommendedData,
      KPActions: {
        getMetaDataKP,
        getPublishedKPData
      },
      kpMetaData,
      publishedData,
      userDetails,
      getCasesMetaDetails,
      isPublishEntitlement,
      callTaggingStatus,
      defaultLanguage,
    } = this.props;
    const { mainKPState } = this.state;
    const attachment = this.getAttachment(kpMetaData, publishedData);
    const {
      getPaRecommendedData: prevRecommendedData,
      kpMetaData: prevKpMetaData,
      publishedData: prevPublishedData,
      userDetails: prevUserDetails,
      defaultLanguage: prevDefaultLanguage,
    } = prevProps;
    const prevAttachment = this.getAttachment(prevKpMetaData, prevPublishedData);
    const {
      props: {
        match: {
          params: { kpId },
        },
        history
      },
    } = this;
    if (JSON.stringify(getPaRecommendedData) !== JSON.stringify(prevRecommendedData) && !Array.isArray(prevRecommendedData)) {
      const isInPreviewMode = history.location.pathname.includes('preview');
      setTimeout(() => {
        isInPreviewMode ? getMetaDataKP(kpId) : getPublishedKPData(kpId);
      }, 2000);
    }
    if (attachment && attachment?.case?.caseNumbers !== prevAttachment?.case?.caseNumbers) {
      const caseNumbers = attachment?.case?.caseNumbers;
      if (caseNumbers.length) {
        getCasesMetaDetails(caseNumbers);
      }
    }

    if (publishedData !== prevPublishedData) {
      this.updateDocvizOverlayState();
    }
    if (isPublishEntitlement !== prevProps.isPublishEntitlement || kpMetaData?.status !== prevKpMetaData?.status || userDetails?.id !== prevUserDetails?.id) {
      this.handleBtnsConfig();
    }
    const newMainKPstate = this.getMainKPstate(kpMetaData, defaultLanguage);
    const prevMainKPstate = this.getMainKPstate(prevKpMetaData, prevDefaultLanguage);
    if (kpMetaData && JSON.stringify(newMainKPstate) !== JSON.stringify(prevMainKPstate)) {
      this.setState({
        mainKPState: {
          ...mainKPState,
          ...newMainKPstate
        }
      });
    }
    const { KNOWLEDGE_STATUS: { PUBLISHED, REVIEW } } = CONFIG;
    if (mainKPState?.status === PUBLISHED || (mainKPState?.status === REVIEW && isPublishEntitlement === false)) {
      this.closeModalW();
      this.openModalPublish();
    }

    const { IS_RECOMMENDED_TAGGING_ON } = CONFIG;
    const { uniqueId, file } = newMainKPstate;
    if (prevMainKPstate?.uniqueId !== uniqueId && IS_RECOMMENDED_TAGGING_ON && !!uniqueId && uniqueId !== "0" && !!isPublishEntitlement && isFileTypePPT(file?.name)) {
      callTaggingStatus(uniqueId);
    }
  }

  updateDocvizOverlayState = () => {
    const { location: { search } } = this.props;
    const searchParams = new URLSearchParams(search);
    let defaultDocvizOverlayState = searchParams.get('opendocviz');
    let slideNo = searchParams.get('slideno');

    defaultDocvizOverlayState = convertStrToBool(defaultDocvizOverlayState)
    slideNo = convertStrToNum(slideNo);

    if (defaultDocvizOverlayState) {
      if (slideNo) {
        this.setState({ slideIndexNo: slideNo - 1, defaultDocvizOverlayState })
      } else {
        this.setState({ slideIndexNo: 0, defaultDocvizOverlayState })
      }
    }
  }

  getShareViaEmailBody = () => {
    const { EMAIL: { KP_BODY, DESCRIPTION_HEADING }, ELLIPSIS } = LABELS;
    const { MAILTO_BODY_BROWSER_RESTRICTED_CHARLIMIT } = CONFIG;
    const pageLink = getmyCurrentLocation();
    const { history: { location: { pathname } }, kpMetaData, publishedData } = this.props;
    const kpData = pathname.includes('preview') ? kpMetaData : publishedData;
    const { description = "" } = kpData;
    let formattedBody = description.length > MAILTO_BODY_BROWSER_RESTRICTED_CHARLIMIT ? stripHtml(noWordsTrimmingInString(description, MAILTO_BODY_BROWSER_RESTRICTED_CHARLIMIT) + ELLIPSIS) : stripHtml(description);
    let bodyText = `${KP_BODY} \n  ${pageLink} \n\n ${DESCRIPTION_HEADING} \n ${formattedBody}`;
    return bodyText;
  }

  prepareShareAnalytics = (paRecommendedCount) => {
    const { PA_RECOMMENDED, NOT_PA_RECOMMENDED } = MATERIAL_STATUS;
    const {
      match: {
        params: { kpId },
      }
    } = this.props;
    const { KP: displayKP } = SCREEN_NAMES;

    let newAdobeData = {
      materialPAStatus: paRecommendedCount ? PA_RECOMMENDED : NOT_PA_RECOMMENDED, //evar14
      displayMode: displayKP, //evar10
      resultType: displayKP, //evar6
      KCpage: {
        id: kpId //evar123
      }
    };

    return newAdobeData;
  }
  copyLinkAnalytics = (paRecommendedCount) => {
    let newAdobeData = this.prepareShareAnalytics(paRecommendedCount);
    const {
      MY_NAVIGATOR: {
        ENTITY_TYPES: { KP }
      }
    } = LABELS;

    const { KP_LINK } = COPY_COMPONENT;
    newAdobeData.copyTrigger = KP; //evar44
    newAdobeData.copyComponent = KP_LINK;  //evar115           

    ANALYTICS.kc.sendEventData(newAdobeData, DTMRULE.EVENT55_COPY_LINK);
  }

  shareViaEmailAnalytics = (paRecommendedCount) => {
    let newAdobeData = this.prepareShareAnalytics(paRecommendedCount);
    ANALYTICS.kc.sendEventData(newAdobeData, DTMRULE.EVENT16_SHAREVIAEMAIL);
  }

  handleEdit = () => {
    const {
      match: {
        params: { kpId },
      },
      history,
    } = this.props;
    const { UI_URL: { METADATA_KP } } = CONFIG;
    history.push({
      pathname: METADATA_KP('step1', kpId), //`/kp/metadata/step3/${kpId}`,
    });
  }

  handleReviewFooterBtns = (e) => {
    if (e.target.id === "btn-edit") {
      this.handleEdit();
    }
  }

  paAnalyticsHandler = (isSave) => {
    const {
      match: {
        params: { kpId },
      },
    } = this.props;

    const {
      KP_CONSUMPTION: {
        KP_PA_RECOMMENDED,
        KP_PA_RECOMMENDED_SAVE
      }
    } = TRIGGERS;
    const { KP_CONSUMPTION } = PRIMARY_CATEGORY;
    const { KP_CONSUMPTION: PAGENAME } = PAGES;
    const { KNOWLEDGE_PAGE_ACTION } = DTMRULE;

    const newAdobeData = {
      KCpage: {
        id: kpId,
      },
      page: {
        category: {
          primaryCategory: KP_CONSUMPTION,
        },
        pageInfo: {
          pageName: PAGENAME,
          pageURL: getmyCurrentLocation(),
        },
      },
      collection: {
        trigger: isSave ? KP_PA_RECOMMENDED_SAVE : KP_PA_RECOMMENDED
      },

    };
    ANALYTICS.kc.sendEventData(newAdobeData, KNOWLEDGE_PAGE_ACTION);
  }

  handlePreviewCloseAction = () => {
    this.setState({ slideIndexNo: 0, defaultDocvizOverlayState: false })
  }

  updateDocvizDoc = (docvizDoc) => {
    try {
      const { slideIndexNo } = this.state;
      if (docvizDoc) {
        if (slideIndexNo > -1 && docvizDoc?.slides?.length && slideIndexNo > docvizDoc?.slides?.length) {
          docvizDoc.currentSlideIndex = docvizDoc?.slides?.length - 1;
        } else {
          docvizDoc.currentSlideIndex = slideIndexNo;
        }
      }
    } catch (error) {
      console.log('Error in updateDocvizDoc function ', error);
    }
  }

  /**
  * Fucntion used to show and hide card scroll view in Docviz
  * @returns bool
  */
  getCardViewValidStatus = (newDocvizDoc, isMDPRestricted) => {
    try {
      const { isPreviewDownloadEntitlement, isPreviewDownloadREntitlement } = this.props;
      return getDocvizSupportedFileState({ newDocvizDoc, isMDPRestricted, isPreviewDownloadEntitlement, isPreviewDownloadREntitlement });
    } catch (error) {
      console.log('Error in getCardViewValidStatus function ', error)
    }
    return false;
  }

  /**
   * Function used to show framwork ribbon
   * @param {*} newDocvizDoc 
   * @param {*} isMDPRestricted 
   * @returns 
   */
  getFrameWorkRibbonStatus = (newDocvizDoc, isMDPRestricted) => {
    try {
      if (newDocvizDoc &&
        this.getCardViewValidStatus(newDocvizDoc, isMDPRestricted) &&
        newDocvizDoc.frameworkSlides &&
        newDocvizDoc.frameworkSlides.length > 0) {
        return true;
      }
    } catch (error) {
      console.log('Error in getFrameWorkRibbonStatus function ', error);
    }
    return false;
  }

  getUserId = async () => {
    const userId = await getUserId();
    this.setState({ userId });
  }

  openModalW = () => {
    this.setState({
      isWModalVisible: true
    });
  }

  closeModalW = () => {
    this.handleChange(false, "isPostPublish");
    this.setState({
      isWModalVisible: false,
    });
  }

  openModalPublish = () => {
    this.setState({
      isPublishModalVisible: true
    });
  }

  setStateData = (key, data) => {
    this.setState({ [key]: data })
  }

  handleToggleSwitchChange = (isToggle) => {
    this.handleChange(isToggle, "isPostPublish");
  }

  getMainKPstate = (resData, defaultLanguage = {}, creationFlow = "") => {
    let practiseareas = [{ 'ipaTags': [], 'fpaTags': [], 'bcgInternals': [] }];
    let paTagsOwner = false;

    // data collection for step3 field: `Designate ONE Sector/Topic as the OWNER`
    if (resData?.ipaTags || resData?.fpaTags || resData?.bcgInternals) {
      practiseareas = [{ 'ipaTags': resData?.ipaTags || [], 'fpaTags': resData?.fpaTags || [], 'bcgInternals': resData?.bcgInternals || [] }]

      // calculate the owner flag for custom field validation of "Step 3 : `Designate ONE Sector/Topic as the OWNER`
      paTagsOwner = isSectorTopicOwnerSelected(practiseareas);
    }

    //renaming the keys as component consumes name-value pairs, api sends title-url pairs
    let links = resData?.additionalURLs || [];
    let renamedLinks = [];
    if (links.length) {
      renamedLinks = links.map(link => ({ name: link.title, value: link.url }));
    }

    // derive URL param to set isURLAdded
    let urlParamData = "0";
    if (!!creationFlow) {
      urlParamData = creationFlow === "url" ? "1" : "0";
    }

    const data = {
      //step1 fields
      title: resData?.title || "",
      description: resData?.description || "",
      descriptionLength: resData?.descriptionLength || 0,
      contacts: resData?.additionalContacts || [],
      additionalURLs: renamedLinks || [],
      additionalContacts: resData?.additionalContacts || [],
      mdpName: resData?.attachment?.case?.mdps || [],
      originalRequestor: resData?.originalRequestor ? [resData?.originalRequestor?.toString()] : [],
      materialUrl: resData?.materialUrl || {
        "address": "",
        "title": ""
      },
      isURLAdded: resData?.isURLAdded || urlParamData,
      authors: resData?.attachment?.authors || [],
      file: {
        name: resData?.attachment?.fileName || "",
        size: resData?.attachment?.fileSize || 0,
      },
      documentPath: resData?.attachment?.documentPath || "",
      documentReplacementDate: resData?.documentReplacementDate || "",
      kpOwner: resData ? (resData.owner ? [resData?.owner] : 0) : [this?.state?.userId], // ==> [null]
      isRestricted: resData?.isRestricted || false,
      relatedToCMP: resData?.relatedToCMP || false,
      relatedToGTM: resData?.relatedToGTM || false,
      isCMPUser: resData?.isCMPUser || false,
      isGTMUser: resData?.isGTMUser || false,
      uniqueId: resData?.uniqueId || "",
      isRelatedToCase: resData?.attachment?.isRelatedToCase || false,
      selectedSuggestedAuthors: resData?.selectedSuggestedAuthors || [],
      caseNumbers: resData?.attachment?.case?.caseNumbers || [],
      id: resData?.id || "",
      practiseAreaTags: practiseareas || [],
      paTagsOwner: paTagsOwner || false, // for custom validation on step3: field Designate ONE Sector/Topic as the OWNER
      regions: resData?.regionCountries || {},
      searchDiscoveryTags: resData?.subjects || [],
      otherlanguage: resData ? (resData?.attachment?.language?.name ? resData?.attachment?.language : defaultLanguage) : defaultLanguage,
      officeLocation: resData?.office?.name ? [resData?.office] : [],
      knowledgeType: resData?.contentType?.name ? resData?.contentType : {},
      isInternal: resData?.contentType?.isInternal ? resData?.contentType?.isInternal : false,
      internalOfficeLocation: resData?.InternalOffice || {},
      status: resData?.status || "",
      version: resData?.version || 1,
      isPostPublish: false,
      isTitleHasSubjects: resData?.isTitleHasSubjects || false,
      isMdpApprovalRequired: resData?.isMdpApprovalRequired || "",
      isMdpApprovalValidationRequired: resData?.isMdpApprovalValidationRequired || false,
    }
    return data;
  }

  updateMeta = async (updatedState, isShowLoader = false) => {
    const {
      KPActions: { saveMetaDataKP }
    } = this.props;
    try {
      // update in db
      saveMetaDataKP(updatedState, isShowLoader);
    } catch (err) {
      console.error(err);
    }
  };

  checkIsTitleHasSubjects = (data) => {
    const { isTitleFiledUpdated, isTitleHasSubjects } = this.props;
    if (data && typeof data === 'object') {
      const { isTitleHasSubjects: mainKpIsTitleHasSubjects } = data;
      if (isTitleFiledUpdated) {
        return isTitleHasSubjects;
      } else {
        return mainKpIsTitleHasSubjects
      }
    }
  }

  getSendKPstate = (data, status, isFinalSubmit) => {

    //renaming the keys as component sends name-value pairs, api needs title-url pairs
    let links = data?.additionalURLs || [];
    const { KNOWLEDGE_STATUS: { REVIEW, PUBLISHED }, IS_MDP_APPROVED_NEWFIELD_KP } = CONFIG;
    let renamedLinks = [];
    if (links.length) {
      renamedLinks = links.map(link => ({ title: link.name, url: link.value }));
    }
    const { tagsSuggestionsList } = this.props;
    const res = {
      title: data.title,
      description: data.description,
      descriptionLength: data.descriptionLength,
      attachment: {
        case: {
          mdps: data.mdpName || [],
          caseNumbers: data.caseNumbers || []
        },
        documentPath: data.documentPath || "",
        isRelatedToCase: data.isRelatedToCase,
        authors: data.authors || [],
        fileName: data.file?.name || "",
        fileSize: data.file?.size || 0,
        language: data?.otherlanguage ? data?.otherlanguage : {}
      },
      originalRequestor: data?.originalRequestor?.toString(),
      documentReplacementDate: data.documentReplacementDate,
      owner: data?.kpOwner[0],
      isRestricted: data.isRestricted,
      relatedToCMP: data.relatedToCMP,
      relatedToGTM: data.relatedToGTM,
      uniqueId: data.uniqueId,
      selectedSuggestedAuthors: data.selectedSuggestedAuthors || [],
      id: data?.id,
      ipaTags: data.practiseAreaTags[0]?.ipaTags || [],
      fpaTags: data.practiseAreaTags[0]?.fpaTags || [],
      bcgInternals: data.practiseAreaTags[0]?.bcgInternals || [],
      prefillEmail: [],
      status,
      updatedBy: data?.id ? this.state.userId : 0,
      regionCountries: data?.regions || [],
      additionalContacts: data?.additionalContacts || [],
      additionalURLs: renamedLinks || [],
      office: data?.officeLocation.length ? data.officeLocation[0] : {},
      InternalOffice: data?.internalOfficeLocation || [],
      subjects: data?.searchDiscoveryTags.length ? data?.searchDiscoveryTags : [],
      contentType: Object.keys(data?.knowledgeType).length ? data?.knowledgeType : {},
      materialUrl: data?.materialUrl || [],
      isURLAdded: data?.isURLAdded || "0",
      version: data?.version || 1,
      isTitleHasSubjects: this.checkIsTitleHasSubjects(data),
      isMdpApprovalRequired: data?.isMdpApprovalRequired || "",
      isMdpApprovalValidationRequired: data?.isMdpApprovalValidationRequired,
      polyHierarchiLength: getLengthOfMultiArrayWithPoly([{
        ipaTags: data.practiseAreaTags[0]?.ipaTags || [],
        fpaTags: data.practiseAreaTags[0]?.fpaTags || [],
        bcgInternals: data.practiseAreaTags[0]?.bcgInternals || []
      }])
    }

    const isRpaReview = getIsRpaReview();
    if (isRpaReview) {
      res["isrpareview"] = true;
    }

    if (!data?.id) {
      // send these attributes to api only once(first time when kpid does not exist)
      res["createdBy"] = this.state.userId;
    }

    if (status === REVIEW && isFinalSubmit) {
      res["sendforReview"] = true;
    }

    if (status === PUBLISHED && isFinalSubmit && tagsSuggestionsList) {
      res["suggestedTags"] = tagsSuggestionsList;
    }

    if (status === PUBLISHED && (data?.version > 1)) {
      res["postPublish"] = data?.isPostPublish;
    }

    if (status !== PUBLISHED && isFinalSubmit && this.checkIsApprovedByMdpShow() && IS_MDP_APPROVED_NEWFIELD_KP) {
      res["isMdpApprovalValidationRequired"] = this.checkIsApprovedByMdpShow();
    }

    if (isFinalSubmit && (status === PUBLISHED || status === REVIEW)) {
      let { REMOVE_TAGS_REGX } = CONFIG;
      /*
        In case of blank description "<p></p>\n" this is going from UI side,
        if description is blank and "<p></p>\n" this string is coming  in description
        then we have to set it back to "" blank string so that we get a validation error from backend side
      */
      let { description } = data;
      try {
        if (!description.replace(REMOVE_TAGS_REGX, "").trim().length) {
          res.description = "";
          res.descriptionLength = 0
        } else {
          res.descriptionLength = getDescriptionCharLength(description);
        }
      } catch (error) {
        console.error('getSendKPstate func description error ', error)
      }
    }
    return res;
  }

  getFormData = (status = CONFIG.DRAFT_STATUS, isFinalSubmit = false) => {
    const { mainKPState } = this.state;
    return this.getSendKPstate(mainKPState, status, isFinalSubmit);
  }

  getDifference = (array1, array2) => {
    return array1.filter(object1 => {
      return !array2.some(object2 => {
        return object1?.label_guid === object2?.label_guid;
      });
    });
  }

  getAcceptedTags = (array1, array2) => {
    return array1.filter(object1 => {
      return array2.some(object2 => {
        return object1?.label_guid === object2?.label_guid;
      });
    });
  }
  getMetaData = () => {
    const {
      kpMetaData,
      history,
      publishedData,
    } = this.props;
    const isInPreviewMode = history.location.pathname.includes('preview');
    return isInPreviewMode ? kpMetaData : publishedData;
  }

  isKpNewLayoutEnabled = () => {
    const consumptionMetaData = this.getMetaData();
    const { isPreviewDownloadEntitlement, isPreviewDownloadREntitlement } = this.props;
    const enabled = isPreviewDownloadEntitlement && isPreviewDownloadREntitlement || (isPreviewDownloadEntitlement === true && isPreviewDownloadREntitlement === false && consumptionMetaData?.isRestricted === false);
    return enabled;
  }

  isKpNewLayoutFn = () => {

    const consumptionMetaData = this.getMetaData();
    const { attachment } = consumptionMetaData;
    return IS_KPC_NEW_LAYOUT && isFileTypePPT(attachment?.fileName) && this.isKpNewLayoutEnabled();
  }

  formatList = (currentList) => {
    let data = [];
    currentList.forEach(object => {
      data.push({
        "label_guid": object?.id || object?.label_guid,
        "label_value": object?.name || object?.label_value
      });
    });
    return data;
  }

  getFeedbackData = (current, suggestion) => {
    let accepted = [];
    let rejected = [];
    let enteredTags = [];
    try {
      const currentList = this.formatList(current);
      const suggestionList = this.formatList(suggestion);
      enteredTags = this.getDifference(currentList, suggestionList);
      rejected = this.getDifference(suggestionList, currentList);
      accepted = this.getAcceptedTags(suggestionList, currentList)
    } catch (e) {
      console.error("getFeedbackData method failed", e);
    }
    return {
      accepted,
      rejected,
      enteredTags
    }
  }

  finalSubmitHandler = () => {
    const { submitFeedbackRecoomendedTags, recommendedTagsDataByFile } = this.props;
    const { mainKPState: { id, isInternal }, comment } = this.state;
    const { KNOWLEDGE_STATUS: { PUBLISHED, REVIEW }, IS_RECOMMENDED_TAGGING_ON } = CONFIG;
    const isReview = this.isReview();
    const formData = isReview ? this.getFormData(REVIEW, true) : this.getFormData(PUBLISHED, true);
    if (comment)
      formData.comment = comment;
    const { uniqueId, subjects } = formData;
    const { Subject: subjectSuggestions } = recommendedTagsDataByFile;

    if (IS_RECOMMENDED_TAGGING_ON && uniqueId && uniqueId !== "0" && !!subjects?.length && !!subjectSuggestions?.length && !isReview && !isInternal) {
      const feedbackData = this.getFeedbackData(subjects, subjectSuggestions);
      submitFeedbackRecoomendedTags({
        taskId: uniqueId,
        type: "S",
        feedbackData
      });
    }
    this.updateMeta(formData, true);
    ANALYTICS.kc.sendEventData({
      page: {
        pageInfo: {
          pageName: PAGES.KP_PREVIEW //evar1
        },
        category: {
          primaryCategory: PRIMARY_CATEGORY.KP
        }
      },
      collection: {
        trigger: this.isReview() ? TRIGGERS.SEND_FOR_REVIEW : TRIGGERS.PUBLISH,
        screenName: SCREEN_NAMES.KP_STEP_3,
      },
      KCpage: {
        id: id
      }
    }, DTMRULE.KP);
  }

  handleChange = (data, keyName) => {
    let value = data;
    let name = keyName;
    const { mainKPState } = this.state;
    let newValueObj = {};
    if (value !== mainKPState[name]) {
      newValueObj[name] = value;
      //set the state finally
      this.setState(
        {
          mainKPState: { ...mainKPState, ...newValueObj },
        }
      );
    }
  };

  cloneHandler = (isOpen) => {
    this.setState({
      showCloneModal: isOpen
    });
  }

  closeCloneModalCallback = () => {
    const { mainKPState: { id } } = this.state;
    this.cloneHandler(false);
    // Adobe Analytics event262 - clone cancel click
    ANALYTICS.kc.sendEventData({
      page: {
        pageInfo: {
          pageName: PAGES.KP_PREVIEW //evar1
        },
        category: {
          primaryCategory: PRIMARY_CATEGORY.KP
        }
      },
      KCpage: {
        id: id
      }
    }, DTMRULE.CLONE_CANCEL_BUTTON_CLICK);
  }

  cloneSubmitCallback = () => {
    const { mainKPState: { id } } = this.state;
    // Adobe Analytics event261 - clone KP
    ANALYTICS.kc.sendEventData({
      page: {
        pageInfo: {
          pageName: PAGES.KP_PREVIEW //evar1
        },
        category: {
          primaryCategory: PRIMARY_CATEGORY.KP
        }
      },
      KCpage: {
        id: id
      }
    }, DTMRULE.CLONE_BUTTON_CLICK);
  }

  routeToContribution = () => {
    const { history } = this.props;
    const { MYNAVIGATOR_TABS: { CONTRIBUTIONS } } = CONSTANTS;
    history.push({
      pathname: CONFIG.UI_URL.HOME,
      search: `?tabselected=${CONTRIBUTIONS}`
    });
  }

  routeToHomePage = () => {
    const { history } = this.props;
    history.push({
      pathname: CONFIG.UI_URL.HOME
    });
  }

  checkIsApprovedByMdpShow = () => {
    const { mainKPState: { knowledgeType, status, isMdpApprovalRequired, isInternal } } = this.state;
    const { userDetails } = this.props;
    const isReview = this.isReview();
    const isMdpOwner = isOwnerMdp(userDetails);
    return conditionsApprovalMdp(isReview, isInternal, isMdpOwner, knowledgeType, status, isMdpApprovalRequired);
  }

  render() {
    const { history: { location: { search } } } = this.props;
    const { SITECORE_XM_ENABLED } = CONFIG;
    const searchParams = new URLSearchParams(search);
    const isRpaReviewParam = searchParams.get('isrpareview');
    const isRpaReview = isRpaReviewParam === "true";
    const {
      kpMetaData,
      fileExistsOnBucket,
      history,
      publishedData,
      isPreviewDownloadEntitlement,
      isPreviewDownloadREntitlement,
      isCreateEditEntitlement,
      isEditEntitlement,
      isPublishEntitlement,
      getDownloadsCount,
      downloads,
      associatedBundles,
      isparecommender,
      associatedCases,
      updatePaRecommended,
      getKPFile,
      isWarningModalVisible,
      warningModalStepsList,
      getInRoleData
    } = this.props;
    const {
      btnsConfig,
      initiatePolling,
      defaultDocvizOverlayState,
      isWModalVisible,
      isDocvizLoadError,
      comment: commentText,
      mainKPState,
      isPublishModalVisible,
      showCloneModal
    } = this.state;
    const {
      props: {
        match: {
          params: { kpId },
        }
      },
    } = this;
    const isInPreviewMode = history.location.pathname.includes('preview');
    const consumptionMetaData = isInPreviewMode ? kpMetaData : publishedData;
    const isFileExist =
      fileExistsOnBucket[kpId] && !!consumptionMetaData?.attachment?.fileName;
    const {
      KP: {
        PUBLISH_REQUIRED_FIELDS_WARNING_MSG,
      },
      KPCONSUMPTIONGROUP: {
        TOPBAR_HEADING_PREVIEW,
        TOPBAR_HEADING_UNPUBLISHED,
        TOPBAR_MESSAGE_PREVIEW,
        TOPBAR_HEADING_REVIEW,
        TOPBAR_MESSAGE_REVIEW,
        KNOWLEDGE_PAGE,
        TOPBAR_MESSAGE_ARCHIVED_PAGE,
        ADDITIONAL_CONTACTS,
        ORIGINAL_REQUESTOR,
        AUTHORS,
        TOPBAR_HEADING_ARCHIVED,
        TOPBAR_MESSAGE_PARECOMM_DISABLED,
        EDIT_PAGE
      },
      BUTTONS: {
        OK
      },
      BUNDLE_TYPE: { TOPIC_COLLECTION },
      PAGETITLE: {
        PREVIEW_PREFIX
      },
      KP: { PUBLISHED_MESSAGE, KP_NEW_PUBLISHED_MESSAGE, REVIEW_MESSAGE, HOME, CONTRIBUTIONS }
    } = LABELS;
    const fileDetails = { fileName: consumptionMetaData?.attachment?.fileName, documentPath: consumptionMetaData?.attachment?.documentPath, isFileExist: isFileExist, startFileDownload: this.handleFileDownload };
    const { contentType: name, title, description, updatedDate, materialUrl, isRestricted, lockedBy, relatedToGTM, InternalOffice } = consumptionMetaData;
    const {
      AUTHOR_CARD,
      ADDITIONAL_CONTACT_CARD,
      ORIGINAL_REQUESTOR_CARD
    } = TRIGGERS;
    const consumptionData = {
      name: name?.name,
      title,
      description,
      updatedDate,
      isBundle: false,
      isCollection: false,
      isMDPRestricted: isRestricted,
      isInPreviewMode: isInPreviewMode,
    }
    const tabsTriggers = { firstTab: AUTHOR_CARD, secondTab: ADDITIONAL_CONTACT_CARD, thirdTab: ORIGINAL_REQUESTOR_CARD };
    const {
      peopleResultsActions: { getPeopleDataUsingIDs },
      docvizDoc,
      getPaRecommendedData,
      isViewClientInfo,
      paRecommendedApiError,
      getDocvizMetadata
    } = this.props;
    let totalTags = [];
    let paRecommendedCount = 0;
    const url = CONFIG.UI_URL.KP_CONSUMPTION(kpId);
    const previewUrl = window.location.host + url;
    const {
      KNOWLEDGE_STATUS: {
        PUBLISHED,
        ARCHIVED,
        UNPUBLISHED,
        REVIEW
      },
      IS_ARCHIVE_VISIBLE,
      API_URL: {
        DOCVIZ_POOLING,
      },
      XA,
      DOCVIZ_POOLING_API_DATA: {
        TIME,
        INITIAL_TIME,
        CALLBACK_KEYS,
        ERROR_MSZ
      }
    } = CONFIG;

    const isStatusArchived = consumptionMetaData.status === ARCHIVED;
    const isStatusPublished = consumptionMetaData.status === PUBLISHED;
    const isStatusReview = consumptionMetaData.status === REVIEW;
    const isStatusUnpublished = consumptionMetaData.status === UNPUBLISHED;

    const isEditVisible = isStatusPublished;
    const isTopBarVisible = consumptionMetaData?.status && (isStatusPublished ? isparecommender : true);
    const isDraftDeleteVisible = !isStatusPublished && isCreateEditEntitlement && !isStatusReview;
    const isArchiveVisible = isStatusPublished && isPublishEntitlement && IS_ARCHIVE_VISIBLE;
    const isContributeKnowlegeVisible = (!isInPreviewMode || isStatusReview) && name?.name !== TOPIC_COLLECTION;
    const isMyNavigatorVisible = !isInPreviewMode || isStatusReview;
    const isValidDocviz = isValidDocvizFile(consumptionMetaData?.attachment?.fileName);
    let { ipaTags = [], fpaTags = [], bcgInternals = [], subjects = [] } = consumptionMetaData;
    if (!isEmpty(consumptionMetaData) && Object.entries(consumptionMetaData).length > 0) {
      totalTags = [...ipaTags, ...fpaTags, ...bcgInternals];
      paRecommendedCount = getCountOfFlagsAsTrue(totalTags, "isPARecommended");
    }
    let heading, message, barColor, popupMessage, paBtnDisabled = false;
    if (isStatusUnpublished) {
      heading = TOPBAR_HEADING_UNPUBLISHED;
      message = CONFIG.TOPBAR_MESSAGE_UNPUBLISHED(KNOWLEDGE_PAGE);
    } else if (isStatusArchived) {
      heading = TOPBAR_HEADING_ARCHIVED;
      message = TOPBAR_MESSAGE_ARCHIVED_PAGE;
    } else if (isStatusPublished) {
      barColor = 'layout_Kbconsumption__topbar-color';
      if (!!lockedBy || !!paRecommendedApiError) {
        message = TOPBAR_MESSAGE_PARECOMM_DISABLED;
        paBtnDisabled = true;
      } else {
        message = CONFIG.TOPBAR_MESSAGE_PUBLISHED_PA(KNOWLEDGE_PAGE);
        popupMessage = CONFIG.TOPBAR_MESSAGE_PUBLISHED_PA_MODAL(KNOWLEDGE_PAGE);
      }
    } else if (isStatusReview) {
      barColor = 'layout_Kbconsumption__topbar-color';
      heading = TOPBAR_HEADING_REVIEW;
      message = TOPBAR_MESSAGE_REVIEW;
    } else {
      barColor = 'layout_Kbconsumption__topbar-color';
      heading = TOPBAR_HEADING_PREVIEW;
      message = TOPBAR_MESSAGE_PREVIEW;
    }
    let succesMessage = '';
    if (mainKPState?.status === PUBLISHED) {
      succesMessage = SITECORE_XM_ENABLED ? KP_NEW_PUBLISHED_MESSAGE : PUBLISHED_MESSAGE;
    } else if (mainKPState?.status === REVIEW) {
      succesMessage = REVIEW_MESSAGE;
    }
    let showThumbsUpDown = false;
    const { TITLES_ALLOWED_THUMBSUPDOWN_KP, SKIPPED_THUMS_UPDOWN_ENABLED } = CONFIG;
    const { userDetails } = this.props;
    let isUserAllowedThumbsupdown = userDetails && TITLES_ALLOWED_THUMBSUPDOWN_KP.indexOf(userDetails?.businessTitle) !== -1;
    if (isStatusPublished && relatedToGTM &&
      (isUserAllowedThumbsupdown || !!window.byPassTitleCheck || SKIPPED_THUMS_UPDOWN_ENABLED)) {
      showThumbsUpDown = true;
    }
    let showAssociatedOffices = false;
    if (InternalOffice?.length) {
      showAssociatedOffices = true;
    }
    const pollingData = {
      url: DOCVIZ_POOLING(kpId, isInPreviewMode),
      api_key: XA,
      time: TIME,
      initialTime: INITIAL_TIME,
      callbackKey: CALLBACK_KEYS,
      callback: () => {
        this.setState({
          initiatePolling: false
        });
        getDocvizMetadata(kpId, isInPreviewMode)
      },
      errorMsz: ERROR_MSZ,
      fileDetails: {
        fileName: fileDetails?.fileName,
        documentPath: fileDetails?.documentPath,
        kpId: kpId,
        resultType: "MA"
      }
    }
    const associatedCasesFinal = associatedCases?.length ? associatedCases.filter((caseItem) => !!caseItem?.id) : [];

    this.updateDocvizDoc(docvizDoc);
    const warningStepsData = this.creatingWarningStepsData(warningModalStepsList);

    const isKpNewLayout = this.isKpNewLayoutFn() && !isDocvizLoadError;

    return (
      <Layout
        className="layout_kpconsumption"
        id="creationdashboard"
        layoutClass="layout_kpconsumption"
      >
        {!isEmpty(consumptionMetaData) && (
          <>
            <Helmet>
              <title>{isInPreviewMode ? PREVIEW_PREFIX + title : title}</title>
            </Helmet>

            {(isTopBarVisible) && (
              <TopBar
                heading={heading}
                message={message}
                barColor={barColor}
                paButton={isStatusPublished && isparecommender}
                paButtonDisabled={paBtnDisabled}
                getPaRecommendedData={getPaRecommendedData}
                updatePaRecommended={updatePaRecommended}
                popupMessage={popupMessage}
                savePaClickCallback={() => this.paAnalyticsHandler(true)}
                buttonPaClickCallback={() => this.paAnalyticsHandler(false)}
                isPublishEntitlement={isPublishEntitlement}
                button={{
                  isShow: isStatusReview,
                  text: EDIT_PAGE,
                  getClick: () => this.handleEdit()
                }}
              />
            )}
            <ConsumptionHeader
              consumptionData={consumptionData}
              consumptionMetaData={consumptionMetaData}
              paRecommendedCount={paRecommendedCount}
              editIconEntitlement={isEditEntitlement}
              publishEntitlement={isPublishEntitlement}
              isContinueEditVisible={isCreateEditEntitlement}
              getDownloadsCount={getDownloadsCount}
              downloads={downloads}
              getConsumptionData={this.getConsumptionData}
              hideEllipsis={isStatusReview ? false : isInPreviewMode}
              hideShare={isInPreviewMode}
              isContributeKnowlegeVisible={isContributeKnowlegeVisible}
              isMyNavigatorVisible={isMyNavigatorVisible}
              isArchiveVisible={isArchiveVisible}
              isEditVisible={isEditVisible}
              isDeleteVisible={isDraftDeleteVisible}
              isDraftVisible={isDraftDeleteVisible}
              emailBody={this.getShareViaEmailBody()}
              copyURLAnalytics={this.copyLinkAnalytics}
              shareviaEmailAnalytics={this.shareViaEmailAnalytics}
              isRpaReview={isRpaReview}
              showThumbsUpDown={showThumbsUpDown}
              showAssociatedOffices={showAssociatedOffices}
              internalOffice={InternalOffice}
              finalSuccess={this.thumbsUpDownAnalyticsCallback}
              isUnpublishLoader={this.props.isVisibleFPLoader}
            />
            <div className={`${isKpNewLayout ? "layout_kpconsumption__twoCols" : ""}`}>
              <div className={`${isKpNewLayout ? "layout_kpconsumption__twoCols-leftsec" : ""}`}>
                <span className="consumptionDivider" hidden={false} />
                <ConsumptionDescription
                  description={consumptionData?.description}
                  isMDPRestricted={consumptionData?.isMDPRestricted}
                  isInPreviewMode={isInPreviewMode}
                  fileDetails={fileDetails}
                  linkClickAnalytics={this.onLinkClickCallback}
                  materialUrl={materialUrl}
                  isPreviewDownloadEntitlement={isPreviewDownloadEntitlement}
                  isPreviewDownloadREntitlement={isPreviewDownloadREntitlement}
                  previewUrl={previewUrl}
                  id={kpId}
                  kpMetaData={consumptionMetaData}
                  isDocvizNotSupported={!isValidDocviz}
                  docvizDoc={docvizDoc}
                  pollingData={pollingData}
                  initiatePolling={initiatePolling}
                  downloadApplicationName={KNOWLEDGE_PAGE_APP_NAME}
                  openDocvizOverlay={defaultDocvizOverlayState}
                  handlePreviewCloseAction={this.handlePreviewCloseAction}
                  showFrameWorkRibbon={this.getFrameWorkRibbonStatus(docvizDoc, consumptionData?.isMDPRestricted)}
                  showReleventMarkers={this.getFrameWorkRibbonStatus(docvizDoc, consumptionData?.isMDPRestricted)}
                  getKPFile={getKPFile}
                  showOnlyDescription={isKpNewLayout}
                />
                <div className={`layout_kpconsumption__tabs`}>
                  <ConsumptionContacts
                    data={[
                      { heading: AUTHORS, data: consumptionMetaData?.attachment?.authors },
                      { heading: ADDITIONAL_CONTACTS, data: consumptionMetaData.additionalContacts },
                      { heading: ORIGINAL_REQUESTOR, data: !!consumptionMetaData?.originalRequestor ? [consumptionMetaData.originalRequestor] : [], hideIndiviualCount: true }
                    ]}
                    tabsTriggers={tabsTriggers}
                    getPeopleDataUsingIDs={getPeopleDataUsingIDs}
                    cardClickCallback={this.consumptionContactsAnalyticsCallback}
                    onTabClicked={this.contactsTabClickCallback}
                  />
                  <ConsumptionAdditionalContent
                    metaData={consumptionMetaData}
                    associatedBundles={associatedBundles}
                    associatedCases={!!isViewClientInfo && !!isPublishEntitlement ? associatedCasesFinal : []}
                    onLinkClickCallback={this.onLinkClickCallback}
                    associatedBundlesClickCallback={this.associatedBundlesClickCallback}
                  />
                </div>
              </div>
              {!!isKpNewLayout && (<div className="layout_kpconsumption__twoCols-rightsec">
                <ConsumptionDescription
                  needHeaderMargin={false}
                  description={consumptionData?.description}
                  isMDPRestricted={consumptionData?.isMDPRestricted}
                  isInPreviewMode={isInPreviewMode}
                  fileDetails={fileDetails}
                  linkClickAnalytics={this.onLinkClickCallback}
                  materialUrl={materialUrl}
                  isPreviewDownloadEntitlement={isPreviewDownloadEntitlement}
                  isPreviewDownloadREntitlement={isPreviewDownloadREntitlement}
                  previewUrl={previewUrl}
                  id={kpId}
                  kpMetaData={consumptionMetaData}
                  isDocvizNotSupported={!isValidDocviz}
                  docvizDoc={docvizDoc}
                  pollingData={pollingData}
                  initiatePolling={initiatePolling}
                  downloadApplicationName={KNOWLEDGE_PAGE_APP_NAME}
                  openDocvizOverlay={defaultDocvizOverlayState}
                  handlePreviewCloseAction={this.handlePreviewCloseAction}
                  showFrameWorkRibbon={this.getFrameWorkRibbonStatus(docvizDoc, consumptionData?.isMDPRestricted)}
                  showReleventMarkers={this.getFrameWorkRibbonStatus(docvizDoc, consumptionData?.isMDPRestricted)}
                  getKPFile={getKPFile}
                  showOnlyDocviz={isKpNewLayout}
                />
              </div>)
              }
            </div>
            <div className="layout_kpconsumption__tabs">
              <ConsumptionTaxonomySectionV1
                onTabClicked={this.sendAnalyticsOnTabClick}
                subjectsShowMore
                subjects={subjects}
                ipaTagList={ipaTags}
                fpaTagList={fpaTags}
                bcgInternalList={bcgInternals}
              />
            </div>
            {/* Warning modal before confirmation for publish and Review both cases */}
            <WarningModal
              heading={this.isReview() ? REVIEW_QUESTION : PUBLISH_QUESTION}
              button1={{
                text: WARNING_MODAL_CANCEL,
                clickHandler: this.closeModalW,
              }}
              button2={{
                text: WARNING_MODAL_CONFIRM,
                clickHandler: this.finalSubmitHandler,
              }}
              isOpen={isWModalVisible}
              commentTextField={this.isReview() ? false : (kpMetaData?.version > 1)}
              showPreviewBar={(SITECORE_XM_ENABLED ? getInRoleData.IsCMPUser : kpMetaData?.isCMPUser) && !this.isReview() && (kpMetaData?.version > 1)}
              setCommentCallback={this.setStateData}
              onRequestClose={this.closeModalW}
              comment={commentText}
              previewBarCallback={this.handleToggleSwitchChange}
            />
            {/* Success modal for publish and review */}
            <WarningModal
              overlayClassName={`${showCloneModal ? "knowledgepage__warningwrap--hide" : ""}`}
              heading={succesMessage}
              headingAlign={"left"}
              button1={this.isReview() ? {
                text: CONTRIBUTIONS,
                clickHandler: this.routeToContribution,
                colorClass: "black"
              } : {
                text: SITECORE_XM_ENABLED ? LABELS.BUTTONS.XMC_HOME : HOME,
                clickHandler: SITECORE_XM_ENABLED ? this.routeToContribution : this.routeToHomePage,
                colorClass: "black"
              }}
              customWidth={this.isReview() ? 640 : false}
              button2={this.isReview() ? {
                text: LABELS.BUTTONS.CONTRIBUTE_NEW_KNOWLEDGE,
                clickHandler: () => {
                  history.push(
                    {
                      pathname: CONFIG.UI_URL.CREATION
                    }
                  );
                },
                colorClass: "green"
              } : {
                text: SITECORE_XM_ENABLED ? LABELS.BUTTONS.CONTRIBUTE_MORE : LABELS.BUTTONS.VIEW_CONSUMPTION,
                clickHandler: () => {
                  history.push(
                    {
                      pathname: SITECORE_XM_ENABLED ? (GLOBAL_CONFIG.UI_URL.CREATION) : (GLOBAL_CONFIG.UI_URL.KP_CONSUMPTION(mainKPState?.id))
                    }
                  );
                },
                colorClass: "green"
              }}
              {...(!SITECORE_XM_ENABLED && {
                button3: {
                  text: LABELS.BUTTONS.CLONE,
                  clickHandler: () => this.cloneHandler(true)
                }
              })}
              iconName={'check'}
              isOpen={isPublishModalVisible}
            />
            <CloneModal
              id={mainKPState?.id}
              status={mainKPState?.status}
              showCloneModal={showCloneModal}
              closeModalCallback={this.closeCloneModalCallback}
              cloneSubmitCallback={this.cloneSubmitCallback}
            />
            {isInPreviewMode && (
              <>
                <FooterWorkflow buttons={btnsConfig} getEvent={this.footerButtonsClick} isStickyAtBottom />
                <WarningModal
                  heading={PUBLISH_REQUIRED_FIELDS_WARNING_MSG}
                  iconName={'warning'}
                  button1={{
                    text: OK,
                    clickHandler: this.cancelWarningModal,
                  }}
                  isOpen={isWarningModalVisible}
                  KPWarningModalBodySection={<WorkflowWarningSteps stepsConfig={{
                    stepsList: warningStepsData,
                    cancelOldModal: true,
                    cancelOldModalFunc: this.closeModalW,
                    btnClickHandler: this.goToKpStepsPages
                  }} />}
                  onRequestClose={this.cancelWarningModal}
                  kbeWarningCustomCls="kbeWarning__customCls"
                />
              </>
            )}
          </>
        )}
      </Layout>
    );
  }
}

KpConsumptionContainer.defaultProps = {
  inputByEmail: false,
};
const mapStateToProps = (state) => ({
  kpMetaData: kpSelectors.getMetadata(state),
  isLoading: kpSelectors.getLoading(state),
  associatedCases: caseSelectors.getCasesMetaDetails(state),
  peopleResults: peopleResultsSelectors.getPeopleResults(state, 'KP_Details'),
  fileExistsOnBucket: kpSelectors.getPreviewDocumentStatus(state),
  publishedData: kpSelectors.getPublishedMetadata(state),
  downloads: kpSelectors.getDownloadsCount(state),
  isCreateEditEntitlement: entitlementSelectors.getEntitlementValue(state, KNOWLEDGE_CONTRIBUTIONS, CREATE_EDIT_DRAFT),
  isPreviewDownloadEntitlement: entitlementSelectors.getEntitlementValue(state, KNOWLEDGE_CONTRIBUTIONS, PREVIEW_DOWNLOAD),
  isPreviewDownloadREntitlement: entitlementSelectors.getEntitlementValue(state, KNOWLEDGE_CONTRIBUTIONS, PREVIEW_DOWNLOAD_R),
  isEditEntitlement: entitlementSelectors.getEntitlementValue(state, KNOWLEDGE_CONTRIBUTIONS, EDIT_PUBLISHED_CONTENT),
  isPublishEntitlement: entitlementSelectors.getEntitlementValue(state, KNOWLEDGE_CONTRIBUTIONS, PUBLISH_CONTENT),
  isWarningModalVisible: kpSelectors.getWarningModalStatus(state),
  warningModalStepsList: kpSelectors.getWarningStepsList(state),
  isVisibleFPLoader: globalSelectors.isVisibleFPLoader(state),

  isViewClientInfo: entitlementSelectors.getEntitlementValue(state, CASE_PAGES, VIEW_CLIENT_INFO),

  associatedBundles: kpSelectors.getAssociatedBundles(state),
  docvizDoc: docvizSelectors.getDocvizDocument(state),
  isparecommender: isPaRecommendedSelectors.getPaRecommendedStatus(state),
  getPaRecommendedData: isPaRecommendedSelectors.getPaRecommendedData(state),
  paRecommendedApiError: isPaRecommendedSelectors.getPaRecommendedError(state),
  userDetails: globalSelectors.getUserDetails(state),
  recommendedTagsDataByFile: recommendedTaggingSelectors.getRecommendedTagsData(state),
  defaultLanguage: kpSelectors.getLanguageID(state, "English"),
  tagsSuggestionsList: kpSelectors.getTagsSuggestionsList(state),
  isTitleFiledUpdated: kpSelectors.getTitleFiledUpdatedStatus(state),
  isTitleHasSubjects: kpSelectors.getSubjectSearchStatus(state),
  getInRoleData: kpSelectors.getInRoleData(state),
});

const mapDispatchToProps = (dispatch) => ({
  KPActions: bindActionCreators({ ...KPActions }, dispatch),
  getDownloadsCount: (KPId) => dispatch({
    type: KP.GET_DOWNLOADS,
    payload: { KPId }
  }),
  getFileStatus: (KPId) => dispatch({
    type: KP.GET_FILES_STATUS,
    payload: {
      KPIds: [KPId],
      appName: KNOWLEDGE_PAGE_APP_NAME,
      type: CONFIG.downloadTypeForKP
    }
  }),
  getCasesMetaDetails: (caseNumbers) => dispatch(getCasesMetaDetails(caseNumbers)),
  getKPFile: (kpId) => dispatch({
    type: KP.GET_FILE,
    payload: {
      kpId: kpId,
      appName: KNOWLEDGE_PAGE_APP_NAME,
      type: CONFIG.downloadTypeForKP
    }
  }),
  peopleResultsActions: bindActionCreators(
    { ...peopleResultsActions },
    dispatch
  ),
  getAssociatedBundles: (kpId) => dispatch({ type: KP.GET_ASSOCIATED_BUNDLES, payload: { kpId } }),
  getIsPaRecommended: (id) => dispatch(isPaRecommended(id)),
  resetWarningModal: () => dispatch({ type: KP.RESET_WARNING_MODAL }),
  updatePaRecommended: (data) => dispatch(updateRecommendPA(data)),
  getDocvizMetadata: (id, isInPreviewMode) => dispatch({ type: DOCVIZ.GET_DOCVIZ_METADATA, payload: { id, isInPreviewMode } }),
  resetDocvizData: () => dispatch(resetDocvizMetadata()),
  getPolyhierarchyApiData: () => dispatch(getPolyhierarchyApiData()),
  callTaggingStatus: (taskId) => dispatch(getTaggingStatus(taskId)),
  submitFeedbackRecoomendedTags: (params) => dispatch(postFeedbackRecommendedTags(params)),
  uploadStart: (data) => dispatch(uploadStart(data))
});

export const KpConsumption = connect(
  mapStateToProps,
  mapDispatchToProps
)(KpConsumptionContainer);

export { KpConsumptionContainer };
