import { put, call, takeLatest } from 'redux-saga/effects';
// Constant
import { PA_RECOMMENDED } from "redux/constants";
// Config
import CONFIG from "config";
// Axios Util
import { doAxiosRequest } from "config-axios";
// Actions
import {
  isRolePARecommenderPending, isRolePARecommenderSuccess, isRolePARecommenderFailure,
  isPaRecommendedSuccess, isPaRecommendedPending, isPaRecommendedFailure,
  updateRecommendPASuccess, updateRecommendPAPending, updateRecommendPAFailure,
} from "./parecommended.actions";
import { hidePageLoader } from "redux/actions/global/global.actions";
import { moduleFailure } from "redux/global.action.js";

export function* isRolePaRecommender() {
  try {
    yield put(isRolePARecommenderPending());
    const { API_URL: { IS_PA_RECOMMENDER_ROLE }, XA } = CONFIG;

    const axiosConfig = {
      method: 'GET',
      endpoint: IS_PA_RECOMMENDER_ROLE(),
      headers: {
        'x-api-key': XA
      }
    };
    const response = yield call(doAxiosRequest, axiosConfig);
    yield put(isRolePARecommenderSuccess(response?.data));
    // yield put(isRolePARecommenderSuccess({ isPaRecommended: false }));
  } catch (error) {
    yield put(isRolePARecommenderFailure(error));
    yield put(hidePageLoader());
  }
}

export function* isPaRecommended(action) {
  try {
    yield put(isPaRecommendedPending());
    const { payload: { id } } = action;
    const { API_URL: { IS_PA_RECOMMENDED_PATH }, XA } = CONFIG;

    const axiosConfig = {
      method: 'GET',
      endpoint: IS_PA_RECOMMENDED_PATH(id),
      headers: {
        'x-api-key': XA
      }
    };
    const response = yield call(doAxiosRequest, axiosConfig);
    yield put(isPaRecommendedSuccess(response.data || []));
  } catch (error) {
    yield put(isPaRecommendedFailure(error));
  }
};

export function* updateRecommendPA(action) {
  try {
    yield put(updateRecommendPAPending());
    const { API_URL: { UPDATE_RECOMMEND_PA_PATH }, XA } = CONFIG;

    const axiosConfig = {
      method: 'POST',
      endpoint: UPDATE_RECOMMEND_PA_PATH(),
      params: action.payload,
      headers: {
        'x-api-key': XA
      }
    };
    const response = yield call(doAxiosRequest, axiosConfig);
    yield put(updateRecommendPASuccess(response.data || []));
  } catch (error) {
    yield put(updateRecommendPAFailure(error));
  }
};

export default function* PaRecommendedSaga() {
  try {
    yield takeLatest(PA_RECOMMENDED.IS_PA_RECOMMENDED, isPaRecommended);
    yield takeLatest(PA_RECOMMENDED.UPDATE_RECOMMEND_PA, updateRecommendPA);
    yield takeLatest(PA_RECOMMENDED.IS_ROLE_PA_RECOMMENDER, isRolePaRecommender);
  } catch (error) {
    yield put(moduleFailure(error, 'PaRecommendedSaga'));
  }
}
