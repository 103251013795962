import React from 'react';
import PropTypes from 'prop-types';
import LABELS from "labels";
import './VersionListing.scss';
import moment from 'moment';
import CONFIG from 'config';
import { v4 as uuidv4 } from 'uuid';


export const removeDuplicateLabels = (data, FIELD_MAPPING_KP) => {
  const newLabelArray = [];
  return data?.fieldsChanged?.map((field) => FIELD_MAPPING_KP[field.toLowerCase()])
    .filter((newLabel) => {
      if (newLabelArray.indexOf(newLabel) === -1) {
        newLabelArray.push(newLabel);
        return true;
      }
      return false;
    });
};

const VersionListing = ({
  adminHistoryData,
  adminHistoryTableKey
}) => {
  const {
    ADMIN_HISTORY_SECTION: {
      AVAILABLE_CONSUMPTION
    }
  } = LABELS;
  const { CP_UI_DATE_FORMAT, FIELD_MAPPING_KP } = CONFIG;



  return (
    !!adminHistoryData && adminHistoryData.length > 0 &&
    <>
      <div className="versionlisting__columns">
        {Object.keys(adminHistoryTableKey).map(field => (
          <div
            key={field}
            className={`versionlisting__column versionlisting__column--${field.toLowerCase()} 
              ${field.toLowerCase() === 'version' && 'versionlisting__center'}`}
          >
            {adminHistoryTableKey[field]}
          </div>
        ))}
      </div>
      <div className="table-comtent">
        {!!adminHistoryData && adminHistoryData.map((data, index) => (
          <div class="versionlisting__tablecontent" key={index}>
            <section className="versionlisting">
              <div className="versionlisting__value versionlisting__value--date">{moment(new Date(data.date)).format(CP_UI_DATE_FORMAT)}</div>
              <div className="versionlisting__value versionlisting__value--status">{data.status}</div>
              <div className="versionlisting__value versionlisting__value--changed_by">{data.un}</div>
              <div className="versionlisting__value versionlisting__value--version versionlisting__center">{data.version}
                {data.isAvailableConsumption &&
                  <> <br />{AVAILABLE_CONSUMPTION}</>
                }
              </div>
              <div className="versionlisting__value versionlisting__value--change_description">{data.comment}</div>
              <div className='versionlisting__value versionlisting__value--updatedFields'>
                {removeDuplicateLabels(data, FIELD_MAPPING_KP)?.map((label) => (
                  <span key={uuidv4()} className='versionlisting__value versionlisting__value--updatedFields versionlisting__inline'>{label}</span>
                ))}
              </div>
            </section>
          </div>
        ))}
      </div>
    </>
  );
};

VersionListing.propTypes = {
  adminHistoryData: PropTypes.array.isRequired,
  adminHistoryTableKey: PropTypes.object.isRequired
};

export default React.memo(VersionListing);
