import React from 'react';
import { LoginCallback } from "@okta/okta-react";
import { getFromLocalStorageAsJSON, removeFromLocalStorage } from 'utils/localStorage/localStorage';
import ErrorPage from "containers/ErrorPage/ErrorPage";
import CONFIG from "config";

const ImplicitCallbackWrapper = () => {
    // ENR-13019 -- temp fix : we need to eventually do this clear token on Okta expiration flow
    if (getFromLocalStorageAsJSON(CONFIG.OKTA.STK)) {
        removeFromLocalStorage(CONFIG.OKTA.STK);
    }
    return <LoginCallback errorComponent={ErrorPage} />;
};

export default ImplicitCallbackWrapper;