import React from "react";
import { connect } from "react-redux";
// Redux
import { bindActionCreators } from "redux";
import Image from "components/shared/Image";
import DefaultIcon from "assets/images/default_user.svg";
import PropTypes from "prop-types";
import { actions as peopleResultsActions } from 'redux/actions/peopleresults/peopleresults_actions';
import { mapOrder, openLinkProfile } from "utils/helpers/helpers";
import ReactTooltip from 'react-tooltip';
import CONFIG from "config";
// SCSS
import "./IconListingTeam.scss";

class IconListing extends React.PureComponent {

    state = {
        iconListingData: [],
        extraTeam: 0
    }
    filterPeopleResult = (peopleResult) => {
        let alumni = [];
        let nonAlumni = [];
        peopleResult.forEach(teamMember => {
            (teamMember?.alumni === "TRUE" || teamMember?.alumni === "YES") ?
                alumni.push(teamMember)
                :
                nonAlumni.push(teamMember)
        });
        return { alumni, nonAlumni };
    }

    setImages = async () => {
        const {
            hrIds,
            onlyNonAlumni,
            limit,
            peopleResultsActions: {
                getPeopleDataUsingIDs
            }
        } = this.props;
        const {
            PEOPLE_API_QUERY_PARAMS: {
                AUTHORS_LISTING: {
                    REQUEST_DATA
                }
            }
        } = CONFIG;

        const { ids } = hrIds;

        if (ids?.length > 0) {
            const peopleResult = await getPeopleDataUsingIDs(ids, false, { REQUEST_DATA });
            if (peopleResult && peopleResult.length) {
                const { nonAlumni } = this.filterPeopleResult(peopleResult);

                const data = onlyNonAlumni ? nonAlumni : peopleResult;
                const orderPeopleResult = mapOrder(data, ids, 'id');

                const allProfilePicture = orderPeopleResult.map(people => {
                    const { picture, preferredFirstName, preferredLastName, id } = people;
                    return { picture, name: preferredFirstName + " " + preferredLastName, id }
                });

                this.setState({
                    extraTeam: allProfilePicture.length - limit
                });
                const profilePictures = allProfilePicture.slice(0, limit);

                this.setState({
                    iconListingData: data.length ? profilePictures : false
                });
            }
        } else {
            this.setState({
                iconListingData: false
            });
        }
    };

    componentDidMount = () => {
        this.setImages();
    }

    handleIconClick = (e, hrid) => {
        const { iconClickCallback } = this.props;
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        openLinkProfile(hrid);
        if (iconClickCallback)
            iconClickCallback(hrid);
    }

    render() {
        const {
            iconListingData,
            extraTeam
        } = this.state;
        const {
            hrIds: {
                ids
            },
            heading,
            limit,
            showLabelAtTop
        } = this.props;


        return (
            <>
                {!!iconListingData &&
                    <div className="iconlisting">
                        <span className={`iconlisting__text ${showLabelAtTop ? 'iconlisting__text-top' : ''}`}>{heading} </span>
                        <span className="iconlisting__imageslist">
                            {
                                iconListingData.length > 0 ?
                                    iconListingData.slice(0, limit).map(({ picture, name, id }, index) => {
                                        return (
                                            <>
                                                <Image
                                                    key={id}
                                                    className={"iconlisting__image iconlisting__image__" + Number(index + 1)}
                                                    src={picture}
                                                    alt="user"
                                                    data-tip={name}
                                                    onClick={(e) => this.handleIconClick(e, id)}
                                                />
                                                <ReactTooltip
                                                    place="bottom"
                                                    effect="solid"
                                                    className="iconlisting__tooltip"
                                                    offset={{ bottom: "-4px" }}
                                                />
                                            </>
                                        );
                                    })
                                    :
                                    ids.slice(0, limit).map((id, index) => {
                                        return (
                                            <>
                                                <Image
                                                    key={id}
                                                    className={"iconlisting__image iconlisting__image__" + Number(index + 1)}
                                                    src={DefaultIcon}
                                                    alt="user"
                                                />
                                            </>
                                        );
                                    })
                            }

                            {!!extraTeam && extraTeam > 0 &&
                                <span className="iconlisting__staticThumbnail">+{extraTeam}</span>
                            }
                        </span>
                    </div>
                }
            </>
        );

    }
}

IconListing.propTypes = {
    hrIds: PropTypes.object,
    onlyNonAlumni: PropTypes.bool,
    showLabelAtTop: PropTypes.bool,
    limit: PropTypes.number
};

IconListing.defaultProps = {
    onlyNonAlumni: false,
    limit: 2,
    showLabelAtTop: false
};


const mapDispatchToProps = (dispatch) => ({
    peopleResultsActions: bindActionCreators(
        { ...peopleResultsActions },
        dispatch
    ),
});
export const IconListingTeam = connect(
    null,
    mapDispatchToProps
)(IconListing);

export { IconListing };