import { put, takeLatest, call, takeEvery, delay, all } from 'redux-saga/effects';
// Constant
import { KP } from "redux/constants";
// Config
import CONFIG from "config";
// Axios Util
import { doAxiosRequest } from "config-axios";
// Actions
import {
  getDownloadsSuccess, getDownloadsFailure,
  getFilesStatusSuccess, getFilesStatusFailure, getFilesStatusPending,
  getAssociatedBundlesSuccess, getAssociatedBundlesFailure, getAssociatedBundlesPending,
  getFilesDownloadPending, getFileDownloadSuccess, getFilesDownloadFailure,
  getTagsSuggestionsSuccess, getTagsSuggestionsPending, getTagsSuggestionsFailure,
  getUniqueTitleConfirmationPending, getUniqueTitleConfirmationSuccess, getUniqueTitleConfirmationFailure,
  getSubjectSearchPending, getSubjectSearchSuccess, getSubjectSearchFailure
} from "./knowledgepage.actions";
// helper
import {
  downloadFileFromUrl,
  removeCurlyBrackets,
  isWordPresentInList
} from "utils/helpers/helpers";

import { moduleFailure } from "redux/global.action.js";
import { getSmartLogicBearerToken } from "../authorization/authorization.saga";
import { getFromLocalStorageAsJSON } from 'utils/localStorage/localStorage';

export function* getDownloadsCount(action) {
  try {
    const { payload: { KPId } } = action;
    const { API_URL: { GET_BULK_DOWNLOADS_COUNTS } } = CONFIG;
    const axiosConfig = {
      method: 'GET',
      endpoint: GET_BULK_DOWNLOADS_COUNTS(KPId),
      headers: {
        'x-api-key': CONFIG.XA
      }
    };
    const response = yield call(doAxiosRequest, axiosConfig);
    yield put(getDownloadsSuccess(response?.downloadCountList[0]?.logicalDownloadAllTime || 0));

  } catch (error) {
    yield put(getDownloadsFailure(error));
  }
};

export function* checkKPFilesExistence(action) {
  try {
    yield put(getFilesStatusPending());
    const { payload: { KPIds = [], appName, type } } = action;
    const { API_URL: { GET_KP_IS_FILE_EXIST } } = CONFIG;
    const ids = removeCurlyBrackets(KPIds);
    yield delay(500);
    const axiosConfig = {
      method: 'GET',
      endpoint: GET_KP_IS_FILE_EXIST(ids.join(','), appName, type),
      headers: {
        'x-api-key': CONFIG.XA
      }
    };
    const response = yield call(doAxiosRequest, axiosConfig);
    yield put(getFilesStatusSuccess(response));

  } catch (error) {
    yield put(getFilesStatusFailure(error));
  }
};

export function* getAssociatedBundles(action) {
  try {
    yield put(getAssociatedBundlesPending());
    const { payload: { kpId } } = action;
    const { API_URL: { GET_ASSOCIATED_BUNDLES } } = CONFIG;

    const axiosConfig = {
      method: 'GET',
      endpoint: GET_ASSOCIATED_BUNDLES(kpId),
      headers: {
        'x-api-key': CONFIG.XA
      }
    };
    const response = yield call(doAxiosRequest, axiosConfig);
    yield put(getAssociatedBundlesSuccess(response?.data || []));

  } catch (error) {
    yield put(getAssociatedBundlesFailure(error));
  }
};

export function* getKPFile(action) {
  try {
    yield put(getFilesDownloadPending());
    const { payload: { kpId, appName, type } } = action;
    const { API_URL: { GET_KP_FILE } } = CONFIG;
    const axiosConfig = {
      method: 'GET',
      endpoint: GET_KP_FILE(kpId, appName, type),
      headers: {
        'x-api-key': CONFIG.XA
      }
    };
    const response = yield call(doAxiosRequest, axiosConfig);

    downloadFileFromUrl(response?.documentData?.preSignedUrl);
    yield put(getFileDownloadSuccess());
    yield delay(1000);
    yield call(getDownloadsCount, { payload: { KPId: kpId } });

  } catch (error) {
    yield put(getFilesDownloadFailure(error));
  }
};


export function* getTagsSuggestions(action) {
  try {
    yield put(getTagsSuggestionsPending());
    const { payload: { caseNumbers, type } } = action;
    const { API_URL: { GET_TAGS_SUGGESTIONS } } = CONFIG;
    const axiosConfig = {
      method: 'POST',
      endpoint: GET_TAGS_SUGGESTIONS() + (type ? "?type=" + type : ""),
      headers: {
        'x-api-key': CONFIG.XA
      },
      params: {
        "cases": caseNumbers
      },
    };
    let response = yield call(doAxiosRequest, axiosConfig);
    response = { ...response, type }
    yield put(getTagsSuggestionsSuccess(response));

  } catch (error) {
    yield put(getTagsSuggestionsFailure(error));
  }
};

/**
 * Used to call a API related to unique title check
 * @param {*} action 
 */
export function* getUniqueTitleConfirmation(action) {
  try {
    yield put(getUniqueTitleConfirmationPending());
    const { payload: { id, title } } = action;
    const { API_URL: { GET_UNIQUE_TITLE_CONFIRMATION } } = CONFIG;
    const axiosConfig = {
      method: 'GET',
      endpoint: GET_UNIQUE_TITLE_CONFIRMATION() + ("?id=" + id + "&title=" + encodeURIComponent(title)),
      params: {
        id,
        title,
      },
      headers: {
        'x-api-key': CONFIG.XA
      }
    };
    let response = yield call(doAxiosRequest, axiosConfig);
    yield put(getUniqueTitleConfirmationSuccess(response));
  } catch (error) {
    yield put(getUniqueTitleConfirmationFailure(error));
  }
};

export function* getSubjectSearchData(action) {
  try {
    const { payload: { term, typeSearchKey } } = action;
    const { XA, OKTA: { STK }, API_URL: { GET_TYPESEARCH_DATA } } = CONFIG;
    const smartLogicAcessToken = getFromLocalStorageAsJSON(STK);
    yield put(getSubjectSearchPending());
    const axiosConfig = {
      method: "get",
      headers: {
        'customAuthHeader': true,
        'noAuthHeader': true,
        'authorization': `Bearer ${smartLogicAcessToken}`,
        'x-api-key': XA
      }
    };
    let searchflag = false;
    for (let i = 0; i < term.length; i++) {
      const responses = yield all(typeSearchKey.map(item => {
        axiosConfig["endpoint"] = GET_TYPESEARCH_DATA(CONFIG.TBDB_IDS[item.searchKey], encodeURIComponent(term[i]));
        return call(doAxiosRequest, axiosConfig);
      }));

      let combinedArray = [];
      responses.forEach(res => {
        if (res?.data?.termHints) {
          combinedArray = [...combinedArray, ...res.data.termHints];
        }
      });

      searchflag = isWordPresentInList(term[i], combinedArray)
      if (searchflag) {
        break;
      }
    }
    yield put(getSubjectSearchSuccess(searchflag));
  } catch (error) {
    if (error?.error?.response?.status === 403 || error?.error?.response?.status === 401) {
      yield call(getSmartLogicBearerToken);
    } else {
      yield put(getSubjectSearchFailure(error));
    }
  }
};

export default function* KnowledgePageSaga() {
  try {
    yield takeLatest(KP.GET_DOWNLOADS, getDownloadsCount);
    yield takeEvery(KP.GET_FILES_STATUS, checkKPFilesExistence);
    yield takeLatest(KP.GET_ASSOCIATED_BUNDLES, getAssociatedBundles);
    yield takeLatest(KP.GET_FILE, getKPFile);
    yield takeLatest(KP.GET_TAGS_SUGGESTIONS, getTagsSuggestions);
    yield takeLatest(KP.GET_UNIQUE_TITLE_CONFIRMATION, getUniqueTitleConfirmation);
    yield takeLatest(KP.GET_SUBJECTS_SEARCH_DATA, getSubjectSearchData);
  } catch (error) {
    yield put(moduleFailure(error, 'KnowledgePageSaga'));
  }
}