import React from "react";
import { Dropdown } from 'semantic-ui-react';
import './Dropdown.scss';

const DropdownCustom = ({ ...props }) => (
    <div className={`dropdownCustom ${props.customClass ? props.customClass : ''}`}>
        <Dropdown {...props} />
    </div>
);

export default DropdownCustom;