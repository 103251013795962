import React from 'react';
import file_upload from 'assets/images/kpc_file_upload.svg';
import './AdditionalUrls.scss';
import PropTypes from 'prop-types';
import SupportingLink from 'components/shared/SupportingLink';
import { v4 as uuidv4 } from 'uuid';
export const AdditionalUrls = ({ metaData, onLinkClickCallback }) => {
  const { additionalURLs } = metaData;
  return (
    <>
      {additionalURLs.length > 0 &&
        additionalURLs.map((additionalURL) => {
          return (
            <section className="additionalurl" key={uuidv4()}>
              <SupportingLink
                deleteIconInline={false}
                deleteIconOutside={false}
                fileIcon={file_upload}
                additionalURL={additionalURL}
                onLinkClickCallback={onLinkClickCallback}
              />
            </section>
          );
        })}
    </>
  );
};
AdditionalUrls.propTypes = {
  metaData: PropTypes.object,
};

AdditionalUrls.defaultProps = {
  metaData: { additionalURLs: [] },
};
