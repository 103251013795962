// Axios Utility
import { doAxiosRequest } from "config-axios";
// Constant
import { KNOWLEDGE_ITEMS } from "redux/constants";
// Config
import GLOBAL_CONFIG from "config";
// auth utils
import { getUserId } from "utils/auth/auth";
// query utils
import { SEARCH } from 'utils/queryUtils/queryUtils';

// actions
export const actions = {
  // This action is used on Screen when user search for material in search box
  // get Knowledge Object(Material/Knowledge Item) Details from CMS
  searchKnowledgeObjDetails: (id, searchTerm) => async (dispatch) => {
    const searchCriteria = {
      pageNumber: GLOBAL_CONFIG.API_URL.SEARCH.DEFAULTS.PAGE_NUMBER,
      pageSize: GLOBAL_CONFIG.API_URL.SEARCH.DEFAULTS.PAGE_SIZE,
      status: GLOBAL_CONFIG.KNOWLEDGE_STATUS.PUBLISHED,
      id: id,
      text: searchTerm,
      searchType: GLOBAL_CONFIG.KNOWLEDGE_TYPE_KEYS.PAGES
    };

    // dispatch pending state
    dispatch({
      type: KNOWLEDGE_ITEMS.KP_SEARCH_PENDING,
      payload: {
        criteria: searchCriteria
      }
    });

    // try to get Details
    try {
      if (id || searchTerm) {
        /**
         * axiosConfig: request object to collect all http request related data
         * @param method: get, post, put etc
         * @param endpoint: api url
         * @param headers: custom headers
         * @param params: data to be sent in post calls
        **/

        const axiosConfig = {
          method: 'get',
          endpoint: `${SEARCH(searchCriteria)}`,
          headers: {
            'x-api-key': GLOBAL_CONFIG.XA
          }
        };
        const response = await doAxiosRequest(axiosConfig);

        if (response?.data) {
          // dispatch success state
          dispatch({
            type: KNOWLEDGE_ITEMS.KP_SEARCH_SUCCESS,
            payload: response.data
          });
        } else {
          dispatch({
            type: KNOWLEDGE_ITEMS.KP_SEARCH_NORECORD,
            payload: null,
          });
        }
      } else {
        console.log("no filter criteria");
      }
    } catch (error) {
      // dispatch failure state
      dispatch({
        type: KNOWLEDGE_ITEMS.KP_SEARCH_FAILURE,
        payload: error.toString(),
      });
      throw error;
    }
  },

  addKnowledgeObjectToBundle: (koid) => async (dispatch) => {
    // dispatch pending state
    dispatch({
      type: KNOWLEDGE_ITEMS.ADD_KNOWLEDGE_OBJECT_TO_BUNDLE_PENDING,
      payload: koid,
    });
    // try to push koid in metadata redux object
    try {
      if (koid) {
        // dispatch success state
        dispatch({
          type: KNOWLEDGE_ITEMS.ADD_KNOWLEDGE_OBJECT_TO_BUNDLE_SUCCESS,
          payload: koid,
        });
      }
    } catch (error) {
      // dispatch failure state
      dispatch({
        type: KNOWLEDGE_ITEMS.ADD_KNOWLEDGE_OBJECT_TO_BUNDLE_FAILURE,
        payload: error.toString(),
      });
      throw error;
    }
  },
  addSupportingLinkToBundle: (linkobj) => async (dispatch) => {
    // dispatch pending state
    dispatch({
      type: KNOWLEDGE_ITEMS.ADD_LINK_TO_BUNDLE_PENDING,
      payload: linkobj,
    });
    // try to push koid in metadata redux object
    try {
      if (linkobj) {
        // dispatch success state
        dispatch({
          type: KNOWLEDGE_ITEMS.ADD_LINK_TO_BUNDLE_SUCCESS,
          payload: linkobj,
        });
      }
    } catch (error) {
      // dispatch failure state
      dispatch({
        type: KNOWLEDGE_ITEMS.ADD_LINK_TO_BUNDLE_FAILURE,
        payload: error.toString(),
      });
      throw error;
    }
  },
  updateSupportingLinkList: (localLink) => async (dispatch) => {
    dispatch({
      type: KNOWLEDGE_ITEMS.SAVE_LINK_TO_METADATA_PENDING,
    });
    try {
      if (localLink) {
        // dispatch success state
        dispatch({
          type: KNOWLEDGE_ITEMS.SAVE_LINK_TO_METADATA_SUCCESS,
          payload: localLink,
        });
      }
    } catch (error) {
      // dispatch failure state
      dispatch({
        type: KNOWLEDGE_ITEMS.SAVE_LINK_TO_METADATA_FAILURE,
        payload: error.toString(),
      });
      throw error;
    }
  },



  //clear localSearchItem and noRecordFound
  resetSearchState: () => async (dispatch) => {
    dispatch({
      type: KNOWLEDGE_ITEMS.RESET_LOCALSEARCH_STATE_PENDING,
      payload: "",
    });
    // try to get KO data/details
    try {
      // dispatch success state
      dispatch({
        type: KNOWLEDGE_ITEMS.RESET_LOCALSEARCH_STATE_SUCCESS,
        payload: "",
      });
    } catch (error) {
      // dispatch failure state

      throw error;
    }
  },

  // This action is used to retrieve the user's recent contributions when adding knowledge pages to bundles
  // get Knowledge/Page/Search
  searchRecentContributions: () => async (dispatch) => {
    const searchCriteria = {
      pageNumber: GLOBAL_CONFIG.API_URL.SEARCH.DEFAULTS.PAGE_NUMBER,
      pageSize: GLOBAL_CONFIG.API_URL.SEARCH.DEFAULTS.PAGE_SIZE,
      status: GLOBAL_CONFIG.KNOWLEDGE_STATUS.PUBLISHED,
      originalCreator: await getUserId(),
      searchType: GLOBAL_CONFIG.KNOWLEDGE_TYPE_KEYS.PAGES
    };

    // dispatch pending state
    dispatch({
      type: KNOWLEDGE_ITEMS.GET_RECENT_CONTRIBUTIONS_PENDING,
      payload: {
        criteria: searchCriteria
      }
    });
    // try to get Details
    try {

      /**
     * axiosConfig: request object to collect all http request related data
     * @param method: get, post, put etc
     * @param endpoint: api url
     * @param headers: custom headers
     * @param params: data to be sent in post calls
     */

      const axiosConfig = {
        method: 'get',
        endpoint: `${SEARCH(searchCriteria)}`,
        headers: {
          'x-api-key': GLOBAL_CONFIG.XA
        }
      };
      const response = await doAxiosRequest(axiosConfig);
      if (response?.data) {
        // dispatch success state
        dispatch({
          type: KNOWLEDGE_ITEMS.GET_RECENT_CONTRIBUTIONS_SUCCESS,
          payload: response.data
        });
      } else {
        dispatch({
          type: KNOWLEDGE_ITEMS.GET_RECENT_CONTRIBUTIONS_NORECORD,
          payload: null,
        });
      }

    } catch (error) {
      dispatch({
        type: KNOWLEDGE_ITEMS.GET_RECENT_CONTRIBUTIONS_FAILURE,
        payload: { error: error.toString() }
      });
      throw error;
    }
  },
};
