import React from 'react';
import { TabComponent } from 'components/shared/TabComponent';
import { AdditionalUrls } from 'components/AdditionalUrls/AdditionalUrls';
import AssociatedBundles from 'components/AssociatedBundles/AssociatedBundles';
import LABELS from 'labels';
import './ConsumptionAdditionalContent.scss';
import PropTypes from 'prop-types';
import { AssociatedCaseListing } from 'components/AssociatedCaseListing';

export class ConsumptionAdditionalContent extends React.PureComponent {
  state = {
    activeTab: 0,
  };
  getActiveTab = (activeIndex) => {
    this.setState({ activeTab: activeIndex });
  };
  render() {
    const {
      ADDITIONAL_URLS: { ADDITIONAL_URLS_TAB_HEADING },
      KPCONSUMPTIONGROUP: {
        ASSOCIATED_BUNDLES,
        ASSOCIATED_CASES
      },
    } = LABELS;
    const {
      metaData,
      metaData: { additionalURLs },
      onLinkClickCallback,
      associatedBundles,
      associatedCases,
      associatedBundlesClickCallback
    } = this.props;
    return (
      associatedBundles?.length || additionalURLs?.length || associatedCases.length ? <div className="additionalcontent margin_bottom--30">
        <TabComponent
          className="tabs__container additionalcontent__tab-component consumptionTabContainer"
          defaultActiveIndex={associatedBundles?.length ? 0 : associatedCases?.length ? 1 : 2}
          getActiveIndex={this.getActiveTab}
          menu={{ secondary: true, pointing: true }}
          arrayOfPaneElements={[
            {
              className: 'item-style',
              heading: ASSOCIATED_BUNDLES,
              hiddenClassName: !!associatedBundles.length ? "" : "displaynone",
              count: associatedBundles.length ?? 0,
              component: <AssociatedBundles bundles={associatedBundles} clickCallBack={associatedBundlesClickCallback} />,
            },
            {
              className: 'item-style',
              heading: ASSOCIATED_CASES,
              hiddenClassName: !!associatedCases.length ? "" : "displaynone",
              count: associatedCases.length ?? 0,
              component: <AssociatedCaseListing associatedCases={associatedCases} />,
            },
            {
              className: 'item-style',
              heading: ADDITIONAL_URLS_TAB_HEADING,
              hiddenClassName: !!additionalURLs?.length ? "" : "displaynone",
              count: additionalURLs?.length ?? 0,
              component: <AdditionalUrls metaData={metaData} onLinkClickCallback={onLinkClickCallback} />,
            },
          ]}
        />
      </div> : null
    );
  }
}
ConsumptionAdditionalContent.propTypes = {
  metaData: PropTypes.object,
  associatedBundles: PropTypes.arrayOf(PropTypes.object),
};

ConsumptionAdditionalContent.defaultProps = {
  metaData: {},
  associatedBundles: [],
  associatedCases: []
};
