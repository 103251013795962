import {
  API_GET_MATERIAL_PENDING,
  API_GET_MATERIAL_FAILURE,
  API_GET_MATERIAL_SUCCESS,
  // API_SAVE_MATERIAL_PENDING,
  API_SAVE_MATERIAL_FAILURE,
  API_SAVE_MATERIAL_SUCCESS,
  REDIRECT_METADATA_PAGE,
  API_SAVE_UPLOAD_MATERIAL_PENDING,
  API_SAVE_UPLOAD_MATERIAL_SUCCESS,
  API_SAVE_UPLOAD_MATERIAL_FAILURE
} from 'redux/constants';

export const name = 'material';

export const initialState = {
  loading: true,
  error: false,
  errorMessage: '',
  data: {},
  redirect: false
};

export const selectors = {
  getLoading: state => state[name].loading,
  getError: state => state[name].error,
  getErrorMessage: state => state[name].errorMessage,
  getMaterialData: state => state[name].data,
  getRedirect: state => state[name].redirect
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case API_GET_MATERIAL_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: '',
        data: action.payload
      };
    case API_GET_MATERIAL_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload
      };
    case API_GET_MATERIAL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    // case API_SAVE_MATERIAL_PENDING:
    case API_SAVE_UPLOAD_MATERIAL_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: '',
        data: action.payload
      };
    case API_SAVE_MATERIAL_FAILURE:
    case API_SAVE_UPLOAD_MATERIAL_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload
      };
    case API_SAVE_MATERIAL_SUCCESS:
    case API_SAVE_UPLOAD_MATERIAL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    case REDIRECT_METADATA_PAGE:
      return {
        ...state,
        redirect: action.redirect
      };
    default:
      return state;
  }
}
