import React from 'react';
import './FcgGridTemplate.scss';




const FcgGridTemplate = ({ rows, columns, shape }) => {
    const renderBlocks = () => {
        const containerWidth = 160;
        const containerHeight = 101;
        const gridGap = 5;
        const totalHorizontalGap = gridGap * (columns - 1);
        const totalVerticalGap = gridGap * (rows - 1);
        const paddingTopBottom = 10;
        const paddingLeftRight = 10;

        const blockWidth = (containerWidth - paddingLeftRight - totalHorizontalGap) / columns;
        const blockHeight = (containerHeight - paddingTopBottom - totalVerticalGap) / rows;
        const effectiveDimension = Math.min(blockWidth, blockHeight);
        const blocks = [];
        for (let i = 0; i < rows * columns; i++) {
            const style = {
                width: `${effectiveDimension}px`,
                height: `${effectiveDimension}px`,
            };
            if (shape === 'circle') {
                style.borderRadius = '50%';
            } else {
                style.borderRadius = '0%';
            }

            blocks.push(
                <div key={i} className={` fcgGridTemplate__block fcgGridTemplate__block--${shape}`} style={style}></div>
            );
        }
        return blocks;
    }

    return (
        <div className='fcgGridTemplate__gridInner'
            style={{
                gridTemplateColumns: `repeat(${columns}, 1fr)`,
                gridTemplateRows: `repeat(${rows}, 1fr)`
            }}
        >
            {renderBlocks()}
        </div>
    );
}

export default FcgGridTemplate;