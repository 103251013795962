import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import LABELS from "labels";
import { KP as KP_REDUX_CONSTANTS } from "redux/constants";
import { Layout } from "containers/Layout";
import downloadBig from "assets/images/download-big.svg";
import Image from "components/shared/Image";
import "./KpDownload.scss";
import { actions as KPActions } from 'redux/actions/knowledgepage/knowledgepage_actions';
import {
  notifyWarning,
  dismissNotifications,
} from "redux/actions/notification/notification_actions";

import { selectors as kpSelectors } from "redux/reducers/knowledgepage/knowledgepage_reducer";

const {
  KP: {
    DOWNLOAD_PAGE_TITLE
  },
  KNOWLEDGE_PRODUCT_NAME: {
    KP
  },
  ERROR_MESSAGES: {
    DOWNLOAD_ERROR
  }
} = LABELS;

const {
  GET_FILE
} = KP_REDUX_CONSTANTS;


class KpDownloadContainer extends React.PureComponent {

  componentDidMount() {
    const {
      getKPFile,
      match: {
        params: {
          appName,
          type,
          kpId
        }
      },
    } = this.props;

    getKPFile(kpId, appName, type);
  }

  componentDidUpdate = () => {
    const {
      isDownloadError,
      notifyWarning,
      dismissNotifications,
      KPActions: { resetDownloadError }
    } = this.props;

    if (isDownloadError) {
      notifyWarning("", DOWNLOAD_ERROR);
      setTimeout(() => {
        dismissNotifications();
        resetDownloadError();
      }, 5000);
    }

  }

  render() {

    return (
      <Layout
        id="kpDownload"
        pageTitle={DOWNLOAD_PAGE_TITLE}
        productName={KP}
      >
        <div className="kpdownload">
          <Image
            className="kpdownload__image"
            src={downloadBig}
            alt="Download"
          />
          <h2>Your file is currently downloading. Check the Download location upon completion.</h2>
        </div>
      </Layout>
    )
  }
}
/* istanbul ignore next */
const mapStateToProps = (state) => ({
  isDownloadError: kpSelectors.getError(state),
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch) => ({
  getKPFile: (kpId, appName, type) => dispatch({
    type: GET_FILE,
    payload: { kpId, appName, type }
  }),
  KPActions: bindActionCreators({ ...KPActions }, dispatch),
  notifyWarning: (title, msg) => dispatch(notifyWarning(title, msg)),
  dismissNotifications: () => dispatch(dismissNotifications()),
});

export { KpDownloadContainer };

export const KpDownload = connect(
  mapStateToProps,
  mapDispatchToProps
)(KpDownloadContainer);