// Case Page Config
import LABELS from "labels";
import GLOBAL_CONFIG from "config";
import CONSTANTS from "globalConstants";
import infoIIcon from "assets/images/infoIIcon/info.svg";

const { CP_CONTRIBUTION: { NOTIFICATION_MESSAGE_OVERVIEW } } = LABELS;
const { SAVE, VIEW_CONSUMPTION } = LABELS.BUTTONS;
const { AUTO_TAG_TYPE_CONFIG_OBJ } = GLOBAL_CONFIG;

const caseoverview = {
  sanitizedCaseTitle: {
    onEmpty: {
      message: NOTIFICATION_MESSAGE_OVERVIEW
    },
    type: "string"
  },
  clientDescription: {
    onEmpty: {
      message: NOTIFICATION_MESSAGE_OVERVIEW
    },
    isKeyDependent: {
      value: "clientName",
      type: "string"
    },
  },
  clientBusinessUnitDescription: {
    onEmpty: {
      message: NOTIFICATION_MESSAGE_OVERVIEW
    },
    isKeyDependent: {
      value: "clientBUName",
      type: "string"
    }
  },
  office: {
    onEmpty: {
      message: NOTIFICATION_MESSAGE_OVERVIEW
    },
    type: "object"
  },
}

const caseinsights = {
  relatedCasesAndRelationshipType: {
    onEmpty: {
      message: NOTIFICATION_MESSAGE_OVERVIEW
    },
    required_object_field: {
      key: "caseTypeGuid"
    },
    type: "arrayOfObjects"
  }
}

const CONFIG = {
  relatedCasesAndRelationshipField: 'relatedCasesAndRelationshipType',
  footerWorkflow: [
    {
      align: "left",
      iconAlign: "pre",
      id: "btn-consump",
      classname: "footer-workflow__link footer-workflow__button--back footer-workflow__button--fullsize",
      text: VIEW_CONSUMPTION,
      link: "",
      outFilled: true,
      target: false,
      visible: true,
      disabled: false,
      children: [],
      isClick: true,
    },
    {
      align: "right",
      visible: true,
      children: [
        {
          order: 1,
          text: SAVE,
          id: "btn-publish",
          iconAlign: "post",
          classname: "green big",
          disabled: false,
          visible: true,
          isClick: true,
        }
      ],
    },
  ],

  validationDictionary: {
    caseoverview,
    caseinsights,
    cases_validation: {
      ...caseoverview, ...caseinsights
    }
  }
};

export const getTaggedTaxonomyConfig = () => {
  let taggedTaxonomyConfig = []
  try {
    const { AUTO_TAG_TYPE_CONFIG: { C_GEO, C_CNBU, C_RA, C_BCGT, C_PIPE } } = CONSTANTS;
    const { tagName: C_GEO_TAG_NAME, tagToolTipMsg: C_GEO_TOOLTIP_MSG } = AUTO_TAG_TYPE_CONFIG_OBJ('CASE', C_GEO);
    const { tagName: C_CNBU_TAG_NAME, tagToolTipMsg: C_CNBU_TOOLTIP_MSG } = AUTO_TAG_TYPE_CONFIG_OBJ('CASE', C_CNBU);
    const { tagName: C_RA_TAG_NAME, tagToolTipMsg: C_RA_TOOLTIP_MSG } = AUTO_TAG_TYPE_CONFIG_OBJ('CASE', C_RA);
    const { tagName: C_BCGT_TAG_NAME, tagToolTipMsg: C_BCGT_TOOLTIP_MSG } = AUTO_TAG_TYPE_CONFIG_OBJ('CASE', C_BCGT);
    const { tagName: C_PIPE_TAG_NAME, tagToolTipMsg: C_PIPE_TOOLTIP_MSG } = AUTO_TAG_TYPE_CONFIG_OBJ('CASE', C_PIPE);
    taggedTaxonomyConfig = [
      { infoIconKeyName: 'autoTagType', infoIconValueName: C_GEO_TAG_NAME, infoIcon: infoIIcon, infoIconToolTipMessage: C_GEO_TOOLTIP_MSG },
      { infoIconKeyName: 'autoTagType', infoIconValueName: C_CNBU_TAG_NAME, infoIcon: infoIIcon, infoIconToolTipMessage: C_CNBU_TOOLTIP_MSG },
      { infoIconKeyName: 'autoTagType', infoIconValueName: C_RA_TAG_NAME, infoIcon: infoIIcon, infoIconToolTipMessage: C_RA_TOOLTIP_MSG },
      { infoIconKeyName: 'autoTagType', infoIconValueName: C_BCGT_TAG_NAME, infoIcon: infoIIcon, infoIconToolTipMessage: C_BCGT_TOOLTIP_MSG },
      { infoIconKeyName: 'autoTagType', infoIconValueName: C_PIPE_TAG_NAME, infoIcon: infoIIcon, infoIconToolTipMessage: C_PIPE_TOOLTIP_MSG }
    ]
  } catch (error) {
    console.log('Error in getTaggedTaxonomyConfig function ', error);
  }

  return taggedTaxonomyConfig;
}

export default CONFIG;
