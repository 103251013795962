import React, { Component, createRef } from 'react';
import { Form } from "semantic-ui-react";

import "./AdvancedDropdown.scss";
import Image from "components/shared/Image";
import LABELS from "labels";
import { matchesQuery } from "utils/helpers/helpers";
import SearchIcon from "assets/images/search-icon-dark-gray.svg";
import { HierarchicalAccordian } from "components/HierarchicalAccordian/HierarchicalAccordian";

class AdvancedDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      queryMatchCount: 0,
      dropdownMenuOptions: null,
      dropdownMenuOptionsCopy: null,
      filteredOptions: null
    };
    this.searchbarRef = createRef();
  }

  componentDidMount() {
    const {
      getdata,
      taxonomyName
    } = this.props;
    const taxonomyData = getdata(taxonomyName[0].key);

    this.setState({
      dropdownMenuOptions: taxonomyData,
      dropdownMenuOptionsCopy: JSON.parse(JSON.stringify(taxonomyData)),
      filteredOptions: taxonomyData
    });
    document.addEventListener("mousedown", this.handleOutsideClick);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleOutsideClick);
  }

  componentDidUpdate(prevProps, prevState) {
    const { query } = this.state;
    if (typeof prevState.query !== "undefined" && query !== prevState.query) {
      this.parseContentBasedOnQuery();
    }
  }

  parseContentBasedOnQuery = () => {
    const { query, dropdownMenuOptionsCopy, dropdownMenuOptions, filteredOptions } = this.state;
    const { onSearchCallback, responseKeyName } = this.props;
    let filteredChildren = [], filteredOption = null;
    if (query) {
      filteredChildren = [];
      dropdownMenuOptionsCopy.forEach((option) => {
        filteredOption = this.filterOptionByQuery(option, query, responseKeyName, false);
        if (filteredOption) {
          filteredChildren.push(filteredOption);
        }
      });
      let queryMatchCount = filteredOptions ? this.getSearchResultsCount(filteredChildren) : 0;
      onSearchCallback(query, queryMatchCount);
      this.setState({
        filteredOptions: filteredChildren,
        queryMatchCount: queryMatchCount
      });
    } else {
      let filteredChildren = [];
      dropdownMenuOptions && dropdownMenuOptions.forEach((option) => {
        filteredOption = this.filterOptionByQuery(option, query, responseKeyName, true);
        if (filteredOption) {
          filteredChildren.push(filteredOption);
        }
      });
      this.setState({
        filteredOptions: filteredChildren ? filteredChildren : dropdownMenuOptions
      });
    }
  }

  filterOptionByQuery = (option, query, responseKeyName, isReset = false) => {
    const { children, name } = responseKeyName;
    let optionChildren = [];
    if (option.hasOwnProperty(name)) {
      if (isReset || matchesQuery(option, name, query)) {
        option.isFiltered = true; // match with query - show item         
      } else {
        option.isFiltered = false; // no match - hide item
      }

      // recursive call
      if (option.hasOwnProperty(children)) {
        optionChildren = [];
        let isAnyChildFiltered = false;
        let filterOption;
        option[children].forEach((child) => {
          filterOption = this.filterOptionByQuery(child, query, responseKeyName, false);
          if (filterOption) {
            optionChildren.push(filterOption);
          }
        });

        if (optionChildren.length > 0) {
          option[children].splice(0, option[children].length); // empty the array
          option[children] = optionChildren;
        }
        optionChildren.forEach((child) => {
          if (child.isFiltered || child.isChildFiltered) {
            isAnyChildFiltered = true;
          }
        });
        option.isChildFiltered = isAnyChildFiltered; // update the visibility of the parent
      }
      return option;
    }
  }

  onQueryChange = (event) => {
    if (event.target.value.length) {
      this.setState({
        query: event.target.value
      })
    } else {
      this.setState({
        query: ''
      })
    }
  };

  getSearchResultsCount = (options) => {
    let childFiltered = 0;
    options.forEach((child) => {
      const { isFiltered, Children } = child;
      if (isFiltered) {
        childFiltered++;
      }
      if (Children?.length) {
        childFiltered += this.getSearchResultsCount(Children);
      }
    });
    return childFiltered;
  }

  handleOutsideClick = (e) => {
    const { setisOpen } = this.props;
    if (this.searchbarRef.current.contains(e.target)) {
      return;
    }
    setisOpen(false, true);
  };


  render() {
    const {
      className, getdata, taxonomyName,
      responseKeyName,
      selectedRegions, isOpen, setisOpen, addSelectedRegion,
      chosenSearchTermClickCallback
    } = this.props;
    const { filteredOptions, query, queryMatchCount } = this.state;
    const {
      KP: {
        STEP3: {
          NO_MATCHED_REGIONS_COUNTRY_LINE1,
          NO_MATCHED_REGIONS_COUNTRY_LINE2
        }
      }
    } = LABELS;

    return (
      <div className="advancedDropdown" ref={this.searchbarRef}>
        <div className="advancedDropdown__searchwrapper">
          <Form.Input
            type="text"
            value={query}
            className="advancedDropdown__searchbox"
            onChange={this.onQueryChange}
            autoComplete="off"
            icon={
              <span className="seachicon">
                <Image
                  src={SearchIcon}
                  alt={LABELS.ALT_TEXTS.ENTER_ICON}
                  className="searchicon"
                />
              </span>
            }
          />

        </div>
        {
          (query && !queryMatchCount) ?
            <div className="advancedDropdown__noresults">
              <p>{NO_MATCHED_REGIONS_COUNTRY_LINE1}</p>
              <p>{NO_MATCHED_REGIONS_COUNTRY_LINE2}</p>
            </div>
            :
            <HierarchicalAccordian
              className={className}
              filteredOptions={filteredOptions}
              taxonomyName={taxonomyName}
              responseKeyName={responseKeyName}
              selectedRegions={selectedRegions}
              getdata={getdata}
              searchbarRef={this.searchbarRef}
              isOpen={isOpen}
              setisOpen={setisOpen}
              query={query}
              queryMatchCount={queryMatchCount}
              addSelectedRegion={addSelectedRegion}
              chosenSearchTermClickCallback={chosenSearchTermClickCallback}
            />
        }
      </div>
    );
  }
}

AdvancedDropdown.propTypes = {
};


export default AdvancedDropdown;