import React from "react";
import Autosuggest from "react-autosuggest";
import PropTypes from "prop-types";
import Image from "components/shared/Image";
import infoIcon from 'assets/images/info-white.svg';
import Highlighter from "components/shared/Highlighter";
import searchIcon from "assets/images/search-icon-dark-gray.svg";
import findresult from "assets/images/findresult.svg";
import LABELS from "labels";
import { Tooltip } from "components/shared/Tooltip";
// Shared component
import MailButton from "components/shared/MailButton";
import { ImageWithToolip } from "components/shared/ImageWithToolip";
import PolyHPostFixIcon from "assets/images/polyHierarchi/polyHierarchiPostFixICon.svg";
import "./AutosuggestSelector.scss";

export class AutosuggestSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      suggestions: [],
      selectedSuggestions: [],
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { searchData } = this.props;
    if (prevProps.searchData !== searchData) {
      let updatedList = this.getFilteredList();
      this.setState({
        suggestions: updatedList,
      });
    }
  }

  getFilteredList = () => {
    const { searchData, hideList } = this.props;
    const { responseTypeSearchKeyName } = this.props;
    return searchData.filter((elem) => !hideList.some((id) => elem[responseTypeSearchKeyName["id"]] === id));
  };

  getSuggestionValue = (suggestion) => {
    const {
      id,
      name
    } = suggestion;
    const {
      suggestions,
      selectedSuggestions,
      value
    } = this.state;
    const {
      responseTypeSearchKeyName,
      sendSelectedFilterData,
      typeAheadHandler
    } = this.props;
    const modifiedObj = {
      id: suggestion[responseTypeSearchKeyName["id"]],
      name: suggestion[responseTypeSearchKeyName["name"]],
      tabId: suggestion.facets[0].id,
    };
    const isPresentSlectedData = selectedSuggestions.filter((item) => {
      return item?.id === modifiedObj?.id;
    }).length;
    const updateState =
      isPresentSlectedData === 0
        ? [...selectedSuggestions, modifiedObj]
        : [...selectedSuggestions];
    this.setState(
      {
        selectedSuggestions: updateState,
      },
      () => {
        sendSelectedFilterData(modifiedObj);
        this.setState({
          value: "",
        });
      }
    );

    const selectedSuggestionRank = suggestions.findIndex((element) => element?.name === name);

    if (typeAheadHandler) {
      typeAheadHandler(id, value, name, selectedSuggestionRank + 1);
    }

    return suggestion[responseTypeSearchKeyName["name"]];
  };

  filterTagFromParentTags = (parentTagsData, suggestion) => {
    return Object.keys(suggestion).length > 0 && !!parentTagsData?.length && parentTagsData.filter(
      (i) => i.filter((j) => j?.id === suggestion?.id).length > 0
    );
  }

  renderSuggestion = (suggestion) => {
    const { value } = this.state;
    const { responseTypeSearchKeyName, parentTagsData, fnToCheckIDIsPolyHierarchi } = this.props;
    const { TAXONOMY: { TOOLTIPS: { POLY_HIERARCHI_TAXONOMY_SUGGESTION_MSG } } } = LABELS;
    let filterParentTags = [];
    try {
      filterParentTags = this.filterTagFromParentTags(parentTagsData, suggestion);
    } catch (e) {
      console.log("Error in filterTagFromParentTags", e);
    }
    const isPolyHierarchi = fnToCheckIDIsPolyHierarchi(suggestion?.id);
    if (
      !!parentTagsData?.length &&
      Object.keys(suggestion).length > 0 &&
      !!filterParentTags?.length
    ) {
      return (
        <>
          {isPolyHierarchi && <ImageWithToolip id={`${suggestion?.id}-tooltip`} imgIcon={PolyHPostFixIcon} toolTipClass="react-autosuggest__tooltip__poly-img" imgClassName="autoSelector__poly-img polyHRotateImage polyHierarchi__heightWidthSet" toolTipMsg={POLY_HIERARCHI_TAXONOMY_SUGGESTION_MSG} />}
          <Tooltip
            id={suggestion?.id}
            className={"react-autosuggest__tooltip"}
            position="right"
            isShowCustomizeIconHTML
            delayHide={0}
            icon={
              <div
                className={`react-autosuggest__suggestion-wrap react-autosuggest__suggestion-wrap--highlighter ${isPolyHierarchi ? 'react-autosuggest__suggestion-wrap--withPolyH' : ''} `}
                data-tip
                data-for={suggestion?.id}
              >
                <Highlighter
                  searchWord={value}
                  textToHighlight={suggestion[responseTypeSearchKeyName["name"]]}
                />
              </div>
            }
          >
            {
              <p
                className="react-autosuggest__tooltip-content"
                dangerouslySetInnerHTML={{
                  __html: filterParentTags[0].reduce((acc, item) => {
                    return (acc = `${acc} <span class="react-autosuggest__arrow-right"></span> ${item.name}`);
                  }, ""),
                }}
              />
            }
          </Tooltip>
        </>
      );
    } else {
      return (
        <div className="react-autosuggest__suggestion-wrap-outer">
          {isPolyHierarchi && <ImageWithToolip id={`${suggestion?.id}-no-tooltip`} imgIcon={PolyHPostFixIcon} toolTipClass="react-autosuggest__tooltip__poly-img" imgClassName="txbase__poly-img polyHRotateImage polyHierarchi__heightWidthSet" toolTipMsg={POLY_HIERARCHI_TAXONOMY_SUGGESTION_MSG} />}
          <div
            className={`react-autosuggest__suggestion-wrap react-autosuggest__suggestion-wrap--highlighter  ${isPolyHierarchi ? 'react-autosuggest__suggestion-wrap--withPolyH' : ''}`}
            data-tip
            data-for={suggestion?.id}
          >
            <Highlighter
              searchWord={value}
              textToHighlight={suggestion[responseTypeSearchKeyName["name"]]}
            />
          </div>
        </div>
      );
    }
  };

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    const {
      getSearchData
    } = this.props;
    getSearchData(value);
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  shouldRenderSuggestions = (value) => {
    const { characterCount } = this.props;
    if (value.length >= characterCount) {
      return true;
    }
  };

  renderSuggestionsContainer = ({ containerProps, children, query }) => {
    const { renderSuggestionInfotext, characterCount, noResults: { heading: noResultHeading, isShow: noResultIsShow, emailConfig } } = this.props;
    const { suggestions } = this.state;
    const { ALT_TEXTS: { SUGGESTION_LIST_INFOTEXT } } = LABELS;
    return (
      <div {...containerProps}>
        {children}
        {noResultIsShow && suggestions?.length === 0 && query.length >= characterCount && (
          <div className="react-autosuggest__suggestions-list react-autosuggest__noresult">
            <p>{noResultHeading}</p>
            <Image src={findresult} alt={LABELS.ALT_TEXTS.SEARCH_ICON} />
            <MailButton
              emailState={emailConfig}
            />
          </div>
        )}
        {suggestions?.length > 20 && (
          <div className="react-autosuggest__suggestion-list-bottom-infoBox">
            <Image src={infoIcon} />
            <span className="infoText">{renderSuggestionInfotext ?? SUGGESTION_LIST_INFOTEXT}</span>
          </div>
        )}
      </div>
    );
  }

  onBlur = () => {
    setTimeout(() => {
      let autoSuggestListContainerEle = document.getElementsByClassName('react-autosuggest__noresult');
      if (autoSuggestListContainerEle && autoSuggestListContainerEle?.length > 0) {
        autoSuggestListContainerEle[0].style.display = 'none';
      }
    }, 0)
  }

  render() {
    const { value, suggestions } = this.state;
    const { placeholder } = this.props;
    const inputProps = {
      placeholder: placeholder,
      value,
      onChange: this.onChange,
      onBlur: this.onBlur
    };
    return (
      <div className="autosuggest__customize">
        <div className="react-autosuggest__search--icon">
          <Image src={searchIcon} alt={LABELS.ALT_TEXTS.SEARCH_ICON} />
        </div>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          shouldRenderSuggestions={this.shouldRenderSuggestions}
          inputProps={inputProps}
          renderSuggestionsContainer={this.renderSuggestionsContainer}
        />
      </div>
    );
  }
}

AutosuggestSelector.defaultProps = {
  searchData: [],
  characterCount: 2,
  placeholder: "",
  responseTypeSearchKeyName: {
    id: "id",
    name: "name",
  },
  noResults: {
    isShow: false,
    heading: "",
    emailConfig: {}
  },
  fnToCheckIDIsPolyHierarchi: () => { }
};

AutosuggestSelector.propTypes = {
  searchData: PropTypes.array,
  responseTypeSearchKeyName: PropTypes.object,
  placeholder: PropTypes.string,
  characterCount: PropTypes.number,
  typeAheadHandler: PropTypes.func,
  fnToCheckIDIsPolyHierarchi: PropTypes.func
};
