import React from "react";
import PropTypes from "prop-types";
import CONFIG from "config";
import Carousel from "components/shared/Carousel/Carousel";
import { RelatedCaseTile } from "../RelatedCaseTile/RelatedCaseTile";
import { openURL } from "utils/helpers/helpers";
// SCSS
import "./RelatedCasesList.scss";

export const AddCarouselWrapper = ({ releatedCasePagesCount, children }) => {
  const { CP_LIMITS: { RELATED_CASES_SLIDES } } = CONFIG;
  return (RELATED_CASES_SLIDES <= releatedCasePagesCount ?
    <Carousel
      config={
        {
          slidesToShow: RELATED_CASES_SLIDES,
          slidesToScroll: RELATED_CASES_SLIDES,
          dots: false
        }
      }
      hideButtonText
    >
      {children}
    </Carousel>
    :
    <>{children}</>
  )
}

class RelatedCasesList extends React.Component {
  onCardClick = (e, projectId) => {
    e && e.stopPropagation();
    const { UI_URL } = CONFIG;
    const { cardClickAnalyticsCallback, redirectOnV1 } = this.props;
    redirectOnV1 ? openURL(UI_URL.CP_CONSUMPTION_NEW(projectId), false) : openURL(UI_URL.CP_CONSUMPTION_OLD(projectId), false);
    cardClickAnalyticsCallback(projectId);
  }

  render() {
    const { casePages, isViewClientInfo, iconClickCallback } = this.props;
    return (
      <section className="relatedCasesList">
        {casePages.length ? (
          <div className="relatedCasesList__slider">
            <AddCarouselWrapper releatedCasePagesCount={casePages.length}>
              {casePages.map((casePage, index) => (
                <div key={casePage.projectId} id={casePage.projectId} className="relatedCasesList__tile">
                  <RelatedCaseTile
                    data={casePage.data}
                    type={casePage.type}
                    index={index}
                    onCardClick={this.onCardClick}
                    iconClickCallback={iconClickCallback}
                    isViewClientInfo={isViewClientInfo}
                  />
                </div>
              ))}
            </AddCarouselWrapper>
          </div>
        ) : null
        }
      </section>
    );
  }
}

RelatedCasesList.propTypes = {
  casePages: PropTypes.object
};

RelatedCasesList.defaultProps = {
  redirectOnV1: false
}

export { RelatedCasesList };