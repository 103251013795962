import React from "react";
import PropTypes from "prop-types";


import Image from "components/shared/Image";
import { getFileIcon } from "utils/helpers/helpers";

import "./Attachment.scss";

export const Attachment = ({ item }) => {
  const { attachment,
    fileName,
    title
  } = item;
  let fileNamev1 = fileName ? fileName : attachment?.fileName;
  let attachmentTitle = title
    ? title
    : fileNamev1
      ? fileNamev1
      : "";

  let attachmentIcon = "";
  attachmentIcon = getFileIcon(fileNamev1);
  return (
    <div className="attachment">
      <div className="attachment__image">
        <Image className="attachment__icon" src={attachmentIcon} alt="icon" />
      </div>
      <div className="attachment__content">
        <div className="attachment__title" title={attachmentTitle}>{attachmentTitle}</div>
        <div className="attachment__sub-title" title={fileNamev1}>{fileNamev1}</div>
      </div>
    </div>
  );
};

Attachment.propTypes = {
  item: PropTypes.object,
};
