import { PEOPLE_DATA, TYPEAHEAD_DATA } from "redux/constants";
export const name = "peopleresults";

// initial state
export const initialState = {
  loading: false,
  error: false,
  errorMessage: "",
  peopleResults: [],
  noRecordFound: false,
  typeAheadResults: [],
};

// reducer selectors
export const selectors = {
  getLoading: state => state[name].loading,
  getError: state => state[name].error,
  getErrorMessage: state => state[name].errorMessage,
  getPeopleResults: (state, selectorName) => state[name][selectorName] ? state[name][selectorName] : [],
  getNoRecordFound: state => state[name].noRecordFound,
  getTypeAheadResults: (state, selectorName) => state[name][selectorName] ? state[name][selectorName] : [],
};

// reducer
export function reducer(state = initialState, action) {
  switch (action.type) {
    case PEOPLE_DATA.API_PEOPLE_RESULTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload
      };
    case PEOPLE_DATA.API_PEOPLE_RESULTS_PENDING:
      return {
        ...state,
        loading: true,
        noRecordFound: false,
        error: false,
        errorMessage: ''
      };
    case PEOPLE_DATA.API_PEOPLE_RESULTS_SUCCESS:
      return {
        ...state,
        loading: false,
        noRecordFound: action.payload.items?.length <= 0,
        [action.payload.selectorName]: action.payload.items
      };
    case TYPEAHEAD_DATA.API_TYPEAHEAD_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        [action.payload.selectorName]: action.payload.items
      };
    case TYPEAHEAD_DATA.API_TYPEAHEAD_DATA_CLEAR:
      return {
        ...state,
        loading: false,
        [action.payload.selectorName]: []
      };
    case TYPEAHEAD_DATA.API_TYPEAHEAD_RESULTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload
      };
    case TYPEAHEAD_DATA.API_TYPEAHEAD_RESULTS_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: ''
      };
    default:
      return state;
  }
}