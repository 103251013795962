import React from "react";
import CaseBar from 'components/CaseBar/CaseBar';
import CaseDetailsBT from 'components/CaseDetailsBT/CaseDetailsBT';
import { Taxonomy } from "containers/Taxonomy";
import CONFIG from "config";
import LABELS from "labels";
import PropTypes from "prop-types";
import CONSTANTS from "globalConstants";
import './CaseItem.scss';
import { TextAreaCounter } from "components/TextAreaCounter/TextAreaCounter";
import { getContentToDisplay } from "utils/helpers/helpers";
import { getTaggedTaxonomyConfig } from "containers/CasePage/CasePage.config";

export class CaseItem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSaveButtonDisable: true,
      textValue: props.data.sanitizedCaseTitle
    }
  }

  componentDidMount() {
  }

  componentDidUpdate = async (prevProps, prevState) => {
    const { textValue: prevTextValue } = prevState;
    const { textValue } = this.state;
    const { data: { sanitizedCaseTitle, caseGuid: prevCaseGuid } } = prevProps;
    const { data: { sanitizedCaseTitle: newSanitizedCaseTitle, caseGuid } } = this.props;
    if (textValue !== prevTextValue || sanitizedCaseTitle !== newSanitizedCaseTitle) {
      this.setState({
        isSaveButtonDisable: newSanitizedCaseTitle === textValue || prevCaseGuid !== caseGuid
      });
    }
  }
  handleTitleSave = (e, caseGuid, inputValue) => {
    const { saveMyCaseBT } = this.props;
    const { MY_CASES: { TITLE_PATH } } = CONFIG;
    if (inputValue !== '') {
      saveMyCaseBT({
        guid: caseGuid.toLowerCase(),
        data: {
          title: inputValue,
          id: caseGuid.toLowerCase(),
          path: TITLE_PATH
        }
      });
    }
  }

  handleTextAreaChange = (value) => {
    this.setState({
      textValue: value
    });
  }

  handleBSTTaxonomySubmit = (caseGuid, data) => {
    const { saveMyCaseBT } = this.props;
    const { MY_CASES: { BST_PATH } } = CONFIG;
    if (data[0].length || data[1].length || data[2].length) {
      saveMyCaseBT({
        guid: caseGuid.toLowerCase(),
        data: {
          ipaTags: data[0],
          fpaTags: data[1],
          bcgInternalTags: data[2],
          id: caseGuid.toLowerCase(),
          path: BST_PATH
        }
      });
    }
  }


  handleSubjectTaxonomySubmit = (caseGuid, data) => {
    const { saveMyCaseBT } = this.props;
    const { MY_CASES: { SUBJECT_PATH } } = CONFIG;
    if (data[0].length) {
      saveMyCaseBT({
        guid: caseGuid.toLowerCase(),
        data: {
          subjectTags: data[0],
          id: caseGuid.toLowerCase(),
          path: SUBJECT_PATH
        }
      });
    }
  }

  render() {
    const { isSaveTitleLoading, data, kmsOwnerName, isSaveSubjectLoading, isSaveBstLoading, expertTagsIds, subjectTagsIds } = this.props;
    const { isSaveButtonDisable } = this.state;
    const {
      MY_CASES: {
        ADMINISTRATIVE_TITLE,
        SUBJECT_TAGGING,
        ADMIN_SELECT_PA_HEADING,
        OPENED_ON,
        CASE_EDITOR,
        CASE_CAPTURE_STATUS,
        CASE_E_OFFICE,
        CASE_E_OFFICE_LINK_TEXT,
        CASE_STATUS,
        UPDATED_ON,
        CLIENT,
        BU,
        UNSANITIZED_CASE_TITLE,
        TITLE_HEADING,
        TITLE_PLACEHOLDER,
        TITLE_SAVE_BTN,
        TITLE_SAVE_BTN_LOADING
      },
      CP_CONTRIBUTION: {
        EMPTY_VALUE,
      },
      TAXONOMY: {
        SEARCH,
        IPA_FPA_MODAL_SEARCH_PLACEHOLDER,
        SEARCH_SUBJECT_PLACEHOLDER,
        TYPESEARCH_PLACEHOLDER
      }
    } = LABELS;

    const {
      CP_TAXONOMY_CONFIG: {
        PA,
        SUBJECT
      }
    } = CONFIG;
    const {
      TBDBS: {
        SEARCH_FPA_IPA_INTERNAL,
        SEARCH_SUBJECT,
      },
    } = CONSTANTS;
    const {
      CASE_E_OFFICE_LINK,
      MY_CASES: {
        TITLE_MAX_LIMIT
      },
    } = CONFIG;

    const {
      caseType,
      caseGuid,
      caseNumber,
      tbOpenDate,
      caseCaptureStatus,
      caseStatus,
      updatedOn,
      clientName,
      clientDescription,
      businessUnit,
      clientBusinessUnitDescription,
      unsanitizedCaseTitle,
      sanitizedCaseTitle,
      bcgInternalList,
      fpaTagList,
      ipaTagList,
      subjectTags,
    } = data;
    const clientBUNameToShow = getContentToDisplay(businessUnit, EMPTY_VALUE, clientName?.toLowerCase() !== businessUnit?.toLowerCase());
    const clientBusinessUnitDescriptionToShow = getContentToDisplay(clientBusinessUnitDescription, EMPTY_VALUE, clientName?.toLowerCase() !== businessUnit?.toLowerCase());
    const caseData = {
      [OPENED_ON]: tbOpenDate,
      [CASE_EDITOR]: kmsOwnerName?.displayName,
      [CASE_CAPTURE_STATUS]: caseCaptureStatus,
      [CASE_E_OFFICE]: { text: CASE_E_OFFICE_LINK_TEXT, link: CASE_E_OFFICE_LINK(caseNumber) },
      [CASE_STATUS]: caseStatus,
      [UPDATED_ON]: updatedOn,
    }
    const clientData = {
      [CLIENT]: {
        name: clientName,
        des: clientDescription
      },
      [BU]: {
        name: clientBUNameToShow,
        des: clientBusinessUnitDescriptionToShow
      },
      [UNSANITIZED_CASE_TITLE]: {
        des: unsanitizedCaseTitle
      },
    }

    return (
      <div className="mycases">
        <CaseBar
          caseType={caseType}
          caseNumber={caseNumber}
          caseData={caseData}
          caseGuid={caseGuid}
        />
        <div className="mycases__left">
          <CaseDetailsBT
            clientData={clientData} />
        </div>
        <div className="mycases__right">
          {/* title and tag section */}
          <h5>{TITLE_HEADING}</h5>
          <TextAreaCounter
            maxLength={TITLE_MAX_LIMIT}
            placeHolder={TITLE_PLACEHOLDER}
            prefillValue={sanitizedCaseTitle}
            buttonText={TITLE_SAVE_BTN}
            buttonClick={(e, inputValue) => this.handleTitleSave(e, caseGuid, inputValue)}
            isButtonLoading={isSaveTitleLoading}
            buttonTextLoading={TITLE_SAVE_BTN_LOADING}
            buttonDisable={isSaveButtonDisable}
            handleChangeCallback={this.handleTextAreaChange}
          />
          <ul className="mycases__taxonomy">
            <li>
              <Taxonomy
                v2
                preKeyFetched
                label={ADMINISTRATIVE_TITLE}
                placeholder={SEARCH}
                modalHeader={ADMIN_SELECT_PA_HEADING}
                taxonomyName={PA}
                hideTagsIds={[...subjectTagsIds]}
                isApplicableForPolyHerarchi
                preFillData={[
                  ipaTagList,
                  fpaTagList,
                  bcgInternalList
                ]}
                openModalCallback={() => { }}
                clickHandlerCallback={() => { }}
                deleteSelectedCallBack={() => { }}
                onChange={(data) => this.handleBSTTaxonomySubmit(caseGuid, data)}
                responseTypeSearchKeyName={{
                  id: "id",
                  name: "name",
                }}
                isShowParentTagsTooltip
                typeSearchplaceholder={IPA_FPA_MODAL_SEARCH_PLACEHOLDER}
                characterCount={2}
                taxonomyTypeSearchName={[
                  { searchKey: SEARCH_FPA_IPA_INTERNAL }
                ]}
                informationIconsConfig={getTaggedTaxonomyConfig()}
                disabled={isSaveBstLoading}
              />
            </li>
            <li>
              <Taxonomy
                label={SUBJECT_TAGGING}
                v2
                preKeyFetched
                placeholder={SEARCH_SUBJECT_PLACEHOLDER}
                modalHeader={SUBJECT_TAGGING}
                taxonomyName={SUBJECT}
                preFillData={[
                  subjectTags,
                ]}
                hideTagsIds={[...expertTagsIds]}
                onChange={(data) => this.handleSubjectTaxonomySubmit(caseGuid, data)}
                openModalCallback={() => { }}
                clickHandlerCallback={() => { }}
                deleteSelectedCallBack={() => { }}
                submitHandlerCallback={() => { }}
                responseTypeSearchKeyName={{
                  id: "id",
                  name: "name",
                }}
                isShowParentTagsTooltip
                typeSearchplaceholder={TYPESEARCH_PLACEHOLDER}
                characterCount={2}
                taxonomyTypeSearchName={[
                  { searchKey: SEARCH_SUBJECT }
                ]}
                informationIconsConfig={getTaggedTaxonomyConfig()}
                disabled={isSaveSubjectLoading}
              />
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

CaseItem.propTypes = {
  data: PropTypes.array.isRequired,
  saveMyCaseBT: PropTypes.func,
  isSaveTitleLoading: PropTypes.bool,
  isSaveSubjectLoading: PropTypes.bool,
  isSaveBstLoading: PropTypes.bool,
  kmsOwnerName: PropTypes.string.isRequired,
  expertTagsIds: PropTypes.array,
  subjectTagsIds: PropTypes.array
};

CaseItem.defaultProps = {
  saveMyCaseBT: () => { },
  isSaveTitleLoading: false,
  expertTagsIds: [],
  subjectTagsIds: [],
  isSaveSubjectLoading: false,
  isSaveBstLoading: false
};