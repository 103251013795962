// Constant
import { KP } from "redux/constants";

// ------------------ get KP downloads count from API -------------------------

export const getDownloadsSuccess = (downloads) => ({
    type: KP.GET_DOWNLOADS_SUCCESS,
    payload: { downloads }
});

export const getDownloadsFailure = (error) => ({
    type: KP.GET_DOWNLOADS_FAILURE,
    payload: { error: error?.toString() }
});

export const getFilesStatusSuccess = (payload) => ({
    type: KP.GET_FILES_STATUS_SUCCESS,
    payload
});

export const getFilesStatusPending = () => ({
    type: KP.GET_FILES_STATUS_PENDING,
});

export const getFilesStatusFailure = (error) => ({
    type: KP.GET_FILES_STATUS_FAILURE,
    payload: { error: error?.toString() }
});

export const getAssociatedBundlesSuccess = (payload) => ({
    type: KP.GET_ASSOCIATED_BUNDLES_SUCCESS,
    payload
});

export const getAssociatedBundlesPending = () => ({
    type: KP.GET_ASSOCIATED_BUNDLES_PENDING,
});

export const getAssociatedBundlesFailure = (error) => ({
    type: KP.GET_ASSOCIATED_BUNDLES_FAILURE,
    payload: { error: error?.toString() }
});

export const getFilesDownloadPending = () => ({
    type: KP.GET_FILE_PENDING,
});

export const getFileDownloadSuccess = () => ({
    type: KP.GET_FILE_SUCCESS
});

export const getFilesDownloadFailure = (error) => ({
    type: KP.GET_FILE_FAILURE,
    payload: { error: error?.toString() }
});



export const getTagsSuggestions = (payload) => ({
    type: KP.GET_TAGS_SUGGESTIONS,
    payload
});

export const resetTagsSuggestions = () => ({
    type: KP.RESET_TAGS_SUGGESTIONS
});

export const getTagsSuggestionsSuccess = (payload) => ({
    type: KP.GET_TAGS_SUGGESTIONS_SUCCESS,
    payload
});

export const getTagsSuggestionsPending = () => ({
    type: KP.GET_TAGS_SUGGESTIONS_PENDING,
});

export const getTagsSuggestionsFailure = (error) => ({
    type: KP.GET_TAGS_SUGGESTIONS_FAILURE,
    payload: { error: error?.toString() }
});

//Actions for Unique Title
export const getUniqueTitleConfirmation = (payload) => ({
    type: KP.GET_UNIQUE_TITLE_CONFIRMATION,
    payload
});

export const getUniqueTitleConfirmationSuccess = (payload) => ({
    type: KP.GET_UNIQUE_TITLE_CONFIRMATION_SUCCESS,
    payload
});

export const getUniqueTitleConfirmationPending = () => ({
    type: KP.GET_UNIQUE_TITLE_CONFIRMATION_PENDING,
});

export const getUniqueTitleConfirmationFailure = (error) => ({
    type: KP.GET_UNIQUE_TITLE_CONFIRMATION_FAILURE,
    payload: { error: error?.toString() }
});

export const resetUniqueTitleConfirmation = () => ({
    type: KP.RESET_UNIQUE_TITLE_CONFIRMATION
});

export const getSubjectSearchPending = () => ({
    type: KP.SUBJECTS_SEARCH_PENDING
})

export const getSubjectSearchSuccess = (payload) => ({
    type: KP.SUBJECTS_SEARCH_SUCCESS,
    payload
})

export const getSubjectSearchFailure = (error) => ({
    type: KP.SUBJECTS_SEARCH_FAILURE,
    payload: { error: error?.toString() }
})

export const setTitleFieldsUpdateState = (payload) => ({
    type: KP.TITLE_FILED_UPDATED,
    payload
})