export function mapMetadataToDoc(kpId, metadata) {
  const doc = {
    kpId,
    docRank: 0,
    title: metadata.title,
    dateModified: metadata.updatedDate,
    searchAbstract: metadata.description,
    materialType: [
      metadata.contentType
    ],
    resultType: 'MA', // TODO: Hardcoded because docviz needs. What is the path forward?
    materialCategory: '', // TODO: Needed when we handle LAB courses
    geographicalRegion: '', // TODO: We have region/country
    region: '', // TODO: API needs to parse/return this
    fileName: metadata.filename,
    attachmentFileType: metadata.filename.slice((metadata.filename.lastIndexOf(".") - 1 >>> 0) + 2),
    URLTitle: '',
    materialURL: '',
    description: metadata.description,
    paRecommended: metadata.isPARecommended,
    winProposalRecommended: false, // TODO: Where does this come from?
    industryPAs: [], // TODO: API needs to return this
    functionalPAs: [], // TODO: API needs to return this
    recordAdministrator: [], // TODO: Where does this come from?
    authors: [], // TODO: API needs to return this
    attachments: [],
    entitled: !metadata.isRestricted,
    baseURL: metadata.url + "/" + metadata.s3Object + "/",
    frameworkSlides: metadata?.slidesTagged || [],
    labCourseId: '',
    labDescription: '',
    labCourseName: '',
    labFrom: '',
    labUrl: '',
    labCourseRating: 0,
    lessonNumber: '',
    lessonDuration: 0,
    previewAvailable: true, // TODO: Compute this??
    relevantSlides: [],
    slides: metadata.previews.map(p => {
      return {
        relevance: 0,
        slideNumber: p.pageNumber,
        imageRef: p.medium.hqUrl,
        htmlUrl: p.html.hqUrl
      }
    }),
    currentSlideNumber: 0
  }

  return doc;
};