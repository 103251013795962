
// utility for calling axios
import { doAxiosRequest } from "config-axios";
// Constant
import { KP, GLOBAL } from "redux/constants";
// Config
import CONFIG from "config";

// helper
import {
  triggerUsabillaSurvey, mergeKnowledgeWithLockInfo
} from "utils/helpers/helpers";

import { actions as peopleResultsActions } from 'redux/actions/peopleresults/peopleresults_actions';

// actions
export const actions = {
  // save metadata
  saveMetaDataKP: (metadata, isShowLoader) => async (dispatch) => {
    let guid = '';
    try {
      dispatch({ type: KP.KP_SAVE_PENDING });
      if (isShowLoader) {
        dispatch({ type: GLOBAL.SHOW_PAGELOADER });
      }
      const axiosConfig = {
        method: "post",
        endpoint: CONFIG.API_URL.SAVE_KP_DATA,
        params: metadata,
        headers: {
          'x-api-key': CONFIG.XA
        }
      };
      const response = await doAxiosRequest(axiosConfig);
      if (response.data.id) {
        dispatch({
          type: KP.KP_SAVE_SUCCESS,
          payload: { data: response.data },
        });
        if (isShowLoader) {
          dispatch({ type: GLOBAL.HIDE_PAGELOADER });
          /**
           * Below line will only call if isShowLoader is pass, not with the every field update api
           * if the message length is greater then 1 inside validationsummary then we have to show a
           * Waring Required field Modal else success code will run
           */
          const {
            id,
            validationsummary: {
              messages = []
            } = {}
          } = response
          if (messages?.length) {
            dispatch({
              type: KP.SHOW_WARNING_MODAL,
              payload: {
                data: messages,
                id: id
              }
            });
          } else {
            triggerUsabillaSurvey('KP_PUBLISH');
          }
        }
        guid = response.data.id;
        return guid;
      }

    } catch (error) {
      dispatch({
        type: KP.KP_SAVE_FAILURE,
        payload: { error: error.toString() },
      });
      if (isShowLoader) {
        dispatch({ type: GLOBAL.HIDE_PAGELOADER });
      }
      throw error;
    }
  },

  getPublishedKPData: (kpid) => async (dispatch) => {
    const {
      getPeopleDataUsingIDs
    } = peopleResultsActions;

    try {
      dispatch({ type: KP.KP_SAVE_PENDING });
      const axiosConfig = {
        method: 'get',
        endpoint: `${CONFIG.API_URL.SAVE_KP_DATA}/{${kpid}}?isConsumptionView=true`,
        headers: {
          'x-api-key': CONFIG.XA
        }
      };

      const response = await doAxiosRequest(axiosConfig);

      // for a knowledge item that is locked (lockedBy has value)
      // convert hr id (kp) to name by making people api call
      // enhance knowledge item with lockedByName field
      let knowledgeItems = [response?.data];
      const ids = [...new Set(knowledgeItems.map(item => item.lockedBy?.toString()))].filter(Boolean);
      if (ids?.length > 0) {
        const people = await dispatch(getPeopleDataUsingIDs(ids, false));
        knowledgeItems = mergeKnowledgeWithLockInfo(knowledgeItems, people);
      }

      dispatch({
        type: KP.KP_PUBLISHED_SUCCESS,
        payload: { data: knowledgeItems[0] },
      });
    } catch (error) {
      dispatch({
        type: KP.KP_SAVE_FAILURE,
        payload: { error: error.toString() },
      });
      throw error;
    }
  },

  getMetaDataKP: (kpid) => async (dispatch) => {
    try {
      dispatch({ type: KP.KP_SAVE_PENDING });
      const axiosConfig = {
        method: 'get',
        endpoint: `${CONFIG.API_URL.SAVE_KP_DATA}/{${kpid}}`,
        headers: {
          'x-api-key': CONFIG.XA
        }
      };
      const response = await doAxiosRequest(axiosConfig);
      dispatch({
        type: KP.KP_SAVE_SUCCESS,
        payload: { data: response.data },
      });
    } catch (error) {
      dispatch({
        type: KP.KP_SAVE_FAILURE,
        payload: { error: error.toString() },
      });
      throw error;
    }
  },

  clearMetaDataKP: () => async (dispatch) => {
    dispatch({ type: KP.KP_CLEAR });
  },

  // get Knowledge Page Type and Language Dropdown data
  getDropdownDataFromApi: (id, name) => async (dispatch) => {
    dispatch({
      type: KP.DROPDOWN_DATA_PENDING,
      payload: "",
    });
    try {
      const { SITECORE_XM_ENABLED } = CONFIG;
      const axiosConfig = {
        method: "get",
        endpoint: SITECORE_XM_ENABLED ? CONFIG.API_URL.GET_TAXONOMY_XM(id) : CONFIG.API_URL.GET_TAXONOMY(id),
        headers: {
          'x-api-key': CONFIG.XA
        }
      };
      const List = await doAxiosRequest(axiosConfig);
      if (name === "knowledgeType") {
        dispatch({
          type: KP.KNOWLEDGE_PAGE_TYPE_DROPDOWN_DATA_SUCCESS,
          payload: List.value,
        });
      } else {
        dispatch({
          type: KP.KNOWLEDGE_PAGE_LANGUAGE_DROPDOWN_DATA_SUCCESS,
          payload: List.value,
        });
      }
    } catch (error) {
      dispatch({
        type: KP.DROPDOWN_DATA_FAILURE,
        payload: { error: error.toString() },
      });
      throw error;
    }
  },
  resetDownloadError: () => async dispatch => {
    dispatch({ type: KP.RESET_DOWNLOAD_ERROR });
  }
};
