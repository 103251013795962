import React, { useEffect } from 'react';
import CONFIG from 'config';
import { getUserEmail } from 'utils/auth/auth';
import './UsabillaSetup.scss';

const UsabillaSetup = () => {
  useEffect(() => {
    const loadUsabilla = async () => {
      const userEmail = getUserEmail();
      try {
        window.usabilla_live('data', { email: userEmail, custom: { app_version: CONFIG.KNOWLEDGE_CONTRIBUTE_VERSION } });
      } catch (err) {
        console.log(err);
      }
    };

    loadUsabilla();
  }, []);

  return <div />;
};

export default UsabillaSetup;