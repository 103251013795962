import React from "react";
import PropTypes from "prop-types";
import Image from "components/shared/Image";
import LocationIcon from "assets/images/location.svg";
import LABELS from "labels";
import "./Location.scss";

export const Location = ({ name, id}) => {
  return (
    <>
    { name && 
        <div className="location" id={id}>
            <span className="location__icon"><Image src={LocationIcon} alt={LABELS.ALT_TEXTS.LOCATION_ICON}  /></span>
            <span className="location__name">{name}</span>      
        </div>
    }
    </>
  );
};

Location.propTypes = {
    name: PropTypes.string,
    id: PropTypes.string    
};
Location.defaultProps = {
    name: ''  
};
