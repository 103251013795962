import React from 'react';
import { Form } from "semantic-ui-react";
import PropTypes from "prop-types";
import Counter from "components/shared/Counter";

import "./InputCounter.scss"

export const InputCounter = ({ maxLength, value, onChange, onBlur, customClass, ...props }) => {
    return (
        <Form.Input
            type="text"
            maxLength={maxLength}
            value={value}
            icon={
                <>
                    {customClass ? <div className={customClass}>
                        <Counter
                            inputLength={value.length}
                            maxLength={maxLength}
                        />
                    </div> :
                        <Counter
                            inputLength={value.length}
                            maxLength={maxLength}
                        />}

                </>
            }
            onBlur={(e) => onBlur(e.target.value)}
            onChange={(e) => onChange(e.target.value)}
            {...props}
        />
    )
};

InputCounter.propTypes = {
    maxLength: PropTypes.string,
    value: PropTypes.string
};

InputCounter.defaultProps = {
    value: '',
    maxLength: ''
};