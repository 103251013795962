
/**
 * @desc this function is get all children ids
 * @param childrendata as array of objects
 * @returns array with only Ids
 */
export const getAllIdofitemChildren = (childrendata, responseKeyName, leafArr = []) => {
  const { id, children } = responseKeyName;
  let tempArr = leafArr;
  for (let eachchild in childrendata) {
    if (!(childrendata[eachchild][children] && childrendata[eachchild][children].length)) {
      tempArr.push(childrendata[eachchild][id]);
    } else {
      getAllIdofitemChildren(childrendata[eachchild][children], responseKeyName, tempArr);
    }
  }
  return tempArr;
}

/**
 * @desc this function is check a parent item has any Child selected already or not
 * @param item as object (key value pair)
 * @param selectedRegions as array of objects (key value pair)
 * @returns className to be shown inside the checkbox
 */

export const checkIfItemHasSelectedChild = (item, selectedRegions, responseKeyName, isTop) => {
  const { id, children } = responseKeyName;
  let childrenItems = item[children];
  let selectedData = [];
  let selection = 'notchecked';
  let isTopMostParent = false;

  let selectedLength = 0, totalLength = 0, totalLengthWithChildren = 0;
  let childAllIds = [];

  for (let eachchild in childrenItems) {
    if (isTop) {
      if (childrenItems[eachchild][children] && childrenItems[eachchild][children].length) {
        childAllIds = getAllIdofitemChildren(childrenItems[eachchild][children], responseKeyName);
        totalLengthWithChildren += childAllIds.length;
      } else {
        totalLengthWithChildren = totalLengthWithChildren + 1;
      }
      isTopMostParent = true;
    } else {
      if (childrenItems[eachchild][children] && childrenItems[eachchild][children].length) {
        childAllIds = getAllIdofitemChildren(childrenItems[eachchild][children], responseKeyName);
        totalLength = totalLength + childAllIds.length;
      } else {
        childAllIds = [];
        totalLength = totalLength + 1;
      }
      isTopMostParent = false;
    }
    for (let eachregion in selectedRegions) {
      const eachValue = selectedRegions[eachregion][id];
      if (childAllIds.indexOf(eachValue) >= 0 && selectedData.indexOf(eachValue) === -1) {
        selectedData.push(eachValue);
      }
      if (eachValue === childrenItems[eachchild][id] && selectedData.indexOf(eachValue) === -1) {
        selectedData.push(eachValue);
      }
    }
  }
  selectedLength = selectedData.length;
  if (isTopMostParent) {
    if (selectedLength && selectedLength < totalLengthWithChildren) {
      selection = 'partial';
    } else if (selectedLength === totalLengthWithChildren) {
      selection = 'checked';
    }
  } else {
    if (selectedLength && selectedLength < totalLength) {
      selection = 'partial';
    } else if (selectedLength === totalLength) {
      selection = 'checked';
    }
  }
  return selection;
}