import React from "react";
import Image from "components/shared/Image";
import { Link } from "react-router-dom";
import ContributePlusIcon from "assets/images/plus.svg";
import { Tooltip } from "components/shared/Tooltip";
import CONFIG from "config";
import ANALYTICS from "utils/analytics/analytics";
import { DTMRULE, PAGES, PRIMARY_CATEGORY, SCREEN_NAMES, TRIGGERS } from "utils/analytics/analytics_constants";
import "./ContributeKnwldgBtn.scss";
//labels
import LABELS from "labels";

export const ContributeKnwldgBtn = ({ url, disable, disableTooltipHTML }) => {
  const analyticsHandler = () => {
    const newAdobeData = {
      page: {
        pageInfo: {
          pageName: PAGES.MY_NAVIGATOR
        },
        category: {
          primaryCategory: PRIMARY_CATEGORY.MY_NAVIGATOR
        }
      },
      collection: {
        myTrigger: TRIGGERS.CONTRIBUTE_KNOWLEDGE, //evar135
        screenName: SCREEN_NAMES.MY_CONTRIBUTIONS
      },
    };
    ANALYTICS.kc.sendEventData(newAdobeData, DTMRULE.MY_CREATION_ACTIONS); //Event 234
  };
  const {
    UI_URL: {
      CREATION
    }
  } = CONFIG;
  const { TARGET: { SELF, BLANK } } = LABELS;
  const target = url === CREATION ? SELF : BLANK;
  return (
    disable ? (<div className="contributeknwldg">
      <Tooltip
        position="top"
        id={"contribute-knowledge"}
        isShowCustomizeIconHTML
        icon={
          <span className={`contributeknwldg__button contributeknwldg__button--disable`} data-tip
            data-for={"contribute-knowledge"}>
            <Image
              className="contributeknwldg__icon"
              src={ContributePlusIcon}
              alt="Contribute Knowledge"
            />
            <span className="contributeknwldg__text">{LABELS.BUTTONS.CONTRIBUTE_KNOWLEDGE}</span>
          </span>
        }
      >
        {disableTooltipHTML}
      </Tooltip>
    </div>)
      : (
        <Link
          to={{ pathname: url }}
          className="contributeknwldg"
          onClick={() => analyticsHandler()}
          target={target}
        >
          <span className="contributeknwldg__button">
            <Image
              className="contributeknwldg__icon"
              src={ContributePlusIcon}
              alt="Contribute Knowledge"
            />
            <span className="contributeknwldg__text">{LABELS.BUTTONS.CONTRIBUTE_KNOWLEDGE}</span>
          </span>
        </Link>
      )
  );
};