// constant
import { CASE_KMSOWNER_LIST } from "redux/constants";

// local variable
export const name = "caseKmsOwners";

// initial state
export const initialState = {
    errorStatus: "",
    data: [],
    loading: false,
};

export const selectors = {
    getCaseOwnerList: state => state[name].data,
};

const setCaseOwnerListPending = (state) => {
    return {
        ...state,
        loading: true,
    }
};


const setCaseOwnerListFailure = (state, payload) => {
    const { error } = payload;
    return {
        ...state,
        errorStatus: error,
        loading: false,
    }
};

const setCaseOwnerListSucess = (state, payload) => {
    const { kmsOwnerList } = payload;
    const kmsOwnerData = kmsOwnerList.map(owner => owner.id)
    return {
        ...state,
        ...initialState,
        data: kmsOwnerData,
    }
};

export function reducer(state = initialState, action) {
    const { payload, type } = action
    switch (type) {
        case CASE_KMSOWNER_LIST.GET_CASE_KMSOWNER_LIST_PENDING:
            return setCaseOwnerListPending(state);
        case CASE_KMSOWNER_LIST.GET_CASE_KMSOWNER_LIST_FAILURE:
            return setCaseOwnerListFailure(state, payload);
        case CASE_KMSOWNER_LIST.GET_CASE_KMSOWNER_LIST_SUCCESS:
            return setCaseOwnerListSucess(state, payload);

        default:
            return state;
    }
}