import { put, call, takeLatest } from 'redux-saga/effects';
// Constant
import { CASE_KMSOWNER_LIST } from "redux/constants";
// Config
import CONFIG from "config";
// Label
import LABELS from "labels";
// Axios Util
import { doAxiosRequest } from "config-axios";
import { moduleFailure } from "redux/global.action.js";
import {
    getCaseOwnerListPending,
    getCaseOwnerListSuccess,
    getCaseOwnerListFailure,
} from "./caseOwners.actions";

export function* getCaseOwnerList() {
    try {
        yield put(getCaseOwnerListPending());
        const {
            API_URL: {
                GET_CASE_KMSOWNERS
            },
            XA,
            STATUS_CODES: {
                SUCCESS
            }
        } = CONFIG;
        const res = yield call(doAxiosRequest, {
            method: 'get',
            endpoint: GET_CASE_KMSOWNERS(),
            headers: {
                'x-api-key': XA,
            }
        });
        if (res?.error?.code === SUCCESS || (res && !res.error)) {
            yield put(getCaseOwnerListSuccess(res));
        } else {
            const { CP_CONTRIBUTION: { T_AND_B_ERROR } } = LABELS;
            yield put(getCaseOwnerListFailure(T_AND_B_ERROR));
        }
    } catch (error) {
        yield put(getCaseOwnerListFailure(error));
    }
}


export default function* CaseOwnerSaga() {
    const { GET_CASE_KMSOWNER_LIST } = CASE_KMSOWNER_LIST;
    try {
        yield takeLatest(GET_CASE_KMSOWNER_LIST, getCaseOwnerList);
    } catch (error) {
        yield put(moduleFailure(error, 'CaseOwnerSaga'));
    }
}