import React, { Component } from "react";
import { Layout } from "containers/Layout";
import { Link } from "react-router-dom";
import { Tooltip } from "components/shared/Tooltip";
import { CreationOptionModal } from "components/CreationOptionModal";
import LABELS from "labels";
import CONFIG from "config";
import CONSTANTS from "globalConstants";
import { connect } from "react-redux";

import ANALYTICS from "utils/analytics/analytics";
import { selectors as entitlementSelectors } from "redux/reducers/authorization/authorization_reducer";
import { PAGES, PRIMARY_CATEGORY, DTMRULE, TRIGGERS, SCREEN_NAMES } from "utils/analytics/analytics_constants";
import { Helmet } from "react-helmet";

import "./Creation.scss";
const {
    ENTITLEMENTS: {
        KNOWLEDGE_CONTRIBUTIONS,
        SHOW_BUNDLE,
    }
} = CONSTANTS;
class CreationContainer extends Component {
    constructor(props) {
        super(props);
        const {
            history: {
                location: {
                    pathname,
                    search,
                }
            }
        } = props;
        const { UI_URL: { CREATION_KP } } = CONFIG;
        this.state = {
            isModalOpen: `${pathname}${search}` === CREATION_KP
        }
    }

    componentDidMount = () => {
        ANALYTICS.page.initialize({ pageName: PAGES.CREATION, category: PRIMARY_CATEGORY.CREATION, trackPageLoad: true });
    }

    handleModalClick = () => {
        const { CREATION: { CREATE_KP_BTN } } = TRIGGERS;
        this.setState({
            isModalOpen: true
        });
        this.hitAnalytics(CREATE_KP_BTN);
    }

    /**
 * @desc closes the help modal on esc, or click of cancel button
 */
    cancelModal = () => {
        this.setState({
            isModalOpen: false
        })
    }

    hitAnalytics = (triggerparam) => {
        // Adobe Analytics event 232 - Creation screen
        ANALYTICS.kc.sendEventData({
            page: {
                pageInfo: {
                    pageName: PAGES.CREATION
                },
                category: {
                    primaryCategory: PRIMARY_CATEGORY.KP
                }
            },
            collection: {
                trigger: triggerparam,
                screenName: SCREEN_NAMES.CREATION,
            }
        }, DTMRULE.CREATION);
    }

    render() {
        const {
            CREATION: {
                PAGE_TITLE,
                CREATE_KNOWLEDGE_PAGE_BTN_HEADING,
                CREATE_KNOWLEDGE_PAGE_TIPTEXT,
                CREATE_ASSOCIATE_BUNDLE_BTN_HEADING,
                CREATE_ASSOCIATE_BUNDLE_TIPTEXT,
                CREATE_KNOWLEDGE_BUNDLE_BTN_HEADING,
                CREATE_KNOWLEDGE_BUNDLE_TIPTEXT,
                EDIT_CASE_PAGE_BTN_HEADING,
                EDIT_CASE_PAGE_TIPTEXT,
                CREATION_PAGE_HERE_TO_TEXT,
                CREATE_KNOWLEDGE_PAGE_WITH_FILE,
                UPLOAD_ASSETS_TEXT,
                CREATE_KNOWLEDGE_PAGE_WITH_URL,
                ADD_URL_TEXT
            },
            BUTTONS: {
                CREATE,
                CANCEL
            },
            PAGETITLE: {
                CREATION
            }
        } = LABELS;

        const { showBundleEntitlement } = this.props;

        const { CREATION: { CREATE_KB_BTN, CREATE_TPKB_BTN, CREATE_EDITCASE_BTN } } = TRIGGERS;
        const { isModalOpen } = this.state;
        const { KNOWLEDGEBUNDLE, STEP1 } = CONSTANTS;
        return (
            <Layout
                id="creationdashboard"
                layoutClass="layout__creation"
                pageTitle={PAGE_TITLE}
                breadCrumbFlag
                productName={LABELS.KNOWLEDGE_PRODUCT_NAME.CREATION}
                bannerFlag
            >
                <Helmet>
                    <title>{CREATION}</title>
                </Helmet>
                <div className="layout__pagestyle layout__page layout__fix--inner">
                    <p className="layout__text">{CREATION_PAGE_HERE_TO_TEXT}</p>
                    <ul className="layout__creationblock">
                        <li className="layout__creationbtn">
                            <div className="layout__creationlink layout__btn-tag" onClick={this.handleModalClick}>
                                <span className="layout__creationtxt">{CREATE_KNOWLEDGE_PAGE_BTN_HEADING}</span>
                                <Tooltip id="create_kp_btn" position="right">{CREATE_KNOWLEDGE_PAGE_TIPTEXT}</Tooltip>
                            </div>
                        </li>
                        <li className="layout__creationbtn hidevisibility">
                            <Link onClick={() => this.hitAnalytics(CREATE_TPKB_BTN)} className="layout__creationlink" to={{ pathname: CONFIG.UI_URL.METADATA_KB(STEP1) }}>
                                <span className="layout__creationtxt">{CREATE_ASSOCIATE_BUNDLE_BTN_HEADING}</span>
                                <Tooltip id="create_kp_btn_associated" position="right">{CREATE_ASSOCIATE_BUNDLE_TIPTEXT}</Tooltip>
                            </Link>
                        </li>
                        {!!showBundleEntitlement &&
                            <li className="layout__creationbtn">
                                <Link onClick={() => this.hitAnalytics(CREATE_KB_BTN)} className="layout__creationlink" to={{ pathname: CONFIG.UI_URL.METADATA_KB(STEP1), search: `?bundleType=${KNOWLEDGEBUNDLE}` }}>
                                    <span className="layout__creationtxt">{CREATE_KNOWLEDGE_BUNDLE_BTN_HEADING}</span>
                                    <Tooltip id="create_kb_btn" position="right">{CREATE_KNOWLEDGE_BUNDLE_TIPTEXT}</Tooltip>
                                </Link>
                            </li>
                        }
                        <li className="layout__creationbtn hidevisibility">
                            <Link onClick={() => this.hitAnalytics(CREATE_EDITCASE_BTN)} className="layout__creationlink layout__creationlink--disabled" to={{ pathname: CONFIG.UI_URL.CREATION }}>
                                <span className="layout__creationtxt">{EDIT_CASE_PAGE_BTN_HEADING}</span>
                                <Tooltip id="create_case_btn" position="right">{EDIT_CASE_PAGE_TIPTEXT}</Tooltip>
                            </Link>
                        </li>
                    </ul>
                </div>
                <CreationOptionModal
                    isOpen={isModalOpen}
                    onRequestClose={this.cancelModal}
                    createKpWithFile={CREATE_KNOWLEDGE_PAGE_WITH_FILE}
                    uploadTxt={UPLOAD_ASSETS_TEXT}
                    createKpWithUrl={CREATE_KNOWLEDGE_PAGE_WITH_URL}
                    addUrlTxt={ADD_URL_TEXT}
                    btnCreate={CREATE}
                    btncancel={CANCEL}
                />
            </Layout>
        )
    }
}

const mapStateToProps = (state) => ({
    showBundleEntitlement: entitlementSelectors.getEntitlementValue(state, KNOWLEDGE_CONTRIBUTIONS, SHOW_BUNDLE),
});

export const Creation = connect(mapStateToProps, null)(CreationContainer);

export { CreationContainer };