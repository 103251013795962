import React from "react";
import Term from "./Term";

const LoadingError = () => <div className="treeViewer__loading treeViewer__error">Failed to load data</div>;

const LoadingSpinner = () => <div className="treeViewer__loading">Loading...</div>;

const TermChildren = ({
  node,
  path,
  onOpen,
  onClose,
  onClick,
  selectedObj,
}) => {
  const { isOpen, children, error } = node;
  return isOpen ? (
    <div className="term-children">
      {error != null ? (
        <LoadingError />
      ) : children.length === 0 ? (
        <LoadingSpinner />
      ) : (
        <>
          {children.map((childNode) => (
            <Term
              key={childNode.term.id}
              node={childNode}
              path={[...path, childNode.term.id]}
              onClick={onClick}
              onOpen={onOpen}
              onClose={onClose}
              selectedObj={selectedObj}
            />
          ))}
        </>
      )}
    </div>
  ) : null;
};

export default TermChildren;
