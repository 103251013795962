import { put, call, takeLatest } from 'redux-saga/effects';
// Constant
import { KNOWLEDGE_HISTORY } from "redux/constants";
// Config
import CONFIG from "config";
// Axios Util
import { doAxiosRequest } from "config-axios";
// Actions
import {
  getAdminHistorySuccess, getAdminHistoryPending, getAdminHistoryFailure
} from "./knowledgehistory.actions";
import { moduleFailure } from "redux/global.action.js";

export function* getAdminHistory(action) {
  try {
    yield put(getAdminHistoryPending());
    const { payload: { KPId } } = action;
    const { API_URL: { GET_ADMIN_HISTORY_PATH } } = CONFIG;

    const axiosConfig = {
      method: 'GET',
      endpoint: GET_ADMIN_HISTORY_PATH(KPId),
      headers: {
        'x-api-key': CONFIG.XA
      }
    };
    const response = yield call(doAxiosRequest, axiosConfig);
    yield put(getAdminHistorySuccess(response?.itemHistory || []));

  } catch (error) {
    yield put(getAdminHistoryFailure(error));
  }
};

export default function* KnowledgeHistorySaga() {
  try {
    yield takeLatest(KNOWLEDGE_HISTORY.GET_ADMIN_HISTORY, getAdminHistory);
  } catch (error) {
    yield put(moduleFailure(error, 'KnowledgeHistorySaga'));
  }
}
