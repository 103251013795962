/***
 * VersionKnowledgeObjectContainer High Order Component 
 * * */
//React
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { WarningModal } from "components/WarningModal/WarningModal";
import { TRIGGERS } from "utils/analytics/analytics_constants";
import { actions as versioningActions } from "redux/actions/versioning/versioning_actions";
import {
  notifyWarning,
  dismissNotifications,
} from "redux/actions/notification/notification_actions";
import { actions as mynavigatorActions } from "redux/actions/mynavigator/mynavigator_actions";
import history from "redux/history";
import { openURL } from "utils/helpers/helpers";

import { selectors as versioningSelectors } from "redux/reducers/versioning/versioning_reducer";
import { selectors as mynavigatorSelectors } from "redux/reducers/mynavigator/mynavigator_reducer";

import LABELS from "labels";
import CONFIG from "config";
export class VersionKnowledgeObjectContainer extends Component {
  /**
   *Creates an instance of HOC.
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      isWarningModalVisible: false,
      warningModalId: false,
      itemType: '',
      entityData: {}
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { versioningErrorStatus, warningModalCallBack } = this.props;
    const { isWarningModalVisible } = this.state;
    if (prevState.isWarningModalVisible !== isWarningModalVisible && !!warningModalCallBack) {
      warningModalCallBack(isWarningModalVisible);
    }
    if (prevProps.versioningErrorStatus !== this.props.versioningErrorStatus) {
      if (versioningErrorStatus.error) {
        this.setErrorState();
      }
    }
  }

  editRedirect = (id) => {
    const {
      analyticsCallBack,
      openInNewTab,
      redirectUrl,
      entity,
      isRpaReview,
    } = this.props;

    const url = redirectUrl(id);
    if (analyticsCallBack) {
      const {
        KNOWLEDGE_STATUS: {
          UNPUBLISHED
        }
      } = CONFIG;
      if (entity.status === UNPUBLISHED) {
        // for move to draft option
        analyticsCallBack(id, TRIGGERS.MOVE_TO_DRAFT);
      }
      else {
        analyticsCallBack(id);
      }
    }
    if (openInNewTab)
      openURL(url, true);
    else {
      let pushObj = {
        pathname: url,
      };
      if (isRpaReview) {
        pushObj['search'] = "?isrpareview=true"
      }
      history.push(pushObj);
    }
    this.closeWarningModalHandler();
  }

  actionHandler = (event, toOpenModal, entity) => {
    this.setState({
      entityData: entity
    });
    if (toOpenModal) {
      this.openWarningModal(event, entity);
    } else {
      this.editHandler(event, entity);
    }
  }

  openWarningModal = (event, entity) => {
    event.stopPropagation();
    this.setState({
      isWarningModalVisible: true,
      entityData: entity
    });
  }
  editHandler = (event, entity) => {
    event.stopPropagation();
    const {
      entityId,
      itemType
    } = this.props;

    const {
      KNOWLEDGE_STATUS: {
        PUBLISHED,
        ARCHIVED,
        UNPUBLISHED
      }
    } = CONFIG;

    if (entity) {
      this.setState({
        itemType: itemType
      });

      if (entity.status === PUBLISHED || entity.status === UNPUBLISHED || entity.status === ARCHIVED) {
        // for both pages and bundles, before editing show warning modal
        this.setState({
          isWarningModalVisible: true,
          warningModalId: entityId
        });
      } else {
        this.editRedirect(entityId);
      }
    }
  };

  setErrorState = () => {
    const {
      notifyWarningMessage,
      dismissAllNotifications,
    } = this.props;
    dismissAllNotifications();
    const { WORK_SPACE: { EDIT_API_ERROR_TEXT, EDIT_API_ERROR_TITLE } } = LABELS;
    setTimeout(() => {
      this.closeWarningModalHandler();
      notifyWarningMessage(EDIT_API_ERROR_TITLE, EDIT_API_ERROR_TEXT);
    }, 200);
    setTimeout(() => {
      dismissAllNotifications();
    }, 10000);
  }


  confirmWarningModalHandler = async (event) => {
    event.stopPropagation();
    const { warningModalId, itemType, entityData: entity } = this.state;
    const { KNOWLEDGE_TYPE_KEYS: { BUNDLES, COLLECTIONS, PAGES } } = CONFIG;
    const { toOpenModal, actionData: { callBack }, isContributionPage } = this.props;
    if (toOpenModal) {
      callBack && callBack(event, entity);
      this.closeWarningModalHandler();
    } else {
      const {
        mynavigatorActions: {
          searchKnowledge
        },
        searchCriteria
      } = this.props;
      if (warningModalId) {
        const { versioningActions: { editPublishedKP, editPublishedKB }, handleClose, isRpaReview } = this.props;
        this.setState({
          isWarningModalVisible: false
        });
        if (itemType === PAGES) {
          const response = await editPublishedKP(warningModalId, isRpaReview, isContributionPage);
          if (response.id) {
            this.editRedirect(warningModalId);
            !!handleClose && handleClose();
          }
        }
        else if (itemType === BUNDLES || itemType === COLLECTIONS) {
          const response = await editPublishedKB(warningModalId, isRpaReview, isContributionPage);
          if (response.kbId) {
            this.editRedirect(warningModalId);
          }
        }
        // if editing a published item, refresh my navigator view
        // there needs to be a delay because we need to wait for sitecore to publish item to web index
        // this will be removed once versioning is implemented in sitecore
        setTimeout(() => {
          searchKnowledge(searchCriteria);
        }, 1250);
      }
    }
  };

  closeWarningModalHandler = (event) => {
    if (event) {
      event.stopPropagation();
    }
    const { handleClose, isContributionPage, isTextClicked } = this.props;
    if (!!handleClose)
      handleClose();
    this.setState({
      isWarningModalVisible: false,
      warningModalId: false,
      entityData: {}
    });
    isContributionPage && isTextClicked(true);
  }

  // return the component
  render() {
    const {
      children,
      modalHeading,
      toOpenModal,
      entity,
      btnDisabled,
      apiBtnName
    } = this.props;

    const {
      MY_NAVIGATOR: {
        BUTTONS: {
          WARNING_MODAL_CANCEL
        }
      },
    } = LABELS;

    return (
      <>
        <button
          className={`kea__btn ${btnDisabled ? "kea__btn--disabled" : ""}`}
          onClick={(event) => this.actionHandler(event, toOpenModal, entity)}
          disabled={btnDisabled}
        >
          {children}
        </button>
        <div onClick={(e) => e.stopPropagation()}>
          <WarningModal
            heading={modalHeading}
            button1={{
              clickHandler: (event) => this.closeWarningModalHandler(event),
              text: WARNING_MODAL_CANCEL,

            }}
            button2={{
              text: apiBtnName,
              clickHandler: (event) => this.confirmWarningModalHandler(event),
            }}
            isOpen={this.state.isWarningModalVisible}
            onRequestClose={this.closeWarningModalHandler}
          />
        </div>
      </>
    )
  }
}

VersionKnowledgeObjectContainer.defaultProps = {
  isTextClicked: () => { }
}


const mapStateToProps = (state) => ({
  versioningErrorStatus: versioningSelectors.getErrorStatus(state),
  searchCriteria: mynavigatorSelectors.getsearchCriteria(state)
});

const mapDispatchToProps = (dispatch) => ({
  versioningActions: bindActionCreators({ ...versioningActions }, dispatch),
  notifyWarningMessage: (title, msg) => dispatch(notifyWarning(title, msg)),
  dismissAllNotifications: () => dispatch(dismissNotifications()),
  mynavigatorActions: bindActionCreators({ ...mynavigatorActions }, dispatch)
});


const VersionKnowledgeObjectHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(VersionKnowledgeObjectContainer);

export default VersionKnowledgeObjectHOC;