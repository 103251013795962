import React from 'react';
import PropTypes from 'prop-types';
import { KnowledgeEntity } from 'components/KnowledgeEntity';
import { openURL } from "utils/helpers/helpers";
import LABELS from 'labels';
import CONFIG from 'config';
import './AssociatedBundles.scss';

const openConsumptionPage = (id) => {
  const { UI_URL: { KB_CONSUMPTION } } = CONFIG;
  const url = KB_CONSUMPTION(id);
  openURL(url, true);
}

const AssociatedBundles = ({ bundles = [], clickCallBack }) => {
  const {
    BUNDLE_TYPE: { TOPIC_COLLECTION }
  } = LABELS;
  const {
    KNOWLEDGE_TYPE_KEYS: { BUNDLES, COLLECTIONS }
  } = CONFIG;

  return (
    <section className="associatedBundles">
      <ul>
        {bundles.map((kb, index) => {
          const { kbId: id, bundleType } = kb;
          return (
            <li className="associatedBundles__item" key={id} onClick={() => {
              if (clickCallBack) clickCallBack(id, index);
              openConsumptionPage(id);
            }}>
              <KnowledgeEntity
                entity={kb}
                entityType={bundleType?.name === TOPIC_COLLECTION ? COLLECTIONS : BUNDLES}
                hideStatus={true}
              />
            </li>
          )
        })}
      </ul>
    </section>
  );
};

AssociatedBundles.propTypes = {
  bundles: PropTypes.arrayOf(PropTypes.object),
};

AssociatedBundles.defaultProps = {
  bundles: [],
};

export default React.memo(AssociatedBundles);