import { put, takeLatest, call } from 'redux-saga/effects';
// Constant
import { MY_NAVIGATOR } from "redux/constants";
// Config
import CONFIG from "config";
// Axios Util
import { doAxiosRequest } from "config-axios";
import {
    getMyActivityListPending,
    getMyActivityListSuccess,
    getMyActivityListFailure,
    getDownloadsCountPending,
    getDownloadsCountSuccess,
    getDownloadsCountFailure

} from "./mynavigator.actions";
import { moduleFailure } from "redux/global.action.js";

export function* getDownloadsData(action) {
    const { payload: { kpids } } = action;
    try {
        const { API_URL: { GET_BULK_DOWNLOADS_COUNTS } } = CONFIG;
        yield put(getDownloadsCountPending());
        const axiosConfig = {
            method: 'GET',
            endpoint: GET_BULK_DOWNLOADS_COUNTS(kpids.toString()),
            headers: {
                'x-api-key': CONFIG.XA
            }
        };
        const res = yield call(doAxiosRequest, axiosConfig);
        yield put(getDownloadsCountSuccess(res?.downloadCountList));

    } catch (error) {
        yield put(getDownloadsCountFailure(error));
    }
}

export function* getMyActivitiesList(action) {
    const { payload: { searchCriteria, searchCriteria: { pageNumber, pageSize, days = 0 } } } = action;
    try {
        const { XA, API_URL: { GET_PA_RECOMMENDED_ACTIVITIES_LIST } } = CONFIG;
        yield put(getMyActivityListPending(searchCriteria));
        let paramurl;

        if (pageNumber && pageSize) {
            paramurl = GET_PA_RECOMMENDED_ACTIVITIES_LIST(pageSize, pageNumber, days);
        }
        const res = yield call(doAxiosRequest, {
            method: 'get',
            endpoint: paramurl,
            headers: {
                'x-api-key': XA
            }
        });
        yield put(getMyActivityListSuccess(res));

    } catch (error) {
        yield put(getMyActivityListFailure(error));
    }
}

export default function* MyNavigatorSaga() {
    try {
        yield takeLatest(MY_NAVIGATOR.GET_MY_ACTIVITY_LIST, getMyActivitiesList);
        yield takeLatest(MY_NAVIGATOR.GET_DOWNLOADS_COUNT, getDownloadsData);

    } catch (error) {
        yield put(moduleFailure(error, 'MyNavigatorSaga'));
    }
}