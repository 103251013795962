// constant
import { GLOBAL } from "redux/constants";
import LABELS from "labels";
// local variable
export const name = "global";
const { LOADING } = LABELS;
// initial state
export const initialState = {
  isVisibleFPLoader: false,
  isBodyScrollable: true,
  loadingText: LOADING,
  userDetails: {}
};
// reducer selectors
export const selectors = {
  isVisibleFPLoader: (state) => state[name].isVisibleFPLoader,
  isBodyScrollable: (state) => state[name].isBodyScrollable,
  getLoadingText: (state) => state[name].loadingText,
  getUserDetails: (state) => state[name].userDetails,
};

const setShowFPLoader = (state, text = LOADING) => {
  return {
    ...state,
    isVisibleFPLoader: true,
    loadingText: text,
  }
};

const setHideFPLoader = (state) => {
  return {
    ...state,
    isVisibleFPLoader: false,
    loadingText: LOADING,
  }
};


const enableBodyScroll = (state) => {
  return {
    ...state,
    isBodyScrollable: true
  }
};

const disableBodyScroll = (state) => {
  return {
    ...state,
    isBodyScrollable: false
  }
};

const setLoggedInUserData = (state, payload) => {
  return {
    ...state,
    userDetails: payload
  }
};

export function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GLOBAL.SHOW_PAGELOADER:
      return setShowFPLoader(state, payload);
    case GLOBAL.HIDE_PAGELOADER:
      return setHideFPLoader(state);

    case GLOBAL.FULLPAGELOADER_ERROR:
      return setHideFPLoader(state);

    case GLOBAL.ENABLE_BODY_OVERFLOW:
      return enableBodyScroll(state);
    case GLOBAL.DISABLE_BODY_OVERFLOW:
      return disableBodyScroll(state);

    case GLOBAL.LOGGEDIN_USER_DATA:
      return setLoggedInUserData(state, payload);
    default:
      return state;
  }
}
