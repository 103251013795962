import { put, takeLatest, call } from 'redux-saga/effects';
// Constant
import { META_DATA } from "redux/constants";
// Config
import CONFIG from "config";
// Axios Util
import { doAxiosRequest } from "config-axios";
import { moduleFailure } from "redux/global.action.js";
import { actions as peopleResultsActions } from 'redux/actions/peopleresults/peopleresults_actions';
import { getAssociatedKPs } from "redux/actions/associatedKPs/associatedKPs.saga";
import { mergeKnowledgeWithLockInfo } from 'utils/helpers/helpers';
import {
  getConsumptionKBPending,
  getConsumptionKBSuccess,
  getConsumptionKBFailure,
  getPreviewConsumptionKBSuccess,
  getMetadataKBPending,
  getMetadataKBSuccess,
  getMetadataKBFailure
} from "./metadata.actions";

export function* getConsumptionKnowledgeBundle(action) {
  const { payload: { kbId, isConsumptionView } } = action;
  const {
    getPeopleDataUsingIDs
  } = peopleResultsActions;

  try {
    yield (put(getConsumptionKBPending()));
    const axiosConfig = {
      method: 'get',
      endpoint: `${CONFIG.API_URL.GET_METADATA(kbId)}?isConsumptionView=${isConsumptionView}`,
      headers: {
        'x-api-key': CONFIG.XA
      }
    };
    const metadata = yield call(doAxiosRequest, axiosConfig);

    if (!!metadata?.data) {
      if (isConsumptionView) {
        let knowledgeItems = [metadata?.data];
        const ids = [...new Set(knowledgeItems.map(item => item.lockedBy?.toString()))].filter(Boolean);
        if (ids?.length > 0) {
          const people = yield call(getPeopleDataUsingIDs(ids, false));
          knowledgeItems = mergeKnowledgeWithLockInfo(knowledgeItems, people);
        }
        yield put(getConsumptionKBSuccess(knowledgeItems[0]));
      }
      else
        yield put(getPreviewConsumptionKBSuccess(metadata));
    }
    else
      yield put(getConsumptionKBFailure(metadata?.error?.message));
  }
  catch (error) {
    yield put(getConsumptionKBFailure(error));
  }
}

export function* getMetadata(action) {
  const { payload: { kbId } } = action;

  try {
    yield put(getMetadataKBPending());
    const axiosConfig = {
      method: 'get',
      endpoint: CONFIG.API_URL.GET_METADATA(kbId),
      headers: {
        'x-api-key': CONFIG.XA
      }
    };
    const metadata = yield call(doAxiosRequest, axiosConfig);

    if (metadata.data) {
      const KPIds = metadata.data.knowledgeObjects;
      yield call(getAssociatedKPs, { payload: { KPIds } });
      yield put(getMetadataKBSuccess(metadata));
    }
  }
  catch (error) {
    yield put(getMetadataKBFailure(error));
  }
}
export default function* MetadataSaga() {
  try {
    yield takeLatest(META_DATA.GET_CONSUMPTION_KB_DATA, getConsumptionKnowledgeBundle);
    yield takeLatest(META_DATA.API_GET_METADATA, getMetadata)

  } catch (error) {
    yield put(moduleFailure(error, 'MetadataKBSaga'));
  }
}