import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import CONFIG from "config";
import { Link } from "react-router-dom";

import ANALYTICS from "utils/analytics/analytics";
import { TRIGGERS } from "utils/analytics/analytics_constants";
import "./BreadCrumb.scss";

export class BreadCrumb extends PureComponent {
  hitAnalytics = () => {
    let newAdobeData = {
      collection: {
        trigger: TRIGGERS.HOME,
      },
    };
    ANALYTICS.kc.sendEventData(newAdobeData);
  };

  render() {
    const { pageTitle, homeIconFlag, productName } = this.props;
    return (
      <Fragment>
        <div className={"breadCrumb breadCrumb-" + productName}>
          {
            homeIconFlag &&
            <Link
              to={{ pathname: CONFIG.UI_URL.HOME }}
              onClick={this.hitAnalytics}
              className="link__home"
              title="Go to Home"
            >
              <i className="home icon white"></i>
            </Link>
          }
          <h1 className="breadCrumb__title title">{pageTitle}</h1>
        </div>
      </Fragment >
    );
  }
}

BreadCrumb.propTypes = {
  pageTitle: PropTypes.string,
  homeIconFlag: PropTypes.bool
};

BreadCrumb.defaultProps = {
  pageTitle: "",
  homeIconFlag: true
};