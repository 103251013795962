// React
import React from "react";
// PropTypes
import PropTypes from "prop-types";

import { getHighLighterText } from "utils/helpers/helpers"

// Css
import "./Highlighter.scss";

/**
 * @desc by default highlight all the words in a string except the search word
 * @param {String} searchWord ==> word to be searched
 * @param {String} textToHighlight ==> text in which the word is to be searched
 * @param {String} className ==> class to be applied
 * @param {Boolean} inverted ==> invert the selection in the textToHighlight
 * @returns html
 */
const Highlighter = ({
  searchWord,
  textToHighlight,
  className,
  inverted
}) => {
  const parts = getHighLighterText(searchWord, textToHighlight);
  const classToApply = className ?? "highlighter";

  return (
    <span> {parts.map((part, i) => {
      const isMatch = part.toLowerCase() === searchWord.toLowerCase();
      return (
        <span key={i} className={inverted ? isMatch ? classToApply : "" : !isMatch ? classToApply : ""}>
          {part}
        </span>
      )
    }
    )}
    </span>
  );
};


// PropTypes
Highlighter.propTypes = {
  searchWord: PropTypes.string.isRequired,
  textToHighlight: PropTypes.string.isRequired,
  className: PropTypes.string,
  inverted: PropTypes.bool,
};

export default Highlighter;