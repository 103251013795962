import React from 'react';
import PropTypes from 'prop-types';

//Components
import ListIconButton from "components/shared/ListIconButton/ListIconButton"

//Images
import gridActive from "assets/images/gridView/gridViewActive.svg";
import gridNonActive from "assets/images/gridView/gridViewNonActive.svg";
import listActive from "assets/images/listView/listViewActive.svg";
import listNonActive from "assets/images/listView/listViewNonActive.svg";

// SCSS
import "./GridListButton.scss";

const GridListButton = ({ setGridOrListView, kpViewSection }) => {

    return (
        <div className="kpgridlist__buttonSec">
            <ListIconButton handleOnClick={setGridOrListView}
                isActive={kpViewSection === 'grid' ? true : false}
                btnName="GRID"
                activeBtnName="grid"
                activeIcon={gridActive}
                nonActiveIcon={gridNonActive}
            />

            <ListIconButton handleOnClick={setGridOrListView}
                isActive={kpViewSection === 'list' ? true : false}
                btnName="LIST"
                activeBtnName="list"
                activeIcon={listActive}
                nonActiveIcon={listNonActive}
            />
        </div>
    );
};


GridListButton.propTypes = {
    setGridOrListView: PropTypes.func,
    kpViewSection: PropTypes.string
};

GridListButton.defaultProps = {
    setGridOrListView: () => { },
    kpViewSection: "list"
};

export default GridListButton;