/*
 * Use this component you need the prerequisite
 * react-bootstrap bootstrap npm modules
 * install these npm modules with npm install --save and modules name
 * after installation of dependencies
 * you can use this component like this <SupportingLinks />
 * also you need inject required props
 */
// React
import React, { useState, useEffect } from "react";
// PropTypes
import PropTypes from "prop-types";
// Semantic UI
import { Form } from "semantic-ui-react";
// Array move
import arrayMove from "array-move";

// Components
import Counter from "components/shared/Counter/Counter";
import Image from "components/shared/Image";
import DraggableList from "components/shared/Draggable/DraggableList";
import SupportingLink from "components/shared/SupportingLink/SupportingLink";

import warningIcon from "assets/images/warning/warning.svg";
import { removeSpaces } from "utils/helpers/helpers";
// Css
import "./AddSupportingLinks.scss";
// Config
import CONFIG from "./AddSupportingLinks.config";
import { ActionButton } from "components/shared/ActionButton";
import LABELS from "labels";
const { ADD } = LABELS.BUTTONS;
const { URL } = LABELS;

/**
 * @desc return the supporting links with multiple inputs
 * @returns html
 */
const AddSupportingLinks = ({
  defaultState = [],
  limit = 5,
  validate = true,
  getLinks,
  maxLength = 150,
  urlSanitization = [],
  urlWhiteList = [],
  urlValidation = null,
  hideFormOnReachingLimit = false,
  minimumItemsMessage,
  deleteCallback // to be sent mandatory when hideFormOnReachingLimit is true
}) => {
  // Local constant
  const [linksState, setLinksState] = useState(defaultState);
  const [inputState, setInputState] = useState({
    ...CONFIG.defaultValues,
  });
  const [validation, setValidation] = useState({
    ...CONFIG.defaultValidation,
  });

  useEffect((
  ) => {
    setLinksState(defaultState);
  }, [defaultState]);

  /**
   * @desc handle Error msg
   * @memberof AddSupportingLinks
   * @param {Boolean} error
   * @param {String} errorMsg
   * @return html/null
   */
  const ErrorMsg = (error, errorMsg) =>
    !error ? (
      <>
        <Image
          src={warningIcon}
          alt="icon warning"
          className="add-supportinglinks__error--icon"
        />
        <span className="add-supportinglinks__errormsg">{errorMsg}</span>
      </>
    ) : null;

  /**
   * @desc handle the button add
   * @memberof AddSupportingLinks
   * @return no return
   */
  const handleSubmit = (event) => {
    const newInputState = { name: inputState.name, value: inputState.value.trim() }
    let { name, value } = newInputState;
    const links = [...linksState];
    const isExisting = links.some(m => m.name.toLowerCase() === name.toLowerCase());
    // stop default redirect
    event.preventDefault();
    event.stopPropagation();
    // update the validation state
    setValidation({ ...CONFIG.defaultValidation });
    // check name , value , custom url
    if (name === "") {
      // update the state
      setValidation({ ...validation, name: false });
    }
    else if (isExisting) {
      setValidation({ ...validation, existingName: false });
    }
    else if (value === "") {
      // update the state
      setValidation({ ...validation, value: false });
    } else if (urlValidation && validate && !CONFIG.validate(value, urlValidation)) {
      // update the state
      setValidation({ ...validation, urlCustom: false });
    }
    else if (urlSanitization.length && validate && CONFIG.validateStringsPresence(value, urlSanitization)
      && !(urlWhiteList.length && CONFIG.validateStringsPresence(value, urlWhiteList))
    ) {
      // update the state
      setValidation({ ...validation, urlCustomString: false });
    }
    else {
      // update the links states
      setLinksState([...linksState, { ...newInputState }]);
      // send to parent
      getLinks([...linksState, { ...newInputState }], "addlink");
      // update the input state
      setInputState({ ...CONFIG.defaultValues });
    }
  };

  /**
   * @desc handle the input event
   * @memberof AddSupportingLinks
   * @param {Event} event - input current event
   * @return no return
   */
  const handleChange = (event) => {
    // update validation state
    setValidation({ ...CONFIG.defaultValidation });
    // update input state
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const handleBlurChange = (event) => {
    let trimValue = removeSpaces(event.target.value);
    // update validation state
    setValidation({ ...CONFIG.defaultValidation });
    // update input state
    setInputState({ ...inputState, [event.target.name]: trimValue });
  };

  /**
   * @desc sort the array
   * @memberof AddSupportingLinks
   * @param {Object} {oldIndex, newIndex}
   * @param {String} errorMsg
   * @return no return
   */
  const handleSort = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      // sort the array
      setLinksState(arrayMove(linksState, oldIndex, newIndex));

      // send to parent
      getLinks(arrayMove(linksState, oldIndex, newIndex), "reorder");
    }
  };

  /**
   * @desc handle the button delete
   * @memberof AddSupportingLinks
   * @param {Number} ind - current index
   * @return no return
   */
  const handleDelete = (e, ind) => {
    e.stopPropagation();
    // mutate input state and delete
    linksState.splice(ind, 1);
    // update the state
    setLinksState([...linksState]);
    // send to parent
    getLinks([...linksState], "delete");
  };
  // Return Supporting Links with list
  return (
    <div className="add-supportinglinks">

      {(defaultState.length < limit || limit > 1) && (
        <>
          {minimumItemsMessage && <div className="assets-message">{minimumItemsMessage}</div>}
          <Form onSubmit={handleSubmit} widths="equal" autoComplete="off">
            <Form.Group inline data-testid="supporting-links" className="add-supportinglinks__wrapper">
              <Form.Field>
                <label className="add-supportinglinks__label">URL Name</label>
                <Form.Input
                  autoComplete="off"
                  className={`add-supportinglinks__input ${!validation.name || !validation.existingName ? "customhighlight" : ""}`}
                  data-testid="input-url-name-1"
                  type={CONFIG.inputType}
                  placeholder={CONFIG.placeHolder.name}
                  value={inputState.name}
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlurChange}
                  maxLength={maxLength}
                  icon={
                    <Counter
                      inputLength={inputState.name.length}
                      maxLength={maxLength}
                    />
                  }
                />
                <div className="add-supportinglinks__error">
                  {ErrorMsg(validation.existingName, CONFIG.errorMsg.existingName)}
                  {ErrorMsg(validation.name, CONFIG.errorMsg.name)}</div>
              </Form.Field>
              <Form.Field>
                <label className="add-supportinglinks__label">{URL}</label>
                <Form.Input
                  autoComplete="off"
                  className={`add-supportinglinks__input ${(!validation.value || !validation.urlCustom || !validation.urlCustomString) ? "customhighlight" : ""}`}
                  data-testid="input-url-1"
                  type={CONFIG.inputType}
                  placeholder={CONFIG.placeHolder.url}
                  value={inputState.value}
                  name="value"
                  onChange={handleChange}
                />
                <div className="add-supportinglinks__error">
                  {ErrorMsg(validation.value, CONFIG.errorMsg.url[0])}
                  {ErrorMsg(validation.urlCustom, CONFIG.errorMsg.url[1])}
                  {ErrorMsg(validation.urlCustomString, CONFIG.errorMsg.url[1])}
                </div>
              </Form.Field>
              <Form.Button
                className="add-supportinglinks__button"
                disabled={linksState.length === limit}
              >
                <ActionButton type={ADD} />
              </Form.Button>

            </Form.Group>
          </Form>

          {!hideFormOnReachingLimit &&
            <div className="add-supportinglinks__multiTiles">
              <DraggableList
                data-testid="draggable-list"
                items={linksState}
                onSort={handleSort}
                countOfItems={linksState.length}
                componentToDrag={<SupportingLink onDelete={handleDelete} />}
                dragHandle
              />
            </div>}
        </>
      )}

      {hideFormOnReachingLimit && limit === 1 && defaultState.length === limit &&
        <div className="add-supportinglinks__singleTile">
          <div className="tile">
            <SupportingLink item={defaultState[0]} deleteIconInline={false} deleteIconOutside={true} deleteCallback={deleteCallback} />
          </div>
        </div>
      }
    </div>
  );
};
// Export AddSupportingLinks as default
export default AddSupportingLinks;

// PropTypes
AddSupportingLinks.propTypes = {
  defaultState: PropTypes.arrayOf(PropTypes.shape(CONFIG.defaultValues)),
  limit: PropTypes.number,
  validate: PropTypes.bool,
  getLinks: PropTypes.func.isRequired,
  maxLength: PropTypes.number,
  urlSanitization: PropTypes.array,
  urlValidation: PropTypes.any,
  minimumItemsMessage: PropTypes.string,
};
