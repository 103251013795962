import { put, takeLatest, call } from 'redux-saga/effects';
// Constant
import { KNOWLEDGE_ITEMS } from "redux/constants";
// Config
import GLOBAL_CONFIG from "config";
// Axios Util
import { doAxiosRequest } from "config-axios";
// Actions
import {
  getAssociatedKPsPending, getAssociatedKPsSuccess, getAssociatedKPsFailure,
  saveAssociatedKPPending, saveAssociatedKPSuccess, saveAssociatedKPFailure,
  sortAssociatedKPsPending, sortAssociatedKPsSuccess, sortAssociatedKPsFailure
} from "./associatedKPs.actions";

import { moduleFailure } from "redux/global.action.js";

export function* getAssociatedKPs(action) {
  try {
    const { payload: { KPIds, isConsumptionView = true, API = GLOBAL_CONFIG.API_URL.GET_KP_LIST } } = action;
    const IdsArray = KPIds.map(id => (id.indexOf('{') > -1 ? id : `{${id}}`));

    yield put(getAssociatedKPsPending());

    const axiosConfig = {
      method: 'POST',
      endpoint: API,
      params: {
        isConsumptionView,
        kpIds: IdsArray
      },
      headers: {
        'x-api-key': GLOBAL_CONFIG.XA
      }

    };
    const response = yield call(doAxiosRequest, axiosConfig);
    const items = response.map(item => item.data);
    yield put(getAssociatedKPsSuccess(items.filter(item => !!item)));

  } catch (error) {
    yield put(getAssociatedKPsFailure(error));
  }
};

export function* saveKPinList(action) {
  const { payload: { kpdata } } = action;
  try {
    yield put(saveAssociatedKPPending());
    yield put(saveAssociatedKPSuccess(kpdata));
  }
  catch (error) {
    yield put(saveAssociatedKPFailure(error));
  }
}
export function* updateKPList(action) {
  const { payload: { newkpdata } } = action;
  try {
    yield put(sortAssociatedKPsPending());
    yield put(sortAssociatedKPsSuccess(newkpdata));
  }
  catch (error) {
    yield put(sortAssociatedKPsFailure(error));
  }
}

export default function* AssociatedKPsSaga() {
  try {
    yield takeLatest(KNOWLEDGE_ITEMS.GET_ASSOCIATED_KNOWLEDGE_PAGES, getAssociatedKPs);
    yield takeLatest(KNOWLEDGE_ITEMS.SAVE_ASSOCIATED_KP, saveKPinList);
    yield takeLatest(KNOWLEDGE_ITEMS.REORDER_ASSOCIATED_KPS, updateKPList);
  } catch (error) {
    yield put(moduleFailure(error, 'AssociatedKPsSaga'));
  }
}