import React from 'react';

//SCSS
import "./LoadingSpinner.scss";

const LoadingSpinner = ({ showSpinner }) => {
    return (
        <>
            {showSpinner && (
                <div className="loaderSpinner">
                    <span className="loaderSpinner__loader"></span>
                </div>
            )}
        </>
    );
};

export default LoadingSpinner;