///////////// Importing Dependencies ///////////
import React from "react";
import PropTypes from "prop-types";

//////////// Importing Components //////////
import { Button } from "components/shared/Button";

//////////// Importing scss Files /////////
import "./WorkflowWarningSteps.scss";

/**
 * Component is helpful for rendering dynamic error and success steps for Knowledge Page
 */

class WorkflowWarningSteps extends React.PureComponent {
    componentDidMount() {
        const { stepsConfig: { cancelOldModal, cancelOldModalFunc } } = this.props;
        if (cancelOldModal && cancelOldModalFunc) {
            cancelOldModalFunc();
        }
    }

    getClass = (isSuccess) => {
        return isSuccess ? 'WorkflowWarningSteps__success-btn' : 'WorkflowWarningSteps__error-btn'
    }
    render() {
        const { stepsConfig: { stepsList, btnClickHandler } } = this.props;
        return (
            <div className="WorkflowWarningSteps">
                {stepsList && stepsList.map((item, index) => {
                    const {
                        buttonText: title,
                        buttonIcon: icon,
                        isSuccess,
                        link,
                        iconAltText: altText,
                        showDivider
                    } = item;
                    let dynamicClass = this.getClass(isSuccess)
                    return (
                        <React.Fragment key={index}>
                            <Button className={dynamicClass} onClick={() => btnClickHandler(link)}>
                                <img src={icon} alt={altText} />
                                <span>{title}</span>
                            </Button>
                            {showDivider && (
                                <span className="WorkflowWarningSteps__divider" />
                            )}
                        </React.Fragment>
                    )
                })}
            </div>
        );
    }
}

WorkflowWarningSteps.defaultProps = {
    stepsConfig: {
        cancelOldModal: false,
        cancelOldModalFunc: () => { },
        stepsList: [],
        btnClickHandler: () => { }
    }
};

WorkflowWarningSteps.propTypes = {
    stepsConfig: PropTypes.shape({
        cancelOldModal: PropTypes.bool,
        cancelOldModalFunc: PropTypes.func,
        stepsList: PropTypes.array
    })
};

export default WorkflowWarningSteps;
