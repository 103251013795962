import { getStaffEmailID, isUserAuthenticated } from "utils/auth/auth";
import { datadogRum } from '@datadog/browser-rum';
import { TOOLNAME } from "utils/analytics/analytics_constants";
import _ from 'lodash';

export default {
  initialize({ pageName, category, trackPageLoad, toolName = TOOLNAME?.CONTRIBUTE_KNOWLEDGE, pageId, isIdPresent = true }) {
    let staffEmailID = "unknown";
    if (isUserAuthenticated()) {
      staffEmailID = getStaffEmailID();
    }
    const data = {
      page: {
        pageInfo: {
          pageName: pageName, //eVar1
        },
        headerInfo: {
          staffEmailID: staffEmailID, //eVar23
          toolName: toolName, //eVar80
        },
        category: {
          primaryCategory: category, //eVar50
        },
      },
    };

    window.digitalData = isIdPresent ? { ...data, ...pageId } : data;
    window.analyticsObj = isIdPresent ? { ...data, ...pageId } : data;

    if (trackPageLoad) {
      this.track("virtual_page_load");
    }
  },

  track(
    eventName,
    options = {
      integrations: {
        Adobe: true,
        DataDog: true,
      },
    }
  ) {
    try {
      setTimeout(() => {
        if (options.integrations.Adobe && window._satellite) {
          window._satellite.track(eventName);
        }
      }, 200);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
    try {
      if (options.integrations.DataDog) {
        datadogRum.addAction(eventName, window.digitalData);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  },

  setGlobalProps(adobeAnalyticsData) {
    const adobe = {
      collection: {
        trigger: "",
        ID: "",
        screenName: "",
      },
      suggestedTopicID: "",
      KCpage: {
        id: ""
      }
    };
    if (Object.keys(adobeAnalyticsData).length) {
      Object.assign(adobe, adobeAnalyticsData);
    }


    if (adobe) {
      const initializedData = { ...window.analyticsObj };
      window.digitalData = _.merge(initializedData, adobe);

    }
  },
};
