import React from 'react';
import PropTypes from 'prop-types';
import { CaseConsumptionActions } from 'components/CaseConsumptionActions/CaseConsumptionActions';
import './ConsumptionDescriptionV1.scss';
import withErrorBoundary from 'containers/ErrorBoundary/ErrorBoundary';
import { v4 as uuidv4 } from 'uuid';

export const ConsumptionDescriptionV1Wrapper = ({
  title,
  descriptionObj,
  assets,
  projectId,
  billingInfo,
  showBilling,
  showEdit,
  showAssets,
  showAssetsv1,
  knowledgeAssets,
  caseVignettes,
  caseGuid,
  showBudget,
  caseContactListingCallback
}) => {
  return (
    <section className="consDescripv1 cpconsumption__gap">
      <section className="consDescripv1__headingSection layout__fix--inner">
        <header className="consDescripv1__mainHeading ">
          <h2>{title}</h2>
        </header>
        <CaseConsumptionActions
          assets={assets}
          projectId={projectId}
          billingInfo={billingInfo}
          showEdit={showEdit}
          showBilling={showBilling}
          showAssets={showAssets}
          showAssetsv1={showAssetsv1}
          knowledgeAssets={knowledgeAssets}
          caseVignettes={caseVignettes}
          caseGuid={caseGuid}
          showBudget={showBudget}
          caseContactListingCallback={caseContactListingCallback}
        />
      </section>
      <section className="consDescripv1__titledescription layout__fix--inner">
        <ul>
          {descriptionObj.map((rowData, i) => {
            const { heading1, description1, heading2, description2 } = rowData;
            return (
              <li key={uuidv4()}>
                {heading1 && description1 &&
                  <div className="consDescripv1__titledescription-leftsec">
                    {heading1 && (<h5>{heading1}</h5>)}
                    {description1 && (<p>{description1}</p>)}
                  </div>
                }
                {heading2 && description2 &&
                  <div className="consDescripv1__titledescription-rightsec">
                    {heading2 && (<h5>{heading2}</h5>)}
                    {description2 && (<p>{description2}</p>)}
                  </div>
                }
              </li>
            );
          })}
        </ul>
      </section>
    </section>
  );
}

ConsumptionDescriptionV1Wrapper.propTypes = {
  descriptionObj: PropTypes.array,
  showBilling: PropTypes.bool,
  showAssets: PropTypes.bool,
  showEdit: PropTypes.bool,
  assets: PropTypes.array,
  billingInfo: PropTypes.object,
  caseGuid: PropTypes.string,
  showBudget: PropTypes.bool,
};

ConsumptionDescriptionV1Wrapper.defaultProps = {
  descriptionObj: [{
    heading1: '',
    description1: '',
    heading2: '',
    description2: ''
  }],
  showBilling: false,
  showAssets: true,
  showEdit: false,
  assets: [],
  billingInfo: {},
  caseGuid: '',
  showBudget: false,
};

export const ConsumptionDescriptionv1 = withErrorBoundary(ConsumptionDescriptionV1Wrapper);
