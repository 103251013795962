// constant
import { KNOWLEDGEOBJECT_VERSIONING } from "redux/constants";

// local variable
export const name = "versioning";

// initial state
export const initialState = {
  errorStatus: "",
  data: "",
  status: "",
  cloneStatus: ""
};

export const selectors = {
  getErrorStatus: state => state[name].errorStatus,
  getApiStatus: state => state[name].status,
  getCloneStatus: state => state[name].cloneStatus
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case KNOWLEDGEOBJECT_VERSIONING.EDIT_PUBLISHED_KP_PENDING:
    case KNOWLEDGEOBJECT_VERSIONING.EDIT_PUBLISHED_KB_PENDING:
      return {
        ...state,
        data: action.payload
      };
    case KNOWLEDGEOBJECT_VERSIONING.EDIT_PUBLISHED_KP_FAILURE:
    case KNOWLEDGEOBJECT_VERSIONING.EDIT_PUBLISHED_KB_FAILURE:
      return {
        ...state,
        errorStatus: action.payload,
        data: ""
      };
    case KNOWLEDGEOBJECT_VERSIONING.UNPUBLISH_KNOWLEDGE_ASSET_FAILURE:
      return {
        ...state,
        errorStatus: action.payload.res,
        data: ""
      };
    case KNOWLEDGEOBJECT_VERSIONING.EDIT_PUBLISHED_KP_SUCCESS:
    case KNOWLEDGEOBJECT_VERSIONING.EDIT_PUBLISHED_KB_SUCCESS:
    case KNOWLEDGEOBJECT_VERSIONING.UNPUBLISH_KNOWLEDGE_ASSET_SUCCESS:
    case KNOWLEDGEOBJECT_VERSIONING.ARCHIVE_KNOWLEDGE_ASSET_SUCCESS:
      return {
        ...state,
        status: action.payload
      };
    case KNOWLEDGEOBJECT_VERSIONING.CLONE_KNOWLEDGE_ASSET_PENDING:
      return {
        ...state,
        cloneStatus: ""
      };
    case KNOWLEDGEOBJECT_VERSIONING.CLONE_KNOWLEDGE_ASSET_SUCCESS:
      return {
        ...state,
        cloneStatus: action.payload
      }
    case KNOWLEDGEOBJECT_VERSIONING.CLONE_KNOWLEDGE_ASSET_FAILURE:
      return {
        ...state,
        cloneStatus: action.payload
      }
    default:
      return state;
  }
}