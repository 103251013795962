// constant
import { CASE_METADATA } from "redux/constants";
// local variable
export const name = "caseConsumption";
// initial state
export const initialState = {
	metadata: {
		data: {},
		isLoading: false,
		error: "",
	},
};
// reducer selectors
export const selectors = {
	getCaseDetails: (state) => state[name].metadata.data,
	getCaseDetailsLoading: (state) => state[name].metadata.isLoading,
	getCaseDetailsError: (state) => state[name].metadata.error,
};

const setMetadata = (state, payload) => {
	const { metadata } = initialState;
	return {
		...state,
		metadata: {
			...metadata,
			isLoading: false,
			data: payload,
		}
	}
};

const setMetadataLoading = (state) => {
	const { metadata } = initialState;
	return {
		...state,
		metadata: {
			...metadata,
			isLoading: true,
		}
	}
};

const setMetadataError = (state, payload) => {
	const { metadata } = initialState;
	return {
		...state,
		metadata: {
			...metadata,
			isLoading: false,
			error: payload.error,
		}
	}
};

const resetMetadata = (state) => {
	const { metadata } = initialState;
	return {
		...state,
		metadata,
	}
};

export function reducer(state = initialState, action) {
	const { type, payload } = action;
	switch (type) {
		case CASE_METADATA.GET_CASE_CONSUMPTION_DATA_PENDING:
			return setMetadataLoading(state);
		case CASE_METADATA.GET_CASE_CONSUMPTION_DATA_SUCCESS:
			return setMetadata(state, payload);
		case CASE_METADATA.GET_CASE_CONSUMPTION_DATA_FAILURE:
			return setMetadataError(state, payload);
		case CASE_METADATA.RESET_CASE_CONSUMPTION_DATA:
			return resetMetadata(state);
		default:
			return state;
	}
}