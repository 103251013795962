import React from 'react';
import { PaRecommended } from 'components/PaRecommended/PaRecommended';
import { Button } from "semantic-ui-react";
import Image from "components/shared/Image";
import infoIcon from 'assets/images/info-white.svg';
import "./TopBar.scss";
import PropTypes from 'prop-types';
import withErrorBoundary from 'containers/ErrorBoundary/ErrorBoundary';

export class TopBarWrapper extends React.PureComponent {
  render() {
    const { heading,
      message,
      barColor,
      paButton,
      getPaRecommendedData,
      updatePaRecommended,
      paButtonDisabled,
      isPublishEntitlement,
      savePaClickCallback,
      buttonPaClickCallback,
      popupMessage,
      button,
    } = this.props;
    const { isShow, text, getClick } = button;
    return (
      <section className="sectionWrapper">
        <div className={`topbar ${barColor}`}>
          <div className="topbar__wrapper layout__fix">
            <div className="layout__fix--inner">
              <Image src={infoIcon} className={`topbar__wrapper-info  ${barColor}`} />
              <span className="topbar__wrapper-description">
                <span className="topbar__wrapper-heading">{heading}</span>
                {!!heading ? "   |   " : ""}
                <span>{message}</span>
                {paButton &&
                  <PaRecommended
                    getPaRecommendedData={getPaRecommendedData}
                    updatePaRecommended={updatePaRecommended}
                    popupMessage={popupMessage}
                    paButtonDisabled={paButtonDisabled}
                    savePaClickCallback={savePaClickCallback}
                    buttonClickCallback={buttonPaClickCallback}
                  />
                }
                {isPublishEntitlement && isShow && <Button onClick={getClick} className="black bannerBtn">{text}</Button>}
              </span>
            </div>
          </div>
        </div >
      </section>
    )
  }

}

TopBarWrapper.propTypes = {
  heading: PropTypes.string,
  message: PropTypes.string.isRequired,
  barColor: PropTypes.string.isRequired,
  isPublishEntitlement: PropTypes.bool,
  button: PropTypes.shape({
    isShow: PropTypes.bool,
    text: PropTypes.string,
    getClick: PropTypes.func,
  })
}

TopBarWrapper.defaultProps = {
  heading: "",
  barColor: "",
  paButton: false,
  button: {
    isShow: false,
    text: "",
    getClick: () => { }
  }
};

export const TopBar = withErrorBoundary(TopBarWrapper);