import React from "react";
import { ReactComponent as ChevronRight } from "assets/images/chevronright.svg";

const ToggleChildren = ({ name, isOpen, onClick }) => (
  <button
    onClick={onClick}
    className={`browse ${isOpen ? "browse-open" : "browse-close"}`}
    title={`${isOpen ? "Collapse" : "Expand"} ${name}`}
  >
    <ChevronRight />
  </button>
);

export default ToggleChildren;
