import React from "react";
import { Form } from "semantic-ui-react";
import { PeopleSelector } from "components/PeopleSelector";
import GLOBAL_CONSTANTS from "globalConstants";
import LABELS from 'labels';
import "./KPStep2.scss";

// Adobe Analytics file
import ANALYTICS from "utils/analytics/analytics";
import { DTMRULE, PAGES, PRIMARY_CATEGORY, TRIGGERS, SCREEN_NAMES } from "utils/analytics/analytics_constants";

const { STEP2 } = GLOBAL_CONSTANTS;
export class KPStep2 extends React.PureComponent {

    componentDidMount = () => {
        const { mainKPState: { id, caseNumbers }, getTagsSuggestions, validationCheck } = this.props;
        const { SUGGESTIONS_AUTHORS, STEP2 } = GLOBAL_CONSTANTS;

        if (caseNumbers?.length) {
            getTagsSuggestions(caseNumbers, SUGGESTIONS_AUTHORS);
        }

        ANALYTICS.page.initialize({ pageName: PAGES.KP_CREATION, category: PRIMARY_CATEGORY.KP, trackPageLoad: true, pageId: { KCpage: { id } } });

        validationCheck(STEP2)
    }

    /**
     * This function is helpful in showing suggested list
     * If case number is not added in page one then we will return false 
     * in this case.
     * 
     * @returns bool
     */
    isShowSuggestions = () => {
        const {
            tagsSuggestionsList: { authors = [] } = {},
            mainKPState: { caseNumbers = [] } = {}
        } = this.props;
        return (authors?.length && caseNumbers?.length) ? true : false;
    }

    /**
     * Below funciton used to call handle change or for saving data into DB specifically for People Selector
     * for drag and drop feature if sequence is change then we just have to call the handleChange for authors
     * and for adding and removing any siggestion list we have to call the else part 
     * @param {*} data 
     * @param {*} selectedSuggestedAuthors 
     * @param {*} changeSequenceOfAuthors 
     */
    onAuthorDataChange = (data, selectedSuggestedAuthors, changeSequenceOfAuthors) => {
        const { handleChange, mainKPState } = this.props;
        if (changeSequenceOfAuthors) {
            handleChange(data, "authors", STEP2, false, true);
        } else {
            const name = "selectedSuggestedAuthors";
            const isSuggestedAuthorsChange = selectedSuggestedAuthors !== mainKPState[name];
            handleChange(data, "authors", STEP2, () => {
                if (isSuggestedAuthorsChange) {
                    handleChange(selectedSuggestedAuthors, name, STEP2, false, true);
                }
            }, !isSuggestedAuthorsChange);
        }
    }

    render() {
        const {
            OPTIONAL_TEXT,
            METADATA: {
                PEOPLE_SELECTOR_PLACEHOLDER,
            },
            KP: {
                STEP2: {
                    MDP_NAME_HEADING,
                    AUTHORS_HEADING,
                    ORIGINAL_REQUESTOR,
                    ADDITIONAL_CONTACTS,
                    AUTHORS_SUGGESTED_TITLE
                }
            },
            TOOLTIPS: {
                KP_MDP_1,
                KP_MDP_2,
                KP_AUTHORS_1,
                KP_AUTHORS_2,
                KP_ADDITIONAL
            }
        } = LABELS;
        const {
            handleChange,
            mainKPState: {
                mdpName,
                authors,
                isRelatedToCase,
                originalRequestor,
                selectedSuggestedAuthors,
                additionalContacts,
                id
            },
            errorState,
            tagsSuggestionsList
        } = this.props;

        const { authors: authorsSuggestions } = tagsSuggestionsList;
        const queryFilter = `{subFilters: [{logicOperator: OR subFilters: [{ valueFilter: { field:"title=Managing Director and Partner*" }}{ valueFilter: { field:"title=Platinion Managing Director*" }}]}]}`;

        return (
            <div className="kpstep2__wrap">
                <Form.Group>
                    <Form.Field>
                        <PeopleSelector
                            label={MDP_NAME_HEADING}
                            placeholder={PEOPLE_SELECTOR_PLACEHOLDER}
                            name="mdpName"
                            disabled={isRelatedToCase ? false : true}
                            className={errorState && errorState['mdpName'] ? 'peopleselector__error' : ''}
                            preFillData={[mdpName]}
                            tooltipHtml={
                                <>
                                    <p>{KP_MDP_1}</p>
                                    <p>{KP_MDP_2}</p>
                                </>
                            }
                            isStateReset={true}
                            onChange={(data) => {
                                handleChange(data, "mdpName", STEP2);
                            }}
                            filterQuery={queryFilter}
                            getSuggestionValueCallback={({ valueText, displayName }) => {
                                ANALYTICS.kc.sendEventData({
                                    search: {
                                        term: valueText,
                                        typeHeadOptionText: displayName,
                                    },
                                    page: {
                                        pageInfo: {
                                            pageName: PAGES.KP_PAGES,
                                            // pageURL: ""
                                        },
                                        category: {
                                            primaryCategory: PRIMARY_CATEGORY.KP
                                        }
                                    },
                                    collection: {
                                        trigger: TRIGGERS.TYPEAHEAD_KP_MDPNAME,
                                        ID: id,
                                        screenName: SCREEN_NAMES.KP_STEP_2,
                                    },
                                }, DTMRULE.KP);
                            }}
                        />

                    </Form.Field>
                </Form.Group>
                <Form.Group>
                    <Form.Field>
                        <PeopleSelector
                            label={AUTHORS_HEADING}
                            placeholder={PEOPLE_SELECTOR_PLACEHOLDER}
                            className={errorState && errorState['authors'] ? 'peopleselector__error' : ''}
                            name="authors"
                            preFillData={[authors]}
                            preFillSelectedSuggestion={selectedSuggestedAuthors}
                            tooltipHtml={
                                <>
                                    <p>{KP_AUTHORS_1}</p>
                                    <p>{KP_AUTHORS_2}</p>
                                </>
                            }
                            onChange={(data, selectedSuggestedAuthors, changeSequenceOfAuthors) => {
                                this.onAuthorDataChange(data, selectedSuggestedAuthors, changeSequenceOfAuthors);
                            }}
                            getSuggestionValueCallback={({ valueText, displayName }) => {
                                ANALYTICS.kc.sendEventData({
                                    search: {
                                        term: valueText,
                                        typeHeadOptionText: displayName,
                                    },
                                    page: {
                                        pageInfo: {
                                            pageName: PAGES.KP_PAGES,
                                            // pageURL: ""
                                        },
                                        category: {
                                            primaryCategory: PRIMARY_CATEGORY.KP
                                        }
                                    },
                                    collection: {
                                        trigger: TRIGGERS.TYPEAHEAD_KP_AUTHOR,
                                        ID: id,
                                        screenName: SCREEN_NAMES.KP_STEP_2,
                                    },
                                }, DTMRULE.KP);
                            }}
                            suggestions={{
                                heading: AUTHORS_SUGGESTED_TITLE,
                                hrids: authorsSuggestions,
                                useDragDrop: true
                            }}
                            showSuggestionList={this.isShowSuggestions()}
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Group className="displaynone">
                    <Form.Field>
                        <PeopleSelector
                            label={ORIGINAL_REQUESTOR}
                            placeholder={PEOPLE_SELECTOR_PLACEHOLDER}
                            name="originalRequestor"
                            maxLimit={1}
                            className={errorState && errorState['originalRequestor'] ? 'peopleselector__error' : ''}
                            preFillData={[originalRequestor]}
                            onChange={(data) => {
                                handleChange(data, "originalRequestor", STEP2);
                            }}
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Group>
                    <Form.Field>
                        <PeopleSelector
                            label={ADDITIONAL_CONTACTS}
                            optionalTxt={OPTIONAL_TEXT}
                            tooltipHtml={
                                <>
                                    <p>{KP_ADDITIONAL}</p>
                                </>
                            }
                            placeholder={PEOPLE_SELECTOR_PLACEHOLDER}
                            name="contacts"
                            preFillData={[additionalContacts]}
                            onChange={(data) => {
                                handleChange(data, "additionalContacts", STEP2);
                            }}
                            getSuggestionValueCallback={({ valueText, displayName }) => {
                                ANALYTICS.kc.sendEventData({
                                    search: {
                                        term: valueText,
                                        typeHeadOptionText: displayName,
                                    },
                                    page: {
                                        pageInfo: {
                                            pageName: PAGES.KP_PAGES,
                                        },
                                        category: {
                                            primaryCategory: PRIMARY_CATEGORY.KP
                                        }
                                    },
                                    collection: {
                                        trigger: TRIGGERS.ADDITIONAL_CONTACT,
                                        screenName: SCREEN_NAMES.EDIT_KP_STEP_1,
                                    },
                                    KCpage: {
                                        id: id
                                    },
                                }, DTMRULE.KP);
                            }}
                            suggestions={{
                                useDragDrop: true
                            }}
                        />
                    </Form.Field>
                </Form.Group>
            </div>
        );
    }
}

KPStep2.defaultProps = {
    validationCheck: () => { }
}
