import {
  API_GET_ALL_MATERIALS_PENDING,
  API_GET_ALL_MATERIALS_FAILURE,
  API_GET_ALL_MATERIALS_SUCCESS
} from 'redux/constants';

export const name = 'materials';

export const initialState = {
  loading: false,
  error: false,
  errorMessage: '',
  data: []
};

export const selectors = {
  getLoading: state => state[name].loading,
  getError: state => state[name].error,
  getErrorMessage: state => state[name].errorMessage,
  getMaterialData: state => state[name].data
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case API_GET_ALL_MATERIALS_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: '',
        data: action.payload
      };
    case API_GET_ALL_MATERIALS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload
      };
    case API_GET_ALL_MATERIALS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    default:
      return state;
  }
}
