import React, { Component } from "react";
import Image from "components/shared/Image";
import Modal from "components/shared/Modal";
import history from "redux/history";
import PropTypes from "prop-types";
import { Button } from "components/shared/Button";
import artFileIcon from "assets/images/art-work-file.svg";
import artUrlIcon from "assets/images/art-work-url.svg";
import crossIcon from "assets/images/cross/crossBlack.svg";
import GLOBAL_CONSTANTS from "globalConstants";
import CONFIG from "config";
// scss
import "./CreationOptionModal.scss";

export class CreationOptionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUrl: false,
      isFile: true,
      selectedOptionText: CONFIG.CREATION_MODAL_OPTION.FILE,
    };
  }

  handleSelectOption = (option) => {
    if (option === CONFIG.CREATION_MODAL_OPTION.FILE) {
      this.setState({
        isFile: true,
        isUrl: false,
        selectedOptionText: option,
      });
    } else {
      this.setState({
        isFile: false,
        isUrl: true,
        selectedOptionText: option,
      });
    }
  };

  handleCreate = () => {
    const { selectedOptionText } = this.state;
    const { STEP1 } = GLOBAL_CONSTANTS;
    const configUrl = CONFIG.UI_URL.METADATA_KP(STEP1);
    history.push({
      pathname: configUrl,
      search: `?option=${selectedOptionText}`,
    });
  };

  render() {
    const {
      isOpen,
      onRequestClose,
      createKpWithFile,
      uploadTxt,
      createKpWithUrl,
      addUrlTxt,
      btnCreate,
      btncancel,
    } = this.props;
    const { isFile, isUrl } = this.state;

    return (
      <Modal
        isOpen={isOpen}
        className="creationOption__modal"
        onRequestClose={onRequestClose}
      >
        <div className="creationOption__section">
          <span onClick={onRequestClose} className="creationOption__modalcross">
            <Image src={crossIcon} alt="cross" />
          </span>

          <div
            className={`creationOption__box ${isFile ? "selected" : ""}`}
            onClick={() => this.handleSelectOption(CONFIG.CREATION_MODAL_OPTION.FILE)}
          >
            <Image src={artFileIcon} alt="file" className="" />
            <div>
              <p className="txt txt-bold">{createKpWithFile}</p>
              <span className="txt">{uploadTxt}</span>
            </div>
          </div>

          <div
            className={`creationOption__box ${isUrl ? "selected" : ""}`}
            onClick={() => this.handleSelectOption(CONFIG.CREATION_MODAL_OPTION.URL)}
          >
            <Image src={artUrlIcon} alt="Url" className="" />
            <div>
              <p className="txt txt-bold">{createKpWithUrl}</p>
              <span className="txt">{addUrlTxt}</span>
            </div>
          </div>
        </div>
        <div className="creationOption__btn">
          <Button className="cancel-btn" onClick={onRequestClose}>
            {btncancel}
          </Button>
          <Button className="create-btn" onClick={this.handleCreate}>
            {btnCreate}
          </Button>
        </div>
      </Modal>
    );
  }
}

CreationOptionModal.defaultProps = {
  isOpen: false,
  onRequestClose: () => { },
  btnCreate: "Create",
  btncancel: "Cancel",
}

CreationOptionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  createKpWithFile: PropTypes.string,
  uploadTxt: PropTypes.string,
  createKpWithUrl: PropTypes.string,
  addUrlTxt: PropTypes.string,
  btnCreate: PropTypes.string,
  btncancel: PropTypes.string,
};
