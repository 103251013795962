// React
import React from "react";
// PropTypes
import PropTypes from "prop-types";
// Shared Components
import { Button } from "components/shared/Button";
import Image from "components/shared/Image";
// Icon
import DeleteIcon from "assets/images/speaker.svg";
// Css
import "./MailButton.scss";

/**
 * @param {*} { text, email, buttonText, subject }
 * @returns
 */
const MailButton = ({ emailState }) => {
  // mailTo constant
  const mailTo = `mailto:${emailState?.email}?Subject=${emailState?.subject}`;
  return (
    <div data-testid="email-button" className="mail-button">
      <span className="mail-button__text">{emailState?.text}</span>
      <Button as="a" href={mailTo} className="mail-button__btn">
        {emailState.buttonText}
        <Image src={DeleteIcon} className="mail-button__img" />
      </Button>
    </div>
  );
};
// export default MailButton
export default MailButton;
// PropTypes
MailButton.propTypes = {
  emailState: PropTypes.shape({
    text: PropTypes.string,
    email: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    buttonIcon: PropTypes.string,
    subject: PropTypes.string
  }).isRequired
};
