import { removeStopwords, eng } from 'stopword';
import LABELS from 'labels';
import { filterInternalOfficeDataByGlobal } from "utils/helpers/helpers";

const { KP: {
    STEP3: { NUDGES: { INTERNAL_OFFICE_LOCATION } },
    STEP1: { NUDGES: {
        FIVE_WORDS_LIMIT,
        IMPROVE_FINDABILITY,
        SEARCHABLE_WORDS,
        IMPROVE_SEARCHABILITY,
        KEYWORDS_LIMIT_FOR_BETTER_CONTEXT,
        UPLOAD_FILE_SUBJECT_SUGGESTION_MSG
    } }
} } = LABELS;

const nudgeTitleSuggestions = (obj, isInternal) => {
    let temp = [];
    const { localStateTitle: value, subjectSearchStatus, subjectSearchLoadingStatus } = obj;
    if (value.length) {
        const words = value.split(' ').filter((x) => x !== "");
        const numberOfWords = words.length;
        const nonStopWords = removeStopwords(words, eng);
        const nonStopWordsString = nonStopWords.length ? (nonStopWords.join(', ')) : '';
        let titleSuggestions = [
            { isPassed: false, message: FIVE_WORDS_LIMIT, isHide: isInternal },
            { isPassed: false, message: IMPROVE_FINDABILITY },
            { isPassed: false, message: SEARCHABLE_WORDS + nonStopWordsString },
            { isPassed: false, message: IMPROVE_SEARCHABILITY, showLoading: subjectSearchLoadingStatus }
        ];

        if (numberOfWords > 4) {
            titleSuggestions[0].isPassed = true;
        }
        if (nonStopWords.length > 1) {
            titleSuggestions[1].isPassed = true;
        }
        if (nonStopWords.length) {
            titleSuggestions[2].isPassed = true;
        }
        if (subjectSearchStatus && !subjectSearchLoadingStatus) {
            titleSuggestions[3].isPassed = true;
        }

        temp = titleSuggestions;
    }
    return temp;
}

const nudgeSubjectTaxonomySuggestions = (obj) => {
    let temp = [];
    const { value, hasFile, subjectSuggestions = [], isInternal } = obj;
    const data = value[0];

    const getSuggestionsForSubjects = (titleSuggestions) => {
        if (subjectSuggestions.length > 0) {
            titleSuggestions[0].isPassed = true;
        }
        return titleSuggestions
    }

    const getSuggestionsForKeyWords = (titleSuggestions, index = 0) => {
        if (data.length <= 8 && data.length >= 4) {
            titleSuggestions[index].isPassed = true;
        }
        return titleSuggestions
    }

    const getUpdatedSuggestions = () => {
        if (data.length) {
            let titleSuggestions = [
                { isPassed: false, message: KEYWORDS_LIMIT_FOR_BETTER_CONTEXT },
            ];
            titleSuggestions = getSuggestionsForKeyWords(titleSuggestions, 0)
            temp = titleSuggestions;
        }
    }

    if (!isInternal && hasFile) {
        if (data.length) {
            let titleSuggestions = [
                { isPassed: false, message: UPLOAD_FILE_SUBJECT_SUGGESTION_MSG },
                { isPassed: false, message: KEYWORDS_LIMIT_FOR_BETTER_CONTEXT },
            ];
            titleSuggestions = getSuggestionsForSubjects(titleSuggestions)
            titleSuggestions = getSuggestionsForKeyWords(titleSuggestions, 1)
            temp = titleSuggestions;
        } else {
            let titleSuggestions = [
                { isPassed: false, message: UPLOAD_FILE_SUBJECT_SUGGESTION_MSG },
            ];
            titleSuggestions = getSuggestionsForSubjects(titleSuggestions)
            temp = titleSuggestions;
        }
    } else {
        getUpdatedSuggestions()
    }

    return temp;
}

const nudgeInternalOfficeSuggestions = (val) => {
    let temp = [];

    let value = filterInternalOfficeDataByGlobal(val, "id");
    if (value && Array.isArray(value) && value.length > 0) {
        let titleSuggestions = [
            { isPassed: false, message: INTERNAL_OFFICE_LOCATION }
        ];

        if (value.length > 1) {
            titleSuggestions[0].isPassed = true
        }

        temp = titleSuggestions;
    }

    return temp;
}

export const isAllNudgePassed = (suggestions) => {
    let temp = true;
    if (suggestions.length) {
        suggestions.forEach(({ isPassed, isHide }) => {
            if (!isPassed && !isHide) {
                temp = false;
            }
        });
    } else {
        return false
    }

    return temp;
}

export const nudgeConditions = ({ nodeName, value, isInternal }) => {
    switch (nodeName) {
        case "title":
            return nudgeTitleSuggestions(value, isInternal);
        case "subjectTaxonomy":
            return nudgeSubjectTaxonomySuggestions(value);
        case "internalOfficeSelections":
            return nudgeInternalOfficeSuggestions(value);
        default:
            return [];
    }
}

/**
 * handlingNudgeForTitleField function used to update the value when subject search
 * loading is true or false
 * @param {*} configObj 
 * @returns 
 */
export const handlingNudgeForTitleField = (configObj = {}) => {
    try {
        const { value, prevProps, props } = configObj;
        if (value) {
            const { nudgeConfig: {
                subjectSearchStatus: prevSubjectSearchStatus,
                subjectSearchLoadingStatus: prevSubjectSearchLoadingStatus
            } } = prevProps;
            const { nudgeConfig: {
                subjectSearchStatus,
                subjectSearchLoadingStatus
            } } = props;
            if ((prevSubjectSearchStatus !== subjectSearchStatus) || (subjectSearchLoadingStatus !== prevSubjectSearchLoadingStatus)) {
                return {
                    value,
                    showNudge: true,
                    subjectSearchLoadingStatus
                }
            }
        }
        return null
    } catch (error) {
        console.log('error ', error)
    }
}

export const handlingNudgeForSubjectField = (configObj = {}) => {
    try {
        const { value, prevProps, props } = configObj;
        if (value) {
            const { nudgeConfig: {
                subjectSuggestions: prevSubjectSuggestions,
                isInternal: prevIsInternal
            } } = prevProps;
            const { nudgeConfig: {
                subjectSuggestions,
                isInternal
            } } = props;
            if ((prevSubjectSuggestions !== subjectSuggestions) || (prevIsInternal !== isInternal)) {
                return {
                    value,
                    showNudge: true
                }
            }
        }
        return null
    } catch (error) {
        console.log('error ', error)
    }
}

/**
   * Update Specific field for 
   * @param {*} nodeName 
   * @param {*} value 
   * @param {*} showNudge 
   */
export const updateNudgeValueForSpecificField = (nodeName, value, nudgeConfig = null) => {
    try {
        if (nodeName === 'title') {
            const { subjectSearchStatus, subjectSearchLoadingStatus } = nudgeConfig;
            let obj = {
                localStateTitle: value,
                subjectSearchStatus,
                subjectSearchLoadingStatus
            }
            return obj
        } else if (nodeName === 'subjectTaxonomy') {
            const { hasFile, subjectSuggestions, isInternal } = nudgeConfig;
            let obj = {
                value,
                hasFile,
                subjectSuggestions,
                isInternal
            }
            return obj
        } else {
            return value
        }
    } catch (error) {
        console.log('error ', error)
    }

}

/**
   * if any config is passed and they are dependent on componentDidUpdate then use this below fucntion
  * Handling field specific functionality for specific fields majorly for componentDidUpdate
  * @param {*} nodeName 
  * @param {*} value 
  * @param {*} showNudge 
  */
export const getFieldSpecificConfig = (prevProps, props) => {
    try {
        const { nodeName, nudgeConfig, value } = props;
        if (nodeName === 'title') {
            const { subjectSearchStatus, subjectSearchLoadingStatus } = nudgeConfig;
            let configObj = {
                value: {
                    localStateTitle: value,
                    subjectSearchStatus,
                    subjectSearchLoadingStatus
                },
                prevProps,
                props,
                nodeName
            }
            return handlingNudgeForTitleField(configObj);
        } else if (nodeName === 'subjectTaxonomy') {
            const { hasFile, subjectSuggestions, isInternal } = nudgeConfig;
            let configObj = {
                value: {
                    value,
                    hasFile,
                    subjectSuggestions,
                    isInternal
                },
                prevProps,
                props,
                nodeName
            }
            return handlingNudgeForSubjectField(configObj);
        } else {
            return null
        }
    } catch (error) {
        console.log('error ', error)
    }
}