// Constant
import { KNOWLEDGE_HISTORY } from "redux/constants";

export const getAdminHistorySuccess = (payload) => ({
    type: KNOWLEDGE_HISTORY.GET_ADMIN_HISTORY_SUCCESS,
    payload
});
export const getAdminHistory = (KPId) => ({
    type: KNOWLEDGE_HISTORY.GET_ADMIN_HISTORY,
    payload:{KPId}
});

export const getAdminHistoryPending = () => ({
    type: KNOWLEDGE_HISTORY.GET_ADMIN_HISTORY_PENDING,
});

export const getAdminHistoryFailure = (error) => ({
    type: KNOWLEDGE_HISTORY.GET_ADMIN_HISTORY_FAILURE,
    payload: { error: error?.toString() }
});
