import React from "react";
import PropTypes from "prop-types";
import { KnowledgeEntity } from "components/KnowledgeEntity";
import { openURL } from "utils/helpers/helpers";
import GLOBAL_CONSTANTS from "globalConstants";
import CONFIG from "config";
import { CloneModal } from "components/CloneModal";

export const KnowledgeListing = (props) => {
  const {
    knowledgeEntities,
    entityType,
    editAction,
    deleteAction,
    previewAction,
    unpublishAction,
    archiveAction,
    isPublishContentEntitlement,
    isCreateEditEntitlement,
    isEditPublishedEntitlement,
    previewAction: {
      callBack: previewCallBack
    },
    isSiteAdmin,
    isArchiveVisible,
    closeCloneModalCallback,
    cloneSubmitCallback
  } = props;
  const {
    KNOWLEDGE_STATUS: {
      DRAFT,
      REVIEW,
      PUBLISHED,
      ARCHIVED,
      UNPUBLISHED
    },
    KNOWLEDGE_TYPE_KEYS: {
      BUNDLES,
      COLLECTIONS,
      PAGES
    },
    UI_URL: {
      METADATA_KB,
      METADATA_KP,
      KP_PREVIEW_DETAILS,
      KB_PREVIEW
    }
  } = CONFIG;

  const isBundle = entityType === BUNDLES;
  const isCollection = entityType === COLLECTIONS;
  const [cloneModalObject, setCloneModalObject] = React.useState({
    showCloneModal: false,
    status: '',
    id: ''
  });

  const redirectUrl = (event, entity) => {
    // stop event bubbling for disabled button
    if (event.target.className.includes('stopBubble')) return;

    const isDraft = entity.status === DRAFT;
    const previewVisible = !isDraft && entity.status;
    if (entity.status === REVIEW) {
      const { id } = entity;
      const url = isBundle || isCollection ? KB_PREVIEW(id) : KP_PREVIEW_DETAILS(id);
      openURL(url, true);
    } else if (isDraft && !isCreateEditEntitlement) {
      return;
    } else if (previewVisible) {
      previewCallBack(event, entity)
    } else if (isDraft) {
      const { id } = entity;
      const { STEP1 } = GLOBAL_CONSTANTS;
      const url = isBundle || isCollection ? METADATA_KB(STEP1, id) : METADATA_KP(STEP1, id);
      openURL(url, true);
    }
  }

  const isEllipsesVisible = (entity) => {
    const {
      status
    } = entity;

    if (status === DRAFT)
      return isCreateEditEntitlement;
    else if (status === PUBLISHED)
      return isCollection ?
        (isSiteAdmin && isPublishContentEntitlement) : (isCreateEditEntitlement || isPublishContentEntitlement);
    else if (status === UNPUBLISHED || status === ARCHIVED)
      return isCollection ? (isSiteAdmin && isCreateEditEntitlement) : isCreateEditEntitlement;
  }
  const handleClone = (event, showModal, knowledeId = "", knowledegeStatus = "") => {
    if (event)
      event.stopPropagation();
    setCloneModalObject({
      id: knowledeId,
      status: knowledegeStatus,
      showCloneModal: showModal
    });
  }



  let cursorVisible = true;
  const { showCloneModal, status, id } = cloneModalObject;
  return (
    <div className="knowledge-listing">
      <ul>
        {knowledgeEntities.map((entity, index) => {
          let isDraft = entity.status === DRAFT;
          let isShowClone = entity.status === PUBLISHED || entity.status === DRAFT || entity.status === UNPUBLISHED || entity.status === ARCHIVED;
          let entityTypeKP = entityType === PAGES;
          const isCloneVisible = isShowClone && entityTypeKP && isCreateEditEntitlement;
          cursorVisible = !(isDraft && !isCreateEditEntitlement);
          return (<li
            className={cursorVisible ? 'cursor-pointer' : ''}
            key={index} onClick={(event) => redirectUrl(event, entity)} >
            <KnowledgeEntity
              key={index}
              entity={entity}
              isDraft={isDraft}
              entityType={entityType}
              editAction={editAction}
              unpublishAction={unpublishAction}
              isCreateEditEntitlement={isCreateEditEntitlement}
              isEditPublishedEntitlement={isEditPublishedEntitlement}
              deleteAction={deleteAction}
              ellipseIconVisible={isEllipsesVisible(entity)}
              previewAction={previewAction}
              archiveAction={archiveAction}
              isSiteAdmin={isSiteAdmin}
              isPublishContentEntitlement={isPublishContentEntitlement}
              isArchiveVisible={isArchiveVisible}
              isContributionPage
              onCloneButtonClick={handleClone}
              isCloneVisible={isCloneVisible}
            />
          </li>)
        }
        )}
      </ul>
      <CloneModal
        id={id}
        status={status}
        showCloneModal={showCloneModal}
        closeModalCallback={(e) => {
          handleClone(e, false);
          closeCloneModalCallback(id);
        }}
        cloneSubmitCallback={() => cloneSubmitCallback(id)}
      />
    </div>
  );
};

KnowledgeListing.propTypes = {
  knowledgeEntities: PropTypes.array.isRequired,
  entityType: PropTypes.string.isRequired,
  editAction: PropTypes.object.isRequired,
  deleteAction: PropTypes.object.isRequired,
  previewAction: PropTypes.object.isRequired,
  archiveAction: PropTypes.object.isRequired,
  unpublishAction: PropTypes.object.isRequired,
  isSiteAdmin: PropTypes.bool,
};

KnowledgeListing.defaultProps = {
  knowledgeEntities: [],
  entityType: "",
  isSiteAdmin: false,
};