import React from 'react';
import LABELS from 'labels';
import './FcgTemplate.scss';
import FcgGridTemplate from 'components/shared/FcgGridTemplate/FcgGridTemplate';
import ProposalWhiteTheme from "../../assets/images/ProposalWhiteTheme.svg";

const {
    FACE_CARD_GENERATOR: { TEMPLATE_MESSAGE, TEMPLATE_MESSAGE_2 },
    PROPOSAL_BIO: { PROPOSAL_TEMPLATE_MESAGE_1, PROPOSAL_TEMPLATE_MESAGE_2, WHITE_THEME }
} = LABELS;

class FcgTemplate extends React.PureComponent {
    constructor(props) {
        super();
    }
    render() {
        const { templates, onTemplateClick } = this.props;

        return (
            <div className='faceCardTemplate__mainContainer'>
                <div className="faceCardTemplate__templateContainer">
                    {templates.map((row, rowIndex) => (
                        row.length > 0 && (
                            <div key={`row-${rowIndex}`} className="faceCardTemplate__rowContainer">
                                <div className="faceCardTemplate__templateMessage">
                                    {rowIndex === 0 ? TEMPLATE_MESSAGE : PROPOSAL_TEMPLATE_MESAGE_1}
                                </div>
                                <div className="faceCardTemplate__gridContainer">
                                    <p>
                                        {rowIndex === 0 ? TEMPLATE_MESSAGE_2 : PROPOSAL_TEMPLATE_MESAGE_2}
                                    </p>
                                    <div className="faceCardTemplate__templatesGrid">
                                        {Array.isArray(row) && row.map((template) => {
                                            const { id, rows, columns, label, shape } = template;

                                            return (
                                                <div key={id} className={`faceCardTemplate__templateSection  ${rows === 2 ? 'faceCardTemplate__templateSection--two-rows' : ''}`}

                                                    onClick={() => onTemplateClick(template)}
                                                >
                                                    {shape ? (
                                                        <div className="faceCardTemplate__gridBox">
                                                            <FcgGridTemplate
                                                                rows={rows}
                                                                columns={columns}
                                                                shape={shape}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <div className="faceCardTemplate__imageContainer">
                                                            <img
                                                                src={ProposalWhiteTheme}
                                                                alt={label}
                                                                className="faceCardTemplate__image"
                                                            />
                                                        </div>
                                                    )}
                                                    <h5 className='faceCardTemplate__templateLabel'>{label}</h5>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        )
                    ))}
                </div>
            </div>
        );
    }
}

export default FcgTemplate;