import React from "react";
import crossIcon from "assets/images/big-cross-icon.svg";
import ImagePicker from "components/shared/ImagePicker";
import Image from "components/shared/Image";
import "./ImagesGrid.scss";
import LABELS from "labels";

const {
    ALT_TEXTS: { CANCEL_MODAL_ALT }
} = LABELS;

export const ImagesGrid = (
    {
        heading, subheading,
        images, preFilledImage,
        onImageSelection,
        isImageSelected,
        onSelectedImageSubmit,
        footerBtnText,
        closeSelectImageModal
    }
) => {
    return (
        <div className="imagesGrid">
            <div className="imagesGrid__header">
                <div className="imagesGrid__header--title">{heading}</div>
                <div className="imagesGrid__header--subtitle">{subheading}</div>
                <Image className="imagesGrid__header--close" onClick={() => closeSelectImageModal()} src={crossIcon} alt={CANCEL_MODAL_ALT} />
            </div>
            <div className="imagesGrid__images">
                <ImagePicker
                    defaultState={preFilledImage}
                    images={images}
                    onImageSelect={(e) => onImageSelection(e)}
                />
            </div>
            <div className="imagesGrid__footer">
                <button type="button" className="btn-selectimage" id="btn-selectimage" title="" disabled={!isImageSelected} onClick={() => onSelectedImageSubmit()} class="imagesGrid__footer--button ui small button">{footerBtnText}</button>
            </div>
        </div>

    );
}