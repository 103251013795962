import {
    DTMRULE,
    TOOLNAME,
    MATERIAL_STATUS
} from "./analytics_constants";
import { getmyCurrentLocation, getDefaultViewSection, capitalizeFirstLetter } from "utils/helpers/helpers";
import ANALYTICS from "utils/analytics/analytics";
import { getStaffEmailID, isUserAuthenticated } from "utils/auth/auth";

export const triggerClippingAnalytics = ({ kpid, id, isCases, paRecommended, pageName, primaryCategory, slideNumber }) => {
    const {
        PA_RECOMMENDED,
        NOT_PA_RECOMMENDED
    } = MATERIAL_STATUS;
    const { CASE_PAGE } = TOOLNAME;

    const newAdobeData = {
        materialID: "NA",
        resultType: "NA",
        displayMode: "NA",
        materialPAStatus: paRecommended ? PA_RECOMMENDED : NOT_PA_RECOMMENDED,
        attachmentID: "NA",
        search: {
            term: "NA",
            bestbetflag: "NA"
        },
        slideNumber,
        page: {
            category: {
                primaryCategory,
            },
            pageInfo: {
                pageName,
                pageURL: getmyCurrentLocation()
            },
        },
        preFilterTerm: "NA",
        preOrderTerm: "NA",
        collection: {
            ID: isCases ? "NA" : id
        },
        KCpage: {
            id: kpid
        },
        caseID: isCases ? id : "NA",
        SERPview: "NA",
        chatID: "NA",
        threadID: "NA"
    };
    if (isCases) {
        ANALYTICS.kc.sendEventData(newAdobeData, DTMRULE.CLIPPING_KP, CASE_PAGE);
    } else {
        ANALYTICS.kc.sendEventData(newAdobeData, DTMRULE.CLIPPING_KP);
    }

};

export const triggerOverlayDownloadAnalytics = ({ kpid, id, isCases, paRecommended, pageName, primaryCategory }) => {
    const {
        PA_RECOMMENDED,
        NOT_PA_RECOMMENDED
    } = MATERIAL_STATUS;
    const { CASE_PAGE } = TOOLNAME;

    const newAdobeData = {
        materialID: "NA",
        resultType: "NA",
        displayMode: "NA",
        globalRelevance: "NA",
        materialPAStatus: paRecommended ? PA_RECOMMENDED : NOT_PA_RECOMMENDED,
        cardExpansionStatus: "expanded",
        suggestedTopicTitle: "NA",
        suggestedTopicPath: "NA",
        suggestedTopicID: "NA",
        suggestedTopicGroup: "NA",
        attachmentID: "NA",
        authorStaffID: "NA",
        search: {
            term: "NA",
            type: "search type",
            searchTrigger: "NA",
            filterTerm: "NA",
            filterLabel: "NA",
            toolName: "NA",
            bestbetflag: "NA",
        },
        searchResultRanking: "NA",
        preFilterTerm: "NA",
        preOrderTerm: "NA",
        tpof: {
            topicLevel: "NA",
        },
        page: {
            category: {
                primaryCategory,
            },
            pageInfo: {
                pageName,
                pageURL: getmyCurrentLocation()
            },
        },
        collection: {
            trigger: "NA",
            ID: isCases ? "NA" : id
        },
        trigger: "NA",
        KCpage: {
            id: kpid
        },
        caseID: isCases ? id : "NA",
        casePage: {
            id: isCases ? id : "NA",
            trigger: "NA"
        },

        SERPview: "NA",
        report: {
            reportType: "NA",
        },
        reportNameID: "NA",
        chatID: "NA",
        threadID: "NA"
    };
    if (isCases) {
        ANALYTICS.kc.sendEventData(newAdobeData, DTMRULE.DOWNLOAD_INTERACTIONS, CASE_PAGE);
    } else {
        ANALYTICS.kc.sendEventData(newAdobeData, DTMRULE.DOWNLOAD_INTERACTIONS);
    }
};

export const initialize = ({ pageName, category, trackPageLoad, toolName = TOOLNAME?.CONTRIBUTE_KNOWLEDGE, pageId, isIdPresent = true }) => {
    let staffEmailID = "unknown";
    if (isUserAuthenticated()) {
        staffEmailID = getStaffEmailID();
    }
    const data = {
        page: {
            pageInfo: {
                pageName: pageName, //eVar1
            },
            headerInfo: {
                staffEmailID: staffEmailID, //eVar23
                toolName: toolName, //eVar80
            },
            category: {
                primaryCategory: category, //eVar50
            },
        },
        search: {
            type: "NA" //eVar25
        },
        rc: {
            tabName: 'NA' //eVar48
        },
        KCpage: {
            id: 'NA' //eVar123
        },
        displayModeToggle: 'NA', //eVar24
        caseID: 'NA', //eVar118
        SERPview: capitalizeFirstLetter(getDefaultViewSection()) //eVar35
    };

    window.digitalData = isIdPresent ? { ...data, ...pageId } : data;
    window.analyticsObj = isIdPresent ? { ...data, ...pageId } : data;

    if (trackPageLoad) {
        ANALYTICS.page.track("virtual_page_load");
    }
}

export const kbConsumptionAnalytics = (options) => {
    initialize(options)
}