import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";

// SHARED COMPONENTS
import { InfoGrid } from "components/shared/InfoGrid";
import { InfoCard } from "components/shared/InfoCard";
import RichEditor from "components/shared/RichEditor";
import Counter from "components/shared/Counter";
import Checkbox from "components/shared/Checkbox";
import AssistCross from "components/shared/AssistCross";
import { AllocationListing } from "components/AllocationListing";
import { Taxonomy } from "containers/Taxonomy";

// HELPERS
import LABELS from "labels";
import CONFIG from "config";
import CONSTANTS from "globalConstants";
import { areObjectsEqual, renameKeys, getContentToDisplay } from "utils/helpers/helpers";
import { getTaggedTaxonomyConfig } from "containers/CasePage/CasePage.config";

// COMPONENTS
import { PeopleSelector } from "components/PeopleSelector";
import { CaseDurationAndCapture } from "components/CaseDurationAndCapture/CaseDurationAndCapture";
import TimeAndBillingInfo from "./TimeAndBillingInfo/TimeAndBillingInfo";
import { TypeAheadSelector } from "components/TypeAheadSelector";
import Image from "components/shared/Image";

// IMAGES
import tagIcon from "assets/images/tag_round.svg";
import warningIcon from "assets/images/warning/warning.svg";

// SCSS
import "./CPStep1.scss";

const {
  TAXONOMY: {
    SEARCH,
    SEARCH_SUBJECT_PLACEHOLDER,
    IPA_FPA_MODAL_SEARCH_PLACEHOLDER,
    SUBJECT_LABEL,
    SUBJECT_MODAL_HEADING
  },
  CP_CONTRIBUTION: {
    UNSANITIZED_CASE_TITLE,
    ADMIN_SELECTPA_HEADING
  },
} = LABELS;

class CPStep1 extends React.Component {
  constructor(props) {
    super();
    const {
      stepState: {
        sanitizedCaseTitle,
        clientDescription,
        clientBusinessUnitDescription,
        sanitizedCaseDescription,
      },
    } = props;
    this.state = {
      sanitizedCaseTitle: sanitizedCaseTitle || "",
      clientDescription: clientDescription || "",
      clientBusinessUnitDescription: clientBusinessUnitDescription || "",
      sanitizedCaseDescription: sanitizedCaseDescription?.trim() || "",
    };
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onBlurHandler = this.onBlurHandler.bind(this);
  }

  onChangeHandler = (target) => {
    const { value, name } = target;
    this.setState({ [name]: value });
  }

  setStepState = (data) => {
    const {
      sanitizedCaseTitle,
      clientDescription,
      clientBusinessUnitDescription,
      sanitizedCaseDescription,
    } = data;
    this.setState({
      sanitizedCaseTitle: sanitizedCaseTitle || "",
      clientDescription: clientDescription || "",
      clientBusinessUnitDescription: clientBusinessUnitDescription || "",
      sanitizedCaseDescription: sanitizedCaseDescription?.trim() || "",
    });
  }

  componentDidMount = () => {
    const { onLoadAnalytics } = this.props;
    onLoadAnalytics()
  }

  componentDidUpdate = (prevProps) => {
    const { stepState: prevStepState } = prevProps;
    const { stepState } = this.props;
    if (stepState && !areObjectsEqual(stepState, prevStepState)) {
      this.setStepState(stepState);
    }
  }

  onConfidentialHandler = () => {
    const {
      stepState: {
        confirmedConfidential,
      }
    } = this.props;
    this.setState({ sanitizedCaseDescription: "" });
    this.onBlurHandler({ name: "confirmedConfidential", value: !confirmedConfidential });
  }

  onRichTextChangeHandler = (name, value) => {
    this.setState({ [name]: value });
    this.onBlurHandler({ name, value });
  }

  onBlurHandler = (target) => {
    const { value, name } = target;
    const { handleChange } = this.props;
    handleChange(value, name);
  }
  setContentForAllocation = (data, obj) => {
    return data.map(current => renameKeys({ ...current }, obj));
  }
  getOfficeLocationValue = (data) => {
    let value = {}
    if (data && data.length) {
      const { id, name } = data[0];
      value = { id, name };
    }
    return value;
  }

  showOfficeWarningMsg = (isWarning, errorMsg) => {
    if (isWarning) {
      return (
        <div className="cpstep1__error">
          <Image
            src={warningIcon}
            alt="icon warning"
            className="cpstep1__error--icon"
          />
          <span className="cpstep1__errormsg">{errorMsg}</span>
        </div>
      );
    }
    return null;
  }

  checkOfficeWarningCondition = (selectedOffice = []) => {
    let isGeoAutoTagPresent = false;
    try {
      const { officeList, showOfficeWarning, initialCaseOffice } = this.props;
      if (showOfficeWarning && selectedOffice && Array.isArray(selectedOffice)) {
        selectedOffice.forEach(item => {
          if (officeList &&
            item.id && officeList[item.id] && officeList[item.id].isApplicableForGeoAutoTag &&
            initialCaseOffice && initialCaseOffice?.id && initialCaseOffice?.id !== item.id) {
            isGeoAutoTagPresent = true;
          }
          return item;
        })
      }
    } catch (error) {
      console.log('Error in checkOfficeWarningCondition ', error)
    }

    return isGeoAutoTagPresent;
  }

  handleOfficeChange = (data) => {
    const { setOfficeWarningFlag, handleChange } = this.props;
    handleChange(this.getOfficeLocationValue(data), "office");
    if (setOfficeWarningFlag) {
      setOfficeWarningFlag(true)
    }
  }

  render() {
    const {
      unSanitizedCaseData,
      stepState: {
        confirmedConfidential,
        subjectTags,
        ipaTagList,
        fpaTagList,
        bcgInternalList,
        office,
        id
      },
      handleChange,
      errorState = {},
      expertTagsIds,
      subjectTagsIds,
      caseInfo
    } = this.props;
    const {
      sanitizedCaseTitle,
      clientDescription,
      clientBusinessUnitDescription,
      sanitizedCaseDescription,
    } = this.state;
    const {
      CP_CONTRIBUTION_LIMITS: {
        EDITOR_LIMIT,
        TITLE_CHAR_LIMIT,
        CLIENT_DESC_CHAR_LIMIT,
        CLIENT_BU_DESC_CHAR_LIMIT,
        CASE_DESC_LIMIT,
      },
      CP_TAXONOMY_CONFIG: {
        PA,
        SUBJECT
      }
    } = CONFIG;
    const {
      CP_CONTRIBUTION: {
        EMPTY_VALUE,
        STEP1_FIELDS: {
          CASE_EDITOR,
          CASE_OFFICE,
          CASE_OFFICE_PLACEHOLDER,
          CASE_EDITOR_PLACEOLDER,
          CASE_TITLE,
          CLIENT_DESCRIPTION,
          CLIENT_BU_DESCRIPTION,
          CASE_DESCRIPTION,
          BUSINESS_SUBJECT_TAGGING,
          CONFIDENTIAL,
          BUSINESS_TAGGING,
          SUBJECT_TAGGING,
          OFFICE_AUTO_GEO_TAG_WARNING
        },
        ALLOCATION_LISTING: {
          INDUSTRY_PA,
          NO_ALLOCATION,
          FUNCTIONAL_PA,
          TOPIC_LEVEL_ALLOCATION,
          ALLOCATION_PERCENT,
          OVERLAY_TOPIC_GLOBAL_ADVANTAGE,
          OVERLAY_TOPIC_SOCIAL_ADVANTAGE,
          OVERLAY_TOPIC_ClIMATE_SUSTAINABILITY,
          BCG_REVENUE_FLAGS,
          PIPE,
          BCG_TURN,
          DIGITAL_ANALYTICS,
          FPA_STATUS,
        }
      },
      TAXONOMY: {
        AUTOTAGGED_MESSAGE_CASES
      }
    } = LABELS;
    const {
      clientName,
      clientBUName,
      unsanitizedCaseTitle,
      industryPracticeAreas,
      functionalPracticeAreas,
      FPAData: {
        globalImpact = [],
        socialImpact = [],
        climateImpact = []
      },
      pipeFlag,
      bcgTurn,
      digitalAnalyticsFlag,
      fpaStatus,
      source,
    } = caseInfo;
    const {
      TBDBS: {
        SEARCH_FPA_IPA_INTERNAL,
        SEARCH_SUBJECT,
      },
    } = CONSTANTS;
    const dataBcgRevenues = [
      {
        allocationPercent: pipeFlag,
        description: PIPE
      }, {
        allocationPercent: bcgTurn,
        description: BCG_TURN
      }, {
        allocationPercent: digitalAnalyticsFlag,
        description: DIGITAL_ANALYTICS
      }
    ]
    let officeLocation = []
    if (office && office.hasOwnProperty('id') && office.id !== "") {
      officeLocation = [{ ...office }]
    }
    return (
      <div className="cpstep cpstep1">
        <CaseDurationAndCapture {...caseInfo} />
        <InfoGrid
          gridTitle={
            <InfoCard
              imgPath={tagIcon}
              label={UNSANITIZED_CASE_TITLE}
              value={unsanitizedCaseTitle}
            />
          }
          data={unSanitizedCaseData}
        />
        <Form.Group>
          <Form.Field>
            <TypeAheadSelector
              label={`${CASE_OFFICE} *`}
              name="office"
              placeholder={CASE_OFFICE_PLACEHOLDER}
              apiUrl={CONFIG.API_URL.GET_OFFICES_DATA}
              preFillData={officeLocation}
              onFocusShowList={true}
              characterCount={2}
              responseKeyName={{
                id: "Id",
                name: "Name",
              }}
              prefillKeyName={{
                id: "id",
                name: "name",
              }}
              onChange={(data) => this.handleOfficeChange(data)}
              className={errorState && errorState['office'] ? 'cpstep1__customhighlight' : ''}
              maxLimit={1}
              hideList={[]}
            />
            {this.showOfficeWarningMsg(this.checkOfficeWarningCondition(officeLocation), OFFICE_AUTO_GEO_TAG_WARNING)}
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field>
            <PeopleSelector
              disabledCross
              maxLimit={EDITOR_LIMIT}
              label={CASE_EDITOR}
              placeholder={CASE_EDITOR_PLACEOLDER}
              className={errorState && errorState['editor'] ? 'peopleselector__error' : ''}
              name="editor"
              disabled={!caseInfo?.knwOwnerHrId}
              preFillData={caseInfo?.knwOwnerHrId ? [[caseInfo?.knwOwnerHrId]] : []}
              onChange={(data) => {
                handleChange(data, "editor");
              }}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field>
            <label>{CASE_TITLE} *</label>
            <Form.Input
              type="text"
              maxLength={TITLE_CHAR_LIMIT}
              onBlur={(e) => this.onBlurHandler(e.target)}
              onChange={(e) => this.onChangeHandler(e.target)}
              value={sanitizedCaseTitle}
              name="sanitizedCaseTitle"
              className={errorState && errorState['sanitizedCaseTitle'] ? 'input__error' : ''}
              icon={
                <Counter
                  inputLength={sanitizedCaseTitle.length}
                  maxLength={TITLE_CHAR_LIMIT}
                />
              }
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field>
            <label>{CLIENT_DESCRIPTION} {getContentToDisplay(clientName, EMPTY_VALUE) !== EMPTY_VALUE && `(${clientName}) *`}</label>
            <Form.Input
              type="text"
              maxLength={CLIENT_DESC_CHAR_LIMIT}
              onBlur={(e) => this.onBlurHandler(e.target)}
              onChange={(e) => this.onChangeHandler(e.target)}
              value={clientDescription}
              name="clientDescription"
              className={errorState && errorState['clientDescription'] ? 'input__error' : ''}
              icon={
                <Counter
                  inputLength={clientDescription.length}
                  maxLength={CLIENT_DESC_CHAR_LIMIT}
                />
              }
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field>
            <label>{CLIENT_BU_DESCRIPTION} {getContentToDisplay(clientBUName, EMPTY_VALUE) !== EMPTY_VALUE && `(${clientBUName}) *`} </label>
            <Form.Input
              type="text"
              maxLength={CLIENT_BU_DESC_CHAR_LIMIT}
              onBlur={(e) => this.onBlurHandler(e.target)}
              onChange={(e) => this.onChangeHandler(e.target)}
              value={clientBusinessUnitDescription}
              name="clientBusinessUnitDescription"
              className={errorState && errorState['clientBusinessUnitDescription'] ? 'input__error' : ''}
              icon={
                <Counter
                  inputLength={clientBusinessUnitDescription.length}
                  maxLength={CLIENT_BU_DESC_CHAR_LIMIT}
                />
              }
            />
          </Form.Field>
        </Form.Group>
        <label className="cpstep1__descriptionLabel">{CASE_DESCRIPTION}</label>
        <Checkbox
          onChange={() => this.onConfidentialHandler()}
          checked={confirmedConfidential}
          name="confirmedConfidential"
          label={CONFIDENTIAL}
          className="cpstep1__confirmCheck custom__checkbox"
        />
        <div className="cpstep1__descriptionWrapper">
          <AssistCross
            section1={
              <section className="cpstep1__descriptionSection">
                <Form.Group>
                  <Form.Field>
                    <div
                      className={
                        errorState && errorState["sanitizedCaseDescription"]
                          ? "richEditor customhighlight"
                          : "richEditor"
                      }
                    >
                      <RichEditor
                        updatedKey={id}
                        initialState={sanitizedCaseDescription}
                        handleOnblur={(value) => this.onRichTextChangeHandler("sanitizedCaseDescription", value)}
                        charLimit={CASE_DESC_LIMIT}
                      />
                    </div>
                  </Form.Field>
                </Form.Group>
              </section>
            }
            section2={
              <section className="cpstep1__tAndBSection">
                <TimeAndBillingInfo caseInfo={caseInfo} />
              </section>
            }
            sectionToToggle="section2"
            isDefaultExpanded={true}
          />
        </div>
        <label className="cpstep1__taxaonomyLabel">{BUSINESS_SUBJECT_TAGGING}</label>
        <div className="cpstep1__subjectWrapper">
          <AssistCross
            section1={
              <section className="cpstep1__subjectSection">
                <Form.Group>
                  <Form.Field>
                    <Taxonomy
                      label={BUSINESS_TAGGING}
                      placeholder={SEARCH}
                      modalHeader={ADMIN_SELECTPA_HEADING}
                      taxonomyName={PA}
                      hideTagsIds={[...subjectTagsIds]}
                      isApplicableForPolyHerarchi
                      preFillData={[
                        ipaTagList,
                        fpaTagList,
                        bcgInternalList
                      ]}
                      autoTaggedMessage={AUTOTAGGED_MESSAGE_CASES}
                      isShowNoResultInTypeAhead
                      onChange={(data) => {
                        handleChange({
                          "ipaTagList": data[0],
                          "fpaTagList": data[1],
                          "bcgInternalList": data[2],
                        }, ["ipaTagList", "fpaTagList", "bcgInternalList"]);
                      }}
                      className={errorState && errorState['practiseAreaTags'] ? 'txbase__error' : ''}
                      openModalCallback={() => { }}
                      clickHandlerCallback={() => { }}
                      deleteSelectedCallBack={() => { }}
                      submitHandlerCallback={() => { }}
                      responseTypeSearchKeyName={{
                        id: "id",
                        name: "name",
                      }}
                      isShowParentTagsTooltip
                      typeSearchplaceholder={IPA_FPA_MODAL_SEARCH_PLACEHOLDER}
                      characterCount={2}
                      taxonomyTypeSearchName={[
                        { searchKey: SEARCH_FPA_IPA_INTERNAL }
                      ]}
                      informationIconsConfig={getTaggedTaxonomyConfig()}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group>
                  <Form.Field>
                    <Taxonomy
                      key={id}
                      label={SUBJECT_TAGGING}
                      placeholder={SEARCH_SUBJECT_PLACEHOLDER}
                      modalHeader={SUBJECT_MODAL_HEADING}
                      hideTagsIds={[...expertTagsIds]}
                      taxonomyName={SUBJECT}
                      onChange={(data) => {
                        handleChange(data[0], "subjectTags");
                      }}
                      preFillData={[subjectTags]}
                      className={errorState && errorState['subjectTags'] ? 'txbase__error' : ''}
                      openModalCallback={() => { }}
                      clickHandlerCallback={() => { }}
                      deleteSelectedCallBack={() => { }}
                      submitHandlerCallback={() => { }}
                      typeAheadHandlerCallback={() => { }}
                      responseTypeSearchKeyName={{
                        id: "id",
                        name: "name",
                      }}
                      isShowParentTagsTooltip
                      typeSearchplaceholder={SUBJECT_LABEL}
                      characterCount={2}
                      autoTaggedMessage={AUTOTAGGED_MESSAGE_CASES}
                      taxonomyTypeSearchName={[
                        { searchKey: SEARCH_SUBJECT }
                      ]}
                      informationIconsConfig={getTaggedTaxonomyConfig()}
                    />
                  </Form.Field>
                </Form.Group>
              </section>
            }
            section2={
              <section className="cpstep1__subjectInfoAllocations">
                <div className="subjectInfoContainer">
                  <AllocationListing
                    content={this.setContentForAllocation(industryPracticeAreas, {
                      description: "description",
                      allocationPercent: "allocationPercent",
                    })}
                    topHeader={INDUSTRY_PA}
                    subHeader={[TOPIC_LEVEL_ALLOCATION, ALLOCATION_PERCENT]}
                    isCollpased={true}
                    isCollapsible={true}
                    noAllocation={NO_ALLOCATION} />
                  <AllocationListing
                    content={this.setContentForAllocation(functionalPracticeAreas, {
                      description: "description",
                      allocationPercent: "allocationPercent",
                    })}
                    subTopHeader={[FPA_STATUS, fpaStatus]}
                    topHeader={FUNCTIONAL_PA}
                    subHeader={[TOPIC_LEVEL_ALLOCATION, ALLOCATION_PERCENT]}
                    isCollpased={true}
                    isCollapsible={true}
                    noAllocation={NO_ALLOCATION} />
                  <AllocationListing
                    content={this.setContentForAllocation(globalImpact, {
                      overlayTopicName: "description",
                      allocationPercentage: "allocationPercent"
                    })}
                    topHeader={OVERLAY_TOPIC_GLOBAL_ADVANTAGE}
                    subHeader={[TOPIC_LEVEL_ALLOCATION, ALLOCATION_PERCENT]}
                    isCollpased={true}
                    isCollapsible={true}
                    noAllocation={NO_ALLOCATION} />
                  <AllocationListing
                    content={this.setContentForAllocation(socialImpact, {
                      overlayTopicName: "description",
                      allocationPercentage: "allocationPercent"
                    })}
                    topHeader={OVERLAY_TOPIC_SOCIAL_ADVANTAGE}
                    subHeader={[TOPIC_LEVEL_ALLOCATION, ALLOCATION_PERCENT]}
                    isCollpased={true}
                    isCollapsible={true}
                    noAllocation={NO_ALLOCATION} />
                  <AllocationListing
                    content={this.setContentForAllocation(climateImpact, {
                      overlayTopicName: "description",
                      allocationPercentage: "allocationPercent"
                    })}
                    topHeader={OVERLAY_TOPIC_ClIMATE_SUSTAINABILITY}
                    subHeader={[TOPIC_LEVEL_ALLOCATION, ALLOCATION_PERCENT]}
                    isCollpased={true}
                    isCollapsible={true}
                    noAllocation={NO_ALLOCATION} />
                  <AllocationListing
                    content={dataBcgRevenues}
                    topHeader={BCG_REVENUE_FLAGS}
                    source={source}
                    isCollpased={true}
                    isCollapsible={true}
                    noAllocation={NO_ALLOCATION} />
                </div>
              </section>
            }
            sectionToToggle="section2"
            isDefaultExpanded={true}
          />
        </div>
      </div>
    );
  }
}

CPStep1.propTypes = {
  unSanitizedCaseData: PropTypes.array.isRequired,
  caseInfo: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  expertTagsIds: PropTypes.array,
  subjectTagsIds: PropTypes.array,
  stepState: PropTypes.object,
};

CPStep1.defaultProps = {
  stepState: {},
  expertTagsIds: [],
  subjectTagsIds: [],
};

export { CPStep1 };