import React from "react";
import PropTypes from "prop-types";
import Dropdown from 'components/shared/Dropdown';
import { SearchBar } from "components/SearchBar";
import CONFIG from "config";
import LABELS from "labels";

import "./KnowledgeFilters.scss";

export const KnowledgeFilters = (props) => {
  const {
    selectedStatus,
    selectedType,
    recordStats,
    onStatusClick,
    onTypeChange,
    onSearchSubmit,
    searchTerm,
    isArchiveVisible
  } = props;
  const { draft, published, withdrawn, archived, review, inProgress } = recordStats;
  const {
    MY_NAVIGATOR: {
      STATUS_FILTERS: {
        REVIEW: LABEL_REVIEW,
        DRAFT: LABEL_DRAFT,
        PUBLISHED: LABEL_PUBLISHED,
        UNPUBLISHED: LABEL_UNPUBLISHED,
        INPROGRESS: LABEL_INPROGRESS,
        ARCHIVED: LABEL_ARCHIVED
      },
      SHOW,
      ENTITY_TYPES,
    },
  } = LABELS;
  const {
    KNOWLEDGE_TYPE_KEYS: {
      PAGES,
      BUNDLES,
      COLLECTIONS
    },
    KNOWLEDGE_STATUS: {
      DRAFT,
      INPROGRESS,
      PUBLISHED,
      UNPUBLISHED,
      ARCHIVED,
      REVIEW
    }
  } = CONFIG;
  const typeFilters = [
    { key: PAGES, text: ENTITY_TYPES.KP, value: PAGES },
    { key: BUNDLES, text: ENTITY_TYPES.KB, value: BUNDLES },
    { key: COLLECTIONS, text: ENTITY_TYPES.TC, value: COLLECTIONS }
  ];
  const filterPills = [
    { id: "status--draft", label: `${LABEL_DRAFT} - ${draft}`, value: DRAFT, condition: true },
    { id: "status--review", label: `${LABEL_REVIEW} - ${review}`, value: REVIEW, condition: true },
    { id: "status--published", label: `${LABEL_PUBLISHED} - ${published}`, value: PUBLISHED, condition: true },
    { id: "status--archived", label: `${LABEL_ARCHIVED} - ${archived}`, value: ARCHIVED, condition: isArchiveVisible },
    { id: "status--withdrawn", label: `${LABEL_UNPUBLISHED} - ${withdrawn}`, value: UNPUBLISHED, condition: true },
    { id: "status--inProgress", label: `${LABEL_INPROGRESS} - ${inProgress}`, value: INPROGRESS, condition: true },
  ]
  return (
    <div className="knowledge-filters">
      <div className="knowledge-filters__search">
        <SearchBar
          searchTerm={searchTerm}
          onSubmit={onSearchSubmit}
          disabled={selectedStatus === INPROGRESS}
        />
        {selectedStatus !== INPROGRESS && (
        <div className="knowledge-filters__sort">
          <div className="knowledge-filters__label">
            {SHOW}
          </div>
          <div className="knowledge-filters__dropdown">
            <Dropdown
              fluid
              // defaultValue={PAGES}
              selection
              value={selectedType}
              options={typeFilters}
              onChange={(e, { value }) => { onTypeChange(value); }}
            />
          </div>
        </div>
        )}
      </div>
      <div className="knowledge-filters__pills">

        {filterPills.map(({ id, label, value, condition }) => {
          return condition ?
            <div
              id={id}
              className={`knowledge-filters__status knowledge-filters__status-pill${selectedStatus === value ? "--active" : ""}`}
              onClick={() => onStatusClick(value)}
            >
              {label}
            </div> : null
        })
        }
      </div>
    </div>

  )
};

KnowledgeFilters.defaultProps = {
  selectedStatus: "",
  recordStats: { total: 0, draft: 0, published: 0, withdrawn: 0, inProgress: 0 },
  onStatusClick: () => { },
  totalActivities: 0,
  selectedType: "",
  onTypeChange: () => { },
  searchTerm: "",
  onSearchSubmit: () => { }
}

KnowledgeFilters.propTypes = {
  selectedStatus: PropTypes.string.isRequired,
  recordStats: PropTypes.object.isRequired,
  onStatusClick: PropTypes.func.isRequired,
  selectedType: PropTypes.string.isRequired,
  onTypeChange: PropTypes.func.isRequired,
  onSearchSubmit: PropTypes.func.isRequired,
  searchTerm: PropTypes.string,
};