import { put, call, takeLatest, takeEvery } from 'redux-saga/effects';
// Constant
import { MYCASES } from "redux/constants";
// Config
import CONFIG from "config";
// Axios Util
import { doAxiosRequest } from "config-axios";
import { moduleFailure } from "redux/global.action.js";
import {
    getCasesBTPending,
    getCasesBTSuccess,
    getCasesBTFailure,
    saveCaseBTPending,
    saveCaseBTSuccess,
    saveCaseBTFailure,
    getCaseDurationPending,
    getCaseDurationSuccess,
    getCaseDurationFailure,
} from "./casesBT.action";

export function* getCasesBT(action) {
    const { payload } = action;
    try {
        yield put(getCasesBTPending());
        const res = yield call(doAxiosRequest, {
            method: 'get',
            endpoint: CONFIG.API_URL.GET_CASES_BT(payload),
            headers: {
                'x-api-key': CONFIG.XA,
            }
        });
        yield put(getCasesBTSuccess(res));
    } catch (error) {
        yield put(getCasesBTFailure(error));
    }
}

export function* getCaseDuration() {
    try {
        yield put(getCaseDurationPending());
        const res = yield call(doAxiosRequest, {
            method: 'get',
            endpoint: CONFIG.API_URL.GET_CASE_DURATION(),
            headers: {
                'x-api-key': CONFIG.XA,
            }
        });
        yield put(getCaseDurationSuccess(res));
    } catch (error) {
        yield put(getCaseDurationFailure(error));
    }
}

export function* saveCaseBT(action) {
    const { payload: { guid, data } } = action;
    try {
        yield put(saveCaseBTPending(data));
        const res = yield call(doAxiosRequest, {
            method: 'patch',
            endpoint: CONFIG.API_URL.SAVE_CASE_BT(guid),
            params: data,
            headers: {
                'x-api-key': CONFIG.XA,
            }
        });
        if (res?.error?.code === "200") {
            yield put(saveCaseBTSuccess({ guid, data }));
        } else {
            yield put(saveCaseBTFailure(res));
        }

    } catch (error) {
        yield put(saveCaseBTFailure(error));
    }
}

export default function* CasesBTSaga() {
    try {
        yield takeLatest(MYCASES.GET_CASES_BT, getCasesBT);
        yield takeLatest(MYCASES.GET_CASES_DURATION, getCaseDuration);
        yield takeEvery(MYCASES.SAVE_CASE_BT, saveCaseBT);
    } catch (error) {
        yield put(moduleFailure(error, 'CasesBTSaga'));
    }
}