import LABELS from "labels";
const { BACK, EDIT, PUBLISH, SEND_REVIEW } = LABELS.BUTTONS;
// Config
const LOCAL_CONFIG = {
  footerWorkflow: [
    {
      align: "left",
      icon: "arrow left",
      iconAlign: "pre",
      id: "btn-back",
      classname: "footer-workflow__link footer-workflow__button--back",
      text: BACK,
      link: "",
      outFilled: true,
      target: false,
      visible: true,
      disabled: false,
      children: [],
      isClick: true,
    },
    {
      align: "right",
      children: [
        {
          type: "submit",
          id: "btn-publish",
          text: PUBLISH,
          classname: "green",
          disabled: false,
          visible: false,
          isClick: true,
        },
        {
          type: "submit",
          id: "btn-review",
          text: SEND_REVIEW,
          classname: "green",
          disabled: false,
          visible: false,
          isClick: true,
        },
      ],
    },
  ],
  footerWorkflowForReview: [
    {
      align: "left",
      id: "btn-edit",
      classname: "footer-workflow__link footer-workflow__button--edit",
      text: EDIT,
      link: "",
      outFilled: true,
      target: false,
      visible: true,
      disabled: false,
      children: [],
      isClick: true,
    },
  ],

};

// export default
export default LOCAL_CONFIG;
