// Constant
import { CASES } from "redux/constants";

export const getCasesMetaDetails = (caseNumbers) => ({
    type: CASES.GET_CASES_META_DETAILS,
    payload: {
        caseNumbers: caseNumbers
    }
});


export const getCasesMetaDetailsPending = () => ({
    type: CASES.API_GET_CASES_META_DETAILS_PENDING,
});

export const getCasesMetaDetailsSuccess = (res) => ({
    type: CASES.API_GET_CASES_META_DETAILS_SUCCESS,
    payload: {
        res
    }
});

export const getCasesMetaDetailsFailure = (error) => ({
    type: CASES.API_GET_CASES_META_DETAILS_FAILURE,
    payload: {
        error: error.toString()
    }
});
