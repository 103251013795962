import CONFIG from "config";
import CONSTANTS from "globalConstants";

export const SEARCH = (searchCriteria) => {
  const {
    pageNumber,
    pageSize,
    status,
    legacyNavId,
    originalCreator,
    searchType,
    bundleType,
    id,
    text
  } = searchCriteria;
  const {
    API_URL: {
      SEARCH: {
        KC,
        DEFAULTS
      }
    },
    KNOWLEDGE_PAGE_TYPE
  } = CONFIG;

  // build out query params depending on KP vs KB
  // this will be unified when the global knowledge search is implemented
  const { QUERY_PARAMS: { PAGE_NUMBER, PAGE_SIZE, STATUS, ORIGINAL_CREATOR, LEGACY_NAV_ID, ID, TEXT, TYPE } } = KC;
  const { KNOWLEDGE_TYPE_KEYS: { BUNDLES, COLLECTIONS } } = CONFIG;
  const isKB = (searchType === BUNDLES || searchType === COLLECTIONS);
  const { PAGE_SIZE: DEFAULT_PAGE_NUMBER, PAGE_SIZE: DEFAULT_PAGE_SIZE } = DEFAULTS

  // Find out the GUID for filtering the search results
  let type = KNOWLEDGE_PAGE_TYPE;
  const { KNOWLEDGEBUNDLE, TOPICCOLLECTION } = CONSTANTS;
  const { BUNDLE_TYPE } = CONFIG;
  if (bundleType === CONFIG.KNOWLEDGE_TYPE_KEYS.COLLECTIONS) {
    type = BUNDLE_TYPE[TOPICCOLLECTION];
  } else if (bundleType === CONFIG.KNOWLEDGE_TYPE_KEYS.BUNDLES) {
    type = BUNDLE_TYPE[KNOWLEDGEBUNDLE];
  }

  const queryString = ''.concat(
    // add page number to query string by default
    // default value if pageNumber is unspecified
    `${PAGE_NUMBER}=${!!pageNumber ? pageNumber : DEFAULT_PAGE_NUMBER}`,
    // add page size to query string by default
    // default value if pageSize is unspecified
    `&${PAGE_SIZE}=${!!pageSize ? pageSize : DEFAULT_PAGE_SIZE}`,
    // if status is specified, add to query string, else remove
    !!status ? `&${STATUS}=${status}` : '',
    // if legacyNavId is specified, add to query string, else remove (add only for KP)
    !!legacyNavId && !isKB ? `&${LEGACY_NAV_ID}=${legacyNavId}` : '',
    // if original creator is specified, add to query string, else remove
    !!originalCreator && originalCreator !== 0 ? `&${ORIGINAL_CREATOR}=${originalCreator}` : '',
    // if id is specified, add to query string, else remove
    !!id ? `&${ID}=${id}` : '',
    // if text is specified, add to query, else remove
    !!text ? `&${TEXT}=${encodeURIComponent(text)}` : '',
    //  type to filer the KP, bundles and topic collections
    !!type ? `&${TYPE}=${type}` : '',
  );

  return KC.URL(queryString);
};
