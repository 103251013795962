import React from 'react';
import { Label } from 'semantic-ui-react';
import './ShowMore_Less.scss';
import LABELS from 'labels';
import PropTypes from 'prop-types';
import InfoIcon from "assets/images/plusgreen.svg";
import Image from "components/shared/Image";

export class ShowMore extends React.PureComponent {

  constructor(props) {
    super(props);
    this.exampleRef = React.createRef();
    this.state = {
      scrollHeight: 0,
      showLess: false,
    };
    this.handleShowMoreAndLess = this.handleShowMoreAndLess.bind(this);
  }

  componentDidUpdate(props, state) {
    let element = this.exampleRef.current;
    if (!!element && state.scrollHeight !== element.scrollHeight) {
      this.setState({
        scrollHeight: element.scrollHeight,
      });
    }
  }

  componentDidMount() {
    this.setState({
      scrollHeight: this.exampleRef?.current?.scrollHeight,
    });
  }

  handleShowMoreAndLess = () => {
    const { showLess } = this.state;
    const { showMoreButtonCallback } = this.props;
    const {
      IDENTIFIER: {
        Cases_DESCRIPTION_SHOW_MORE,
        Cases_DESCRIPTION_SHOW_LESS
      },
    } = LABELS;
    this.setState({ showLess: !showLess });
    if (showMoreButtonCallback) {
      showMoreButtonCallback({
        identifier: showLess ? Cases_DESCRIPTION_SHOW_LESS : Cases_DESCRIPTION_SHOW_MORE,
        showLess
      })
    }
  };

  render() {
    const { children, height, applyShowMore } = this.props;
    const { scrollHeight, showLess } = this.state;
    const overflowShow = scrollHeight <= height || showLess;

    return (
      <div className="showMore">
        {applyShowMore ?
          <>
            <div
              style={
                overflowShow ? { maxHeight: '100%' } : { maxHeight: `${height}px` }
              }
              className={overflowShow ? 'showMore__visible' : 'showMore__hide'}
              ref={this.exampleRef}
            >
              {children}
            </div>
            <div
              className="showMore__button"
              hidden={!(scrollHeight > height + 5 && !showLess)}
              onClick={this.handleShowMoreAndLess}
            >
              <Image src={InfoIcon} className="showMore__icon-plus" />
              <Label className="showMore__showmore-label">
                {LABELS.KPCONSUMPTIONGROUP.SHOW_MORE}
              </Label>
            </div>
            <div
              className="showMore__button"
              hidden={!showLess}
              onClick={this.handleShowMoreAndLess}
            >
              <span className="showMore__icon-minus" />
              <Label className="showMore__showmore-label">
                {LABELS.KPCONSUMPTIONGROUP.SHOW_LESS}
              </Label>
            </div>
          </>
          : (
            children
          )
        }
      </div>
    );
  }

}
ShowMore.propTypes = {
  height: PropTypes.number,
  applyShowMore: PropTypes.bool
};

ShowMore.defaultProps = {
  height: 0,
  applyShowMore: true
};
