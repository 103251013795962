import React from 'react';
import PropTypes from 'prop-types';

import LABELS from "labels";

import { Tooltip } from "components/shared/Tooltip";
import Dropdown from 'components/shared/Dropdown';
import { openLinkProfile, sortArray, sortArrayAlphabetically, capitalizeFirstLetter } from "utils/helpers/helpers";
import { v4 as uuidv4 } from 'uuid';


import './ContactsSection.scss';

const {
  GLOBAL: {
    ALUMNI: ALUMNI_TAG,
    YES
  },
  CASE_CONTACTS: {
    HEADERS,
    DATA_KEYS: {
      NAME: KEY_NAME,
      POSITION: KEY_POSITION,
      HOURS: KEY_HOURS,
      HRID: KEY_HRID,
      ALUMNI: KEY_ALUMNI,
    },
    HOURS_TOOLTIP_MESSAGE,
  },
  MY_NAVIGATOR: {
    SORT
  }
} = LABELS;

export class ContactsSection extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sortBy: "hours",
      sortedData: props.data ? props.data : []
    }
  }
  componentDidUpdate = (prevProps) => {
    const { data } = this.props;
    if (data !== prevProps.data) {
      this.setState({
        sortedData: data
      });
    }
  }

  openProfile = (hrId) => {
    const { caseContactsClickCallback, identifier } = this.props;
    if (caseContactsClickCallback) {
      caseContactsClickCallback(
        {
          identifier,
          hrId
        }
      );
    }
    openLinkProfile(hrId)
  }

  sortChangeHandler = (value) => {
    const { sortBy } = this.state;
    const { data, sortOrderSelectionCallback } = this.props;
    if (value === sortBy) return;
    if (sortOrderSelectionCallback) {
      sortOrderSelectionCallback(
        {
          selectedSortOrder: capitalizeFirstLetter(value),
          preFilterTerm: "N/A",
          preOrderTerm: capitalizeFirstLetter(sortBy),
        }
      );
    }
    let sortedData = sortArray([...data], [KEY_HOURS]);
    if (value === "name") {
      sortedData = sortArrayAlphabetically([...data], [KEY_NAME]);
    }
    this.setState({
      sortBy: value,
      sortedData: sortedData
    });
  }

  removeUndefinedNames = (data) => {
    return data?.filter(({ preferredName }) => !!preferredName);
  }

  render() {
    const {
      heading,
      headers: {
        NAME, POSITION, HOURS
      },
      sortBy,
      showSortOption,
      context,
      showContactsCount,
      showSortLabel,
      showAlumniTag,
    } = this.props;

    const { sortedData } = this.state;

    const typeFilters = [
      { key: "hours", text: "Hours", value: "hours" },
      { key: "name", text: "Name", value: "name" },
    ];
    return (
      <>
        <div className="contactsSection__headwrap">
          {
            !!heading &&
            <span className={`contactsSection__heading ${context}__heading`}>
              {heading}
              {showContactsCount && <div class="ui label label__count">{this.removeUndefinedNames(sortedData)?.length}</div>}
            </span>
          }
          {!!showSortOption && (
            <div className={`contactsSection__dropdown ${context}__dropdown`}>
              {showSortLabel && SORT.toUpperCase()}
              <Dropdown
                fluid
                defaultValue={"hours"}
                selection
                value={sortBy}
                options={typeFilters}
                onChange={(e, { value }) => { this.sortChangeHandler(value); }}
              />
            </div>
          )}
        </div>

        <div className={context}>
          <div className={`${context}--listing`}>
            <div className="contactsSection">
              <section className="contactsSection__headers display">
                <span className="display__items__name">{NAME}</span>
                <span className="display__items__position">{POSITION}</span>
                <span className="display__items__hours--heading">
                  {HOURS}
                  {
                    <span className="contactsSection__headers__tooltip">
                      <Tooltip id={`${context}_cp_consumption_contacts_section`} position="left">
                        <span className="contactsSection__headers__tooltip__text">
                          {HOURS_TOOLTIP_MESSAGE}
                        </span>
                      </Tooltip>
                    </span>
                  }
                </span>
              </section>
              <ul className={`contactsSection__data ${context}__data`}>

                {
                  Array.isArray(sortedData) && sortedData.length ?
                    sortedData.map((item, index) => {
                      const {
                        [KEY_NAME]: NAME,
                        [KEY_POSITION]: POSITION,
                        [KEY_HOURS]: HOURS,
                        [KEY_HRID]: HRID,
                        [KEY_ALUMNI]: ALUMNI,
                      } = item;
                      return (
                        !!NAME ? (<li key={uuidv4()} className="display">
                          <span
                            className="contactsSection__data--name display__items__name"
                            onClick={() => this.openProfile(HRID)}>
                            {NAME}
                            {showAlumniTag && ALUMNI?.toUpperCase() === YES.toUpperCase() && <span className="display__items__name--tag">{ALUMNI_TAG}</span>}
                          </span>
                          <span className="display__items__position">{POSITION}</span>
                          <span className="display__items__hours">{HOURS}</span>
                        </li>) : null
                      )
                    })
                    :
                    null
                }
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  }
}

ContactsSection.propTypes = {
  heading: PropTypes.string,
  headers: PropTypes.object,
  data: PropTypes.array,
  context: PropTypes.string,
  showSortOption: PropTypes.bool,
  showSortLabel: PropTypes.bool,
  showContactsCount: PropTypes.bool,
};

ContactsSection.defaultProps = {
  heading: "",
  headers: { ...HEADERS },
  data: [],
  context: "",
  showSortOption: false,
  showSortLabel: false,
  showContactsCount: false,
  showAlumniTag: false,
};

