// React
import React from "react";
// PropTypes
import PropTypes from "prop-types";
import Image from "components/shared/Image";
import { Tooltip } from "components/shared/Tooltip";

import { inspectIdFromURL } from "utils/helpers/helpers";
import lockarrow from 'assets/images/donwloadRestricted.svg';

// Css
import "./RestrictedDownloadIcon.scss";

export const RestrictedDownloadIcon = ({
  restrictedText,
  mailToText,
  toolTipPosition,
  mailTo
}) => {
  return (
    <div className="restricted-download">
      <Tooltip
        position={toolTipPosition}
        id={inspectIdFromURL(mailTo)}
        clickable
        icon={
          <Image src={lockarrow} className="restricted-download__iconMarkup" />
        }
      >
        <div className="restricted-download__mail--hover">
          <span>{restrictedText}</span>
          <a className="restricted-download__hoverlink hoveroverride" href={mailTo} onClick={(e) => e.stopPropagation()}>
            {mailToText}
          </a>
        </div>
      </Tooltip>
    </div>
  );
};

// PropTypes
RestrictedDownloadIcon.propTypes = {
  restrictedText: PropTypes.string.isRequired,
  mailToText: PropTypes.string.isRequired,
  toolTipPosition: PropTypes.string.isRequired,
  mailTo: PropTypes.string.isRequired
};
