import React, { createRef } from "react";
import PropTypes from "prop-types";
import { checkIfItemHasSelectedChild } from "./utils";
import { checkIfNodePresentInObject } from "utils/helpers/helpers";
import { Icon } from 'semantic-ui-react';
import { CLASSES } from "redux/constants";
import "./HierarchicalAccordian.scss";
import { v4 as uuidv4 } from 'uuid';

class HierarchicalAccordian extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      taxonomyArr: []
    }
    this.wrapperRef = createRef();
  }

  componentDidMount() {
    const { getdata, taxonomyName, filteredOptions } = this.props;
    const taxonomyData = filteredOptions ? filteredOptions : getdata(taxonomyName[0].key);
    this.setState({
      taxonomyArr: taxonomyData
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.taxonomyArr !== nextProps.filteredOptions) {
      return {
        taxonomyArr: nextProps.filteredOptions
      }
    }
    return null;
  }

  handleAccordianClick = (e) => {
    const { EXPANDED } = CLASSES;
    let targetClassList = e.currentTarget.classList;
    let contentClassList = e.currentTarget.nextSibling;
    if (contentClassList && targetClassList) {
      if (contentClassList.classList.contains(EXPANDED)) {
        contentClassList.classList.remove(EXPANDED);
        targetClassList.remove(EXPANDED);
        if (e.target.attributes['data-accordian']) {
          e.target.attributes['data-accordian'].value = '';
        }
      } else {
        contentClassList.classList.add(EXPANDED);
        targetClassList.add(EXPANDED);
        if (e.target.attributes['data-accordian']) {
          e.target.attributes['data-accordian'].value = EXPANDED;
        }
      }
    }
  }

  getSelectionType = (item, selectedRegions, isTopMost) => {
    let className = "option ";
    const { responseKeyName } = this.props;
    const { id, children } = responseKeyName;

    if (item[children] && item[children].length) {
      let selectiontype = checkIfItemHasSelectedChild(item, selectedRegions, responseKeyName, isTopMost);
      className += selectiontype;
    } else {
      className += checkIfNodePresentInObject(selectedRegions, item[id], responseKeyName) ? " selected " : "";
    }
    return className;
  };

  getChevron = () => {
    return (<Icon name='chevron down' className="chevron_icon" />);
  }

  getContentParsedWithQuery = (itemname, query) => {
    return (
      <>
        {query && (itemname).split(new RegExp(`(${query})`, 'gi')).map((subString) => (
          <span key={uuidv4()} className={`${query.toLowerCase() === subString.toLowerCase() ? " highlighted " : " non-matched "}`}>
            {subString}
          </span>
        ))}
        {!query && <span>{itemname}</span>}
      </>
    )
  }

  getSelectorLayout = (allData, isTopMost) => {
    const { responseKeyName, selectedRegions, query } = this.props;
    const { id, name, children } = responseKeyName;
    return (
      allData &&
      allData.map((item) => {
        const hideNonMatchedElement = typeof item['isFiltered'] !== "undefined" && !item['isFiltered'];
        const hasChildren = item[children] && item[children].length > 0;
        const hideParent = hasChildren && typeof item['isChildFiltered'] !== "undefined" && !item['isChildFiltered'];

        return (
          <li
            key={item[id]}
            id={item[id]}
            className={`
            customize__msddstyle 
            ${hasChildren ? "customize__msdd--hierarchical" : "customize__msdd--single"}
            ${hideNonMatchedElement ? " itemhidden" : " itemshown"}            
            ${hideParent ? " child-hidden" : " child-shown"}            
            `}
          >
            <span
              onClick={!hasChildren ? (e) => this.handleClick(e, item) : (e) => this.handleAccordianClick(e)}
              data-accordian={''}
              className={`customize__msddtxt option ${this.getSelectionType(item, selectedRegions, isTopMost)} ${query && !hideParent ? " expanded" : ""}`}
            >
              <span className="customcheckbox" onClick={(e) => this.handleClick(e, item)}></span>
              <span className="content">{this.getContentParsedWithQuery(item[name], query)}</span>
              <span className="accordian-icon">{hasChildren && this.getChevron()}</span>
            </span>
            {hasChildren && (
              <ul className={`customize__msddsublist ${query && !hideParent ? " expanded" : ""}`}>
                {this.getSelectorLayout(item[children], false)}
              </ul>
            )
            }
          </li>
        );
      })
    );
  };


  handleClick = (event, item) => {
    event.stopPropagation();
    const { addSelectedRegion, query, responseKeyName, queryMatchCount, chosenSearchTermClickCallback } = this.props;
    const { name } = responseKeyName;
    addSelectedRegion(item);
    chosenSearchTermClickCallback(query, queryMatchCount, item[name]);
  };
  render() {
    const { className, isOpen } = this.props;
    const { taxonomyArr } = this.state;
    const selectorLayout = this.getSelectorLayout(taxonomyArr, true);
    return (
      <>
        {isOpen &&
          <div
            className={`customize customize__msdd ${className ? className : ""}`}
            ref={this.wrapperRef}
          >
            <ul className={`customize__msddlist`}>
              {selectorLayout}
            </ul>
          </div>
        }
      </>
    );
  }
}

HierarchicalAccordian.propTypes = {
  className: PropTypes.string,
  taxonomyName: PropTypes.array.isRequired,
  selectedRegions: PropTypes.object.isRequired,
  getdata: PropTypes.func,
  isOpen: PropTypes.bool,
  setisOpen: PropTypes.func,
  addSelectedRegion: PropTypes.func,
  query: PropTypes.string,
  queryMatchCount: PropTypes.number
}

HierarchicalAccordian.defaultProps = {
  className: '',
  taxonomyName: {},
  selectedRegions: {},
  isOpen: true,
  query: '',
  queryMatchCount: 0
}

export { HierarchicalAccordian };