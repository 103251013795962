import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from "components/shared/Tooltip";
import './InfoPopup.scss';

const RootElement = document.getElementById("root");

export class InfoPopup extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      menuIsOpen: false,
    };
    this.myRef = React.createRef();
  }

  toggleOptions = (event, disabled = false) => {
    const { fireAnalyticsEvent, resetInfoPopuModalState } = this.props;
    if (event) {
      event.stopPropagation();
    }
    if (fireAnalyticsEvent && !this.state.menuIsOpen) {
      fireAnalyticsEvent();
    }
    if (!disabled) {
      this.setState({ menuIsOpen: !this.state.menuIsOpen });
      resetInfoPopuModalState();
    }
  }

  componentDidMount = () => {
    if (RootElement) {
      RootElement.addEventListener('mousedown', this.handleClickOutside);
    }
    document.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount = () => {
    if (RootElement) {
      RootElement.removeEventListener('mousedown', this.handleClickOutside);
    }
    document.removeEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.isInfoPopupModalOpen !== prevProps.isInfoPopupModalOpen) {
      this.props.isInfoPopupModalOpen && this.setState({ menuIsOpen: !this.props.isInfoPopupModalOpen });
    }
  }

  /**
   * Hide Popup if outside is clicked
   */
  handleClickOutside = (event) => {
    const { onPopupClose, resetInfoPopuModalState } = this.props;
    if (
      this.myRef && this.myRef.current
      && !this.myRef.current.contains(event.target)) {
      this.setState({ menuIsOpen: false });
      resetInfoPopuModalState();
      if (onPopupClose) {
        onPopupClose();
      }
    }
  }

  /**
   * Hide Popup on scroll
   */
  handleScroll = () => {
    const { resetInfoPopuModalState } = this.props;
    this.setState({ menuIsOpen: false });
    resetInfoPopuModalState();
  }

  getPosition = () => {
    const position = {
      top: 'auto',
      left: 'auto',
      right: 'auto',
      bottom: 'auto',
    };
    const { popupVerticalPosition, popupHorizontalPosition } = this.props;
    if (popupVerticalPosition === 'bottom') {
      position.top = '40px';
    }
    if (popupVerticalPosition === 'top') {
      position.top = '-70px';
    }
    if (popupHorizontalPosition === 'left') {
      position.right = '0px';
    }
    if (popupHorizontalPosition === 'right') {
      position.left = '0px';
    }
    if (popupHorizontalPosition === 'center') {
      position.left = '50%';
      position.transform = 'translate(-50%, 0)';
    }
    return position;
  }

  render() {
    const {
      children,
      title,
      tooltipPosititon,
      tooltipId,
      trigger,
      withTooltip,
      offset,
      delayHide,
      disabled,
    } = this.props;
    const { menuIsOpen } = this.state;
    return (
      <div className="infopopup" ref={this.myRef}>
        {withTooltip ?
          <Tooltip
            position={tooltipPosititon}
            id={tooltipId}
            offset={offset}
            delayHide={delayHide}
            onClick={(e) => this.toggleOptions(e, disabled)}
            icon={
              <button disabled={disabled} className="infopopup__trigger" >{trigger}</button>
            }
          >
            {title}
          </Tooltip>
          :
          <button title={title} disabled={disabled} className="infopopup__trigger" onClick={this.toggleOptions}>{trigger}</button>
        }
        {menuIsOpen &&
          <div className="ui popup infopopup__content" style={this.getPosition()}>
            {children}
          </div>
        }
      </div>
    )
  }
}
InfoPopup.propTypes = {
  children: PropTypes.element.isRequired,
  title: PropTypes.string,
  tooltipPosititon: PropTypes.string,
  tooltipId: PropTypes.string.isRequired,
  trigger: PropTypes.element.isRequired,
  popupVerticalPosition: PropTypes.string,
  popupHorizontalPosition: PropTypes.string,
  withTooltip: PropTypes.bool,
  offset: PropTypes.string,
  disabled: PropTypes.bool,
  fireAnalyticsEvent: PropTypes.func,
};

InfoPopup.defaultProps = {
  tooltipPosititon: "bottom",
  popupVerticalPosition: "bottom",
  popupHorizontalPosition: "center",
  withTooltip: false,
  title: "",
  offset: "",
  disabled: false,
  fireAnalyticsEvent: () => { },
  resetInfoPopuModalState: () => { }
};