// Constant
import { POLYHIERACHY } from "redux/constants";

export const getPolyhierarchyApiData = () => ({
    type: POLYHIERACHY.GET_POLYHIERARCHY_API_DATA
});

export const polyhierarchyPending = () => ({
    type: POLYHIERACHY.POLYHIERARCHY_PENDING
});

export const polyhierarchySuccess = (data) => ({
    type: POLYHIERACHY.POLYHIERARCHY_SUCCESS,
    payload: data
});

export const polyhierarchyFailure = () => ({
    type: POLYHIERACHY.POLYHIERARCHY_FAILURE
});