import React from "react";
import { connect } from "react-redux";
import { WarningModal } from "components/WarningModal/WarningModal";

import LABELS from "labels";
import { cloneKnowledgeAsset } from "redux/actions/versioning/versioning.actions";
import { selectors as versioningSelectors } from "redux/reducers/versioning/versioning_reducer";
import CONFIG from "config";
import GLOBAL_CONSTANTS from "globalConstants";
import { openURL } from 'utils/helpers/helpers';


class CloneModalContainer extends React.PureComponent {
    constructor(props) {
        super();
        this.state = {
            isCModalVisible: false,
            isRetryModalVisible: false,
            isSuccessModalVisible: false,
        };
    }
    finalSubmitHandler = () => {
        const { id, status, isContribution, cloneSubmit, cloneSubmitCallback } = this.props;
        cloneSubmit(id, status, isContribution);
        if (!!cloneSubmitCallback) {
            cloneSubmitCallback();
        }
    }
    openModal = () => {
        this.setState({
            isCModalVisible: true
        });
    }
    openRetryModal = () => {
        this.setState({
            isRetryModalVisible: true
        });
    }

    openSuccessModal = () => {
        this.setState({
            isSuccessModalVisible: true,
            isCModalVisible: false,
            isRetryModalVisible: false
        });
    }
    closeSuccessModal = () => {
        const { closeModalCallback } = this.props;
        this.setState({
            isSuccessModalVisible: false
        });
        if (closeModalCallback)
            closeModalCallback();
    }

    closeModal = () => {
        const { closeModalCallback } = this.props;
        this.setState({
            isCModalVisible: false,
            isRetryModalVisible: false
        });
        if (closeModalCallback)
            closeModalCallback();
    }
    openClonedPage = () => {
        const { cloneStatus } = this.props;
        const { UI_URL: { METADATA_KP } } = CONFIG;
        const { newId } = cloneStatus;
        const { STEP1 } = GLOBAL_CONSTANTS;
        const url = METADATA_KP(STEP1, newId);
        openURL(url, true);
    }

    componentDidUpdate(prevProps) {
        const { showCloneModal, cloneStatus } = this.props;
        if (prevProps.showCloneModal !== showCloneModal && showCloneModal) {
            this.openModal();
        }
        if (prevProps.cloneStatus !== cloneStatus && cloneStatus) {
            if (cloneStatus?.newId) {
                this.openClonedPage();
                this.openSuccessModal();
            } else if (cloneStatus?.error) {
                this.openRetryModal();
            }
        }
    }

    render() {
        const { isCModalVisible, isSuccessModalVisible, isRetryModalVisible } = this.state;
        // label & texts
        const {
            WORK_SPACE: {
                WARNING_MODAL_CANCEL,
            },
            CLONE: {
                HEADING,
                BUTTON_CLONE,
                BUTTON_RETRY,
                HEADING_SUCCESS,
                HEADING_RETRY
            }
        } = LABELS;

        return (
            <>
                <WarningModal
                    heading={isRetryModalVisible ? HEADING_RETRY : HEADING}
                    button1={{
                        text: WARNING_MODAL_CANCEL,
                        clickHandler: this.closeModal,
                    }}
                    button2={{
                        text: isRetryModalVisible ? BUTTON_RETRY : BUTTON_CLONE,
                        clickHandler: this.finalSubmitHandler,
                    }}
                    ctasAlign={'corner'}
                    iconName={isRetryModalVisible ? 'warning' : 'copy'}
                    isOpen={isCModalVisible}
                    onRequestClose={this.closeModal}
                />
                <WarningModal
                    heading={HEADING_SUCCESS}
                    button1={{
                        text: "CLOSE",
                        clickHandler: this.closeSuccessModal,
                    }}
                    iconName={'check'}
                    isOpen={isSuccessModalVisible}
                    onRequestClose={this.closeSuccessModal}
                />
            </>
        );
    }
}

CloneModalContainer.defaultProps = {
    isContribution: false
};

const mapStateToProps = (state) => ({
    cloneStatus: versioningSelectors.getCloneStatus(state),
});

const mapDispatchToProps = (dispatch) => ({
    // taxonomyActions: bindActionCreators({ ...taxonomyActions }, dispatch),
    cloneSubmit: (id, status, isContribution) => dispatch(cloneKnowledgeAsset(id, status, isContribution)),
});

export const CloneModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(CloneModalContainer);

export { CloneModalContainer };
