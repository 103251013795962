import React from "react";

export function CustomArrow(props) {
    const { className, onClick, btnText, showBtn } = props;
    return (
        <button className={`${className}`} onClick={onClick}>
            {showBtn && <i aria-hidden="true" className="arrow left icon"></i>}
            {btnText}
            {!showBtn && <i aria-hidden="true" className="arrow right icon"></i>}
        </button>
    );
}