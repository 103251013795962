// React
import React from "react";
// PropTypes
import PropTypes from "prop-types";

import { Link } from "react-router-dom";
// Semantic UI
import { Button } from "semantic-ui-react";
// Components
import Icon from "components/shared/Icon";

export const CustomButton = ({ btn, getEvent }) => {
  return (
    <Button
      {...(btn?.order ? { style: { order: btn.order } } : null)}
      {...(btn?.link
        ? {
          as: btn.target ? "a" : Link,
          ...(btn.target ? { href: btn.link } : { to: btn?.link }),
          target: btn.target ? "_blank" : "_self",
          className: btn.classname
            ? btn.classname
            : "footer-workflow__link",
        }
        : {
          className: btn.classname
            ? btn.classname
            : "footer-workflow__btn",
        })}
      size="small"
      disabled={btn?.disabled ?? false}
      type={btn?.type ?? "button"}
      id={btn.id}
      title={btn.tooltip}
      onClick={(event, data) =>
        btn?.isClick
          ? getEvent && typeof getEvent === "function"
            ? getEvent(event, data)
            : null
          : null
      }
    >
      {btn?.icon && btn?.iconAlign === "pre" && <Icon name={btn?.icon} />}
      {btn?.text}
      {btn?.icon && btn?.iconAlign === "post" && <Icon name={btn?.icon} />}
    </Button>
  );
};

// PropTypes
CustomButton.propTypes = {
  btn: PropTypes.shape({
    type: PropTypes.string,
    align: PropTypes.string,
    icon: PropTypes.string,
    iconAlign: PropTypes.oneOf(["pre", "post"]),
    text: PropTypes.string,
    link: PropTypes.string,
    classname: PropTypes.string,
    target: PropTypes.bool,
    disabled: PropTypes.bool,
    visible: PropTypes.bool,
    outFilled: PropTypes.bool,
    isClick: PropTypes.bool,
    tooltip: PropTypes.string,
    children: PropTypes.arrayOf(
      PropTypes.shape({
        order: PropTypes.number,
        type: PropTypes.string,
        align: PropTypes.string,
        icon: PropTypes.string,
        iconAlign: PropTypes.oneOf(["pre", "post"]),
        text: PropTypes.string,
        classname: PropTypes.string,
        link: PropTypes.string,
        target: PropTypes.bool,
        disabled: PropTypes.bool,
        visible: PropTypes.bool,
        outFilled: PropTypes.bool,
        isClick: PropTypes.bool,
        tooltip: PropTypes.string,
      })
    ),
  }).isRequired,
  getEvent: PropTypes.func,
}; 
