import { OktaAuth } from '@okta/okta-auth-js';
import * as utils from '@kdshared/okta-utils';
import CONFIG from 'config';

const {
  ENVIRONMENT_MAIN,
  OKTA: {
    ISSUER,
    CID,
    REDIRECT_URL
  }
} = CONFIG;
// utils.enableVerboseLogging();
const baseConfig = utils.authServiceConfig(ENVIRONMENT_MAIN);
const authConfig = {
  issuer: ISSUER,
  clientId: CID,
  redirectUri: `${window.location.origin}${REDIRECT_URL}`,
}

export default new OktaAuth({ ...baseConfig, ...authConfig });
