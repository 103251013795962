import React, { PureComponent } from 'react';
import 'react-toastify/dist/ReactToastify.css';

import crossIcon from "assets/images/cross/crossBlack.svg";
import nudgeCaution from "assets/images/nudge_caution.svg";
import nudgeGreenOk from "assets/images/nudge_green_ok.svg";

import nudgeGreenSmall from "assets/images/nudge_green_small.svg";
import nudgeOrangeLarge from "assets/images/nudge_orange_large.svg";
import nudgeOrangeLargebolt from "assets/images/bolt.gif";

import LabelWithAdditionalText from "components/shared/LabelWithAdditionalText/LabelWithAdditionalText";
import { nudgeConditions, isAllNudgePassed, updateNudgeValueForSpecificField, getFieldSpecificConfig } from "components/shared/NudgePopUp/Nudge";
import { ThumbsUpDown } from "components/ThumbsUpDown";
import Image from "components/shared/Image";
import PropTypes from 'prop-types';
import inlineSpinner from "assets/images/spinner/spinner-v3.gif";
import './NudgePopUp.scss';

export class NudgePopUp extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      suggestions: [],
      isVisibleNudge: false
    };
  }
  componentDidMount = () => {
    const { handleOutsideClick = false, nodeName, value, nudgeConfig } = this.props;

    let nudgeValue = updateNudgeValueForSpecificField(nodeName, value, nudgeConfig);
    this.initializeNudge(nudgeValue, false);

    if (handleOutsideClick) {
      this.outsideClickHandler('addEventListener');
    }
  }

  componentDidUpdate = (prevProps) => {
    this.handlingFieldSpecificFunctionality(prevProps);
    const { isInternal: prevIsInternal } = prevProps;
    const { isInternal } = this.props;
    if (prevIsInternal !== isInternal) {
      const { nodeName, value, nudgeConfig } = this.props;

      let nudgeValue = updateNudgeValueForSpecificField(nodeName, value, nudgeConfig);
      this.initializeNudge(nudgeValue, false);
    }
  }

  componentWillUnmount = () => {
    const { handleOutsideClick = false } = this.props;
    if (handleOutsideClick) {
      this.outsideClickHandler('removeEventListener');
    }
  }

  outsideClickHandler = (eventListner) => {
    document[eventListner]("click", this.handleNudgeOnKeyPress)
  }

  handleNudgeOnKeyPress = (event) => {
    if (!document.getElementById('nudgepopup__wrap').contains(event.target)) {
      this.hideIfSuggestionPassed();
    }
  }

  /**
   * if any config is passed and they are dependent on componentDidUpdate then use this below fucntion
  * Handling field specific functionality for specific fields majorly for componentDidUpdate
  * @param {*} nodeName 
  * @param {*} value 
  * @param {*} showNudge 
  */
  handlingFieldSpecificFunctionality = (prevProps) => {

    let responseConfig = getFieldSpecificConfig(prevProps, this.props)

    if (responseConfig) {
      const { nodeName, delay } = this.props;
      if (nodeName === 'title') {
        const { value, showNudge, subjectSearchLoadingStatus } = responseConfig;
        this.initializeNudge(value, showNudge);
        if (!subjectSearchLoadingStatus) {
          setTimeout(() => {
            this.hideIfSuggestionPassed();
          }, delay);
        }
      }
      if (nodeName === 'subjectTaxonomy') {
        const { value, showNudge } = responseConfig;
        this.initializeNudge(value, showNudge);
        setTimeout(() => {
          this.hideIfSuggestionPassed();
        }, delay);
      }
    }
  }


  nudgeIconClick = (e) => {
    const { children } = this.props;
    if (children.props.nudgeIconClick)
      children.props.nudgeIconClick(e);
    this.setState({
      isVisibleNudge: true
    });
  }
  crossIconClick = () => {
    this.setState({
      isVisibleNudge: false
    })
  }
  initializeNudge = (value, isShowNudge) => {
    const { nodeName, isInternal } = this.props;
    const suggestions = nudgeConditions({ nodeName, value, isInternal });
    this.setState({
      suggestions,
      isVisibleNudge: isShowNudge
    });
  }

  /**
   * Handling onChange for specific fields
   * @param {*} nodeName 
   * @param {*} value 
   * @param {*} showNudge 
   */
  handleOnChangeForSpecificField = (nodeName, value, showNudge = true) => {
    let nudgeValue = '';
    const { nudgeConfig } = this.props;
    switch (nodeName) {
      case 'title':
      case 'subjectTaxonomy':
        nudgeValue = updateNudgeValueForSpecificField(nodeName, value, nudgeConfig)
        break;

      default:
        nudgeValue = updateNudgeValueForSpecificField(nodeName, value)
        break;
    }

    this.initializeNudge(nudgeValue, showNudge);
  }

  onChange = (value) => {
    const { children, onChange, checkSuggestionsOnChange, delay, nodeName } = this.props;
    if (children.props[onChange])
      children.props[onChange](value);

    this.handleOnChangeForSpecificField(nodeName, value, true);

    if (checkSuggestionsOnChange) {
      const that = this;
      setTimeout(function () {
        that.hideIfSuggestionPassed();
      }, delay);
    }
  }
  hideIfSuggestionPassed = () => {
    const { suggestions } = this.state;
    const isAllPassed = isAllNudgePassed(suggestions);
    this.setState({
      isVisibleNudge: !isAllPassed
    });
  }

  onBlur = (value) => {
    const { children, onBlur } = this.props;
    if (children.props[onBlur])
      children.props[onBlur](value);
    this.hideIfSuggestionPassed();
  }


  render = () => {
    const { children, onChange, onBlur, feedbackHeading, finalSuccess, heading, impactOnSearch: { label: impactOnSearchLabel, type, isHideOutside }, nudgeCustomClass } = this.props;
    const { isVisibleNudge, suggestions } = this.state;
    const isAllPassed = isAllNudgePassed(suggestions);

    let extraClass = '';
    if (isAllPassed) {
      extraClass = 'nudgepopup__suggestions--passed ';
    }
    if (!isVisibleNudge || !suggestions.length) {
      extraClass += 'nudgepopup__suggestions--hide ';
    }
    const inputClassName = isAllPassed || !suggestions.length ? '' : ' nudge__suggested ';
    return (
      <div className="nudgepopup__wrap" id="nudgepopup__wrap">
        <div className='nudgepopup__righttop'>
          {!isHideOutside && !!impactOnSearchLabel && !isVisibleNudge &&
            <LabelWithAdditionalText title={""} addionalLabel={impactOnSearchLabel} value={type} rightGap />
          }
          {<Image
            className={`nudgepopup__nudgeicon ${!!suggestions.length && !isVisibleNudge ? '' : 'nudgepopup__nudgeicon--hide'}`}
            src={isAllPassed ? nudgeGreenSmall : nudgeOrangeLargebolt}
            alt="nudge icon"
            onClick={this.nudgeIconClick}
          />
          }
        </div>
        {React.cloneElement(children, {
          [onChange]: this.onChange,
          [onBlur]: this.onBlur,
          className: children.props.className + inputClassName

        })}

        <div className={`nudgepopup__suggestions ${extraClass} ${nudgeCustomClass ? nudgeCustomClass : ' '} `}>
          <h4 className="nudgepopup__heading">
            <Image
              className="nudgepopup__orangeIcon"
              src={isAllPassed ? nudgeGreenSmall : nudgeOrangeLarge}
              alt="nudge Orange"
            />
            <span>{heading}</span>
          </h4>
          {!!impactOnSearchLabel && <LabelWithAdditionalText title={""} addionalLabel={impactOnSearchLabel} value={type} rightGap />}
          <ul className="nudgepopup__suggestionslist">
            {suggestions.map(({ message, isPassed, showLoading, isHide }, index) => {
              return (
                !isHide && (
                  <>
                    <li className='nudgepopup__list'>
                      {(showLoading) ? (
                        <Image src={inlineSpinner} alt="spinner" />
                      ) : (
                        <Image
                          src={isPassed ? nudgeGreenOk : nudgeCaution}
                          alt="suggestion icon"
                        />
                      )}
                      <span className={`${isPassed ? 'nudgepopup__suggestionpassed' : ''}`}>{message}</span>
                    </li>
                  </>
                )
              );
            })}

          </ul>
          <Image
            className="nudgepopup__crossicon"
            src={crossIcon}
            alt="cross"
            onClick={() => this.crossIconClick()}
          />
          <div className="nudgepopup__feedback">
            <span className="nudgepopup__feedback-heading">{feedbackHeading}</span>
            <span className="nudgepopup__feedback-thumbs">
              <ThumbsUpDown
                isThumbsUpDownV2
                finalSuccess={finalSuccess}
              />
            </span>
          </div>
        </div>

      </div >
    );
  }
}

NudgePopUp.propTypes = {
  nodeName: PropTypes.string.isRequired,
  onChange: PropTypes.string,
  onBlur: PropTypes.string,
};

NudgePopUp.defaultProps = {
  onChange: "onChange",
  onBlur: "onBlur",
  impactOnSearch: { label: "", type: "", isHideOutside: false },
  isInternal: false
};