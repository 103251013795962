// React
import React from "react";
// PropTypes
import PropTypes from "prop-types";

import LABELS from "labels";
import warningIcon from "assets/images/warning/warningWhite_3x.svg";

// Css
import "./Workflow.scss";

const { TPKB, KP, ALL } = LABELS.KNOWLEDGE_PRODUCT_NAME;

/**
 * @desc create a dynamic step progress bar
 * @param {Array} stepper
 * @returns html
 */
const Workflow = ({ productName, stepper, activeStep, showSanitizationBanner, sanitizationTitle }) => {
  return (
    <div className="workflowSteps">
      <div
        data-testid="stepper"
        className={`workflow ${showSanitizationBanner ? 'workflow__sanitization' : ''}`}
        id={"workflow-" + productName}
      >
        {stepper &&
          stepper.map((step, ind) => (
            <div className={"workflow__items"} id={`item-${ind}`} key={ind}>
              <label
                className={
                  activeStep === ind + 1
                    ? "workflow__label workflow__label--active"
                    : activeStep > ind + 1
                      ? "workflow__label workflow__label--done"
                      : "workflow__label workflow__label--pending"
                }
              >
                <div className="workflow__heading">
                  <div className="workflow__heading--heading">
                    STEP {ind + 1}
                  </div>
                  <div
                    key={ind}
                    className={
                      !step.active
                        ? "workflow__heading--sub-heading"
                        : "workflow__heading--sub-heading workflow__heading--sub-heading-active"
                    }
                  >
                    {step.text}
                  </div>
                </div>
                <div className="workflow__input">
                  <input
                    type="checkbox"
                    readOnly
                    checked={
                      activeStep === ind + 1
                        ? true
                        : activeStep > ind + 1
                          ? true
                          : false
                    }
                  />
                  <div
                    className={
                      activeStep === ind + 1
                        ? "workflow__input--check-mark workflow__input--check-mark--active"
                        : activeStep > ind + 1
                          ? "workflow__input--check-mark workflow__input--check-mark--done"
                          : "workflow__input--check-mark workflow__input--check-mark--pending"
                    }
                    key={ind}
                  ></div>
                </div>
              </label>
            </div>
          ))}
      </div>
      {stepper && stepper?.length > 0 && (
        <div
          className={`workflow-border ${showSanitizationBanner ? 'workflow-border__sanitization' : ''} `}
          style={{
            width: `${getWidth(stepper.length, activeStep)}%`,
          }}
        ></div>
      )}
      {showSanitizationBanner && (
        <div className="sanitization-banner">
          <img src={warningIcon} alt="warning" />
          <span>{sanitizationTitle}</span>
        </div>
      )}
    </div>
  );
};

/**
 * @param {*} total
 * @param {*} activeStep
 */
const getWidth = (total, activeStep) =>
  activeStep !== 1
    ? total === activeStep
      ? 100
      : (95 / total) * activeStep
    : 20;

// PropTypes
Workflow.propTypes = {
  stepper: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      text: PropTypes.string,
      link: PropTypes.string,
      active: PropTypes.bool,
    }).isRequired
  ),
  productName: PropTypes.oneOf([TPKB, KP, ALL]),
  activeStep: PropTypes.number,
  showSanitizationBanner: PropTypes.bool,
  sanitizationTitle: PropTypes.string
};

export default Workflow;
