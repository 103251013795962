import { put, call, takeLatest, delay } from 'redux-saga/effects';
import moment from 'moment';
import CONSTANTS from "globalConstants";
// Constant
import { FCG_NEW } from "redux/constants";

// Config
import CONFIG from "config";
// Axios Util
import { doAxiosRequest } from "config-axios";
import { newRequestAPIPending, newRequestAPISuccess, newRequestAPIFailure, pollingAPIStart, pollingAPISuccess, pollingAPIFailure } from "./faceCardGeneratorv1.actions";
import { downloadFileFromBlobResoponse, triggerUsabillaSurvey } from 'utils/helpers/helpers';

export function* handleNewRequestAPI(action) {
    try {
        yield put(newRequestAPIPending());
        const { XA, API_URL: { FACE_CARD_GENERATOR_ACKNOWLEDGE } } = CONFIG;
        const { payload } = action;
        const axiosConfig = {
            method: 'POST',
            endpoint: FACE_CARD_GENERATOR_ACKNOWLEDGE,
            params: payload,
            headers: {
                'x-api-key': XA
            }
        };
        const response = yield call(doAxiosRequest, axiosConfig);
        if (response?.error.code === "200" && response?.requestId) {
            yield put(newRequestAPISuccess(response?.requestId));
            yield put(pollingAPIStart(response?.requestId));
        } else {
            yield put(newRequestAPIFailure(response?.error.message));
        }
    }
    catch (error) {
        yield put(newRequestAPIFailure(error.toString()));
    }
}

const getCurrentDateTime = () => {
    var date = new Date();
    var value = date.getTime();
    const formattedDate = moment(value).format('DDMMYYYY_HHmmss');
    return formattedDate;
}
const triggerUsabilla = () => {
    const {
        USABILLA_SURVEY_KEYS: {
            FACECARD_DOWNLOAD
        }
    } = CONSTANTS;
    triggerUsabillaSurvey(FACECARD_DOWNLOAD);
}

export function* downloadFileFromCloudFront(action) {
    try {
        let { payload: { url } } = action;
        const axiosConfig = {
            method: 'GET',
            responsetype: 'blob',
            endpoint: url,
            headers: {
                'x-api-key': process.env.REACT_APP_CLIPPING_API_KEY
            }
        };
        const response = yield call(doAxiosRequest, axiosConfig);
        const formattedDate = getCurrentDateTime();
        downloadFileFromBlobResoponse(response, "Face Card Slides_" + formattedDate + ".pptx");
        triggerUsabilla();

    } catch (error) {
        console.log('Error in downloadFileFromCloudFront', error);
    }
}

export function* handlePollingAPI(action) {
    try {
        const { API_URL: { FACE_CARD_GENERATOR_POLLING } } = CONFIG;
        const axiosConfig = {
            method: 'GET',
            endpoint: FACE_CARD_GENERATOR_POLLING + '?requestId=' + action.payload.requestId,
            headers: {
                'x-api-key': CONFIG.XA
            }
        };
        const response = yield call(doAxiosRequest, axiosConfig);
        const status = response?.status?.toLowerCase();
        if (response?.error.code === "200" && status !== 'error') {
            if (status === 'completed') {
                yield put(pollingAPISuccess(response));
            } else {
                yield delay(2000);  // Wait for 1 second before next poll
                yield put(pollingAPIStart(action.payload.requestId));
            }
        }
        else {
            yield put(pollingAPIFailure(response?.error.message));
        }

    } catch (error) {
        yield put(pollingAPIFailure(error.toString()));
    }
}

export default function* faceCardGeneratorSagaV1() {
    yield takeLatest(FCG_NEW.GET_FC_SLIDE_DATA, handleNewRequestAPI);
    yield takeLatest(FCG_NEW.DOWNLOAD_FILE_FROM_CLOUDFRONT, downloadFileFromCloudFront);
    yield takeLatest(FCG_NEW.FC_POLLING_API_PENDING, handlePollingAPI);
}
