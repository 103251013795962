import React from "react";
import PropTypes from "prop-types";

// Styles
import "./CaseSearchItem.scss";

const CaseSearchItem = ({ caseNumber, caseTitle }) => {
	return (
		<div className="caseSearchItem">
			<section className="caseSearchItem__caseNumber">{caseNumber}</section>
			<section className="caseSearchItem__caseTitle">{caseTitle}</section>
		</div>
	);
};

CaseSearchItem.propTypes = {
	caseNumber: PropTypes.string.isRequired,
	caseTitle: PropTypes.string.isRequired,
}

export default CaseSearchItem;