import React from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

import LABELS from "labels";
import CONFIG from "config";

import { areObjectsEqual, isNumeric, sortArray, getConcatNames, isValidHrid, getPeopleApiDataByHrids } from "utils/helpers/helpers";
import { ContactsSection } from './ContactsSection';
import { OtherContactsModal } from './OtherContactsModal';

import { actions as peopleResultsActions } from "redux/actions/peopleresults/peopleresults_actions";


import './CaseContactsListing.scss';
import withErrorBoundary from 'containers/ErrorBoundary/ErrorBoundary';

const {
  CASE_CONTACTS: {
    DATA_KEYS: {
      NAME, POSITION, HOURS, ALUMNI, HRID
    },
    VIEW_OTHER_CONTACTS,
    CASE_TEAM_LIST_HEADING,
    OTHER_CONTACTS_MODAL_HEADING,
    CCO,
    CASE_EDITOR,
    BILLING_PARTNER,
    EXECUTING_MDP
  },
  IDENTIFIER: {
    CARD_CP_CCO,
    CARD_CP_KMS_OWNER,
    CARD_CP_BILLING_PARTNER,
    CARD_CP_VIEW_OTHER_CONTACTS,
    CARD_CP_CASE_TEAM_MEMBER,
    CARD_CP_EXECUTING_MDP
  }

} = LABELS;

export class CaseContacts extends React.PureComponent {

  state = {
    alumniData: [],
    nonAlumniData: [],
    isOtherContactsModalVisible: false
  };

  setTeamMembersData = async () => {
    const [alumniData, nonAlumniData] = await this.getCaseTeamData();
    const { caseTeamDataSetCallback } = this.props;
    this.setState({
      alumniData,
      nonAlumniData,
    }, () => {
      if (caseTeamDataSetCallback) {
        caseTeamDataSetCallback(nonAlumniData);
      }
    });
  }

  componentDidMount = () => {
    const {
      caseDetails: {
        allCaseTeams
      }
    } = this.props;
    if (allCaseTeams?.length) {
      this.setTeamMembersData();
    }
  }

  componentDidUpdate = (prevProps) => {
    const {
      caseDetails: {
        allCaseTeams
      }
    } = this.props;
    const {
      caseDetails: {
        allCaseTeams: prevCaseTeam
      }
    } = prevProps;
    const {
      alumniData,
      nonAlumniData
    } = this.state;
    if (allCaseTeams?.length && !areObjectsEqual(allCaseTeams, prevCaseTeam) && !alumniData.length && !nonAlumniData.length) {
      this.setTeamMembersData();
    }
  }

  openOtherContactsModal = () => {
    this.setState({
      isOtherContactsModalVisible: true
    });
  }

  closeOtherContactsModal = () => {
    this.setState({
      isOtherContactsModalVisible: false
    });
  }

  handleOtherContacts = (event) => {
    const { caseContactsClickCallback } = this.props;
    if (event) {
      event.stopPropagation();
    }
    if (caseContactsClickCallback) {
      caseContactsClickCallback(
        {
          identifier: CARD_CP_VIEW_OTHER_CONTACTS
        }
      );
    }
    this.openOtherContactsModal();
  }

  getContactTileData = () => {
    const {
      caseDetails: {
        cco,
        billingPartnerHrId,
        kmsOwner,
        executingMDPHrId
      },
      isHideCCO,
      showBillingPartner
    } = this.props;
    let contactTileData = [];
    if (!isHideCCO) {
      contactTileData.push({
        heading: CCO,
        hrId: isValidHrid(cco) ? [cco] : '',
        identifier: CARD_CP_CCO
      })
    }

    showBillingPartner && contactTileData.push({
      heading: BILLING_PARTNER,
      hrId: isNumeric(billingPartnerHrId) ? [billingPartnerHrId] : '',
      identifier: CARD_CP_BILLING_PARTNER
    })
    contactTileData.push({
      heading: EXECUTING_MDP,
      hrId: (Array.isArray(executingMDPHrId) && !!executingMDPHrId.length) ? executingMDPHrId : '',
      identifier: CARD_CP_EXECUTING_MDP
    })
    contactTileData.push({
      heading: CASE_EDITOR,
      hrId: isNumeric(kmsOwner) ? [kmsOwner] : '',
      identifier: CARD_CP_KMS_OWNER
    })
    return contactTileData;
  }

  prepareContactDataFromCaseApi = (caseTeamMember) => {
    const {
      preferredFirstName,
      preferredLastName,
      positionTitle,
      timeAndBillingHours,
      hrEmployeeId
    } = caseTeamMember

    return {
      [NAME]: `${preferredFirstName} ${preferredLastName}`,
      [POSITION]: positionTitle,
      [HOURS]: timeAndBillingHours,
      [HRID]: hrEmployeeId
    };
  }

  prepareContactDataFromPeopleApi = (peopleData, caseTeamMember) => {
    const {
      hrEmployeeId,
      positionTitle,
      timeAndBillingHours,
    } = caseTeamMember

    return {
      [NAME]: peopleData[NAME],
      [POSITION]: positionTitle,
      [HOURS]: timeAndBillingHours,
      [HRID]: hrEmployeeId
    };
  }

  getCaseTeamData = async () => {
    const {
      caseDetails: {
        allCaseTeams
      },
      peopleResultsActions: { getPeopleDataUsingIDs },
    } = this.props;

    let alumniData = [], nonAlumniData = [];

    // list of hrIds for which we need People API results
    const hrIds = allCaseTeams.map(caseTeamMember => caseTeamMember?.hrEmployeeId)

    if (!hrIds.length) {
      return [alumniData, nonAlumniData];
    }

    const {
      PEOPLE_API_QUERY_PARAMS: {
        CASE_CONTACT_SEARCH: {
          LIMIT, REQUEST_DATA
        }
      },
    } = CONFIG;
    const peopleApiParams = {
      LIMIT, REQUEST_DATA
    }
    
    const peopleResultSet = await getPeopleApiDataByHrids(hrIds, this.props.peopleResultsActions.getPeopleDataUsingIDs); 


    if (Array.isArray(peopleResultSet) && peopleResultSet.length) {
      let peopleResultSubDataMap = new Map();
      peopleResultSet.forEach(peopleData => {
        peopleResultSubDataMap.set(peopleData?.id, {
          [NAME]: getConcatNames(peopleData?.preferredFirstName, peopleData?.preferredLastName),
          [ALUMNI]: peopleData?.alumni,
        });
      });

      const allCaseTeamSortedByTBHours = sortArray(allCaseTeams, "timeAndBillingHours");

      allCaseTeamSortedByTBHours.forEach(caseTeamMember => {
        const { hrEmployeeId } = caseTeamMember;
        const peopleData = peopleResultSubDataMap.get(hrEmployeeId.toString());
        const caseMemberData = (typeof peopleData?.[ALUMNI] === "undefined") ?
          this.prepareContactDataFromCaseApi(caseTeamMember)
          :
          this.prepareContactDataFromPeopleApi(peopleData, caseTeamMember);

        if (caseTeamMember?.isAlumni === "TRUE" || peopleData?.[ALUMNI] === "YES") {
          alumniData.push(caseMemberData)
        } else {
          nonAlumniData.push(caseMemberData)
        }
      });
    }
    return [alumniData, nonAlumniData];
  }

  render() {
    const {
      caseDetails: {
        cco,
        kmsOwner,
        billingPartnerHrId,
        executingMDPHrId
      }
    } = this.props;
    const {
      nonAlumniData,
      alumniData,
      isOtherContactsModalVisible,
    } = this.state;
    const { caseContactsClickCallback, sortOrderSelectionCallback } = this.props;
    const showViewOtherContacts = (Array.isArray(alumniData)
      && alumniData.length)
      || isNumeric(cco) || isNumeric(kmsOwner) || isNumeric(billingPartnerHrId) || (Array.isArray(executingMDPHrId) && !!executingMDPHrId.length);
    return (
      <>
        <div className="caseContacts">
          {Array.isArray(nonAlumniData) && (
            <ContactsSection
              heading={CASE_TEAM_LIST_HEADING}
              data={nonAlumniData}
              caseContactsClickCallback={caseContactsClickCallback}
              sortOrderSelectionCallback={sortOrderSelectionCallback}
              identifier={CARD_CP_CASE_TEAM_MEMBER}
              showSortOption
            />
          )}
          {
            showViewOtherContacts &&
            <aside className="caseContacts__viewOtherContacts">
              <Link
                to={{}}
                className="caseContacts__viewOtherContacts__link"
                onClick={() => this.handleOtherContacts()}
              >
                <span className="contributeknwldg__text">{VIEW_OTHER_CONTACTS}</span>
              </Link>
            </aside>
          }

        </div>
        {
          showViewOtherContacts &&
          <OtherContactsModal
            heading={OTHER_CONTACTS_MODAL_HEADING}
            data={alumniData}
            isOpen={isOtherContactsModalVisible}
            onRequestClose={this.closeOtherContactsModal}
            contactTileData={this.getContactTileData()}
            caseContactsClickCallback={caseContactsClickCallback}
          />
        }
      </>
    );
  }
}

CaseContacts.propTypes = {
  caseDetails: PropTypes.object,
  isHideCCO: PropTypes.bool
};

CaseContacts.defaultProps = {
  caseDetails: {},
  isHideCCO: false
};


const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({
  peopleResultsActions: bindActionCreators(
    { ...peopleResultsActions },
    dispatch
  )
});

export const CaseContactsListing = connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorBoundary(CaseContacts));