// constant
import { PA_RECOMMENDED } from "redux/constants";

// local variable
export const name = "parecommender";

// initial state
export const initialState = {
  error: false,
  errorMessage: "",
  isparecommender: false,
  isRolePARecommender: null,
  isFetchingParecommender: false,
  paRecommendedData: []

};
// reducer selectors
export const selectors = {
  getPaRecommendedError: (state) => state[name].error,
  getPaRecommendedStatus: (state) => state[name].isparecommender,
  getPaRecommendedData: (state) => state[name].paRecommendedData,
  getRolePARecommeder: (state) => state[name].isRolePARecommender
};

const setRolePaRecommender = (state, payload) => {
  const { isparecommender } = payload;
  return Object.assign({}, state, {
    ...state,
    isRolePARecommender: isparecommender
  });
};
const setRolePaRecommenderFailure = (state, payload) => {
  const { error } = payload;
  return Object.assign({}, state, {
    ...state,
    error: true,
    isRolePARecommender: false,
    errorMessage: error,

  });
};

const setPaRecommendedPending = (state) => {
  return Object.assign({}, state, {
    ...state,
    isFetchingParecommender: true,
  });
};
const setPaRecommended = (state, payload) => {
  const { isparecommender } = payload;
  return Object.assign({}, state, {
    isparecommender: isparecommender,
    paRecommendedData: payload,
    isFetchingParecommender: false,
  });
};
const setPaRecommendedFailure = (state, payload) => {
  const { error } = payload;
  return Object.assign({}, state, {
    error: true,
    errorMessage: error,
    isFetchingParecommender: false,
  });
};


// reducer
export function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {

    case PA_RECOMMENDED.IS_ROLE_PA_RECOMMENDER_SUCCESS:
      return setRolePaRecommender(state, payload);
    case PA_RECOMMENDED.IS_ROLE_PA_RECOMMENDER_FAILURE:
      return setRolePaRecommenderFailure(state, payload);

    case PA_RECOMMENDED.IS_PA_RECOMMENDED_SUCCESS:
      return setPaRecommended(state, payload);
    case PA_RECOMMENDED.IS_PA_RECOMMENDED_FAILURE:
      return setPaRecommendedFailure(state, payload);
    case PA_RECOMMENDED.IS_PA_RECOMMENDED_PENDING:
      return setPaRecommendedPending(state);

    case PA_RECOMMENDED.UPDATE_RECOMMEND_PA_SUCCESS:
      return setPaRecommended(state, payload);
    case PA_RECOMMENDED.UPDATE_RECOMMEND_PA_FAILURE:
      return setPaRecommendedFailure(state, payload);
    case PA_RECOMMENDED.UPDATE_RECOMMEND_PA_PENDING:
      return setPaRecommendedPending(state);
    default:
      return state;
  }
}
