// Dependencies
import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import OutsideClickHandler from 'react-outside-click-handler';
import Image from "components/shared/Image";
import LoaderIcon from "assets/images/spinner/spinner-v3.gif";
import { Tooltip } from "components/shared/Tooltip";
import crossIcon from "assets/images/cross-icon.svg";
import { Link } from "react-router-dom";

// Assets
import SearchIcon from "assets/images/Enter/enter-arrow.svg";
import warningIcon from "assets/images/warning/warning.svg";
import LABELS from "labels";

// Styles
import "./SearchBox.scss";
import CONFIG from "config";

class SearchBox extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			searchTerm: "",
			showResults: false,
			errorMessage: "",
		};
	}

	componentDidUpdate = (prevProps) => {
		const {
			searchResults,
			showErrorInResults,
			totalCount,
			isSearching,
			noResultsMessage,
			tooManyResultsMessage,
			maximumResults
		} = this.props;
		const { showResults } = this.state;
		const { isSearching: wasSearching } = prevProps;
		if (!isSearching && wasSearching) {
			if (!searchResults?.length && noResultsMessage) {
				this.setState({ errorMessage: noResultsMessage });
			}
			else if (maximumResults && totalCount > maximumResults && tooManyResultsMessage) {
				this.setState({ errorMessage: tooManyResultsMessage });
			}
			if (!showResults) {
				this.setState({ showResults: !!showErrorInResults });
			}
		}
	};

	// handle the search icon click
	handleSubmit = (event) => {
		const { handleOnSearch, validateInput, showErrorInResults } = this.props;
		const { searchTerm } = this.state;
		event.preventDefault();
		event.stopPropagation();
		this.setState({ errorMessage: "" });
		const error = validateInput(searchTerm);
		if (error) {
			this.setState({ errorMessage: error, showResults: !!showErrorInResults });
		}
		else {
			handleOnSearch(searchTerm);
		}
	};

	//handle the input event
	handleChange = (event) => {
		const { target: { value } } = event;
		const { handleOnChange } = this.props;
		this.setState({ searchTerm: value });
		handleOnChange(value);
	};

	// when user clicks outside of the search box
	onOutsideClickHandler = () => {
		const { handleOutsideClick } = this.props;
		this.setState({ searchTerm: "" });
		handleOutsideClick();
		this.setState({ showResults: false, errorMessage: "" });
	};

	// submit the form
	handleKeyPress = (e) => {
		// submit the form
		if (e.keyCode === 13) {
			this.handleSubmit(e);
		}
		if (e.keyCode === 27) {
			// on escape fire outsideclick
			this.onOutsideClickHandler();
		}
	};

	// when search box is clicked
	onClickHandler = () => {
		const { handleOnClick } = this.props;
		handleOnClick();
	};

	// when item from search  is clicked
	onResultClick = (item) => {
		const { handleOnResultClick } = this.props;
		handleOnResultClick(item);
		this.onOutsideClickHandler();
	}

	render() {
		const {
			label,
			placeHolder,
			showEnterIcon,
			enterIcon,
			className,
			searchResults,
			renderResult,
			resultsHeading,
			showErrorInResults,
			hideLabel,
			selectedCase,
			crossClickCallback,
			isSearching,
			maxLength,
			isMultipleCases,
			tooltipMessage
		} = this.props;
		const {
			searchTerm,
			showResults,
			errorMessage,
		} = this.state;
		const { UI_URL: { CP_CONSUMPTION_NEW } } = CONFIG;
		const caseUrl = CP_CONSUMPTION_NEW(selectedCase.caseGuid);
		const { ASSOCIATED_CASES_NO_TITLE, ASSOCIATED_CASES_NO_DETAILS } = LABELS;
		return (
			<div className={`searchBoxWrapper ${className}`}>
				<OutsideClickHandler
					onOutsideClick={this.onOutsideClickHandler}
				>
					<Form className="searchBoxWrapper__searchForm">
						<Form.Group>
							<Form.Field className="searchBoxWrapper__field">
								{!hideLabel && (
									<>
										<label className={`searchBoxWrapper__label ${!!tooltipMessage ? " searchBoxWrapper__labelwithtooltip" : " "}`}>
											{label}
										</label>
										{!!tooltipMessage && <Tooltip id="search_related_tooltip">
											{tooltipMessage}
										</Tooltip>}

									</>
								)}

								{isMultipleCases ? (
									<div className={`searchBoxWrapper__multipleCase ${className}`}>
										<Form.Input
											className="searchBoxWrapper__input"
											value={searchTerm}
											placeholder={placeHolder}
											onChange={this.handleChange}
											autoComplete="off"
											onPaste={this.handleChange}
											onKeyDown={this.handleKeyPress}
											onClick={this.onClickHandler}
											maxLength={maxLength}
											icon={
												<>
													{!!showEnterIcon && <span onClick={this.handleSubmit}>
														<Image
															src={!isSearching ? enterIcon : LoaderIcon}
															alt={!isSearching ? LABELS.ALT_TEXTS.ENTER_ICON : "loading"}
															className="searchBoxWrapper__input__searchIcon"
														/>
													</span>
													}
												</>
											}
										/>
										{selectedCase?.length > 0 && <div className="searchBoxWrapper__multipleCase__caseno">
											{selectedCase?.map((item, index) => {
												const itemTitle = item.caseTitle || item?.sanitizedCaseTitle;
												const itemId = item?.caseGuid || item?.id;
												return (
													<div key={itemId} className="searchBoxWrapper__selecteditem">
														<Link
															to={{ pathname: CP_CONSUMPTION_NEW(itemId) }}
															target="_blank"
															rel="noopener noreferrer"
															className="searchBoxWrapper__link"
															onClick={(e) => {
																if (!itemId)
																	e.preventDefault();
															}}
														>
															<Tooltip
																id={itemId ? itemId + "-tooltip" : "no-case-id"}
																position="top"
																icon={
																	item.caseNumber
																}
															>
																{!!itemId ? (!!itemTitle ? itemTitle : ASSOCIATED_CASES_NO_TITLE) : ASSOCIATED_CASES_NO_DETAILS}
															</Tooltip>
														</Link>
														<Image
															className="searchBoxWrapper__selected__crossicon"
															src={crossIcon}
															alt={LABELS.ALT_TEXTS.CROSS}
															onClick={() => crossClickCallback(item)}
														/>
													</div>
												)
											})}
										</div>}
									</div>)
									:
									(!selectedCase?.caseNumber ?
										<Form.Input
											className="searchBoxWrapper__input"
											value={searchTerm}
											placeholder={placeHolder}
											onChange={this.handleChange}
											autoComplete="off"
											onPaste={this.handleChange}
											onKeyDown={this.handleKeyPress}
											onClick={this.onClickHandler}
											maxLength={maxLength}
											icon={
												<>
													{!!showEnterIcon && <span onClick={this.handleSubmit}>
														<Image
															src={!isSearching ? enterIcon : LoaderIcon}
															alt={!isSearching ? LABELS.ALT_TEXTS.ENTER_ICON : "loading"}
															className="searchBoxWrapper__input__searchIcon"
														/>
													</span>
													}
												</>
											}
										/>
										:
										<div className="searchBoxWrapper__selecteditem">
											<Link
												to={{ pathname: caseUrl }}
												target="_blank"
												rel="noopener noreferrer"
												className="searchBoxWrapper__link"
											>
												<Tooltip
													id={"case-title-face"}
													position="top"
													icon={
														/* eslint-disable-next-line jsx-a11y/anchor-is-valid */
														selectedCase?.caseNumber

													}
												>
													{selectedCase?.caseTitle}
												</Tooltip>
											</Link>
											<Image
												className="searchBoxWrapper__selected__crossicon"
												src={crossIcon}
												alt={LABELS.ALT_TEXTS.CROSS}
												onClick={() => crossClickCallback(selectedCase)}
											/>
										</div>
									)
								}
								{!!errorMessage && !showErrorInResults && <div classname="searchBoxWrapper__error">{errorMessage}</div>}
							</Form.Field>
						</Form.Group>
					</Form>
					{showResults && <ul className="searchBoxWrapper__results">
						{!!resultsHeading &&
							<label className="searchBoxWrapper__results__label">{resultsHeading}</label>
						}
						{!!errorMessage &&
							<p className="searchBoxWrapper__results__noResults">{errorMessage}</p>
						}
						{
							!errorMessage && !!searchResults.length && searchResults.map((item, index) => {
								if (item?.alreadySelectedAndSelf?.isAlreadySelected) {
									return (
										<li key={item.id} className="searchBoxWrapper__results__item searchBoxWrapper__results__item__disabled">
											<Tooltip
												id={item.id}
												className={"searchBoxWrapper__results__tooltip"}
												position="top"
												delayHide={0}
												icon={
													renderResult(item)
												}
											>
												<p className="searchBoxWrapper__results__tooltip__txt">
													<Image src={warningIcon} />
													<span>{item.alreadySelectedAndSelf.message}</span>
												</p>
											</Tooltip>
										</li>
									)
								}
								else {
									return (
										<li key={item.id} className="searchBoxWrapper__results__item" onClick={() => this.onResultClick(item)}>
											{renderResult(item)}
										</li>
									)
								}
							})
						}
					</ul>}
				</OutsideClickHandler>
			</div>
		);
	}
};

// PropTypes
SearchBox.propTypes = {
	handleOnSearch: PropTypes.func,
	handleOnChange: PropTypes.func,
	handleOnClick: PropTypes.func,
	handleOnResultClick: PropTypes.func,
	validateInput: PropTypes.func,
	label: PropTypes.string,
	placeHolder: PropTypes.string,
	showEnterIcon: PropTypes.bool,
	enterIcon: PropTypes.element,
	className: PropTypes.string,
	handleOutsideClick: PropTypes.func,
	noResultsMessage: PropTypes.string,
	tooManyResultsMessage: PropTypes.string,
	searchResults: PropTypes.array,
	renderResult: PropTypes.func.isRequired,
	resultsHeading: PropTypes.string,
	showErrorInResults: PropTypes.bool,
	maxLength: PropTypes.number,
	totalCount: PropTypes.number,
	maximumResults: PropTypes.number,
	isSearching: PropTypes.bool,
	tooltipMessage: PropTypes.string,
};

SearchBox.defaultProps = {
	handleOnSearch: () => { },
	handleOnChange: () => { },
	handleOnClick: () => { },
	handleOnResultClick: () => { },
	validateInput: () => { },
	label: null,
	hideLabel: false,
	selectedCase: {},
	placeHolder: "",
	showEnterIcon: true,
	enterIcon: SearchIcon,
	className: "",
	noResultsMessage: "",
	tooManyResultsMessage: "",
	handleOutsideClick: () => { },
	searchResults: [],
	resultsHeading: "",
	tooltipMessage: "",
	showErrorInResults: true,
	maxLength: 1000,
	totalCount: 0,
	maximumResults: 0,
	isSearching: false,
};

export default SearchBox;