import React from 'react';
import { OverlayWrapper } from "containers/Docviz/Docviz";
import { getmyCurrentLocation } from 'utils/helpers/helpers';
import {
    PAGES,
    DTMRULE,
    SCREEN_NAMES,
    MATERIAL_STATUS,
} from "utils/analytics/analytics_constants";
import { OverlayViewSubject, SUBSCRIBERS } from '@kdshared/docviz';
import ANALYTICS from "utils/analytics/analytics";
import { triggerOverlayDownloadAnalytics } from 'utils/analytics/utils_analytics';

class DocvizOverlayWrapper extends React.PureComponent {
    constructor(props) {
        super(props)
        this.overLayViewSubscriber = null;
    }
    triggerOverlayDocvizAnalytics = (doc, dtmRule) => {
        const {
            kpDocvizAnalyticsData: {
                id,
                category,
                paRecommended,
            },
            doc: {
                kpId
            }
        } = this.props;
        const {
            PA_RECOMMENDED,
            NOT_PA_RECOMMENDED
        } = MATERIAL_STATUS;

        const { KB_CREATION, KB_CONSUMPTION, CP_CREATION, CASE_PAGE } = PAGES;
        const newAdobeData = {
            KCpage: {
                id: kpId,
            },
            materialPAStatus: paRecommended ? PA_RECOMMENDED : NOT_PA_RECOMMENDED,
            page: {
                pageInfo: {
                    pageName: category,
                    pageURL: getmyCurrentLocation()
                },
            },
            resultType: SCREEN_NAMES.KP,
            search: {
                searchdocvizTerm: doc?.searchTerm
            },
            ...doc,
        };
        if (category === KB_CREATION || category === KB_CONSUMPTION) {
            newAdobeData.caseID = id;
        } else if (category === CP_CREATION || category === CASE_PAGE) {
            newAdobeData.collection = { ID: id }
        }
        ANALYTICS.kc.sendEventData(newAdobeData, dtmRule);
    };
    triggerAnalyticsOverlay = () => {
        const {
            kpDocvizAnalyticsData: {
                id,
                category,
                paRecommended,
            },
            doc: {
                kpId
            }
        } = this.props;
        const { CP_CREATION, CASE_PAGE } = PAGES;
        triggerOverlayDownloadAnalytics({
            id: id,
            kpid: kpId,
            isCases: category === CP_CREATION || category === CASE_PAGE,
            paRecommended,
            pageName: category,
            primaryCategory: category,
        });
    }
    componentDidMount() {
        const {
            DOCVIZ_OVERLAY_CLOSES,
            DOCVIZ_SLIDE_DOWN_ARROW,
            DOCVIZ_SLIDE_UP_ARROW,
            DOCVIZ_SLIDE_SEARCH
        } = DTMRULE;
        const { getKPFile } = this.props
        this.overLayViewSubscriber = OverlayViewSubject.subscribe(action => {
            switch (action.type) {
                case SUBSCRIBERS.SEARCH_CLOSE_CLICK:
                    this.triggerOverlayDocvizAnalytics(action?.doc, DOCVIZ_OVERLAY_CLOSES);
                    break;
                case SUBSCRIBERS.DOWN_CLICK:
                    this.triggerOverlayDocvizAnalytics(action?.doc, DOCVIZ_SLIDE_DOWN_ARROW);
                    break;
                case SUBSCRIBERS.UP_CLICK:
                    this.triggerOverlayDocvizAnalytics(action?.doc, DOCVIZ_SLIDE_UP_ARROW);
                    break;
                case SUBSCRIBERS.SEARCH_CLICK:
                    this.triggerOverlayDocvizAnalytics(action?.doc, DOCVIZ_SLIDE_SEARCH);
                    break;
                default:
                    break;
                case SUBSCRIBERS.OVERLAY_DOWNLOAD_CLICK:
                    getKPFile && getKPFile(action?.doc?.kpId);
                    this.triggerAnalyticsOverlay();
                    break;
            }
        });
    }
    componentWillUnmount() {
        if (this.overLayViewSubscriber) {
            this.overLayViewSubscriber.unsubscribe();
        }
    }
    render() {
        return (
            <OverlayWrapper {...this.props} />
        )
    }
}
export default DocvizOverlayWrapper;