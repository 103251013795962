import React from "react";
import PropTypes from "prop-types";
import { Icon } from 'semantic-ui-react';
// Components
import Image from "components/shared/Image";
// Helpers
import { getFileIcon, getURLWithProtocol, formatTheDate, openURL, stripHtml, noWordsTrimmingInString } from "utils/helpers/helpers";
import { IconListingTeam } from "components/CasesRelated/IconListingTeam/IconListingTeam";
import LABELS from "labels";
import "./KPItem.scss";
import { ThumbsUpDown } from "components/ThumbsUpDown";
import { ConsumptionShare } from "components/ConsumptionShare";
import CONFIG from "config";
import { v4 as uuidv4 } from 'uuid';
import ANALYTICS from "utils/analytics/analytics";
import {
  DTMRULE,
  SCREEN_NAMES,
  MATERIAL_STATUS,
  COPY_COMPONENT,
  TOOLNAME
} from "utils/analytics/analytics_constants";

const KPItem = ({ item, index, icons, previewEnabled, handleFileClick, isLastBeforeShowMore, showThumbsUpDown, isCasePage, finalSuccess, getKPFile }) => {
  const {
    updatedDate,
    contentType: { name: type },
    materialUrl: { address },
    attachment: { fileName, authors },
    title,
    description,
    isURLAdded,
    paRecommended,
    relatedToGTM,
    id
  } = item;
  const { CP_UI_DATE_FORMAT } = CONFIG;
  const linkType = isURLAdded === '1' && address ? 'link' : fileName;
  const material = linkType === 'link' ? address : fileName;

  const formattedDate = !!updatedDate ? formatTheDate(updatedDate, CP_UI_DATE_FORMAT) : "";

  const handleThumbnailClick = (event, item) => {
    if (handleFileClick) {
      event.stopPropagation();
      if (linkType === 'link') {
        openURL(getURLWithProtocol(material), true);
      } else {
        handleFileClick(event, item);
      }
    }
  };
  const {
    KP: {
      AUTHOR_LISTING_LABEL
    },
    EMAIL: { KP_BODY, DESCRIPTION_HEADING },
    MAILTO_BODY_BROWSER_RESTRICTED_CHARLIMIT,
    ELLIPSIS
  } = LABELS;

  const getShareViaEmailBody = () => {
    let formattedBody = description.length > MAILTO_BODY_BROWSER_RESTRICTED_CHARLIMIT ? stripHtml(noWordsTrimmingInString(description, MAILTO_BODY_BROWSER_RESTRICTED_CHARLIMIT) + ELLIPSIS) : stripHtml(description);
    let bodyText;
    bodyText = `${KP_BODY} \n ${window.location.origin}/kp/${id}`;
    bodyText += `\n\n ${DESCRIPTION_HEADING} \n ${formattedBody}`;
    return bodyText;
  }

  const prepareShareAnalytics = (paRecommendedCount) => {
    const { PA_RECOMMENDED, NOT_PA_RECOMMENDED } = MATERIAL_STATUS;
    const { KP: displayKP } = SCREEN_NAMES;

    let newAdobeData = {
      materialPAStatus: paRecommendedCount ? PA_RECOMMENDED : NOT_PA_RECOMMENDED, //evar14
      displayMode: displayKP, //evar10
      resultType: displayKP, //evar6
      KCpage: {
        id //evar123
      }
    };

    return newAdobeData;
  }
  const copyLinkAnalytics = (paRecommendedCount) => {
    let newAdobeData = prepareShareAnalytics(paRecommendedCount);
    const {
      MY_NAVIGATOR: {
        ENTITY_TYPES: { KP }
      }
    } = LABELS;

    const { KP_LINK } = COPY_COMPONENT;
    newAdobeData.copyTrigger = KP; //evar44
    newAdobeData.copyComponent = KP_LINK;  //evar115           

    ANALYTICS.kc.sendEventData(newAdobeData, DTMRULE.EVENT55_COPY_LINK, isCasePage ? TOOLNAME?.CASE_PAGE : TOOLNAME?.CONTRIBUTE_KNOWLEDGE);
  }

  const shareViaEmailAnalytics = (paRecommendedCount) => {
    let newAdobeData = prepareShareAnalytics(paRecommendedCount);
    ANALYTICS.kc.sendEventData(newAdobeData, DTMRULE.EVENT16_SHAREVIAEMAIL, isCasePage ? TOOLNAME?.CASE_PAGE : TOOLNAME?.CONTRIBUTE_KNOWLEDGE);
    console.log('hh');
  }

  return (
    <div className="kpitem">
      <div className="kpitem__content">
        {!!updatedDate || !!type ? (
          <div className="kpitem__date-type">
            <div>
              {!!type &&
                <div className="kpitem__date-type__type" title={type}>
                  <Icon className={`kpitem__pastar ${!paRecommended ? 'displaynone' : ''}`} name='star' />
                  {type}
                </div>}
              {!!formattedDate && <div className="kpitem__date-type__date">
                {formattedDate}
              </div>}

            </div>
          </div>
        ) : null}
        <div className="kpitem__material">
          <div className="kpitem__material__subtitle" title={title}>{title}</div>
          <div className="kpitem__material__content">
            <Image
              onClick={(event) => handleThumbnailClick(event, item)}
              className={previewEnabled ? "kpitem__material__icon" : "kpitem__material__icon kpitem__material__icon--disabled"}
              src={getFileIcon(linkType)}
              alt="icon" />
            {
              linkType === "link" ?
                <a onClick={e => e.stopPropagation()} href={getURLWithProtocol(material)} rel="noopener noreferrer" target="__blank" className="kpitem__material__name">{material}</a>
                :
                <div className={previewEnabled ? "kpitem__material__name" : "kpitem__material__name kpitem__material__name--disabled"} onClick={(event) => handleFileClick(event, item)} title={material}>{material}</div>
            }
          </div>
        </div>

        <div className={`kpitem__authours`}>
          <IconListingTeam
            heading={AUTHOR_LISTING_LABEL}
            hrIds={{ ids: authors }}
            onlyNonAlumni
            showLabelAtTop
            limit={3}
          />
        </div>

        <div className="kpitem__ctaswrap">
          <div className="kpitem__ctas">
            {
              icons.map((icon, idx) => {
                let { icon: img, link, disabled, hidden, show, callback, className } = icon;
                let doRender = (show || typeof show === 'undefined') ? true : false;
                return (
                  doRender && (
                    link ?
                      (
                        <a
                          key={uuidv4()}
                          href={link}
                          className={`kpitem__ctas__inner ${className ? className : ''} ${disabled ? "kpitem__ctasdisabled" : ""
                            } ${hidden ? "hidevisibility nopointer" : ""}`}
                          target="_blank"
                          onClick={(event) => callback(event, item)}
                          rel="noopener noreferrer"
                        >
                          {img}
                        </a>
                      )
                      :
                      (
                        <button
                          key={uuidv4()}
                          className={`kpitem__ctas__inner ${className ? className : ''} ${disabled ? "kpitem__ctasdisabled" : ""
                            } ${hidden ? "hidevisibility nopointer" : ""}`}
                          onClick={(event) => callback(event, item)}
                          disabled={disabled}
                        >
                          {img}
                        </button>
                      )
                  )
                )
              })
            }
            <div class="shareWrapper">
              <ConsumptionShare
                className="csHeader__images--share kb--share"
                emailSubject={title}
                isPositionTop={isLastBeforeShowMore}
                copyURL={`${window.location.origin}/kp/${id}`}
                emailBody={getShareViaEmailBody()}
                copyURLAnalytics={copyLinkAnalytics}
                paRecommendedCount={paRecommended}
                shareviaEmailAnalytics={shareViaEmailAnalytics}
              />
            </div>
          </div>
          {
            !!showThumbsUpDown && relatedToGTM &&
            <div className="kpitem__thumbs">
              <ThumbsUpDown
                finalSuccess={finalSuccess}
              />
            </div>
          }
        </div>
      </div>
    </div >
  );
}

KPItem.propTypes = {
  item: PropTypes.object.isRequired,
  icons: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.element,
    callBack: PropTypes.func,
    disabled: PropTypes.bool,
    hidden: PropTypes.bool,
    show: PropTypes.bool, // different from hidden, it does not put the element in the DOM
    link: PropTypes.string,
    className: PropTypes.string,
  })),
  index: PropTypes.number.isRequired,
  handleFileClick: PropTypes.func,
  isLastBeforeShowMore: PropTypes.bool,
  previewEnabled: PropTypes.bool,
  isCasePage: PropTypes.bool,
};

KPItem.defaultProps = {
  icons: [],
  previewEnabled: false,
  isLastBeforeShowMore: false,
  showThumbsUpDown: true,
  isCasePage: false,
  handleFileClick: () => { },
};

export default React.memo(KPItem);
