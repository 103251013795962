import CONFIG from 'config';
import Hashes from 'jshashes';
import { datadogRum } from '@datadog/browser-rum';
import { isUserAuthenticated, getUserEmail, getOktaExpirations } from 'utils/auth/auth';

const {
  DD: {
    AD,
    CT,
  },
  applicationName,
  ENVIRONMENT_MAIN,
  KNOWLEDGE_CONTRIBUTE_VERSION
} = CONFIG

let retryCount = 0;
export const setGlobalRumVars = async () => {
  let email = 'unknown';
  if (isUserAuthenticated()) {
    email = await getUserEmail();
  }
  const [oktaAccessExpiration, oktaIdExpiration] = getOktaExpirations();
  if (email) {
    const staffSystemId = new Hashes.SHA256().hex(email);
    datadogRum.setGlobalContextProperty('user', {
      staffSystemId,
      principal: email,
      oktaAccessExpiration: oktaAccessExpiration || 'empty',
      oktaIdExpiration: oktaIdExpiration || 'empty'
    });
  } else if (retryCount < 10) {
    retryCount += 1;
    setTimeout(setGlobalRumVars, 1000);
  }
};

if (ENVIRONMENT_MAIN !== 'local') {
  datadogRum.init({
    applicationId: AD,
    clientToken: CT,
    site: 'datadoghq.com',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 30,    
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    service: applicationName,
    env: ENVIRONMENT_MAIN,
    version: KNOWLEDGE_CONTRIBUTE_VERSION
  });

  setGlobalRumVars();
}
