// Constant
import { KNOWLEDGE_ITEMS } from "redux/constants";

// ------------------ get KPs from API -------------------------
export const getAssociatedKPsPending = () => ({
    type: KNOWLEDGE_ITEMS.GET_ASSOCIATED_KNOWLEDGE_PAGES_PENDING
});

export const getAssociatedKPsSuccess = (pages) => ({
    type: KNOWLEDGE_ITEMS.GET_ASSOCIATED_KNOWLEDGE_PAGES_SUCCESS,
    payload: { pages }
});

export const clearAssociatedKPs = () => ({
    type: KNOWLEDGE_ITEMS.CLEAR_ASSOCIATED_KNOWLEDGE_PAGES
});

export const getAssociatedKPsFailure = (error) => ({
    type: KNOWLEDGE_ITEMS.GET_ASSOCIATED_KNOWLEDGE_PAGES_FAILURE,
    payload: { error: error?.toString() }
});

// ------------- Add more KP to list --------------
export const saveAssociatedKPPending = () => ({
    type: KNOWLEDGE_ITEMS.SAVE_ASSOCIATED_KP_PENDING
});

export const saveAssociatedKPSuccess = (kpdata) => ({
    type: KNOWLEDGE_ITEMS.SAVE_ASSOCIATED_KP_SUCCESS,
    payload: { kpdata }
});

export const saveAssociatedKPFailure = (error) => ({
    type: KNOWLEDGE_ITEMS.SAVE_ASSOCIATED_KP_FAILURE,
    payload: { error: error?.toString() }
});

// ---------------reorder the kp list -------------
export const sortAssociatedKPsPending = () => ({
    type: KNOWLEDGE_ITEMS.REORDER_ASSOCIATED_KPS_PENDING
});

export const sortAssociatedKPsSuccess = (pages) => ({
    type: KNOWLEDGE_ITEMS.REORDER_ASSOCIATED_KPS_SUCCESS,
    payload: { pages }
});

export const sortAssociatedKPsFailure = (error) => ({
    type: KNOWLEDGE_ITEMS.REORDER_ASSOCIATED_KPS_FAILURE,
    payload: { error: error?.toString() }
});

