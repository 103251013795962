import React from "react";
import PropTypes from "prop-types";
import Image from "components/shared/Image";
import { Tooltip } from "components/shared/Tooltip";
import "./ImageWithToolip.scss";

export const ImageWithToolip = ({ id, toolTipMsg, imgIcon, imgClassName, toolTipClass }) => {
    return (
        <>
            {toolTipMsg ? (
                <Tooltip
                    id={id}
                    position="top"
                    icon={<Image
                        className={`${imgClassName} polyHierarchi`}
                        src={imgIcon}
                        data-tip
                        data-for={id}
                        alt="Poly Hierarchi"
                    ></Image>}
                    delayHide={0}
                    data-multiline={true}
                    className={toolTipClass}
                >
                    {toolTipMsg}
                </Tooltip>
            ) : (
                <Image
                    className={`${imgClassName} polyHierarchi imageIcon`}
                    src={imgIcon}
                    data-tip
                    data-for={id}
                    alt="Poly Hierarchi"
                ></Image>
            )}
        </>

    );
};

ImageWithToolip.propTypes = {
    name: PropTypes.string,
    id: PropTypes.string
};
ImageWithToolip.defaultProps = {
    name: ''
};
