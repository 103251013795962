/*
 * you can use this component like this <Counter />
 * also you need inject props
 */
// React
import React from "react";
// Prop types
import PropTypes from "prop-types";
// Css
import "./Counter.scss";

/**
 * @desc return the character count
 * @returns html
 */
const Counter = ({ inputLength = 0, maxLength = 0, style }) => (
  <span data-testid="counter" className="counter" style={style}>
    <span>{inputLength}</span>
    <span>/</span>
    <span>{maxLength}</span>
  </span>
);
// Export Counter as default
export default Counter;
// Counter Prop types
Counter.propTypes = {
  inputLength: PropTypes.number,
  maxLength: PropTypes.number,
  style: PropTypes.shape({})
};
