/*
 * Use this component you need the prerequisite
 * react-bootstrap bootstrap npm modules
 * install these npm modules with npm install --save and modules name
 * after installation of dependencies
 * you can use this component like this <ImagePicker />
 * also you need inject required props initialState, onEditorStateChange
 * and optional defaultState
 */

// React
import React from "react";
// PropTypes
import PropTypes from "prop-types";
// React-Bootstrap
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
// Css
import "./ImagePicker.scss";

/**
 * @desc return the image selector with multiple image
 * @returns html
 */
const ImagePicker = ({ defaultState, images = [], onImageSelect }) => {
  // Local constant
  const [imageState, setImageState] = React.useState(defaultState || "");

  // set the default state
  React.useEffect(() => {
    // set the default image name
    setImageState(defaultState);
  }, [defaultState]);

  /**
   * @desc handle the image click
   * @memberof ImagePicker
   * @param {Object} selectedImage - selected image details
   * @return no return
   */
  const handleClick = selectedImage => {
    // update the imageSelect state
    setImageState(selectedImage);
    // send to the parent function
    onImageSelect(selectedImage);
  };
  /**
   * @desc handle class name
   * @memberof ImagePicker
   * @param {String} imageId - image id
   * @return {String} updated class names
   */
  const handleClassName = image =>
    imageState === image
      ? "picker__image picker__image--active"
      : "picker__image";

  // Return Image Picker Elements
  return (
    <Card data-testid="image-picker" className="picker">
      <Row className="picker__row">
        {images &&
          images.length > 0 &&
          images.map((image, ind) => (
            <Col
              className="picker__col"
              md={3}
              lg={3}
              sm={3}
              xl={3}
              xs={3}
              key={`image-picker-${ind}`}
            >
              <Card.Img
                as={Image}
                className={handleClassName(image.name)}
                src={image.value}
                alt={image.name}
                rounded
                onClick={() => handleClick(image.name)}
              />
            </Col>
          ))}
        {(!images || images.length === 0) && "Provide me Images"}
      </Row>
    </Card>
  );
};
// Export ImagePicker as default
export default ImagePicker;

// PropTypes
ImagePicker.propTypes = {
  defaultState: PropTypes.string,
  images: PropTypes.arrayOf(
    PropTypes.shape({ alt: PropTypes.string, src: PropTypes.string })
  ).isRequired,
  onImageSelect: PropTypes.func.isRequired
};
