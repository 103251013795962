import {
  ERROR,
  INFO,
  SUCCESS,
  WARNING,
  DISMISS_NOTIFICATIONS
} from 'redux/constants';
export const name = 'notification';
const initialState = {
  mode: '',
  title: '',
  message: '',
  autoclose: false,
  timestamp: ''
};

export const selectors = {
  getMode: state => state[name].mode,
  getTitle: state => state[name].title,
  getMessage: state => state[name].message,
  getAutoClose: state => state[name].autoclose,
  getTimestamp: state => state[name].timestamp
};

export function reducer(state = initialState, action) {
  const { payload = {} } = action;
  const { title, message } = payload;
  switch (action.type) {
    case ERROR:
      return {
        ...state,
        mode: 'error',
        title: title,
        message: message,
        autoclose: false,
        timestamp: new Date().toString()
      };
    case INFO:
      return {
        ...state,
        mode: 'info',
        title: title,
        message: message,
        autoclose: true,
        timestamp: new Date().toString()
      };
    case SUCCESS:
      return {
        ...state,
        mode: 'success',
        title: title,
        message: message,
        autoclose: true,
        timestamp: new Date().toString()
      };
    case WARNING:
      return {
        ...state,
        mode: 'warning',
        title: title,
        message: message,
        autoclose: false,
        timestamp: new Date().toString()
      };
    case DISMISS_NOTIFICATIONS:
      return {
        ...state,
        mode: 'dismissNotifications'
      };
    default:
      return state;
  }
}
