import React, { useState, useRef, useEffect } from "react";
import { Tooltip } from "components/shared/Tooltip";
import { TreeViewer } from "components/TreeViewer";
import "./SLHierarchicalDropdown.scss";

export const SLHierarchicalDropdown = (props) => {
  const [show, setOpen] = useState(false);
  const [selectedObj, setSelectedObj] = useState({ id: "", name: "" });
  const {
    label,
    placeholder,
    slectedValue,
    onChange,
    className,
    tooltipHtml,
  } = props;
  const wrapperRef = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (slectedValue && Object.keys(slectedValue).length > 0) {
      const obj = { id: slectedValue.id, name: slectedValue.name };
      setSelectedObj(obj);
    }
  }, [slectedValue]);

  const handleOutsideClick = (e) => {
    if (wrapperRef.current.contains(e.target)) {
      return;
    }
    setOpen(false);
  };

  const onDropdownClick = () => {
    setOpen(!show);
  };

  const handleTreeViewerClick = (selectedNode) => {
    const obj = {
      name: selectedNode.name,
      id: selectedNode.id,
    };
    setSelectedObj(obj);
    onChange({ ...obj });
    setOpen(!show);
  };

  return (
    <>
      <label className="slcustomize__label">{label}</label>
      {tooltipHtml && (
        <Tooltip id="hierarical-dropdown" position="right">
          {tooltipHtml}
        </Tooltip>
      )}
      <div
        className={`slcustomize slcustomize__dropdown ${className ? className : ""}`}
        ref={wrapperRef}
      >
        <span className="slcustomize__dropdowntxt" onClick={onDropdownClick}>
          {selectedObj.name ? selectedObj.name : placeholder}
          <span className="slcustomize__dropdownarrow"></span>
        </span>
        <div
          className={`slcustomize__dropdownlist ${show ? "slcustomize__dropdownshow" : ""
            }`}
        >
          <TreeViewer onClick={handleTreeViewerClick} selectedObj={selectedObj} />
        </div>
      </div>
    </>
  );
};
