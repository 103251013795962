import React from 'react';
import { Card } from 'semantic-ui-react';
import ContactCard from 'components/shared/ContactCard/ContactCard';
import './CardsLayout.scss';
import { ShowMore } from 'components/shared/ShowMore_Less/ShowMore';
import { getConcatNames } from 'utils/helpers/helpers';
import { v4 as uuidv4 } from 'uuid';

export class CardsLayout extends React.PureComponent {
  render() {
    const { contacts = {}, cardClickCallback, tabIdentifier } = this.props;
    return (
      <>
        <ShowMore height={350} >
          <Card.Group
            itemsPerRow={4}
            className="cardslayout__wrapper"
          >
            {Object.entries(contacts).length > 0 &&
              Object.values(contacts).map((contact = {}, index) => {
                let { id, preferredLastName, preferredFirstName, businessTitle, hostOfficeLocation, alumni, workEmail, picture } = contact;
                return (
                  contact && Object.entries(contact).length > 0 && (
                    <ContactCard
                      key={uuidv4()}
                      picture={picture}
                      displayName={getConcatNames(preferredFirstName, preferredLastName)}
                      businessTitle={businessTitle}
                      hostOfficeLocation={hostOfficeLocation}
                      id={id}
                      alumni={alumni}
                      tabIdentifier={tabIdentifier}
                      workEmail={workEmail}
                      cardClickCallback={cardClickCallback}

                    ></ContactCard>
                  )
                );
              })}
          </Card.Group>

        </ShowMore>
      </>
    );
  }
}
