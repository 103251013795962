import {
  UPLOAD_S3,
} from 'redux/constants';
import { doAxiosRequest } from 'config-axios';
import { isResponseOk } from "utils/helpers/helpers";
import {
  getUploadPending,
  getUploadSuccess,
  getUploadFailure,
  fetchPresignurlPending,
  fetchPresignurlFailure,
} from './upload.actions'

import CONFIG from 'config';
import { moduleFailure } from 'redux/global.action';
import { put, takeLatest, call, delay } from '@redux-saga/core/effects';
import { checkFileProcessing, kpMetagenAIProgress } from '../genai/genai.actions';

function* fileUploadtoS3(action) {
  const { payload: { file, signedRequest, requestId } } = action;
  try {
    yield put(getUploadPending({
      file: action.file,
      uploadLoader: true
    }));
    const response = yield call(doAxiosRequest, {
      method: 'put',
      endpoint: signedRequest,
      file: file,
      headers: {
        'Content-Type': file.type,
        'customAuthHeader': true,
        'x-api-key': CONFIG.XA
      }
    });
    if (isResponseOk(response)) {
      try {
        yield put(getUploadSuccess({
          file: file,
          success: response,
          uploadLoader: false
        }));

        if (CONFIG.IS_GENAI_SUGGESTION_ENABLED) {
          yield put(kpMetagenAIProgress());
          yield delay(CONFIG.META_GENAI_POLL_DELAY);
          yield put(checkFileProcessing(requestId, file.name)); // Dispatch the new action
        }

      } catch (error) {
        yield put(getUploadFailure({
          file: file,
          uploadLoader: false,
          error: error.toString()
        }));
      }
    }
  } catch (error) {
    yield put(fetchPresignurlFailure(error));

  }
}

export function* uploadStart(action) {
  const { payload: { file, filename, folderId, version } } = action;
  try {
    yield put(fetchPresignurlPending({
      file: file,
      uploadLoader: true
    }));
    const response = yield call(doAxiosRequest, {
      method: 'get',
      endpoint: CONFIG.API_URL.GET_PRESIGNED(filename, folderId, version),
      headers: {
        'x-api-key': CONFIG.XA
      }
    });
    if (response?.signedRequest) {
      yield call(fileUploadtoS3, { payload: { file, signedRequest: response?.signedRequest, requestId: folderId } })
    }
  } catch (error) {
    yield put(fetchPresignurlFailure({
      file: file,
      uploadLoader: false,
      error: error.toString()
    }));

  }
}

export default function* UploadSaga() {
  try {
    yield takeLatest(UPLOAD_S3.GET_UPLOAD_S3, uploadStart);
  } catch (error) {
    yield put(moduleFailure(error, UploadSaga))
  }
}