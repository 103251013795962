///////////// Importing Depencies ///////////
import React from 'react';
import PropTypes from "prop-types";

//////////// Importing Components //////////
import Image from "components/shared/Image";
import { Tooltip } from "components/shared/Tooltip";

//////////// Importing Image Files ////////
import crossIcon from "assets/images/cross-icon.svg";
import userIcon from "assets/images/user.svg";
import AddIcon from "assets/images/plusicon.svg";
import rightIcon from "assets/images/right-icon.svg";

//////////// Importing scss Files /////////
import './PeopleInfoCard.scss'

const PeopleInfoTextSec = ({ preferredDisplayName, businessTitle, id, showBoldCls, initialWidth, hidePeopleTitleTooltip }) => {
    const defaultInitialWidth = initialWidth;
    let dynamictitle = null;
    let titleNameOuterSec = null;
    let style;

    /**
     * Below code is used to show tooltip if businessTitle length is 
     * greater then the display name
     */

    if (preferredDisplayName && businessTitle && preferredDisplayName.length < businessTitle.length) {
        const width = preferredDisplayName?.trim().length ? preferredDisplayName.length : 10;
        style = {
            width: (width * defaultInitialWidth) + 'px'
        }
    } else {
        style = {
            width: 'max-content'
        }
    }

    /**
    * Common func to render both businessTitle and display name 
    */
    const getInnerSection = (dynamictitle) => {
        return (
            <div className="peopleInfoText--main" >
                <div className={`peopleInfoText--name ${showBoldCls ? 'peopleInfoText--name_bold' : ''} `}>{preferredDisplayName?.trim().length ? preferredDisplayName : "Anonymized"}</div>
                {dynamictitle}
            </div >
        )
    }

    dynamictitle = (<div className={`peopleInfoText--title`} style={style}>{businessTitle}</div>)

    titleNameOuterSec = (
        <Tooltip
            id={id}
            position="top"
            icon={
                <>{getInnerSection(dynamictitle)}</>
            }
            delayHide={0}
            data-multiline={true}
            className="customTooltipClass"
            disabled={hidePeopleTitleTooltip ? true : false}
        >
            {businessTitle}
        </Tooltip>
    )

    return titleNameOuterSec
}

/**
 * This component helps is rendering the card for People selector
 * which includes a user profile image, user name, user businessTitle and
 * a cross icon which helps in selecting the user in selecting
 * and deselecting as Author or required KP.
 * @param {showImage:bool, disabledCross: bool, peopleObj: Obj, deleteSelected: func} param0 
 * @returns JSX
 */
const PeopleInfoCard = ({
    showImage,
    disabledCross,
    peopleObj,
    deleteSelected,
    id,
    showAddBtn,
    showBoldCls,
    initialWidth,
    customClass,
    addToSelectedList,
    isSelected,
    showDragDropIcon,
    hidePeopleTitleTooltip
}) => {
    const { preferredDisplayName, picture, businessTitle } = peopleObj;

    return (
        <div className={`peopleInfo_outer ${customClass}`}>
            {showDragDropIcon && (
                <div className='peopleInfo_outer--dragDropHandlerSection'></div>
            )}

            {showImage && (
                <Image src={`${picture ? picture : userIcon}`} alt="user" />
            )}

            <PeopleInfoTextSec
                preferredDisplayName={preferredDisplayName}
                businessTitle={businessTitle}
                id={id}
                showBoldCls={showBoldCls}
                initialWidth={initialWidth}
                hidePeopleTitleTooltip={hidePeopleTitleTooltip} />

            {!disabledCross && (
                <Image
                    className="peopleInfo_outer__crossicon"
                    src={crossIcon}
                    alt="cross"
                    onClick={deleteSelected}
                />
            )}

            {showAddBtn && (
                <Image
                    className="peopleInfo_outer__addicon"
                    src={isSelected ? rightIcon : AddIcon}
                    alt="add"
                    onClick={!isSelected ? addToSelectedList : () => { }}
                />
            )}
        </div>
    )
}

PeopleInfoCard.propTypes = {
    showImage: PropTypes.bool,
    disabledCross: PropTypes.bool,
    peopleObj: PropTypes.shape({
        preferredDisplayName: PropTypes.string,
        picture: PropTypes.string,
        businessTitle: PropTypes.string
    }),
    deleteSelected: PropTypes.func,
    id: PropTypes.string,
    customClass: PropTypes.string,
    addToSelectedList: PropTypes.func,
    isSelected: PropTypes.bool,
};

PeopleInfoCard.defaultProps = {
    deleteSelected: () => { },
    showImage: false,
    disabledCross: false,
    peopleObj: {},
    id: '',
    customClass: '',
    addToSelectedList: () => { },
    isSelected: false,
    showDragDropIcon: false,
    hidePeopleTitleTooltip: false
}

export { PeopleInfoCard }