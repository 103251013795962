/*
 * Use this component you need the prerequisite
 * draft-js, react-draft-wysiwyg, draftjs-to-html, html-to-draftjs npm modules
 * install these npm modules with npm install --save and modules name
 * after installation of dependencies
 * you can use this component like this <RichEditor />
 * also you need inject required props initialState, onEditorStateChange
 */

// React
import React, { useCallback } from "react";
// PropTypes
import PropTypes from "prop-types";
// Editor Components
import { ContentState, EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
// Convertor
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
// component
import Counter from "components/shared/Counter";
// Config
import CONFIG from "./RichEditor.config";
// Css
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./RichEditor.scss";

import {
  formattedDescriptionFromStartAndEnd,
  trimDescriptionBlocksFromStartAndEnd
} from "utils/helpers/helpers";

/**
 * @desc return text editor with custom configuration
 * @param {String} initialState default visible string
 * @param {Function} handleOnblur
 * @returns html
 */
const RichEditor = ({ initialState, updatedKey, handleOnblur, charLimit, formatDescription = false, onDescriptionChangeHandler, customClass, overRideSettings = {} }) => {
  const [editorState, setEditorState] = React.useState(
    initialState
      ? EditorState.createWithContent(
        ContentState.createFromBlockArray(
          htmlToDraft(initialState).contentBlocks
        )
      )
      : EditorState.createEmpty()
  );


  /**
  * @desc update the current editor state
  * @memberof RichEditor
  * @return no 
  */
  const updateEditorStateWithFocusToEnd = useCallback(
    () => {
      if (!!initialState) {
        return setEditorState(EditorState.createWithContent(
          ContentState.createFromBlockArray(
            htmlToDraft(initialState).contentBlocks
          ))), [initialState]
      }
    }
  );

  // set the initial state
  React.useEffect(() => {
    // check updated key
    if (updatedKey) {
      // update the editor state
      updateEditorStateWithFocusToEnd();
    }
  }, [initialState, updatedKey]);


  /**
   * @desc get the length of the editor text
   * @memberof RichEditor
   * @return editor text length
   */
  const charLength = () =>
    editorState.getCurrentContent().getPlainText("").length;

  /**
   * @desc check condition
   * @memberof RichEditor
   * @return {String} handled | not-handled
   */
  const handleCondition = condition => (condition ? "handled" : "not-handled");

  /**
   * @desc update the editor text
   * @memberof RichEditor
   * @param {String} editorState - updated string
   * @return no return
   */
  const handleChange = eState => {
    // Update state on every key down
    setEditorState(eState);

    if (onDescriptionChangeHandler && formatDescription) {
      onDescriptionChangeHandler(eState.getCurrentContent().getPlainText("").length)
    }
  };

  const onBlur = () => {
    // Send the updated string to parent component
    try {
      const prevEditorState = editorState.getCurrentContent();

      let rawConvertedData = convertToRaw(prevEditorState);

      let updatedDescriptionBlocks = trimDescriptionBlocksFromStartAndEnd(rawConvertedData.blocks)

      rawConvertedData.blocks = updatedDescriptionBlocks;

      let data = draftToHtml(rawConvertedData);
      if (formatDescription) {
        data = formattedDescriptionFromStartAndEnd(data, prevEditorState.getPlainText(""));

        let eState = EditorState.createWithContent(
          ContentState.createFromBlockArray(
            htmlToDraft(data).contentBlocks
          ));

        setEditorState(eState);
        const eStatePlaneText = eState.getCurrentContent().getPlainText("");
        let descriptionLength = eStatePlaneText.length;
        let obj = {
          descriptionLength,
          data
        }
        handleOnblur(obj);
      } else {
        handleOnblur(data);
      }
    } catch (error) {
      console.log('error ', error)
    }
  }

  /**
   * @desc check max length then update
   * @memberof RichEditor
   * @return {String} handled | not-handled
   */
  const handleBeforeInput = () =>
    handleCondition(charLength() > charLimit - 1);

  /**
   * @desc check max length then update
   * @memberof RichEditor
   * @param {String} text
   * @return {Boolean} handled | not-handled
   */
  const handlePastedText = text =>
    handleCondition(charLength() + text.length > charLimit);

  /**
   * @desc check user enter event/key
   * @memberof RichEditor
   * @param {String} command
   * @return {Boolean} handled | not-handled
   */
  const handleKeyCommand = command =>
    handleCondition(
      command.toUpperCase().trim() === "SPLIT-BLOCK" &&
      charLength() > charLimit - 1
    );

  // Return Editor component with custom configuration
  return (
    <React.Fragment>
      <Editor
        placeholder={CONFIG.placeHolder}
        editorClassName="rich__editor"
        editorState={editorState}
        onEditorStateChange={handleChange}
        toolbar={{
          options: CONFIG.options,
          ...CONFIG.toolBarOptions
        }}
        toolbarClassName="rich__toolbar"
        wrapperClassName="rich"
        localization={{ locale: "en", translations: CONFIG.editorLabels }}
        handleBeforeInput={handleBeforeInput}
        handlePastedText={handlePastedText}
        handleKeyCommand={handleKeyCommand}
        onBlur={onBlur}
        {...overRideSettings}
      />
      {customClass ? <div className={customClass}>
        <Counter
          inputLength={charLength()}
          maxLength={charLimit}
          style={CONFIG.style}
        />
      </div> :
        <Counter
          inputLength={charLength()}
          maxLength={charLimit}
          style={CONFIG.style}
        />}
    </React.Fragment>
  );
};
// Export Rich Editor as default
export default RichEditor;

// PropTypes
RichEditor.propTypes = {
  initialState: PropTypes.string,
  handleOnblur: PropTypes.func.isRequired,
  updatedKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onDescriptionChangeHandler: PropTypes.func,
  formatDescription: PropTypes.bool
};
