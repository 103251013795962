import React from "react";
import Image from "components/shared/Image";
import notequal from "assets/images/notequal.svg";
import LABELS from "labels";

import './KnowledgeNoResults.scss';

export const KnowledgeNoResults = ({ hideSuggestions, title }) => {
  const {
    MY_NAVIGATOR: {
      NO_RESULT_TITLE,
      NO_RESULT_SUGESSTIONS
    }
  } = LABELS;

  return (
    <div className="know-no-results">
      <div className="know-no-results__not-equal"><Image src={notequal} /></div>
      <div>
        <div className="know-no-results__header">{!!title ? title : NO_RESULT_TITLE}</div>
        {!hideSuggestions && <div
          className="know-no-results__suggestions"
          dangerouslySetInnerHTML={{
            __html: NO_RESULT_SUGESSTIONS,
          }}
        />
        }
      </div>
    </div>
  );
};