import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Form } from "semantic-ui-react";

import ChevronIcon from "assets/images/chevron.png";
import crossIcon from "assets/images/cross-icon.svg";
import Image from "components/shared/Image";
import { TAXONOMY } from "redux/constants";
import { selectors as taxonomySelector } from "redux/reducers/taxonomy/taxonomy_reducer";
import { checkIfNodePresentInObject, getAllNodesIntoArray } from "utils/helpers/helpers";
import { removeDuplicatesObjects, getAllLeafChildren } from "./utils";
import AdvancedDropdown from "components/UISearch/AdvancedDropdown";
import "./MultiSelectDropdown.scss";

class MultiSelectDropdownContainer extends React.Component {
  constructor(props) {
    super(props);
    const prefilldata = this.preFillDataHandler();
    this.state = {
      isSelectorOpen: false,
      selectedRegions: prefilldata
    }
  }

  componentDidMount = () => {
    const { taxonomyName, getTaxonomy } = this.props;
    try {
      // dispatch the action
      taxonomyName.forEach(({ key }) => { getTaxonomy(key) });
    } catch (err) {
      console.error(err);
    }
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.isGlobalSelected) {
      return {
        selectedRegions: []
      }
    }
    return null;
  }

  openSelector = (flag) => {
    this.setState({ isSelectorOpen: flag });
  }

  preFillDataHandler = () => {
    let { preFillData, responseKeyName } = this.props;
    let temp = {};
    preFillData.forEach((ele, index) => {
      let id = ele.id;
      const name = ele.name;

      if (id && name) {
        id = id.replace(/{|}/g, "").toLowerCase();
        const newnode = { [responseKeyName.id]: id, [responseKeyName.name]: name };
        temp = {
          ...temp,
          [id]: newnode
        }
      }

    });
    return temp;
  }

  addSelectedRegion = (item) => {
    const { selectedRegions } = this.state;
    const { responseKeyName, onChange } = this.props;
    const { id, children, name } = responseKeyName;

    if (!(item[children].length)) {
      const isItemAlreadyPresent = checkIfNodePresentInObject(selectedRegions, item[id], responseKeyName);
      if (isItemAlreadyPresent) {
        this.deleteSelectedRegion(item[id]);
      } else {
        //if child
        let saveData = {
          [id]: item[id],
          [name]: item[name]
        };

        let tempObj = {
          ...selectedRegions,
          [item[id]]: saveData
        };
        tempObj = removeDuplicatesObjects(tempObj);
        this.setState({
          selectedRegions: tempObj,
        }, () => {
          onChange(getAllNodesIntoArray(tempObj));
        });
      }
    } else { // parent click
      const allLeafItems = getAllLeafChildren(item, responseKeyName);
      let regionsState = selectedRegions;
      let temp = {};
      let totalItemsPresent = 0;

      for (let eachleaf in allLeafItems) {
        let leafitem = allLeafItems[eachleaf];
        let isItemAlreadyPresent = checkIfNodePresentInObject(selectedRegions, leafitem[id], responseKeyName);
        if (isItemAlreadyPresent) {
          totalItemsPresent++;
        }
        if (!isItemAlreadyPresent) {
          temp = {
            ...temp,
            [leafitem[id]]: leafitem
          };
        }
      }
      if (totalItemsPresent === allLeafItems.length) {
        for (let eachleaf in allLeafItems) {
          let leafitem = allLeafItems[eachleaf];
          delete regionsState[leafitem[id]];
        }
      }

      if (Object.keys(temp).length) {
        let newtempObj = {
          ...selectedRegions,
          ...temp
        };
        newtempObj = removeDuplicatesObjects(newtempObj);
        this.setState({
          selectedRegions: newtempObj
        }, () => {
          onChange(getAllNodesIntoArray(newtempObj));
        });
      } else {
        let tempobj = {
          ...regionsState
        }
        tempobj = removeDuplicatesObjects(tempobj);
        this.setState({
          selectedRegions: tempobj
        }, () => {
          onChange(getAllNodesIntoArray(tempobj));
        });
      }
    }

  }

  deleteSelectedRegion = (id) => {
    const { selectedRegions } = this.state;
    const { responseKeyName, onChange } = this.props;
    let temp = selectedRegions;
    for (let eachitem in temp) {
      if (temp[eachitem][responseKeyName.id] === id) {
        delete temp[eachitem];
      }
    }

    this.setState(
      {
        selectedRegions: temp
      }, () => {
        onChange(getAllNodesIntoArray(temp));
      }
    );
  }


  render() {
    const { isSelectorOpen, selectedRegions } = this.state;
    const { className, taxonomyData, taxonomyName,
      responseKeyName,
      countrySearchCallback,
      countrySearchTermClickCallback
    } = this.props;

    return (
      <>
        <div className={`slctrbase ${className}`}>
          {Object.keys(selectedRegions).length ?
            <>
              <div className="slctrbase__wrapinput">
                <ul>
                  {Object.keys(selectedRegions).map((id) => {
                    return (
                      <li key={id}>
                        <span>{selectedRegions[id][responseKeyName.name]}</span>
                        <Image
                          src={crossIcon}
                          alt="cross"
                          onClick={() => this.deleteSelectedRegion(selectedRegions[id][responseKeyName.id])}
                        />
                      </li>
                    )
                  })
                  }

                </ul>
                <span
                  className={`slctrbase__img`}
                  onClick={() => this.openSelector(true)}
                >
                  <Image src={ChevronIcon} alt="chevron icon" />
                </span>
              </div>
            </> : <>
              <Form.Input
                type="text"
                readOnly
                onClick={() => this.openSelector(true)}
                icon={
                  <span
                    className={`slctrbase__img`}
                    onClick={() => this.openSelector(true)}
                  >
                    <Image src={ChevronIcon} alt="chevron icon" />
                  </span>
                }
              />
            </>
          }


          {isSelectorOpen &&
            <>
              <AdvancedDropdown
                className={className}
                taxonomyName={taxonomyName}
                responseKeyName={responseKeyName}
                selectedRegions={selectedRegions}
                getdata={taxonomyData}
                isOpen={isSelectorOpen}
                setisOpen={this.openSelector}
                addSelectedRegion={this.addSelectedRegion}
                onSearchCallback={countrySearchCallback}
                chosenSearchTermClickCallback={countrySearchTermClickCallback}
              />
            </>
          }
        </div>
      </>

    )
  }
}

MultiSelectDropdownContainer.defaultProps = {
  label: "",
  responseKeyName: {
    id: "Id",
    name: "Name",
    children: "Children"
  },
  className: '',
  taxonomyName: {},
  preFillData: []
};

MultiSelectDropdownContainer.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  taxonomyName: PropTypes.object.isRequired,
  responseKeyName: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  preFillData: PropTypes.array
}

const mapStateToProps = (state) => ({
  taxonomyData: (taxonomyName) => taxonomySelector.getTaxonomyData(state, taxonomyName)
});

const mapDispatchToProps = (dispatch) => ({
  getTaxonomy: (key) => dispatch({ type: TAXONOMY.GET_TAXONOMY, payload: { key } })
});

export { MultiSelectDropdownContainer };

export const MultiSelectDropdown = connect(
  mapStateToProps,
  mapDispatchToProps
)(MultiSelectDropdownContainer);
