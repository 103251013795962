import PAGE_ANALYTICS from "utils/analytics/page_analytics";
import { DTMRULE, TOOLNAME } from "utils/analytics/analytics_constants";
import { getStaffEmailID } from "utils/auth/auth";
export default {
  /**
   *
   * @param {*} Object - having adobeAnalyticsData
   * @param {*} string - having dtmRule
   */

  sendEventData(adobeAnalyticsData, dtmRule = DTMRULE.KB, toolName = TOOLNAME?.CONTRIBUTE_KNOWLEDGE) {
    const staffEmailID = getStaffEmailID();
    const pageInfoObj = { headerInfo: { staffEmailID: staffEmailID, toolName: toolName } };
    adobeAnalyticsData = { ...adobeAnalyticsData, page: { ...adobeAnalyticsData['page'], ...pageInfoObj } }
    PAGE_ANALYTICS.setGlobalProps(adobeAnalyticsData);
    PAGE_ANALYTICS.track(dtmRule);
  },
};
