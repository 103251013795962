import React from 'react';
import PropTypes from 'prop-types';
import './CaseVersionListing.scss';
import moment from 'moment';
import CONFIG from 'config';
import { v4 as uuidv4 } from 'uuid';
import NoCaseHistory from "assets/images/noCaseHistory.svg";
import Image from "components/shared/Image";
import LABELS from 'labels';


export const extractNewDataKeys = (caseHistoryData, FIELD_MAPPING_CASES) => {
  const newDataKeys = {};
  caseHistoryData.forEach(item => {
    const newData = item.NewData || {};
    Object.keys(newData).forEach(key => {
      const label = FIELD_MAPPING_CASES[key.toLowerCase()];
      if (label) {
        newDataKeys[label] = true;
      }
    });
  });
  return Object.keys(newDataKeys);
}
const {
  CASE_HISTORY_MODAL: {
    NO_CASE_HISTORY
  }
} = LABELS;
const CaseVersionListing = ({
  caseHistoryData,
  caseHistoryTableKey
}) => {
  const { CP_UI_DATE_FORMAT, FIELD_MAPPING_CASES } = CONFIG;

  return (
    <div className='caseVersionListing'>
      {!!caseHistoryData && caseHistoryData.length > 0 ? (
        <>
          <div className="caseversionlisting__columns">
            {Object.keys(caseHistoryTableKey).map(field => (
              <div
                key={field}
                className={`caseversionlisting__column caseversionlisting__column--${field.toLowerCase()} 
              ${field.toLowerCase() === 'version' && 'versionlisting__center'}`}
              >
                {caseHistoryTableKey[field]}
              </div>
            ))}
          </div>
          <div className="caseversionlisting__tablewrap">
            {!!caseHistoryData && caseHistoryData.map((data, index) => (
              <div className="caseversionlisting__tablecontent" key={index}>
                <section className="caseversionlisting">
                  <div className="caseversionlisting__value caseversionlisting__value--date">{moment(new Date(data.UpdatedDate)).format(CP_UI_DATE_FORMAT)}</div>

                  <div className="caseversionlisting__value caseversionlisting__value--changed_by">{data.un}</div>

                  <div className='caseversionlisting__value caseversionlisting__value--updatedFields'>
                    {extractNewDataKeys([data], FIELD_MAPPING_CASES).map((label, idx, arr) => (
                      <span key={uuidv4()} className="caseversionlisting__value caseversionlisting__value--updatedFields caseversionlisting__inline">
                        {label}
                        {idx !== arr.length - 1}
                      </span>
                    ))}
                  </div>
                </section>
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <div className="caseversionlisting__columns" >
            {Object.keys(caseHistoryTableKey).map(field => (
              <div
                key={field}
                className={`caseversionlisting__column caseversionlisting__column--${field.toLowerCase()} 
              ${field.toLowerCase() === 'version' && 'versionlisting__center'}`}
              >
                {caseHistoryTableKey[field]}
              </div>
            ))}
          </div>
          <div className="caseversionlisting__message">
            <Image src={NoCaseHistory} />
            <p>{NO_CASE_HISTORY}</p>
          </div>
        </>
      )}
    </div>

  );
};

CaseVersionListing.propTypes = {
  caseHistoryData: PropTypes.array.isRequired,
  caseHistoryTableKey: PropTypes.object.isRequired
};

export default React.memo(CaseVersionListing);
