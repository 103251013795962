import React, { PureComponent } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
// Redux
import { bindActionCreators } from "redux";
import LABELS from "labels";
import Modal from "components/shared/Modal";
import Image from "components/shared/Image";
import { ContactsSection } from '../ContactsSection';
import ContactCard from 'components/shared/ContactCard/ContactCard';
import bigCrossIcon from "assets/images/big-cross-icon.svg";
import { actions as peopleResultsActions } from 'redux/actions/peopleresults/peopleresults_actions';
import { isObjEmpty, areObjectsEqual, getConcatNames } from 'utils/helpers/helpers';
import { Loader } from "components/shared/Loader";
import { v4 as uuidv4 } from 'uuid';

import "./OtherContactsModal.scss"
import withErrorBoundary from 'containers/ErrorBoundary/ErrorBoundary';

const {
	CASE_CONTACTS: {
		ALUMNI_LIST_HEADING,
		NO_CONTACT_AVILABLE,
	},
	IDENTIFIER: {
		CARD_CP_ALUMNI,
	}
} = LABELS;
export class OtherContactsModalDisplay extends PureComponent {

	state = {
		peopleResult: {}
	}

	componentDidMount = async () => {
		this.setPeopleResultAndImage();
	}

	componentDidUpdate = async (prevProps) => {
		const { contactTileData } = this.props;
		const { contactTileData: prevContactTileData } = prevProps;
		if (!!contactTileData && !areObjectsEqual(contactTileData, prevContactTileData)) {
			this.setPeopleResultAndImage();
		}
	}

	setPeopleResultAndImage = async () => {
		const {
			peopleResultsActions: {
				getPeopleDataUsingIDs
			},
			contactTileData
		} = this.props;
		const hrIds = [];
		Array.isArray(contactTileData) && contactTileData.length &&
			contactTileData.forEach((item, index) => {
				const { hrId } = item;
				!!hrId && hrIds.push(hrId);
			});
		if (hrIds.length) {
			let peopleResultTemp = {};
			const peopleData = await getPeopleDataUsingIDs(hrIds.flat(), false);
			Array.isArray(peopleData) && peopleData.length && (peopleData.forEach(async (peopleObj) => {
				const {
					id
				} = peopleObj;
				peopleResultTemp[id] = peopleObj;

				this.setState({
					peopleResult: { ...peopleResultTemp }
				});
			}));
		}
	}

	handleContactClick = ({ hrId, tabIdentifier, cardIdentifier }) => {
		const { caseContactsClickCallback } = this.props;
		caseContactsClickCallback({ hrId, identifier: tabIdentifier, cardIdentifier });
	};

	render() {

		const {
			heading,
			isOpen,
			onRequestClose,
			data,
			contactTileData,
			projectId,
			caseContactsClickCallback,
		} = this.props;

		const {
			peopleResult
		} = this.state;

		const { IDENTIFIER: { CARD_CP_EXECUTING_MDP } } = LABELS;

		return (
			<Modal
				isOpen={isOpen}
				className="ReactModal__Content--othersContacts"
				onRequestClose={onRequestClose}>
				<div className="otherContacts__wrap">
					<section className="otherContacts__header">
						<h4 className="otherContacts__header__heading">{heading}</h4>
						<span onClick={onRequestClose} className="otherContacts__modalcross">
							<Image src={bigCrossIcon} alt="cross" />
						</span>
					</section>
					<section className="otherContacts__data">
						{
							Array.isArray(contactTileData) && !!contactTileData.length && (
								contactTileData.map((item, index) => {
									const { heading, hrId, identifier } = item;
									if (!hrId) return (
										<section className="otherContacts__card">
											<heading className="otherContacts__cardHeading">{heading}</heading>
											<p className="otherContacts__nodata">{NO_CONTACT_AVILABLE}</p>
										</section>)
									else {
										const isPeopleObjEmpty = identifier !== CARD_CP_EXECUTING_MDP ? !isObjEmpty(peopleResult[hrId]) : true;
										if (isPeopleObjEmpty) {
											return (
												<section className="otherContacts__card" key={uuidv4()}>
													<heading className="otherContacts__cardHeading">{heading}</heading>
													<div className="otherContacts__card__innerWrapper">
														{hrId?.map((item) => {
															if (!isObjEmpty(peopleResult[item])) {
																const { businessTitle, preferredLastName, preferredFirstName, workEmail, hostOfficeLocation, alumni, picture, id } = peopleResult[item];
																return <ContactCard
																	key={uuidv4()}
																	heading={heading}
																	id={id}
																	displayName={getConcatNames(preferredFirstName, preferredLastName)}
																	businessTitle={businessTitle}
																	tabIdentifier={identifier}
																	picture={picture}
																	workEmail={workEmail}
																	cardClickCallback={this.handleContactClick}
																	hostOfficeLocation={hostOfficeLocation}
																	alumni={(!!alumni && (alumni === "TRUE" || alumni === "YES")) ? "YES" : "NO"}
																/>
															}
															else
																return (
																	<Loader
																		isVisible={true}
																	/>)
														})
														}
													</div>
												</section>)
										} else
											return (
												<Loader
													isVisible={true}
												/>)
									}
								})
							)
						}
						{Array.isArray(data) && data.length ?
							<div className="otherContacts__contactsSection">
								<ContactsSection
									heading={ALUMNI_LIST_HEADING}
									data={data}
									context="otherContactsModal"
									projectId={projectId}
									caseContactsClickCallback={caseContactsClickCallback}
									identifier={CARD_CP_ALUMNI}
								/>
							</div>
							:
							null
						}
					</section>
				</div>
			</Modal>
		)
	}
};

OtherContactsModalDisplay.propTypes = {
	heading: PropTypes.string.isRequired,
	projectId: PropTypes.string,
	isOpen: PropTypes.bool,
	onRequestClose: PropTypes.func,
	data: PropTypes.array,
	contactTileData: PropTypes.array
};

OtherContactsModalDisplay.defaultProps = {
	isOpen: false,
	onRequestClose: () => { },
	data: [],
	contactTileData: [],
	projectId: ''
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
	peopleResultsActions: bindActionCreators(
		{ ...peopleResultsActions },
		dispatch
	),
});

export const OtherContactsModal = connect(
	mapStateToProps,
	mapDispatchToProps
)(withErrorBoundary(OtherContactsModalDisplay));