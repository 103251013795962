import React, { PureComponent } from "react";
import PropTypes from "prop-types";

// SHARED COMPONENTS
import Image from "components/shared/Image";
import { InfoCard } from "components/shared/InfoCard";

// HELPERS
import { splitToChunks } from "utils/helpers/helpers";

// IMAGES
import bigCrossIcon from "assets/images/big-cross-icon.svg";
import expandIcon from "assets/images/arrow_left_down_right_up.svg";

// SCSS
import "./InfoGrid.scss";

export class InfoGrid extends PureComponent {

  state = {
    collapsed: true
  };

  onCrossIconClick = () => {
    this.setState(prevState => ({
      collapsed: !prevState.collapsed
    }));
  };

  getListBorderClass = (ulIndex, liIndex, dataLength) => {
    const {
      collapsed
    } = this.state;
    const {
      columnPerRow
    } = this.props;
    const isLastColumn = liIndex === columnPerRow - 1;
    const isLastRow = ulIndex === dataLength - 1;
    if (collapsed) {
      return isLastColumn ? "" : "info-grid__li--border-right";
    } else {
      if (isLastColumn) {
        return isLastRow ? "" : "info-grid__li--border-bottom";
      } else {
        return isLastRow ? "info-grid__li--border-right" : "info-grid__li--border-right-bottom";
      }
    }
  }
  getListPaddingClass = (liIndex) => {
    if (liIndex === 0) {
      return "info-grid__li--no-left-padding";
    }
    return "info-grid__li--padding";
  }

  render() {
    const {
      data,
      gridTitle,
      showTitleDataSeparator,
      showCollapseIcon,
      columnPerRow,
    } = this.props;
    const {
      collapsed
    } = this.state;
    const collapseIconPath = collapsed ? expandIcon : bigCrossIcon;
    const renderData = Array.isArray(data) && data.length ? splitToChunks(data, columnPerRow) : [];

    return (
      <section className="info-grid">
        <div className="info-grid__left">
          <div
            className={`info-grid__header ${!!gridTitle ? "info-grid__header--space-between" : "info-grid__header--end"}`}
          >
            {!!gridTitle &&
              <div>
                {gridTitle}
              </div>
            }

          </div>
          {!!showTitleDataSeparator &&
            <span className="info-grid__separator" />
          }
          <div>
            {Array.isArray(renderData) && renderData.length ?
              <>
                {
                  renderData.map((data, ulIndex) => {
                    return (
                      <ul
                        key={`_${ulIndex}`}
                        className={`info-grid__ul 
                        ${ulIndex !== 0 && collapsed
                            ? "displaynone" : ""}
                      `}
                      >
                        {
                          data.map((item, liIndex) => {
                            const {
                              imgPath, showFullImage, imgShape, imgBgColorClass, label, value
                            } = item;
                            return (
                              <li
                                key={`${label}_${liIndex}`}
                                className={`info-grid__li 
                                ${this.getListBorderClass(ulIndex, liIndex, renderData.length)}
                                ${this.getListPaddingClass(liIndex)}
                              `}
                                style={{ maxWidth: `${100 / columnPerRow}%` }} // generate maxWidth of each li based on columnPerRow
                              >
                                <InfoCard
                                  imgPath={imgPath}
                                  imgShape={imgShape}
                                  showFullImage={showFullImage}
                                  imgBgColorClass={imgBgColorClass}
                                  label={label}
                                  value={value}
                                />
                              </li>
                            )
                          }
                          )}
                      </ul>
                    )
                  })
                }
              </>
              : <span className="info-grid--no-data" />
            }
          </div>
        </div>
        {showCollapseIcon &&
          <div
            onClick={() => this.onCrossIconClick()}
            className={`info-grid__toggle ${collapsed ? "info-grid__toggle--bgcolor" : ""}`}
          >
            <Image src={collapseIconPath}
              className={`info-grid__toggle-icon ${collapsed ? "info-grid__toggle-icon--expand" : "info-grid__toggle-icon--collapse"}`}
              alt="collapse"
            />
          </div>
        }

      </section>
    );
  }
};

InfoGrid.propTypes = {
  data: PropTypes.array.isRequired,
  gridTitle: PropTypes.element,
  showTitleDataSeparator: PropTypes.bool,
  showCollapseIcon: PropTypes.bool,
  columnPerRow: PropTypes.number
};

InfoGrid.defaultProps = {
  showTitleDataSeparator: true,
  showCollapseIcon: true,
  columnPerRow: 3
};