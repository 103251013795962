import React from "react";
import PropTypes from "prop-types";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import Image from "components/shared/Image";
import bundleIcon from "assets/images/collection.svg";
import LABELS from "labels";
import { getFileIcon, formatTheDate } from "utils/helpers/helpers";

import "./EntityItem.scss";
import CONFIG from "config";

const EntityItem = ({ index, item }) => {
  const {
    title,
    description,
    type,
    updatedDate,
    ipaTags, fpaTags, bcgInternals,
    fileName,
    url
  } = item;
  const {
    MY_NAVIGATOR: {
      ENTITY_TYPES: {
        KB,
        TCS
      }
    }
  } = LABELS;
  const { CP_UI_DATE_FORMAT } = CONFIG;
  let allSectorTags = { ...fpaTags, ...ipaTags, ...bcgInternals };
  const formattedDate = !!updatedDate ? formatTheDate(updatedDate, CP_UI_DATE_FORMAT) : "";

  const getTagsHtml = (alltags) => {
    let htmlContent = [];
    let paClass = '';
    if (Object.keys(alltags).length) {
      Object.keys(alltags).forEach((key) => {
        paClass = '';
        if (alltags[key].name) {
          paClass = alltags[key].isPARecommended ? 'pa-pill tagged' : 'pa-pill';
          htmlContent.push(<span className={paClass} key={key}>{alltags[key].name}</span>);
        }
      })
    }
    return htmlContent;

  }
  return (
    <div className="entityitem" key={index}>
      <div className="entityitem__content">
        {!!updatedDate || !!type ? (
          <div className="entityitem__dateandtype">
            {!!formattedDate && <div className="entityitem__dateandtype__date">
              {formattedDate}
            </div>}
            {!!type && <div className="entityitem__dateandtype__type" title={type}>
              {type}
            </div>}
          </div>
        ) : null}

        <div className="entityitem__details">
          <div className="entityitem__details__pa">
            {Object.keys(allSectorTags).length > 0 && getTagsHtml(allSectorTags)}
          </div>
          <div className="entityitem__details__iconstitle">
            {!fileName && (type === KB || type === TCS) &&
              <Image
                className="entityitem__details__icon"
                src={bundleIcon}
                alt="icon"
              />
            }
            <div className="entityitem__details__subtitle" title={title}>
              {title}
            </div>
          </div>
          <div className="entityitem__details__desc">
            <HTMLEllipsis
              unsafeHTML={description}
              maxLine="2"
              title={description}
            />
          </div>
          {!!fileName ?
            <div className="entityitem__details__filename">
              <Image className="entityitem__details__icon" src={getFileIcon(fileName)} alt="file type icon" /> {fileName}
            </div>
            : url?.title !== "" &&
            <div className="entityitem__details__filename">
              <Image className="entityitem__details__icon" src={getFileIcon('link')} alt="link type icon" /> {url?.title}
            </div>
          }
        </div>
      </div>
    </div>
  );
}

EntityItem.propTypes = {
  item: PropTypes.object.isRequired
};


export default React.memo(EntityItem);
