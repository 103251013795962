import React from "react";
import PropTypes from 'prop-types';
import "./FaceCardDefaultView.scss";
import DefaultView from "assets/images/default_view_faceCard.svg";
import Arrow from "assets/images/arrowFaceCard.svg";
import ErrorImage from "assets/images/FaceCardError.svg";
import Image from "components/shared/Image";
import LABELS from "labels";

const {
	FACE_CARD_ERROR_PAGE: {
		MESSAGE_1, MESSAGE_2, POLLING_API_ERROR, REQUEST_API_ERROR
	}
} = LABELS

const FaceCardDefaultView = ({ stepper, isError }) => {
	return (

		<div className="faceCardDefault">
			<Image
				src={!isError ? DefaultView : ErrorImage}
				alt="base image"
				className="faceCardDefault__image"
			/>
			{!isError ? <div className="faceCardDefault__para">
				{stepper.map((step, index) => (
					<>
						<span className="faceCardDefault__text">{step.message}</span>
						{index !== stepper.length - 1 &&
							<Image
								src={Arrow}
								alt="base image"
								className="faceCardDefault__arrow"
							/>
						}
					</>
				))}
			</div> :
				<div>
					<p className="faceCardDefault__text faceCardDefault__text--error">{MESSAGE_1}</p>
					<p className="faceCardDefault__text faceCardDefault__text--error">{MESSAGE_2}</p>
				</div>
			}
		</div>
	)

};

FaceCardDefaultView.defaultProps = {
	stepper: [],
	isError: false
};

FaceCardDefaultView.propTypes = {
	isDownloadDisable: PropTypes.array
};


export const FaceCardDefaultViewv1 = ({ stepper, isPollingError, isRequestError }) => {
	const isError = isPollingError || isRequestError;

	return (

		<div className="faceCardDefault">
			<Image
				src={!isError ? DefaultView : ErrorImage}
				alt="base image"
				className="faceCardDefault__image"
			/>
			{!isError ? <div className="faceCardDefault__para">
				{stepper.map((step, index) => (
					<>
						<span className="faceCardDefault__text">{step.message}</span>
						{index !== stepper.length - 1 &&
							<Image
								src={Arrow}
								alt="base image"
								className="faceCardDefault__arrow"
							/>
						}
					</>
				))}
			</div> :
				<div>
					{!!isPollingError && (
						<p className="faceCardDefault__text faceCardDefault__text--error">{POLLING_API_ERROR}</p>)}

					{!!isRequestError && (
						<p className="faceCardDefault__text faceCardDefault__text--error">{REQUEST_API_ERROR}</p>)}
					<p className="faceCardDefault__text faceCardDefault__text--error">{MESSAGE_2}</p>
				</div>
			}
		</div>
	)

};


FaceCardDefaultViewv1.defaultProps = {
	stepper: [],
	isError: false
};

FaceCardDefaultViewv1.propTypes = {
	isDownloadDisable: PropTypes.array
};

export default FaceCardDefaultView;
