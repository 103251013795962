import React from "react";
import PropTypes from "prop-types";
import Image from "components/shared/Image";
import LoaderIcon from "assets/images/Loader/Loader.svg";
import SavedIcon from "assets/images/Saved/Saved.svg";
import PolyHIcon from "assets/images/polyHierarchi/polyHierarchiIcon.svg"
import polyHierarchiWhiteIcon from "assets/images/polyHierarchi/polyHierarchiWhiteIcon.svg"
import { Tooltip } from "components/shared/Tooltip";
import "./Pill.scss";

export const Pill = ({ defaultText, id, toolTipMsg, isDisable, showPolyIcon, showToolTip, active, saveProgress, saveDone = false, onClickHandler }) => {

  const onPillClick = () => {
    if (onClickHandler && !isDisable) {
      onClickHandler(id)
    }
  }

  let defaultPill = (
    <div
      id={`pill-${id}`}
      className={`pill ${active ? 'active' : 'non-active'} ${isDisable ? 'pill-disable' : ' '} ${showPolyIcon ? 'pill-polyH' : ' '}`}
      onClick={() => onPillClick()}
    >
      {showPolyIcon && (
        <Image
          className="pill__polyIcon"
          src={active ? polyHierarchiWhiteIcon : PolyHIcon}
          alt="Poly Hierarchi"
        ></Image>
      )}
      <span>{defaultText}</span>
      {saveProgress && (
        <Image
          className="pill__saveprogressicon"
          src={LoaderIcon}
          alt="Save in progress"
        ></Image>
      )}
      {saveDone && (
        <Image
          className="pill__savedicon"
          src={SavedIcon}
          alt="Save done"
        ></Image>
      )}
    </div>
  )

  return (
    <>
      {(showToolTip && toolTipMsg) ? (
        <Tooltip
          id={`${id}-pill`}
          position="top"
          icon={defaultPill}
          delayHide={0}
          data-multiline={true}
          className="pillTooltipClass"
        >
          {toolTipMsg}
        </Tooltip>
      ) : (
        <>{defaultPill}</>
      )}
    </>
  );
};

Pill.propTypes = {
  defaultText: PropTypes.string.isRequired,
  saveProgress: PropTypes.bool,
  saveDone: PropTypes.bool,
  active: PropTypes.bool,
  onClickHandler: PropTypes.func,
  id: PropTypes.string,
  toolTipMsg: PropTypes.string,
  showToolTip: PropTypes.bool,
  showPolyIcon: PropTypes.bool
};
Pill.defaultProps = {
  defaultText: "-",
  saveDone: false,
  saveProgress: false,
  active: false,
  toolTipMsg: "",
  showToolTip: false,
  showPolyIcon: false
};
