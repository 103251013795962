import React from "react";
import PropTypes from "prop-types";
import LABELS from "labels";
import CONFIG from "config";
import { TimeDuration } from "components/TimeDuration/TimeDuration";
import { CaseType } from "components/CaseElements/CaseType";
import "./CaseDurationAndCapture.scss";

export const CaseDurationAndCapture = ({
  caseOpenDate,
  caseCloseDate,
  caseType,
  caseNumber,
  caseStatus,
}) => {
  const { CP_CONTRIBUTION: { CAPTURE_STATUS } } = LABELS;
  const { CP_DATE_FORMAT } = CONFIG;
  return (
    <div className="caseDurationAndCapture">
      <section className="caseDurationAndCapture__leftSection">
        {!!caseType && <CaseType caseType={caseType} caseNumber={caseNumber} />}
        {!!caseOpenDate && <TimeDuration
          incomingDateFormat={CP_DATE_FORMAT}
          startDate={caseOpenDate}
          endDate={caseCloseDate}
        />}
      </section>
      <section className="caseDurationAndCapture__rightSection">
        <label>{CAPTURE_STATUS} :</label><span>{caseStatus}</span>
      </section>
    </div>
  );
};

CaseDurationAndCapture.propTypes = {
  caseOpenDate: PropTypes.string,
  caseCloseDate: PropTypes.string,
  caseNumber: PropTypes.string,
  caseStatus: PropTypes.string,
  caseType: PropTypes.string,
};
CaseDurationAndCapture.defaultProps = {
  caseCloseDate: '',
  caseNumber: '',
  caseStatus: '',
  caseType: '',
  caseOpenDate: '',
};