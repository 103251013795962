import { put, takeLatest, call, delay } from 'redux-saga/effects';
// Constant
import { RECOMMENDED_TAGGING } from "redux/constants";
// Config
import CONFIG from "config";
// Axios Util
import { doAxiosRequest } from "config-axios";
import {
    taskRegisterPending,
    taskRegisterSuccess,
    taskRegisterFailure,
    taggingStatusFailure,
    taggingStatusSuccess,
    taggingStatusPending,
    recommendedTagsPending,
    recommendedTagsSuccess,
    recommendedTagsFailure,
    feedbackTagsPending,
    feedbackTagsFailure,
    feedbackTagsSuccess
} from "./recommendedtagging.actions";
import { moduleFailure } from "redux/global.action.js";

export function* getTaggingStatus(action) {
    try {
        const status = {
            start: "Start",
            processing: "Processing",
            success: "Success",
            failed: "Failed"
        }
        const delayBetweenStatusCalls = 5000;
        const { payload: { taskId } } = action;
        const { XA, API_URL: { TASK_STATUS } } = CONFIG;
        yield put(taggingStatusPending());
        const res = yield call(doAxiosRequest, {
            method: 'GET',
            endpoint: TASK_STATUS(taskId),
            headers: {
                'x-api-key': XA,
            }
        });
        yield put(taggingStatusSuccess(res));
        if (!!res?.status_completion && (res?.status_completion === status.start || res?.status_completion === status.processing)) {
            yield delay(delayBetweenStatusCalls);
            yield call(getTaggingStatus, { payload: { taskId } });
        } else if (!!res?.status_completion && res?.status_completion === status.success) {
            yield call(getRecommendedTags, { payload: { taskId } });
        } else {
            yield put(taggingStatusFailure("Processing failed"));
        }
    } catch (error) {
        yield put(taggingStatusFailure(error));
    }
}

export function* getTaskRegister(action) {
    try {
        const { payload: { filePath } } = action;
        const { XA, S3_BUCKET_NAME, API_URL: { TASK_REGISTER } } = CONFIG;
        yield put(taskRegisterPending());
        const res = yield call(doAxiosRequest, {
            method: 'POST',
            endpoint: TASK_REGISTER(),
            params: {
                "file_path": filePath,
                "s3_bucket_name": S3_BUCKET_NAME
            },
            headers: {
                'x-api-key': XA,
            }
        });
        yield put(taskRegisterSuccess(res));
        if (res?.task_id) {
            yield call(getTaggingStatus, { payload: { taskId: res?.task_id } });
        }
    } catch (error) {
        yield put(taskRegisterFailure(error));
    }
}


export function* getRecommendedTags(action) {
    try {
        const { payload: { taskId } } = action;
        const { XA, API_URL: { GET_RECOMMENDED_TAGS } } = CONFIG;
        yield put(recommendedTagsPending());
        const res = yield call(doAxiosRequest, {
            method: 'GET',
            endpoint: GET_RECOMMENDED_TAGS(taskId),
            headers: {
                'x-api-key': XA,
            }
        });
        yield put(recommendedTagsSuccess(res));
    } catch (error) {
        yield put(recommendedTagsFailure(error));
    }
}

export function* postFeedbackRecommendedTags(action) {
    try {
        const { payload: { taskId, type, feedbackData } } = action;
        console.log('feedbackData', feedbackData)
        const {
            accepted,
            rejected,
            enteredTags
        } = feedbackData;
        const { XA, API_URL: { POST_FEEDBACK_RECOMMENDED_TAGS } } = CONFIG;
        yield put(feedbackTagsPending());
        const res = yield call(doAxiosRequest, {
            method: 'POST',
            endpoint: POST_FEEDBACK_RECOMMENDED_TAGS(),
            params: {
                "task_id": taskId,
                "label_type": type,
                "user_entered_tags": enteredTags,
                "accepted_tags": accepted,
                "rejected_tags": rejected
            },
            headers: {
                'x-api-key': XA,
            }
        });
        yield put(feedbackTagsSuccess(res));
    } catch (error) {
        yield put(feedbackTagsFailure(error));
    }
}



export default function* recommendedTaggingSaga() {
    try {
        yield takeLatest(RECOMMENDED_TAGGING.GET_TASK_REGISTER, getTaskRegister);
        yield takeLatest(RECOMMENDED_TAGGING.GET_TAGGING_STATUS, getTaggingStatus);
        yield takeLatest(RECOMMENDED_TAGGING.GET_RECOMMENDED_TAGS, getRecommendedTags);
        yield takeLatest(RECOMMENDED_TAGGING.POST_FEEDBACK_RECOMMENDED_TAGS, postFeedbackRecommendedTags);

    } catch (error) {
        yield put(moduleFailure(error, 'recommended tagging saga'));
    }
}