import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { getDuration } from "utils/helpers/helpers";
import LABELS from "labels";
import "./TimeDuration.scss";
import CONFIG from "config";

export const TimeDuration = ({ startDate, endDate, incomingDateFormat }) => {
  const { DURATIONS: { FROM, TILL } } = LABELS;
  const { CP_UI_DATE_FORMAT } = CONFIG;
  const formatDate = (date) => {
    if (!date) {
      return '';
    }
    return moment(date, incomingDateFormat).format(CP_UI_DATE_FORMAT);
  }

  return (
    <div className="timeDuration">
      <strong>{getDuration(startDate, endDate, incomingDateFormat, "Ongoing")}</strong>
      <span> | {FROM} {formatDate(startDate)}{endDate ? ` - ${TILL} ${formatDate(endDate)}` : ''}</span>
    </div>
  );
};

TimeDuration.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string,
  incomingDateFormat: PropTypes.string,
};
TimeDuration.defaultProps = {
  startDate: '',
  endDate: '',
  incomingDateFormat: 'YYYY-MM-DD',
};
