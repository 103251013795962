import React from 'react';
import Counter from "components/shared/Counter";
import PropTypes from "prop-types";
import { TextArea, Form } from "semantic-ui-react";
import { Button } from "components/shared/Button";
import "./TextAreaCounter.scss"

export class TextAreaCounter extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            inputValue: ''
        }
    }
    changeHandler = (event) => {
        const {
            handleChangeCallback
        } = this.props;
        const val = event.target.value;
        this.setState({
            inputValue: val
        });
        if (handleChangeCallback) {
            handleChangeCallback(val)
        }
    }
    componentDidMount = () => {
        const { prefillValue } = this.props;
        this.setState({
            inputValue: prefillValue
        });
    }
    componentDidUpdate = (prevProps) => {
        const { prefillValue } = this.props;
        if (prefillValue !== prevProps.prefillValue) {
            this.setState({
                inputValue: prefillValue
            });
        }
    }
    render() {
        const {
            maxLength,
            placeHolder,
            buttonText,
            isButtonLoading,
            buttonTextLoading,
            buttonClick,
            buttonDisable
        } = this.props;
        const { inputValue } = this.state;

        return (
            <Form>
                <div className="textareacounter">
                    <TextArea
                        className="textareacounter__textarea"
                        maxLength={maxLength}
                        onChange={this.changeHandler}
                        placeholder={placeHolder}
                        value={inputValue}
                    />
                    <Counter
                        inputLength={inputValue?.length}
                        maxLength={maxLength}
                        className="textareacounter__icon"
                    />
                </div>
                {!!buttonText && (
                    <Button
                        className="title__cta green"
                        onClick={(e) => buttonClick(e, inputValue)}
                        disabled={!!isButtonLoading || buttonDisable}
                    >
                        {!!isButtonLoading ? buttonTextLoading : buttonText}
                    </Button>
                )}
            </Form>
        )
    }
}

TextAreaCounter.propTypes = {
    maxLength: PropTypes.number.isRequired,
    placeHolder: PropTypes.string,
    handleChangeCallback: PropTypes.func,
    prefillValue: PropTypes.string,
    buttonText: PropTypes.string,
    isButtonLoading: PropTypes.bool,
    buttonDisable: PropTypes.bool,
    buttonTextLoading: PropTypes.string
};

TextAreaCounter.defaultProps = {
    placeHolder: '',
    prefillValue: '',
    buttonText: '',
    isButtonLoading: false,
    buttonDisable: false,
    buttonTextLoading: ''
};