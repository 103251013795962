import React from 'react';
import { connect } from 'react-redux';
import { ConsumptionHeader } from 'components/ConsumptionHeader/ConsumptionHeader'
import { ConsumptionDescription } from 'components/ConsumptionDescription/ConsumptionDescription';
import { bindActionCreators } from 'redux';
import { selectors as kpSelectors } from 'redux/reducers/knowledgepage/knowledgepage_reducer';
import { isEmpty, isEqual } from 'lodash';
import { ConsumptionContacts } from 'components/ConsumptionContacts/ConsumptionContacts';
import { Layout } from 'containers/Layout';
import './KbConsumption.scss';
import FooterWorkflow from 'components/shared/FooterWorkflow';
import LOCAL_CONFIG from '../../KpConsumption/Consumption.config';
import { TopBar } from 'components/shared/TopBar/TopBar';
import { actions as metaDataActions } from "redux/actions/metadata/metadata_actions";
import { actions as peopleResultsActions } from 'redux/actions/peopleresults/peopleresults_actions';
import { ConsumptionTaxonomySectionV1 } from 'components/ConsumptionTaxonomySection/ConsumptionTaxonomySectionV1';
import { ConsumptionAssociations } from 'components/ConsumptionAssociations/ConsumptionAssociations';
import { GlobalStyle, OverlayViewSubject, SUBSCRIBERS } from '@kdshared/docviz';
import { getPolyhierarchyApiData } from "redux/actions/polyhierarchy/polyhierarchy.actions";

// Selectors
import { selectors as metaDataSelectors } from "redux/reducers/metadata/metadata_reducer";
import { selectors as peopleResultsSelectors } from 'redux/reducers/peopleresults/peopleresults_reducer';
import { selectors as entitlementSelectors } from "redux/reducers/authorization/authorization_reducer";
import { selectors as docvizSelectors } from "redux/reducers/docviz/docviz_reducer";
import { selectors as associatedKPsSelectors } from "redux/reducers/associatedKPs/associatedKPs_reducer";
import { selectors as isPaRecommendedSelectors } from "redux/reducers/parecommened/parecommened_reducer";
import { isPaRecommended, updateRecommendPA } from "redux/actions/parecommened/parecommended.actions";
import { enableBodyOverflow, disableBodyOverflow } from "redux/actions/global/global.actions";
import { selectors as globalSelectors } from "redux/reducers/global/global_reducer";
import CONFIG from 'config';
import LABELS from 'labels';
import DOCVIZ_CONFIG from '../../Docviz/Docviz.config';
import { ENTITLEMENTS, META_DATA, DOCVIZ, KP } from 'redux/constants';
import { getCountOfFlagsAsTrue, deleteUsabillaKey, getmyCurrentLocation, stripHtml, noWordsTrimmingInString, getIsRpaReview, getDefaultViewSection, getScroll, setScroll, capitalizeFirstLetter } from "utils/helpers/helpers";
import CONSTANTS from "globalConstants";
import { Helmet } from "react-helmet";
// Analytics
import ANALYTICS from "utils/analytics/analytics";
import {
  PAGES,
  PRIMARY_CATEGORY,
  TRIGGERS,
  DTMRULE,
  SCREEN_NAMES,
  MATERIAL_STATUS,
  COPY_COMPONENT
} from "utils/analytics/analytics_constants";
import DocvizOverlayWrapper from 'components/DocvizOverlayWrapper/DocvizOverlayWrapper';
import { triggerClippingAnalytics, initialize, kbConsumptionAnalytics } from 'utils/analytics/utils_analytics';

const {
  ENTITLEMENTS: {
    KNOWLEDGE_CONTRIBUTIONS,
    CREATE_EDIT_DRAFT,
    PREVIEW_DOWNLOAD,
    PREVIEW_DOWNLOAD_R,
    EDIT_PUBLISHED_CONTENT,
    SHOW_BUNDLE,
    PUBLISH_CONTENT,
  },
  CONSUMPTION_RATING
} = CONSTANTS;

const {
  KB_CONSUMPTION: {
    KB_KP_VIEW_PAGE,
    KB_KP_PREVIEW,
    KB_KP_DOWNLOAD
  }
} = TRIGGERS;

const {
  DOCVIZ_OVERLAY_OPEN,
  VIEW_MATERIAL_PAGE,
  PREVIEW_INTERACTIONS,
  DOWNLOAD_INTERACTIONS
} = DTMRULE;

const {
  IDENTIFIER: {
    CARD_DOCVIZ_OVERLAY_OPEN,
    CARD_PREVIEW_INTERACTIONS,
    CARD_AUTHOR,
    CARD_EMAIL
  },
  KPCONSUMPTIONGROUP: {
    NOT_PA_RECOMMENDED,
    RECOMMENDED,
  },
  DISPLAY_MODE,
} = LABELS;

const { DOWNLOAD_APPLICATION_NAME: { KNOWLEDGE_BUNDLE_APP_NAME } } = CONFIG;

class KbConsumptionContainer extends React.Component {
  constructor(props) {
    super(props);
    const { footerWorkflow } = LOCAL_CONFIG;
    this.state = {
      btnsConfig: footerWorkflow,
      defaultTabIndex: { AdditionalContacts: 0, originalRequestor: 1 },
      isBundles: false,
      isInPreviewMode: false,
      docvizOpen: false,
      legacyMaterialId: '',
      legacyAttachmentId: ''
    };
    this.overlaySubscriber = null;
  }
  componentDidMount() {
    const {
      match: {
        params: { kbId },
      }
    } = this.props;
    const isBundle = this.isBundle();
    this.getConsumptionData();
    deleteUsabillaKey(CONSUMPTION_RATING);
    const { getPolyhierarchyApiData, getKPFile } = this.props;
    getPolyhierarchyApiData();
    this.overlaySubscriber = OverlayViewSubject.subscribe(action => {
      switch (action.type) {
        case SUBSCRIBERS.CLOSE_CLICK:
          const { globalActions: { enableBodyOverflow } } = this.props;
          this.setState({
            docvizOpen: false,
            legacyAttachmentId: '',
            legacyMaterialId: ''
          });
          setTimeout(() => {
            setScroll(this.state.scrollTopValue);
          }, 0)
          enableBodyOverflow();
          break;
        case SUBSCRIBERS.CLIP_DOWNLOAD_CLICK:
          const { slides } = action;
          const {
            downloadSelectedSlides,
            docvizDoc
          } = this.props;
          const slideNumbers = slides.map(s => s.slideNumber);
          downloadSelectedSlides(slideNumbers, docvizDoc.kpId, docvizDoc);
          const isBundle = this.isBundle();
           triggerClippingAnalytics({
            id: kbId,
            kpid: docvizDoc.kpId,
            isCases: false,
            paRecommended: docvizDoc?.paRecommended,
            pageName: isBundle? PAGES.KB_CONSUMPTION : PAGES.TC_CONSUMPTION,
            primaryCategory: isBundle? PRIMARY_CATEGORY.KB_CONSUMPTION : PRIMARY_CATEGORY.TC_CONSUMPTION,
            slideNumber: slideNumbers.join("|")
          });
          break;
        default:
          break;
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { isInPreviewMode } = this.state;
    const {
      consumptionKnowledgeBundle: previousBundle,
      isEditEntitlement: previousEnt,
      getPaRecommendedData: prevRecommendedData,
    } = prevProps;
    const {
      checkSiteAdmin,
      consumptionKnowledgeBundle,
      consumptionKnowledgeBundlePreview,
      isEditEntitlement,
      getPaRecommendedData
    } = this.props;
    if (isInPreviewMode && !isEmpty(consumptionKnowledgeBundlePreview) && !isEqual(consumptionKnowledgeBundlePreview, prevProps.consumptionKnowledgeBundlePreview)) 
      { this.pageLoadAnalytics(); }
    if ( !isEmpty(consumptionKnowledgeBundle) && Object.entries(consumptionKnowledgeBundle).length > 0 && !isEqual(consumptionKnowledgeBundle, previousBundle) )
       { this.pageLoadAnalytics(); }

    if (!isInPreviewMode
      && isEditEntitlement
      && !isEmpty(consumptionKnowledgeBundle)
      && Object.entries(consumptionKnowledgeBundle).length > 0
      && (
        !isEqual(consumptionKnowledgeBundle, previousBundle)
        || (isEditEntitlement !== previousEnt)
      )
    ) {
      const { bundleType } = consumptionKnowledgeBundle;
      const { BUNDLE_TYPE: { TOPIC_COLLECTION } } = LABELS;
      if (bundleType?.name === TOPIC_COLLECTION) {
        checkSiteAdmin();
      }
    }
    if (JSON.stringify(getPaRecommendedData) !== JSON.stringify(prevRecommendedData) && !Array.isArray(prevRecommendedData)) {
      setTimeout(() => {
        this.getConsumptionData(true);
      }, 2000);
    }

    // if the docviz loading state changes from loading true to false
    // means the metadata has been fetched and we should open the docviz modal
    // and also call analytics
    if (prevProps.isDocvizLoading && !this.props.isDocvizLoading) {
      const { globalActions: { disableBodyOverflow } } = this.props;
      this.setState({
        docvizOpen: true
      });
      disableBodyOverflow();
      let scrollPos = getScroll();
      this.setState({
        scrollTopValue: scrollPos
      });
      this.handleDocvizPreviewAnalytics();
    }
  }

  componentWillUnmount() {
    if (this.overlaySubscriber) {
      this.overlaySubscriber.unsubscribe();
    }
    const {
      metaDataActions: { clearMetaData },
    } = this.props;
    // clear meta data
    clearMetaData();
    deleteUsabillaKey(CONSUMPTION_RATING);
  }

  isCollection = () => {
    const { consumptionKnowledgeBundle: { bundleType: { name } } } = this.props;
    const { MY_NAVIGATOR: { ENTITY_TYPES: { TCS } } } = LABELS;
    return name === TCS;
  }
    isBundle = () => {
      const { consumptionKnowledgeBundle,consumptionKnowledgeBundlePreview } = this.props;
      const { isInPreviewMode } = this.state;
      const bundleData = isInPreviewMode ? consumptionKnowledgeBundlePreview : consumptionKnowledgeBundle;

      const { bundleType } = bundleData;
      const bundleType_value = bundleType?.name;
      const { MY_NAVIGATOR: { ENTITY_TYPES: { KB } } } = LABELS;
      return bundleType_value === KB;
  } 

    consumptionContactsAnalyticsCallback = ({ tabIdentifier, cardIdentifier, hrId }) => {
    const {
      match: {
        params: { kbId },
      },
      history: { location: { pathname } },
      consumptionKnowledgeBundlePreview,
      consumptionKnowledgeBundle,
    } = this.props;
    const {
      PA_RECOMMENDED,
      NOT_PA_RECOMMENDED
    } = MATERIAL_STATUS;
    const isInPreviewMode = pathname.includes('preview');
    const isBundle = this.isBundle();

    const bundlesMetaData = isInPreviewMode ? consumptionKnowledgeBundlePreview : consumptionKnowledgeBundle;
    const { paRecommended } = bundlesMetaData;
    const newAdobeData = {
      collection: {
        ID: kbId,
        trigger: tabIdentifier,
      },
      page: {
        category: {
          primaryCategory: isBundle? PRIMARY_CATEGORY.KB_CONSUMPTION : PRIMARY_CATEGORY.TC_CONSUMPTION,
        },
        pageInfo: {
          pageName: isBundle? PAGES.KB_CONSUMPTION : PAGES.TC_CONSUMPTION,
        },
      },
      resultType: PRIMARY_CATEGORY.KB_CONSUMPTION,
      materialPAStatus: paRecommended ? PA_RECOMMENDED : NOT_PA_RECOMMENDED
    };
    const { VIEW_AUTHOR_PROFILE, AUTHOR_EMAIL_INTERACTION } = DTMRULE;
    if (hrId) newAdobeData.authorStaffID = hrId;
    let dtmRule;
    if (cardIdentifier === CARD_AUTHOR) {
      dtmRule = VIEW_AUTHOR_PROFILE;
    } else if (cardIdentifier === CARD_EMAIL) {
      dtmRule = AUTHOR_EMAIL_INTERACTION;
    }
    ANALYTICS.kc.sendEventData(newAdobeData, dtmRule);
  }

  sendAnalyticsOnLinkClick = (link) => {
    const {
      match: {
        params: { kbId },
      },
    } = this.props;
    const isBundle = this.isBundle();
    const newAdobeData = {
      collection: {
        ID: kbId,
        trigger: TRIGGERS.ADDITIONAL_URLS,
      },
      page: {
        category: {
          primaryCategory: isBundle? PRIMARY_CATEGORY.KB_CONSUMPTION : PRIMARY_CATEGORY.TC_CONSUMPTION,
        },
        pageInfo: {
          pageName: isBundle? PAGES.KB_CONSUMPTION : PAGES.TC_CONSUMPTION,
          pageURL: getmyCurrentLocation(),
        },
      },
      materialURL: link,
      resultType: SCREEN_NAMES.KB,
      section: SCREEN_NAMES.KB,
    };
    ANALYTICS.kc.sendEventData(newAdobeData, DTMRULE.VISIT_URL_INTERACTIONS);
  };

  sendAnalyticsOnTabClick = (tab) => {
    const {
      match: {
        params: { kbId },
      },
    } = this.props;
    const isBundle = this.isBundle();
    const newAdobeData = {
      collection: {
        ID: kbId,
      },
      page: {
        category: {
          primaryCategory: isBundle? PRIMARY_CATEGORY.KB_CONSUMPTION : PRIMARY_CATEGORY.TC_CONSUMPTION,
        },
        pageInfo: {
          pageName: isBundle? PAGES.KB_CONSUMPTION : PAGES.TC_CONSUMPTION,
          pageURL: getmyCurrentLocation(),
        },
      },
      searchTabName: tab,
    };
    ANALYTICS.kc.sendEventData(newAdobeData, DTMRULE.TAB_CLICK);
  };

  getConsumptionData = (reinitalize) => {
    const {
      props: {
        match: {
          params: { kbId },
        },
        history: { location: { pathname } }
      },
    } = this;

    const isInPreviewMode = pathname.includes('preview');
    const {
      getConsumptionMetaData,
      getIsPaRecommended
    } = this.props;

    const isConsumptionView = !isInPreviewMode ? true : false;
    getConsumptionMetaData(kbId, isConsumptionView)  //'da028c68-3b9d-4af5-9f27-1543677b54f6';
    if (!reinitalize) {
      this.setState({ isInPreviewMode });
      if (kbId) getIsPaRecommended(kbId);
    }
  }

  thumbsUpDownAnalyticsCallback = (analyticsData) => {
    const { RESULT_EXPLICIT_FEEDBACK } = DTMRULE;
    let newAdobeData = this.prepareAnalyticsData(analyticsData);
    newAdobeData = { ...newAdobeData, ...analyticsData };
    ANALYTICS.kc.sendEventData(newAdobeData, RESULT_EXPLICIT_FEEDBACK);
  }

  handleKPDownloadAnalytics = (analyticsData) => {
    const newAdobeData = this.prepareAnalyticsData(analyticsData, KB_KP_DOWNLOAD);
    ANALYTICS.kc.sendEventData(newAdobeData, DOWNLOAD_INTERACTIONS);
  }

  handleKPCardClickAnalytics = (analyticsData) => {
    const newAdobeData = this.prepareAnalyticsData(analyticsData, KB_KP_VIEW_PAGE);
    ANALYTICS.kc.sendEventData(newAdobeData, VIEW_MATERIAL_PAGE);
  }

  handleDocvizPreview = (kpId, legacyMaterialId, legacyAttachmentId, paRecommended) => {
    const { getDocvizMetadata } = this.props;
    const {
      match: {
        params: { kbId },
      }
    } = this.props;
    const isBundle = this.isBundle();
    const { KB_CONSUMPTION } = PAGES;
    this.setState({
      legacyMaterialId,
      legacyAttachmentId,
      kpDocvizAnalyticsData: {
        id: kbId,
        paRecommended,
        category: isBundle ? PRIMARY_CATEGORY.KB_CONSUMPTION : PRIMARY_CATEGORY.TC_CONSUMPTION
      }
    });
    getDocvizMetadata(kpId);
  };

  handleDocvizPreviewAnalytics = () => {
    const { docvizDoc } = this.props;
    const { paRecommended, kpId, slides } = docvizDoc;
    const { legacyMaterialId, legacyAttachmentId } = this.state;

    let analyticsData = {};
    analyticsData.materialPAStatus = paRecommended ? RECOMMENDED : NOT_PA_RECOMMENDED;

    analyticsData.materialID = legacyMaterialId;
    analyticsData.attachmentID = legacyAttachmentId;
    analyticsData.pageId = kpId;
    analyticsData.slideCount = slides?.length;
    const analyticsEvent81Data = {
      ...analyticsData,
      displayMode: DISPLAY_MODE.DOCVIZ,
      identifier: CARD_DOCVIZ_OVERLAY_OPEN
    }

    this.handleKPPreviewAnalytics(analyticsEvent81Data); //event81

    analyticsData.identifier = CARD_PREVIEW_INTERACTIONS;
    this.handleKPPreviewAnalytics(analyticsData); //event 15
  };

  handleKPPreviewAnalytics = (analyticsData) => {
    let dtmRule;
    const {
      identifier
    } = analyticsData;
    if (identifier === CARD_DOCVIZ_OVERLAY_OPEN) {
      dtmRule = DOCVIZ_OVERLAY_OPEN
    } else if (identifier === CARD_PREVIEW_INTERACTIONS) {
      dtmRule = PREVIEW_INTERACTIONS
    }
    const newAdobeData = this.prepareAnalyticsData(analyticsData, KB_KP_PREVIEW);
    ANALYTICS.kc.sendEventData(newAdobeData, dtmRule);
  }

  prepareAnalyticsData = (analyticsData, trigger) => {
    const {
      pageId,
      materialPAStatus,
      materialID,
      attachmentID,
      displayMode,
      slideCount
    } = analyticsData;
    const isBundle = this.isBundle();
    const {
      match: {
        params: { kbId },
      }
    } = this.props;
    const newAdobeData = {
      page: {
        category: {
          primaryCategory: isBundle? PRIMARY_CATEGORY.KB_CONSUMPTION : PRIMARY_CATEGORY.TC_CONSUMPTION,
        },
        pageInfo: {
          pageName: isBundle? PAGES.KB_CONSUMPTION : PAGES.TC_CONSUMPTION,
          pageURL: getmyCurrentLocation()
        },
      },
      resultType: SCREEN_NAMES.KB,
      collection: {
        ID: kbId,
        trigger: trigger
      }
    };

    if (pageId) newAdobeData.KCpage = { id: pageId };
    if (materialPAStatus) newAdobeData.materialPAStatus = materialPAStatus;
    if (displayMode) newAdobeData.displayMode = displayMode;
    if (attachmentID) newAdobeData.attachmentID = attachmentID;
    if (materialID) newAdobeData.materialID = materialID;
    if (slideCount) newAdobeData.numberofSlides = slideCount;

    return newAdobeData;
  }

  handleBack = () => {
    const {
      match: {
        params: { kbId },
      },
      history,
    } = this.props;
    const { STEP3 } = CONSTANTS;
    history.push({
      pathname: CONFIG.UI_URL.METADATA_KB(STEP3, kbId),
    });
  };

  getShareViaEmailBody = () => {
    const {
      consumptionKnowledgeBundlePreview,
      consumptionKnowledgeBundle
    } = this.props;
    const { isInPreviewMode } = this.state;
    const { MAILTO_BODY_BROWSER_RESTRICTED_CHARLIMIT } = CONFIG;
    const bundlesMetaData = isInPreviewMode ? consumptionKnowledgeBundlePreview : consumptionKnowledgeBundle;

    const { ELLIPSIS, EMAIL: { KB_BODY, TC_BODY, DESCRIPTION_HEADING }, BUNDLE_TYPE: { TOPIC_COLLECTION }, } = LABELS;
    const { bundleType: { name }, description } = bundlesMetaData;
    const pageLink = getmyCurrentLocation();
    let formattedBody = description.length > MAILTO_BODY_BROWSER_RESTRICTED_CHARLIMIT ? stripHtml(noWordsTrimmingInString(description, MAILTO_BODY_BROWSER_RESTRICTED_CHARLIMIT) + ELLIPSIS) : stripHtml(description);
    let bodyText;
    bodyText = name === TOPIC_COLLECTION ? (`${TC_BODY} \n ${pageLink}`) : (`${KB_BODY} \n ${pageLink}`);
    bodyText += `\n\n ${DESCRIPTION_HEADING} \n ${formattedBody}`;

    return bodyText;
  }

  shouldHideEllipsis(name, status) {
    const { isEditEntitlement, isSiteAdmin } = this.props;
    const { BUNDLE_TYPE: { TOPIC_COLLECTION }, MY_NAVIGATOR: { STATUS_FILTERS: { PUBLISHED } } } = LABELS;
    return status === PUBLISHED && name === TOPIC_COLLECTION && (!isSiteAdmin || !isEditEntitlement);
  }

  prepareShareAnalytics = (paRecommendedCount) => {
    const { consumptionKnowledgeBundle: { kbId } } = this.props;
    const { PA_RECOMMENDED, NOT_PA_RECOMMENDED } = MATERIAL_STATUS;
    const { KB: displayKB } = SCREEN_NAMES;

    let newAdobeData = {
      materialPAStatus: paRecommendedCount ? PA_RECOMMENDED : NOT_PA_RECOMMENDED, //evar14
      displayMode: displayKB, //evar10
      resultType: displayKB, //evar6                        
      collection: { ID: kbId } //evar111
    };

    return newAdobeData;
  }
  copyLinkAnalytics = (paRecommendedCount) => {
    let newAdobeData = this.prepareShareAnalytics(paRecommendedCount);
    const {
      MY_NAVIGATOR: {
        ENTITY_TYPES: { TC, KB }
      }
    } = LABELS;

    const { KB_LINK } = COPY_COMPONENT;

    newAdobeData.copyTrigger = this.isBundle() ? KB : this.isCollection() ? TC : ''; //evar44
    newAdobeData.copyComponent = KB_LINK;  //evar115           

    ANALYTICS.kc.sendEventData(newAdobeData, DTMRULE.EVENT55_COPY_LINK);
  }

  shareViaEmailAnalytics = (paRecommendedCount) => {
    let newAdobeData = this.prepareShareAnalytics(paRecommendedCount);
    ANALYTICS.kc.sendEventData(newAdobeData, DTMRULE.EVENT16_SHAREVIAEMAIL);
  }

  paAnalyticsHandler = (isSave) => {
    const {
      KB_CONSUMPTION: {
        KB_PA_RECOMMENDED,
        KB_PA_RECOMMENDED_SAVE
      }
    } = TRIGGERS;

    const { KB_CONSUMPTION } = PRIMARY_CATEGORY;
    const { TAB_CLICK } = DTMRULE;

    const {
      match: {
        params: { kbId },
      },
    } = this.props;
    const isBundle = this.isBundle();

    const newAdobeData = {
      collection: {
        ID: kbId,
        trigger: isSave ? KB_PA_RECOMMENDED_SAVE : KB_PA_RECOMMENDED,
      },
      page: {
        category: {
          primaryCategory: isBundle? PRIMARY_CATEGORY.KB_CONSUMPTION : PRIMARY_CATEGORY.TC_CONSUMPTION,
        },
        pageInfo: {
          pageName: isBundle? PAGES.KB_CONSUMPTION : PAGES.TC_CONSUMPTION,
          pageURL: getmyCurrentLocation(),
        },
      }
    };
    ANALYTICS.kc.sendEventData(newAdobeData, TAB_CLICK);
  }

  pageLoadAnalytics = () => {
    const {
      props: {
        match: {
          params: { kbId },
        },
        history: { location: { pathname } }
      },
    } = this;

    const isInPreviewMode = pathname.includes('preview');
    const isConsumptionView = !isInPreviewMode ? true : false;
    const isBundle = this.isBundle();

    const pageName = isBundle
    ? (isConsumptionView ? PAGES.KB_CONSUMPTION : PAGES.KB_CREATION) 
    : (isConsumptionView ? PAGES.TC_CONSUMPTION : PAGES.TC_CREATION); // eVar1

    const primaryCategory = isBundle
    ? PRIMARY_CATEGORY.KB_CONSUMPTION
    : PRIMARY_CATEGORY.TC_CONSUMPTION;
    kbConsumptionAnalytics({ pageName: pageName,
      category: primaryCategory,
        trackPageLoad: true, pageId: { collection: { ID: kbId } } })
  }

  //handleViewAnalytics
  handleViewAnalytics = (kpViewSection) => {
    const {
      match: {
        params: { kbId },
      },
      history: { location: { pathname } }
    } = this.props;

    const isInPreviewMode = pathname.includes('preview');
    const isConsumptionView = !isInPreviewMode ? true : false;
    const isBundle = this.isBundle();
    const newAdobeData = {
      collection: {
        ID: kbId, //eVar111
        screenName: "NA" // //eVar112
      },
      page: {
        category: {
          primaryCategory: isBundle? PRIMARY_CATEGORY.KB_CONSUMPTION : PRIMARY_CATEGORY.TC_CONSUMPTION ,
        },
        pageInfo: {
          pageName: isBundle
          ?  (isConsumptionView ? PAGES.KB_CONSUMPTION : PAGES.KB_CREATION)  : (isConsumptionView?PAGES.TC_CONSUMPTION : PAGES.TC_CREATION ),
          pageURL: window.location.href //eVar3
        },
      },
      search: {
        term: "NA" //eVar25
      },
      preFilterTerm: "NA", //eVar67
      preOrderTerm: "NA", ////eVar68
      toggleViewType: `New clicked view - ${capitalizeFirstLetter(kpViewSection)}`, //eVar148
      SERPview: `Existing view - ${capitalizeFirstLetter(getDefaultViewSection())}` //eVar35
    };
    ANALYTICS.kc.sendEventData(newAdobeData, DTMRULE.KB_TOGGLE_VIEW);
  }

  render() {
    const isRpaReview = getIsRpaReview();
    const {
      consumptionKnowledgeBundlePreview,
      consumptionKnowledgeBundle,
      knowledgePages,
      isparecommender,
      updatePaRecommended,
      getPaRecommendedData,
      userDetails,
      getKPFile,
      downloadAllStatus,
      match: {
        params: { kbId },
      }
    } = this.props;

    const {
      btnsConfig,
      kpDocvizAnalyticsData
    } = this.state;
    const { isInPreviewMode } = this.state;
    const bundlesMetaData = isInPreviewMode ? consumptionKnowledgeBundlePreview : consumptionKnowledgeBundle;
    const {
      KPCONSUMPTIONGROUP: {
        TOPBAR_HEADING_PREVIEW,
        TOPBAR_HEADING_UNPUBLISHED,
        TOPBAR_MESSAGE_PREVIEW,
        ADDITIONAL_CONTRIBUTORS,
        TOPBAR_HEADING_ARCHIVED,
        TOPBAR_MESSAGE_ARCHIVED_COLLECTION,
        TOPBAR_MESSAGE_ARCHIVED_BUNDLE,
        TOPBAR_MESSAGE_PARECOMM_DISABLED,
        ORIGINAL_REQUESTOR
      },
      BUNDLE_TYPE: { TOPIC_COLLECTION },
      MY_NAVIGATOR: { ENTITY_TYPES: { TC } },
      PAGETITLE: {
        PREVIEW_PREFIX
      }
    } = LABELS;
    const {
      IS_ARCHIVE_VISIBLE,
      KB_GRID_VIEW_ENABLED,
      DOWNLOAD_APPLICATION_NAME: { KNOWLEDGE_BUNDLE_APP_NAME },
      KNOWLEDGE_STATUS: {
        UNPUBLISHED,
        PUBLISHED,
        ARCHIVED
      } } = CONFIG;
    const { bundleType:
      name, title, description, updatedDate, relatedLinks: links, knowledgeObjects: KPs, lockedBy
    } = bundlesMetaData;
    const { isEditEntitlement, isSiteAdmin, publishEntitlement, isCreateEditEntitlement, showBundleEntitlement } = this.props;
    const {
      ADDITIONAL_CONTRIBUTORS_CARD,
      ORIGINAL_REQUESTOR_CARD
    } = TRIGGERS;
    const consumptionData = {
      name: name?.name,
      title,
      description,
      updatedDate,
      isBundle: true,
      isCollection: false,
      isInPreviewMode,
      links: links?.length || 0,
      KPs: KPs?.length || 0,
    }
    const tabsTriggers = { firstTab: ADDITIONAL_CONTRIBUTORS_CARD, secondTab: ORIGINAL_REQUESTOR_CARD };
    const {
      peopleResultsActions: { getPeopleDataUsingIDs },
      isPreviewDownloadEntitlement,
      isPreviewDownloadREntitlement,
      paRecommendedApiError
    } = this.props;
    const { industryPATags = [], functionalPATags = [], bcgInternals = [], ipaTopicTags = [], fpaTopicTags = [], bcgxTopicTags = [] } = bundlesMetaData;
    let totalTags = [], associatedTopicPages = [], associatedSectors = {}, topicPagesCount = [];
    let paRecommendedCount = 0;
    if (!isEmpty(bundlesMetaData) && Object.entries(bundlesMetaData).length > 0) {
      totalTags = [...industryPATags, ...functionalPATags, ...bcgInternals];
      associatedSectors = { count: totalTags.length, sectors: { 'Industries': industryPATags, 'Functions': functionalPATags, 'BCG Internal': bcgInternals } }
      topicPagesCount = [...ipaTopicTags, ...fpaTopicTags, ...bcgxTopicTags];

      const ipaTopicTagsModified = ipaTopicTags.map(item =>
        ({ name: item.topicName })
      );

      const fpaTopicTagsModified = fpaTopicTags.map(item =>
        ({ name: item.topicName })
      );

      const bcgxTopicTagsModified = bcgxTopicTags.map(item =>
        ({ name: item.topicName })
      );


      associatedTopicPages = { sectors: { 'Industry': ipaTopicTagsModified, 'Functions': fpaTopicTagsModified, 'BCG X': bcgxTopicTagsModified }, count: topicPagesCount.length }
      paRecommendedCount = getCountOfFlagsAsTrue(totalTags, "isPARecommended");
    }
    const isStatusUnpublished = bundlesMetaData.status === UNPUBLISHED;
    const hideEllipsis = this.shouldHideEllipsis(name, bundlesMetaData?.status) || isInPreviewMode;
    let heading, message, barColor, popupMessage, paBtnDisabled = false;

    const isContributeKnowlegeVisible = !isInPreviewMode && name?.name !== TOPIC_COLLECTION;
    const isArchiveOrUnpublished = bundlesMetaData?.status === ARCHIVED || bundlesMetaData?.status === UNPUBLISHED;
    const isStatusPublished = bundlesMetaData?.status === PUBLISHED;
    const isStatusArchived = bundlesMetaData.status === ARCHIVED;
    const { otherProps } = DOCVIZ_CONFIG;
    const isTopBarVisible = bundlesMetaData?.status && (isStatusPublished ? isparecommender : true);
    const isArchiveVisible = bundlesMetaData?.status === PUBLISHED && (name?.name === TOPIC_COLLECTION ? (isSiteAdmin && publishEntitlement) : publishEntitlement) && IS_ARCHIVE_VISIBLE;
    const modifiedName = name?.name === TOPIC_COLLECTION ? TC : name?.name;
    const isDraftDeleteVisible = !isStatusPublished && (name?.name === TOPIC_COLLECTION ? (isSiteAdmin && isCreateEditEntitlement) : isCreateEditEntitlement);
    if (isStatusUnpublished) {
      heading = TOPBAR_HEADING_UNPUBLISHED;
      message = CONFIG.TOPBAR_MESSAGE_UNPUBLISHED(modifiedName);
    } else if (isStatusArchived) {
      heading = TOPBAR_HEADING_ARCHIVED;
      message = name?.name === TOPIC_COLLECTION ? TOPBAR_MESSAGE_ARCHIVED_COLLECTION : TOPBAR_MESSAGE_ARCHIVED_BUNDLE;
    } else if (isStatusPublished) {
      barColor = 'layout_Kbconsumption__topbar-color';
      if ((lockedBy && lockedBy.length) || !!paRecommendedApiError) {
        message = TOPBAR_MESSAGE_PARECOMM_DISABLED;
        paBtnDisabled = true;
      } else {
        message = CONFIG.TOPBAR_MESSAGE_PUBLISHED_PA(modifiedName);
        popupMessage = CONFIG.TOPBAR_MESSAGE_PUBLISHED_PA_MODAL(modifiedName);
      }
    } else {
      barColor = 'layout_Kbconsumption__topbar-color';
      heading = TOPBAR_HEADING_PREVIEW;
      message = TOPBAR_MESSAGE_PREVIEW;
    }
    let showThumbsUpDown = false;
    const { TITLES_ALLOWED_THUMBSUPDOWN_KP, SKIPPED_THUMS_UPDOWN_ENABLED } = CONFIG;
    if ((userDetails && TITLES_ALLOWED_THUMBSUPDOWN_KP.indexOf(userDetails?.businessTitle) !== -1)
      || !!window.byPassTitleCheck
      || SKIPPED_THUMS_UPDOWN_ENABLED
    ) {
      showThumbsUpDown = true;
    }
    return (
      <Layout
        className="layout_Kbconsumption"
        id="creationdashboard"
        layoutClass="layout_Kbconsumption"
      >
        <Helmet>
          <title>{isInPreviewMode ? PREVIEW_PREFIX + title : title}</title>
        </Helmet>
        <GlobalStyle />
        {this.state.docvizOpen && (
          <DocvizOverlayWrapper
            getKPFile={getKPFile}
            downloadAllStatus={downloadAllStatus}
            doc={this.props.docvizDoc}
            {...otherProps}
            downloadStatus={this.props.downloadStatus}
            kpDocvizAnalyticsData={kpDocvizAnalyticsData}
          />
        )}

        {!isEmpty(bundlesMetaData) && (
          <>
            {(isTopBarVisible) && (
              <TopBar
                heading={heading}
                message={message}
                barColor={barColor}
                paButton={isStatusPublished && isparecommender}
                getPaRecommendedData={getPaRecommendedData}
                paButtonDisabled={paBtnDisabled}
                popupMessage={popupMessage}
                updatePaRecommended={updatePaRecommended}
                savePaClickCallback={() => this.paAnalyticsHandler(true)}
                buttonPaClickCallback={() => this.paAnalyticsHandler(false)}
              />
            )}
            <ConsumptionHeader
              consumptionData={consumptionData}
              totalKPs={knowledgePages?.length}
              consumptionMetaData={bundlesMetaData}
              paRecommendedCount={paRecommendedCount}
              isSiteAdmin={isSiteAdmin}
              editIconEntitlement={isEditEntitlement && showBundleEntitlement}
              isContinueEditVisible={isCreateEditEntitlement}
              hideEllipsis={hideEllipsis}
              hideShare={isInPreviewMode}
              getConsumptionData={this.getConsumptionData}
              publishEntitlement={publishEntitlement}
              isContributeKnowlegeVisible={isContributeKnowlegeVisible}
              isMyNavigatorVisible={!isInPreviewMode}
              isArchiveVisible={isArchiveVisible}
              isEditVisible={!isArchiveOrUnpublished}
              isDeleteVisible={isDraftDeleteVisible}
              isDraftVisible={isDraftDeleteVisible}
              emailBody={this.getShareViaEmailBody()}
              copyURLAnalytics={this.copyLinkAnalytics}
              shareviaEmailAnalytics={this.shareViaEmailAnalytics}
              isRpaReview={isRpaReview}
              isUnpublishLoader={this.props.isVisibleFPLoader}

            />
            <span className="consumptionDivider" hidden={false} />
            <ConsumptionDescription
              description={consumptionData?.description}
              isInPreviewMode={isInPreviewMode}
              downloadApplicationName={KNOWLEDGE_BUNDLE_APP_NAME}
            />
            <div className="layout_Kbconsumption__tabs">
              <ConsumptionAssociations
                links={bundlesMetaData?.relatedLinks}
                KPIds={bundlesMetaData?.knowledgeObjects}
                totalKPs={knowledgePages?.length}
                isPreviewDownloadEntitlement={isPreviewDownloadEntitlement}
                isPreviewDownloadREntitlement={isPreviewDownloadREntitlement}
                kpCardClickCallback={this.handleKPCardClickAnalytics}
                getPeopleDataUsingIDs={getPeopleDataUsingIDs}
                kpDownloadCallback={this.handleKPDownloadAnalytics}
                onTabClicked={this.sendAnalyticsOnTabClick}
                onLinkClicked={this.sendAnalyticsOnLinkClick}
                handleDocvizPreview={this.handleDocvizPreview}
                showThumbsUpDown={showThumbsUpDown}
                thumbsUpDownAnalyticsCallback={this.thumbsUpDownAnalyticsCallback}
                downloadApplicationName={KNOWLEDGE_BUNDLE_APP_NAME}
                showKpGridView={KB_GRID_VIEW_ENABLED}
                kbId={kbId}
                handleViewAnalytics={this.handleViewAnalytics}
                getKPFile={getKPFile}
              />
              <ConsumptionContacts
                data={[
                  { heading: ADDITIONAL_CONTRIBUTORS, data: bundlesMetaData?.contributors },
                  { heading: ORIGINAL_REQUESTOR, data: !!bundlesMetaData?.originalRequestor ? [bundlesMetaData.originalRequestor] : [], hideIndiviualCount: true }
                ]}
                tabsTriggers={tabsTriggers}
                getPeopleDataUsingIDs={getPeopleDataUsingIDs}
                onTabClicked={this.sendAnalyticsOnTabClick}
                cardClickCallback={this.consumptionContactsAnalyticsCallback}
              />
              <ConsumptionTaxonomySectionV1
                subjects={bundlesMetaData?.subjectTags}
                topicPagesHidden={false}
                subjectsShowMore={true}
                associatedTopicPages={associatedTopicPages}
                onTabClicked={this.sendAnalyticsOnTabClick}
                isKb
                ipaTagList={industryPATags}
                fpaTagList={functionalPATags}
                bcgInternalList={bcgInternals}
              />
            </div>
            {isInPreviewMode && (
                <FooterWorkflow buttons={btnsConfig} getEvent={this.handleBack} isStickyAtBottom />
            )}
          </>
        )
        }
      </Layout>
    );
  }
}

KbConsumptionContainer.defaultProps = {
  bundlesMetaData: { bundleType: {} },
  knowledgePages: [],
};
const mapStateToProps = (state) => ({
  kbMetaData: metaDataSelectors.getMetadata(state),
  isLoading: kpSelectors.getLoading(state),
  peopleResults: peopleResultsSelectors.getPeopleResults(state, 'KB_Details'),
  consumptionKnowledgeBundlePreview: metaDataSelectors.getConsumptionKnowledgeBundlePreview(state),
  consumptionKnowledgeBundle: metaDataSelectors.getConsumptionKnowledgeBundle(state),
  isCreateEditEntitlement: entitlementSelectors.getEntitlementValue(state, KNOWLEDGE_CONTRIBUTIONS, CREATE_EDIT_DRAFT),
  isPreviewDownloadEntitlement: entitlementSelectors.getEntitlementValue(state, KNOWLEDGE_CONTRIBUTIONS, PREVIEW_DOWNLOAD),
  isPreviewDownloadREntitlement: entitlementSelectors.getEntitlementValue(state, KNOWLEDGE_CONTRIBUTIONS, PREVIEW_DOWNLOAD_R),
  isEditEntitlement: entitlementSelectors.getEntitlementValue(state, KNOWLEDGE_CONTRIBUTIONS, EDIT_PUBLISHED_CONTENT),
  showBundleEntitlement: entitlementSelectors.getEntitlementValue(state, KNOWLEDGE_CONTRIBUTIONS, SHOW_BUNDLE),
  publishEntitlement: entitlementSelectors.getEntitlementValue(state, KNOWLEDGE_CONTRIBUTIONS, PUBLISH_CONTENT),
  isSiteAdmin: entitlementSelectors.checkSiteAdmin(state),
  docvizDoc: docvizSelectors.getDocvizDocument(state),
  isDocvizLoading: docvizSelectors.getLoading(state),
  downloadStatus: docvizSelectors.getDownloadStatus(state),
  knowledgePages: associatedKPsSelectors.getAssociatedKPs(state),
  isparecommender: isPaRecommendedSelectors.getPaRecommendedStatus(state),
  getPaRecommendedData: isPaRecommendedSelectors.getPaRecommendedData(state),
  paRecommendedApiError: isPaRecommendedSelectors.getPaRecommendedError(state),
  userDetails: globalSelectors.getUserDetails(state),
  downloadAllStatus: kpSelectors.getLoadingDownloadAll(state),
  isVisibleFPLoader: globalSelectors.isVisibleFPLoader(state),
});

const mapDispatchToProps = (dispatch) => ({
  checkSiteAdmin: () => dispatch({ type: ENTITLEMENTS.CHECK_SITE_ADMIN }),
  getConsumptionMetaData: (kbId, isConsumptionView) => dispatch(
    {
      type: META_DATA.GET_CONSUMPTION_KB_DATA, payload: { kbId, isConsumptionView }
    }),
  metaDataActions: bindActionCreators({ ...metaDataActions }, dispatch),
  peopleResultsActions: bindActionCreators(
    { ...peopleResultsActions },
    dispatch
  ),
  globalActions: bindActionCreators({ enableBodyOverflow, disableBodyOverflow }, dispatch),
  getIsPaRecommended: (id) => dispatch(isPaRecommended(id)),
  getDocvizMetadata: (id) => dispatch({ type: DOCVIZ.GET_DOCVIZ_METADATA, payload: { id } }),
  updatePaRecommended: (data) => dispatch(updateRecommendPA(data)),
  downloadSelectedSlides: (pages, documentId, docvizDoc) => dispatch({
    type: DOCVIZ.DOWNLOAD_SELECTED_SLIDES,
    payload: {
      pages,
      documentId,
      doc: docvizDoc,
      appName: KNOWLEDGE_BUNDLE_APP_NAME
    }
  }),
  getPolyhierarchyApiData: () => dispatch(getPolyhierarchyApiData()),
  getKPFile: (kpId) => dispatch({
    type: KP.GET_FILE,
    payload: {
      kpId: kpId,
      appName: KNOWLEDGE_BUNDLE_APP_NAME,
      type: CONFIG.downloadTypeForKP
    }
  }),
});

export const KbConsumption = connect(
  mapStateToProps,
  mapDispatchToProps
)(KbConsumptionContainer);

export { KbConsumptionContainer };
