
import React from "react";
import PropTypes from "prop-types";
import { Image } from 'semantic-ui-react';

import { InfoPopup } from 'components/shared/InfoPopup/InfoPopup';
import { MailTo } from 'components/shared/MailTo';

import CopyDoneIcon from "assets/images/copied.svg";
import ShareIcon from 'assets/images/share_xl.svg';

import { copyStringToClipboard } from "utils/helpers/helpers";
import LABELS from "labels";
import "./ConsumptionShare.scss";
export class ConsumptionShare extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            copiedURL: false
        }
    }
    onPopupClose = () => {
        this.setState({
            copiedURL: false
        });
    }
    copyTextToClipboard = () => {
        const { copyURL, copyURLAnalytics, paRecommendedCount } = this.props;
        copyStringToClipboard(copyURL);
        this.setState({ copiedURL: true });
        copyURLAnalytics(paRecommendedCount);
    }

    onClickMailTo = () => {
        const { shareviaEmailAnalytics, paRecommendedCount } = this.props;
        shareviaEmailAnalytics(paRecommendedCount);
    }

    render() {
        const {
            BUTTONS: {
                SHARE, SHARE_VIA_EMAIL, COPY_URL, COPY_URL_DONE
            }
        } = LABELS;
        const { className, emailSubject, emailTo, isPositionTop, emailBody } = this.props;
        const { copiedURL } = this.state;
        return (
            <div className="consumptionShare">
                <InfoPopup
                    tooltipPosition="bottom"
                    tooltipId="share_options"
                    title={SHARE}
                    popupVerticalPosition={isPositionTop ? "top" : "bottom"}
                    popupHorizontalPosition="left"
                    withTooltip={false}
                    onPopupClose={this.onPopupClose}
                    trigger={
                        <Image src={ShareIcon} title={SHARE} className={className} />
                    }
                >

                    <ul className="consumptionShare__content" onClick={(e) => e.stopPropagation()}>
                        <li className="consumptionShare__content--mail" onClick={this.onClickMailTo}><MailTo email={emailTo} subject={emailSubject} body={emailBody}>{SHARE_VIA_EMAIL}</MailTo></li>
                        <li className="consumptionShare__content--copy" onClick={this.copyTextToClipboard}>
                            {copiedURL ? <Image src={CopyDoneIcon} className="consumptionShare__content--copy_icon" /> : null}
                            {copiedURL ? <span className="consumptionShare__content--copy_bold">{COPY_URL_DONE}</span> : COPY_URL}
                        </li>
                    </ul>

                </InfoPopup>
            </div>
        );
    }
}

ConsumptionShare.defaultProps = {
    emailTo: '',
    isPositionTop: false,
    className: ''
}
ConsumptionShare.propTypes = {
    emailTo: PropTypes.string
}
