
// React
import React from 'react';
// Icon
import Image from "components/shared/Image";

//Images
import DeleteIcon from "assets/images/delete/delete_2x.svg";
import AddIcon from "assets/images/add/add-white.svg";

//labels
import LABELS from "labels";

// Scss
import './ActionButton.scss';

// Help Icon return the help hover functionality 
export const ActionButton = ({ type, onClickCallback }) => {
  const { BUTTONS: { ADD, DELETE } } = LABELS;
  return (
    <div className={`action-button action-button__${type}`} onClick={onClickCallback}>
      {type === ADD && <Image src={AddIcon} />}
      {type === DELETE && <Image src={DeleteIcon} />}
    </div>
  )
};
