import React from "react";
import Modal from "components/shared/Modal";

import LABELS from "labels";
import { ImagesGrid } from "components/shared/ImagesGrid";
import { SelectCoverImage } from "components/SelectCoverImage";
import CONFIG from "config";

export class ImageSelectorWithModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            isImageSelected: false,
            nameOfTopicCollectionCover: ""
        }
    }
    openImagePickerModal = () => {
        this.setState({
            isModalOpen: true
        })
    }

    closeSelectImageModal = () => {
        this.setState({
            isModalOpen: false
        })
    }

    onImageSelection = (nameOfImage) => {
        this.setState({
            isImageSelected: true,
            nameOfTopicCollectionCover: nameOfImage
        });
    }

    onSelectedImageSubmit = () => {
        const { handleChange, images } = this.props;
        const { nameOfTopicCollectionCover } = this.state;
        let imageObj = null;
        const { SITECORE_XM_ENABLED } = CONFIG;
        images.forEach((image) => {
            if (image.name === nameOfTopicCollectionCover) {
                imageObj = {
                    id: image.id,
                    name: image.name,
                };
            }
        });
        if (nameOfTopicCollectionCover.length) {
            handleChange(SITECORE_XM_ENABLED ? imageObj : nameOfTopicCollectionCover, "coverImage");
            this.closeSelectImageModal();
        }
    }
    render() {
        const {
            METADATA: {
                COVER_IMAGE_BUTTON,
                COVER_PIC_SUBTITLE,
                COVER_PIC_TITLE,
            },
        } = LABELS;
        const { className, images, preFilledImage, tcTitle, tcDesc, tcDate, tcFiles } = this.props;
        const { isModalOpen, isImageSelected } = this.state;
        let preFilledImageURL;
        let filteredImgObj = images.filter((image) => image.name === preFilledImage);
        if (filteredImgObj.length) {
            preFilledImageURL = filteredImgObj[0].value;
        }
        return (
            <>
                <SelectCoverImage
                    preFilledImage={preFilledImage}
                    preFilledImageURL={preFilledImageURL}
                    className={className}
                    tcTitle={tcTitle}
                    tcDesc={tcDesc}
                    tcDate={tcDate}
                    tcFiles={tcFiles}
                    openImagePickerModal={this.openImagePickerModal}
                />
                <Modal
                    isOpen={isModalOpen}
                    overlayClassName="drawfromright"
                    className="ReactModal__Content--kbstep1ImagePicker drawfromright"
                    onRequestClose={this.closeSelectImageModal}
                >
                    <ImagesGrid
                        heading={COVER_PIC_TITLE}
                        subheading={COVER_PIC_SUBTITLE}
                        images={images}
                        preFilledImage={preFilledImage}
                        onImageSelection={this.onImageSelection}
                        isImageSelected={isImageSelected}
                        onSelectedImageSubmit={this.onSelectedImageSubmit}
                        footerBtnText={COVER_IMAGE_BUTTON}
                        closeSelectImageModal={this.closeSelectImageModal}
                    />
                </Modal>
            </>
        )
    }
}
