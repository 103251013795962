export function termIsLeaf(sesTerm) {
  if (sesTerm.hierarchy == null) {
    return true;
  } // each path contains a broader concept at semi-last position (last position of each path is the term)


  const broaderConcepts = sesTerm.paths?.map(pathObj => {
    const fieldsCount = pathObj.path.length;

    if (fieldsCount < 2) {
      return "";
    }

    const broaderConcept = pathObj.path[fieldsCount - 2];
    return broaderConcept.field.id;
  }) ?? []; // use collected broader concepts to filter out broader relationships from hierarchy

  const onlyNarrowerHierarchies = sesTerm.hierarchy.filter(hierarchy => {
    return !hierarchy.fields.some(field => broaderConcepts.includes(field.field.id));
  });
  return onlyNarrowerHierarchies.length === 0;
}