import React from 'react';
import Modal from "components/shared/Modal";
import PropTypes from "prop-types";
import Image from "components/shared/Image";
import { Button } from "components/shared/Button";
import CrossIcon from "assets/images/cross/cross.svg";
import "./PopupModal.scss"

export const PopupModal = ({
    button1: {
        text: button1text,
        clickHandler: button1Click,
        button1Class,
    },
    button2: {
        text: button2text,
        clickHandler: button2Click,
        button2Class
    },
    isOpen,
    onRequestClose,
    children,
    headerMessage,
    modalContentWidth,
    showCloseIcon,
}) => {
    const modalWidth = {
        content: { width: modalContentWidth }
    }
    return (
        <Modal style={modalWidth} isOpen={isOpen} className="popupModal" onRequestClose={onRequestClose}>
            {headerMessage &&
                <div className="popupModal__header">
                    <p>{headerMessage}</p>
                </div>
            }
            {showCloseIcon && <Button className="popupModal__headerClose" onClick={onRequestClose}><Image alt="close" src={CrossIcon} /></Button>}
            {children}
            <div className="popupModal__buttons">
                {button1text &&
                    <Button className={`popupModal__buttons--button ${button1Class ? button1Class : " black"} `} onClick={button1Click}>
                        {button1text}
                    </Button>}
                {button2text &&
                    <Button className={`popupModal__buttons--button ${button2Class ? button2Class : " green"}`} onClick={button2Click}>
                        {button2text}
                    </Button>}
            </div>
        </Modal >
    )
};

PopupModal.propTypes = {
    headerMessage: PropTypes.string.isRequired,
    width: PropTypes.string,
    isOpen: PropTypes.bool,
    onRequestClose: PropTypes.func,
    button1: PropTypes.object,
    button2: PropTypes.object,
    showCloseIcon: PropTypes.bool,
};

PopupModal.defaultProps = {
    isOpen: false,
    button1: {
        text: false,
        clickHandler: () => { },
        colorClass: "black"
    },
    button2: {
        text: false,
        clickHandler: () => { },
        colorClass: "green"
    },
    width: 'auto',
    showCloseIcon: false,
};