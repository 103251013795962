import React from "react";
import PropTypes from "prop-types";
import EntityItem from "components/EntityItem/EntityItem";
import { v4 as uuidv4 } from 'uuid';
// SCSS
import "./EntityList.scss";

class EntityList extends React.Component {
  render() {
    const {
      heading,
      list,
      emptyListHeading,
      onEntityClick
    } = this.props;
    return (
      <section className="entityList">
        {list?.length ?
          <>
            {!!heading && <span className="entityList__heading--withContent">{heading}</span>}
            <ul>
              {list.map((item, index) => {
                const { id, type } = item
                return (
                  <li
                    key={uuidv4()}
                    onClick={() => onEntityClick(id, type, item)}
                  >
                    <EntityItem
                      index={index}
                      item={item}
                    />
                  </li>
                )
              })
              }
            </ul>
          </>
          : <span className="kpList__heading">{emptyListHeading}</span>
        }
      </section>

    );
  }
};

EntityList.propTypes = {
  KPIds: PropTypes.array.isRequired,
  knowledgePages: PropTypes.array,
  heading: PropTypes.string,
  emptyListHeading: PropTypes.string,

};

EntityList.defaultProps = {
  KPIds: [],
  knowledgePages: [],
  heading: "",
};

export { EntityList };
