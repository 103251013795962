import React from "react";

import Image from "components/shared/Image";
import AdditionalInfoIcon from "assets/images/additional-info/ai_2x.svg";
import LABELS from "labels";
// Semantic UI
import { Form } from "semantic-ui-react";
import CaseHistoryModal from "components/CaseHistoryModal/CaseHistoryModal"

import "./AdditionalInfo.scss";

export const AdditionalInfo = ({ label, guid, getCaseHistory,caseHistoryTableKey,caseHistoryData }) => {
  const [showHistoryModal, setShowHistoryModal] = React.useState(false);

  const closeHistoryModal = () => {
    setShowHistoryModal(false);
  };

  const {
    CASE_HISTORY_MODAL: {
      HEADER,
      TABLE_KEYS
    }
  } = LABELS;

  const isCaseHistoryEnabled = process.env.REACT_APP_IS_CASE_HISTORY_ENABLED?.trim() === "true";

  return (
    <>
      <Form.Button onClick={() => setShowHistoryModal(true)} className="additionalinfo" disabled={!isCaseHistoryEnabled}>
        <Image
          className="additionalinfo--icon"
          src={AdditionalInfoIcon}
          alt="Additional Information"
        />
        <label>{label}</label>
      </Form.Button>

      <CaseHistoryModal
        isOpen={showHistoryModal}
        onRequestClose={closeHistoryModal}
        header={HEADER}
        cid={guid}
        getCaseHistory={getCaseHistory}
        caseHistoryTableKey={TABLE_KEYS}
        caseHistoryData={caseHistoryData}
      />
    </>
  );
};

AdditionalInfo.defaultProps = {
  label: ""
}
