import React from 'react';
import { TabComponent } from 'components/shared/TabComponent';
import { CardsLayout } from '../Contacts/CardsLayout';
import './ConsumptionContacts.scss';
import { getPeopleDataByID } from 'utils/helpers/helpers';
import PropTypes from 'prop-types';

const firstTab = 'firstTab';

export class ConsumptionContacts extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedSuggestions: {},
      additionalContactsImages: {},
      tabIndex: { AdditionalContacts: 0, originalRequestor: 1 },
      activeTab: 0,
      tabData: []
    };
  }
  getActiveTab = (activeIndex) => {
    this.setState({ activeTab: activeIndex });
  };

  getDataByID = async (data, index) => {
    const { getPeopleDataUsingIDs } = this.props;
    let temp1 = await getPeopleDataByID(
      data,
      false,
      getPeopleDataUsingIDs
    );
    const { tabData } = this.state;

    let tempArray = [...tabData];
    tempArray[index] = { ...tempArray[index], ...temp1 };
    this.setState({
      tabData: tempArray
    })

  };

  componentDidMount() {
    const { data } = this.props;
    data.forEach((elem, index) => {
      if (elem?.data.length)
        this.getDataByID(elem.data, index)
    })
  }

  tabClicked = (e, index) => {
    const { onTabClicked, data } = this.props;
    const headings = [];
    data.forEach(elem => {
      if (Object.keys(elem.data)?.length) {
        headings.push(elem.heading)
      }
    })
    if (!!onTabClicked) {
      onTabClicked(headings[index]);
    }
  }

  fetchArrayListing = () => {
    let dataList = [];
    const { tabData } = this.state;
    const { data, cardClickCallback, tabsTriggers } = this.props;
    !!tabData && tabData.forEach((elem, index) => {
      if (!!elem && Object.keys(elem)?.length) {
        dataList.push({
          className: 'item-style',
          heading: data[index].heading,
          count: Object.keys(elem)?.length,
          hiddenClassName: Object.keys(elem)?.length
            ? ''
            : 'displaynone',
          hideIndiviualCount: !!data[index]?.hideIndiviualCount,
          component: (
            <CardsLayout
              contacts={elem}
              //idList={additionalContacts}
              tabIdentifier={tabsTriggers[firstTab]}
              cardClickCallback={cardClickCallback}
            />
          ),
        })
      }
    });
    return dataList;
  }

  render() {
    const arrayTabData = this.fetchArrayListing();
    return (
      arrayTabData.length > 0 && <div className="contacts-wrapper margin_bottom--30">
        <TabComponent
          className="contacts-wrapper__tab-component tabs__container consumptionTabContainer"
          defaultActiveIndex={0}
          getActiveIndex={this.getActiveTab}
          menu={{ secondary: true, pointing: true }}
          onTabChangeCallback={this.tabClicked}
          arrayOfPaneElements={arrayTabData}
        />
      </div>
    );
  }
}
ConsumptionContacts.propTypes = {
  tabsTriggers: PropTypes.object,
  onTabClicked: PropTypes.func,
  cardClickCallback: PropTypes.func,
  data: PropTypes.array
};

ConsumptionContacts.defaultProps = {
  tabsTriggers: {},
  onTabClicked: () => { },
  cardClickCallback: () => { },
  data: []
};
