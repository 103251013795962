import React from "react";
import LABELS from "labels";
import { Form } from "semantic-ui-react";
import PropTypes from "prop-types";
import { getFileIcon, getFileNameOnly, getFileExtension } from "utils/helpers/helpers";
import AddIcon from "assets/images/add/add.svg";
import ReplaceIcon from "assets/images/replace.svg";
import EditFileIcon from "assets/images/editfile.svg";
import inlineSpinner from "assets/images/spinnerinner.gif";
import Image from "components/shared/Image";
import './UploadFile.scss';
import { Tooltip } from "components/shared/Tooltip";
import CONFIG from 'config';

export class UploadFile extends React.PureComponent {
  state = {
    editFileName: "",
    isEdit: false
  };
  editIconClick = () => {
    const {
      file,
      fileNameChangeCallback,
      handleUnsupportedFileError
    } = this.props;
    const fileNameOnly = getFileNameOnly(file?.name);
    this.setState({
      isEdit: true,
      editFileName: fileNameOnly
    }, () => {
      this.inputRef.focus();
      if (fileNameChangeCallback)
        fileNameChangeCallback();
    });
    handleUnsupportedFileError(false)
  }

  handleEditFileNameChange = (target) => {
    const { value } = target;
    this.setState({ editFileName: value });
  }

  handleBlur = (nFileName) => {
    let newFileName = nFileName.trim();
    const {
      file,
      handleBlurFileNameChange
    } = this.props;

    const fileNameOnly = getFileNameOnly(file?.name);
    this.setState({
      isEdit: false
    }, () => {
      if (newFileName.length && newFileName !== fileNameOnly.trim()) {
        let fileextn = getFileExtension(file?.name);
        const newFileNameObj = {
          ...file,
          name: newFileName + "." + fileextn
        }
        handleBlurFileNameChange(newFileNameObj);
      }
    })

  }
  render() {
    const {
      KP: {
        STEP1: {
          ADDFILE_HEADING,
          ADDFILE_BUTTON,
          REPLACEFILE_BUTTON,
          ADDFILE_STATUS,
          UPLOAD_FILE_PLACEHOLDER,
          UNSUPPORTED_FILE_TOOLTIP
        }
      }
    } = LABELS;
    const { fileUploadStatus, hasFile, file, handleFileChange, errorState, isFileExistOnS3, disabled, handleUnsupportedFileError, isUnsupportedFileError } = this.props;
    const { editFileName, isEdit } = this.state;
    const { SUPPORTED_FILE_EXTENSIONS } = CONFIG
    return (
      <>
        <label>{ADDFILE_HEADING}<Tooltip>{UNSUPPORTED_FILE_TOOLTIP}</Tooltip></label>
        <div className={`uploadFile${(errorState && errorState['file'] || isUnsupportedFileError) ? " input__error" : ""}`}>
          {!fileUploadStatus && !!isFileExistOnS3 &&
            <>
              {file?.name && <div className={`uploadFile__valueandicon ${isEdit ? "uploadFile__valueandicon--editview " : " "}`}>
                <Image src={getFileIcon(file?.name)} alt="icon" />
                {isEdit ?
                  <input
                    type="text"
                    ref={(c) => (this.inputRef = c)}
                    value={editFileName}
                    name="editFileName"
                    className="uploadFile__editfileinput"
                    onChange={(e) => this.handleEditFileNameChange(e.target)}
                    onBlur={() => this.handleBlur(editFileName)}
                  />
                  : null}
                {!isEdit ?
                  <span className="uploadFile__filename">
                    {file?.name}
                    <Image src={EditFileIcon} alt="icon" disabled={disabled} onClick={() => this.editIconClick()} className="uploadFile__editfileicon" />
                  </span>
                  : ""}
              </div>
              }
            </>
          }
          {!!fileUploadStatus && <div className="uploadFile__status"><Image src={inlineSpinner} alt="spinner" /><span>{ADDFILE_STATUS}</span></div>}
          {!fileUploadStatus && !isEdit &&
            <>
              {(!hasFile || !isFileExistOnS3) &&
                < span className="uploadFile__placeholder">{UPLOAD_FILE_PLACEHOLDER}</span>
              }
              <div className={`uploadFile__wrap ${!!hasFile ? 'uploadFile__wrap--rep' : ''}`}>
                {(!hasFile || !isFileExistOnS3) && <div className="uploadFile__btn--add">
                  <Image src={AddIcon} alt="Add File Image" />{ADDFILE_BUTTON}</div>}
                {!!hasFile && isFileExistOnS3 && <div className="uploadFile__btn--replace"><Image src={ReplaceIcon} alt="Replace File Image" />{REPLACEFILE_BUTTON}</div>}

                <Form.Input
                  type="file"
                  className="upload"
                  disabled={disabled}
                  onChange={(e) => {
                    let file = e.target.files[0];
                    handleFileChange(file, hasFile, e)
                  }}
                  onClick={() => handleUnsupportedFileError(false)}
                  accept={SUPPORTED_FILE_EXTENSIONS}
                />
              </div>
            </>
          }
        </div>
      </>
    )
  }
}

UploadFile.propTypes = {
  fileUploadStatus: PropTypes.bool.isRequired,
  hasFile: PropTypes.string,
  file: PropTypes.object.isRequired,
  handleFileChange: PropTypes.object.isRequired,
  errorState: PropTypes.object
};
UploadFile.defaultProps = {
  fileUploadStatus: false,
  hasFile: "",
  file: {},
  errorState: {}
};