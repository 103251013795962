import React from 'react'
import './FaceCardGeneratorHeader.scss'
import logoIcon from "assets/images/bcg_logo.svg";
import LABELS from 'labels';
import FaceCardUserProfile from 'components/FaceCardUserProfile/FaceCardUserProfile';

const {
    FACE_CARD_GENERATOR: {
        PAGE_TITLE
    }
} = LABELS
export class FaceCardGeneratorHeader extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { userDetails } = this.props;
        return (
            <div className="faceCardHeader">
                <div className='faceCardHeader__left'>
                    <img src={logoIcon} className='faceCardHeader__left__logoIcon' />
                    <p className='faceCardHeader__left__logoText'>{PAGE_TITLE}<sup>(Beta)</sup></p>
                </div>
                <div className='faceCardHeader__right'>
                    {<FaceCardUserProfile userDetails={userDetails} />}
                </div>
            </div>
        )
    }
}