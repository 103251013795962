import { put, call, takeEvery } from 'redux-saga/effects';
// Constant
import { CASES } from "redux/constants";
// Config
import CONFIG from "config";
// Label
import LABELS from "labels";
// Axios Util
import { doAxiosRequest } from "config-axios";
import { moduleFailure } from "redux/global.action.js";
import {
    getCasesMetaDetailsPending,
    getCasesMetaDetailsSuccess,
    getCasesMetaDetailsFailure
} from "./case.action";

export function* getCasesMetaDetails(action) {
    const { payload: { caseNumbers } } = action;
    const {
        API_URL: {
            GET_CASES_META_DETAILS
        },
        XA,
    } = CONFIG;
    try {
        yield put(getCasesMetaDetailsPending());
        const res = yield call(doAxiosRequest, {
            method: 'POST',
            endpoint: GET_CASES_META_DETAILS(),
            // params: action.payload,
            params: {
                cases: caseNumbers
            },
            headers: {
                'x-api-key': XA,
            }
        });
        if (res?.error.code === 200 && res?.data) {
            yield put(getCasesMetaDetailsSuccess(res?.data));
        } else {
            yield put(getCasesMetaDetailsFailure(res?.error));
        }

    } catch (error) {
        yield put(getCasesMetaDetailsFailure(error));
    }
}

export default function* CasesSaga() {
    try {
        yield takeEvery(CASES.GET_CASES_META_DETAILS, getCasesMetaDetails);
    } catch (error) {
        yield put(moduleFailure(error, 'CasesSaga'));
    }
}