import React from "react";
import Image from "components/shared/Image";
import selectImagePlaceholder from "assets/images/selectImage.svg";
import "./SelectCoverImage.scss";
import LABELS from "labels";

const {
    METADATA: {
        COVER_IMAGE_BUTTON,
        COVER_IMAGE_BUTTON1,
        COVER_IMAGE_PLC_TEXT
    },
} = LABELS;

export const SelectCoverImage = ({ preFilledImage, preFilledImageURL, className, tcTitle, tcDesc, tcDate, tcFiles, openImagePickerModal }) => {
    return (
        <div className={`imageSelector ${className} ${preFilledImage ? `imageSelected` : `imageNotSelected`}`}>
            {
                preFilledImage ?
                    <div className="imageSelector__imageSelected">
                        <div className="imageSelector__tile">
                            <span className="imageSelector__tile--title">{tcTitle}</span>
                            <Image className="imageSelector__tile--image" src={preFilledImageURL} alt={preFilledImage} />
                            <button onClick={() => openImagePickerModal()} type="button" className="imageSelector--button imageSelector__tile--button ui small button">{COVER_IMAGE_BUTTON1}</button>
                        </div>
                        <div className="imageSelector__tileText">
                            <div className="imageSelector__tileText--desc">{tcDesc}</div>
                            <div className="imageSelector__tileText--date">{tcDate}</div>
                            <div className="imageSelector__tileText--files">{tcFiles}</div>
                        </div>
                    </div>
                    :
                    <div className="imageSelector__content imageSelector__imageNotSelected">
                        <span className="imageSelector--text">{COVER_IMAGE_PLC_TEXT}</span>
                        <Image className="imageSelector--image" src={selectImagePlaceholder} alt="" />
                        <button onClick={() => openImagePickerModal()} type="button" className="imageSelector--button ui small button">{COVER_IMAGE_BUTTON}</button>
                    </div>
            }

        </div>
    );
}
