import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Modal from "components/shared/Modal";
import Image from "components/shared/Image";
import crossIcon from "assets/images/cross/cross.svg";
import AdminHistoryModalList from "components/AdminHistoryModalList/AdminHistoryModalList";
import VersionListing from "components/VersionListing/VersionListing";
import LABELS from "labels";
import AdditionalInfoIcon from "assets/images/additional-info/ai_2x.svg";
import { v4 as uuidv4 } from 'uuid';
// Actions
import { actions as peopleResultsActions } from 'redux/actions/peopleresults/peopleresults_actions';
// scss
import "./AdminHistoryModal.scss";

export class AdminHistoryModal extends React.PureComponent {
  state = {
    owners: [],
    adminData: []
  };

  componentDidMount = async () => {
    const {
      peopleResultsActions: {
        getPeopleDataUsingIDs,
      },
      data,
      fields
    } = this.props;
    const {
      ADMIN_HISTORY_SECTION: {
        KEYS: {
          PERSON,
        },
      },
    } = LABELS;
    let personColumn = null;
    Object.keys(fields).forEach(field => {
      const { key } = fields[field];
      if (key === PERSON) {
        personColumn = field;
      }
    });
    if (!!personColumn) {
      const owners = data.map(record => record[personColumn]);
      if (owners && owners.length) {
        const peopleResult = await getPeopleDataUsingIDs(owners, false);
        this.setState({
          owners: peopleResult
        });
      }
    }

    this.updateAdminData();
  };

  componentDidUpdate = (prevProps) => {
    const { adminHistoryData } = this.props;
    if (JSON.stringify(adminHistoryData) !== JSON.stringify(prevProps.adminHistoryData)) {
      this.updateAdminData();
    }
  }

  getColumnClass = (isFixed, key) => {
    const {
      ADMIN_HISTORY_SECTION: {
        KEYS: {
          PERSON,
          TITLE,
        }
      }
    } = LABELS;
    let className = 'adminhistorymodal__column';
    if (isFixed) {
      className += ' adminhistorymodal__column--fixed';
    }
    if (key === PERSON) {
      className += ' adminhistorymodal__column--person';
    }
    if (key === TITLE) {
      className += ' adminhistorymodal__column--title';
    }
    return className;
  };

  updateAdminData = async () => {
    const {
      adminHistoryData,
      peopleResultsActions: {
        getPeopleDataUsingIDs
      }
    } = this.props;
    if (!!adminHistoryData) {
      const users = [];
      adminHistoryData.forEach(record => {
        //To do - Will connect it with team 
        if (record.user && record.user !== "0" && record.user !== "") {
          if (users.indexOf(record.user) === -1)
            users.push(record.user);
        }
      });
      let adminData = [];
      if (users && users.length) {
        const peopleResult = await getPeopleDataUsingIDs(users, false);
        adminData = adminHistoryData.map(data => {
          if (data.user && peopleResult) {
            peopleResult.forEach(user => {
              if (user.id === data.user)
                data.un = user.preferredName;
            });
          }
          return data;
        })
      };
      this.setState({ adminData })
    }
  };

  render() {
    const {
      onRequestClose,
      isOpen,
      data,
      fields,
      header,
      adminHistoryTableKey
    } = this.props;
    const { owners, adminData } = this.state;
    return (
      <Modal
        isOpen={isOpen}
        className="adminhistorymodal"
        overlayClassName="drawfromright"
        onRequestClose={onRequestClose}
        closeTimeoutMS={500}
      >
        <section className="adminhistorymodal__header">
          <Image
            className="adminhistorymodal__headericon"
            src={AdditionalInfoIcon}
            alt={header}
          />
          <div className="adminhistorymodal__headertitle">{header}</div>
          <button className="adminhistorymodal__headerclose" onClick={onRequestClose}>
            <Image
              src={crossIcon}
            />
          </button>
        </section>
        <section>
          <div className="adminhistorymodal__columns">
            {Object.keys(fields).map(field => {
              const { fixed, label, key } = fields[field];
              return (
                <div key={field} className={this.getColumnClass(fixed, key)}>{label}</div>
              );
            })}
          </div>
          <div className="adminhistorymodal__content">
            {!!data && data.map((obj, index) => {
              return (
                !!obj &&
                <AdminHistoryModalList
                  key={uuidv4()}
                  people={owners}
                  data={obj}
                  columns={fields}
                  index={index}
                />
              )
            })}
          </div>
          <VersionListing
            adminHistoryData={adminData}
            adminHistoryTableKey={adminHistoryTableKey}
          />
        </section>
      </Modal>
    );
  }
};

AdminHistoryModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  fields: PropTypes.object.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  peopleResultsActions: bindActionCreators({ ...peopleResultsActions }, dispatch)
});

export default connect(
  null,
  mapDispatchToProps
)(AdminHistoryModal);
