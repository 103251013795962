import React from 'react';
import Modal from "components/shared/Modal";
import PropTypes from "prop-types";
import { Button } from "components/shared/Button";
import deleteIcon from "assets/images/delete-modal-icon.svg";
import "./DeleteModal.scss"

export const DeleteModal = ({
    heading,
    button1: {
        text: button1text,
        clickHandler: button1Click
    },
    button2: {
        text: button2text,
        clickHandler: button2Click
    },
    isOpen,
    onRequestClose
}) => {
    return (
        <Modal ariaHideApp={false} isOpen={isOpen} className="delete-modal" onRequestClose={onRequestClose}>
            <div className="delete-modal__title">
                <img src={deleteIcon} alt="delete icon" className="delete-modal__img" />
                <h5 className="delete-modal__heading">{heading}</h5>
            </div>
            <div className="delete-modal__btn-group">
                {button1text && <Button className="black delete-modal__btn" onClick={button1Click}>{button1text}</Button>}
                {button2text && <Button className="red delete-modal__btn" onClick={button2Click}>{button2text}</Button>}
            </div>
        </Modal>
    )
};

DeleteModal.propTypes = {
    heading: PropTypes.string.isRequired,
    isOpen: PropTypes.bool,
    onRequestClose: PropTypes.func,
    button1: PropTypes.object,
    button2: PropTypes.object
};

DeleteModal.defaultProps = {
    isOpen: false,
    onRequestClose: () => { },
    button1: {
        text: false,
        clickHandler: () => { }
    },
    button2: {
        text: false,
        clickHandler: () => { }
    },
};