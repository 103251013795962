import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from 'uuid';

//config
import LABELS from "labels";

//images
import crossIcon from "assets/images/cross/cross.svg";
import slide1Image from "assets/images/help-steps/slide1/slide1_2x.png";
import slide2Image from "assets/images/help-steps/slide2/slide2_2x.png";
import announcementImage from "assets/images/announcement/announcement_2x.svg";

//shared components
import Image from "components/shared/Image";
import Carousel from "components/shared/Carousel/Carousel";
import Modal from "components/shared/Modal";

//scss
import "./HelpModal.scss";

export const HelpModal = ({ isOpen, onRequestClose }) => {
  const {
    steps,
    announcementText,
    heading,
    subheading,
  } = LABELS.HELP_MODAL;

  const getSlides = () => (
    <Carousel>
      <div className="slide1">
        <div className="slide-wrapper">
          {
            steps.map((value, index) => (
              index < 3 &&
              <div className="step-text" id={'step:' + index} key={uuidv4()}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <strong>{value.stepmsg} {index + 1}: </strong> {value.message} {value?.message_link ? <a href={value?.link_destination} rel="noopener noreferrer" target="_blank" className="hlink">{value.message_link}</a> : ""}
              </div>
            ))
          }
          <Image src={slide1Image} className="slide1Img" alt="Step 1 image" />
        </div>
      </div>
      <div className="slide2">
        <div className="slide-wrapper">
          {
            steps.map((value, index) => (
              index >= 3 &&
              <Fragment key={uuidv4()}>
                {index + 1 === steps.length ? <Image src={slide2Image} className="slide2Img" alt="Step 2 image" /> : ''}
                <div className="step-text" id={'step:' + index}>
                  <strong>{value.stepmsg} {index + 1}: </strong> {value.message}
                </div>
              </Fragment>
            ))
          }
        </div>
        <div className="announcement-text">
          <Image src={announcementImage} alt="Announcement image" />
          <span className="heading">{announcementText.message_link}</span>
          <div>{announcementText.message1}</div>
        </div>
      </div>
    </Carousel >
  );

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      className="help-modal"
      onRequestClose={onRequestClose}
    >
      <div className="help-modal__header">
        <h5 className="heading">{heading}</h5>
        <span onClick={onRequestClose} className="btn-closemodal">
          <Image src={crossIcon} alt="cross icon" />
        </span>
      </div>
      <div className="help-modal__body">
        <div className="heading">{subheading}</div>
        <div className="content carousel-howto">{getSlides()}</div>
      </div>
      <div className="help-modal__footer">
      </div>
    </Modal>
  );
};

HelpModal.propTypes = {
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
};

HelpModal.defaultProps = {
  isOpen: false,
  onRequestClose: () => { },
};
