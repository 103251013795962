// Constant
import { RECOMMENDED_TAGGING } from "redux/constants";

const {
    GET_TASK_REGISTER,
    API_TASK_REGISTER_PENDING,
    API_TASK_REGISTER_SUCCESS,
    API_TASK_REGISTER_FAILURE,
    GET_TAGGING_STATUS,
    API_TAGGING_STATUS_SUCCESS,
    API_TAGGING_STATUS_FAILURE,
    API_TAGGING_STATUS_PENDING,
    GET_RECOMMENDED_TAGS,
    API_RECOMMENDED_TAGS_PENDING,
    API_RECOMMENDED_TAGS_FAILURE,
    API_RECOMMENDED_TAGS_SUCCESS,
    API_RECOMMENDED_TAGS_RESET,


    POST_FEEDBACK_RECOMMENDED_TAGS,
    API_FEEDBACK_TAGS_PENDING,
    API_FEEDBACK_TAGS_SUCCESS,
    API_FEEDBACK_TAGS_FAILURE

} = RECOMMENDED_TAGGING;

export const taskRegisterPending = () => ({
    type: API_TASK_REGISTER_PENDING
});

export const taskRegisterSuccess = (res) => ({
    type: API_TASK_REGISTER_SUCCESS,
    payload: {
        res: res,
    }
});

export const taskRegisterFailure = (error) => ({
    type: API_TASK_REGISTER_FAILURE,
    payload: {
        error: error.toString(),
    }
});


export const getTaskRegister = (filePath) => ({
    type: GET_TASK_REGISTER,
    payload: {
        filePath
    }
});


export const taggingStatusPending = () => ({
    type: API_TAGGING_STATUS_PENDING
});

export const taggingStatusSuccess = (res) => ({
    type: API_TAGGING_STATUS_SUCCESS,
    payload: {
        res: res,
    }
});

export const taggingStatusFailure = (error) => ({
    type: API_TAGGING_STATUS_FAILURE,
    payload: {
        error: error.toString(),
    }
});


export const getTaggingStatus = (taskId) => ({
    type: GET_TAGGING_STATUS,
    payload: {
        taskId
    }
});




export const recommendedTagsPending = () => ({
    type: API_RECOMMENDED_TAGS_PENDING
});

export const recommendedTagsReset = () => ({
    type: API_RECOMMENDED_TAGS_RESET
});


export const recommendedTagsSuccess = (res) => ({
    type: API_RECOMMENDED_TAGS_SUCCESS,
    payload: {
        res: res,
    }
});

export const recommendedTagsFailure = (error) => ({
    type: API_RECOMMENDED_TAGS_FAILURE,
    payload: {
        error: error.toString(),
    }
});


export const getRecommendedTags = (taskId) => ({
    type: GET_RECOMMENDED_TAGS,
    payload: {
        taskId
    }
});




export const feedbackTagsPending = () => ({
    type: API_FEEDBACK_TAGS_PENDING
});

export const feedbackTagsSuccess = (res) => ({
    type: API_FEEDBACK_TAGS_SUCCESS,
    payload: {
        res: res,
    }
});

export const feedbackTagsFailure = (error) => ({
    type: API_FEEDBACK_TAGS_FAILURE,
    payload: {
        error: error.toString(),
    }
});


export const postFeedbackRecommendedTags = ({ taskId, type, feedbackData }) => ({
    type: POST_FEEDBACK_RECOMMENDED_TAGS,
    payload: {
        taskId,
        type,
        feedbackData
    }
});