import React from 'react';
import Image from "components/shared/Image";
import PropTypes from "prop-types";

import './InfoCard.scss';
import LABELS from 'labels';

const getImageBgColorClass = (showFullImage, imgBgColorClass) => {
  return showFullImage ? "" : imgBgColorClass;
}

const getImageShapeClass = (showFullImage, imgShape) => {
  if (showFullImage) {
    return "info-card__image--bound-with-radius";
  }
  return imgShape === "rectangle" ? "info-card__image--bound-rect" : "info-card__image--bound-square";
}

const {
  CP_CONTRIBUTION: {
    EMPTY_VALUE,
  }
} = LABELS

/**
* @desc returns an info card containing an image and text in rows and 
  text block containing a label and value in columns
* @returns
*/
export const InfoCard = ({
  showFullImage,
  imgBgColorClass,
  imgShape,
  imgPath,
  label,
  value
}) => {
  return (
    <section className="info-card">
      <div
        className={`info-card__image-wrap 
          ${getImageBgColorClass(showFullImage, imgBgColorClass)}
        `}
      >
        <Image
          className={`info-card__image 
            ${getImageShapeClass(showFullImage, imgShape)}
          `}
          src={imgPath}
          alt="infoCard Image"
        />
      </div>
      <div className="info-card__text">
        <span className="info-card__label">{label}</span>
        <span className="info-card__value">{value}</span>
      </div>
    </section>
  );
};

InfoCard.propTypes = {
  showFullImage: PropTypes.bool,
  imgPath: PropTypes.string,
  imgBgColorClass: PropTypes.string,
  imgShape: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string
};

InfoCard.defaultProps = {
  showFullImage: false,
  imgShape: "sqaure",
  imgBgColorClass: "info-card__image-wrap--bg-green",
  imagePath: "",
  label: "",
  value: EMPTY_VALUE
};