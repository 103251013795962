// Config
const LOCAL_CONFIG = {
  btnNext: {
    text: "Next",
    align: "right",
    classname: "btnnext",
    icon: "arrow right",
    iconAlign: "post",
    outFilled: false,
    visible: true,
    disabled: false,
    children: [],
    isClick: true,
  },
  btnPrev: {
    align: "right",
    icon: "arrow left",
    iconAlign: "pre",
    id: "btn-back",
    classname: "btnprev",
    text: "Back",
    link: "",
    outFilled: true,
    target: false,
    visible: true,
    disabled: false,
    children: [],
    isClick: true,
  },
};

// export default
export default LOCAL_CONFIG;
