import { CCO_DATA } from "redux/constants";
export const name = "pvtpeopleresults";

// initial state
export const initialState = {
  loading: false,
  error: false,
  errorMessage: "",
  clientContact: {
    primaryCCO: {
      clientName: ""
    }
  },
};

// reducer selectors
export const selectors = {
  getPrimaryCCO: state => state[name].clientContact?.primaryCCO,
};

const setCCOLoading = (state) => {
  return {
    ...state,
    loading: true,
    error: false,
    errorMessage: "",

  }
};

const setCCOError = (state, payload) => {
  return {
    ...state,
    loading: false,
    error: true,
    errorMessage: payload.error,
  }
};

const setCCOData = (state, payload) => {
  return {
    ...state,
    loading: false,
    clientContact: payload.clientContact,
    error: false

  }
};

// reducer
export function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case CCO_DATA.GET_CCO_RESULTS_FAILURE:
      return setCCOError(state, payload);
    case CCO_DATA.GET_CCO_RESULTS_PENDING:
      return setCCOLoading(state);
    case CCO_DATA.GET_CCO_RESULTS_SUCCESS:
      return setCCOData(state, payload);
    default:
      return state;
  }
}