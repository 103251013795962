import React from 'react';
import PropTypes from 'prop-types';
import { openURL } from "utils/helpers/helpers";
import './AssociatedCaseListing.scss';
import Image from "components/shared/Image";
import caseIcon from "assets/images/case_icon.svg";
import { ShowMore } from 'components/shared/ShowMore_Less/ShowMore';

import CONFIG from 'config';
import LABELS from 'labels';


const openConsumptionPage = (id) => {
  const { UI_URL: { CP_CONSUMPTION_NEW } } = CONFIG;
  const url = CP_CONSUMPTION_NEW(id);
  openURL(url, true);
}

const AssociatedCaseListing = ({ associatedCases, clickCallBack, caseItemsInitialLimit, caseItemHeight }) => {
  const { ASSOCIATED_CASES_NO_TITLE } = LABELS;
  return (
    <section className="caselisting">
      <ShowMore
        applyShowMore={associatedCases?.length > caseItemsInitialLimit ? true : false}
        height={caseItemHeight * caseItemsInitialLimit}>
        <ul>
          {associatedCases.map((item, index) => {
            const { caseType, caseNumber, id, sanitizedCaseTitle } = item;
            return (
              <li className="caselisting__item" key={id} onClick={() => {
                if (clickCallBack) clickCallBack(id, index);
                openConsumptionPage(id);
              }}>
                <div className="caselisting__left">
                  <div className="caselisting__iconwrap">
                    <Image
                      className="caselisting__icon"
                      src={caseIcon}
                      alt="icon"
                    />
                  </div>
                  <div className={`caselisting__leftcontent ${!caseType ? "caselisting__nocasetype" : ""}`}>
                    {caseType && <p><strong>{caseType}</strong></p>}
                    <p>{caseNumber}</p>
                  </div>
                </div>
                <div className="caselisting__right">
                  {sanitizedCaseTitle ? <p>{sanitizedCaseTitle}</p> : <p className='caselisting__notitle'>{ASSOCIATED_CASES_NO_TITLE}</p>}
                </div>
              </li>
            )
          })}
        </ul>
      </ShowMore>
    </section>
  );
};

AssociatedCaseListing.propTypes = {
  associatedCases: PropTypes.arrayOf(PropTypes.object),
};

AssociatedCaseListing.defaultProps = {
  associatedCases: [],
  caseItemsInitialLimit: 10,
  caseItemHeight: 78
};

export { AssociatedCaseListing }