import React, { Component } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import LABELS from 'labels';

export class ErrorBoundary extends Component {
	state = {
		error: null,
		errorInfo: null,
		hasError: false,
	};

	static getDerivedStateFromError = () => {
		return { hasError: true };
	};

	componentDidCatch = (error, errorInfo) => {
		/* eslint-disable */
		console.log("Oops, something went wrong :");
		console.log("The error: ", error?.toString());
		console.log("Where it occured: ", errorInfo?.componentStack);
		/* eslint-enable */
		datadogRum.addAction("uncaught_error", error?.toString());
		this.setState({ error, errorInfo });
	}

	render() {
		const { hasError, error, errorInfo } = this.state;
		const { children } = this.props;
		const { UNCAUGHT_ERROR_MSG } = LABELS;
		if (hasError) {
			return (
				<>
					<div>{UNCAUGHT_ERROR_MSG}</div>
					<div>The error: {error?.toString()}</div>
					<div>Where it occured: {errorInfo?.componentStack}</div>
				</>
			);
		}
		return children;
	}
}

// HOC wrapping the passed component
const withErrorBoundary = WrappedComponent => {
	class ErrorWrapper extends Component {
		render() {
			return (
				<ErrorBoundary>
					<WrappedComponent {...this.props} />
				</ErrorBoundary>
			);
		}
	}
	ErrorWrapper.displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
	return ErrorWrapper;
}

export default withErrorBoundary;