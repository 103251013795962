import { produce } from "immer";
const rootTerm = {
  id: "root",
  name: "root"
};
export const rootNode = {
  term: rootTerm,
  isOpen: true,
  isLeaf: false,
  children: [],
  error: null
};
export const findByPath = (node, path) => {
  if (path.length === 0) {
    return node;
  }

  const [childId, ...pathFromChild] = path;
  const lookedForChild = node.children ? node.children.find(node => node.term.id === childId) : null;

  if (lookedForChild) {
    return findByPath(lookedForChild, pathFromChild);
  } else {
    return null;
  }
};
export const treeReducer = produce((draftRoot, action) => {
  switch (action.type) {
    case "OPEN_NODE":
      {
        const {
          nodePath
        } = action;
        const node = findByPath(draftRoot, nodePath);

        if (node) {
          node.isOpen = true;
        }

        break;
      }

    case "CLOSE_NODE":
      {
        const {
          nodePath
        } = action;
        const node = findByPath(draftRoot, nodePath);

        if (node) {
          node.isOpen = false;
          node.error = null;
        }

        break;
      }

    case "SET_CHILDREN":
      {
        const {
          nodePath,
          children
        } = action;
        const node = findByPath(draftRoot, nodePath);

        if (node) {
          node.children = children;
        }

        break;
      }

    case "SET_ERROR":
      {
        const {
          nodePath,
          error
        } = action;
        const node = findByPath(draftRoot, nodePath);

        if (node) {
          node.error = error;
        }

        break;
      }

    default:
      break;
  }
});