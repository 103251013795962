import {
  GEN_AI,
} from 'redux/constants';

export const checkFileProcessing = (requestId, fileName) => ({
  type: GEN_AI.INIT_GENAI_KP_API,
  payload: { requestId, fileName },
});

export const kpMetagenAISuccess = (response) => ({
  type: GEN_AI.KP_METAGENAI_SUCCESS,
  payload: response
});

export const kpMetagenAIProgress = () => ({
  type: GEN_AI.KP_METAGENAI_PROGRESS
});

export const setShowGenAIIcon = (value) => ({
  type: GEN_AI.SET_SHOW_GEN_AI_ICON,
  payload: value,
});

export const addSuccessfulFileId = (fileId, fileName, title, description) => ({
  type: GEN_AI.ADD_SUCCESSFUL_FILE_ID,
  payload: { fileId, fileName, title, description },
});

export const kpMetagenAIFailure = (error) => ({
  type: GEN_AI.KP_METAGEN_AI_FAILURE,
  payload: error,
});