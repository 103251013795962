// Constant
import CONSTANTS from "globalConstants";
import { GLOBAL } from 'redux/constants'
const { ACTIONS: { GLOBAL_SAGA_FAILURE, GLOBAL_MODULE_FAILURE } } = CONSTANTS;
const { LOGGEDIN_USER_DATA } = GLOBAL;

export const globalSagaFailure = (error) => ({
    type: GLOBAL_SAGA_FAILURE,
    error
});

export const moduleFailure = (error) => ({
    type: GLOBAL_MODULE_FAILURE,
    error
});

export const setLoggedInUserData = (payload) => ({
    type: LOGGEDIN_USER_DATA,
    payload
});