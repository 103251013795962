import React, { memo, useCallback } from "react";
import ToggleChildren from "./ToggleChildren";
import { Tooltip } from "components/shared/Tooltip";
import Image from "components/shared/Image";
import infoIcon from 'assets/images/info-orange.svg';
import CONSTANTS from "globalConstants";
import { v4 as uuidv4 } from 'uuid';

const LoadingError = () => <div className="treeViewer__loading treeViewer__error">Failed to load data</div>;

const LoadingSpinner = () => <div className="treeViewer__loading">Loading...</div>;

const maybeCall =
  (callback, ...args) =>
    () =>
      callback && callback.apply(null, args);

const Term = ({
  node,
  path,
  onOpen,
  onClose,
  onClick,
  selectedObj,
}) => {
  const {
    KNOWLEDGE_PAGE_TYPE: {
      TOOLTIP_DESCRIPTION,
      TOOLTIP_ADDITIONAL_INFO
    }
  } = CONSTANTS;
  const { term, isLeaf, isOpen } = node;
  const handleToggleClick = useCallback(
    () => (isOpen ? onClose(path) : onOpen(path)),
    [isOpen, onOpen, onClose, path]
  );
  const getToolipContent = (arr) => {
    let filterArr = arr.filter(item => (item.name).toLowerCase() === TOOLTIP_DESCRIPTION || (item.name).toLowerCase() === TOOLTIP_ADDITIONAL_INFO);
    return filterArr.map(item => {
      if ((item.name).toLowerCase() === TOOLTIP_DESCRIPTION) {
        return <div className="treeViewer__term__tooltip__desciption" key={uuidv4()}>{item?.fields?.[0]?.field?.name || ""}</div>
      } else {
        return <div className="treeViewer__term__tooltip__additional_info" key={uuidv4()}>
          {/* <i className="fa fa-info-circle" /> */}
          <Image src={infoIcon} />
          <span>{item?.fields?.[0]?.field?.name || ""}</span></div>
      }
    });
  }

  return (
    <div className="treeViewer__term">
      {isLeaf ?
        <Tooltip
          id={term.id}
          className={"treeViewer__term__tooltip"}
          position="right"
          delayHide={0}
          icon={
            <div className={`treeViewer__term__details last-leaf-node ${selectedObj.id === term.id ? "selected" : ""}`}>
              <button
                className="treeViewer__term__details__text"
                onClick={maybeCall(onClick, term)}
              >
                {term.name}
              </button>
            </div>
          }
        >
          <div className="treeViewer__term__tooltip__txt">
            {term?.equivalence?.length > 0 && getToolipContent(term.equivalence)}
          </div>
        </Tooltip>
        :
        <>
          <div
            className="treeViewer__term__details"
            onClick={handleToggleClick}
          >
            <ToggleChildren name={term.name} isOpen={isOpen} />
            <button className="treeViewer__term__details__text" >
              {term.name}
            </button>
          </div>
          <TermChildren
            node={node}
            path={path}
            onClick={onClick}
            onOpen={onOpen}
            onClose={onClose}
            selectedObj={selectedObj}
          />
        </>
      }
    </div>
  );
};

const TermChildren = ({
  node,
  path,
  onOpen,
  onClose,
  onClick,
  selectedObj,
}) => {
  const { isOpen, children, error } = node;
  return isOpen ? (
    <div className="term-children">
      {error != null ? (
        <LoadingError />
      ) : children.length === 0 ? (
        <LoadingSpinner />
      ) : (
        <>
          {children.map((childNode) => (
            <Term
              key={childNode.term.id}
              node={childNode}
              path={[...path, childNode.term.id]}
              onClick={onClick}
              onOpen={onOpen}
              onClose={onClose}
              selectedObj={selectedObj}
            />
          ))}
        </>
      )}
    </div>
  ) : null;
};

export default memo(Term);
