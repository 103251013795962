import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

// components
import SupportingLink from 'components/shared/SupportingLink';
import { ShowMore } from 'components/shared/ShowMore_Less/ShowMore';

// styles
import './AdditionalLinks.scss';

export const AdditionalLinks = ({ links, heading, emptyListHeading, onLinkClick }) => {
  return (
    links.length > 0 ?
      <>
        <span className="additionallink__heading">{heading}</span>
        <ShowMore height={890}>
          {links.map((link, index) => {
            return (
              <section className="additionallink" key={uuidv4()}>
                <SupportingLink
                  deleteIconInline={false}
                  deleteIconOutside={false}
                  item={link}
                  onLinkClick={onLinkClick}
                />
              </section>
            );
          })}
        </ShowMore>
      </> : <span className="additionallink__heading">{emptyListHeading}</span>
  );
};

AdditionalLinks.propTypes = {
  links: PropTypes.array,
  heading: PropTypes.string,
  emptyListHeading: PropTypes.string,
  onLinkClick: () => { },
};

AdditionalLinks.defaultProps = {
  links: [],
  heading: '',
  emptyListHeading: '',
  onLinkClick: () => { },
};
