/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { Tab as SemanticTab, Menu, Label, Accordion, Icon, Button } from "semantic-ui-react";
import "./TabComponent.scss";
import { map } from "lodash";
import { Pane } from "./Pane";
import { Tooltip } from "components/shared/Tooltip";
import withErrorBoundary from "containers/ErrorBoundary/ErrorBoundary";

export function TabComponentWrapper({
  defaultActiveIndex = 0,
  arrayOfPaneElements,
  getActiveIndex,
  onTabChangeCallback,
  wrapperClassName,
  hideCount,
  isCollapsible,
  ...props
}) {
  const [activeIndex, setActiveIndex] = useState(defaultActiveIndex);
  const [collapsed, setCollapsed] = useState(false);
  const [rightOffset, setRightOffset] = useState(0);
  const tabsRef = useRef(null);

  const sendData = useCallback((activeIndex) => {
    if (getActiveIndex) {
      getActiveIndex(activeIndex);
    }
  }, [getActiveIndex]);

  useEffect(() => {
    sendData(activeIndex);
  }, [activeIndex, sendData]);

  useEffect(() => {
    setActiveIndex(defaultActiveIndex);
  }, [defaultActiveIndex]);

  useEffect(() => {
    if (tabsRef.current && window.getComputedStyle) {
      const element = tabsRef.current.children[0]?.children[0];
      const computedStyle = window.getComputedStyle(element);
      const offset = parseFloat(computedStyle?.paddingRight) + 5;
      setRightOffset(offset);
    } else {
      setRightOffset(0);
    }
  }, [tabsRef]);

  return (
    <section className={`tabs ${wrapperClassName ? wrapperClassName : ''}`} ref={tabsRef}>
      <SemanticTab
        className="tabs__container"
        activeIndex={activeIndex}
        // eslint-disable-next-line react/jsx-no-bind
        onTabChange={(e, { activeIndex: newActiveIndex }) => {
          setActiveIndex(newActiveIndex);
          if (onTabChangeCallback) {
            onTabChangeCallback(e, newActiveIndex, activeIndex);
          }
        }}
        panes={getPanesStructure(arrayOfPaneElements, hideCount, collapsed)}
        {...props}
      />
      {isCollapsible && <Button className="tabs__toggle" style={{ right: rightOffset }} type="button">
        {collapsed ?
          <Icon name='chevron down' onClick={() => setCollapsed(false)} />
          : <Icon name='chevron up' onClick={() => setCollapsed(true)} />
        }
      </Button>}
    </section>
  );
}

function getPanesStructure(arrayOfPaneElements, hideCount, collapsed) {
  return map(
    arrayOfPaneElements,
    ({ className, heading, count, component, disable, hiddenClassName = "", hideIndiviualCount, tooltipMessage = "" }, index) => {
      let accordionSec = null;
      if (tooltipMessage) {
        accordionSec = (<Tooltip
          id={`${index}`}
          position="right"
          icon={
            <><Accordion className={className}>{heading}</Accordion></>
          }
          delayHide={0}
          data-multiline={true}
          className="customTooltipClass"
        >
          {tooltipMessage}
        </Tooltip>)
      } else {
        accordionSec = (<Accordion className={className}>{heading}</Accordion>)
      }
      return !disable
        ? {
          menuItem: (
            <Menu.Item key={index} className={hiddenClassName + " message"}>
              {accordionSec}
              {!hideCount && !hideIndiviualCount && <Label className="label__count">{count}</Label>}
            </Menu.Item>
          ),
          render: () => <Pane className={collapsed ? "displaynone" : hiddenClassName}>{component}</Pane>,
        }
        : {
          menuItem: (
            <Menu.Item
              key={index}
              className={hiddenClassName + " leftElement"}
              disabled
              as="span"
            >
              {accordionSec}
              {!hideCount && !hideIndiviualCount && <Label className="leftElement__count">{count}</Label>}
            </Menu.Item>
          ),
        };
    }
  );
}

export const TabComponent = withErrorBoundary(TabComponentWrapper);