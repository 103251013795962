import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Pill } from "components/shared/Pill";
import { Tooltip } from "components/shared/Tooltip";
import LABELS from "labels";
// config
import G_CONFIG from "config";
import GLOBAL_CONSTANTS from "globalConstants";
import { copyObject } from "utils/helpers/helpers";

import "./Custodian.scss";

const Custodian = ({
  data,
  clickHandler,
  tooltipHtml,
  className,
  showToolTip,
  getSLparentTags,
  parentTagsData,
  callSlParentTag,
  resetCallSlParentTagFlag
}) => {
  const [custodianPath, setCustodianPath] = useState({});
  const {
    METADATA: {
      SECTOR_OWNER_TITLE
    },
    TAXONOMY: {
      TOOLTIPS: { CUSTODIAN_POLY_MSG }
    }
  } = LABELS;

  const {
    TBDBS: {
      SEARCH_FPA_IPA_INTERNAL,
    }
  } = GLOBAL_CONSTANTS;

  useEffect(() => {
    if (callSlParentTag) {
      getSLparentTags(G_CONFIG.TBDB_IDS[SEARCH_FPA_IPA_INTERNAL], data.map(item => item.id));
      resetCallSlParentTagFlag(false);
    }
  }, [callSlParentTag])

  useEffect(() => {
    const mergeParentTagsData = showToolTip && data.length ? mergeParent(data, parentTagsData) : [];
    const setHierarchicalPath = fnToSetPath(mergeParentTagsData);
    setCustodianPath(setHierarchicalPath)
  }, [parentTagsData])

  const fnToSetPath = (parentData = []) => {
    let obj = {};
    if (parentData && parentData.length > 0) {
      data.map((item, index) => {
        obj[item.id] = parentData[index];
        return item;
      })
    }
    return obj;
  }

  const mergeParent = (tagsTemp, parentTagsTemp) => {
    let tags = copyObject(tagsTemp);
    if (parentTagsTemp && parentTagsTemp.length) {
      let parentTags = copyObject(parentTagsTemp);

      const mergedTags = parentTags.map((item, index) => {
        item.pop();
        return [...item, tags[index]];
      });
      return mergedTags;
    }
  }

  const getPolyToolTipMsg = (taxonomyPath = [], isPolyH) => {
    if (taxonomyPath && taxonomyPath?.length > 0) {
      return (
        <div>
          <p>{isPolyH ? CUSTODIAN_POLY_MSG : ''} {taxonomyPath.map(item => {
            let myNAme = '';
            if (item) {
              myNAme = item.name || item.label;
            }
            return (
              <><span class="pill__arrow-right"></span> {myNAme}</>
            )
          })}</p>
        </div>
      )
    }
    return null;
  }

  return (
    <>
      {!!data.length && (
        <div className="pa-custodian-wrap">
          <label>{SECTOR_OWNER_TITLE}</label>
          {tooltipHtml && (
            <Tooltip id="taxonomy_tooltip_custodian">{tooltipHtml}</Tooltip>
          )}
          <div className={className}>
            <div className="custodian">
              {
                data.map(
                  pilldata => <Pill
                    key={pilldata?.id}
                    defaultText={pilldata?.label}
                    id={pilldata?.id}
                    active={pilldata?.isOwner}
                    onClickHandler={() => clickHandler(pilldata?.id)}
                    //toolTipMsg={getPolyToolTipMsg(pilldata?.taxonomyPath, pilldata?.isPolyHierarchi)}
                    toolTipMsg={getPolyToolTipMsg(custodianPath[pilldata?.id], pilldata?.isPolyHierarchi)}
                    showToolTip={showToolTip}
                    showPolyIcon={pilldata?.isPolyHierarchi}
                    dataPath={pilldata?.taxonomyPath}
                  />)
              }
            </div>
          </div>
        </div>
      )}
    </>
  );
};

Custodian.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    isOwner: PropTypes.bool,
  })).isRequired,
  clickHandler: PropTypes.func.isRequired,
  className: PropTypes.string,
  showToolTip: PropTypes.bool,
  getSLparentTags: PropTypes.func,
  parentTagsData: PropTypes.any,
  callSlParentTag: PropTypes.bool,
  resetCallSlParentTagFlag: PropTypes.func
};

Custodian.defaultProps = {
  className: "",
  showToolTip: false,
  getSLparentTags: () => { },
  parentTagsData: [],
  callSlParentTag: false,
  resetCallSlParentTagFlag: () => { }
};

export default Custodian;
