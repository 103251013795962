import React, { useState } from 'react';
import RichEditor from "components/shared/RichEditor/";
import { Tooltip } from "components/shared/Tooltip";
import Image from "components/shared/Image";
import editIcon from "assets/images/ProposalBio_edit.svg";
import LABELS from "labels";
import warningIcon from "assets/images/warning/warning.svg";
import './BulletEdior.scss';
import { replaceInLabel } from 'utils/helpers/helpers';

let editorReferece = false;

export const BulletEditor = (
    {
        baseHtml,
        maximumLines,
        oneLineHeight = 21,
        id,
        nonGreenHeading,
        toolbarHidden,
        callbackEdit,
        disableEdit,
        heading
    }) => {
    const [isEditable, setEditable] = useState(false);
    const [html, setHtml] = useState(baseHtml);
    const [extaLines, setExtaLines] = useState(0);

    const {
        PROPOSAL_BIO: {
            MAXIMUM_LIMIT_REACHED,
            EDIT_MSG
        },
    } = LABELS;



    const handleOnblur = (value, maxlines) => {
        const currentNoOfLines = getCurrentNoOfLines();
        if (maxlines < currentNoOfLines) {
            const extra = currentNoOfLines - maxlines;
            setExtaLines(extra);
        } else {
            setExtaLines(0);
            setHtml(value);
            setEditable(false);
            if (callbackEdit)
                callbackEdit(false);

        }

    }
    const handleCondition = condition => (condition ? "handled" : "not-handled");

    const getCurrentNoOfLines = () => {
        const boxHeight = document.querySelector(`.${id} > .rich__editor .DraftEditor-root`)?.offsetHeight;
        const currentNoOfLines = boxHeight / oneLineHeight;
        return Math.round(currentNoOfLines);
    }

    const handleBeforeInput = (maxlines) => handleCondition(maxlines <= getCurrentNoOfLines());

    const handleKeyCommand = (maxlines, command) => handleCondition(command.toUpperCase().trim() === "SPLIT-BLOCK" && maxlines <= getCurrentNoOfLines());

    const handlePastedText = (maxlines, text) => {
        const currentNoOfLines = getCurrentNoOfLines();
        // console.log("maxlines", maxlines, text, text.length, currentNoOfLines);
        return handleCondition((maxlines - 1) < currentNoOfLines);
    };
    const defaultSectionHeight = (maximumLines - 1) * oneLineHeight;

    return (
        <div className="bulleteditor">
            {!!heading && (
                <h5 className={`bulleteditor__heading ${!!nonGreenHeading ? "bulleteditor__heading-black" : ""}`}>
                    {heading}
                    <Tooltip
                        id="role-tooltip"
                        position="top"
                        disabled={disableEdit}
                        icon={
                            <Image
                                src={editIcon}
                                className="bulleteditor__edit_icon"
                                onClick={() => {
                                    if (!disableEdit) {
                                        setEditable(true);
                                        setTimeout(() => {
                                            editorReferece?.focus()
                                        }, 50);
                                        if (callbackEdit)
                                            callbackEdit(true);
                                    }
                                }}
                                disabled={disableEdit}
                            />
                        }
                    >
                        {EDIT_MSG}
                    </Tooltip>
                </h5>
            )}
            {isEditable ?
                <div className={`${!!extaLines ? "bulleteditor__error" : ""}`} style={{ minHeight: defaultSectionHeight }}>
                    <RichEditor
                        initialState={html}
                        updatedKey={"updatedKey"}
                        handleOnblur={(e) => handleOnblur(e, maximumLines)}
                        overRideSettings={{
                            toolbarHidden: toolbarHidden,
                            toolbar: {
                                options: ["inline", "list"],
                                inline: {
                                    inDropdown: false,
                                    options: []
                                },
                                list: {
                                    inDropdown: false,
                                    options: ['unordered']
                                }
                            },
                            wrapperClassName: "editpoceditor " + id,
                            handleBeforeInput: () => handleBeforeInput(maximumLines),
                            handleKeyCommand: (command) => handleKeyCommand(maximumLines, command),
                            handlePastedText: (text) => handlePastedText(maximumLines, text),
                            editorRef: (ref) => { editorReferece = ref },
                            stripPastedStyles: true
                        }}
                    />
                    {!!extaLines && <p className="bulleteditor__errormsg">
                        <Image src={warningIcon}
                            className='bulleteditor__warningIcon'
                        />
                        {replaceInLabel(MAXIMUM_LIMIT_REACHED, "{extaLines}", extaLines)}
                    </p>}
                </div>
                :
                <div className="contentsec" style={{ minHeight: defaultSectionHeight }}>
                    <div dangerouslySetInnerHTML={{ __html: html }}>
                    </div>
                </div>
            }
        </div >

    );
}