import React, { useState } from "react";
import PropTypes from "prop-types";
import { Icon } from 'semantic-ui-react';
import Image from "components/shared/Image";
import { Button } from "components/shared/Button";
// Constants
import GLOBAL_CONSTANTS from "globalConstants";
// Icons
import CrossIcon from "assets/images/cross/cross.svg";
// Styles
import "./AssistCross.scss";

const { SECTION1, SECTION2 } = GLOBAL_CONSTANTS;

const AssistCross = ({
  section1,
  section2,
  sectionToToggle,
  isDefaultExpanded,
}) => {
  const [ isExpanded, setIsExpanded ] = useState(isDefaultExpanded);
  return (
    <div className="assistCrossWrapper">
      {isExpanded ?
        <Image role="button" onClick={() => setIsExpanded(false)} src={CrossIcon} alt="cross" className="assistCrossWrapper__cross" />
        :
        <Button onClick={() => setIsExpanded(true)} type="button" className="assistCrossWrapper__assistBtn">
          ASSIST
          <Icon name="arrow right" className="button" />
        </Button>
      }
      <div className="sectionsWrap">
        {(sectionToToggle !== SECTION1 || isExpanded) && section1}
        {(sectionToToggle !== SECTION2 || isExpanded) && section2}
      </div>
    </div>
  );
};

AssistCross.propTypes = {
  section1: PropTypes.element.isRequired,
  section2: PropTypes.element.isRequired,
  sectionToToggle: PropTypes.oneOf([SECTION1, SECTION2]),
  isDefaultExpanded: PropTypes.bool,
};

AssistCross.defaultProps = {
  isDefaultExpanded: true,
  sectionToToggle: SECTION2,
};

export default AssistCross;