import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Image from "components/shared/Image";
import { Attachment } from "components/shared/Attachment";
import { showPreviewIcon } from "utils/helpers/helpers";
import PreviewIcon from "assets/images/preview.svg";
import DeleteIcon from "assets/images/delete.svg";

import "./KnowledgeItem.scss";

export class KnowledgeItem extends PureComponent {
  getIconImage = (iconName) => {
    let image;
    switch (iconName) {
      case "preview":
        image = PreviewIcon;
        break;
      case "delete":
        image = DeleteIcon;
        break;
      default:
        image = false;
        break;
    }
    return image;
  };
  render() {
    const {
      icon1: {
        icon: icon1img,
        callBack: icon1CallBack,
        url: urlIcon1,
        disabled: icon1Disabled,
      },
      icon2: {
        icon: icon2img,
        callBack: icon2CallBack,
        disabled: icon2Disabled,
      },
      item,
      ind,
    } = this.props;
    const icon1Image = this.getIconImage(icon1img);
    const icon2Image = this.getIconImage(icon2img);
    const previewVisible = showPreviewIcon(item, true, false, false);
    return (
      <div className="addedki">
        <div className="addedki__content">
          <Attachment item={item} />
        </div>
        <div className="addedki__ctas">
          <div className="addedki__ctasInner">
            {!urlIcon1 && icon1Image && previewVisible && (
              <button
                className={`addedki__ctas--preview ${icon1Disabled ? "addedki__ctasdisabled" : ""
                  }`}
                onClick={() => icon1CallBack(ind)}
              >
                <Image
                  src={this.getIconImage(icon1img)}
                  alt="Preview Knowledge Object"
                />
              </button>
            )}

            {urlIcon1 && icon1Image && (
              <a
                href={`${urlIcon1}{${ind}}`}
                className={`addedki__ctas--preview ${icon1Disabled ? "addedki__ctasdisabled" : ""
                  }`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  src={this.getIconImage(icon1img)}
                  alt="Preview Knowledge Object"
                />
              </a>
            )}

            {icon2Image && (
              <button
                className={`addedki__ctas--delete ${icon2Disabled ? "addedki__ctasdisabled" : ""
                  }`}
                onClick={() => icon2CallBack(ind)}
              >
                <Image
                  src={this.getIconImage(icon2img)}
                  alt="Delete Knowledge Object from Bundle"
                />
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

KnowledgeItem.propTypes = {
  item: PropTypes.object,
  icon1: PropTypes.object,
  icon2: PropTypes.object,
};

KnowledgeItem.defaultProps = {
  icon1: { icon: "", callBack: () => { } },
  icon2: { icon: "", callBack: () => { } },
};
