import React, { useState } from "react";
import {FCG_ProposalBiov1} from "containers/FCG_ProposalBio/FCG_ProposaBio";
import ProposalBioSlide from "containers/ProposalBioSlide/ProposalBioSlide";

const ProposalBioContainer = ({ selectedTemplateTitle, handleClose }) => {
	const [isGrayscale, setIsGrayscale] = useState(true);
	const [isSquareShape, setIsSquareShape] = useState(true);
	const [proposalPeopleData, setProposalPeopleData] = useState(null);

	const handleToggleGrayscale = () => {
		setIsGrayscale((prev) => !prev);
	};

	const handleToggleShape = () => {
		setIsSquareShape((prev) => !prev);
	};

	const handleClearProposalData = () => {
		setProposalPeopleData(null);
	};

	return (
		<div className="ProposalBioContainer">
			<FCG_ProposalBiov1
				selectedTemplateTitle={selectedTemplateTitle}
				handleClose={handleClose}
				handleClearProposalData={handleClearProposalData}
				onToggleGrayscale={handleToggleGrayscale}
				onToggleShape={handleToggleShape}
				isGrayscale={isGrayscale}
				isSquareShape={isSquareShape}
				onPreviewData={setProposalPeopleData}
			/>
			<ProposalBioSlide
				peopleData={proposalPeopleData}
				isGrayscale={isGrayscale}
				isSquareShape={isSquareShape}
			/>
		</div>
	);
};

export default ProposalBioContainer;
