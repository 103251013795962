import React from "react";
import LABELS from "labels";
import CONFIG from "config";
import "./TimeAndBillingInfo.scss";

const TimeAndBillingInfo = ({ caseInfo = {} }) => {
  const { unsanitizedCaseDesc = "", caseNumber } = caseInfo;
  const {
    CP_CONTRIBUTION: {
      STEP1_FIELDS: {
        T_AND_B,
        CAPTURE_TOOL,
        OFFICE,
        OFFICE_LINK,
        CAPTURE_LINK,
        T_AND_B_DESC,
      }
    }
  } = LABELS;
  const {
    CASE_CAPTURE_LINK,
    CASE_E_OFFICE_LINK
  } = CONFIG;
  return (
    <div className="timeAndBillWrapper">
      <section>
        <label>{T_AND_B}</label>
        <div className="timeAndBillWrapper__tbdata">
          <label className="timeAndBillWrapper__subtitle">{T_AND_B_DESC}</label>
          <p className="timeAndBillWrapper__value">{unsanitizedCaseDesc}</p>
        </div>
      </section>
      <section>
        <label>{CAPTURE_TOOL}</label>
        <div className="timeAndBillWrapper__tbdata">
          <a target="__blank" rel="noopener noreferrer" className={!caseNumber ? "timeAndBillWrapper__disabledLink" : ""} href={CASE_CAPTURE_LINK(caseNumber)}>{CAPTURE_LINK}</a>
        </div>
      </section>
      <section>
        <label>{OFFICE}</label>
        <div className="timeAndBillWrapper__tbdata">
          <a target="__blank" rel="noopener noreferrer" className={!caseNumber ? "timeAndBillWrapper__disabledLink" : ""} href={CASE_E_OFFICE_LINK(caseNumber)}>{OFFICE_LINK}</a>
        </div>
      </section>
    </div>
  );
}

export default TimeAndBillingInfo;