import React from "react";
import PropTypes from "prop-types";
import Unsupported from "assets/images/unsupported-preview-noslider.svg";
import Image from "components/shared/Image";
import { Docviz } from "containers/Docviz";
import LABELS from "labels";
import { checkIfAllQueryStringsExist, isValidDocvizFile, checkOrAddProtocolToUrl } from "utils/helpers/helpers";
import { Icon } from 'semantic-ui-react';
import { Tooltip } from "components/shared/Tooltip";

import "./KPSideBar.scss";
import CONFIG from 'config';

export class KPSideBar extends React.PureComponent {
	//state = {};
	constructor(props) {
		super(props);
		this.state = {
			isEntitled: true
		}
	}

	setEntitled = (isEntitled) => {
		this.setState({ isEntitled });
	}

	getIsCardScrollView = () => {
		const { kpMetaData, docvizDoc } = this.props;
		const { attachment: { fileName } } = kpMetaData;
		const isValidDocviz = isValidDocvizFile(fileName);
		const { isEntitled } = this.state;
		const { DOCVIZ_SCROLL_VIEW_EXTNS } = CONFIG;
		let showUnsupportedPreview = isEntitled && !isValidDocviz;
		if (docvizDoc && docvizDoc.attachmentFileType && (DOCVIZ_SCROLL_VIEW_EXTNS.indexOf(docvizDoc.attachmentFileType) > -1) && !showUnsupportedPreview && isEntitled) {
			return true;
		}
		return false;
	}

	render() {
		const { PREVIEW_HEADING , PREVIEW_TOOLTIP, PREVIEW_MESSAGE} = LABELS.KP;
		const {
			kpMetaData,
			docvizDoc,
			pollingData,
			initiatePolling,
			isMDPRestricted,
			startFileDownload,
			downloadApplicationName,
			activeStep,
			isKpContributionPage,
			showFrameworkWarning,
			isTagFrameworkEntitlement,
			getKPFile
		} = this.props;
		const {
			isURLAdded,
			materialUrl: {
				address,
				title: materialTitle
			},
			attachment: {
				fileName
			}
		} = kpMetaData;
		const isValidDocviz = isValidDocvizFile(fileName);
		const { EMBEDDED_VIDEO_URL_TYPE } = CONFIG;
		const isEmbeddedUrl = checkIfAllQueryStringsExist(address, EMBEDDED_VIDEO_URL_TYPE);
		return (
			<div className="KPSideBar__wrap">
				<div className="KPSideBar__preview">
					{
						isURLAdded === '0' &&
						<>
							<h5>{PREVIEW_HEADING}</h5>
							<Docviz
								kpMetaData={kpMetaData}
								docvizDoc={docvizDoc}
								isConsumptionView={false}
								isDocvizNotSupported={!isValidDocviz}
								pollingData={pollingData}
								initiatePolling={initiatePolling}
								isMDPRestricted={isMDPRestricted}
								download={startFileDownload}
								downloadApplicationName={downloadApplicationName}
								activeStep={activeStep}
								isKpContributionPage={isKpContributionPage}
								docvizActions={{
									showDownload: true,
									showPreview: true,
									showEyePreview: false,
									showShare: false,
									showEmail: false
								}}
								showFrameworkWarning={showFrameworkWarning}
								isTagFrameworkEntitlement={isTagFrameworkEntitlement}
								getKPFile={getKPFile}
								//showCardScrollView
								showCardScrollView={isKpContributionPage && this.getIsCardScrollView()}
								setEntitled={this.setEntitled}
								isEntitled={this.state.isEntitled}
							/>
						</>
					}
					{
						isURLAdded === '1' && materialTitle &&
						<>
							<Tooltip labelClass="toolH" labelText={PREVIEW_HEADING}>
							<span>{PREVIEW_TOOLTIP}</span>
                           </Tooltip>
							{isEmbeddedUrl ?
								<>
									<iframe title={materialTitle} src={address} className="preview__frame" />
									<div className="KPSideBar__bottom">
										<Icon name="linkify" bordered className="slink__icon--link" />
										<a href={checkOrAddProtocolToUrl(address)} target="_blank" rel="noopener noreferrer" className="preview__materialLink">{materialTitle}</a>
									</div>
								</> :
								<>
								<Image className="fileIcon"
									src={Unsupported}
									alt="KP Placeholder Docviz Image"
								/>
							<div className="unsupportedText">
                                {PREVIEW_MESSAGE}
                            </div>
							<div className={`preview__document ${isEmbeddedUrl && 'preview__bottomfixed'}`}>
                                {materialTitle && (
                                <>
                                 <Icon name="linkify" bordered className="slink__icon--link" />
                                 <a href={checkOrAddProtocolToUrl(address)} target="_blank" rel="noopener noreferrer" onClick={this.handleLinkClick} className="preview__materialLink">{materialTitle}</a>
                                </>
                                )}
                            </div>
                            </>
							}
						</>
					}

				</div>
			</div>
		);
	}
}

KPSideBar.propTypes = {
	kpMetaData: PropTypes.shape().isRequired,
	docvizDoc: PropTypes.shape().isRequired,
	pollingData: PropTypes.shape(),
	isTagFrameworkEntitlement: PropTypes.bool.isRequired,

};
