import React from "react";
import PropTypes from "prop-types";
import Dropdown from 'components/shared/Dropdown';
import CONFIG from "config";
import LABELS from "labels";

import "./ActivitesFilters.scss";

export const ActivitesFilters = (props) => {
  const {
    selectedStatus,
    selectedDays,
    totalActivities,
    onStatusClick,
    onDaysChange
  } = props;
  const {
    MY_NAVIGATOR: {
      STATUS_FILTERS: {
        PA_RECOMMDS: LABEL_PA_RECOMMDS
      },
      SHOW
    },
  } = LABELS;
  const {
    KNOWLEDGE_STATUS: {
      DRAFT,
      NO_RESULTS
    },
    DAYS_FILTER_OPTIONS
  } = CONFIG;

  return (
    <div className="activitesfilters">

      <div className="activitesfilters__pills">
        <div
          id="status--parecommendations"
          className={`activitesfilters__status activitesfilters__status-pill${selectedStatus !== NO_RESULTS ? "--active" : ""}`}
          onClick={() => onStatusClick(DRAFT)}
        >
          {`${LABEL_PA_RECOMMDS} - ${totalActivities}`}
        </div>
      </div>

      <div className="activitesfilters__sort">
        <div className="activitesfilters__label">
          {SHOW}
        </div>
        <div className="activitesfilters__dropdown">
          <Dropdown
            fluid
            // defaultValue={PAGES}
            selection
            value={selectedDays}
            options={DAYS_FILTER_OPTIONS}
            onChange={(e, { value }) => { onDaysChange(value); }}
          />
        </div>
      </div>

    </div>

  )
};

ActivitesFilters.defaultProps = {
  selectedStatus: "",
  onStatusClick: () => { },
  totalActivities: 0,
  selectedType: "",
  onDaysChange: () => { }
}

ActivitesFilters.propTypes = {
  selectedStatus: PropTypes.string.isRequired,
  onStatusClick: PropTypes.func.isRequired,
  selectedType: PropTypes.string.isRequired,
  onDaysChange: PropTypes.func.isRequired
};