import React from 'react';
import { Popup } from 'semantic-ui-react';
import "./ArrowPopup.scss";

const ArrowPopup = ({ children, ...otherProps }) => {
    return (
        <Popup
            content={children}
            {...otherProps}
        />
    )
}

export { ArrowPopup }
