/* 
Sample Material page url:
 smartsearch : https://smartsearch.bcg.com/material/xxxxxxxx?attachmentId=yyyyyyyyy
 Navigator: http://bcgnavigator.bcg.com/Detail/material_detail.asp?Material_ID=xxxxxxxx 
*/
import CONSTANTS from 'config';

const CONFIG = {
  popConfirm: {
    heading:
      "You are about to remove this knowledge page from the bundle. Please confirm!",
    buttonText: {
      cancel: "Cancel",
      delete: "Delete",
    },
  },
  style: { top: "10px", position: "absolute", right: "5px" },
  buttonText: { add: "Add", delete: "Delete" },
  defaultValues: { searchbox: "" },
  defaultValidation: {
    searchbox: true,
    urlCustom: true,
    midCustom: true,
    noresultfound: false,
  },
  errorMsg: {
    default: `You can add maximum ${CONSTANTS.KNOWLEDGE_BUNDLE_LIMITS.KNOWLEDGE_ITEMS_MAXLIMIT} Knowledge Pages`,
    searchbox: "Please enter a search term, URL or Material Id",
    url: ["Invalid Material id", "Please Enter BCG Whitelisted URL"],
    noresultfound: "No match found",
  },
  inputType: "text",
  placeHolder: {
    title: "Search for Knowledge Pages using Title, filename, ID, or paste the URL",
    searchbox: "Search for Knowledge Pages using Title, filename, ID, or paste the URL",
    recentContributionsTitle: "Recent Contributions by you"
  },
  // e.g msn.com
  inspectUrl: (str) => /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/g.test(str),
  // e.g bcg.com
  inspectWhitelistedUrl: (str) => /^(http(s)?:\/\/.)?(www\.)?([-a-zA-Z0-9@:%._+~#=]{2,256}\.)?(bcg.com|bcg.co|bcg.in)\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/g.test(str),
  // e.g https://minerva-qa.bcg.com/material/100090543?attachmentId=25132204
  inspectMaterialUrl: (str) => /^(http(s)?:\/\/)?[a-zA-Z0-9_.+-]+(?:(?:[a-zA-Z0-9-]+\.)?[a-zA-Z]+\.)?(bcg.com|bcg.co|bcg.in)|(material_detail.asp\?Material_ID=[0-9]+)?(material\/[0-9]+)?([0-9]+)$/g.test(str),
  // e.g 100090543
  inspectMaterialId: (str) => /^[0-9]{1,9}$/g.test(str),
  inspectKpId: (str) => {
    const matches = str.match(/(\{){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\}){0,1}/);
    return matches ? matches[0] : "";
  },
  findMaterialIdFromUrl: (str) => {
    let res, res1;
    if (str.indexOf("material/") > 0) {
      res = str.split("material/");
      res1 = res[1].split("?attach");
      return res1[0];
    } else if (str.indexOf("Material_ID=") > 0) {
      res1 = str.split("Material_ID=");
      return res1[1];
    } else {
      return str;
    }
  },
  docVizURL: (materialId, attachmentId) => {
    return (
      "https://smartsearch.bcg.com/material/" +
      materialId +
      "?attachmentId=" +
      attachmentId +
      "&showDocViz=true"
    );
  },
};
// export default Config
export default CONFIG;
