import React from "react";
import PropTypes from "prop-types";
import ReactModal from "react-modal";

ReactModal.setAppElement("body");

export const Modal = ({ isOpen, children, className, ...others }) => {
  return (
    <ReactModal
      isOpen={isOpen}
      overlayClassName="ReactModal__Overlay"
      className={`ReactModal__Content  ${className}`}
      // style={{
      //   content: {
      //     zoom: getZoomValue()
      //   }
      // }}
      {...others}
    >
      {children}
    </ReactModal>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool,
  className: PropTypes.string,
};

Modal.defaultProps = {
  isOpen: false,
  className: "",
};
