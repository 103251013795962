import React, { useState } from 'react';
import PropTypes from "prop-types";
import { Icon } from 'semantic-ui-react';
import { v4 as uuidv4 } from 'uuid';
import "./AllocationListing.scss";
import LABELS from 'labels';

export const AllocationListing = ({ content, topHeader, subTopHeader, subHeader, isCollpased, isCollapsible, noAllocation, source }) => {
    const [collapsed, setCollapsed] = useState(isCollpased);
    const contentLength = content?.length;

    const {
        CP_CONTRIBUTION: {
            ALLOCATION_LISTING: {
                DIGITAL_ANALYTICS,
                SAP
          }
        }
      } = LABELS;

    return (
        <div className="allocationlisting">
            {!!topHeader &&
                <div className="allocationlisting__topheading ">
                    <div className="allocationlisting__leftcontent allocationlisting__topheading--extended">
                        <span onClick={() => setCollapsed(!collapsed)} className="allocationlisting__topheading--pointer">{topHeader}</span>
                    </div>
                    <div className="allocationlisting__rightcontent allocationlisting__topheading--contracted">
                        {isCollapsible &&
                            <Icon name={`${collapsed ? 'chevron up' : 'chevron down'}`} onClick={() => setCollapsed(!collapsed)} />
                        }
                    </div>
                </div>
            }
            {!!collapsed &&
                <>
                    {!!subTopHeader && subTopHeader.length &&
                        <div className="allocationlisting__content--head">
                            <div className="allocationlisting__leftcontent">
                                {subTopHeader[0]}
                            </div>
                            <div className="allocationlisting__rightcontent">
                                {subTopHeader[1]}
                            </div>
                        </div>
                    }
                    <div className="allocationlisting__content">
                        {!!subHeader && !!content && !!contentLength &&
                            <div className="allocationlisting__content__subheading">
                                <div className="allocationlisting__leftcontent">
                                    {subHeader[0]}
                                </div>
                                <div className="allocationlisting__rightcontent">
                                    {subHeader[1]}
                                </div>
                            </div>
                        }
                        {content.map((item, index) => {
                            const { description, allocationPercent, sectors } = item;
                            return (
                                <div>                                    
                                {description === DIGITAL_ANALYTICS && source === SAP ? '' :
                                <section key={uuidv4()}>
                                    <div className="allocationlisting__content--head" >
                                     <div className="allocationlisting__leftcontent">
                                            {description}
                                        </div>   
                                        <div className="allocationlisting__rightcontent">
                                            {allocationPercent}
                                        </div>

                                    </div>
                                    {
                                        sectors && sectors.length > 0 &&
                                        sectors.map((nestedItem, index) => {
                                            const { description, allocationPercent } = nestedItem;
                                            return (
                                                <div className="allocationlisting__content--child" key={uuidv4()}>
                                                    <div className="allocationlisting__leftcontent">
                                                        {description}
                                                    </div>
                                                    <div className="allocationlisting__rightcontent">
                                                        {allocationPercent}
                                                    </div>

                                                </div>
                                            )
                                        })
                                    }
                                    {contentLength !== index + 1 && <span className="allocationlisting__separator" />}
                                </section>
                                }
                                </div>
                            )
                        })}
                        {
                            (!content || !contentLength) &&
                            <div className="allocationlisting__nocontent">{noAllocation}</div>
                        }
                    </div>
                </>
            }
        </div >
    )
};

AllocationListing.propTypes = {
    content: PropTypes.array.isRequired,
    topHeader: PropTypes.string,
    subHeader: PropTypes.array,
    subTopHeader: PropTypes.array,
    isCollpased: PropTypes.bool,
    isCollapsible: PropTypes.bool,
    noAllocation: PropTypes.string,
};

AllocationListing.defaultProps = {
    content: [],
    topHeader: "",
    isCollpased: true,
    isCollapsible: true,
    noAllocation: "",
}
