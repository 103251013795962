import React from 'react';
import './Loader.scss';
import Image from "components/shared/Image";
import inlineSpinner from "assets/images/spinnerinner.gif";
import PropTypes from "prop-types";
import LABELS from "labels";

const { 
    LOADING, 
    LOADING_ICON_ALT 
} = LABELS;

export const Loader = ({ 
    isVisible, 
    imgSrc, 
    text,
    wrapClass,
    textClass
}) => {
    return isVisible ? (
        <div className={`loader__wrap ${wrapClass}`}>
            <Image
                src={imgSrc}
                alt={LOADING_ICON_ALT}
            />
            <span className={`loader__text ${textClass}`}>{text}</span>
        </div>
    ) : null;
};

Loader.propTypes = {
    isVisible: PropTypes.bool,
    imgSrc: PropTypes.string,
    text: PropTypes.string,
    wrapClass: PropTypes.string,
    textClass: PropTypes.string
};

Loader.defaultProps = {
    isVisible: false,
    imgSrc: inlineSpinner,
    text: LOADING,
    wrapClass: "",
    textClass: ""
};