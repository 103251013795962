// React
import React from "react";
// PropTypes
import PropTypes from "prop-types";
// Sortable Hoc
import {
  SortableContainer,
  SortableElement,
  sortableHandle
} from "react-sortable-hoc";
//Scss
import "./DraggableList.scss";

const DragHandle = sortableHandle(() => (
  <span className="draggable__handler"></span>
));

const SortableItem = SortableElement(
  ({ item, countOfItems, ind, dragHandle, componentToDrag, componentFunc, customClass }) => (
    <div className={`${customClass ? customClass : 'draggable__item'}`}>
      {dragHandle && countOfItems > 1 && <DragHandle />}
      {componentToDrag ? (
        React.cloneElement(componentToDrag, { item, ind })
      ) : (
        componentFunc(item)
      )}
    </div >
  )
);

const SortableList = SortableContainer(
  ({ items, dragHandle, countOfItems, componentToDrag, componentFunc, customClass }) => (
    <div data-testid="draggable-items" className="draggable">
      {items.map((item, ind) => (
        <SortableItem
          key={`sortable-item-${ind}`}
          index={ind}
          ind={ind}
          item={item}
          countOfItems={countOfItems}
          dragHandle={dragHandle}
          componentToDrag={componentToDrag}
          componentFunc={componentFunc}
          customClass={customClass}
        />
      ))}
    </div>
  )
);

/**
 * @param {*} { items, onSort, dragHandle, componentToDrag }
 */
const DraggableList = ({
  items,
  onSort,
  dragHandle,
  countOfItems,
  componentToDrag,
  axis,
  componentFunc,
  customClass,
  onSortStart
}) => (
  <SortableList
    items={items}
    onSortStart={onSortStart}
    onSortEnd={onSort}
    useDragHandle={dragHandle}
    dragHandle={dragHandle}
    countOfItems={countOfItems}
    componentToDrag={componentToDrag}
    useWindowAsScrollContainer={true}
    axis={axis}
    componentFunc={componentFunc}
    customClass={customClass}
  />
);
// export default
export default DraggableList;

// DraggableList Prop Types
DraggableList.propTypes = {
  items: PropTypes.array,
  onSort: PropTypes.func,
  componentToDrag: PropTypes.object,
  dragHandle: PropTypes.bool,
  countOfItems: PropTypes.number
};

// DraggableList Default Props
DraggableList.defaultProps = {
  dragHandle: false,
  axis: 'y',
  componentFunc: () => { },
  customClass: ''
};