// constant
import { KNOWLEDGE_HISTORY } from "redux/constants";

// local variable
export const name = "knowledgeHistory";

// initial state
export const initialState = {
  error: false,
  errorMessage: "",
  adminHistory: [],
  isFetchingAdminHistory: false

};
// reducer selectors
export const selectors = {
  getAdminHistory: (state) => state[name].adminHistory,
};

// reducer
export function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case KNOWLEDGE_HISTORY.GET_ADMIN_HISTORY_SUCCESS:
      return {
        ...state,
        adminHistory: payload,
        isFetchingAdminHistory: false,
      }
    case KNOWLEDGE_HISTORY.GET_ADMIN_HISTORY_FAILURE:
      return {
        ...state,
        error: true,
        adminHistory: [],
        errorMessage: payload.error,
        isFetchingAdminHistory: false,
      }
    case KNOWLEDGE_HISTORY.GET_ADMIN_HISTORY_PENDING:
      return {
        ...state,
        isFetchingAdminHistory: true,
      }
    default:
      return state;
  }
}
