// Constant
import { PA_RECOMMENDED } from "redux/constants";

// is my role Pa recommender
export const isRolePARecommender = () => ({
    type: PA_RECOMMENDED.IS_ROLE_PA_RECOMMENDER
});

export const isRolePARecommenderPending = () => ({
    type: PA_RECOMMENDED.IS_ROLE_PA_RECOMMENDER_PENDING,
});

export const isRolePARecommenderSuccess = (payload) => ({
    type: PA_RECOMMENDED.IS_ROLE_PA_RECOMMENDER_SUCCESS,
    payload
});

export const isRolePARecommenderFailure = (error) => ({
    type: PA_RECOMMENDED.IS_ROLE_PA_RECOMMENDER_FAILURE,
    payload: { error: error?.toString() }
});


// is parecommended knowledge item
export const isPaRecommendedSuccess = (payload) => ({
    type: PA_RECOMMENDED.IS_PA_RECOMMENDED_SUCCESS,
    payload
});
export const isPaRecommended = (id) => ({
    type: PA_RECOMMENDED.IS_PA_RECOMMENDED,
    payload: { id }
});

export const isPaRecommendedPending = () => ({
    type: PA_RECOMMENDED.IS_PA_RECOMMENDED_PENDING,
});

export const isPaRecommendedFailure = (error) => ({
    type: PA_RECOMMENDED.IS_PA_RECOMMENDED_FAILURE,
    payload: { error: error?.toString() }
});

// UPDATE recommended tags list

export const updateRecommendPASuccess = (payload) => ({
    type: PA_RECOMMENDED.UPDATE_RECOMMEND_PA_SUCCESS,
    payload
});
export const updateRecommendPA = (data) => ({
    type: PA_RECOMMENDED.UPDATE_RECOMMEND_PA,
    payload: data
});

export const updateRecommendPAPending = () => ({
    type: PA_RECOMMENDED.UPDATE_RECOMMEND_PA_PENDING,
});

export const updateRecommendPAFailure = (error) => ({
    type: PA_RECOMMENDED.UPDATE_RECOMMEND_PA_FAILURE,
    payload: { error: error.error?.toString() }
});
