// Constant
import { MY_NAVIGATOR } from "redux/constants";


export const showContributionLoader = () => ({
    type: MY_NAVIGATOR.SHOW_CONTRIBUTION_LOADER
});

export const hideContributionLoader = () => ({
    type: MY_NAVIGATOR.HIDE_CONTRIBUTION_LOADER
});

export const getMyActivityListPending = (searchCriteria) => ({
    type: MY_NAVIGATOR.GET_MY_ACTIVITY_LIST_PENDING,
    payload: {
        searchCriteria
    }
});

export const getMyActivityListSuccess = (res) => ({
    type: MY_NAVIGATOR.GET_MY_ACTIVITY_LIST_SUCCESS,
    payload: res
});

export const getMyActivityListFailure = (error) => ({
    type: MY_NAVIGATOR.GET_MY_ACTIVITY_LIST_FAILURE,
    payload: {
        res: error.toString()
    }
});

export const getDownloadsCount = (kpids) => ({
    type: MY_NAVIGATOR.GET_DOWNLOADS_COUNT,
    payload: {
        kpids
    }
});
export const getDownloadsCountPending = (kpids) => ({
    type: MY_NAVIGATOR.GET_DOWNLOADS_COUNT_PENDING,
    payload: {
        kpids
    }
});

export const getDownloadsCountSuccess = (res) => ({
    type: MY_NAVIGATOR.GET_DOWNLOADS_COUNT_SUCCESS,
    payload: res
});

export const getDownloadsCountFailure = (error) => ({
    type: MY_NAVIGATOR.GET_DOWNLOADS_COUNT_FAILURE,
    payload: {
        res: error.toString()
    }
});

export const changeActivitiesPage = (searchCriteria) => ({
    type: MY_NAVIGATOR.CHANGE_ACTIVITIES_PAGE,
    payload: {
        searchCriteria
    }
});