import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'semantic-ui-react';

//Config
import LABELS from 'labels';

//Components
import Image from "components/shared/Image";

//Images
import InfoIcon from "assets/images/plusgreen.svg";

//SCSS
import "./KPGridShowMoreLess.scss";

const KPGridShowMoreLess = ({ isShowMore, handleShowMore }) => {

    const onShowMoreLessClick = (event, value) => {
        event.stopPropagation();
        handleShowMore(value)
    }

    return (
        <>
            {isShowMore ? (
                <div className="kpShowMore" onClick={(e) => onShowMoreLessClick(e, "show_more_clk")}>
                    <Image src={InfoIcon} className="kpShowMore__icon-plus" />
                    <Label className="kpShowMore__kpShowMore-label">
                        {LABELS.KPCONSUMPTIONGROUP.SHOW_MORE}
                    </Label>
                </div>
            ) : (
                <div
                    className="kpShowMore kpShowMore__button"
                    onClick={(e) => onShowMoreLessClick(e, "show_less_clk")}
                >
                    <span className="kpShowMore__icon-minus" />
                    <Label className="kpShowMore__kpShowMore-label">
                        {LABELS.KPCONSUMPTIONGROUP.SHOW_LESS}
                    </Label>
                </div>
            )}
        </>
    );
};

KPGridShowMoreLess.propTypes = {
    knowledgePages: PropTypes.array,
    isDocvizLoading: PropTypes.bool,
    docvizMultiDoc: PropTypes.array,
    showKpGridView: PropTypes.bool,
    mergDocvizWithKnowledgePagesData: PropTypes.func,
    handleFileDownloadFromGrid: PropTypes.func,
    handlePreviewFromGrid: PropTypes.func,
    handleTitleClickFromGrid: PropTypes.func,
    isShowMore: PropTypes.bool
};

KPGridShowMoreLess.defaultProps = {
    knowledgePages: [],
    isDocvizLoading: false,
    docvizMultiDoc: [],
    showKpGridView: false,
    mergDocvizWithKnowledgePagesData: () => { },
    handleFileDownloadFromGrid: () => { },
    handlePreviewFromGrid: () => { },
    handleTitleClickFromGrid: () => { },
    isShowMore: false
};

export default KPGridShowMoreLess;