// Constant
import { MYCASES } from "redux/constants";

export const getCasesBT = (data) => ({
    type: MYCASES.GET_CASES_BT,
    payload: data
});

export const getCasesBTPending = () => ({
    type: MYCASES.GET_CASES_BT_PENDING
});

export const getCasesBTSuccess = (res) => ({
    type: MYCASES.GET_CASES_BT_SUCCESS,
    payload: {
        res
    }
});

export const getCasesBTFailure = (error) => ({
    type: MYCASES.GET_CASES_BT_FAILURE,
    payload: {
        error: error.toString()
    }
});

export const getCaseDuration = () => ({
    type: MYCASES.GET_CASES_DURATION
});

export const getCaseDurationPending = () => ({
    type: MYCASES.GET_CASES_DURATION_PENDING
});

export const getCaseDurationSuccess = (res) => ({
    type: MYCASES.GET_CASES_DURATION_SUCCESS,
    payload: {
        res
    }
});

export const getCaseDurationFailure = (error) => ({
    type: MYCASES.GET_CASES_DURATION_FAILURE,
    payload: {
        error: error.toString()
    }
});

export const saveCaseBT = ({ guid, data }) => ({
    type: MYCASES.SAVE_CASE_BT,
    payload: { guid, data }
});

export const saveCaseBTPending = (data) => ({
    type: MYCASES.SAVE_CASE_BT_PENDING,
    payload: { data }
});

export const saveCaseBTSuccess = (payload) => ({
    type: MYCASES.SAVE_CASE_BT_SUCCESS,
    payload,
});

export const saveCaseBTFailure = (error) => ({
    type: MYCASES.SAVE_CASE_BT_FAILURE,
    payload: {
        error: error.toString()
    }
});
