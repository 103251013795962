// constant
import { CASE_HISTORY } from "redux/constants";

// local variable
export const name = "caseHistory";

// initial state
export const initialState = {
    error: false,
    errorMessage: "",
    caseHistory: [],
    isFetchingCaseHistory: false

};
// reducer selectors
export const selectors = {
    getCaseHistory: (state) => state[name].caseHistory,
};

// reducer
export function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case CASE_HISTORY.GET_CASE_HISTORY_SUCCESS:
            return {
                ...state,
                caseHistory: payload,
                isFetchingCaseHistory: false,
            }
        case CASE_HISTORY.GET_CASE_HISTORY_FAILURE:
            return {
                ...state,
                error: true,
                caseHistory: [],
                errorMessage: payload.error,
                isFetchingCaseHistory: false,
            }
        case CASE_HISTORY.GET_CASE_HISTORY_PENDING:
            return {
                ...state,
                isFetchingCaseHistory: true,
            }
        default:
            return state;
    }
}
