import React from "react";
import LABELS from "labels";
import { getContentToDisplay } from "utils/helpers/helpers";
import PropTypes from 'prop-types';

// Styles
import "./CaseBar.scss";
import CONFIG from "config";
import CONSTANTS from "globalConstants";


const CaseBar = ({ caseType, caseNumber, caseData, caseGuid }) => {
	const {
		CP_CONTRIBUTION: {
			EMPTY_VALUE,
		}
	} = LABELS;
	const { UI_URL: { CP_METADATA } } = CONFIG;
	const { CP_STEP1 } = CONSTANTS;

	const caseURL = CP_METADATA(CP_STEP1, caseGuid?.toLowerCase());
	return (
		<ul className="casebar">
			<li className="casebar__caseList caseType" key={caseNumber}>
				<span className="caseListcaseType_type">{caseType}</span>
				<span className="caseList_data"> : {
					<a
						href={caseURL}
						target="_blank"
						rel="noopener noreferrer"
						className="casebar__link"
					>
						{caseNumber}
					</a>}
				</span>
			</li>
			{!!caseData && Object.keys(caseData).length > 0 && Object.keys(caseData).map(current =>
				<>
					<li className="casebar__caseList" key={caseData[current]}>
						<span className="casebar__heading">{current}</span>
						{typeof (caseData[current]) !== "object" ?
							<span className={`casebar__data ${caseData[current] === "Active" ? 'casebar__data--active' : ''}`} >
								{getContentToDisplay(caseData[current], EMPTY_VALUE)}
							</span>
							:
							<a href={caseData[current]?.link} className={`casebar__datalink`} target="_blank" rel="noopener noreferrer" >
								{getContentToDisplay(caseData[current]?.text, EMPTY_VALUE)}
							</a>
						}
					</li>
				</>
			)}
		</ul>
	);
}

CaseBar.defaultProps = {
	caseType: '-',
	caseNumber: '-',
	caseData: {},
}
CaseBar.propTypes = {
	caseType: PropTypes.string,
	caseNumber: PropTypes.string,
	caseData: PropTypes.object,
};
export default CaseBar;