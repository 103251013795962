// Temp config file to provide test data to render docviz
// This will be removed when the API blocker is resolved
import authService from 'redux/auth';
import CONFIG from 'config';

const apiConfig = {
  "key": process.env.REACT_APP_DOCVIZ_X_API_KEY,
  "token": () => authService.getAccessToken(),
  "env": CONFIG.SERVER_URL
};

const { XA, API_URL: { FRAMEWORK_POST_API } } = CONFIG;

const LOCAL_CONFIG = {
  otherProps: {
    isSmallDevice: false,
    searchApi: {
      domain: CONFIG.ENRICH_API_SMP_DOMAIN,
      key: process.env.REACT_APP_ENRICH_API_SMP_DOMAIN_KEY,
    },
    frameworkApi: {
      url: FRAMEWORK_POST_API(),
      api_key: XA,
    },
    showPreviewOption: true,
    apiConfig,
    showActions: true,
    showTabs: false,
    truncatedBody: true
  }
};

// export default
export default LOCAL_CONFIG;