// Constant
import { CASE_KMSOWNER_LIST } from "redux/constants";

export const getCaseOwnerList = () => ({
    type: CASE_KMSOWNER_LIST.GET_CASE_KMSOWNER_LIST,
});

export const getCaseOwnerListPending = () => ({
    type: CASE_KMSOWNER_LIST.GET_CASE_KMSOWNER_LIST_PENDING,
});

export const getCaseOwnerListSuccess = (response) => ({
    type: CASE_KMSOWNER_LIST.GET_CASE_KMSOWNER_LIST_SUCCESS,
    payload: response
});

export const getCaseOwnerListFailure = (error) => ({
    type: CASE_KMSOWNER_LIST.GET_CASE_KMSOWNER_LIST_FAILURE,
    payload: {
        error: error.toString()
    }
});
