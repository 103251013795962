import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions as KPActions } from "redux/actions/knowledgepage/knowledgepage_actions";
import { selectors as kpSelectors } from "redux/reducers/knowledgepage/knowledgepage_reducer";
import { Tooltip } from "components/shared/Tooltip";
import "./HierarchicalDropdown.scss";

const getParentId = (data, searchId) => {
  if (data && data.length) {
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < data[i].Children.length; j++) {
        if (searchId === data[i].Children[j].Id) {
          return data[i].Id
        }
      }
    };
  }
}

const HierarchicalDropdownContainer = (props) => {
  const {
    slectedValue,
    preFillKeyNames,
    KPActions: {
      getDropdownDataFromApi
    },
    itemId,
    onLoadCallback,
    data,
    tooltipHtml,
    name
  } = props;
  const [show, setOpen] = useState(false);
  const [selectedObj, setSelectedObj] = useState({ Id: "", Name: "" }
  );
  const wrapperRef = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (slectedValue && Object.keys(slectedValue).length > 0 && data?.length) {
      const obj = { Id: slectedValue[preFillKeyNames.id], Name: slectedValue[preFillKeyNames.name] }
      setSelectedObj(obj);

      const parentId = getParentId(data, obj.Id);
      if (onLoadCallback) {
        onLoadCallback({ ...obj, parentId });
      }
    }
  }, [slectedValue, preFillKeyNames, data, onLoadCallback]);

  useEffect(() => {
    getDropdownDataFromApi(itemId, name);
  }, [getDropdownDataFromApi, itemId, name]);

  const handleOutsideClick = (e) => {
    if (wrapperRef.current.contains(e.target)) {
      return;
    }
    setOpen(false);
  };

  const getDropdownData = (allData, parentId) => {
    return (
      allData &&
      allData.map((item) => {
        return (
          <li
            key={item.Id}
            data-parentid={parentId}
            onClick={(e) => handleClick(e, item.Id, item.Name, parentId)}
            className={`customize__dropdownstyle ${item.Children.length > 0
              ? "customize__dropdown--hierarchical"
              : "customize__dropdown--single"
              } ${selectedObj.Id === item.Id ? "selected" : ""}`}
          >
            <span className="option">{item.Name}</span>
            {item.Children.length > 0 && (
              <ul className="customize__dropdownsublist">
                {getDropdownData(item.Children, item.Id)}
              </ul>
            )}
          </li>
        );
      })
    );
  };

  const handleClick = (event, id, name, parentId) => {
    event.stopPropagation();
    if (event.currentTarget.children.length > 1) {
      event.currentTarget.classList.toggle("customize__dropdownchild--show");
    } else {
      const obj = {
        Name: name,
        Id: id
      };
      setSelectedObj(obj);
      props.onChange({ name, id, parentId });
      setOpen(!show);
    }
  };

  const onDropdownClick = () => {
    setOpen(!show);
  };

  let dropdownLayout = getDropdownData(props.data);

  return (
    <>
      <label className="customize__label">{props.label}</label>
      {tooltipHtml && (
        <Tooltip id="hierarical-dropdown" position="right">{tooltipHtml}</Tooltip>
      )}
      <div
        className={`customize customize__dropdown ${props.className ? props.className : ""
          }`}
        ref={wrapperRef}
      >
        <span className="customize__dropdowntxt" onClick={onDropdownClick}>
          {selectedObj.Name ? selectedObj.Name : props.placeholder}
          <span className="customize__dropdownarrow"></span>
        </span>
        <ul
          className={`customize__dropdownlist ${show ? "customize__dropdownshow" : ""
            } ${props.position === "top" ? "customize__dropdowntop" : ""}`}
        >
          {dropdownLayout}
        </ul>
      </div>
    </>
  );
};

HierarchicalDropdownContainer.defaultProps = {
  preFillKeyNames: { id: "id", name: "name" },
  onLoadCallback: () => { }
}

const mapStateToProps = (state) => ({
  getLanguageData: kpSelectors.getLanguageData(state),
});

const mapDispatchToProps = (dispatch) => ({
  KPActions: bindActionCreators({ ...KPActions }, dispatch),
});

export { HierarchicalDropdownContainer };

export const HierarchicalDropdown = connect(
  mapStateToProps,
  mapDispatchToProps
)(HierarchicalDropdownContainer);
