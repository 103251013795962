import React from "react";
import { Form } from "semantic-ui-react";
import Image from "components/shared/Image";
import { TaxonomyModal } from "components/TaxonomyModal";
import Custodian from "components/Custodian";
import { connect } from "react-redux";
import { TAXONOMY } from "redux/constants";
import crossIcon from "assets/images/cross-icon.svg";
import crossIconBlue from "assets/images/cross-icon-blue.svg";
import rightIcon from "assets/images/right-icon.svg";
import { Tooltip } from "components/shared/Tooltip";
import plusicon from "assets/images/plusicon.svg";
import { selectors as taxonomySelector } from "redux/reducers/taxonomy/taxonomy_reducer";
import AddIcon from "assets/images/add/add-white.svg";
import { ThumbsUpDown } from "components/ThumbsUpDown";
import { notifyWarning, dismissNotifications } from "redux/actions/notification/notification_actions";
import { resetAllParentTags } from "redux/actions/taxonomy/Taxonomy.actions";
import { removeCurlyBracketFromId, setOwnerForAllTabs, getPolyHierarchiData, isCurrentAPolyGuid, polyHMaxLimitValidation, getTagsInfoIconDetailsifAny } from "utils/helpers/helpers";
import { ImageWithToolip } from "components/shared/ImageWithToolip";
import PolyHIcon from "assets/images/polyHierarchi/polyHierarchiIcon.svg";
import { getPolyhierarchyApiData } from "redux/actions/polyhierarchy/polyhierarchy.actions";
import { v4 as uuidv4 } from 'uuid';

// Analytics
import "./Taxonomy.scss";

// Constants : Labels and config
import LABELS from "labels";
import GLOBAL_CONFIG from "config";
import CONSTANTS from "globalConstants";

const { TAB, TAB1, TAB2, TAB3, TBDBS: {
  SEARCH_FPA_IPA_INTERNAL,
} } = CONSTANTS;

const tabArray = [TAB1, TAB2, TAB3];
class TaxonomyContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    const temp = this.prefillHandler();
    const { disabled, taxonomyName, getTaxonomy, preKeyFetched } = props;
    this.state = {
      isMaxWarningShown: false,
      selTaxonomy: {
        tab1: {},
        tab2: {},
        tab3: {},
      },
      savedTaxonomy: {
        tab1: {
          ...temp[0],
        },
        tab2: {
          ...temp[1],
        },
        tab3: {
          ...temp[2],
        },
      },
      isModalOpen: false,
    };
    if (!disabled && !preKeyFetched) {
      try {
        // dispatch the action
        taxonomyName.map(({ key }) => getTaxonomy(key));
      } catch (err) {
        console.error(err);
      }
    }
  }

  onChangeHandler = (data, callPostApi = true) => {
    const { onChange } = this.props;
    const tempArr = this.arrayObjectKeysFormatter(data);
    if (onChange && callPostApi) {
      onChange(tempArr);
    }
  };

  prefillHandler = () => {
    const { responseKeyName, preFillData } = this.props;
    let temp = [];
    preFillData && preFillData.forEach((data, index) => {
      temp[index] = {};
      data && Array.isArray(data) &&
        data.forEach((ele) => {
          let id = ele[responseKeyName.id];
          const name = ele[responseKeyName.name];
          const isOwner = ele[responseKeyName.isOwner];
          const isAutoTagged = ele[responseKeyName.isAutoTagged];
          const isAutoGeoTagged = ele[responseKeyName.isAutoGeoTagged];
          const isSuggested = ele[responseKeyName.isSuggested];
          const autoTagType = ele[responseKeyName.autoTagType];


          if (id && name) {
            id = id.replace(/{|}/g, "").toLowerCase();
            temp[index][id] = { name, isOwner, isAutoTagged, isAutoGeoTagged, isSuggested, autoTagType };
          }
        });
    });
    return temp;
  }

  arrayObjectKeysFormatter = (data) => {
    const { responseKeyName } = this.props;
    const temp = [];
    tabArray.forEach((tab) => {
      const arrayTab = [];
      Object.keys(data[tab]).forEach((key) => {
        arrayTab.push({
          [responseKeyName.id]: key,
          [responseKeyName.name]: data[tab][key].name,
          [responseKeyName.isSuggested]: data[tab][key].isSuggested,
          [responseKeyName.isAutoTagged]: data[tab][key].isAutoTagged,
          [responseKeyName.isAutoGeoTagged]: data[tab][key].isAutoGeoTagged,
          [responseKeyName.isOwner]: data[tab][key].isOwner,
          [responseKeyName.autoTagType]: data[tab][key].autoTagType
        });
      });
      temp.push(arrayTab);
    });
    return temp;
  }
  checkIsBST = () => {
    const { taxonomyName } = this.props;
    let isBst = false;
    taxonomyName?.forEach((obj) => {
      if (obj.key === "IPA" || obj.key === "FPA" || obj.key === "BCG_INTERNAL") {
        isBst = true
      }
    });
    return isBst
  }
  componentDidMount = () => {
    const { callSlParentTagFlag, getPolyhierarchyApiData, getExpertsTagsData , getSubjectTagsData } = this.props;
    callSlParentTagFlag(true);
    if (this.checkIsBST()) {
      //Dispatch action to call polyhierarchy API
      getSubjectTagsData();
      getPolyhierarchyApiData();
    } else {
      getExpertsTagsData();
    }
  }

  componentDidUpdate = (prevProps) => {
    const { preFillData } = this.props;

    if (
      JSON.stringify(preFillData) !== JSON.stringify(prevProps.preFillData)
    ) {
      const temp = this.prefillHandler();
      this.setState({
        savedTaxonomy: {
          tab1: {
            ...temp[0],
          },
          tab2: {
            ...temp[1],
          },
          tab3: {
            ...temp[2],
          },
        },
      });
    }
  };

  openModal = () => {
    const {
      disabled,
      openModalCallback,
      clearAllParentTags
    } = this.props;

    if (!disabled) {
      const { savedTaxonomy } = this.state;
      this.setState({
        selTaxonomy: { ...savedTaxonomy },
        isModalOpen: true,
      });

      clearAllParentTags();

      if (openModalCallback) {
        openModalCallback();
      }
    }
  };
  closeModal = () => {
    this.setState({
      isModalOpen: false,
      isMaxWarningShown: false
    });
    this.resetCallSlParentTagFlag(true);
  };
  cancelModal = () => {
    if (this.state.isMaxWarningShown) {
      const {
        dismissNotifications,
      } = this.props;
      try {
        dismissNotifications();
      } catch (err) {
        console.error(err);
      }
    }
    this.setState({
      selTaxonomy: {
        tab1: {},
        tab2: {},
        tab3: {},
      },
    });
    this.closeModal();
  };

  submitHandler = () => {
    const { selTaxonomy } = this.state;
    const { submitHandlerCallback } = this.props;
    this.setState(
      {
        savedTaxonomy: { ...selTaxonomy },
      },
      () => {
        const { savedTaxonomy } = this.state;
        this.onChangeHandler(savedTaxonomy);

        if (submitHandlerCallback) {
          submitHandlerCallback();
        }
      }
    );
    this.closeModal();
  };

  clickHandler = (tab, id, name, colIndex = null, index = null) => {
    const { selTaxonomy } = this.state;
    const { maxLimit, clickHandlerCallback } = this.props;
    const {
      MULTIPLE_TOPIC_PAGES_MAXLIMIT_MSG_TITLE: maxlimit_title,
      MULTIPLE_TOPIC_PAGES_MAXLIMIT_MSG: maxlimit_msg,
    } = LABELS.METADATA;

    const selectedTags = [...Object.keys(selTaxonomy.tab1), ...Object.keys(selTaxonomy.tab2), ...Object.keys(selTaxonomy.tab3)];
    let maxLengthValidationStatus = polyHMaxLimitValidation(selectedTags, maxLimit, id);

    if (!maxLimit || !maxLengthValidationStatus) {
      const updateObj = {
        ...selTaxonomy,
        [tab]: {
          ...selTaxonomy[tab],
          [id]: { colIndex, index, name, isOwner: false, isAutoTagged: false, isAutoGeoTagged: false, isSuggested: false },
        },
      };
      this.setState(
        {
          selTaxonomy: updateObj,
        },
        () => {

          if (clickHandlerCallback) {
            clickHandlerCallback(id);
          }
        }
      );
    } else {
      const {
        notifyWarning,
      } = this.props;
      try {
        notifyWarning(
          maxlimit_title,
          maxlimit_msg.replace("<configuredmaxlimit>", maxLimit)
        );
        this.setState({ isMaxWarningShown: true });
      } catch (err) {
        console.error(err);
      }
    }
  };

  deleteSelected = (tab, id, isSaved, callPostApi = true) => {
    const { selTaxonomy, savedTaxonomy } = this.state;
    const { deleteSelectedCallBack } = this.props;
    let temp = isSaved
      ? JSON.parse(JSON.stringify(savedTaxonomy))
      : JSON.parse(JSON.stringify(selTaxonomy));
    delete temp[tab][id];
    const stateName = isSaved ? "savedTaxonomy" : "selTaxonomy";
    this.setState(
      {
        [stateName]: temp,
      },
      () => {
        if (isSaved) {
          const { savedTaxonomy } = this.state;
          this.onChangeHandler(savedTaxonomy, callPostApi);
        }
      }
    );

    if (deleteSelectedCallBack) {
      deleteSelectedCallBack(id);
    }
  };

  autosuggestSelectedData = (data) => {
    const { taxonomyName } = this.props;
    const { id, name, tabId } = data;
    taxonomyName.forEach((item, index) => {
      const TBDBKey = (GLOBAL_CONFIG.TAXONOMY_IDS[item.key]()).toLowerCase();
      const typesearchTabID = (tabId).toLowerCase();
      if (TBDBKey === typesearchTabID) {
        this.fnToUpdatedTaxonomyData({ tab: TAB + (index + 1), id, name, colIndex: null, index: null, fnReferenceName: "clickHandler" })
      }
    });
  }

  // returns an array of objects with label, id and isOwner
  getCustodianNormalizedData = (savedTaxonomy) => {
    const { allTaxonomyPathObj } = this.props;
    const normalizedTags = [];
    Object.keys(savedTaxonomy || {}).forEach(tab => {
      Object.keys(savedTaxonomy[tab] || {}).forEach(id => {
        let isPolyHierarchi = this.fnToCheckIDIsPolyHierarchi(id);
        let taxonomyPath = '';
        if (allTaxonomyPathObj && Object.keys(allTaxonomyPathObj).length > 0) {
          taxonomyPath = allTaxonomyPathObj[id]
        }
        normalizedTags.push({
          id,
          label: savedTaxonomy[tab][id]?.name,
          isOwner: !!savedTaxonomy[tab][id]?.isOwner,
          isSuggested: savedTaxonomy[tab][id]?.isSuggested,
          isPolyHierarchi,
          taxonomyPath
        });
      });
    });
    return normalizedTags;
  }

  /**
   * Function used to return tabName 
   * eg: tab1 || tab2 || tab3 on bases of taxonomyKey
   * taxonomyKey = "FCA" || "IPA" || "BCG_INTERNAL"
   * 
   * @param {*} taxonomyKey 
   * @returns 
   */
  getTabNameFromTaxonomyKey = (taxonomyKey) => {
    const { taxonomyName } = this.props;
    const tabIndex = taxonomyName.findIndex(({ key }) => key === taxonomyKey);
    return "tab" + (tabIndex + 1);
  }

  getCurrentTabName = (type) => {
    const { SUGGESTIONS_TYPE_MAP } = GLOBAL_CONFIG;
    const taxonomyKey = SUGGESTIONS_TYPE_MAP[type];
    return this.getTabNameFromTaxonomyKey(taxonomyKey)
  }

  /**
   * Function used to return taxonomy key
   * for eg: "FCA" || "IPA" || "BCG_INTERNAL" 
   * @param {*} type 
   * @returns 
   */
  getCurrentTabKey = (type) => {
    const { SUGGESTIONS_TYPE_MAP } = GLOBAL_CONFIG;
    return SUGGESTIONS_TYPE_MAP[type];
  }

  getIsExisting = (id, name, type) => {
    let isExisting = false;
    const { savedTaxonomy } = this.state;
    const currentList = savedTaxonomy[this.getCurrentTabName(type)];
    const currentId = removeCurlyBracketFromId(id)?.toLowerCase();
    isExisting = Object.keys(currentList).indexOf(currentId) !== -1;
    return isExisting;
  }

  getIsExistingModal = (id, name, type) => {
    let isExisting = false;
    const { selTaxonomy } = this.state;
    const currentList = selTaxonomy[this.getCurrentTabName(type)];
    const currentId = removeCurlyBracketFromId(id)?.toLowerCase();
    isExisting = Object.keys(currentList).indexOf(currentId) !== -1;
    return isExisting;
  }

  /**
   * Function used to update taxonomy data, when clicking suggestion from
   * modal
   * @param {*} currentId 
   * @param {*} name 
   * @param {*} currentTab 
   */
  addInModalFromSuggestions = (currentId, name, currentTab) => {
    const { selTaxonomy } = this.state;
    this.setState({
      selTaxonomy: {
        ...selTaxonomy,
        [currentTab]: {
          ...selTaxonomy[currentTab],
          [currentId]: { name, isOwner: false, isAutoTagged: false, isAutoGeoTagged: false, isSuggested: true, autoTagType: "" },
        }
      }
    })
  }

  addTaxonomyFromSuggestions = (currentId, name, currentTab, type) => {
    const { savedTaxonomy } = this.state;
    const { suggestions: { clickCallback } } = this.props;
    this.setState({
      savedTaxonomy: {
        ...savedTaxonomy,
        [currentTab]: {
          ...savedTaxonomy[currentTab],
          [currentId]: { name, isOwner: false, isAutoTagged: false, isAutoGeoTagged: false, isSuggested: true, autoTagType: "" },
        }
      }
    }, () => {
      if (clickCallback)
        clickCallback(currentId, name, type);
      const { savedTaxonomy } = this.state;
      this.onChangeHandler(savedTaxonomy);
    })
  }

  // handles custodian pill click and updates savedTaxonomy as well
  custodianClickHandler = (id) => {
    const { onCustodianClick } = this.props;
    const { savedTaxonomy } = this.state;
    this.setState({
      savedTaxonomy: setOwnerForAllTabs(savedTaxonomy, id),
    }, () => {
      if (onCustodianClick) {
        onCustodianClick(id);
      }
    });
  };

  checkLimitAndDisableInModal = (suggestionTags) => {
    const { maxLimit } = this.props;
    const { selTaxonomy } = this.state;
    const selectedTags = [...Object.keys(selTaxonomy.tab1), ...Object.keys(selTaxonomy.tab2), ...Object.keys(selTaxonomy.tab3)];
    const set = new Set(selectedTags);
    let maxLengthValidationStatus = polyHMaxLimitValidation(selectedTags, maxLimit);
    return suggestionTags.map(item => ({ ...item, isDisabled: (maxLengthValidationStatus && !set.has(((item.id).replace(/[{}]/g, "")).toLowerCase())) }));
  }
  filterRemoveHideTags = (suggestionTags) => {
    const { hideTagsIds } = this.props;
    const filterSuggestionTags = suggestionTags?.filter(item => hideTagsIds?.indexOf(item?.label_guid) === -1);
    return filterSuggestionTags;
  }
  checkLimitAndDisable = (suggestionTags) => {
    const { maxLimit } = this.props;
    const { savedTaxonomy } = this.state;
    const selectedTags = [...Object.keys(savedTaxonomy.tab1), ...Object.keys(savedTaxonomy.tab2), ...Object.keys(savedTaxonomy.tab3)];
    const set = new Set(selectedTags);
    let maxLengthValidationStatus = polyHMaxLimitValidation(selectedTags, maxLimit);
    return suggestionTags.map(item => ({ ...item, isDisabled: (maxLengthValidationStatus && !set.has(((item.id).replace(/[{}]/g, "")).toLowerCase())) }));
  }

  /**
   * Calling Taxonomy related functions to update taxonomy data
   * @param {*} fnObj 
   */
  updateTaxonomyData = (fnObj) => {
    const { id, name, tab, colIndex, index, type, isSaved, callPostApi, fnReferenceName } = fnObj;
    switch (fnReferenceName) {
      case "clickHandler":
        this.clickHandler(tab, id, name, colIndex, index);
        break;

      case "addInModalFromSuggestions":
        this.addInModalFromSuggestions(id, name, tab);
        this.resetCallSlParentTagFlag(true);
        break;

      case "deleteSelected":
        this.deleteSelected(tab, id, isSaved, callPostApi);
        break;

      case "addTaxonomyFromSuggestions":
        this.addTaxonomyFromSuggestions(id, name, tab, type);
        this.resetCallSlParentTagFlag(true);
        break;

      default:
        break;
    }
  }

  /**
   * This is a common function to get the poly herarchi data before
   * calling the refernce functions to add taxonomy data
   * @param {*} obj 
   */
  takeActionOnPolyHierarchiData = (obj) => {
    try {
      const { getAllTaxonomyData, isApplicableForPolyHerarchi } = this.props;
      let callUpdateTaxonomyFn = true;
      if (isApplicableForPolyHerarchi) {
        let taxonomyData = getAllTaxonomyData()
        let polyHierarchResponse = getPolyHierarchiData(obj.id, taxonomyData)
        const { isPolyHierarchiDataPresent, polyHierarchiIdsList } = polyHierarchResponse;

        if (isPolyHierarchiDataPresent) {
          callUpdateTaxonomyFn = false;
          polyHierarchiIdsList.map((item, index) => {
            let lastIDsCalled = ((polyHierarchiIdsList.length - 1 === index) && obj.isSaved) ? true : false;
            const { Id, Name, type } = item;
            const currentTab = this.getTabNameFromTaxonomyKey(type);
            setTimeout(() => {
              let fnObj = {
                id: Id,
                name: Name,
                tab: currentTab,
                colIndex: obj.colIndex,
                index: obj.index,
                type,
                isSaved: obj.isSaved,
                callPostApi: lastIDsCalled,
                fnReferenceName: obj.fnReferenceName
              }

              this.updateTaxonomyData(fnObj)
            }, 0)
            return item;
          })
        }
      }

      if (!isApplicableForPolyHerarchi || callUpdateTaxonomyFn) {
        this.updateTaxonomyData(obj)
      }
    } catch (error) {
      console.log('Error in takeActionOnPolyHierarchiData function ', error)
    }
  }

  /**
   * Common funciton ot update taxonomy data
   * @param {*} obj 
   */
  fnToUpdatedTaxonomyData = (obj) => {
    const { id, name, tab, colIndex, index, type, isSaved, fnReferenceName } = obj;
    this.takeActionOnPolyHierarchiData({ id, name, tab, colIndex, index, type, isSaved, fnReferenceName });
  }

  fnToCheckIDIsPolyHierarchi = (id) => {
    const { isApplicableForPolyHerarchi } = this.props;
    const isPolyGuid = isCurrentAPolyGuid(id);
    if (isApplicableForPolyHerarchi && isPolyGuid) {
      return true;
    }
    return false;
  }

  resetCallSlParentTagFlag = (flag) => {
    const { callSlParentTagFlag } = this.props;
    //this.setState({ callSlParentTag: flag })
    callSlParentTagFlag(flag);
  }

  render() {
    const {
      label,
      placeholder,
      modalHeader,
      getTaxonomyData,
      taxonomyName,
      disabled,
      className,
      typeSearchplaceholder,
      characterCount,
      isShowNoResultInTypeAhead,
      responseTypeSearchKeyName,
      showModalCloseOption,
      taxonomyTypeSearchName,
      typeAheadHandlerCallback,
      tooltipHtml,
      custodianTooltipHtml,
      showCustodian,
      custodianClass,
      mainErrorMessage,
      validationErrorMessage,
      isShowParentTagsTooltip,
      hideTagsIds,
      activeIndex,
      informationIconsConfig,
      showTaxonomyTagCompletePath,
      suggestionResponseKeyName: suggestionsKeyName,
      suggestions: {
        tags: suggestionTagsData,
        heading: suggestionHeading,
        modalHeading: suggestionsModalHeading,
        thumbsUpdownCallback: suggestionThumbsUpdownCallback,
        showThumbs,
        maxLimitLabel: maxLimitText
      },
      getSLparentTags,
      getParentTagsData,
      v2,
      callSlParentTag
    } = this.props;
    const { TAXONOMY: { TOOLTIPS: { POLY_HIERARCHI_CUSTODIAN_MSG, POLY_HIERARCHI_TAXONOMY_SUGGESTION_MSG } } } = LABELS;
    const { savedTaxonomy, isModalOpen, selTaxonomy } = this.state;
    let totalKeys =
      Object.keys(savedTaxonomy.tab1).length +
      Object.keys(savedTaxonomy.tab2).length +
      Object.keys(savedTaxonomy.tab3).length;
    const suggestionTags = this.filterRemoveHideTags(suggestionTagsData);
    const modifiedSuggestionTags = !!suggestionTags?.length ? this.checkLimitAndDisable(suggestionTags) : [];
    const parentTagsData = getParentTagsData(GLOBAL_CONFIG.TBDB_IDS[SEARCH_FPA_IPA_INTERNAL]);
    return (
      <div className={`txbase ${className ? className : ""}`}>
        {!!v2 ?
          <div className="v2">
            <span className="v2__title">
              {label}
            </span>
            <span className="v2__count">
              {totalKeys}
            </span>
            <span
              className="v2__img"
              onClick={() => this.openModal()}
            >
              <Image src={AddIcon} alt="taxanomy icon" />
            </span>
          </div>
          :
          <div className={`${suggestionTags?.length ? "txbase__havesuggestions" : ""}`}>
            {label && <label>{label}</label>}
            {tooltipHtml && (
              <Tooltip id={"taxonomy_tooltip_" + taxonomyName[0].key}>{tooltipHtml}</Tooltip>
            )}
            {Object.keys(savedTaxonomy.tab1).length ||
              Object.keys(savedTaxonomy.tab2).length ||
              Object.keys(savedTaxonomy.tab3).length ? (
              <>
                <div className="txbase__wrapinput">
                  <ul>
                    {tabArray.map((tab) => (
                      <>
                        {savedTaxonomy[tab] &&
                          Object.keys(savedTaxonomy[tab]).map((id) => {
                            const item = savedTaxonomy[tab][id];
                            const isPolyHierarchi = this.fnToCheckIDIsPolyHierarchi(id);
                            const infoIconData = getTagsInfoIconDetailsifAny(item, informationIconsConfig);
                            return (
                              <li key={id} className={(infoIconData?.isTagged) ? "txbase__autoTagged" : ""}>
                                {infoIconData && <ImageWithToolip id={`${id}-informationIcon`} imgIcon={infoIconData?.infoIcon} toolTipClass="" imgClassName="txbase__poly-img txbase__iIcon-img" toolTipMsg={infoIconData?.infoIconToolTipMessage} />}
                                {isPolyHierarchi && <ImageWithToolip id={`${id}-suggestionTags`} imgIcon={PolyHIcon} toolTipClass="" imgClassName="txbase__poly-img" toolTipMsg={POLY_HIERARCHI_TAXONOMY_SUGGESTION_MSG} />}
                                <span>{item.name}</span>
                                {!disabled && (
                                  <Image
                                    src={(infoIconData?.isTagged) ? crossIconBlue : crossIcon}
                                    alt="cross"
                                    onClick={() => this.fnToUpdatedTaxonomyData({ tab, id, isSaved: true, fnReferenceName: "deleteSelected" })}
                                  />
                                )}
                              </li>
                            );
                          })}
                      </>
                    ))}
                  </ul>
                  <span
                    className={`txbase__img ${disabled ? "txbase__disabled" : ""}`}
                    onClick={() => this.openModal()}
                  >
                    <Image src={AddIcon} alt="taxanomy icon" />
                  </span>
                </div>
                {validationErrorMessage}
              </>
            ) : (
              <>
                <Form.Input
                  type="text"
                  // onClick={() => this.openModal()}
                  placeholder={placeholder}
                  value=""
                  onClick={() => this.openModal()}
                  icon={
                    <span
                      className={`txbase__img ${disabled ? "txbase__disabled" : ""}`}
                      onClick={() => this.openModal()}
                    >
                      <Image src={AddIcon} alt="taxanomy icon" />
                    </span>
                  }
                />
                {validationErrorMessage}
              </>
            )}
            {!!modifiedSuggestionTags?.length &&
              <div className="txbase__suggestions">
                {suggestionHeading &&
                  <>
                    <label>{suggestionHeading} </label>
                    {!!showThumbs && <ThumbsUpDown
                      isThumbsUpDownV2
                      finalSuccess={suggestionThumbsUpdownCallback}
                    />}
                  </>
                }
                <ul>
                  {
                    modifiedSuggestionTags.map((itemMain) => {
                      const guid = itemMain[suggestionsKeyName["id"]];
                      const name = itemMain[suggestionsKeyName["name"]];
                      const type = itemMain[suggestionsKeyName["type"]];
                      const { isDisabled } = itemMain;
                      const isExisting = this.getIsExisting(guid, name, type);
                      const id = removeCurlyBracketFromId(guid)?.toLowerCase()
                      const isPolyHierarchi = this.fnToCheckIDIsPolyHierarchi(id);
                      if (isDisabled) {
                        return (<Tooltip
                          id={guid}
                          className={"txbase__suggestions__tooltip"}
                          position="top"
                          delayHide={0}
                          key={uuidv4()}
                          icon={
                            <li key={guid} index={guid} data-type={type} data-id={guid} className="txbase__suggestions__tooltip__disabled">
                              {isPolyHierarchi && <ImageWithToolip id={`${id}-suggestionsT`} imgIcon={PolyHIcon} toolTipClass="" imgClassName="txbase__poly-img" toolTipMsg={POLY_HIERARCHI_TAXONOMY_SUGGESTION_MSG} />}
                              <span>{name}</span>
                              <Image
                                src={isExisting ? rightIcon : plusicon}
                                alt="Plus icon"
                                className={isExisting ? "txbase__existingicon txbase__existing" : ""}
                              />
                            </li>
                          }
                        >
                          <p className="txbase__suggestions__tooltip-content" >{maxLimitText}</p>
                        </Tooltip>)
                      } else {
                        return (
                          <li key={guid} index={guid} data-type={type} data-id={guid}>
                            {isPolyHierarchi && <ImageWithToolip id={`${id}-suggestionsNT`} imgIcon={PolyHIcon} toolTipClass="" imgClassName="txbase__poly-img" toolTipMsg={POLY_HIERARCHI_TAXONOMY_SUGGESTION_MSG} />}
                            <span>{name}</span>
                            <Image
                              src={isExisting ? rightIcon : plusicon}
                              alt="Plus icon"
                              className={isExisting ? "txbase__existingicon txbase__existing" : ""}
                              onClick={() => isExisting ? {} : this.fnToUpdatedTaxonomyData({
                                id: removeCurlyBracketFromId(guid)?.toLowerCase(),
                                tab: this.getCurrentTabName(type),
                                name,
                                type,
                                fnReferenceName: "addTaxonomyFromSuggestions"
                              })}
                            />
                          </li>
                        )
                      }
                    })
                  }
                </ul>
              </div>
            }
            {mainErrorMessage}
          </div>
        }
        {!!showCustodian && (
          !!Object.keys(savedTaxonomy.tab1).length ||
          !!Object.keys(savedTaxonomy.tab2).length ||
          !!Object.keys(savedTaxonomy.tab3).length) &&
          <div className="custodianWrapper">
            <Custodian
              data={this.getCustodianNormalizedData(savedTaxonomy)}
              clickHandler={this.custodianClickHandler}
              className={custodianClass}
              tooltipHtml={custodianTooltipHtml}
              polyToolTipMsg={POLY_HIERARCHI_CUSTODIAN_MSG}
              showToolTip={showTaxonomyTagCompletePath}
              getSLparentTags={getSLparentTags}
              parentTagsData={parentTagsData}
              callSlParentTag={callSlParentTag}
              resetCallSlParentTagFlag={this.resetCallSlParentTagFlag}
            />
          </div>
        }
        {<TaxonomyModal
          isOpen={isModalOpen}
          onRequestClose={this.cancelModal}
          clickHandler={(tab, id, name, colIndex = null, index = null) => {
            this.fnToUpdatedTaxonomyData({ tab, id, name, colIndex, index, fnReferenceName: "clickHandler" })
          }}
          deleteSelected={(tab, id, isSaved) => {
            this.fnToUpdatedTaxonomyData({ tab, id, isSaved, fnReferenceName: "deleteSelected" })
          }}
          submitHandler={this.submitHandler}
          isShowNoResultInTypeAhead={isShowNoResultInTypeAhead}
          typeAheadHandler={typeAheadHandlerCallback}
          selTaxonomy={selTaxonomy}
          modalHeader={modalHeader}
          taxonomyName={taxonomyName}
          getTaxonomyData={getTaxonomyData}
          isShowParentTagsTooltip={isShowParentTagsTooltip}
          typeSearchplaceholder={typeSearchplaceholder}
          characterCount={characterCount}
          responseTypeSearchKeyName={responseTypeSearchKeyName}
          taxonomyTypeSearchName={taxonomyTypeSearchName}
          sendFilterData={this.autosuggestSelectedData}
          showModalCloseOption={showModalCloseOption}
          hideTagsIds={hideTagsIds}
          activeIndex={activeIndex}
          fnToCheckIDIsPolyHierarchi={this.fnToCheckIDIsPolyHierarchi}
          informationIconsConfig={informationIconsConfig}
          suggestions={
            {
              tags: suggestionTags,
              heading: "Top " + suggestionTags?.length + " " + suggestionsModalHeading,
              maxLimitLabel: maxLimitText,
              suggestionsKeyName: suggestionsKeyName,
              getIsExistingModal: this.getIsExistingModal,
              addTagsFromSuggestions: (id, name, type) => {
                const currentId = removeCurlyBracketFromId(id)?.toLowerCase();
                const currentTab = this.getCurrentTabName(type);
                this.fnToUpdatedTaxonomyData({ id: currentId, tab: currentTab, name, type, fnReferenceName: "addInModalFromSuggestions" })
              },
              checkLimitAndDisable: this.checkLimitAndDisableInModal
            }
          }
        />
        }
      </div>
    )
  }
}

TaxonomyContainer.defaultProps = {
  responseKeyName: {
    id: "id",
    name: "name",
    isOwner: "isOwner",
    isAutoTagged: "isAutoTagged",
    isAutoGeoTagged: "isAutoGeoTagged",
    isSuggested: "isSuggested",
    autoTagType: "autoTagType"
  },
  disabled: false,
  kbid: "",
  maxLimit: false,
  showModalCloseOption: false,
  hideTagsIds: [],
  isShowNoResultInTypeAhead: false,
  showCustodian: false,
  onCustodianClick: () => { },
  custodianClass: "",
  mainErrorMessage: "",
  v2: false,
  preKeyFetched: false,
  suggestions: {
    tags: [],
    heading: "",
    modalHeading: "",
    thumbsUpdownCallback: () => { },
    showThumbs: false,
    clickCallback: () => { },
  },
  suggestionResponseKeyName: {
    id: "id",
    name: "name",
    type: "type"
  },
  isApplicableForPolyHerarchi: false,
  informationIconsConfig: [],
  showTaxonomyTagCompletePath: false
};

const mapStateToProps = (state) => ({
  getTaxonomyData: (taxonomyName) =>
    taxonomySelector.getTaxonomyData(state, taxonomyName),
  getAllTaxonomyData: () =>
    taxonomySelector.getAllTaxonomyData(state),
  allTaxonomyPathObj: taxonomySelector.getAllTaxonomyPathObj(state),
  getParentTagsData: (taxonomyName) => taxonomySelector.getParentTagsData(state, taxonomyName),
  callSlParentTag: taxonomySelector.getCallSlParentTag(state)
});

const mapDispatchToProps = (dispatch) => ({
  getTaxonomy: (key) => dispatch({ type: TAXONOMY.GET_TAXONOMY, payload: { key } }),
  notifyWarning: (title, msg) => dispatch(notifyWarning(title, msg)),
  dismissNotifications: () => dispatch(dismissNotifications()),
  getSLparentTags: (key, ids) => dispatch({ type: TAXONOMY.GET_SL_PARENT_TAGS_DATA, payload: { ids, key, needParentalRoot: true } }),
  getExpertsTagsData: () => dispatch({ type: TAXONOMY.GET_EXPERT_TAGS_DATA }),
  getSubjectTagsData: () => dispatch({ type: TAXONOMY.GET_SUBJECT_TAGS_DATA }),
  clearAllParentTags: () => dispatch(resetAllParentTags()),
  callSlParentTagFlag: (flag) => dispatch({ type: TAXONOMY.CALL_SL_PARENT_TAG_FLAG, payload: flag }),
  getPolyhierarchyApiData: () => dispatch(getPolyhierarchyApiData())
});

export const Taxonomy = connect(
  mapStateToProps,
  mapDispatchToProps
)(TaxonomyContainer);

export { TaxonomyContainer };
