import React from "react";
import { addColonToValidData, formatDate, isValidData, sortArray } from "utils/helpers/helpers";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import CONFIG from "config";
import LABELS from "labels";

// SCSS
import "./RelatedCaseTile.scss";
import { IconListingTeam } from "../IconListingTeam/IconListingTeam";

class RelatedCaseTile extends React.PureComponent {
    render() {
        const {
            CP_LIMITS: {
                RELATED_CASES_TEAM
            },
            CP_UI_DATE_FORMAT
        } = CONFIG;
        const { data, type, index, onCardClick, isViewClientInfo, iconClickCallback } = this.props;
        const { CP_CONSUMPTION: { OPENDATE_HEADING } } = LABELS;
        const { shortDescription, description, caseNumber, caseOpenDate, projectId, allCaseTeams, caseType } = data;
        let validCasenumber = ""
        if (isViewClientInfo) {
            validCasenumber = isValidData(shortDescription) ? addColonToValidData(caseNumber) : caseNumber;
        }
        let validType = addColonToValidData(type);
        let validCaseType = addColonToValidData(caseType);
        const labelOpenDate = index === 0 ? OPENDATE_HEADING : "";
        const sortedTrimCaseTeam = sortArray(allCaseTeams, "timeAndBillingHours", "DSC")
        const filteredHrIds = sortedTrimCaseTeam.map(caseTeam => caseTeam.hrEmployeeId);
        const { CP_CONSUMPTION: { CASE_TEAM } } = LABELS;

        return (
            <div className="relatedCaseTile">
                <section className="relatedCaseTile__date"><span className="relatedCaseTile__date--formatted">{labelOpenDate + formatDate(caseOpenDate, CP_UI_DATE_FORMAT)}</span></section>
                <section className="relatedCaseTile__milestone">
                    <div className="icon"> </div>
                </section>
                <section className="relatedCaseTile__content"
                    onClick={(e) => onCardClick(e, projectId)}
                >
                    <Link
                        className="title two-lines-ellipsis"
                        title="Open case in new tab"
                    > {validType + validCaseType + validCasenumber + shortDescription}
                    </Link>
                    <p className="desc three-lines-ellipsis"
                        dangerouslySetInnerHTML={{
                            __html: description,
                        }}>
                    </p>
                    {!!filteredHrIds.length && (
                        <>
                            <div className="relatedCaseTile__horizontalLine"></div>
                            <IconListingTeam
                                heading={CASE_TEAM}
                                hrIds={{ ids: filteredHrIds }}
                                limit={RELATED_CASES_TEAM}
                                key={projectId}
                                iconClickCallback={(...params) => iconClickCallback(...params, projectId)}
                            />
                        </>
                    )}
                </section>
            </div>
        );

    }
}

RelatedCaseTile.propTypes = {
    onCardClick: PropTypes.func,
    data: PropTypes.object,
    type: PropTypes.string,
    index: PropTypes.number
};


export { RelatedCaseTile };