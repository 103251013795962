import React from "react";
import PropTypes from "prop-types";
// SHARED COMPONENTS
import { InfoGrid } from "components/shared/InfoGrid";
import { CaseDurationAndCapture } from "components/CaseDurationAndCapture/CaseDurationAndCapture";
import SearchBox from "components/shared/SearchBox/SearchBox";
import CaseSearchItem from "components/CaseSearchItem/CaseSearchItem";
import CaseListing from "components/CaseListing/CaseListing";
// Helpers
import { validateCaseSearch, areObjectsEqual } from "utils/helpers/helpers";
// Labels
import LABELS from "labels";
// Config
import CONFIG from "config";
//SCSS
import "./CPStep2.scss";

class CPStep2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSearchResults: [],
    }
  }

  searchCaseHandler = (keyword) => {
    const { searchRelatedCases } = this.props;
    searchRelatedCases(keyword);
  }

  clearSearchResults = () => {
    const { resetSearchResults } = this.props;
    resetSearchResults();
  }

  handleOnResultClick = (item) => {
    const { selectedSearchResults } = this.state;
    const { handleChange, getCaseOpenAndCloseDate } = this.props;
    let updatedResult = [];
    const { id, caseNumber, sanitizedCaseTitle } = item;
    if (selectedSearchResults && selectedSearchResults.some(e => e.caseGuid === id)) {
      return;
    }
    getCaseOpenAndCloseDate([id]);
    const selectedItem = {
      caseGuid: id,
      caseNumber,
      caseTitle: sanitizedCaseTitle,
      caseTypeGuid: "",
    };
    updatedResult = [selectedItem, ...selectedSearchResults];
    this.setState({
      selectedSearchResults: updatedResult
    },
      () => {
        const { selectedSearchResults } = this.state;
        handleChange(selectedSearchResults, 'relatedCasesAndRelationshipType')
      }
    )
  }

  deleteClickHandler = (item) => {
    const { selectedSearchResults } = this.state;
    const { handleChange } = this.props;
    let updatedResult = [];
    updatedResult = selectedSearchResults && selectedSearchResults.length > 0 && selectedSearchResults.filter(data => data.caseGuid !== item.caseGuid)
    this.setState({
      selectedSearchResults: updatedResult
    },
      () => {
        const { selectedSearchResults } = this.state;
        handleChange(selectedSearchResults, 'relatedCasesAndRelationshipType')
      }
    )
  }

  caseTypeChangeHandler = (value, selectedCaseNumber) => {
    const { selectedSearchResults } = this.state;
    const { handleChange } = this.props;
    const updatedResults = selectedSearchResults.map(item => {
      const { caseNumber } = item;
      if (caseNumber === selectedCaseNumber) {
        item.caseTypeGuid = value;
      }
      return item;
    })
    this.setState({
      selectedSearchResults: updatedResults
    },
      () => {
        const { selectedSearchResults } = this.state;
        handleChange(selectedSearchResults, 'relatedCasesAndRelationshipType')
      })
  }

  componentDidMount() {
    const { relatedCasesAndRelationshipType, onLoadAnalytics } = this.props;
    if (relatedCasesAndRelationshipType && relatedCasesAndRelationshipType.length) {
      this.setState({
        selectedSearchResults: relatedCasesAndRelationshipType
      })
    }
    onLoadAnalytics()
  }

  componentDidUpdate(prevProps) {
    const { relatedCasesAndRelationshipType: prevRelatedCase } = prevProps;
    const { selectedSearchResults } = this.state;
    if (!areObjectsEqual(selectedSearchResults, prevRelatedCase)) {
      this.setState({
        selectedSearchResults: prevRelatedCase
      })
    }
  }

  checkAlreadySelectedAndSelf = (arr1, arr2) => {
    try {
      const { cid } = this.props;
      const set = new Set(arr1.map(item => (item.caseGuid).toLowerCase()));

      const currentCaseId = (cid).toLowerCase();
      if (!set.has(currentCaseId)) {
        set.add(currentCaseId);
      }
      return arr2.map(item => ({
        ...item,
        alreadySelectedAndSelf: {
          isAlreadySelected: set.has((item?.id || '').toLowerCase()),
          message: currentCaseId === (item?.id || '').toLowerCase() ? LABELS.CP_CONSUMPTION.CASE_TAGGED_ITSELF : LABELS.CP_CONSUMPTION.CASE_TAGGED_IN_LIST
        }
      }));
    } catch (e) {
      console.error('failed in checkAlreadySelectedAndSelf method: ', e, arr1, arr2);
    }
  };

  render() {
    const {
      unSanitizedCaseData,
      caseInfo,
      searchResults,
      totalSearchResults,
      isSearching,
      caseTypes,
      errorState
    } = this.props;

    const {
      CP_CONTRIBUTION: {
        STEP_2_FIELDS: {
          RELATED_CASES,
          SEARCH_RELATED,
          SEARCH_PLACEHOLDER,
          RESULTS_HEADING,
          REFINE_SEARCH,
          NO_RESULTS,
        }
      }
    } = LABELS;

    const { selectedSearchResults } = this.state;
    const { CP_CONTRIBUTION_LIMITS: { SEARCH_CASE_CHAR_LIMIT, SEARCH_CASES_LIMIT } } = CONFIG;
    const modifiedSearchResults = searchResults && searchResults.length > 0 && this.checkAlreadySelectedAndSelf(selectedSearchResults, searchResults);
    return (
      <div className="cpstep cpstep2">
        <CaseDurationAndCapture {...caseInfo} />
        <InfoGrid
          data={unSanitizedCaseData}
          showTitleDataSeparator={false}
        />
        <div className="cpstep2__relatedCases">
          <label className="cpstep2__relatedCases__label">{RELATED_CASES}</label>
          <SearchBox
            handleOnSearch={this.searchCaseHandler}
            selectedSearchResults={selectedSearchResults}
            handleOutsideClick={this.clearSearchResults}
            label={SEARCH_RELATED}
            placeHolder={SEARCH_PLACEHOLDER}
            showErrorInResults={true}
            noResultsMessage={NO_RESULTS}
            tooManyResultsMessage={REFINE_SEARCH}
            maximumResults={SEARCH_CASES_LIMIT}
            maxLength={SEARCH_CASE_CHAR_LIMIT}
            searchResults={modifiedSearchResults}
            totalCount={totalSearchResults}
            resultsHeading={RESULTS_HEADING}
            isSearching={isSearching}
            validateInput={validateCaseSearch}
            handleOnResultClick={this.handleOnResultClick}
            renderResult={item => {
              const { sanitizedCaseTitle, caseNumber } = item;
              return <CaseSearchItem caseNumber={caseNumber} caseTitle={sanitizedCaseTitle} />
            }}
          />
          <CaseListing
            caseListItems={selectedSearchResults}
            caseTypes={caseTypes}
            caseTypeChangeHandler={this.caseTypeChangeHandler}
            deleteClickHandler={this.deleteClickHandler}
            errorState={errorState} />
        </div>
      </div>
    );
  }
}

CPStep2.propTypes = {
  unSanitizedCaseData: PropTypes.array.isRequired,
  caseInfo: PropTypes.object.isRequired,
  searchRelatedCases: PropTypes.func.isRequired,
  resetSearchResults: PropTypes.func.isRequired,
  searchResults: PropTypes.array,
  totalSearchResults: PropTypes.number,
  isSearching: PropTypes.bool,
};

CPStep2.defaultProps = {
  searchResults: [],
  totalSearchResults: 0,
  isSearching: false,
};

export { CPStep2 };
