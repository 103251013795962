import React from "react";
import LABELS from "labels";
import { getContentToDisplay } from "utils/helpers/helpers";
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

// Styles
import "./CaseDetailsBT.scss";

const {
	CP_CONTRIBUTION: {
		EMPTY_VALUE,
	}
} = LABELS;

const CaseDetailsBT = ({ clientData }) => {
	return (
		<ul className="casedata">
			{!!clientData && Object.keys(clientData).length > 0 && Object.keys(clientData).map((current, id) =>
				<li className="casedata__clientdetails" key={uuidv4()}>
					<div className="casedata__des">
						<span className="casedata__title">{current} {clientData[current]?.name ? "|" : ''}<strong> {getContentToDisplay(clientData[current]?.name)}</strong> </span>
						<span className="casedata__data">{getContentToDisplay(clientData[current]?.des, EMPTY_VALUE)}</span>
					</div>
				</li>
			)}
		</ul>
	)
};

CaseDetailsBT.propTypes = {
	clientData: PropTypes.object.isRequired,
};
export default CaseDetailsBT;