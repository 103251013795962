import React from "react";
import { FaceCardFormGeneratorv1 } from "./FaceCardFormv1/FaceCardFormv1";
import FaceCardSlideListingv1 from "components/FaceCardSlideListingv1/FaceCardSlideListingv1";
import {
	PAGES,
	PRIMARY_CATEGORY,
	DTMRULE,
	TOOLNAME,
	TRIGGERS,
} from "utils/analytics/analytics_constants";
import ANALYTICS from "utils/analytics/analytics";
import CONFIG from "config";
import { getmyCurrentLocation } from "utils/helpers/helpers";
import { FaceCardDefaultViewv1 } from "components/FaceCardDefaultView/FaceCardDefaultView";
import LABELS from "labels";
import './FaceCardGeneratorv1.scss';
import { FullPageLoader } from "components/shared/FullPageLoader";


class FaceCardGeneratorv1 extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = this.getInitialState();
	}

	getInitialState = () => {
		return {
			isDownloadDisable: true,
			isNameChecked: true,
			isGreyScaleChecked: false,
			isTitleChecked: false,
			isLocationChecked: false,
			isNameShow: false,
			isTitleShow: false,
			isLocationShow: false,
			dividedSlides: [],
			peopleHrids: [],
			isPreviewShow: false,
			isResetButtonEnabled: false
		}
	}
	callBackTypeChange = (selectedCase) => {
		const { getCaseDetails } = this.props;
		getCaseDetails(selectedCase);
	}
	componentDidUpdate(prevProps, prevState) {
		const { peopleData, peoplePayloadData } = this.props;
		const { isTitleChecked, isLocationChecked, isNameChecked, isGreyScaleChecked, peopleHrids } = this.state;

		if (peopleData !== prevProps.peopleData) {
			this.setState({ dividedSlides: this.handleSlideDivision() });
		}

		const isTitleChanged = isTitleChecked !== prevState.isTitleChecked;
		const isLocationChanged = isLocationChecked !== prevState.isLocationChecked;
		const isNameChanged = isNameChecked !== prevState.isNameChecked;
		const isGreyScaleChange = isGreyScaleChecked !== prevState.isGreyScaleChecked;
		const arePeopleHridsChanged =
			JSON.stringify(peopleHrids) !== JSON.stringify(prevState.peopleHrids);

		if (isTitleChanged || isLocationChanged || isNameChanged || arePeopleHridsChanged || isGreyScaleChange) {
			const hasTitleChanged = peoplePayloadData?.filters.hasTitle !== isTitleChecked;
			const hasLocationChanged = peoplePayloadData?.filters.hasLocation !== isLocationChecked;
			const hasNameChanged = peoplePayloadData?.filters.hasName !== isNameChecked;
			const hasGreyScaleChanged = peoplePayloadData?.filters.grayscale !== isGreyScaleChecked;

			const hasHridChanged =
				JSON.stringify(peoplePayloadData?.employees.map((employee) => employee.hrid)) !==
				JSON.stringify(peopleHrids);

			this.setState({
				isDownloadDisable: hasTitleChanged || hasLocationChanged || hasNameChanged || hasGreyScaleChanged || hasHridChanged,
			});
		}
	}
	resetCallback = () => {
		this.setState({
			isDownloadDisable: true,
			dividedSlides: [],
			isPreviewShow: false
		});
	}
	triggerPreviewDownloadAnalytics = (isPreview, byType) => {
		const { CONTRIBUTE_KNOWLEDGE } = TOOLNAME;
		const newAdobeData = {
			page: {
				category: {
					primaryCategory: PRIMARY_CATEGORY.FACE_CARD_GENERATOR,
				},
				pageInfo: {
					pageName: PAGES.FACE_CARD_GENERATOR,
					pageURL: getmyCurrentLocation(),
				},
			},
			search: {
				term: "NA",
				searchTrigger: byType,
				typeHeadOptionText: "NA",
				typeHeadOptionRank: "NA",
				filterTerm: "NA",
				filterLabel: "NA",
				filterLevel: "NA",
				toolName: "NA",
			},
			preFilterTerm: "NA",
			officePage: {
				mainCategory: "NA",
			},
			rc: {
				tabName: isPreview ? TRIGGERS.PREVIEW : TRIGGERS.DOWNLOADS, //eVar48
			},
		};
		ANALYTICS.kc.sendEventData(newAdobeData, DTMRULE.TAB_CLICKS, CONTRIBUTE_KNOWLEDGE);
	};
	handleDownloadBtnStatus = (value) => {
		this.setState({ isDownloadDisable: value });
	};
	handleNameChange = (value) => {
		this.setState({ isNameChecked: value });
	};
	handleGreyScaleChange = (value) => {
		this.setState({ isGreyScaleChecked: value });
	};
	handleTitleChange = (value) => {
		this.setState({ isTitleChecked: value });
	};

	handleLocationChange = (value) => {
		this.setState({ isLocationChecked: value });
	};
	handlePreviewClick = (byType) => {
		this.setState({
			isTitleShow: this.state.isTitleChecked,
			isLocationShow: this.state.isLocationChecked,
			isNameShow: this.state.isNameChecked
		});
		this.triggerPreviewDownloadAnalytics(true, byType);
	};
	handleSlideDivision = () => {
		const { peopleData } = this.props;
		const slides = [];
		for (let i = 0; i < peopleData.length; i += 10) {
			slides.push(peopleData.slice(i, i + 10));
		}
		return slides;
	};
	handlePeopleSelectorUpdate = (value) => {
		this.setState({
			peopleHrids: value,
		});
	};
	handlePreviewShow = (value) => {
		this.setState({ isPreviewShow: value });
	};

	resetErrorStateChange = () => {
		this.setState({ isPollingError: false, isRequestError: false });
	};


	render() {
		const {
			userDetails,
			getLoading,
			caseDetails,
			peoplePayloadData,
			isPollingError,
			isRequestError,
			defaultTemplate,
			handleClose
		} = this.props;
		const {
			FACE_CARD_DEFAULT_VIEW: {
				stepper
			}
		} = LABELS;
		const {
			isPreviewShow,
			isDownloadDisable,
			isNameChecked,
			isGreyScaleChecked,
			isTitleChecked,
			isLocationChecked,
			isTitleShow,
			isNameShow,
			isLocationShow,
			dividedSlides
		} = this.state;
		return (

			<>
				<FaceCardFormGeneratorv1
					handlePreviewShow={this.handlePreviewShow}
					resetCallback={this.resetCallback}
					handlePeopleSelectorUpdate={this.handlePeopleSelectorUpdate}
					triggerPreviewDownloadAnalytics={this.triggerPreviewDownloadAnalytics}
					handlePreviewClick={this.handlePreviewClick}
					handleNameChange={(data) => this.handleNameChange(data)}
					handleGreyScaleChange={this.handleGreyScaleChange}
					handleTitleChange={(data) => this.handleTitleChange(data)}
					isNameChecked={isNameChecked}
					isGreyScaleChecked={isGreyScaleChecked}
					isTitleChecked={isTitleChecked}
					isLocationChecked={isLocationChecked}
					callBackTypeChange={this.callBackTypeChange}
					handleLocationChange={this.handleLocationChange}
					isDownloadDisable={isDownloadDisable}
					// isDownloadDisable={this.state.isDownloadDisable}
					handleDownloadBtnStatus={(data) => this.handleDownloadBtnStatus(data)}
					isError={isPollingError || isRequestError}
					caseTeam={caseDetails?.caseTeam || []}
					handleErrorStateChange={this.resetErrorStateChange}
					handleClose={handleClose}
					selectedShape={defaultTemplate?.shape}
					selectedTemplateTitle={defaultTemplate?.label}
					userDetails={userDetails}
				// resetSearchResults={this.resetSearchResults} 
				/>
				<div className="facecardgeneratorv1__resultsection">
					{isPreviewShow && !(isPollingError || isRequestError) && dividedSlides?.length > 0 && !getLoading ? (
						<FaceCardSlideListingv1
							startImageUrl={peoplePayloadData?.startImageUrl}
							endImageUrl={peoplePayloadData?.endImageUrl}
							dividedSlides={dividedSlides}
							isTitleShow={isTitleShow}
							isLocationShow={isLocationShow}
							isNameShow={isNameShow}
							isSlideTitle={peoplePayloadData?.slideTitle}
							isGreyScaleChecked={isGreyScaleChecked}
							selectedShape={defaultTemplate?.shape}
						/>
					) : (
						<FaceCardDefaultViewv1 stepper={stepper} isPollingError={isPollingError} isRequestError={isRequestError} />
					)}
					<FullPageLoader isVisible={getLoading} />
				</div>
			</>
		);
	}
}

export { FaceCardGeneratorv1 }