// constant
import { RECOMMENDED_TAGGING } from "redux/constants";

// local variable
export const name = "recommendedTagging";

// initial state
export const initialState = {
  taskRegister: {
    loading: false,
    error: false,
    data: {},
  },
  recommendedTags: {
    loading: false,
    error: false,
    data: {
      Subject: []
    },
  }
};
// reducer selectors
export const selectors = {
  getTaskRegisterLoading: (state) => state[name].taskRegister.loading,
  getTaskRegisterError: (state) => state[name].taskRegister.error,
  getTaskRegisterData: (state) => state[name].taskRegister.data,
  getRecommendedTagsLoading: (state) => state[name].recommendedTags.loading,
  getRecommendedTagsError: (state) => state[name].recommendedTags.error,
  getRecommendedTagsData: (state) => state[name].recommendedTags.data,
};

const taskRegisterSuccess = (state, payload) => {
  const { res } = payload;

  return Object.assign({}, state, {
    taskRegister: {
      ...state.taskRegister,
      loading: false,
      error: false,
      data: res,
    },
  });
}

const taskRegisterFailure = (state, payload) => {
  return Object.assign({}, state, {
    taskRegister: {
      ...state.taskRegister,
      loading: false,
      data: {},
      ...payload,
    },
  });
}

const taskRegisterPending = (state) => {
  const { task_register } = initialState;
  return Object.assign({}, state, {
    taskRegister: {
      ...task_register
    },
  });
}




const recommendedTagsSuccess = (state, payload) => {
  const { res } = payload;

  return Object.assign({}, state, {
    recommendedTags: {
      ...state.recommendedTags,
      loading: false,
      error: false,
      data: res,
    },
  });
}

const recommendedTagsFailure = (state, payload) => {
  return Object.assign({}, state, {
    recommendedTags: {
      ...state.recommendedTags,
      loading: false,
      data: {},
      ...payload,
    },
  });
}

const recommendedTagsPending = (state) => {
  const { recommendedTags } = initialState;
  return Object.assign({}, state, {
    recommendedTags: {
      ...recommendedTags,
      loading: true
    },
  });
}
const recommendedTagsReset = (state) => {
  const { recommendedTags } = initialState;
  return Object.assign({}, state, {
    recommendedTags: {
      ...recommendedTags
    },
  });
}




// reducer
export function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    // GET METADATA
    case RECOMMENDED_TAGGING.API_TASK_REGISTER_PENDING:
      return taskRegisterPending(state);
    case RECOMMENDED_TAGGING.API_TASK_REGISTER_SUCCESS:
      return taskRegisterSuccess(state, payload);
    case RECOMMENDED_TAGGING.API_TASK_REGISTER_FAILURE:
      return taskRegisterFailure(state, payload);

    case RECOMMENDED_TAGGING.API_RECOMMENDED_TAGS_RESET:
      return recommendedTagsReset(state);
    case RECOMMENDED_TAGGING.API_RECOMMENDED_TAGS_PENDING:
      return recommendedTagsPending(state);
    case RECOMMENDED_TAGGING.API_RECOMMENDED_TAGS_SUCCESS:
      return recommendedTagsSuccess(state, payload);
    case RECOMMENDED_TAGGING.API_RECOMMENDED_TAGS_FAILURE:
      return recommendedTagsFailure(state, payload);

    default:
      return state;
  }
}
