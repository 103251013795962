// constant
import CONFIG from "config";
import { MYCASES } from "redux/constants";
import { copyObject } from "utils/helpers/helpers";

// local variable
export const name = "mycases";

// initial state
export const initialState = {
    errorStatus: "",
    data: {},
    loading: false,
    durationLoading: false,
    isSaveTitleCaseLoading: false,
    isSaveSubjectCaseLoading: false,
    isSaveBstCaseLoading: false,
    caseDuration: []
};

export const selectors = {
    getMyCaseList: state => state[name].data.caseList,
    getProjectTypeList: state => state[name].data.projectTypeList,
    getPagination: state => state[name].data.pagination,
    getErrorStatus: state => state[name].errorStatus,
    getLoading: state => state[name].loading,
    getIsSaveTitleCaseLoading: state => state[name].isSaveTitleCaseLoading,
    getIsSaveSubjectCaseLoading: state => state[name].isSaveSubjectCaseLoading,
    getIsSaveBstCaseLoading: state => state[name].isSaveBstCaseLoading,
    getCaseDuration: state => state[name].caseDuration,
};

const setCasesBTPending = (state) => {
    return {
        ...state,
        loading: true,
    }
};

const setCasesBTFailure = (state, payload) => {
    const { error } = payload;
    return {
        ...state,
        errorStatus: error,
        loading: false,
    }
};

const setCasesBTSucess = (state, payload) => {
    const { res: { data, searchCriteria, pagination } } = payload;
    return {
        ...state,
        data: { ...data, searchCriteria: searchCriteria, pagination: pagination },
        errorStatus: "",
        loading: false,
    }
};

const setCasesDurationPending = (state) => {
    return {
        ...state,
        durationLoading: true,
    }
};

const setCasesDurationFailure = (state, payload) => {
    const { error } = payload;
    return {
        ...state,
        errorStatus: error,
        durationLoading: false,
    }
};

const setCasesDurationSucess = (state, payload) => {
    const { res } = payload;
    return {
        ...state,
        caseDuration: res,
        errorStatus: "",
        durationLoading: false,
    }
};

const getLoadingKey = (payload) => {
    const { data: { path } } = payload;
    const { MY_CASES: { BST_PATH, SUBJECT_PATH, TITLE_PATH } } = CONFIG;
    const loading = {
        [TITLE_PATH]: "isSaveTitleCaseLoading",
        [SUBJECT_PATH]: "isSaveSubjectCaseLoading",
        [BST_PATH]: "isSaveBstCaseLoading",
    };
    return loading[path];
}

const setSaveCasePending = (state, payload) => {
    const isLoadingKey = getLoadingKey(payload);
    return {
        ...state,
        [isLoadingKey]: true,
    }
};


const setSaveCaseFailure = (state, payload) => {
    const { error } = payload;
    const isLoadingKey = getLoadingKey(payload);
    return {
        ...state,
        errorStatus: error,
        [isLoadingKey]: false,
    }
};
const keyMap = {
    title: "sanitizedCaseTitle"
}
const setSaveCaseSuccess = (state, payload) => {
    const { guid, data } = payload;
    const isLoadingKey = getLoadingKey(payload);

    const caseList = copyObject(state?.data?.caseList) || [];
    caseList && caseList.forEach((item, index) => {
        const { caseGuid } = item;
        if (caseGuid.toLowerCase() === guid) {
            const { path } = data;
            path && path.forEach((key) => {
                const keyName = keyMap[key];
                caseList[index][keyName] = data[key];
                return false;
            });
        }
    });

    return {
        ...state,
        data: {
            ...state.data,
            caseList: caseList
        },
        errorStatus: "",
        [isLoadingKey]: false,
    }
};

export function reducer(state = initialState, action) {
    const { payload, type } = action
    switch (type) {
        case MYCASES.GET_CASES_BT_PENDING:
            return setCasesBTPending(state);
        case MYCASES.GET_CASES_BT_FAILURE:
            return setCasesBTFailure(state, payload);
        case MYCASES.GET_CASES_BT_SUCCESS:
            return setCasesBTSucess(state, payload);

        case MYCASES.GET_CASES_DURATION_PENDING:
            return setCasesDurationPending(state);
        case MYCASES.GET_CASES_DURATION_FAILURE:
            return setCasesDurationFailure(state, payload);
        case MYCASES.GET_CASES_DURATION_SUCCESS:
            return setCasesDurationSucess(state, payload);

        case MYCASES.SAVE_CASE_BT_PENDING:
            return setSaveCasePending(state, payload);
        case MYCASES.SAVE_CASE_BT_FAILURE:
            return setSaveCaseFailure(state, payload);
        case MYCASES.SAVE_CASE_BT_SUCCESS:
            return setSaveCaseSuccess(state, payload);
        default:
            return state;
    }
}