// constant
import { CASES } from "redux/constants";

// local variable
export const name = "case";

// initial state
export const initialState = {
    casesMetaDetails: {
        loading: false,
        errorMessage: '',
        data: []
    },
};
// reducer selectors
export const selectors = {
    getCasesMetaDetailsLoading: (state) => state[name].casesMetaDetails.loading,
    getCasesMetaDetailsError: (state) => state[name].casesMetaDetails.errorMessage,
    getCasesMetaDetails: (state) => state[name].casesMetaDetails.data,
};


const setCasesMetaDetailsPending = (state) => {
    return Object.assign({}, state, {
        casesMetaDetails: {
            ...state.casesMetaDetails,
            loading: true,
            errorMessage: ''
        },
    });
};

const setCasesMetaDetails = (state, payload) => {
    return Object.assign({}, state, {
        casesMetaDetails: {
            ...state.casesMetaDetails,
            data: payload.res,
            errorMessage: '',
            loading: false,
        },
    });
};

const setCasesMetaDetailsError = (state, payload) => {
    return Object.assign({}, state, {
        casesMetaDetails: {
            ...state.casesMetaDetails,
            errorMessage: payload.error,
            loading: false,
            data: {}
        },
    });
}

// reducer
export function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case CASES.API_GET_CASES_META_DETAILS_PENDING:
            return setCasesMetaDetailsPending(state);
        case CASES.API_GET_CASES_META_DETAILS_SUCCESS:
            return setCasesMetaDetails(state, payload);
        case CASES.API_GET_CASES_META_DETAILS_FAILURE:
            return setCasesMetaDetailsError(state, payload);

        default:
            return state;
    }
}
