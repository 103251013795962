// constant
import { META_DATA, KNOWLEDGE_ITEMS } from "redux/constants";
import CONFIG from 'config';
// helper
import {
  copyObject,
  filterImageDetails,
  mapUpdatesToMetadata,
} from "utils/helpers/helpers";
// local variable
export const name = "metadata";
// initial state
export const initialState = {
  loading: false,
  error: false,
  errorMessage: "",
  metaData: "",
  images: "",
  knowledgeBundlesDraft: "",
  knowledgeBundlesPublished: "",
  consumptionKnowledgeBundlePreview: "",
  consumptionKnowledgeBundle: "",
};

export const filterKbData = (data) => {
  const { KB_KEYS_TO_REMOVE } = CONFIG;
  const newData = copyObject(data);
  KB_KEYS_TO_REMOVE.forEach((item) => {
    delete newData[item];
  });
  return newData;
}
// reducer selectors
export const selectors = {
  getLoading: (state) => state[name].loading,
  getError: (state) => state[name].error,
  getErrorMessage: (state) => state[name].errorMessage,
  getImages: (state) => state[name].images,
  getMetadata: (state) => state[name].metaData,
  getFilteredKbMetaData: (state) => filterKbData(state[name].metaData),
  getKPListSavedInBundle: (state) => state[name].metaData.knowledgeObjects,
  getConsumptionKnowledgeBundle: (state) => state[name].consumptionKnowledgeBundle,
  getConsumptionKnowledgeBundlePreview: (state) => state[name].consumptionKnowledgeBundlePreview,
};

// reducer
export function reducer(state = initialState, action) {
  switch (action.type) {
    case META_DATA.API_SAVE_METADATA_FAILURE:
    case KNOWLEDGE_ITEMS.ADD_KNOWLEDGE_OBJECT_TO_BUNDLE_FAILURE:
    case KNOWLEDGE_ITEMS.SAVE_LINK_TO_METADATA_FAILURE:
    case META_DATA.API_GET_IMAGE_FAILURE:
    case META_DATA.API_GET_METADATA_FAILURE:
    case META_DATA.API_GET_KB_DRAFT_FAILURE:
    case META_DATA.API_GET_KB_PUBLISHED_FAILURE:
    case META_DATA.GET_CONSUMPTION_KB_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };
    // GET METADATA
    case META_DATA.API_GET_METADATA_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
        metaData: action.payload,
      };

    case META_DATA.API_GET_METADATA_SUCCESS:
      return {
        ...state,
        loading: false,
        metaData: action.payload,
      };

    //GET IMAGES
    case META_DATA.API_GET_IMAGE_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
        images: action.payload,
      };

    case META_DATA.API_GET_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        images: filterImageDetails(action.payload),
      };

    // SAVE METADA
    case META_DATA.API_SAVE_METADATA_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
        metaData: action.payload,
      };

    case META_DATA.API_SAVE_METADATA_SUCCESS:
      return {
        ...state,
        loading: false,
        metaData: action.payload,
      };

    // push Knowledge Object To METADATA
    case KNOWLEDGE_ITEMS.ADD_KNOWLEDGE_OBJECT_TO_BUNDLE_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };
    case KNOWLEDGE_ITEMS.ADD_KNOWLEDGE_OBJECT_TO_BUNDLE_SUCCESS:
      const updatedMetadatawithKO = mapUpdatesToMetadata(
        state.metaData,
        "knowledgeObjects",
        action.payload
      );
      return {
        ...state,
        loading: false,
        metaData: updatedMetadatawithKO,
      };

    // ADD Supporting Links to Metadata
    case KNOWLEDGE_ITEMS.SAVE_LINK_TO_METADATA_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };
    case KNOWLEDGE_ITEMS.SAVE_LINK_TO_METADATA_SUCCESS:
      const updatedMetadatawithSL = mapUpdatesToMetadata(
        state.metaData,
        "relatedLinks",
        action.payload
      );
      return {
        ...state,
        loading: false,
        metaData: updatedMetadatawithSL,
      };
    case META_DATA.CLEAR_METADATA:
      return {
        ...state,
        metaData: action.payload,
        consumptionKnowledgeBundlePreview: action.payload,
        consumptionKnowledgeBundle: action.payload
      };
    //Get draft knowledge bundles
    case META_DATA.API_GET_KB_DRAFT_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
        knowledgeBundlesDraft: action.payload,
      };
    case META_DATA.API_GET_KB_DRAFT_SUCCESS:
      return {
        ...state,
        loading: false,
        knowledgeBundlesDraft: action.payload,
      };
    //Get published knowledge bundles
    case META_DATA.API_GET_KB_PUBLISHED_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
        knowledgeBundlesPublished: action.payload,
      };
    case META_DATA.API_GET_KB_PUBLISHED_SUCCESS:
      return {
        ...state,
        loading: false,
        knowledgeBundlesPublished: action.payload,
      };
    case META_DATA.GET_CONSUMPTION_KB_DATA_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };
    case META_DATA.GET_CONSUMPTION_KB_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        consumptionKnowledgeBundle: action.payload.metadata,
      };
    case META_DATA.GET_PREVIEW_CONSUMPTION_KB_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        consumptionKnowledgeBundlePreview: action.payload.metadata,
      };

    default:
      return state;
  }
}
