import CONFIG from "config";
import { MY_NAVIGATOR } from "redux/constants";
export const name = "mynavigator";
// initial state
export const initialState = {
  loading: false,
  error: false,
  errorMessage: "",
  knowledgePages: "",
  noRecordFound: false,
  recordStats: {
    draft: 0,
    published: 0,
    withdrawn: 0,
    archived: 0,
    review: 0,
    total: 0,
    searchResult: 0
  },
  searchCriteria: {
    pageNumber: 1,
    pageSize: 20,
    status: CONFIG.KNOWLEDGE_STATUS.DRAFT,
    originalCreator: 0,
    searchType: CONFIG.KNOWLEDGE_TYPE_KEYS.PAGES,
    text: ""
  },
  tabActivities: {
    isLoading: false,
    data: [],
    searchCriteria: {
      pageNumber: 1,
      days: CONFIG.ACTIVITIES__DEFAULt_DAYS,
      pageSize: CONFIG.ACTIVITIES__PAGE_SIZE
    },
    recordStats: {
      total: 0,
      searchResult: 0,
      published: 0,
      draft: 0,
      archived: 0,
      withdrawn: 0,
      review: 0,
      assigned: 0,
      unassigned: 0
    }
  },
  downloadCountData: []
};

// reducer selectors
export const selectors = {
  getLoading: (state) => state[name].loading,
  getError: (state) => state[name].error,
  getErrorMessage: (state) => state[name].errorMessage,

  getKnowledgePages: (state) => state[name].knowledgePages,
  getRecordStatus: (state) => state[name].noRecordFound,
  getRecordStatistics: (state) => state[name].recordStats,
  getsearchCriteria: (state) => state[name].searchCriteria,

  getActivityList: (state) => state[name].tabActivities.data,
  getActivitiesSearchCriteria: (state) => state[name].tabActivities.searchCriteria,
  getActivitiesRecordStatistics: (state) => state[name].tabActivities.recordStats,
  getActivitiesLoading: (state) => state[name].tabActivities.isLoading,
  getDownloadCount: (state) => state[name].downloadCountData,

};

// reducer
export function reducer(state = initialState, action) {
  switch (action.type) {
    //all failures
    case MY_NAVIGATOR.KP_SEARCH_FAILURE:
    case MY_NAVIGATOR.KB_SEARCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        noRecordFound: false,
        errorMessage: action.payload,
      };
    case MY_NAVIGATOR.SHOW_CONTRIBUTION_LOADER:
      return {
        ...state,
        loading: true,
      }
    case MY_NAVIGATOR.HIDE_CONTRIBUTION_LOADER:
      return {
        ...state,
        loading: false,
      }
    // search knowledge pages pending
    case MY_NAVIGATOR.KP_SEARCH_PENDING:
    case MY_NAVIGATOR.KB_SEARCH_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        noRecordFound: false,
        errorMessage: "",
        searchCriteria: {
          ...state.searchCriteria,
          originalCreator: action.payload.criteria.originalCreator
        }
      };

    // search knowledge pages done
    case MY_NAVIGATOR.KP_SEARCH_SUCCESS:
    case MY_NAVIGATOR.KB_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        knowledgePages: action.payload.data,
        noRecordFound: action.payload?.data?.length === 0,
        recordStats: !!action.payload.statistics ?
          { ...action.payload.statistics } : initialState.recordStats
      };

    // change search criteria
    case MY_NAVIGATOR.CHANGE_SEARCH_TYPE:
    case MY_NAVIGATOR.CHANGE_STATUS_FILTER:
    case MY_NAVIGATOR.CHANGE_PAGE:
      return {
        ...state,
        searchCriteria: action.payload
      };
    case MY_NAVIGATOR.CHANGE_ACTIVITIES_PAGE:
      return {
        ...state,
        tabActivities: { ...state.tabActivities, searchCriteria: action.payload.searchCriteria }
      };

    case MY_NAVIGATOR.GET_MY_ACTIVITY_LIST_PENDING:
      return {
        ...state,
        tabActivities: {
          ...state.tabActivities,
          isLoading: true
        }
      }
    case MY_NAVIGATOR.GET_MY_ACTIVITY_LIST_FAILURE:
      return {
        ...state,
        tabActivities: {
          ...initialState.tabActivities,
          isLoading: false
        }
      }

    case MY_NAVIGATOR.GET_MY_ACTIVITY_LIST_SUCCESS:
      return {
        ...state,
        tabActivities: {
          ...state.tabActivities,
          data: action.payload.data,
          recordStats: !!action.payload.statistics ? { ...action.payload.statistics } : initialState.tabActivities.recordStats,
          isLoading: false
        }
      }

    case MY_NAVIGATOR.GET_DOWNLOADS_COUNT_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        downloadCountData: [],
        errorMessage: ''
      };
    case MY_NAVIGATOR.GET_DOWNLOADS_COUNT_SUCCESS:
      return {
        ...state,
        downloadCountData: action.payload,
        loading: false,
        errorMessage: '',
        error: false
      }
    case MY_NAVIGATOR.GET_DOWNLOADS_COUNT_FAILURE:
      return {
        ...state,
        errorMessage: action.payload.toString(),
        error: true,
        loading: false,
        downloadCountData: [],
      }

    default:
      return state;
  }
}
