import React from "react";
import PropTypes from "prop-types";
import Dropdown from 'components/shared/Dropdown';
import CONSTANTS from "globalConstants";
import deleteIcon from "assets/images/delete.svg";
import Image from "components/shared/Image";
import LABELS from "labels";
import { Link } from "react-router-dom";
// Config
import CONFIG from "config";

// Styles
import "./CaseListing.scss";

const {
	CP_CONTRIBUTION: {
		EMPTY_VALUE,
		INVALID_DATE,
		STEP_2_FIELDS: {
			DELETE_TITLE,
			DEFAULT_CASE_TYPE,
			CASE_LISTING: {
				CASE_ID,
				CASE_TITLE,
				TYPE,
				OPENED_DATE,
				CLOSED_DATE,
				CASE_TYPE: {
					KEY: TYPE_KEY,
					TEXT: TYPE_TEXT,
					VALUE: TYPE_VALUE,
					NAME: TYPE_NAME,
					ID: TYPE_ID
				}
			}
		}
	}
} = LABELS;
const { CASE_TYPE_LIST } = CONFIG;
const { CASE_TYPE_LIST: { MIGRATED } } = CONSTANTS;

const CaseListing = ({ caseListItems, caseTypeChangeHandler, deleteClickHandler, caseTypes, errorState }) => {
	const typeFilters = [];
	caseTypes.forEach(element => {
		const {
			[TYPE_ID]: ID,
			[TYPE_NAME]: NAME,
		} = element;
		typeFilters.push({
			[TYPE_KEY]: ID,
			[TYPE_TEXT]: CASE_TYPE_LIST[NAME],
			[TYPE_VALUE]: ID,
			className: CASE_TYPE_LIST[NAME] === MIGRATED ? "hide-migrated" : ""
		})
	});

	return (
		!!caseListItems && caseListItems.length > 0 && caseListItems.map(item => {
			const { caseNumber, caseTitle, caseTypeGuid, openDate, closedDate, caseGuid } = item;
			const migratedGuid = typeFilters?.filter(item => item[TYPE_TEXT] === MIGRATED)[0]?.key;
			let dropDownValidationClass = "";
			if (errorState && errorState.relatedCasesAndRelationshipType && !caseTypeGuid) {
				dropDownValidationClass = "dropdownValidationCls"
			}
			return (
				<div className="casesList" key={caseNumber}>
					<div className="casesList__item">
						<div className="casesList__item__data">
							<label> {CASE_ID}</label>
							<Link
								to={{ pathname: CONFIG.UI_URL.CP_CONSUMPTION_NEW(caseGuid) }}
								className="casesList__item__data-link"
								title={`Case consumption link: ${caseNumber}`}
								target="_blank"
							>
								{caseNumber}
							</Link>
						</div>
						<div className="casesList__item__data casesList__item__data--wider">
							<label> {CASE_TITLE}</label>
							{caseTitle}
						</div>
						<div className="casesList__item__data casesList__item__data--midwider">
							<label> {TYPE} *</label>
							<Dropdown
								fluid
								selection
								value={caseTypeGuid}
								options={typeFilters}
								placeholder={DEFAULT_CASE_TYPE}
								onChange={(e, { value }) => caseTypeChangeHandler(value, caseNumber)}
								clearable
								customClass={dropDownValidationClass}
								disabled={caseTypeGuid === migratedGuid}
							/>
						</div>
						<div className="casesList__item__data casesList__item__data--lesswider">
							<label> {OPENED_DATE}</label>
							{openDate && openDate !== INVALID_DATE ? openDate : EMPTY_VALUE}
						</div>
						<div className="casesList__item__data casesList__item__data--lesswider">
							<label> {CLOSED_DATE}</label>
							{closedDate && closedDate !== INVALID_DATE ? closedDate : EMPTY_VALUE}
						</div>
					</div>
					<div className="casesList__delete">
						<Image
							src={deleteIcon}
							alt="Delete Selected Case"
							className="casesList__delete"
							title={DELETE_TITLE}
							onClick={() => caseTypeGuid !== migratedGuid ? deleteClickHandler(item) : null}
							disabled={caseTypeGuid === migratedGuid}
						/>
					</div>
				</div>
			)
		})
	)
};
CaseListing.propTypes = {
	caseListItems: PropTypes.array.isRequired,
	caseTypes: PropTypes.array,
	caseTypeChangeHandler: PropTypes.func,
	deleteClickHandler: PropTypes.func.isRequired,
}
CaseListing.defaultProps = {
	caseTypeChangeHandler: () => { },
	caseTypes: []
}

export default CaseListing;