import React from "react";
import { getCasesBT, saveCaseBT, getCaseDuration } from "redux/actions/casesBT/casesBT.action";
import { actions as peopleResultsActions } from 'redux/actions/peopleresults/peopleresults_actions';
import { TAXONOMY } from "redux/constants";
import Image from "components/shared/Image";
import { connect } from "react-redux";
import { KnowledgeNoResults } from "components/KnowledgeNoResults";
import { selectors as taxonomySelector } from "redux/reducers/taxonomy/taxonomy_reducer";
import { selectors as BTSelectors } from 'redux/reducers/casesBT/casesBT_reducer';
import { areObjectsEqual, getStartEndPagination, replaceInLabel } from "utils/helpers/helpers";
import { bindActionCreators } from "redux";
import CONFIG from "config";
import { CaseItem } from "containers/CaseItem";
import LABELS from "labels";
import { Helmet } from "react-helmet";
import Dropdown from 'components/shared/Dropdown';
import { Pagination } from "components/Pagination";
import "./MyCases.scss";
import { FullPageLoader } from "components/shared/FullPageLoader";
import GLOBAL_CONSTANTS from "globalConstants";
class MyCasesContainer extends React.PureComponent {
  constructor(props) {
    const { MY_CASES: { DEFAULT_SELECTED_DURATION } } = CONFIG;
    super(props);
    this.state = {
      kmsOwnerNames: [],
      hrid: false,
      owners: [],
      selectedCaseStatus: 'all',
      selectedDuration: DEFAULT_SELECTED_DURATION,
      selectedCase: 'active',
    }
  }
  getUserNamesImages = async (hrid) => {
    const {
      peopleResultsActions: {
        getPeopleDataUsingIDs
      }
    } = this.props;
    const owners = [hrid];
    const peopleResult = await getPeopleDataUsingIDs(owners, false);
    this.setState({
      owners: peopleResult,
      hrid,
    });
  }

  getMYCasesIntialize = async () => {
    const { getMyCasesBT, getMyCaseDuration, hrid } = this.props;
    const { selectedDuration } = this.state;
    this.getUserNamesImages(hrid)
    this.setState({
      selectedHrid: hrid,
    })
    const {
      MY_CASES: {
        PAGE_NUMBER
      }
    } = CONFIG;
    getMyCasesBT(this.prepraeCaseBtData(PAGE_NUMBER, selectedDuration))
    getMyCaseDuration()
  }

  componentDidMount() {
    this.getMYCasesIntialize();
    const {
      CP_TAXONOMY_CONFIG: {
        PA,
        SUBJECT
      }
    } = CONFIG;
    const {
      getTaxonomy
    } = this.props;
    [...PA, ...SUBJECT].forEach((tag, index) => {
      setTimeout(function () {
        getTaxonomy(tag?.key)
      }, index * 500);
    });
  }

  componentDidUpdate = async (prevProps) => {
    const { myCasesBTList } = this.props;
    const { myCasesBTList: prevCasesBTList } = prevProps;
    const {
      peopleResultsActions: {
        getPeopleDataUsingIDs,
      }
    } = this.props;

    if (myCasesBTList?.length && !areObjectsEqual(myCasesBTList, prevCasesBTList)) {
      const kmsOwnerIDs = myCasesBTList.map(mycase => mycase.kmsOwner);
      const kmsOwnerNames = await getPeopleDataUsingIDs(kmsOwnerIDs, false);
      this.setState({ kmsOwnerNames })
    }
  }

  onkmsOwnerChange = (value) => {

  }
  onFilterChange = (value, type) => {
    const { getMyCasesBT } = this.props;
    const {
      MY_CASES: {
        PAGE_NUMBER
      }
    } = CONFIG;
    this.setState({
      [type]: value
    });
    getMyCasesBT(this.prepraeCaseBtData(PAGE_NUMBER, value))
  }
  changeMycasesPageHandler = (pageNumber) => {
    const { getMyCasesBT, paginationData } = this.props;
    const { selectedDuration } = this.state;
    paginationData.pageNumber = pageNumber;
    getMyCasesBT(this.prepraeCaseBtData(pageNumber, selectedDuration));
  }
  prepraeCaseBtData(pageNumber, days) {
    const {
      MY_CASES: {
        PAGE_SIZE,
      }
    } = CONFIG;
    const { hrid } = this.props;
    return {
      owner: hrid,
      // owner: "",
      ccstatus: "",
      days: days,
      withdrawn: "",
      caseType: "-1",
      allCount: "all",
      pageSize: PAGE_SIZE,
      pageNumber: pageNumber
    }
  }

  render() {
    const {
      myCasesBTList,
      isSaveTitleLoading,
      isSaveBstLoading,
      isSaveSubjectLoading,
      saveMyCaseBT,
      paginationData,
      expertTagsIds,
      subjectTagsIds,
      isMyCasesLoading,
      caseDuration
    } = this.props;
    const { kmsOwnerNames, hrid, owners, selectedHrid, selectedCaseStatus, selectedDuration, selectedCase } = this.state;
    const {
      PAGETITLE: {
        MY_NAVIGATOR
      },
      MY_CASES: {
        PAGINATION_LEFT_MESSAGE
      },
      MY_NAVIGATOR: {
        NO_RESULT_CASE_TITLE
      }
    } = LABELS;
    let kmsOwnersList = false;
    let selectedOwnerItem;
    let selectedCaseItem;
    let selectedDayItem;
    let selectedCaseStatusItem;
    if (owners && owners.length) {
      const { preferredFirstName, preferredLastName, picture, id } = owners[0];
      kmsOwnersList = [
        { key: id, text: preferredFirstName + " " + preferredLastName, value: id, image: { avatar: true, src: picture } },
        { key: "123455", text: "Kush Guglani", value: "123455", image: { avatar: true, src: picture } },
      ];
      selectedOwnerItem = kmsOwnersList.filter((item) => item.value === hrid)[0];
    }
    const caseCaptureStatus = [
      { key: "all", text: "All Case Capture Status", value: "all" },
      { key: "active", text: "Active", value: "active" },
    ];

    const caseStatusOptions = [
      { key: "withdrawn", text: "Withdrawn Cases", value: "withdrawn" },
      { key: "active", text: "Active Cases", value: "active" },
    ];

    let daysFilterOptions = [];

    if (caseDuration && caseDuration.length > 0) {
      daysFilterOptions = caseDuration.map(curr => {
        const { id, label } = curr;
        return ({
          key: id,
          text: label,
          value: id
        })
      });
    }
    selectedCaseItem = caseCaptureStatus.filter((item) => item.value === selectedCaseStatus)[0];
    selectedDayItem = daysFilterOptions.filter((item) => item.value === selectedDuration)[0];
    selectedCaseStatusItem = caseStatusOptions.filter((item) => item.value === selectedCase)[0];
    const BTFilterData = [
      {
        list: caseCaptureStatus,
        selected: selectedCaseStatus,
        selectedItem: selectedCaseItem,
        className: 'mid',
        disabled: true,
        key: "selectedCaseStatus"
      },
      {
        list: daysFilterOptions,
        selected: selectedDuration,
        selectedItem: selectedDayItem,
        className: 'mid',
        disabled: false,
        key: "selectedDuration"
      },
      {
        list: caseStatusOptions,
        selected: selectedCase,
        selectedItem: selectedCaseStatusItem,
        className: 'small',
        disabled: true,
        key: "selectedCase"
      },
    ]
    const { LABEL_VALUE } = GLOBAL_CONSTANTS;
    const showingTotal = paginationData?.totalCount;
    const { start: showingStart, end: showingEnd } = getStartEndPagination(paginationData?.pageSize, paginationData?.pageNumber, showingTotal)
    const paginationLeftMessage = replaceInLabel(PAGINATION_LEFT_MESSAGE, LABEL_VALUE, [showingStart, showingEnd, showingTotal])
    return (
      <>
        <div className="mycases my-navigator__content">
          <Helmet>
            <title>{MY_NAVIGATOR}</title>
          </Helmet>
          <ul className="mycases__filters">
            {selectedHrid && kmsOwnersList &&
              <li className="mycases__large">
                <Dropdown
                  text={
                    <div class="text" role="alert" aria-live="polite">
                      <span>
                        <Image avatar src={selectedOwnerItem?.image?.src} />
                        <span className="mycases__kmsownerlabel">{selectedOwnerItem.text}</span>
                      </span>
                    </div>
                  }
                  fluid
                  className="mycases__kmsowner"
                  disabled
                  defaultValue={selectedHrid}
                  selection
                  options={kmsOwnersList}
                  onChange={(e, { value }) => { this.onkmsOwnerChange(value); }}
                />

              </li>
            }
            {BTFilterData && BTFilterData.map((current, id) => {
              const { selected, selectedItem, list, className, disabled, key } = current;
              return (
                <li className={`mycases__${className}`} key={id}>
                  {selected && list && <Dropdown
                    text={selectedItem?.text}
                    fluid
                    disabled={disabled}
                    defaultValue={selected}
                    selection
                    options={list}
                    onChange={(e, { value }) => { this.onFilterChange(value, key); }}
                  />
                  }
                </li>
              )
            })
            }
          </ul>

          {!!myCasesBTList && myCasesBTList.length > 0 &&
            (<div className="mycases">
              {myCasesBTList.map((caseItem, id) => {
                const { kmsOwner } = caseItem;
                const kmsOwnerName = kmsOwnerNames && kmsOwnerNames.length > 0 && kmsOwnerNames.find(owner => owner?.id === kmsOwner);
                return (
                  <CaseItem
                    key={id}
                    data={caseItem}
                    expertTagsIds={expertTagsIds}
                    subjectTagsIds={subjectTagsIds}
                    saveMyCaseBT={saveMyCaseBT}
                    isSaveTitleLoading={isSaveTitleLoading}
                    isSaveSubjectLoading={isSaveSubjectLoading}
                    isSaveBstLoading={isSaveBstLoading}
                    kmsOwnerName={kmsOwnerName}
                  />
                )
              })}
              <div className="mycases__bottombar">
                <div className="mycases__showing">
                  {paginationLeftMessage}
                </div>
                <div className="mycases__pagination">
                  <Pagination
                    totalItems={paginationData?.totalCount}
                    onChange={this.changeMycasesPageHandler}
                    activePage={paginationData?.pageNumber}
                    itemsPerPage={paginationData?.pageSize}
                  />
                </div>
              </div>
            </div>)
          }
          {(!isMyCasesLoading && (!myCasesBTList || myCasesBTList.length === 0)) &&
            <KnowledgeNoResults hideSuggestions title={NO_RESULT_CASE_TITLE} />
          }
        </div>
        <FullPageLoader isVisible={isMyCasesLoading} inSection />
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  myCasesBTList: BTSelectors.getMyCaseList(state),
  isMyCasesLoading: BTSelectors.getLoading(state),
  isSaveTitleLoading: BTSelectors.getIsSaveTitleCaseLoading(state),
  isSaveSubjectLoading: BTSelectors.getIsSaveSubjectCaseLoading(state),
  isSaveBstLoading: BTSelectors.getIsSaveBstCaseLoading(state),
  paginationData: BTSelectors.getPagination(state),
  expertTagsIds: taxonomySelector.getExpertTagsIdsOnly(state),
  subjectTagsIds: taxonomySelector.getSubjectTagsIdsOnly(state),
  caseDuration: BTSelectors.getCaseDuration(state),
});

const mapDispatchToProps = (dispatch) => ({
  getMyCasesBT: (data) => dispatch(getCasesBT(data)),
  getMyCaseDuration: () => dispatch(getCaseDuration()),
  saveMyCaseBT: (data) => dispatch(saveCaseBT(data)),
  peopleResultsActions: bindActionCreators({ ...peopleResultsActions }, dispatch),
  getTaxonomy: (key) => dispatch({ type: TAXONOMY.GET_TAXONOMY, payload: { key } }),
});

export const MyCases = connect(
  mapStateToProps,
  mapDispatchToProps
)(MyCasesContainer);

export { MyCasesContainer };