import './FaceCardUserProfile.scss'
import { openLinkProfile } from 'utils/helpers/helpers'

import React, { useEffect } from 'react'
const FaceCardUserProfile = ({ userDetails: { picture, preferredFirstName, preferredLastName, id }, isScrolled }) => {
    const [imageHeight, setImageHeight] = React.useState(140);

    useEffect(() => {
        const handleScroll = () => {
            const startPosition = 0;
            const imageMaxHeight = 140;
            const imageMinHeight = 32;
            const scrollY = window.scrollY;
            if (isScrolled) {
                setImageHeight(imageMinHeight);
            } else if (scrollY >= startPosition && scrollY <= (startPosition + imageMaxHeight - imageMinHeight)) {
                setImageHeight(imageMaxHeight - (scrollY - startPosition));
            }
            if (scrollY < 10) {
                setImageHeight(imageMaxHeight);
            }
        } // Adjust the delay as needed

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isScrolled]);

    const handleProfileClick = () => {
        openLinkProfile(id, true);
    };
    return (
        <div className={`faceCardUserProfile ${isScrolled ? 'faceCardUserProfile__scrolled' : ''}`} onClick={handleProfileClick}>
            <img src={picture} className='faceCardUserProfile__image' style={{ width: imageHeight }} />
            <p className='faceCardUserProfile__name'>{`${preferredFirstName} ${preferredLastName}`}</p>
        </div>
    )
}

export default FaceCardUserProfile;