import React from "react";
import PropTypes from "prop-types";
import Image from "components/shared/Image";
import InfoIcon from "assets/images/info.svg";
import ReactTooltip from 'react-tooltip';
import "./Tooltip.scss";

export const Tooltip = ({ position, colorFormat, icon, on, off, children, labelText, labelClass, clickable, id, disabled, onClick, offset, delayHide, isShowCustomizeIconHTML, className, addScrollbar }) => {
  const iconHtml = icon ? icon : <Image src={InfoIcon} />;
  const iconClicked = () => {
    if (onClick) {
      ReactTooltip.hide();
      onClick();
    }
  }
  return (
    <>
      {!!labelText && <label className={`tooltip__label ${labelClass}`}>{labelText}</label>}
      <span className={`tooltipwrap ${className ? className : ''}`}>
        {isShowCustomizeIconHTML ? <>{iconHtml}</> : <span data-tip data-for={id} className="tooltipwrap__icon" onClick={iconClicked}>{iconHtml}</span>}
        <ReactTooltip
          clickable={clickable}
          className={`tooltipwrap__popup content ${colorFormat ? 'inverted' : ''} ${addScrollbar ? 'tooltipwrap__scrollbar' : ''} `}
          place={position}
          event={on}
          eventOff={off}
          id={id}
          multiline={true}
          effect='solid'
          disable={disabled}
          offset={offset}
          delayHide={delayHide}

        >
          {children}
        </ReactTooltip>
      </span>
    </>
  );
};

Tooltip.propTypes = {
  position: PropTypes.oneOf(["top", "left", "right", "bottom"]),
  colorFormat: PropTypes.bool,
  icon: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.element
  ]),
  children: PropTypes.any.isRequired,
  labelText: PropTypes.string,
  on: PropTypes.string,
  off: PropTypes.string,
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  offset: PropTypes.string,
  addScrollbar: PropTypes.bool
};
Tooltip.defaultProps = {
  position: "right",
  colorFormat: false,
  icon: false,
  labelText: "",
  labelClass: "",
  on: "mouseover",
  off: "mouseout",
  disabled: false,
  delayHide: 1000,
  onClick: () => { },
  offset: "",
  addScrollbar: false
};
