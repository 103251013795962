import React, { useState } from "react";
import PropTypes from "prop-types";

import { Form } from "semantic-ui-react";
import LABELS from "labels";

import "./SearchBar.scss";

export const SearchBar = (props) => {
  const { searchTerm, onSubmit, disabled } = props;

  const [searchText, setSearchText] = useState(searchTerm);

  const {
    MY_NAVIGATOR: {
      SEARCH_PLACEHOLDER
    }
  } = LABELS;

  const keyDownHandler = (event) => {
    if (event.keyCode === 13) {
      onSubmit(searchText);
    }
  };

  const changeHandler = (event) => {
    setSearchText(event.target.value);
  };

  return (
    <div className="search-bar">
      <Form.Input
        className="search-bar__input"
        data-testid="input-search-knowledge"
        type="text"
        value={searchText}
        placeholder={SEARCH_PLACEHOLDER}
        autoComplete="off"
        onKeyDown={keyDownHandler}
        onChange={changeHandler}
        name="searchbox"
        iconPosition="left"
        maxLength={100}
        icon="search"
        disabled={disabled}
      />
    </div>
  );
};

SearchBar.propTypes = {
  searchTerm: PropTypes.string,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool
};

SearchBar.defaultProps = {
  searchTerm: "",
  disabled: false,
  onSubmit: () => { }
};
