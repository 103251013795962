import React from 'react';
import { connect } from 'react-redux';
import CONFIG from 'config';
import { getFromLocalStorageAsJSON } from 'utils/localStorage/localStorage';
import { getSmartLogicBearerToken } from 'redux/actions/authorization/authorization.actions';

class SmartLogicSetupContainer extends React.Component {
  componentDidMount() {
    const { fetchSmartLogicData } = this.props;
    if (!getFromLocalStorageAsJSON(CONFIG.OKTA.STK)) {
      fetchSmartLogicData();
    }
  }
  render() {
    return <div />;
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchSmartLogicData: () => dispatch(getSmartLogicBearerToken()),
})
export const SmartLogicSetup = connect(
  null,
  mapDispatchToProps
)(SmartLogicSetupContainer);

export default SmartLogicSetupContainer;