import { put, call, takeEvery } from 'redux-saga/effects';
// Constant
import { KNOWLEDGEOBJECT_VERSIONING } from "redux/constants";
// Config
import CONFIG from "config";

// Axios Util
import { doAxiosRequest } from "config-axios";
import { showPageLoader, hidePageLoader } from "redux/actions/global/global.actions";
import { showContributionLoader, hideContributionLoader } from "redux/actions/mynavigator/mynavigator.actions";
import { moduleFailure } from "redux/global.action.js";

import {
    deleteKnowledgeAssetPending
    , deleteKnowledgeAssetSuccess
    , deleteKnowledgeAssetFailure
    , unpublishKnowledgeAssetPending
    , unpublishKnowledgeAssetSuccess
    , unpublishKnowledgeAssetFailure,
    archiveKnowledgeAssetPending,
    archiveKnowledgeAssetSuccess,
    archiveKnowledgeAssetFailure,
    cloneKnowledgeAssetPending,
    cloneKnowledgeAssetFailure,
    cloneKnowledgeAssetSuccess
} from "./versioning.actions";

export function* deleteKnowledgeAsset(action) {
    const { payload: { knowledgeId, isContributionPage } } = action;
    const isContribution = typeof isContributionPage !== "undefined" && isContributionPage;
    try {
        const { XA, API_URL: { DELETE_KNOWLEDGE_ASSET } } = CONFIG;
        yield put(deleteKnowledgeAssetPending(knowledgeId));
        isContribution ? yield put(showContributionLoader()) : yield put(showPageLoader());
        const paramurl = DELETE_KNOWLEDGE_ASSET(knowledgeId);
        const res = yield call(doAxiosRequest, {
            method: 'get',
            endpoint: paramurl,
            headers: {
                'x-api-key': XA
            }
        });
        yield put(deleteKnowledgeAssetSuccess(knowledgeId, res));
    } catch (error) {
        yield put(deleteKnowledgeAssetFailure(knowledgeId, error));
        yield put(hideContributionLoader());
        yield put(hidePageLoader());
    }
}
export function* unpublishKnowledgeAsset(action) {
    const { payload: { knowledgeId, isContributionPage } } = action;
    const isContribution = typeof isContributionPage !== "undefined" && isContributionPage;
    try {
        const { XA, API_URL: { UNPUBLISH_KNOWLEDGE_ASSET } } = CONFIG;
        yield put(unpublishKnowledgeAssetPending(knowledgeId, !isContribution));
        isContribution ? yield put(showContributionLoader()) : yield put(showPageLoader());
        const paramurl = UNPUBLISH_KNOWLEDGE_ASSET(knowledgeId);
        const res = yield call(doAxiosRequest, {
            method: 'get',
            endpoint: paramurl,
            headers: {
                'x-api-key': XA
            }
        });
        yield put(unpublishKnowledgeAssetSuccess(knowledgeId, !isContribution, res));
        if (!isContribution) {
            yield put(hidePageLoader());
        }
    } catch (error) {
        yield put(unpublishKnowledgeAssetFailure(error));
        yield put(hideContributionLoader());
        yield put(hidePageLoader());
    }
}

export function* archiveKnowledgeAsset(action) {
    const { payload: { knowledgeId, isContributionPage } } = action;
    const isContribution = typeof isContributionPage !== "undefined" && isContributionPage;
    try {
        const { XA, API_URL: { ARCHIVE_KNOWLEDGE_ASSET } } = CONFIG;
        yield put(archiveKnowledgeAssetPending(knowledgeId, !isContribution));
        isContribution ? yield put(showContributionLoader()) : yield put(showPageLoader());
        const paramurl = ARCHIVE_KNOWLEDGE_ASSET(knowledgeId);
        const res = yield call(doAxiosRequest, {
            method: 'get',
            endpoint: paramurl,
            headers: {
                'x-api-key': XA
            }
        });
        yield put(archiveKnowledgeAssetSuccess(knowledgeId, !isContribution, res));
        if (!isContribution) {
            yield put(hidePageLoader());
        }

    } catch (error) {
        yield put(archiveKnowledgeAssetFailure(error));
        yield put(hideContributionLoader());
        yield put(hidePageLoader());
    }
}


export function* cloneKnowledgeAsset(action) {
    const { payload: { id, status, isContribution } } = action;
    const { XA, API_URL: { CLONE_KNOWLEDGE_ASSET } } = CONFIG;

    try {
        yield put(cloneKnowledgeAssetPending(id));
        isContribution ? yield put(showContributionLoader()) : yield put(showPageLoader());
        const res = yield call(doAxiosRequest, {
            method: 'get',
            endpoint: `${CLONE_KNOWLEDGE_ASSET(id)}?status=${status}`,
            headers: {
                'x-api-key': XA
            }
        });
        if (!isContribution) {
            yield put(hidePageLoader());
        }

        if (res?.error.code === 200) {
            yield put(cloneKnowledgeAssetSuccess({ id, isContribution, newId: res.id }));
        } else {
            yield put(cloneKnowledgeAssetFailure({ id, isContribution, error: res?.error.code }));
        }
    } catch (error) {
        yield put(cloneKnowledgeAssetFailure({ id, isContribution, error: "uncaught error" }));
        yield put(hideContributionLoader());
        yield put(hidePageLoader());
    }
}


export default function* VersioningSaga() {
    try {
        yield takeEvery(KNOWLEDGEOBJECT_VERSIONING.DELETE_KNOWLEDGE_ASSET, deleteKnowledgeAsset);
        yield takeEvery(KNOWLEDGEOBJECT_VERSIONING.UNPUBLISH_KNOWLEDGE_ASSET, unpublishKnowledgeAsset);
        yield takeEvery(KNOWLEDGEOBJECT_VERSIONING.ARCHIVE_KNOWLEDGE_ASSET, archiveKnowledgeAsset);
        yield takeEvery(KNOWLEDGEOBJECT_VERSIONING.CLONE_KNOWLEDGE_ASSET, cloneKnowledgeAsset);
    } catch (error) {
        yield put(moduleFailure(error, 'VersioningSaga'));
    }
}