import { META_DATA } from "redux/constants";

/*  Consumption KB*/
export const getConsumptionKBPending = () => ({
  type: META_DATA.GET_CONSUMPTION_KB_DATA_PENDING,
  payload: {
    res: ''
  }
});

export const getConsumptionKBSuccess = (metadata) => ({
  type: META_DATA.GET_CONSUMPTION_KB_DATA_SUCCESS,
  payload: {
    metadata: metadata
  }
});

/*  Preview Consumption KB*/
export const getPreviewConsumptionKBSuccess = (metadata) => ({
  type: META_DATA.GET_PREVIEW_CONSUMPTION_KB_DATA_SUCCESS,
  payload: {
    metadata: metadata?.data
  }
});

export const getConsumptionKBFailure = (error) => ({
  type: META_DATA.GET_CONSUMPTION_KB_DATA_FAILURE,
  payload: error.toString()

});

/*  get metadata KB*/
export const getMetadataKBPending = () => ({
  type: META_DATA.API_GET_METADATA_PENDING,
  payload: ''
});

export const getMetadataKBSuccess = (metadata) => ({
  type: META_DATA.API_GET_METADATA_SUCCESS,
  payload: metadata?.data,
})

export const getMetadataKBFailure = (error) => ({
  type: META_DATA.API_GET_METADATA_FAILURE,
  payload: error.toString()
});