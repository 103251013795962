import React from 'react';
import PropTypes from 'prop-types';

// shared components
import { TabComponent } from 'components/shared/TabComponent';

// constants
import LABELS from 'labels';

//Analytics
import ANALYTICS from "utils/analytics/analytics";
import { PAGES, PRIMARY_CATEGORY, TOOLNAME, DTMRULE, TRIGGERS } from "utils/analytics/analytics_constants";

// styles
import './RelatedCases.scss';
import { RelatedCasesList } from './RelatedCasesList/RelatedCasesList';

export class RelatedCases extends React.PureComponent {
  state = {
    activeTab: 0
  };

  setActiveTab = (activeIndex) => {
    this.setState({ activeTab: activeIndex });
  }

  cardClickAnalyticsCallback = (caseId) => {

    const { projectId } = this.props;
    const { CASE_PAGE } = TOOLNAME;
    const { CP_RELATED_CASE_CLICK } = TRIGGERS;
    const { VIEW_MATERIAL_PAGE } = DTMRULE;

    const newAdobeData = {
      caseID: projectId,
      casePage: {
        trigger: CP_RELATED_CASE_CLICK,
        id: caseId
      },
      page: {
        category: {
          primaryCategory: PRIMARY_CATEGORY.CASE_PAGE,
        },
        pageInfo: {
          pageName: PAGES.CASE_PAGE
        },
      },
      displayMode: PRIMARY_CATEGORY.CASE_PAGE,
      resultType: PRIMARY_CATEGORY.CASE_PAGE,
      materialPAStatus: "NA"
    };

    ANALYTICS.kc.sendEventData(newAdobeData, VIEW_MATERIAL_PAGE, CASE_PAGE);
  }

  iconClickCallback = (hrid, caseId) => {
    const { projectId } = this.props;
    const { CASE_PAGE } = TOOLNAME;
    const { CP_RELATED_CASE } = TRIGGERS;
    const { VIEW_AUTHOR_PROFILE } = DTMRULE;

    const newAdobeData = {
      caseID: projectId,
      casePage: {
        trigger: CP_RELATED_CASE,
        id: caseId
      },
      page: {
        category: {
          primaryCategory: PRIMARY_CATEGORY.CASE_PAGE,
        },
        pageInfo: {
          pageName: PAGES.CASE_PAGE
        },
      },
      resultType: PRIMARY_CATEGORY.CASE_PAGE,
      authorStaffID: hrid
    };

    ANALYTICS.kc.sendEventData(newAdobeData, VIEW_AUTHOR_PROFILE, CASE_PAGE);
  }

  render() {
    const { relatedCases, redirectOnV1, isViewClientInfo } = this.props;
    const { CP_CONSUMPTION: { RELATED_CASES } } = LABELS;
    return (
      <div className="relatedCasesWrapper margin_bottom--30">
        <TabComponent
          className="tabs__container RelatedCasesWrapper__tab-component"
          defaultActiveIndex={0}
          getActiveIndex={this.setActiveTab}
          isCollapsible={true}
          menu={{ secondary: true, pointing: true }}
          arrayOfPaneElements={[
            {
              className: 'item-style',
              heading: RELATED_CASES,
              count: Object.keys(relatedCases)?.length ?? 0,
              component:
                <RelatedCasesList
                  casePages={relatedCases}
                  cardClickAnalyticsCallback={this.cardClickAnalyticsCallback}
                  iconClickCallback={this.iconClickCallback}
                  redirectOnV1={redirectOnV1}
                  isViewClientInfo={isViewClientInfo}
                />,
            },
          ]}
        />
      </div>
    );
  }
}

RelatedCases.prototypes = {
  KPIds: PropTypes.array,
  isPreviewDownloadEntitlement: PropTypes.bool,
  isPreviewDownloadREntitlement: PropTypes.bool
}

RelatedCases.defaultProps = {
  KPIds: [],
  isPreviewDownloadEntitlement: false,
  isPreviewDownloadREntitlement: false,
  redirectOnV1: false
}
