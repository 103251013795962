// constant
import { KNOWLEDGE_ITEMS } from "redux/constants";
// local variable
export const name = "knowledgeitems";
// initial state
export const initialState = {
  loading: false,
  error: false,
  errorMessage: "",
  searchedItem: "",
  knowledgeItems: [],
  relatedLinks: [],
  noRecordFound: false,
  recentContributions: []
};
// reducer selectors
export const selectors = {
  getLoading: (state) => state[name].loading,
  getError: (state) => state[name].error,
  getErrorMessage: (state) => state[name].errorMessage,
  getSearchedKnowledgeItemData: (state) => state[name].searchedItem,
  getKnowledgeItemsinBundle: (state) => state[name].knowledgeItems || [],
  getRecordStatus: (state) => state[name].noRecordFound,
  getSupportingLinksinBundle: (state) => state[name].relatedLinks,
  getRecentContributions: (state) => state[name].recentContributions
};

// reducer
export function reducer(state = initialState, action) {
  switch (action.type) {
    //all failures
    case KNOWLEDGE_ITEMS.KP_SEARCH_FAILURE:
    case KNOWLEDGE_ITEMS.SAVE_KNOWLEDGEITEM_TO_METADATA_FAILURE:
    case KNOWLEDGE_ITEMS.ADD_LINK_TO_BUNDLE_FAILURE:
    case KNOWLEDGE_ITEMS.GET_RECENT_CONTRIBUTIONS_FAILURE:
    case KNOWLEDGE_ITEMS.FETCH_ALL_KNOWLEDGEOBJECTS_FROM_BUNDLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        noRecordFound: false,
        errorMessage: action.payload,
      };

    case KNOWLEDGE_ITEMS.KP_SEARCH_PENDING:
    case KNOWLEDGE_ITEMS.GET_RECENT_CONTRIBUTIONS_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        noRecordFound: false,
        errorMessage: "",
        searchedItem: action.payload
      };

    case KNOWLEDGE_ITEMS.KP_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        searchedItem: action.payload,
        noRecordFound: action.payload?.length === 0,
      };

    case KNOWLEDGE_ITEMS.GET_RECENT_CONTRIBUTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        recentContributions: action.payload,
        noRecordFound: false,
      };

    case KNOWLEDGE_ITEMS.KP_SEARCH_NORECORD:
    case KNOWLEDGE_ITEMS.GET_RECENT_CONTRIBUTIONS_NORECORD:
      return {
        ...state,
        loading: false,
        noRecordFound: true,
      };

    case KNOWLEDGE_ITEMS.FETCH_ALL_KNOWLEDGEOBJECTS_FROM_BUNDLE_PENDING:    
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };

    case KNOWLEDGE_ITEMS.FETCH_ALL_KNOWLEDGEOBJECTS_FROM_BUNDLE_SUCCESS:    
      return {
        ...state,
        loading: false,
        knowledgeItems: action.payload,
      };

    // save supporting link to bundle
    case KNOWLEDGE_ITEMS.ADD_LINK_TO_BUNDLE_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };

    case KNOWLEDGE_ITEMS.ADD_LINK_TO_BUNDLE_SUCCESS:
      const { relatedLinks } = state;
      const newLink = action.payload;
      return {
        ...state,
        loading: false,
        noRecordFound: false,
        relatedLinks: [...relatedLinks, newLink],
      };
    case KNOWLEDGE_ITEMS.RESET_LOCALSEARCH_STATE_SUCCESS:
      return {
        ...state,
        noRecordFound: false,
        searchedItem: "",
      };

    default:
      return state;
  }
}
