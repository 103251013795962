/*
 * you can use this component like this <SupportingLink />
 * also you need inject props
 */
// React
import React from 'react';
// Prop types
import PropTypes from 'prop-types';
// Shared component
import Image from 'components/shared/Image';
// icon
import DeleteIcon from 'assets/images/delete.svg';
import LinkIcon from 'assets/images/linkify.svg';

// Css
import './SupportingLink.scss';
import { ActionButton } from 'components/shared/ActionButton';

//LABELS
import LABELS from 'labels';

import { checkOrAddProtocolToUrl } from "utils/helpers/helpers";

import {
  TRIGGERS
} from "utils/analytics/analytics_constants";
const { DELETE } = LABELS.BUTTONS;


/**
 * @desc return the SupportingLink Component
 * @returns html
 */
const SupportingLink = ({
  item,
  ind,
  onDelete,
  deleteIconInline = true,
  deleteIconOutside = false,
  deleteCallback,
  fileIcon,
  additionalURL,
  onLinkClickCallback,
  onLinkClick = (url) => {
    onLinkClickCallback(url, TRIGGERS.ADDITIONAL_URLS)
  },
}) => (
  <div className="slink">
    <div className="slink__tile">
      {!fileIcon ? (
        <Image className="slink__image--link" src={LinkIcon} />
      ) : (
        <Image className="slink__image--link" src={fileIcon} />
      )}
      <div className="slink__content">
        {!!additionalURL ? (
          <>
            <div className="slink__title">{additionalURL.title}</div>
            <a
              href={checkOrAddProtocolToUrl(additionalURL.url)}
              target="_blank"
              rel="noopener noreferrer"
              className="slink__link"
              onClick={() => onLinkClick(checkOrAddProtocolToUrl(additionalURL.url))}
            >
              {additionalURL.url}
            </a>
          </>
        ) : (
          <>
            <div className="slink__title">{item.name}</div>
            <a
              href={checkOrAddProtocolToUrl(item.value)}
              target="_blank"
              rel="noopener noreferrer"
              className="slink__link"
              onClick={() => onLinkClick(checkOrAddProtocolToUrl(item.value))}
            >
              {item.value}
            </a>
          </>
        )}
      </div>
      {!!deleteIconInline && (
        <Image
          onClick={(e) => onDelete(e, ind)}
          className="slink__icon--delete"
          src={DeleteIcon}
          alt="Delete Supporting links"
        />
      )}
    </div>
    {!!deleteIconOutside && (
      <ActionButton type={DELETE} onClickCallback={deleteCallback} />
    )}
  </div>
);

// Export SupportingLink as default
export default SupportingLink;

// SupportingLink Prop types
SupportingLink.propTypes = {
  onDelete: PropTypes.object,
  index: PropTypes.number,
  item: PropTypes.object,
  deleteIconInline: PropTypes.bool,
  deleteIconOutside: PropTypes.bool,
  deleteCallback: PropTypes.func,
  onLinkClick: PropTypes.func,
};

SupportingLink.defaultProps = {
  onLinkClick: () => { }
}