import { put, takeLatest, call } from 'redux-saga/effects';
// Constant
import { POLYHIERACHY } from "redux/constants";
// Config
import CONFIG from "config";
// Axios Util
import { doAxiosRequest } from "config-axios";
import {
    polyhierarchyPending,
    polyhierarchySuccess,
    polyhierarchyFailure
} from "./polyhierarchy.actions";
import authService from 'redux/auth';


export function* getPolyhierarchy(action) {
    try {
        const { API_URL: { POLYHIERARCHY } } = CONFIG;
        const accessToken = authService.getAccessToken();
        yield put(polyhierarchyPending());
        const axiosConfig = {
            method: "get",
            headers: {
                'authorization': `Bearer ${accessToken}`,
                'x-api-key': process.env.REACT_APP_SC_X_API_KEY
            },
            endpoint: POLYHIERARCHY()
        };
        const response = yield call(doAxiosRequest, axiosConfig);
        yield put(polyhierarchySuccess(response || []));
    } catch (error) {
        yield put(polyhierarchyFailure(error));
    }
};
export default function* polyhierarchySaga() {
    try {
        yield takeLatest(POLYHIERACHY.GET_POLYHIERARCHY_API_DATA, getPolyhierarchy);
    } catch (error) {
        yield put(polyhierarchyFailure(error, 'PolyhierarchySaga'));
    }
}