import { put, call, takeLatest } from 'redux-saga/effects';
// Constant
import { CASE_HISTORY } from "redux/constants";
// Config
import CONFIG from "config";
// Axios Util
import { doAxiosRequest } from "config-axios";
// Actions
import {
    getCaseHistorySuccess, getCaseHistoryPending, getCaseHistoryFailure
} from "./caseHistory.actions";
import { moduleFailure } from "redux/global.action.js";
import authService from 'redux/auth';

export function* getCaseHistory(action) {
    try {
        yield put(getCaseHistoryPending());
        const { payload: { CaseId, Count } } = action;
        const { API_URL: { GET_CASE_HISTORY } } = CONFIG;
        const accessToken = authService.getAccessToken();

        const axiosConfig = {
            method: 'GET',
            endpoint: GET_CASE_HISTORY(CaseId, Count),
            headers: {
                'x-api-key': process.env.REACT_APP_SC_X_API_KEY,
                'authorization': `Bearer ${accessToken}`,
            }
        };
        const response = yield call(doAxiosRequest, axiosConfig);
        yield put(getCaseHistorySuccess(response?.value || []));

    } catch (error) {
        yield put(getCaseHistoryFailure(error));
    }
};

export default function* caseHistorySaga() {
    try {
        yield takeLatest(CASE_HISTORY.GET_CASE_HISTORY, getCaseHistory);
    } catch (error) {
        yield put(moduleFailure(error, 'caseHistorySaga'));
    }
}
