import { UPLOAD_S3 } from 'redux/constants';
export const name = 'upload';
export const initialState = {
  file: {},
  uploadLoader: false,
  success: '',
  error: ''
};
export const selectors = {
  getFileName: state => state[name].file,
  getUploadLoader: state => state[name].uploadLoader,
  fileUploadStatus: state => state[name].success,
};

const setUploadLoading = (state, payload) => {
  const { uploadLoader, file, } = payload;
  return {
    ...state,
    file: file,
    uploadLoader: uploadLoader,
    success: '',
  };
};

const setUploadError = (state, payload) => {
  const { uploadLoader, file, error } = payload;
  return {
    ...state,
    file: file,
    uploadLoader: uploadLoader,
    error: error,
    success: ''
  };
};

const setUploadData = (state, payload) => {
  const { uploadLoader, file, success } = payload;
  return {
    ...state,
    file: file,
    uploadLoader: uploadLoader,
    success: success
  }
};

export function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case UPLOAD_S3.GET_UPLOAD_S3_PENDING:
    case UPLOAD_S3.FETCH_PRESIGNURL_PENDING:
      return setUploadLoading(state, payload);
    case UPLOAD_S3.GET_UPLOAD_S3_SUCCESS:
    case UPLOAD_S3.FETCH_PRESIGNURL_SUCCESS:
      return setUploadData(state, payload);
    case UPLOAD_S3.GET_UPLOAD_S3_FAILURE:
    case UPLOAD_S3.FETCH_PRESIGNURL_FAILURE:
      return setUploadError(state, payload);
    default:
      return state;
  }
}
