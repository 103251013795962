import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
// SHARED COMPONENTS
import { InfoGrid } from "components/shared/InfoGrid";
import { Button } from "components/shared/Button";
import KPList from "components/KPList/KPList";
import { PopupModal } from "components/shared/PopupModal/PopupModal";
import { AddKnowledgeItems } from "containers/KnowledgeBundles/AddKnowledgeItems";
import { CaseDurationAndCapture } from "components/CaseDurationAndCapture/CaseDurationAndCapture";
import { OverlayViewSubject, SUBSCRIBERS } from '@kdshared/docviz';
// Config
import CONFIG from 'config';
// Labels
import LABELS from "labels";
// helpers
import { openURL, getFilteredKPCategorizedList } from "utils/helpers/helpers";
// SCSS
import "./CPStep4.scss";

class CPStep4 extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showAddKPModal: false,
    }
  }

  componentDidMount = () => {
    const { onLoadAnalytics, getKPFile } = this.props;
    onLoadAnalytics()

    this.overlaySubscriber = OverlayViewSubject.subscribe(action => {
      switch (action.type) {
        case SUBSCRIBERS.OVERLAY_DOWNLOAD_CLICK:
          getKPFile(action?.doc?.kpId);
          break;
        default:
          break;
      }
    });
  }

  componentWillUnmount() {
    if (this.overlaySubscriber) {
      this.overlaySubscriber.unsubscribe();
    }
  }

  renderAssetsList = ({ data }) => {
    const { DOWNLOAD_APPLICATION_NAME: { CASE_PAGE_APP_NAME } } = CONFIG;
    const { handleDocvizPreview } = this.props;
    const {
      CP_CONTRIBUTION: {
        STEP_4_FIELDS: {
          CASE_MATERIALS,
          CASE_VIGNETTES,
        }
      },
    } = LABELS;
    const { materials, vignettes } = getFilteredKPCategorizedList(data);
    const listingObject = [
      {
        heading: CASE_VIGNETTES,
        content: vignettes,
        ids: vignettes.map(item => item.id),
      },
      {
        heading: CASE_MATERIALS,
        content: materials,
        ids: materials.map(item => item.id),
      },
    ];
    return (
      <div className="cpstep4__assetsListing">
        {listingObject?.map((group, ind) => {
          const { heading, content, ids } = group;
          return (
            !!content?.length &&
            <KPList
              key={ind}
              isConsumptionView={false}
              heading={heading}
              KPIds={ids}
              isPreviewDownloadEntitlement
              isPreviewDownloadREntitlement
              handleDocvizPreview={handleDocvizPreview}
              data={content}
              downloadApplicationName={CASE_PAGE_APP_NAME}
              isCasePage
            />
          )
        })}
      </div>
    );
  };

  toggleAddKpModal = () => {
    const { showAddKPModal } = this.state;
    this.setState({ showAddKPModal: !showAddKPModal });
  }

  addNewKP = () => {
    const { UI_URL: { CREATION_KP } } = CONFIG;
    openURL(CREATION_KP, true);
    this.toggleAddKpModal();
  }

  render() {
    const {
      unSanitizedCaseData,
      caseInfo,
      stepState: {
        curated,
        proposalReceived,
        knowledgeAssets = [],
        caseVignettesKnowledgeAssets = [],
      },
      handleChange,
      fetchRecentContributions,
      searchKnowledgeItems,
      assetsDetails,
      addKnowledgeAsset,
      handleDocvizPreview,
    } = this.props;

    const { showAddKPModal } = this.state;

    const {
      CP_CONTRIBUTION: {
        YES,
        NO,
        STEP_4_FIELDS: {
          CURATED,
          PROPOSAL_RECEIVED,
          SEARCH_ASSETS_LABEL,
          SEARCH_ASSETS_PLACEHOLDER,
          NOTHING_SEARCHED,
          CP_ADD_NEW_KP,
          ADD_KP_MODAL_BUTTON,
          ADD_KP_MODAL_HEADING,
          ADD_KP_MODAL_SUBHEADING,
        },
      },
    } = LABELS;

    const { CP_CONTRIBUTION_LIMITS: { KNOWLEDGE_ASSETS_LIMIT } } = CONFIG;

    return (
      <div className="cpstep cpstep4">
        <CaseDurationAndCapture {...caseInfo} />
        <InfoGrid
          data={unSanitizedCaseData}
          showTitleDataSeparator={false}
        />
        <Form.Group>
          <Form.Field>
            <label>{PROPOSAL_RECEIVED}</label>
            <div className="cpstep4__radio">
              <Form.Radio
                label={YES}
                name='proposalReceived'
                value={true}
                checked={proposalReceived}
                onChange={(e, { value }) => {
                  handleChange(value, 'proposalReceived');
                }}
              />
              <Form.Radio
                label={NO}
                name='proposalReceived'
                value={false}
                checked={!proposalReceived}
                onChange={(e, { value }) => {
                  handleChange(value, 'proposalReceived');
                }}
              />
            </div>
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field>
            <label>{CURATED}</label>
            <div className="cpstep4__radio">
              <Form.Radio
                label={YES}
                name='curated'
                value={true}
                checked={curated}
                onChange={(e, { value }) => {
                  handleChange(value, 'curated');
                }}
              />
              <Form.Radio
                label={NO}
                name='curated'
                value={false}
                checked={!curated}
                onChange={(e, { value }) => {
                  handleChange(value, 'curated');
                }}
              />
            </div>
          </Form.Field>
        </Form.Group>
        <div className="cpstep4__assets">
          <AddKnowledgeItems
            defaultState={assetsDetails}
            defaultStateKoidList={knowledgeAssets.concat(caseVignettesKnowledgeAssets) ?? []}
            limit={KNOWLEDGE_ASSETS_LIMIT}
            getItems={() => { }}
            handleOnSelection={addKnowledgeAsset}
            handleOnSearch={searchKnowledgeItems}
            handleOnRecentContributions={fetchRecentContributions}
            handleDocvizPreview={handleDocvizPreview}
            searchLabel={SEARCH_ASSETS_LABEL}
            searchPlaceHolder={SEARCH_ASSETS_PLACEHOLDER}
            showHelpIcon={false}
            showDelete={false}
            nothingSearched={NOTHING_SEARCHED}
            className="knowledge-objects--caseItems"
            renderItemsList={this.renderAssetsList}
            isDraggable={false}
            showSearchField={false}
            rightChildren={
              <>
                <span className="cpstep4__assets__or">OR</span>
                <Button type="button" className="cpstep4__assets__add black" onClick={this.toggleAddKpModal}>{CP_ADD_NEW_KP}</Button>
              </>
            }
          />
        </div>
        <PopupModal
          onRequestClose={this.toggleAddKpModal}
          isOpen={showAddKPModal}
          showCloseIcon={true}
          button1={{
            text: ADD_KP_MODAL_BUTTON,
            clickHandler: this.addNewKP,
            button1Class: "green centered"
          }}
          modalContentWidth="368px"
        >
          <h6 className="cpstep4__kpModalHeading">{ADD_KP_MODAL_HEADING}</h6>
          <p className="cpstep4__kpModalContent">{ADD_KP_MODAL_SUBHEADING}</p>
        </PopupModal>
      </div>
    );
  }
}

CPStep4.propTypes = {
  unSanitizedCaseData: PropTypes.array.isRequired,
  caseInfo: PropTypes.object.isRequired,
  stepState: PropTypes.object,
  assetsDetails: PropTypes.array,
  handleChange: PropTypes.func.isRequired,
  fetchRecentContributions: PropTypes.func.isRequired,
  searchKnowledgeItems: PropTypes.func.isRequired,
  addKnowledgeAsset: PropTypes.func.isRequired,
  handleDocvizPreview: PropTypes.func.isRequired,
};

CPStep4.defaultProps = {
  assetsDetails: [],
  stepState: {},
};

export { CPStep4 };
