import React from "react";
import PropTypes from "prop-types";
import { isContentRestrictedOrNull } from "utils/helpers/helpers";
import "./CaseType.scss";
import withErrorBoundary from "containers/ErrorBoundary/ErrorBoundary";

export const CaseTypeWrapper = ({ caseType, caseNumber }) => {
  return (
    <>
      {(caseType || caseNumber) &&
        <div className="caseelements caseType">
          {caseType && <span className="caseType_type">{caseType}</span>}
          {caseNumber && !isContentRestrictedOrNull(caseNumber) && <span className="caseType_number"> : {caseNumber}</span>}
        </div>
      }
    </>
  );
};

CaseTypeWrapper.propTypes = {
  caseType: PropTypes.string,
  caseNumber: PropTypes.string
};
CaseTypeWrapper.defaultProps = {
  caseType: "",
  caseNumber: "",
};

export const CaseType = withErrorBoundary(CaseTypeWrapper);
