import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ReactPaginate from 'react-paginate';
import CONFIG from "config";
import "./Pagination.scss";

export const Pagination = ({ totalItems, onChange, activePage, itemsPerPage }) => {
    const [pageCount, setPageCount] = useState(0);
    const [pageOffset, setPageOffset] = useState(0);

    useEffect(() => {
        setPageCount(Math.ceil(totalItems / itemsPerPage));
    }, [totalItems, itemsPerPage]);

    useEffect(() => {
        setPageOffset(activePage - 1);
    }, [activePage]);

    const handlePageChange = (event) => {
        onChange(event.selected + 1);
    };

    return (
        <ReactPaginate
            breakLabel={"..."}
            breakClassName={"pagination__break-me"}
            onPageChange={handlePageChange}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            pageCount={pageCount}
            forcePage={pageOffset}
            containerClassName="pagination"
            pageClassName="pagination__item"
            pageLinkClassName="pagination__link"
            activeClassName="active"
            previousLabel="previous"
            previousClassName="pagination__prev"
            previousLinkClassName="pagination__prev__link"
            nextLabel="next"
            nextClassName="pagination__next"
            nextLinkClassName="pagination__next__link "
            renderOnZeroPageCount={null}
        />
    )
}

Pagination.defaultProps = {
    activePage: CONFIG.API_URL.SEARCH.DEFAULTS.PAGE_NUMBER,
    itemsPerPage: CONFIG.API_URL.SEARCH.DEFAULTS.PAGE_SIZE
};

Pagination.propTypes = {
    totalItems: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    activePage: PropTypes.number.isRequired,
    itemsPerPage: PropTypes.number
};
