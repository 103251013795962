// Configuration object for toolbar
const CONFIG = {
  style: {
    bottom: "12px",
    right: "20px",
    top: "auto",
    position: "absolute"
  },
  placeHolder: "",
  // List of menu show in toolbar
  options: ["inline", "list"],
  // Toolbar customize options
  toolBarOptions: {
    inline: {
      inDropdown: false,
      options: ["bold", "italic", "underline"]
    },
    list: {
      inDropdown: false,
      options: ['unordered', 'ordered']
    },
  },
  editorLabels: {
    "components.controls.blocktype.normal": "Paragraph"
  }
};
// export config
export default CONFIG;
