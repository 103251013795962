// Constant
import { GLOBAL } from "redux/constants";

export const showPageLoader = (text) => ({
    type: GLOBAL.SHOW_PAGELOADER,
    payload: text
});

export const hidePageLoader = () => ({
    type: GLOBAL.HIDE_PAGELOADER,
    payload: ''
});

export const pageLoaderError = (error) => ({
    type: GLOBAL.FULLPAGELOADER_ERROR,
    error
});

export const disableBodyOverflow = () => ({
    type: GLOBAL.DISABLE_BODY_OVERFLOW
});

export const enableBodyOverflow = () => ({
    type: GLOBAL.ENABLE_BODY_OVERFLOW
});

