import React from 'react';
import './FullPageLoader.scss';
import Image from "components/shared/Image";
import PropTypes from "prop-types";
import loaderIcon from "assets/images/fp_loader.gif";
import LABELS from "labels";

const { LOADING, LOADING_ICON_ALT } = LABELS;

// Help Icon return the help hover functionality 
export const FullPageLoader = ({ isVisible, loadingText, inSection }) => {
    return isVisible ? (
        <div className={`fullpageloader__wrap ${inSection ? "fullpageloader__wrap--insection " : ""}`}>
            <Image
                src={loaderIcon}
                alt={LOADING_ICON_ALT}
            />
            <span className="fullpageloader__text">{loadingText}</span>
        </div>
    ) : null;
};

FullPageLoader.propTypes = {
    isVisible: PropTypes.bool,
    loadingText: PropTypes.string,
};

FullPageLoader.defaultProps = {
    isVisible: false,
    loadingText: LOADING,
};