import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import OutsideClickHandler from 'react-outside-click-handler';
import Image from "components/shared/Image";
import warningIcon from "assets/images/warning/warning.svg";
import './EmailInput.scss';

const EmailInput = ({ placeholder, emailIds, error, handleChange, handleOusideClick, handleKeyPress }) => {
  return (
    <div className="emailInputWrapper">
      <OutsideClickHandler onOutsideClick={handleOusideClick}>
        <Form className='emailInputWrapper__emailInput' onSubmit={(e) => { e.preventDefault(); }}>
          <Form.Group>
            <Form.Field className='emailInputWrapper__field'>
              <div className={`emailInputWrapper__input ${error ? "emailInputWrapper__input--error" : ""}`}>
                <Form.Input
                  type="text"
                  value={emailIds}
                  onChange={handleChange}
                  onKeyDown={handleKeyPress}
                  placeholder={placeholder}
                />

              </div>
              {error &&
                <div className="emailInputWrapper__error">
                  <p>
                    <Image src={warningIcon}
                      className='email__warningIcon'
                    />
                    {error}
                  </p>
                </div>}
            </Form.Field>
          </Form.Group>
        </Form>
      </OutsideClickHandler>
    </div>
  );
};

EmailInput.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  isLoading: PropTypes.bool,
  prefillEmails: PropTypes.array
};

EmailInput.defaultProps = {
  placeholder: '',
  isLoading: false,
  prefillEmails: []
};

export default EmailInput;
