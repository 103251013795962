import React from "react";
import Autosuggest from "react-autosuggest";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions as peopleResultsActions } from "redux/actions/peopleresults/peopleresults_actions";
import { selectors as typeAheadResultsSelectors } from "redux/reducers/peopleresults/peopleresults_reducer";
import { Tooltip } from "components/shared/Tooltip";
import Image from "components/shared/Image";
import crossIcon from "assets/images/cross-icon.svg";
import "./TypeAheadSelector.scss"
import { areObjectsEqual } from "utils/helpers/helpers";

class TypeAheadSelectorContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      suggestions: [],
      selectedSuggestions: [],
      initialData: []
    };
  }

  componentDidMount() {
    const {
      peopleResultsActions: { getTypeAheadData }, name, apiUrl, preFillData
    } = this.props;
    getTypeAheadData(name, apiUrl);
    const data = this.objectKeysFormatter(preFillData, "input");
    this.setState({
      selectedSuggestions: data
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { preFillData } = this.props;
    const { preFillData: previousFillData } = prevProps;
    if (prevProps.getTypeAheadResults !== this.props.getTypeAheadResults) {
      this.setState({
        initialData: this.props.getTypeAheadResults
      })
    }
    if (preFillData.length && !areObjectsEqual(previousFillData, preFillData)) {
      const data = this.objectKeysFormatter(preFillData, "input");
      this.setState({
        selectedSuggestions: data
      })
    }
  }

  objectKeysFormatter = (data, end) => {
    const { prefillKeyName, responseKeyName } = this.props;
    return data.map(function (item) {
      if (end === "input") {
        for (let key in prefillKeyName) {
          item[responseKeyName[key]] = item[prefillKeyName[key]];
        }
      }
      if (end === "output") {
        for (let key in responseKeyName) {
          item[prefillKeyName[key]] = item[responseKeyName[key]];
          delete item[responseKeyName[key]];
        }
        if (!item.Children && !item.Path) {
          delete item.Children;
          delete item.Path;
        }
      }
      return item;
    })
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  shouldRenderSuggestions = (value) => {
    const { onFocusShowList, characterCount } = this.props;
    if (onFocusShowList) {
      if (value.length >= 0) {
        return true;
      }
    } else {
      if (value.length >= characterCount) {
        return true;
      }
    }
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  getSuggestions = (value) => {
    const { characterCount, responseKeyName } = this.props;
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength < characterCount
      ? this.state.initialData
      : this.state.initialData.filter(
        (item) => item[responseKeyName["name"]].toLowerCase().indexOf(inputValue) > -1
      );
  };

  getSuggestionValue = (suggestion) => {
    const { selectedSuggestions, value: valueText } = this.state;
    const { responseKeyName, getSuggestionValueCallback } = this.props;
    const displayName = suggestion[responseKeyName["name"]];
    if (getSuggestionValueCallback) {
      getSuggestionValueCallback({ valueText, displayName });
    }

    const isPresentSlectedData = selectedSuggestions.filter((item) => {
      return item[responseKeyName["id"]] === suggestion[responseKeyName["id"]];
    }).length;
    const updateState = isPresentSlectedData === 0 ? [...selectedSuggestions, suggestion] : [...selectedSuggestions]
    this.setState({
      selectedSuggestions: updateState,
    }, () => {
      const temp = JSON.parse(JSON.stringify(updateState));
      const sendData = this.objectKeysFormatter(temp, "output")
      this.props.onChange(sendData);
      this.setState({
        value: "",
      });
    }
    );

    return suggestion[responseKeyName["name"]];
  };

  renderSuggestion = (suggestion) => {
    const { responseKeyName } = this.props;
    return (
      <div className="react-autosuggest__suggestion-wrap" id="id">
        <span>{suggestion[responseKeyName["name"]]}</span>
      </div>
    );
  };

  deleteSelected = (selectedItem) => {
    const { selectedSuggestions } = this.state;
    const { responseKeyName } = this.props;
    const filterData = selectedSuggestions.filter((item) => {
      return item[responseKeyName["id"]] !== selectedItem[responseKeyName["id"]];
    });
    this.setState({
      selectedSuggestions: filterData,
    }, () => {
      const temp = JSON.parse(JSON.stringify(filterData));
      const sendData = this.objectKeysFormatter(temp, "output")
      this.props.onChange(sendData);
    });
  };

  filterSuggestion = (suggestions) => {
    const { responseKeyName, hideList } = this.props;
    try {
      if (suggestions &&
        Array.isArray(suggestions) &&
        suggestions.length &&
        hideList &&
        Array.isArray(hideList) &&
        hideList.length) {
        const newSuggestion = suggestions.filter((item) => {
          return hideList.indexOf(item[responseKeyName["id"]]) === -1
        });
        return newSuggestion;
      }
    } catch (error) {
      console.log('error ', error)
    }
    return suggestions;
  }

  render() {
    const { value, suggestions, selectedSuggestions } = this.state;
    const finalSuggestion = this.filterSuggestion(suggestions);
    const {
      label,
      className,
      maxLimit,
      placeholder,
      disabledCross,
      infoIcon
    } = this.props;

    const inputProps = {
      placeholder: placeholder,
      value,
      onChange: this.onChange,
    };

    return (
      <>
        {!!label && (<label>{label}
          {infoIcon && (
            <Tooltip id="typeahead">Lorem ipsum</Tooltip>
          )}
        </label>)}
        <div
          className={`typeaheadselector typeaheadselector__wrapper ${selectedSuggestions.length ? "" : " typeaheadselector__wrapper--full"
            } ${className ? className : " "}`}
        >
          {selectedSuggestions.map((item, index) => {
            const { responseKeyName } = this.props;
            return (
              <div key={index} className="typeaheadselector__selected">
                <span>{item[responseKeyName["name"]]}</span>
                {!disabledCross && (
                  <Image
                    className="typeaheadselector__selected__crossicon"
                    src={crossIcon}
                    alt="cross"
                    onClick={() => this.deleteSelected(item)}
                  />
                )}
              </div>
            );
          })}
          {selectedSuggestions.length < maxLimit && (
            <Autosuggest
              suggestions={finalSuggestion}
              onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
              onSuggestionsClearRequested={this.onSuggestionsClearRequested}
              getSuggestionValue={this.getSuggestionValue}
              renderSuggestion={this.renderSuggestion}
              shouldRenderSuggestions={this.shouldRenderSuggestions}
              inputProps={inputProps}
            />
          )}
        </div>
      </>
    );
  }
}

TypeAheadSelectorContainer.defaultProps = {
  disabledCross: false,
  onChange: () => { },
  maxLimit: 500,
  preFillData: [],
  responseKeyName: {
    id: "Id",
    name: "Name",
  },
  prefillKeyName: {
    id: "id",
    name: "name",
  },
  onFocusShowList: false,
  characterCount: 2
};

const mapStateToProps = (state, ownProps) => ({
  getTypeAheadResults: typeAheadResultsSelectors.getTypeAheadResults(state, ownProps.name),
});

const mapDispatchToProps = (dispatch) => ({
  peopleResultsActions: bindActionCreators({ ...peopleResultsActions }, dispatch),
});

export { TypeAheadSelectorContainer };

export const TypeAheadSelector = connect(
  mapStateToProps,
  mapDispatchToProps
)(TypeAheadSelectorContainer);
