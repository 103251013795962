import React from "react";
import { connect } from "react-redux";
// Semantic UI
import { PeopleSelector } from "components/PeopleSelector";
import ToggleSwitch from "components/shared/ToggleSwitch/ToggleSwitch";
import { Button } from "components/shared/Button";
import {
	copyObject,
	getImageNamePreAndPost,
	getPeopleApiDataByHrids,
	sortDataByKey,
} from "utils/helpers/helpers";
import LABELS from "labels";
import { selectors as fcgSelectors } from "redux/reducers/faceCardGeneratorv1/faceCardGeneratorv1_reducer";
import { bindActionCreators } from "redux";
import { actions as peopleResultsActions } from "redux/actions/peopleresults/peopleresults_actions";

//////////// Importing Constants & Helper /////////

import FACE_CARD_CONFIG from "containers/FaceCardGeneratorv1/FaceCardv1.config";
import Image from "components/shared/Image";
import LoaderIcon from "assets/images/spinner/spinner-v3.gif";
import closeFcg from "assets/images/fcg_close.svg";
import "./FCG_ProposalBio.scss";
import CONFIG from "config";
import { TabComponent } from "components/shared/TabComponent/TabComponent";

const {
	METADATA: { PEOPLE_SELECTOR_PLACEHOLDER },
	FACE_CARD_SELECT_TEXTS: { BY_PEOPLE, BY_CASE_NO, BY_EMAIL_ID },
} = LABELS;

class FCG_ProposalBio extends React.PureComponent {
	constructor(props) {
		super();
		this.state = {
			isTitleChecked: false,
			isLocationChecked: false,
			peopleIds: [],
			peopleIdsWithData: [],
			fcgClose: true,
			selectedTemplateTitle: "",
			selectedShape: " ",
			byType: BY_PEOPLE,
			isPreviewDisable: true,
			isDownloadDisable: true,
			isPreviewInProgress: false,
			slideValue: "",
			localSlideTitleLength: 0,
			peopleData: null,
		};
	}


	getHridArray = (obj) => {
		let temp = [];
		temp = obj?.map((obj) => obj?.hrid || obj?.id);
		return temp;
	};

	componentDidUpdate(prevProps, prevState) {
		const { byType, peopleIds } = this.state;

		if (peopleIds !== prevState.peopleIds) {
			if (byType === BY_PEOPLE) {
				if (peopleIds.length === 0) {
					this.setState({ isPreviewDisable: true });
				} else {
					this.setState({ isPreviewDisable: false });
				}
			}
		}
	}
	handleChange = (hrids) => {
		const { handlePeopleSelectorUpdate, resetCallback } = this.props;
		const { peopleIdsWithData } = this.state;
		try {
			let newPeopleIdsWithData = [];
			if (peopleIdsWithData.length) {
				newPeopleIdsWithData = sortDataByKey(peopleIdsWithData, hrids, "id");
				newPeopleIdsWithData = newPeopleIdsWithData.filter((person) =>
					hrids.includes(person.id)
				);
			}

			this.setState({ peopleIds: hrids, 
				peopleIdsWithData: newPeopleIdsWithData , 
				isPreviewDisable: hrids.length === 0, 
                isDownloadDisable: true,
			});
			if (!hrids.length && resetCallback) {
				resetCallback();
			}
			peopleResultsActions.getPeopleDataUsingIDs(hrids).then((data) => {
				if (data && data.length) {
					this.setState({ peopleData: data[0] });
				}
			});
			handlePeopleSelectorUpdate(hrids);
		} catch (error) {
			console.log("Error in handle Change ", error);
		}
	};

	handleFaceCardBtnClick = (id) => {
		try {
			if (id === "btn-preview") {
				this.handlePreview();
			} else if (id === "btn-download") {
				console.log("download");
			}
		} catch (error) {
			console.log("Error in handleFaceCArdBtnClick ", error);
		}
	};
	handlePreview = async () => {
		const {
			peopleResultsActions: { getPeopleDataUsingIDs },
		} = this.props;
		const { peopleIds } = this.state;

		try {
			if (peopleIds.length > 0) {
				const finalData = await getPeopleApiDataByHrids(
					peopleIds,
					getPeopleDataUsingIDs,
					false,
					true
				);

				if (finalData && finalData.length > 0) {
					const peopleData = finalData[0];
					this.setState({
                        peopleData: {
                            ...peopleData,
                        }, 
						isPreviewDisable: true,
						isDownloadDisable:false });

					if (this.props.onPreviewData) {
						this.props.onPreviewData({
							...peopleData,
						});
					}
				}
			}
		} catch (error) {
			this.setState({ isPreviewDisable: false, isDownloadDisable: false });
			console.error("Error in handlePreview:", error);
		}
	};

	updateButtonsConfig = (buttons) => {
		try {
			const { getLoading, isError } = this.props;
			const { peopleIds, isPreviewDisable , isDownloadDisable} = this.state;
			if (buttons?.length > 0) {
				if (peopleIds.length === 0 || getLoading) {
					buttons[0].disabled = true;
					buttons[1].disabled = true;
				} else {
					buttons[0].disabled = false;
				}
				if (peopleIds.length) {
					if (isPreviewDisable || getLoading) {
						buttons[0].disabled = true;
					} else {
						buttons[0].disabled = false;
					}
				}
				if (isDownloadDisable || getLoading || isError) {
					buttons[1].disabled = true;
				} else {
					buttons[1].disabled = false;
				}
			}
		} catch (error) {
			console.log("Error in updateButtonsConfig function ", error);
		}
	};

	crossClickCallback = () => {
		const { resetCallback , handleClearProposalData} = this.props;
		this.setState(
			{
				peopleIds: [],
				peopleIdsWithData: [],
				isDownloadDisable: true,
				isPreviewDisable: true, 
			},
			() => {
				if (resetCallback) resetCallback();
				if (handleClearProposalData) handleClearProposalData();
			}
		);
	};

	callBackAfterPeopleApiFetch = (data) => {
		const finalPeopleIds = this.getHridArray(data);
		const { byType } = this.state;
		this.setState(
			{
				peopleIds: finalPeopleIds,
				peopleIdsWithData: data,
			},
			() => {
				if (finalPeopleIds.length > 0 && byType === BY_EMAIL_ID) {
					this.handlePreview();
				}
			}
		);
	};

	handleClose = () => {
		this.setState({
			fcgClose: true,
		});
	};

	render() {
		const { getLoading, isGrayscale, isSquareShape, onToggleShape, onToggleGrayscale } = this.props;
		const buttons = copyObject(FACE_CARD_CONFIG.footerWorkflow);
		const { peopleIds, byType} = this.state;
		//Update buttons config
		this.updateButtonsConfig(buttons);
		let typeFilters = [{ key: BY_PEOPLE, text: BY_PEOPLE, value: BY_PEOPLE }];
		if (process.env.REACT_APP_IS_FACECARD_EMAIL_ENABLED?.trim() === "true") {
			typeFilters = [
				...typeFilters,
				{ key: BY_EMAIL_ID, text: BY_EMAIL_ID, value: BY_EMAIL_ID },
			];
		}
		const {
			FACE_CARD_GENERATOR: { USE_B_W, PROFILE_PIC_SHAPE },
		} = LABELS;
		return (
			<>
				<div className="FCG_ProposalBio__header">
					<div className="FCG_ProposalBio__title-wrapper">
						<div className="FCG_ProposalBio__title">
							<h2>{this.props.selectedTemplateTitle || "Default Title"}</h2>
						</div>
					</div>
					<button className="FCG_ProposalBio__closeBtn" onClick={this.props.handleClose}>
						<span>Close</span>
						<img src={closeFcg} alt="Close" />
					</button>
				</div>
				<div className="FCG_ProposalBio">
					<div className="FCG_ProposalBio-header">
						<div className="FCG_ProposalBio-searchSec">
							<>
								<TabComponent
									className="FCG_ProposalBio__tabsComponent"
									defaultActiveIndex={0}
									menu={{ secondary: true, pointing: true }}
									hideCount
									arrayOfPaneElements={[
										{
											heading: LABELS.FACE_CARD_SELECT_TEXTS.BY_PEOPLE,
										},
									]}
								></TabComponent>
							</>
							<PeopleSelector
								placeholder={PEOPLE_SELECTOR_PLACEHOLDER}
								isInputFirst
								name="authors"
								preFillData={[peopleIds]}
								isStateReset={!peopleIds.length}
								isBigImage
								excludeAlumni
								maxLimit={1}
								callBackAfterPeopleApiFetch={this.callBackAfterPeopleApiFetch}
								onChange={(data) => {
									this.handleChange(data);
								}}
								suggestions={{
									useDragDrop: true,
								}}
								getSuggestionValueCallback={() => {}}
								className="FCG_ProposalBio__peopleselector"
								isScrollable={true}
								crossClickCallback={this.crossClickCallback}
							/>
						</div>
						<div className="FCG_ProposalBio__content">
							<div className="FCG_ProposalBio__slideTitleCheckboxes">
								<div className="FCG_ProposalBio__blackWhiteToggle__shapeToggle">
									<span>{PROFILE_PIC_SHAPE}</span>
									<div className="FCG_ProposalBio__blackWhiteToggle__shapeToggle__shapeOptions">
										<span className="FCG_ProposalBio__blackWhiteToggle__shapeToggle__shapeOptions__circle">
											{"Circle"}
										</span>
										<ToggleSwitch
										onToggle={onToggleShape}
										isActive={isSquareShape}
										/>	
										<span className="FCG_ProposalBio__blackWhiteToggle__shapeToggle__shapeOptions__square">
											{"Square"}
										</span>
									</div>
								</div>

								<div className="FCG_ProposalBio__blackWhiteToggle__bwToggle">
									<span>{USE_B_W}</span>
									<ToggleSwitch
										onToggle={onToggleGrayscale}
										isActive={isGrayscale}
									/>
								</div>
							</div>
						</div>
						<div className="FCG_ProposalBio-btnSec">
							{getLoading && (
								<Image className="loadericon" src={LoaderIcon} alt="In progress" />
							)}
							{buttons &&
								buttons.map((btn, ind) => (
									<React.Fragment key={`btn-child-${ind}`}>
										{btn?.visible && (
											<Button
												className={`${ind == 1 ? "green" : "black"} ${
													btn.classname
												} ${btn.disabled ? "btn-disbled" : ""}`}
												onClick={() => this.handleFaceCardBtnClick(btn?.id)}
												disabled={btn.disabled}
											>
												<span>{btn.text}</span>
											</Button>
										)}
									</React.Fragment>
								))}
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	getLoading: fcgSelectors.getRequestLoading(state) || fcgSelectors.getPollingLoading(state),
	preSignedUrl: fcgSelectors.getPresignedURL(state),
});

const mapDispatchToProps = (dispatch) => ({
	peopleResultsActions: bindActionCreators({ ...peopleResultsActions }, dispatch),
});

export const FCG_ProposalBiov1 = connect(mapStateToProps, mapDispatchToProps)(FCG_ProposalBio);
