import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from 'redux';
import CONFIG from "config";
import LABELS from "labels";
import CONSTANTS from "globalConstants";
import { v4 as uuidv4 } from 'uuid';
// Components
import KPItem from "components/KPItem/KPItem";
import { DOCVIZ, KNOWLEDGE_ITEMS, KP } from "redux/constants";
import { ConditionalDownloadIcon } from "components/shared/ConditionalDownloadIcon";
import { ShowMore } from 'components/shared/ShowMore_Less/ShowMore';
import Image from "components/shared/Image";
import GridListButton from "components/GridListButton/GridListButton";
import KPGrid from "components/KPGrid/KPGrid";

// Actions
import { actions as KPActions } from 'redux/actions/knowledgepage/knowledgepage_actions';
import {
  notifyWarning,
  dismissNotifications,
} from "redux/actions/notification/notification_actions";

// Selectors
import { selectors as associatedKPsSelectors } from "redux/reducers/associatedKPs/associatedKPs_reducer";
import { selectors as kpSelectors } from "redux/reducers/knowledgepage/knowledgepage_reducer";
import { selectors as entitlementSelectors } from "redux/reducers/authorization/authorization_reducer";
import { selectors as docvizSelectors } from "redux/reducers/docviz/docviz_reducer";

import {
  showDownloadIcon,
  isPreviewDisabled,
  showPreviewIcon,
  splitKpIdsArray,
  formatTheDate,
  checkIsGivenValueIsAArray,
  getElemDistanceFromTop,
  triggerUsabillaSurvey,
  getDefaultViewSection
} from "utils/helpers/helpers";
import { DTMRULE, PAGES, PRIMARY_CATEGORY } from "utils/analytics/analytics_constants";
import ANALYTICS from "utils/analytics/analytics";

import PreviewIcon from 'assets/images/preview-light.svg';
import DownloadIcon from 'assets/images/download.svg';
import ShareIcon from 'assets/images/share.svg';
import bookmarkIcon from 'assets/images/bookmark.png';

import { sendToLocalStorage } from 'utils/localStorage/localStorage';

// SCSS
import "./KPList.scss";

export const getIcon = (title) => {
  switch (title) {
    case "bookmark":
      return <Image src={bookmarkIcon} alt={title} className="kpList__icon kpList__icon-bookmark " />;
    case "preview":
      return <Image className="kpList__preview--icon" src={PreviewIcon} alt={title} />;
    case "download":
      return <Image src={DownloadIcon} alt={title} />;
    case "share":
      return <Image src={ShareIcon} alt={title} />;
    default:
      return null;
  }
};

const {
  KPCONSUMPTIONGROUP: {
    PA_RECOMMENDED,
    NOT_PA_RECOMMENDED
  },
  ERROR_MESSAGES: {
    DOWNLOAD_ERROR
  },
} = LABELS;

const {
  ENTITLEMENTS: {
    KNOWLEDGE_CONTRIBUTIONS,
    PREVIEW_DOWNLOAD,
    PREVIEW_DOWNLOAD_R
  },
  USABILLA_SURVEY_KEYS: {
    TC_GRID
  }
} = CONSTANTS;

const { DOWNLOAD_APPLICATION_NAME: { KNOWLEDGE_PAGE_APP_NAME }, CP_UI_DATE_FORMAT } = CONFIG;

class KPList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      kpViewSection: getDefaultViewSection(true),
      splitKpIdsData: {
        firstArr: [],
        lastArr: []
      },
      isShowMore: true
    }
  }

  componentDidMount() {
    const {
      getAssociatedKnowledgePages,
      KPIds,
      isConsumptionView,
      API,
      getFilesStatus,
      data,
      showKpGridView,
      getKPFile
    } = this.props;
    if (KPIds && KPIds.length) {
      if (!data) {
        getAssociatedKnowledgePages(KPIds, isConsumptionView, API);
      }
      getFilesStatus(KPIds);
    }

    let kp_tc_default_view = getDefaultViewSection(true);
    if (kp_tc_default_view === 'grid' && showKpGridView) {
      this.fetchDataForGridView();
    }
  }
  /**
    * Dividing KPIDs into two array
    * on clicking of grid we are calling the first array KpIds
    * then on calling the showmore wer will call the rest of the KpiIds Data
    */
  fetchDataForGridView = () => {
    try {
      const { getDocvizMetadataMulti, showKpGridView, KPIds } = this.props;

      if (showKpGridView) {
        let splitKpIdsData = splitKpIdsArray(KPIds, 9);
        this.setState({
          splitKpIdsData
        }, () => {
          if (splitKpIdsData && splitKpIdsData.firstArr && Array.isArray(splitKpIdsData.firstArr) && splitKpIdsData.firstArr.length > 0) {
            getDocvizMetadataMulti(splitKpIdsData.firstArr, false, false);
          }
        })
        this.triggerUsabillaSurveyFn();
      }
    } catch (error) {
      console.log('Error in fetchDataForGridView function ', error);
    }
  }

  componentDidUpdate = () => {
    const {
      isDownloadError,
      notifyWarningMessage,
      dismissAllNotifications,
      KPActions: { resetDownloadError }
    } = this.props;

    if (isDownloadError) {
      notifyWarningMessage("", DOWNLOAD_ERROR);
      setTimeout(() => {
        dismissAllNotifications();
        resetDownloadError();
      }, 5000);
    }

  }

  handleFileDownload = (event, item) => {
    event.stopPropagation();
    const {
      getKPFile,
      kpDownloadCallback
    } = this.props;
    let analyticsData = {};
    const { id, legacyAttachmentId, legacyMaterialId, paRecommended } = item;
    analyticsData.materialPAStatus = paRecommended ? PA_RECOMMENDED : NOT_PA_RECOMMENDED;
    if (legacyMaterialId && legacyAttachmentId) {
      analyticsData.materialID = legacyMaterialId;
      analyticsData.attachmentID = legacyAttachmentId;
    }
    analyticsData.pageId = id;
    getKPFile(id);
    if (kpDownloadCallback) {
      kpDownloadCallback(analyticsData);
    }
  };

  // Handle Download from grid View
  handleFileDownloadFromGrid = (item, id) => {
    let event = {
      stopPropagation: () => { }
    }
    if (item?.id === id) {
      this.handleFileDownload(event, item);
    }
  }

  handlePreview = (event, item) => {
    const { id, legacyMaterialId, legacyAttachmentId, paRecommended } = item;
    event.stopPropagation();
    const { handleDocvizPreview } = this.props;
    handleDocvizPreview(id, legacyMaterialId, legacyAttachmentId, paRecommended);
  }

  // Handle Preview from grid
  handlePreviewFromGrid = (item, id) => {
    let event = {
      stopPropagation: () => { }
    }
    if (item?.id === id) {
      this.handlePreview(event, item);
    }
  }

  downloadCallback = (item) => {
    const { isPreviewDownloadEntitlement, isPreviewDownloadREntitlement, filesStatus } = this.props;
    return isPreviewDownloadEntitlement && filesStatus[item?.id] &&
      showDownloadIcon(isPreviewDownloadEntitlement, isPreviewDownloadREntitlement, item?.isRestricted)
      ?
      this.handleFileDownload
      :
      () => { }
  }

  getMaterialPreviewUrl = ({ id, legacyMaterialId, legacyAttachmentId, paRecommended }) => {
    const { kpCardClickCallback } = this.props;
    let previewUrl, analyticsData = {};
    analyticsData.materialPAStatus = paRecommended ? PA_RECOMMENDED : NOT_PA_RECOMMENDED;
    if (legacyMaterialId && legacyAttachmentId) {
      analyticsData.materialID = legacyMaterialId;
      analyticsData.attachmentID = legacyAttachmentId;
    }
    analyticsData.pageId = id;
    previewUrl = CONFIG.UI_URL.KP_CONSUMPTION(id);

    if (kpCardClickCallback) {
      kpCardClickCallback(analyticsData); //event 24
    }
    !!window && window.open(previewUrl, '_blank', 'noopener');
  }

  //Handle Title Click
  handleTitleClickFromGrid = (item, id) => {
    if (item?.id === id) {
      this.getMaterialPreviewUrl(item);
    }
  }

  handleThumbsUpData = (selectedOptions = "N/A", item) => {
    const { id, relatedToGTM, paRecommended } = item;
    const { thumbsUpDownAnalyticsCallback } = this.props
    if (!!thumbsUpDownAnalyticsCallback)
      thumbsUpDownAnalyticsCallback({
        selectedOptionReason: selectedOptions,
        materialPAStatus: paRecommended ? PA_RECOMMENDED : NOT_PA_RECOMMENDED,
        pageId: id,
        gtm: relatedToGTM
      });
  }

  handleFileClick = (event, item) => {
    event.stopPropagation();
    const { isPreviewDownloadEntitlement, isPreviewDownloadREntitlement } = this.props;
    const {
      id,
      legacyMaterialId,
      legacyAttachmentId,
      isRestricted,
      attachment,
      isURLAdded,
      paRecommended
    } = item;
    if (showPreviewIcon({ isURLAdded, attachment }, isPreviewDownloadEntitlement, isPreviewDownloadREntitlement, isRestricted)) {
      const { handleDocvizPreview } = this.props;
      handleDocvizPreview(id, legacyMaterialId, legacyAttachmentId, paRecommended);
    }
  }

  previewVisible = (item) => {
    const { isPreviewDownloadEntitlement, isPreviewDownloadREntitlement } = this.props;
    return showPreviewIcon(item, isPreviewDownloadEntitlement, isPreviewDownloadREntitlement, item.isRestricted);
  }

  setGridOrListView = (kpViewSection) => {
    try {
      const { KEY_KB_TC_DEFAULT_VIEW } = CONFIG;
      const { docvizMultiDoc, handleViewAnalytics } = this.props;
      this.setState({
        kpViewSection
      }, () => {
        sendToLocalStorage(KEY_KB_TC_DEFAULT_VIEW, kpViewSection);
        if (docvizMultiDoc && checkIsGivenValueIsAArray(docvizMultiDoc) && docvizMultiDoc.length === 0) {
          this.fetchDataForGridView();
        }
      })
      if (kpViewSection === 'grid') {
        this.triggerUsabillaSurveyFn();
      }

      handleViewAnalytics(kpViewSection);

    } catch (error) {
      console.log('Error in setGridOrListView function ', error)
    }
  }

  checkIsDocvizSupported = (knowledgePagesItem, docvizItem) => {
    if (docvizItem && docvizItem?.slides?.length > 0) {
      return isPreviewDisabled(knowledgePagesItem)
    }
    return false;
  }

  mergDocvizWithKnowledgePagesData = (docvizItem, kpItem) => {
    let updatedItem = {};

    try {
      let formattedDate = "";

      if (kpItem) {
        let updatedDate = kpItem?.updatedDate || "";
        formattedDate = !!updatedDate ? formatTheDate(updatedDate, CP_UI_DATE_FORMAT) : "";
      }

      updatedItem = { ...docvizItem, contentType: kpItem.contentType, formattedDate }
    } catch (error) {
      console.log('Error in mergDocvizWithKnowledgePagesData function ', error);
    }

    return updatedItem;
  }

  /**
   * Dividing KPIDs into two array
   * on clicking of grid we are calling the first array KpIds
   * then on calling the showmore wer will call the rest of the KpiIds Data
   */
  fetchAllDocvizMultiGuidData = () => {
    try {
      const { getDocvizMetadataMulti, showKpGridView } = this.props;
      const { splitKpIdsData } = this.state;

      if (showKpGridView && splitKpIdsData && splitKpIdsData.lastArr && Array.isArray(splitKpIdsData.lastArr) && splitKpIdsData.lastArr.length > 0) {
        getDocvizMetadataMulti(splitKpIdsData.lastArr, false, true);
      }
    } catch (error) {
      console.log('Error in fetchAllDocvizMultiGuidData function ', error);
    }
  }

  handleShowMore = (value) => {
    try {
      const { updateMultiDocvizData, docvizMultiDocAllData } = this.props;
      if (value === 'show_more_clk') {
        if (docvizMultiDocAllData && docvizMultiDocAllData.length > 9) {
          updateMultiDocvizData(true)
        } else {
          this.fetchAllDocvizMultiGuidData()
        }

      } else {
        updateMultiDocvizData(false);
        getElemDistanceFromTop("#kpgridlist")
      }
    } catch (error) {
      console.log('Error in handleShowMore function ', error);
    }
  }

  triggerUsabillaSurveyFn = () => {
    triggerUsabillaSurvey(TC_GRID);
  }

  showMoreButtonCallback = (showMoreObj = {}) => {
    try {
      if (showMoreObj?.showLess) {
        getElemDistanceFromTop("#kpgridlist");
        this.setState({ isShowMore: true })
      } else {
        this.setState({ isShowMore: false })
      }
    } catch (error) {
      console.log('Error in showMoreButtonCallback function ', error);
    }
  }

  //Handle Docviz Load Error Analytics for Grid View
  handleErrorAnalyticsFromGrid = (kpID, id, msz) => {
    if (kpID === id) {
      const { isConsumptionView } = this.props;
      const newAdobeData = {
        KCpage: {
          id: id
        },
        page: {
          category: {
            primaryCategory: isConsumptionView ? PRIMARY_CATEGORY.KP_CONSUMPTION : PRIMARY_CATEGORY.KP,
          },
          pageInfo: {
            pageName: isConsumptionView ? PAGES.KP_CONSUMPTION : PAGES.KP_CREATION,
            pageURL: window.location.href
          },
        },
        docviz: {
          error: msz
        }
      };
      ANALYTICS.kc.sendEventData(newAdobeData, DTMRULE.DOCVIZ_PAGE_LOAD_FAIL);
    }
  }

  render() {
    const {
      knowledgePages: fetchedPages,
      heading,
      isPreviewDownloadEntitlement,
      isPreviewDownloadREntitlement,
      knowLedgeItemsInitialLimit,
      knowledgeItemHeight,
      emptyListHeading,
      filesStatus,
      docvizMultiDoc,
      data,
      isCasePage,
      alwaysShowHeading,
      showThumbsUpDown,
      showKpGridView,
      isDocvizInitialLoading,
      isDocvizFinalLoading,
      isDocvizShowMore
    } = this.props;
    const knowledgePages = data || fetchedPages;

    const { kpViewSection, isShowMore } = this.state;
    return (
      <div className="kpgridlist" id="kpgridlist">
        {showKpGridView && (
          <GridListButton setGridOrListView={this.setGridOrListView} kpViewSection={kpViewSection} />
        )}

        {showKpGridView && kpViewSection === 'grid' && !!knowledgePages?.length && (
          <KPGrid
            docvizMultiDoc={docvizMultiDoc}
            knowledgePages={knowledgePages}
            isDocvizInitialLoading={isDocvizInitialLoading}
            isDocvizFinalLoading={isDocvizFinalLoading}
            mergDocvizWithKnowledgePagesData={this.mergDocvizWithKnowledgePagesData}
            handleFileDownloadFromGrid={this.handleFileDownloadFromGrid}
            handlePreviewFromGrid={this.handlePreviewFromGrid}
            showKpGridView={showKpGridView}
            handleTitleClickFromGrid={this.handleTitleClickFromGrid}
            isShowMore={isDocvizShowMore}
            handleShowMore={this.handleShowMore}
            handleErrorAnalyticsFromGrid={this.handleErrorAnalyticsFromGrid}
          />
        )}

        {(kpViewSection === 'list' || !showKpGridView) && (
          <section className="kpList">
            {!!heading && (!!knowledgePages?.length || alwaysShowHeading) && <span className="kpList__heading--withContent">{heading}</span>}
            {!!knowledgePages?.length &&
              <ShowMore
                applyShowMore={knowledgePages?.length > knowLedgeItemsInitialLimit ? true : false}
                height={knowledgeItemHeight * knowLedgeItemsInitialLimit}
                showMoreButtonCallback={this.showMoreButtonCallback}
              >
                <ul>
                  {knowledgePages.map((item, index) => (
                    <li
                      key={uuidv4}
                      onClick={() => this.getMaterialPreviewUrl(item)}>
                      <KPItem
                        index={index}
                        isLastBeforeShowMore={index + 1 === knowLedgeItemsInitialLimit && isShowMore}
                        item={item}
                        handleFileClick={this.handleFileClick}
                        previewEnabled={this.previewVisible(item)}
                        showThumbsUpDown={showThumbsUpDown}
                        finalSuccess={(selectedOptions = "N/A") => this.handleThumbsUpData(selectedOptions, item)}
                        getKPFile={this.props.getKPFile}
                        isCasePage={isCasePage}
                        icons={[
                          {
                            icon: getIcon('bookmark'),
                            disabled: true,
                            callback: (event) => { event.stopPropagation() },
                          },
                          {
                            icon: getIcon('preview'),
                            disabled: isPreviewDisabled(item),
                            callback: (event) => { this.handlePreview(event, item) },
                            show: showDownloadIcon(isPreviewDownloadEntitlement, isPreviewDownloadREntitlement, item.isRestricted)
                          },
                          {
                            icon: <ConditionalDownloadIcon
                              title='download'
                              item={item}
                              isPreviewDownloadEntitlement={isPreviewDownloadEntitlement}
                              isPreviewDownloadREntitlement={isPreviewDownloadREntitlement}
                            />,
                            disabled: false,
                            className: "download-icon",
                            callback: this.downloadCallback(item),
                            hidden: isPreviewDownloadEntitlement ? item.isURLAdded === '1' : true,
                            show: filesStatus[item?.id] && isPreviewDownloadEntitlement ? item.isURLAdded !== '1' : false
                          }
                        ]}
                      />
                    </li>
                  ))}
                </ul>
              </ShowMore>
            }
            {!knowledgePages?.length && !!emptyListHeading && <span className="kpList__heading">{emptyListHeading}</span>}
          </section>
        )}
      </div>
    );
  }
};

KPList.propTypes = {
  KPIds: PropTypes.array.isRequired,
  knowledgePages: PropTypes.array,
  heading: PropTypes.string,
  emptyListHeading: PropTypes.string,
  isConsumptionView: PropTypes.bool,
  isPreviewDownloadEntitlement: PropTypes.bool,
  isPreviewDownloadREntitlement: PropTypes.bool,
  API: PropTypes.string,
  kpCardClickCallback: PropTypes.func,
  kpDownloadCallback: PropTypes.func,
  handleDocvizPreview: PropTypes.func.isRequired,
  data: PropTypes.array,
  disablePreview: PropTypes.bool,
  alwaysShowHeading: PropTypes.bool,
  showKpGridView: PropTypes.bool,
  isCasePage: PropTypes.bool,
};

KPList.defaultProps = {
  KPIds: [],
  knowledgePages: [],
  heading: "",
  emptyListHeading: "",
  isConsumptionView: true,
  isPreviewDownloadEntitlement: false,
  isPreviewDownloadREntitlement: false,
  API: CONFIG.API_URL.GET_KP_LIST,
  knowLedgeItemsInitialLimit: 10,
  knowledgeItemHeight: 92,
  kpCardClickCallback: () => { },
  kpDownloadCallback: () => { },
  data: null,
  disablePreview: false,
  alwaysShowHeading: false,
  downloadApplicationName: KNOWLEDGE_PAGE_APP_NAME,
  showKpGridView: false,
  isCasePage: false,
};

const mapStateToProps = (state) => ({
  knowledgePages: associatedKPsSelectors.getAssociatedKPs(state),
  isDownloadError: kpSelectors.getError(state),
  filesStatus: kpSelectors.getPreviewDocumentStatus(state),
  docvizMultiDoc: docvizSelectors.getDocvizMultiMetaData(state),
  isPreviewDownloadEntitlement: entitlementSelectors.getEntitlementValue(state, KNOWLEDGE_CONTRIBUTIONS, PREVIEW_DOWNLOAD),
  isPreviewDownloadREntitlement: entitlementSelectors.getEntitlementValue(state, KNOWLEDGE_CONTRIBUTIONS, PREVIEW_DOWNLOAD_R),
  isDocvizInitialLoading: docvizSelectors.getDocvizInitialLoading(state),
  isDocvizFinalLoading: docvizSelectors.getDocvizFinalLoading(state),
  isDocvizShowMore: docvizSelectors.getDocvizShowMoreStatus(state),
  docvizMultiDocAllData: docvizSelectors.getDocvizMultiMetaAllData(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getAssociatedKnowledgePages: (KPIds, isConsumptionView, API) => dispatch({
    type: KNOWLEDGE_ITEMS.GET_ASSOCIATED_KNOWLEDGE_PAGES,
    payload: { KPIds, isConsumptionView, API }
  }),
  getFilesStatus: (KPIds) => dispatch({
    type: KP.GET_FILES_STATUS,
    payload: {
      KPIds: KPIds,
      appName: KNOWLEDGE_PAGE_APP_NAME,
      type: CONFIG.downloadTypeForKP
    }
  }),
  getKPFile: (kpId) => dispatch({
    type: KP.GET_FILE,
    payload: {
      kpId: kpId,
      appName: ownProps.downloadApplicationName,
      type: CONFIG.downloadTypeForKP
    }
  }),
  KPActions: bindActionCreators({ ...KPActions }, dispatch),
  getDocvizMetadataMulti: (ids, isInPreviewMode, isShowMoreClicked) => dispatch({ type: DOCVIZ.GET_DOCVIZ_METADATA_MULTI, payload: { ids, isInPreviewMode, isShowMoreClicked } }),
  notifyWarningMessage: (title, msg) => dispatch(notifyWarning(title, msg)),
  dismissAllNotifications: () => dispatch(dismissNotifications()),
  updateMultiDocvizData: (isShowMoreClicked) => dispatch({ type: DOCVIZ.UPDATE_DOCVIZ_METADATA_MULTI, payload: { isShowMoreClicked } }),
});

export { KPList };

export default connect(mapStateToProps, mapDispatchToProps)(KPList);