import React from 'react';
import { TabComponent } from 'components/shared/TabComponent';
import { TaxonomyListing } from 'components/TaxonomyListing/TaxonomyListing';
import { selectors as taxonomySelector } from "redux/reducers/taxonomy/taxonomy_reducer";
import { resetAllParentTags } from "redux/actions/taxonomy/Taxonomy.actions";
import withErrorBoundary from 'containers/ErrorBoundary/ErrorBoundary';
import './ConsumptionTaxonomySection.scss';
import { connect } from "react-redux";
import { TAXONOMY } from "redux/constants";
import LABELS from 'labels';
import CONFIG from 'config'
import PropTypes from 'prop-types';

export class ConsumptionTaxonomySectionContainerWrapper extends React.PureComponent {
  state = {
    activeTab: 0,
  };

  getIDsArrayFromObject = (data) => {
    return data.map((item) => item.id);
  }

  componentWillUnmount = () => {
    const { clearAllParentTags } = this.props;
    clearAllParentTags();
  }

  componentDidMount = () => {
    const {
      subjects,
      ipaTagList,
      fpaTagList,
      bcgInternalList,
      getSLparentTags
    } = this.props;
    const {
      TBDB_IDS: {
        SEARCH_SUBJECT,
        FPA,
        IPA,
        BCG_INTERNAL
      }
    } = CONFIG;

    const tagsMapping = [
      { tags: subjects, taxonomyName: SEARCH_SUBJECT },
      { tags: ipaTagList, taxonomyName: IPA },
      { tags: fpaTagList, taxonomyName: FPA },
      { tags: bcgInternalList, taxonomyName: BCG_INTERNAL }
    ];

    tagsMapping.forEach(({ tags, taxonomyName }) => {
      if (tags && Array.isArray(tags) && tags.length) {
        const ids = this.getIDsArrayFromObject(tags);
        if (ids.length) {
          getSLparentTags(taxonomyName, ids);
        }
      }
    });
  }

  getActiveTab = (activeIndex) => {
    this.setState({ activeTab: activeIndex });
  };

  tabClicked = (e, index) => {
    const { onTabClicked, caseContactListingCallback } = this.props;
    const { activeTab } = this.state;

    if (!!onTabClicked) {
      const {
        KPCONSUMPTIONGROUP: { ASSOCIATED_TOPIC_PAGES, ASSOCIATED_SECTOR, SUBJECTS },
        IDENTIFIER: {
          CP_SEARCH_AND_DISCOVERY_TAGS_IDENTIFIER,
          CP_ASSOCIATED_PA_TOPIC_SECTOR_IDENTIFIER,
        } } = LABELS;
      const indexMap = [
        SUBJECTS,
        ASSOCIATED_SECTOR,
        ASSOCIATED_TOPIC_PAGES,
      ];
      const identifier = index === 0 ? CP_SEARCH_AND_DISCOVERY_TAGS_IDENTIFIER : CP_ASSOCIATED_PA_TOPIC_SECTOR_IDENTIFIER;
      onTabClicked(indexMap[index]);
      if (caseContactListingCallback && activeTab !== index) {
        caseContactListingCallback({ identifier })
      }
    }
  }

  mergeParent = (tagsTemp, parentTagsTemp) => {
    let tags = this.makeDuplicate(tagsTemp);
    if (parentTagsTemp && parentTagsTemp.length) {
      let parentTags = this.makeDuplicate(parentTagsTemp);

      const mergedTags = parentTags.map((item, index) => {
        item.pop();
        return [...item, tags[index]];
      });
      return mergedTags;
    } else {
      return tags;
    }
  }

  makeDuplicate = (obj) => JSON.parse(JSON.stringify(obj));

  render() {
    const {
      topicPagesHidden,
      subjects,
      ipaTagList,
      fpaTagList,
      bcgInternalList,
      associatedTopicPages,
      subjectsShowMore,
      getParentTagsData,
      sectorsShowMore,
    } = this.props;
    const {
      TBDB_IDS: {
        SEARCH_SUBJECT,
        FPA,
        IPA,
        BCG_INTERNAL
      }
    } = CONFIG;

    const {
      KPCONSUMPTIONGROUP: { ASSOCIATED_TOPIC_PAGES, ASSOCIATED_SECTOR, SUBJECTS },
      TOPICS: {
        INDUSTRIES,
        FUNCTIONAL,
        BCG_INTERNALS,
      }
    } = LABELS;

    const subjectTags = subjects.length ? this.mergeParent(subjects, getParentTagsData(SEARCH_SUBJECT)) : [];
    const ipaTags = ipaTagList.length ? this.mergeParent(ipaTagList, getParentTagsData(IPA)) : [];
    const fpaTags = fpaTagList.length ? this.mergeParent(fpaTagList, getParentTagsData(FPA)) : [];
    const bcgInternals = bcgInternalList.length ? this.mergeParent(bcgInternalList, getParentTagsData(BCG_INTERNAL)) : [];
    const totalTags = [...ipaTagList, ...fpaTagList, ...bcgInternalList];
    const taxonomyData = {
      [INDUSTRIES]: ipaTags,
      [FUNCTIONAL]: fpaTags,
      [BCG_INTERNALS]: bcgInternals,
    }

    return (
      <>
        <div className="sectorsWrapper margin_bottom--30">
          <TabComponent
            className="tabs__container sectorsWrapper__tab-component consumptionTabContainer"
            defaultActiveIndex={0}
            getActiveIndex={this.getActiveTab}
            onTabChangeCallback={this.tabClicked}
            menu={{ secondary: true, pointing: true }}
            arrayOfPaneElements={[
              {
                className: 'item-style',
                heading: ASSOCIATED_SECTOR,
                count: totalTags?.length,
                component: <TaxonomyListing individualShowMore={sectorsShowMore} key={ASSOCIATED_SECTOR} taxonomyData={taxonomyData} />
              },
              {
                className: 'item-style',
                heading: SUBJECTS,
                count: subjectTags?.length ?? 0,
                component: <TaxonomyListing
                  taxonomyData={{ 'Subjects': subjectTags }}
                  showHeading={false}
                  isDefaultTagsExpanded={false}
                  showMoreRecords={subjectsShowMore}
                  key={SUBJECTS}
                />,
              },
              {
                className: 'item-style',
                heading: ASSOCIATED_TOPIC_PAGES,
                count: associatedTopicPages?.count ?? 0,
                component: <TaxonomyListing key={ASSOCIATED_TOPIC_PAGES} taxonomyData={associatedTopicPages?.sectors} associatedTopicPagesCount={associatedTopicPages?.count} />,
                hiddenClassName: (!associatedTopicPages?.count || topicPagesHidden) ? "hidevisibility" : ""
              },
            ]}
          />
        </div>
      </>
    );
  }
}

ConsumptionTaxonomySectionContainerWrapper.propTypes = {
  consumptionData: PropTypes.object,
  topicPagesHidden: PropTypes.bool,
  subjectsShowMore: PropTypes.bool,
  sectorsShowMore: PropTypes.bool,
  subjects: PropTypes.array,
  ipaTagList: PropTypes.array,
  fpaTagList: PropTypes.array,
  bcgInternalList: PropTypes.array,
  associatedTopicPages: PropTypes.object,
  onTabClicked: PropTypes.func,
};

ConsumptionTaxonomySectionContainerWrapper.defaultProps = {
  consumptionData: {},
  topicPagesHidden: true,
  subjects: [],
  ipaTagList: [],
  fpaTagList: [],
  bcgInternalList: [],
  associatedTopicPages: {},
  subjectsShowMore: false,
  sectorsShowMore: false,
  isKb: false,
  onTabClicked: () => { },
};


const mapStateToProps = (state) => ({
  getParentTagsData: (taxonomyName) => taxonomySelector.getParentTagsData(state, taxonomyName),
});

const mapDispatchToProps = (dispatch) => ({
  getSLparentTags: (key, ids) => dispatch({ type: TAXONOMY.GET_SL_PARENT_TAGS_DATA, payload: { ids, key } }),
  clearAllParentTags: () => dispatch(resetAllParentTags()),
});


export const ConsumptionTaxonomySectionV1 = connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorBoundary(ConsumptionTaxonomySectionContainerWrapper));