import React from 'react';
import PropTypes from 'prop-types';
import Modal from "components/shared/Modal";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Image from "components/shared/Image";
import crossIcon from "assets/images/cross/cross.svg";
import CaseVersionListing from 'components/CaseVersionListing/CaseVersionListing';
import AdditionalInfoIcon from "assets/images/additional-info/ai_2x.svg";
import { selectors as casehistory } from "redux/reducers/caseHistory/caseHistory_reducer";
import { getCaseHistory } from 'redux/actions/caseHistory/caseHistory.actions';
import { actions as peopleResultsActions } from 'redux/actions/peopleresults/peopleresults_actions';
import CONFIG from "config";
import LABELS from "labels";

// scss
import "./CaseHistoryModal.scss";

export class CaseHistoryModal extends React.PureComponent {
  state = {
    owners: [],
    caseData: []
  };
  componentDidMount = async () => {
    const { peopleResultsActions: {
      getPeopleDataUsingIDs,
    },
      getCaseHistory, cid, ROW_COUNT, data } = this.props;
    getCaseHistory(cid, LABELS.CASE_HISTORY_MODAL.ROW_COUNT)

    let personColumn = null;
    if (!!personColumn) {
      const owners = data.map(record => record[personColumn]);
      if (owners && owners.length) {
        const peopleResult = await getPeopleDataUsingIDs(owners, false);
        this.setState({
          owners: peopleResult
        });
      }
    }
    this.updateCaseData();
  };

  componentDidUpdate = (prevProps) => {
    const { caseHistoryData } = this.props;
    if (JSON.stringify(caseHistoryData) !== JSON.stringify(prevProps.caseHistoryData)) {
      this.updateCaseData();
    }
  }
  updateCaseData = async () => {
    const {
      caseHistoryData,
      peopleResultsActions: {
        getPeopleDataUsingIDs
      }
    } = this.props;
    if (!!caseHistoryData) {
      const users = caseHistoryData.map(record => record.UpdatedBy)
        .filter(userId => userId && userId !== "0");

      let caseData = [];
      if (users && users.length) {
        const peopleResult = await getPeopleDataUsingIDs(users, false);
        caseData = caseHistoryData.map(data => {
          if (data.UpdatedBy && peopleResult) {
            const person = peopleResult.find(person => person.id === data.UpdatedBy);
            if (person) {
              data.un = person.preferredName;
            } else {
              data.un = data.UpdatedBy;
            }
          }
          return data;
        })
      };
      this.setState({ caseData })
    }
  };


  render() {
    const {
      onRequestClose,
      isOpen,
      header,
      data,
      caseHistoryTableKey,
    } = this.props;
    const { owners, caseData } = this.state;
    return (
      isOpen && (
        <Modal
          isOpen={isOpen}
          className="casehistorymodal"
          overlayClassName="drawfromright"
          onRequestClose={onRequestClose}
          closeTimeoutMS={500}
        >
          <section className="casehistorymodal__header">
            <Image
              className="casehistorymodal__headericon"
              src={AdditionalInfoIcon}
              alt={header}
            />
            <div className="casehistorymodal__headertitle">{header}</div>
            <button className="casehistorymodal__headerclose" onClick={onRequestClose}>
              <Image
                src={crossIcon}
              />
            </button>
          </section>
          <section className='casehistorymodal__case--content'>
            <CaseVersionListing
              caseHistoryData={caseData}
              caseHistoryTableKey={caseHistoryTableKey}
              people={owners}
              data={data}
            />
          </section>
        </Modal>
      )
    );
  }
};

CaseHistoryModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  caseHistoryData: PropTypes.array.isRequired,
  getCaseHistory: PropTypes.func.isRequired,
  peopleResultsActions: PropTypes.object.isRequired,

};
const mapStateToProps = (state) => ({
  caseHistoryData: casehistory.getCaseHistory(state),
});
const mapDispatchToProps = (dispatch) => ({
  getCaseHistory: bindActionCreators(getCaseHistory, dispatch),
  peopleResultsActions: bindActionCreators({ ...peopleResultsActions }, dispatch)

});


export default connect(mapStateToProps, mapDispatchToProps)(CaseHistoryModal);



